import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl:'./confirmation-dialog.component.html',
  styleUrls:['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  message: string = "Are you sure?"
  confirmButtonText = "Yes"
  cancelButtonText = "No"
  textBoxMessage: any;
  validationMessage: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
      if(data){
    this.message = data.message || this.message;
    this.validationMessage = data?.validationMessage || '';
    console.log('validationMessage', this.validationMessage)
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  onConfirmClick(): void {
    console.log("On ConFirm Click ")
    if(this.data?.showTextBox) {
      this.dialogRef.close({textBox: this.textBoxMessage, buttonStatus: 'yes'});
    } else {
      this.dialogRef.close(true);
    }
  }

  onChangeTextBox(event:any): void {
    this.textBoxMessage = event.target.value
  }

  closeDirectly(isDirectlyClose: string = 'no') {
    this.dialogRef.close(isDirectlyClose);
  }
}

