import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { MasterFormPopupComponent } from '../../manage-portal/master-form-popup/master-form-popup.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../user-management/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss']
})
export class FormListComponent implements OnInit {
  formId: any;
  apiUrl: string = '';
  isNodataFound: boolean = false;
  orderSorting: string = '';
  sortSelection: string = '';
  selectedColumn: any = '';
  userLevel: any;
  isMonthly: any;
  resourceCenter: any[] = [];
  formListParams: any;
  isSubmitAvailable: any;
  showCsvBtn: boolean = false;

  constructor(private commonService: CommonServiceService, private snackBar: MatSnackBar, public router: Router, private route: ActivatedRoute, public dialog: MatDialog) { 
    this.route.queryParams.subscribe(params => {
      this.formListParams = params;
      console.log("formId", params)
      if(!params)
      return;
      if((['1','2','3'].includes(params.formId))){
        this.formName = this.formList[params.formId];
        this.apiUrl = this.formApis[params.formId];
        this.formId = params.formId;
      }
    });
    let pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
    this.userLevel = JSON.parse(sessionStorage.getItem('userdata')!);
    let currentBaseProgramForm = this.getCurrentProgramFormMethod(pageAction[0])
    console.log("baseForm",currentBaseProgramForm)
    this.allowedAction = pageAction[0].authenicationJson.filter((el:any)=>{
      if(el?.model === currentBaseProgramForm.model) return el;
    })
  }
  urlParams: {formId:number} = {
    formId : 1
  };
  formListingData: any = [];
  totalDataCount: number = 0;
  public limit = 10;
  public skip = 0;
  public page = 1;
  countryData: any = [];
  viewForm : boolean = true;
  formName:string = '';
  formList = ['','Program Form', 'Baseline Form', 'Monthly Progress Form'];
  formApis = ['','programform/list?page=', 'baselineform/list?page=','baselineform/list?page='];
  routeUrl = ['','/program-form', '/baseline-form','/baseline-monthly-form'];
  allowedAction :any = [];
  baseFormModule : string[] = ['baselineform','programform'];
  downloadResponse:any = [];
  
  // isClassAdded = false;

  ngOnInit(): void {
    this.tableHeader();
    this.getCountryList();
  }
 
 // toggleClass() {
  //   this.isClassAdded = !this.isClassAdded;
  // }

  // @HostListener('document:click', ['$event'])
  // onClick(event: Event) {
  //   const clickedElement = event.target as HTMLElement;
  //   if (!clickedElement.closest('app-admin-forms')) {
  //     this.isClassAdded = false;
  //   }
  // }

  programFormHeader = [
    {
      thead : 'S.No.'
    },
    {
      thead : 'Year',
      key : 'financial_year'
    },
    {
      thead : 'Updated At',
      key : 'updatedAt'
    },
    {
      thead : 'Updated By',
      key : 'name'
    },
    {
      thead : 'Status'
    },
    {
      thead : 'Action'
    },

  ];

  baselineFormHeader = [
    {
      thead : 'S.No.'
    },
    {
      thead : 'Country',
      key : 'country_name'
    },
    {
      thead : 'IP',
      key : 'industrialpark_name'
    },
    {
      thead : 'Factory',
      key : 'factory_name'
    },
    {
      thead : 'Year',
      key : 'financial_year'
    },
    {
      thead : 'Submitted At',
      key : 'createdAt'
    },
    {
      thead : 'Submitted By',
      key : 'name'
    },
    {
      thead : 'Status'
    },
    {
      thead : 'Action'
    },
  ];

  monthlyProgressFormHeader = [
    {
      thead : 'S.No.'
    },
    {
      thead : 'Country',
      key : 'country_name'
    },
    {
      thead : 'IP',
      key : 'industrialpark_name'
    },
    {
      thead : 'Factory',
      key : 'factory_name'
    },
    {
      thead : 'Year',
      key : 'financial_year'
    },
    {
      thead : 'Month',
      key : 'month'
    },
    {
      thead : 'Submitted At',
      key : 'createdAt'
    },
    {
      thead : 'Submitted By',
      key : 'name'
    },
    {
      thead : 'Status'
    },
    {
      thead : 'Action'
    },
  ];

 getCountryList(){
    this.commonService.countryData().subscribe((res:any) => {
      if(res.success){
        this.countryData = res?.data;
        setTimeout(() => {
              this.formListingData = [];
              this.programFormListing(1, this.countryData);
         
          
        }, 100);
      }
    });
  }

  tableHeader(){
    if(this.formName == 'Program Form'){
      this.resourceCenter = this.programFormHeader;
    } else if(this.formName == 'Baseline Form'){
      this.resourceCenter = this.baselineFormHeader;
    } else{
      this.resourceCenter = this.monthlyProgressFormHeader;
    }
  }

  programFormListing(page:any, countryData:any[],sortingOrder:string='',columnName:string=''){
    let userData:any = JSON.parse(sessionStorage.getItem("userdata")|| '{}');
    this.commonService.getProgramFormListing(this.apiUrl,page, userData.role_id, userData.id, this.formId,sortingOrder,columnName).subscribe((res:any) => {
      if(res?.data?.length){
        this.formListingData = res?.data;
        this.showCsvBtn = this.formListingData.find((ele:any) => ele?.is_draft == false) ? true : false;
        
        this.isNodataFound = false;
        console.log("datataa", this.formListingData);
        this.totalDataCount = res.total;
        for (let i = 0; i <= this.formListingData.length; i++) {
          let obj = countryData.find((ele: any) => ele.name == this.formListingData[i].country_name);
          this.formListingData[i]['country_icons'] = obj.country_icons;
      }
      }else{
        this.isNodataFound = true;
      }
      
    })
    console.log("datataa",this.formListingData);
  }


  backToForm(){
    this.router.navigate(['/admin-forms'])
  }
  setPage(event: any) {
    // this.selectedColumn = '';
    this.page = event.pageIndex + 1;
    this.skip = (event.pageIndex + 1) * this.limit - this.limit;
    console.log('limit',event.pageSize,event)
    this.limit = event.pageSize;
    this.programFormListing(event.pageIndex + 1, this.countryData, this.orderSorting, this.selectedColumn);
  }

  openForm(){

    let dataBody = {draftForm: true}
    let createData = {
      title: '',
      data: '',
      type: 'yearChange'
    }

    let currentBaseUrl:string = ''
    if(this.formName == 'Baseline Form'){
      currentBaseUrl = 'baselineform/create'
    }
    else if(this.formName == 'Monthly Progress Form'){
      currentBaseUrl = 'baselineform/create'
      this.isMonthly = 'is_month=true'
    }
    else{
      currentBaseUrl = 'programform/create'
    }
    this.commonService.submitFormAnswers(dataBody, currentBaseUrl, this.isMonthly).subscribe(resp => {
      console.log("lkklklklk",resp);
      if(resp.success){
        let routing = this.routeUrl[this.formId]
        this.router.navigate([routing])
      }      
    }, (error) => {
      console.log(error);
      let objmsg:{message:string} = {
        message: error?.error?.message
      }
      Object.assign(createData, objmsg);
      if(error?.error?.hasOwnProperty('isValid') && !error?.error?.isValid){
        const dialogRefcomp = this.dialog.open(MasterFormPopupComponent, {
          data: createData,
          width: '600px',
          panelClass: 'signUp-dialog-class',
          disableClose: true,
          position: { top: '200px' },
        });
      }
    })
  }

  editForm(viewMode:string = 'editMode', data:any){
    let isFactory:boolean  = data?.factory_id ? true : false;
    let routing = this.routeUrl[this.formId]
    this.router.navigate([routing],{queryParams:{
      type: viewMode,
      id: data?.id,
      isDraft: data?.is_draft,
      isFactory: isFactory,
      formId: this.formListParams?.formId
    }})

  }
  getCurrentProgramFormMethod(data: any) {
    let getCurrentTab: any = null; 
  
    this.baseFormModule.some((el: any) => {
      getCurrentTab = data.authenicationJson.find((e: any) => {
        return e.model === el &&
          (e.isAction || e.is_checked || (e.authentication && e.authentication.some((inside: any) => inside.isAction)));
      });
  
      return getCurrentTab !== undefined; 
    });
  
    return getCurrentTab;
  }

   downloadCSV() {
    let currentBaseUrl:string = ''
    let format:string = 'csv'
    if(this.formName == 'Baseline Form'){
      currentBaseUrl = 'baselineform'
    }
    else if(this.formName == 'Monthly Progress Form'){
      currentBaseUrl = 'baselineform'
      format = 'csv?is_month=true'
    }
    else{
      currentBaseUrl = 'programform'
    }
    console.log("currentBaseUrl",currentBaseUrl)
      this.commonService.downloadDashboardCSV(currentBaseUrl,format).subscribe((res: any) => {
        this.downloadResponse = res;
        console.log("dataaa", this.downloadResponse);
        this.commonService.downloadCSVFile(this.downloadResponse, this.formName)
  
      }, (error => {
        console.log("eerorDashboard", error)
        // if (error?.error?.text) {
        //   this.downloadResponse = error?.error?.text;
        //   let fileName: string = 'ProgramImpactSummary.csv'
        //   console.log("downloadResponse",this.downloadResponse)
        //   this.commonService.downloadCSVFile(this.downloadResponse, this.formName)
        // }
      }))
    }
    sortTable(columnName: any, sortType:string = '') {
      this.sortSelection = sortType;
      this.selectedColumn = columnName;
      this.orderSorting = sortType === 'down' ? 'DESC' : 'ASC';
      console.log("c",columnName)
      this.programFormListing(this.page,this.countryData,this.orderSorting,columnName)
    }

    openSnackBar(message:any, action = '') {
      this.snackBar.open(message, action, {
        duration: 4000,
        // verticalPosition: 'top',
        // horizontalPosition:'right',
        panelClass: message.includes('successfully') ? ['black-snack'] : ['primary']
      });
    }

    deleteLineitem(data:any){
      let url = this.formName == 'Program Form' ? `programform/update/${data?.id}` : `baselineform/update/${data?.id}`;
      let is_monthly: string = this.formName == 'Program Form' ? '' : 'is_month=true';
      let payload = {
        isActive: false
      }
      this.commonService.submitFormAnswers(payload, url, is_monthly).subscribe((res:any) => {
        if(res?.success){
          this.openSnackBar(res?.message);
          this.getCountryList();
        }
      })
    }

    dialogConfirmation(rowData?: any) {
      let message = "Are you sure want to delete?";
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message,
          buttonText: {
            ok: "Yes",
            cancel: "No",
          },
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.deleteLineitem(rowData);
        }
      });
    }
}
