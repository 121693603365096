<div class="Footer_bottom">
    
    <section>
        <div class="container-fluid">
<div class="d-flex align-items-center">
    <svg class="me-2 pointer" (click)="backToForm()" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
      </svg>
      
    <nav aria-label="breadcrumb" class="my-3">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToForm()">Forms</li>
          <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">{{formName}}</li>
        </ol>
      </nav>
</div>
            <div class="row">
                <div class="col-12">

                  </div>
                <div class="col-12">
                    <div class="d-md-flex justify-content-end mb-3">
                        <div class="tabs-list rounded-pill mb-3 overflow-auto d-none">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                <mat-button-toggle value="bold" checked style="display: none">All</mat-button-toggle>
                                <mat-button-toggle value="bold" style="display: none">Monthly</mat-button-toggle>
                                <mat-button-toggle value="bold" style="display: none">Quarterly</mat-button-toggle>
                                <mat-button-toggle value="bold" style="display: none">Annual</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="d-flex align-items-center">
                            <!-- <button class="btn rounded-pill text-white" style="background-color: #00625F;">+ Add
                                New</button> -->
                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                    <ng-container *ngIf="(buttonPermission?.key && buttonPermission?.key === 'csv' && buttonPermission?.isAction) && showCsvBtn" >
                                        <img src="../../../assets/images/download-form.svg" class="img-fluid pointer me-4" alt="" [matTooltip]="'Download CSV'" (click)="downloadCSV()">
                                    </ng-container>
                                </ng-container>
                            <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                <ng-container *ngIf="buttonPermission?.key && buttonPermission?.key === 'create' && buttonPermission?.isAction && (userLevel?.userlevel_id != 1 || formName == 'Program Form')">
                                    <button class="btn rounded-pill text-white mx-4" style="background-color: #00625F;" (click)="openForm()">+ Add New</button>
                                </ng-container>
                        
                                <ng-container>
                                    <button class="filter-dropdown rounded-pill" mat-button [matMenuTriggerFor]="menu" style="display: none">
                                        <span class="">
                                            <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                                <g id="Group_16821" data-name="Group 16821" transform="translate(-4157 -790)">
                                                  <path id="Path_6201" data-name="Path 6201" d="M0,0H20V20H0Z" transform="translate(4157 790)" fill="none"/>
                                                  <path id="Path_6202" data-name="Path 6202" d="M18,4V5.667h-.833L13,11.917V19H8V11.917L3.833,5.667H3V4ZM5.837,5.667l3.83,5.745v5.922h1.667V11.412l3.83-5.745Z" transform="translate(4156.5 789.333)"/>
                                                </g>
                                              </svg>
                                              
                                        </span>
                                        Filters</button>
                                    <mat-menu #menu="matMenu" class="formFilterMenu">
                                      <!-- <ul>
                                            <li>
                                                <div>
                                                        <input type="search" class="form-control">
                                                    </div>
                                                </li>
                                        </ul> -->
                                        <div (click)="$event.stopPropagation();">
                                            <div class="my-3 px-2 position-relative">
                                                <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute filter-search-icon" alt="">
                                                <input type="search" placeholder="Search" class="form-control filter-search rounded-pill">
                                              </div>
                                              <div class="accordion" id="accordionExample">
                                                <div class="accordion-item border-0">
                                                  <h2 class="accordion-header border-0" id="headingOne">
                                                    <button class="accordion-button border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Forms
                                                    </button>
                                                  </h2>
                                                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body form-body">
                                                        <form action="">
                                                            <div class="form-check mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1">
                                                                <label class="form-check-label" for="flexCheckDefault1">
                                                                    All forms
                                                                </label>
                                                              </div>
                                                              <div class="form-check mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
                                                                <label class="form-check-label" for="flexCheckDefault2">
                                                                    Monthly forms
                                                                </label>
                                                              </div>
                                                              <div class="form-check mb-2">
                                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3">
                                                                <label class="form-check-label" for="flexCheckDefault3">
                                                                    Quarterly forms
                                                                </label>
                                                              </div>
                                                        </form>
                                                    </div>
                                                  </div>
                                                </div>
                                                </div>
        
                                                <div class="accordion accordion-flush" id="accordionFlushExample">
                                                    <div class="accordion-item">
                                                      <h2 class="accordion-header" id="flush-headingOne">
                                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                            Date Range
                                                        </button>
                                                      </h2>
                                                      <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                        <div class="accordion-body">
                                                            <div class="row">
                                                                <div class="col-lg-6">
                                                                    <input type="date" name="" placeholder="From" class="form-control" id="">
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <input type="date" name="" placeholder="To" class="form-control" id="">
                                                                </div>
                                                            </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    </div>
                                                    <hr>
                                                    <div class="text-end">
                                                        <button class="btn border-0 fw-600 reset-btn">Reset</button>
                                                        <button class="btn border-0 fw-600 apply-btn">Apply</button>
                                                    </div>
        
                                        </div>
                                    </mat-menu>
                                </ng-container>
                            </ng-container>
                                
                                <!-- <div class="dropdown">
                                    <button class="btn filter-dropdown rounded-pill dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_16821" data-name="Group 16821" transform="translate(-4157 -790)">
                                                  <path id="Path_6201" data-name="Path 6201" d="M0,0H20V20H0Z" transform="translate(4157 790)" fill="none"/>
                                                  <path id="Path_6202" data-name="Path 6202" d="M18,4V5.667h-.833L13,11.917V19H8V11.917L3.833,5.667H3V4ZM5.837,5.667l3.83,5.745v5.922h1.667V11.412l3.83-5.745Z" transform="translate(4156.5 789.333)"/>
                                                </g>
                                              </svg>
                                              
                                        </span>
                                        Filters
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                      <li>
                                      <div class="mb-3">
                                        <input type="search" placeholder="Search" class="form-control">
                                      </div>
                                      </li>
                                      <li>
                                        <div class="accordion accordion-flush" id="accordionFlushExample">
                                            <div class="accordion-item">
                                              <h2 class="accordion-header" id="flush-headingOne">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    Forms
                                                </button>
                                              </h2>
                                              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                                              </div>
                                            </div>
                                            </div>
                                      </li>
                                    </ul>
                                  </div> -->
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-auto col-md-4 col-sm-6" style="display: none">
                    <div class="card status-card mb-3">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <p class="fs-12 mb-2">Total</p>
                                    <div class="fw-600 fs-28">20</div>
                                </div>
                                <div class="border rounded-pill p-2">
                                    20% <span>
                                        <svg class="increase-img" id="trending_up_black_24dp" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <path id="Path_6205" data-name="Path 6205" d="M0,0H20V20H0Z" fill="none"/>
                                            <path id="Path_6206" data-name="Path 6206" d="M13.662,6l1.908,1.912L11.5,11.988,8.172,8.647,2,14.844l1.174,1.178,5-5.011,3.332,3.34L16.752,9.1l1.908,1.912V6Z" transform="translate(-0.33 -1.011)"/>
                                          </svg>
                                          
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-lg-auto col-md-4 col-sm-6" style="display: none">
                    <div class="card status-card mb-3">
                        <div class="card-body">
                            <div class="d-flex align-items-center justify-content-between">
                                <div>
                                    <p class="fs-12 mb-2">Missed</p>
                                    <div class="fw-600 fs-28">12</div>
                                </div>
                                <div class="border rounded-pill p-2">
                                    10% <span>
                                        <svg class="decrease-img" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <g id="trending_up_black_24dp" transform="translate(20 20) rotate(180)">
                                              <path id="Path_6205" data-name="Path 6205" d="M0,0H20V20H0Z" fill="none"/>
                                              <path id="Path_6206" data-name="Path 6206" d="M13.662,6l1.908,1.912L11.5,11.988,8.172,8.647,2,14.844l1.174,1.178,5-5.011,3.332,3.34L16.752,9.1l1.908,1.912V6Z" transform="translate(-0.33 -1.011)"/>
                                            </g>
                                          </svg>
                                          
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
    
            <div class="row">
                <div class="col-12">
                    <div class="card border-0 mb-3">
                        <div class="card-body">
                            <div class="d-sm-flex mb-3 justify-content-between align-items-center" *ngIf="formListingData?.length">
                                <!-- <div class="mb-sm-0 mb-2">Showing result <span class="fw-500">1-3</span> of 3</div> -->
                                <mat-paginator class="f-14" [length]="totalDataCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalDataCount !== 0"></mat-paginator>
                                <div class="position-relative" style="display: none">
                                    <img src="../../../assets/images/search-line.svg"
                                        class="img-fluid position-absolute search-icon" alt="">
                                    <input type="search" placeholder="Search by" class="form-control search-input">
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead class="bg-light">
                                        <tr>
                                            <th class="fw-bold" *ngFor="let item of resourceCenter">
                                             {{item.thead}}
                                             <!-- <i class="fa fa-fw fa-sort pointerHead" *ngIf="item?.key" (click)="sortTable(item?.key)"></i> -->
                                            <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="formListingData?.length && item?.key" (click)="sortTable(item?.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                            <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="formListingData?.length && item?.key" (click)="sortTable(item?.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white">

                                        <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                            <ng-container *ngIf="buttonPermission?.key && buttonPermission?.key === 'list' && buttonPermission?.isAction">
                                                <tr *ngFor="let item of formListingData; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <td *ngIf="formName != 'Program Form'" class="text-left">
                                                        <span>
                                                            <img [src]="item?.country_icons" class="img-fluid me-1 countryFlag" alt="">
                                                        </span>
                                                        {{item?.country_name}}
                                                    </td>
                                                    <td *ngIf="formName != 'Program Form'">
                                                        {{item?.industrialpark_name ? item?.industrialpark_name : '--'}}
                                                    </td>
                                                    <td *ngIf="formName != 'Program Form'">
                                                        {{item?.factory_name ? item?.factory_name : '--'}}
                                                    </td>

                                                    <td>{{item?.financial_year}}</td>
                                                    <td *ngIf="formName == 'Monthly Progress Form'">
                                                        {{item?.month}}
                                                    </td>
                                                    <td>{{formName == 'Program Form' ? (item?.updatedAt | date: 'dd/MM/yyyy') : (item?.createdAt | date: 'dd/MM/yyyy')}}</td>
                                                    <td>{{item?.name}}</td>
                                                    <td><div class="fw-600 m-auto statusBtn" [ngClass]="!item?.is_draft ? 'submit-admin-completed' : 'submit-admin-drafted'">{{item?.is_draft ? 'Draft' : 'Submitted'}}</div></td>
                                                    <td>
                                                        <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="editForm('editMode',item.id)" matTooltip="Edit Form" *ngIf="item?.is_draft">
                                                        <i class="fa fa-eye fas viewForm pointer" *ngIf="!item?.is_draft" matTooltip="View Form" (click)="editForm('viewMode',item.id)"></i> -->
                                                        <div >
                                                            <i class="fa fa-eye fas viewForm pointer" matTooltip="View Form" (click)="editForm('viewMode',item)" style="left: 48px; position: relative; z-index: 2;"></i>
                                                            <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                                <span style="left: 33px; position: relative;">
                                                                    <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" [ngClass]="!(item?.is_draft  && (buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction) && ((userLevel?.userlevel_id == 5 && item?.factory_name == null) || userLevel?.userlevel_id == 7 && item?.factory_name != null ) || (userLevel?.userlevel_id == 1 && (buttonPermission?.key === 'update' && buttonPermission?.isAction))) ? 'invisible' : 'visible'" alt="" (click)="editForm('editMode',item)" matTooltip="Edit Form">
                                                                </span>
                                                                <span style="left: 33px; position: relative;" >
                                                                    <img src="../../../assets/images/delete-bin.svg" class="img-fluid me-2 pointer" [ngClass]="!(userLevel?.userlevel_id == 1 && (buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)) ? 'invisible' : 'visible'" alt="" (click)="dialogConfirmation(item)" matTooltip="Delete Form">
                                                                </span>
                                                            </ng-container>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                        <!-- <tr>
                                            <td>1</td>
                                            <td>
                                                <a href="#" class="form-route text-decoration-none" routerLink="/program-form">Program Form</a>
                                            </td>
                                            <td>Industrial Park</td>
                                            <td>Monthly</td>
                                            <td>40</td>
                                            <td class="text-green">40</td>
                                            <td>21/07/2023</td>
                                            <td>Rakesh Kumar</td>
                                            <td>
                                                <span>
                                                    <img src="../../../assets/images/kenya-flag.png" class="img-fluid me-1" alt="">
                                                </span>Kenya</td>
                                            <td><button class="btn submit-admin fw-600">Submitted</button></td>
                                        </tr>
                                        
                                        <tr>
                                            <td>2</td>
                                            <td>
                                                <a href="#" class="form-route text-decoration-none" routerLink="/baseline-form">Baseline Form</a>
                                            </td>
                                            <td>Industrial Park</td>
                                            <td>Monthly</td>
                                            <td>20</td>
                                            <td class="text-yellow">12</td>
                                            <td>21/07/2023</td>
                                            <td>Raju Yadav</td>
                                            <td>
                                                <span>
                                                    <img src="../../../assets/images/kenya-flag.png" class="img-fluid me-1" alt="">
                                                </span>Kenya</td>
                                            <td><button class="btn delayed-btn fw-600">Delayed</button></td>
                                        </tr>

                                        <tr>
                                            <td>3</td>
                                            <td>
                                                <a href="#" class="form-route text-decoration-none" routerLink="/baseline-monthly-form">Baseline Monthly Form</a>
                                            </td>
                                            <td>Implementing Partner</td>
                                            <td>Annual</td>
                                            <td>17</td>
                                            <td class="text-red">7</td>
                                            <td>21/07/2023</td>
                                            <td>Satyam Sharma</td>
                                            <td>
                                                <span>
                                                    <img src="../../../assets/images/kenya-flag.png" class="img-fluid me-1" alt="">
                                                </span>Kenya</td>
                                                <td><button class="btn delayed-btn fw-600">Delayed</button></td>
                                        </tr> -->
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="isNodataFound" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>