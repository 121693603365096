<div class="Footer_bottom">
  <!-- <section class="first-sec">
    <div class="container">
        <div class="row justify-content-center">
          <ng-container *ngFor="let item of mainHead">
            <div class="col-md-10">
                <h1 class="mb-3 text-center">{{item?.title ? item?.title : 'Impact Stories'}}</h1>
                <p class="color-44">
                    {{item?.subTitle}}
                </p>
            </div>
          </ng-container>  
        </div>
    </div>
</section> -->
<ng-container *ngFor="let item of mainHead">
  <section class="impactStoryComp d-flex flex-column align-items-center justify-content-center first-sec" [ngStyle]="{'background-image': 'url(' + ((item && item.banner )? item.banner : '') + ')'}">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="text text-center">
                    <h1 [ngClass]="{'text-white' : (item && item?.banner )}" >{{item?.title ? item?.title : 'Impact Stories'}}</h1>
                    <p  [ngClass]="{'text-white text-center' : (item && item?.banner),'color-44': !(item && item?.banner)}"  style="font-size: 14px;" [innerHTML]="(item && item?.subTitle ) ? item?.subTitle : ''"></p>
                </div>
            </div>
        </div>
    </div>
  </section>
</ng-container>
<section class="storiesCard" *ngIf="hideImpactData">
    <div class="container">
      <div class="row" >
        <div class="col-12">
          <h3 class="color-44 mb-4" style="margin-bottom: 30px;">Impact Stories</h3>
      </div>
      <ng-container *ngIf="impactData.length">
        <ng-container *ngFor="let items of impactData.slice(0,6); let i = index">
          <div class="col-md-4" style="margin-bottom: 35px;">
    <div class="card-bg h-100">
      <div class="card h-100 card-hover border-0 shadow-sm">
        <div class="position-relative main-img">
    
          <img [src]="items?.banner" class="card-img-top h-100 rounded-0 img-fluid card-img" alt="...">
        </div>
        <div class="card-body" style="background-color: #00625F;">
          <h6 class="text-white">{{items?.title}}</h6>
          <!-- <p class="card-text text-white">{{items?.description | truncatePipe : 150}}</p> -->
          <p class="card-text text-white" [innerHTML]="items?.description | truncatePipe : 150"></p>

        </div>
        <div class="text-center card-footer" style="background-color: #00625F;">
          <button class="btn read-btn mb-3 text-white rounded-pill" style="background-color: #47d985; border: 1px solid #47d985;" routerLink="/related-stories" [queryParams]="{ id: items?.id, prevRoute: prevRoute}">Read More</button>
        </div>
       </div>
    </div>
          </div>
          </ng-container>
          <ng-container *ngIf="isLoadMore">
            <ng-container *ngFor="let items of impactData.slice(6, impactData.length); let i = index">
              <div class="col-md-4" style="margin-bottom: 35px;">
        <div class="card-bg h-100">
          <div class="card h-100 card-hover border-0 shadow-sm">
            <div class="position-relative main-img">
        
              <img [src]="items?.banner" class="card-img-top h-100 rounded-0 img-fluid card-img" alt="...">
            </div>
            <div class="card-body" style="background-color: #00625F;">
              <h6 class="text-white">{{items?.title}}</h6>
              <!-- <p class="card-text text-white">{{items?.description | truncatePipe : 150}}</p> -->
              <p class="card-text text-white" [innerHTML]="items?.description | truncatePipe : 150"></p>
    
            </div>
            <div class="text-center card-footer" style="background-color: #00625F;">
              <button class="btn read-btn mb-3 text-white rounded-pill" style="background-color: #47d985; border: 1px solid #47d985;" routerLink="/related-stories" [queryParams]="{ id: items?.id, prevRoute: prevRoute}">Read More</button>
            </div>
           </div>
        </div>
              </div>
              </ng-container>
          </ng-container>
          <span class="loadmoreTab mb-3" (click)="isLoadMore = !isLoadMore" *ngIf="impactData.length > 4"><u>{{!isLoadMore ? 'Show More..' : 'Show Less'}}</u></span>
      </ng-container>
      
        <!-- <ng-container *ngFor="let item of impactData; let i = index">
          <div class="col-lg-8" *ngIf="i % 2 == 0 && (i == 0 || i > 6)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid image-round h-446 w-100" alt="">
              <div class="text-white position-absolute img-content">
                <h4 class="text-white">{{item?.title}}</h4>
                <p class="fs-16 text-truncate col-8-truncate" style="max-width: 600px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id, prevRoute: prevRoute}">Read More</button>
              </div>
            </div>
          </div>
          </ng-container>
          
        <ng-container *ngFor="let item of impactData; let i = index">
          <div class="col-lg-4" *ngIf="i % 2 == 1 && (i == 1 || i > 7)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid w-100 h-365 image-round" alt="">
              <div class="text-white position-absolute img-content">
                <h6 class="text-white">{{item?.title}}</h6>
                <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id, prevRoute: prevRoute}">Read More</button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngFor="let item of impactData; let i = index">
        <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 2 || i > 8)">
          <div class="position-relative img-opacity mb-3">
            <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
            <div class="text-white position-absolute img-content">
              <h6 class="text-white">{{item?.title}}</h6>
              <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
              <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id, prevRoute: prevRoute}">Read More</button>
            </div>
          </div>
        </div>
        <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 3 || i > 9)">
          <div class="position-relative img-opacity mb-3">
            <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
            <div class="text-white position-absolute img-content">
              <h6 class="text-white">{{item?.title}}</h6>
              <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
              <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
            </div>
          </div>
        </div>
        <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 4 || i > 10)">
          <div class="position-relative img-opacity mb-3">
            <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
            <div class="text-white position-absolute img-content">
              <h6 class="text-white">{{item?.title}}</h6>
              <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
              <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
            </div>
          </div>
        </div>
        </ng-container>

        <ng-container *ngFor="let item of impactData; let i = index">
          <div class="col-lg-6" *ngIf="i % 2 == 1 && (i == 5 || i > 11)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
              <div class="text-white position-absolute img-content">
                <h6 class="text-white">{{item?.title}}</h6>
                <p class="text-truncate" style="font-size: 13px; max-width: 400px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
              </div>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="i % 2 == 1 && (i == 6 || i > 12)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
              <div class="text-white position-absolute img-content">
                <h6 class="text-white">{{item?.title}}</h6>
                <p class="text-truncate" style="font-size: 13px; max-width: 400px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
              </div>
            </div>
          </div>
        </ng-container> -->
      </div>
    </div>
</section>
</div>