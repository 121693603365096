import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserWelcomeComponent } from 'src/app/layout/footer/user-welcome/user-welcome.component';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { MatSelectChange } from '@angular/material/select';
import { UserManagementServiceService } from '../../user-management/user-management-service.service';
import { MasterFormPopupComponent } from '../../manage-portal/master-form-popup/master-form-popup.component';
import { MatVerticalStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.scss']
})
export class CommonFormComponent implements OnInit, OnChanges {
  
//   formQuestions:any = [
//     {
//         "id": 1,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "1",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "countries_with_ip",
//         "title": "How many countries have facilities/industrial parks participating in INSTEP?",
//         "description": "Number of countries that have facilities operating under INSTEP intervention programs",
//         "program_level": false,
//         "country_level": false,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:45.809Z",
//         "updatedAt": "2023-11-22T09:55:36.427Z",
//         "name": "Participating countries"
//     },
//     {
//         "id": 2,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "2",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "industrial_parks_in_projects",
//         "title": "How many Industrial Parks are participating in projects under the INSTEP program?",
//         "description": "Number of IPs where INSTEP projects are being implemented",
//         "program_level": false,
//         "country_level": true,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:46.078Z",
//         "updatedAt": "2023-11-22T13:27:00.453Z",
//         "name": "Industrial parks in INSTEP network"
//     },
//     {
//         "id": 3,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "3",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "ta_factories_in_ips",
//         "title": "How many T&A factories are located in Industrial Parks that participate the INSTEP program?",
//         "description": "Number of T&A factories located in IPs where INSTEP projects are being implemented",
//         "program_level": false,
//         "country_level": false,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:46.249Z",
//         "updatedAt": "2023-11-20T09:24:55.853Z",
//         "name": "T&A factories in the INSTEP network"
//     },
//     {
//         "id": 4,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "4",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "male_workers_in_instep",
//         "title": "How many male workers are part of the INSTEP Program?",
//         "description": "Number of workers reached by the program/No. of workers part of different projects under the INSTEP program. E.g. workers cooperative, ZDHC, ZLD, etc.",
//         "program_level": false,
//         "country_level": false,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:46.405Z",
//         "updatedAt": "2023-11-20T09:24:56.039Z",
//         "name": "Workers in INSTEP network "
//     },
//     {
//         "id": 5,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "5",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "female_workers_in_instep",
//         "title": "How many female workers are part of the INSTEP program?",
//         "description": "Number of workers reached by the program/No. of workers part of different projects under the INSTEP program. E.g. workers cooperative, ZDHC, ZLD, etc.",
//         "program_level": false,
//         "country_level": false,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:46.597Z",
//         "updatedAt": "2023-11-20T09:24:56.229Z",
//         "name": "Workers in INSTEP network "
//     },
//     {
//         "id": 6,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "6",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "implementing_partners_with_idh",
//         "title": "How many implementing partners have contracts with IDH in the INSTEP program to implement projects at industrial parks/factories?",
//         "description": "Implementation partners working with IPs and Factories to implement INSTEP remediation plans - IP that have a contract with IDH",
//         "program_level": false,
//         "country_level": false,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:46.804Z",
//         "updatedAt": "2023-11-20T09:24:56.425Z",
//         "name": "Registered implementation partners"
//     },
//     {
//         "id": 7,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "7",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "multistakeholder_agreements",
//         "title": "How many multi-stakeholder agreements have been signed under INSTEP program?",
//         "description": "Multistakeholder agreements codify commitments from buyers, government, or other ecosystem players towards sustainable sourcing, green growth, etc.",
//         "program_level": false,
//         "country_level": false,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:46.968Z",
//         "updatedAt": "2023-11-20T09:24:56.616Z",
//         "name": "Mutlistakeholder agreements"
//     },
//     {
//         "id": 8,
//         "formId": 1,
//         "theme_name": "Overview",
//         "serial_num": "8",
//         "type": 1,
//         "place_holder": "Enter Number",
//         "required": true,
//         "short_key": "investments_raised",
//         "title": "How much investment has been raised by the INSTEP program into the execution of IPSA Collective Improvement Plans?",
//         "description": "Investments raised by INSTEP ecosystem stakeholders into execution of IPSA Collective Improvement Plans",
//         "program_level": true,
//         "country_level": false,
//         "factory_level": false,
//         "ip_level": false,
//         "isActive": true,
//         "createdBy": 1,
//         "createdAt": "2023-11-20T09:24:47.123Z",
//         "updatedAt": "2023-11-20T09:24:56.805Z",
//         "name": "Co-investments raised"
//     }
// ];
  // sideBarData:any = [
  //   {
  //     "id": 1,
  //     "sidebarHead": "Program",
  //     "subSteppers": [
  //         {
  //           "stepperName":"Overview"   
  //         },
  //         // {
  //         //   "stepperName":"IPSA"                
  //         // }
  //     ]
  //   }
  // ];
  months = [
    {
      month: 'January',
      value: 'January'
    },
    {
      month: 'February',
      value: 'February'
    },
    {
      month: 'March',
      value: 'March'
    },
    {
      month: 'April',
      value: 'April'
    },
    {
      month: 'May',
      value: 'May'
    },
    {
      month: 'June',
      value: 'June'
    },
    {
      month: 'July',
      value: 'July'
    },
    {
      month: 'August',
      value: 'August'
    },
    {
      month: 'September',
      value: 'September'
    },
    {
      month: 'October',
      value: 'October'
    },
    {
      month: 'November',
      value: 'November'
    },
    {
      month: 'December',
      value: 'December'
    }
  ];



  @Input() formQuestions:any = [];
  @Input() url!:string;
  @Input() formId!:number;
  @Input() isMonthly:string = "";
  @Input() createFormURL!:string;
  @Input() prefillForm!:string;
  @Input() updateForm!:string;

  fileUploadInfo:string = "*File size limit: 5MB*";

  userDetails:any = sessionStorage.getItem('userdata')
  @ViewChild('stepper') stepper!: MatVerticalStepper; // Definite assignment assertion
  selectedMonth:any = '';
  selectedYear:any = '';
  finalBody: any = {};
  commonForm!: FormGroup;
  steppers: any;
  urlData: any = {};
  prefillData: any = [];
  urlParams: {type:string, id:number} = {
    type: '',
    id: 0
  };
  formList = ['','Program Form', 'Baseline Form', 'Monthly Progress Form'];
  formName:string = '';
  isViewMode:boolean =false;
  files:any = [];
  imageAllData: any = [];
  putAPIurl: any;
  payloadURL: any = [];
  isEditMode: boolean = false;
  imgURL: any = {};
  emptyQuescount: number = 0;

  countrys :any = [];
  ipNames :any = [];
  factoryNames :any = [];
  showCountryDropdown: boolean = false;
  showIPdropdown: boolean = false;
  showFactoryDropdown: boolean = false;
  userId: string = '';
  selectedCountry: any;
  collapseQuestions: any [] = [];
  nameArray: any = [];
  fileURL: any;
  isDraftStatus: any = '';
  isFinancialYear: any ;
  currentPagePath: string | undefined = '';
  financialYears: any [] = [];
  currentSelectedYear: any;
  currentParams: any = {};
  isFinancialMonth: string = '';
  isFactory: string = '';
  listId: any;
  isformRendered: boolean = false;
  
  
  constructor(private formBuilder: FormBuilder, private commonService: CommonServiceService, public dialog: MatDialog, private snackBar: MatSnackBar,
    private router: Router, private route: ActivatedRoute, private userManagementService: UserManagementServiceService,
    ) {
      this.route.queryParams.subscribe(params => {
        this.currentParams = params;
        console.log("params",this.currentParams);
        
        this.isDraftStatus = Object.keys(params).length && params?.isDraft ? params?.isDraft : '';
        let path = this.route?.snapshot?.routeConfig?.path!;
        this.isFactory = Object.keys(params).length && params?.isFactory ? params?.isFactory : ''; 
        this.listId  = Object.keys(params).length && params?.id ? params?.id : ''; 
        this.currentPagePath = path.replace(/-/g, '_')      
      })
    // this.updateForm();
   }

async getUniqueQuesNames(respData:any){
  let dataArray = await respData.reduce((acc:any, obj:any) => {
    const key = obj.name;
    if (!acc[key]) {
      acc[key] = obj;
    }
    return acc;
  }, []);     
  this.collapseQuestions = Object.values(dataArray);
  console.log("this.collapseQuestions",this.collapseQuestions);
}

getFormQuestions(url:string, formId:number, isMonthly:string =""){
    let isShowFactory: boolean = this.isFactory == 'true' ? true : false;
    let factoryParams:string = (this.userDetails?.userlevel_id == 1 || this.userDetails?.userlevel_id == 5)&& this.isFactory && formId !=1? `&isFactory=${isShowFactory}` : '';
  this.formName = this.formList[formId];
  this.createFinancialForm(this.formName);
   this.commonService.getFormQuestions(url, formId, isMonthly,factoryParams).subscribe(res => {
     this.isformRendered = true;
    this.formQuestions = res?.data;
    // this.nameArray = res.data.map((ele:any) => ele.name);

    // let uniqueQuestionName = res?.data?.map((questions:any) => questions?.name);
    // this.collapseQuestions =  uniqueQuestionName.filter((value:any, index:number, self:any) => {
    //   return self.indexOf(value) === index;});
    this.getUniqueQuesNames(res?.data);
    
      
    this.formQuestions.forEach((element:any) => {
      this.commonForm.addControl(element.short_key, new FormControl('', element?.required ? [
        Validators.required,
        Validators.max(2147483647),
        
      ] : []))
  });

  this.showHideValues();

  this.route.queryParams.subscribe(params => {
    console.log("params",params)
    if(!params)
    return;
    if(params.type == "editMode"){
      this.isEditMode = true;
      
    }
    if((params.type == "editMode" || params.type == "viewMode")){
      this.isViewMode = params.type == "viewMode" ? true : false;
      this.patchFormValues(params);
    }
  })
  this.steppers = this.formQuestions.map((ele:any) => {
    return ele?.theme_name;
  });
  this.steppers = [...new Set(this.steppers)];
  console.log("steppp",this.steppers, this.formQuestions);

  console.log("ddd", this.commonForm, url, formId)
  // this.hasDuplicate(this.formQuestions, 'name');
  }, (error:any) => {
    let errorMessage: string = error?.error?.message || 'Something went wrong';
    this.openSnackBar(errorMessage);
  }
  )
}

// Keeping this message for future referecne
  // hasDuplicate(arr: any[], key: string): boolean{
  //   const encounteredValues: { [key: string]: any } = {};
  //   for (const obj of arr) {
  //     const value = obj[key];
  //     if (encounteredValues[value] !== undefined) {
  //       return true;
  //     } 
  //     encounteredValues[value] = true;
  //   }
  //   return false;
  // }
  
  ngOnChanges(changes:SimpleChanges){
    console.log("changes", changes)
    // if(changes.formId && changes.url && !changes.isMonthly){
    //   this.getFormQuestions(changes.url.currentValue, changes.formId.currentValue);
    // }
    // if(changes.isMonthly.currentValue){
    //   this.getFormQuestions(changes.url.currentValue, changes.formId.currentValue, changes.isMonthly.currentValue);
    // }
  //   if(changes.formQuestions && changes.formQuestions.currentValue && changes.formQuestions.currentValue.length){
  //     this.formQuestions = [...changes.formQuestions.currentValue];
  // //   let dynamicControls:any = {};
  // //   console.log("formQuestions", changes, this.formQuestions, this.commonForm);
  // //   // for(let i = 0; i <= this.formQuestions.length; i++){
  // //   //   let shortKey = this.formQuestions[i].short_key;
  // //   //   console.log("formQuestions", shortKey);
  // //   //   // dynamicControls[shortKey] = ["", Validators.required]
  // //   // }

  //   //   this.formQuestions.forEach((element:any) => {
  //   //     this.commonForm.addControl(element.short_key, new FormControl('', Validators.required))
  //   // });
  //   console.log("formQuestions", this.formQuestions, this.commonForm);
  // //   this.commonForm = this.formBuilder.group(dynamicControls)
  // //   console.log("formQuestions", changes, this.formQuestions);
  // }
  }

  setControls(){
        this.formQuestions.forEach((element:any) => {
        this.commonForm.addControl(element.short_key, new FormControl('', Validators.required))
    });
  }


  ngOnInit(): void {
    this.userDetails = JSON.parse(this.userDetails);
    console.log("form",this.showCountryDropdown,this.showIPdropdown,this.showFactoryDropdown,this.userDetails?.userlevel_id)
    if(this.currentPagePath != 'program_form'){
    this.getCountryDetails();
    }

    // if(Object.keys(this.currentParams).length == 0){
      this.getFinancialYearsData();
    // }
    console.log("formQuestions", this.formQuestions);
    let dynamicControls:any = {};
    console.log("formQuestions", this.formQuestions, this.commonForm);
    // this.setControls();
    this.formQuestions.forEach((element:any) => {
      
      this.commonForm.addControl(element.short_key, new FormControl('', element?.required ? Validators.required : []))
  });
    // for(let i = 0; i <= this.formQuestions.length; i++){
    //   let shortKey = this.formQuestions[i].short_key;
    //   console.log("formQuestions", shortKey);
    //   // dynamicControls[shortKey] = ["", Validators.required]
    // }

    // this.formQuestions.forEach((element:any) => {
    //   dynamicControls[element.short_key] = ["", Validators.required];
    // });
    
    console.log("formQuestions", this.formQuestions,  this.commonForm,this.url);
    if(this.formId && this.url && !this.isMonthly){
      this.getFormQuestions(this.url, this.formId);
    }
    if(this.isMonthly){
      this.getFormQuestions(this.url, this.formId, this.isMonthly);
    }
    this.dropdownActive()
    

  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  openSnackBar(message:any, action = '') {
    this.snackBar.open(message, action, {
      duration: 4000,
      // verticalPosition: 'top',
      // horizontalPosition:'right',
      panelClass: message.includes('successfully') ? ['black-snack'] : ['primary']
    });
  }

  // openSnackBar(data: string[], duration:number) {
  //   this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  // }

  dataSubmitted :boolean = false;
  submitProgramDataForm(param:any, isDraft:any = ""){
    this.emptyQuescount = 0
    this.dataSubmitted = true;
    console.log("valuess", this.commonForm);
    console.log("valuess", this.files);
    
    let createFormUrl = this.createFormURL;
    
    if(!this.commonForm.controls['countryName']?.value.id && this.formName != 'Program Form' && !isDraft){
      this.openSnackBar('Country is required !!');
      return 
    }
    
    this.countForEmptyQues();
    
    if(param && param.type == "editMode"){
      createFormUrl = createFormUrl+"update/" + param.id;
    }else{
      createFormUrl = createFormUrl+"create";
    }
    let userId = JSON.parse(sessionStorage.getItem('userdata')!);
    
    let finalBody = { ...this.commonForm.value };
    if (this.formName === 'Program Form' || this.isDraftStatus === '') finalBody = { ...finalBody, createdBy: userId?.id }

    if (this.formName !== 'Program Form') finalBody = { ...finalBody, is_month: this.isMonthly ? true : false };
    this.finalBody = finalBody;
    // this.finalBody = Object.assign(this.commonForm.value, {"createdBy": userId?.id}, {'is_month': this.isMonthly ? true : false});
    if([2,3].includes(this.formId)){
      console.log("form", this.commonForm, this.finalBody, this.payloadURL)
      // this.finalBody['zdhc_reports'] = this.payloadURL[0];
      // this.finalBody['zdhc_performance_reports_upload'] = this.payloadURL[1]
    }
    // return true;
    if(this.showCountryDropdown && this.formName != 'Program Form'){
      let country = {country_id : this.commonForm?.controls['countryName'] ? this.commonForm?.controls['countryName'].value?.id : '',}
      Object.assign(this.finalBody, country);
    }
    if(this.showIPdropdown && this.formName != 'Program Form'){
      let ipLevel = {industrialpark_id : this.commonForm?.controls['ipName'] && this.commonForm?.controls['ipName'].value != '' ? this.commonForm?.controls['ipName'].value?.id : '',}
      Object.assign(this.finalBody, ipLevel);
    }
    if(this.showFactoryDropdown && this.formName != 'Program Form'){
      let factoryLevel = {factory_id : this.commonForm?.controls['factoryName'] && this.commonForm?.controls['ipName'].value != '' ? this.commonForm?.controls['factoryName'].value?.id : '',}
      Object.assign(this.finalBody, factoryLevel);
    }
    delete this.finalBody?.countryName;
    delete this.finalBody?.ipName;
    delete this.finalBody?.factoryName;
    if(isDraft){
      this.finalBody = Object.assign(this.finalBody, {"is_draft": true});
    //   let dataObject = this.finalBody;
    //   let filteredObject:any = {};
    //   for (const key in dataObject) {
    //     if (dataObject[key] !== "") {
    //         filteredObject[key] = dataObject[key];
    //     }
    // }
    // this.finalBody = filteredObject;
    }else{
      this.finalBody = Object.assign(this.finalBody, {"is_draft": false})
    }
    // Iterate over the keys of the object and making the keys null if it contains Empty String
    for (let key in this.finalBody) {
      if (this.finalBody.hasOwnProperty(key) && this.finalBody[key] === "") {
        this.finalBody[key] = null; // Replace empty string with null
      }
    }
    this.commonService.submitFormAnswers(this.finalBody, createFormUrl, this.isMonthly).subscribe(res => {
      if(res.success){
        this.openSnackBar(res?.message);
     
        setTimeout(() => {
          window.history.back();
        }, 1000);
        // let dialogRef = this.dialog.open(UserWelcomeComponent, {
        //   width: '500px',
        //   position: {top: '150px'},
        //   data: {flag: 'programForm'}
        // });
        // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          
        // });
      }
    },(error) => {console.log("error", error);console.log("valuess", this.commonForm);this.openSnackBar(error.error.message + `, Count: ${this.emptyQuescount}`);});

  }

  countForEmptyQues(){
    for (const key in this.commonForm.value) {
      if (this.commonForm.value.hasOwnProperty(key) && this.commonForm.value[key] === '') {
        this.emptyQuescount++;
      }
    }

    return this.emptyQuescount;
  }

  updateFOrmQuestion(prefillObject: any) {
    let sourceObject = prefillObject;
    console.log("finalDAta",this.prefillData);
    this.formQuestions = this.formQuestions.map((item: any) => {
      const { short_key } = item;

      if (short_key && sourceObject.hasOwnProperty(short_key)) {
        return {
          ...item,
          value: sourceObject[short_key], // Assign value from the source object
        };
      }

      return item;
    });

    this.showHideValues();
  }

  showHideValues(){
    this.formQuestions.forEach((obj:any) => {
      obj.hide = true;
      if (obj.parent) {
        const order = obj.parent.order;
        const value = obj.parent.value[0]; // Assuming value is always an array with a single element
    
        this.formQuestions.forEach((otherObj:any) => {
          if (
            otherObj.short_key === order && otherObj.hasOwnProperty('value')
            
          ) {
            // obj.parent.value && value.includes(obj.parent.value[0]) ? false : true;
            // let otherValue = otherObj.value ? otherObj.value.map((data:any) => data.toString()) : [];
            // obj.hide = otherValue.includes(value) ? false : true;

            if(order == 'meeting_cetp_wastewater_permit_requirements'){
              obj.hide = otherObj.value == value ? false : true;
            }else if(otherObj?.value?.length){
              obj.hide = otherObj.value.some((optionValue:any) => optionValue == value) ? false : true;
            }
            
            
          }
        });
      }
    });

    console.log("formQuestions", this.formQuestions)
  }

  convertStringToNumber(obj:any) {
    for (const key in obj) {
      if(key != 'financial_year'){
        if (typeof obj[key] === 'string' && !isNaN(Number(obj[key]))) {
          obj[key] = Number(obj[key]);
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (Array.isArray(obj[key])) {
            obj[key] = obj[key].map((item:any) => {
              if (typeof item === 'string' && !isNaN(Number(item))) {
                return Number(item);
              }
              return item;
            });
          } else {
            this.convertStringToNumber(obj[key]);
          }
        }
    }}
  }

  patchFormValues(params:any){
    this.urlParams = params;
    this.userId = params.id
    console.log("userId",this.userId)
    let userData:any = JSON.parse(sessionStorage.getItem("userdata")|| '{}');
    let url = this.prefillForm+`?id=${params.id}`;
    console.log("editMode",url,1, userData.userlevel_id, userData.id,this.formId)
    this.commonService.getProgramFormListing(url,-1, userData.userlevel_id, userData.id,this.formId).subscribe((res:any) => {
      this.prefillData = res.data;
      console.log("prefillData", this.prefillData);
      this.prefillData = this.prefillData.find((ele:any) => ele.id == params.id);
      this.isFinancialYear = this.prefillData?.hasOwnProperty('financial_year') ? this.prefillData?.financial_year : '';
      // console.log("prefillData0", this.prefillData);
      if(this.isMonthly && this.isFinancialYear){
        this.currentSelectedYear = this.isFinancialYear;
        this.isFinancialMonth = this.prefillData?.month;
        let yearParams = `financial_year=${this.currentSelectedYear}`
        this.getMonthsData(yearParams,'yearChange', this.prefillData?.month);
      }
      // console.log("prefillData1", this.prefillData);
      this.convertStringToNumber(this.prefillData);
      // console.log("prefillData2", this.prefillData);
      // prefield for drop down

      if(this.prefillData?.country_id && this.prefillData?.industrialpark_id && this.prefillData?.factory_id){
        this.getIpNameDetails([] ,this.prefillData?.country_id);

        setTimeout(() => {
          this.getFactoryNameDetails([], this.prefillData?.industrialpark_id);
        }, 1000);
      }
      else if(this.prefillData?.country_id && this.prefillData?.industrialpark_id && !this.prefillData?.factory_id){
        this.getIpNameDetails([] ,this.prefillData?.country_id);
        this.getFactoryNameDetails([], this.prefillData?.industrialpark_id,false);
      }
      else if(this.prefillData?.country_id && !this.prefillData?.industrialpark_id && !this.prefillData?.factory_id){
        this.getIpNameDetails([] ,this.prefillData?.country_id);
          this.dropdownDataPrefield(this.userDetails?.userlevel_id)
      }else{
        this.dropdownDataPrefield(this.userDetails?.userlevel_id)
      }
      // console.log("prefillData4", this.prefillData);
      this.updateFOrmQuestion(this.prefillData);
      // console.log("prefillData5", this.prefillData);
      this.commonForm.reset();
      this.commonForm.patchValue(this.prefillData);
      this.commonForm.updateValueAndValidity();
      // console.log("prefillData3", this.commonForm, this.formQuestions, this.prefillData)
      if([2,3].includes(this.formId)){
        this.imgURL = {'zdhc_reports': this.prefillData?.zdhc_reports, 'zdhc_performance_reports_upload': this.prefillData.zdhc_performance_reports_upload};
        // this.imgURL.push(this.prefillData.zdhc_reports, this.prefillData.zdhc_performance_reports_upload)
        // this.imgURL = this.prefillData.zdhc_performance_reports_upload
      }

      
      
    })
  }

  focusOnCards(receivedId:string = '', index:number) {
    console.log("sss", receivedId)
    if(receivedId){
      // this.stepper.selectedIndex = index;
    // (<HTMLInputElement>document.getElementById(receivedId.toString())).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById(receivedId.toString())).scrollIntoView();
    }, 500);
    }
  }

  onScroll(event: Event) {
    // In-Progress CODE
    const rightPanelElement = document.getElementById('rightPanel'); // Assuming ID of the right panel
    console.log("event",rightPanelElement);

  }


  checkAndSetShow(obj:any, item:any, selectedValue:any) {
    // if(!selectedValue.length){
    // obj.hide =true;
    // }
    let value = selectedValue.toString().split(",");
    if (obj.parent && obj.parent.order === item.short_key) {
      obj.hide = obj.parent.value && value.includes(obj.parent.value[0]) ? false : true;
      if(obj.hide){
        obj.value = null;
        this.commonForm.patchValue({
          [obj?.short_key]: null
        })
      }
      
    }
  
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        this.checkAndSetShow(obj[key], item, selectedValue);
      }
    }
  }

  optionChange(event:MatSelectChange, item:any){
    console.log("event", event, this.commonForm, this.formQuestions, item)

      // let value = event.value.toString().split(",");
      // let formQuestions = this.formQuestions;
      // item.value = event.value;
      // this.showHideValues();
      if(item.type == 7){
        event.value = parseInt(event.value)
      }
      this.formQuestions.forEach((obj:any) => {
        this.checkAndSetShow(obj,item,event.value);
      });
      // this.formQuestions.forEach((form:any, index:number) => {
      //     if(form.parent && form.parent.order == item.short_key){
      //         if(event.value.length){
      //             console.log("vest", form.short_key, value, form.parent)
      //             this.formQuestions.forEach((child:any) => {
      //                 if(child.short_key == item.short_key){
      //                      console.log("vestValueUpside", form.short_key, value, child)
      //                     if(event.value.length){
      //                           console.log("vestValue", form.short_key, value, form.parent)
      //                         if(value.includes(form.parent.value[0])){
      //                             this.formQuestions[index].hide = false;
      //                             console.log("vestinside", this.formQuestions[index])
      //                         }
      //                     }else{
      //                         this.formQuestions[index].hide = true;
      //                         console.log("vestinsideelse", this.formQuestions[index])
      //                     }
      //                 }
      //             })
      //         }else{
      //           this.formQuestions[index].hide = true;
      //         }
      //     }
          
      // })

      console.log()
  }
  // file upload 
  onFileDropped(event:any, index:any) {
    
    this.files = [];
    this.prepareFilesList(event, index);

  }


   fileBrowseHandler(files: any, index:number, item:any) {
     const file: File = files.target.files[0];
     console.log('filessss',files);
    if(file){
      let maximumFileSize = 5 * 1024 * 1024; // 5MB in bytes
      let currentFileSize = files?.target?.files[0]?.size;

      if(currentFileSize > maximumFileSize){
        files.target.value = null;
       this.openSnackBar(['Please upload file under 5 MB !' || 'Failed !'] , '');
       return ;
      }
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'text/plain', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel', 'text/csv'];
      if (!allowedTypes.includes(file.type)) {
       this.openSnackBar(['Please select a valid file format !' || 'Failed !'] , '');
        return;
      }
    }
    
    let payload = [{
        "file_name": files.target.files[0].name,
        "mime_type": files.target.files[0].type
      }];
      let itemShortKey = item?.short_key;
      this.imgURL[item?.short_key] = '';
      this.commonService.uploadImage(payload).subscribe((res:any) => {
        if(res.success){
          console.log("POSTresponse",res);
          this.imageAllData = res.data;
          this.imageAllData.forEach((ele:any) => {
            this.putAPIurl = ele?.url;
            this.fileURL = ele?.file_url;
            this.payloadURL.splice(index, 0, ele?.file_url);
          });
          this.commonService.imagePUTapi(this.putAPIurl, files.target.files[0]).subscribe((res:any) => {
            console.log("putresponse",res, item);
            this.commonForm.patchValue({
              [itemShortKey]: this.fileURL
            })

          })
        }
      })

    
      if(this.files.length && this.files.length == 2){
        this.files.splice(index, 1);
      }
    this.prepareFilesList(files.target.files, index);
    // console.log('files', files)
  }
  prepareFilesList(files: Array<any>, index?:any) {
    if(index == 0 && this.files.length && (Object.keys(this.files[index]).length == 0)){
      this.files.splice(index, 1)
    }
    for (const item of files) {
      console.log("filesss",files);
      
      item.progress = 0;
      // this.files.push(item);
      
      if(!this.files.length && index > 0){
        let tempObject = {};
        this.files.push(tempObject, item);
      }else{
        this.files.splice(index, 0, item);
      }

    }
    console.log("this.files",this.files, index);
     
    this.uploadFilesSimulator(index);
  }
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }
  deleteFile(index: number = -1, fileDropRef:any) {
    if(index == -1){
      this.imageAllData = [];
      this.imgURL = {};
      return 
    }
    this.files.splice(index, 1, '');
    if (this.files.length == 0) {
      // this.libraryForm.reset();
    }
    fileDropRef.value = null;    
  }
  openDocument(imageURL:string){
    window.open(imageURL);
  }

  // add country releated sections

  async getCountryDetails(){
    let countryId:number = this.userDetails?.userlevel_id == 1 ? 0 : this.userDetails?.country_id;
    await this.userManagementService.countryDetails(countryId).subscribe((res:any) => {
       if(res?.success && res?.data && res.data.length){
         this.countrys =  res?.data;
         let selectedCountry =  this.countrys[0];
           
         if(!this.urlParams?.id){
          this.commonForm.patchValue({
            countryName: selectedCountry
           })
           this.getIpNameDetails('', selectedCountry?.id)
         }
       }
     },(error:any)=>{
       this.openSnackBar([error?.message || 'Failed !'] , '');
     })
   }

   async getIpNameDetails(data:any = [], selectedCountryId:string){
    console.log(data, "123");
    // let countryId:any = this.userDetails?.userlevel_id == 1 ? selectedCountryId : this.userDetails?.country_id;
   await this.userManagementService.ipNameDetails(selectedCountryId).subscribe((res:any) => {
      if(res?.success){
        if(this.countrys?.length && res?.data && res?.data?.length){
          this.ipNames = this.userDetails?.industrialpark_id ? res?.data.filter((ip:any) => ip.id == this.userDetails?.industrialpark_id) : res?.data;
          let selectedIp = this.ipNames[0];
            
          if((this.userDetails?.userlevel_id != 1 || this.userDetails?.industrialpark_id)&& !this.urlParams?.id){
            this.commonForm.patchValue({
              ipName: selectedIp
            })
            this.getFactoryNameDetails('', selectedIp?.id)
          }
        }
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    },()=>{
      if(this.userId){
        if(this.prefillData.country_id && this.prefillData.industrialpark_id  &&  !this.prefillData.factory_id ){
          this.dropdownDataPrefield(this.userDetails?.userlevel_id)
        }
      }
    })
    
  }
  async getFactoryNameDetails(data:any = [], seleectedId:string , isValueChange=true){
    // let factoryId:any = this.userDetails?.userlevel_id == 1 ? seleectedId : this.userDetails?.industrialpark_id;
   await this.userManagementService.factoryNameDetails(seleectedId).subscribe((res:any) => {
      if(res.success){
        if(this.ipNames?.length && res?.data && res.data.length){
          this.factoryNames = this.userDetails?.factory_id ? res.data.filter((factory:any) => factory.id == this.userDetails?.factory_id) : res.data;
          let selectedFactory = this.factoryNames[0];

          if((this.userDetails?.userlevel_id != 1 || this.userDetails?.factory_id)&& !this.urlParams?.id){
            this.commonForm.patchValue({
              factoryName: selectedFactory
            })
          }
            
        }
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    }, () => {
      if(this.userId){
        if(isValueChange){
          this.dropdownDataPrefield(this.userDetails?.userlevel_id)
        }
      }
    })
  }

  dropdownDataPrefield(data:any){
    
    let countryDetail:any;
    let ipDetail:any;
    let factoryDetail:any;
    

    switch (data) {
      case 4:
        /* adding new form controls to the `userForm` FormGroup while selecting the Country Level in user Role. */
        console.log("case4",'')
        if(this.formName != 'Program Form'){
          this.commonForm.addControl("countryName",new FormControl('', Validators.required));
        }

        this.showCountryDropdown = true;
        this.showIPdropdown = false;
        this.showFactoryDropdown = false;
        break;
      case 5:
        console.log("case5",'5')
        /* adding new form controls to the `userForm` FormGroup while selecting the IP LEVEL in user Role. */
        if(this.formName != 'Program Form'){
          this.commonForm.addControl("countryName",new FormControl('', Validators.required));
          this.commonForm.addControl("ipName",new FormControl(''));
        }
        if(this.prefillData?.factory_id){
          this.commonForm.addControl("factoryName",new FormControl(''));
        }
        
        this.showCountryDropdown = true;
        this.showIPdropdown = true;
        this.showFactoryDropdown = this.prefillData?.factory_id ? true : false;
        break;
      case 7:
      case 1:
      case 2:
      case 3:
      case 8:
        /* adding new form controls to the `userForm` FormGroup while selecting the Factory LEVEL in user Role. */
        console.log("case7",'7')
        if(this.formName != 'Program Form'){
          this.commonForm.addControl("countryName",new FormControl('', Validators.required));
          this.commonForm.addControl("ipName",new FormControl(''));
          this.commonForm.addControl("factoryName",new FormControl(''));
        }
        // this.prefieldDropDown()
        this.showCountryDropdown = true;
        this.showIPdropdown = true;
        this.showFactoryDropdown = this.userDetails?.userlevel_id == 1 && !this.prefillData?.factory_id ? false : true;
        break;
    
      default:
        console.log("case5afterdefault", data)
        /* setting the values of the form controls `countryName`, `factoryName`, and `ipName` to an empty object. 
        This is typically done to reset the values of these form controls to their initial state or clear their values while changeing the User Role. */
        if(this.formName != 'Program Form'){
          this.commonForm.controls['countryName'].setValue({});
          this.commonForm.controls['factoryName'].setValue({});
          this.commonForm.controls['ipName'].setValue({});
        }

        /* making the values empty until county is being selected */
        this.ipNames = [];   
        this.factoryNames = [];

        this.showCountryDropdown = false;
        this.showIPdropdown = false;
        this.showFactoryDropdown = false;

        break;
    }
   
    if(this.prefillData?.country_id){
      countryDetail = this.countrys.find((el:any)=> el.id  === this.prefillData.country_id);
      this.commonForm.patchValue({
        countryName :  countryDetail
      })
      this.prefillData.countryName = countryDetail;


      console.log("form countryDetail", this.commonForm,this.prefillData)
    }
    if(this.prefillData?.industrialpark_id){
      ipDetail = this.ipNames.find((el:any)=> el.id === this.prefillData?.industrialpark_id);
      console.log("form ipDetail",ipDetail)
      this.commonForm.patchValue({
        ipName:ipDetail
      })
      this.prefillData.ipName = ipDetail;
    }
    if(this.prefillData?.factory_id){
      factoryDetail = this.factoryNames.find((el:any)=> el.id === this.prefillData?.factory_id);
      console.log("form factoryDetail",factoryDetail)
      this.commonForm.patchValue({
        factoryName:factoryDetail
      })
      this.prefillData.factoryName = factoryDetail;
    }

  }
 

  countryChangeHandler(event: MatSelectChange) : void {
    this.selectedCountry = event.value.id;
   let countryDetail = this.countrys.find((el:any)=> el.id === event.value.id);
    console.log("userForm",this.commonForm);
    this.commonForm.patchValue({
      countryName:countryDetail
    })
    if(event?.value?.id && (this.userDetails?.industrialpark_id || this.userDetails?.userlevel_id == 1)){
      this.getIpNameDetails("",event?.value?.id);
      this.ipNames = [];
      this.factoryNames = [];
      this.commonForm.patchValue({
        ipName:[],
        factoryName:[]
      })
    }
  }
  iPChangeHandler(event: MatSelectChange) : void {
    if(event?.value?.id && (this.userDetails?.factory_id || this.userDetails?.userlevel_id == 1)){
      this.getFactoryNameDetails("", event?.value?.id,false);
      this.factoryNames = [];
      this.commonForm.patchValue({
        factoryName:[]
      })
    }
  }
  get f() {
    return this.commonForm.controls;
  }
 
  dropdownActive(){
    let roleId = [1,2,3,7,8];
    this.showCountryDropdown = this.userDetails?.country_id;
    this.showIPdropdown = this.userDetails?.industrialpark_id;
    this.showFactoryDropdown = this.userDetails?.factory_id;
    this.dropdownDataPrefield(this.userDetails?.userlevel_id)

    // if((this.userDetails?.country_id && this.userDetails?.industrialpark_id && this.userDetails?.factory_id)){
    //   this.showCountryDropdown = true;
    //   this.showIPdropdown = true;
    //   this.showFactoryDropdown = true;
    //   this.dropdownDataPrefield(this.userDetails?.userlevel_id)
    // }
    // if(this.userDetails?.country_id && this.userDetails?.industrialpark_id && !this.userDetails?.factory_id){
    //   this.showCountryDropdown = true;
    //   this.showIPdropdown = true;
    //   this.showFactoryDropdown = false;
    //   this.dropdownDataPrefield(this.userDetails?.userlevel_id)
    // }
    // if(this.userDetails?.country_id && !this.userDetails?.industrialpark_id && !this.userDetails?.factory_id){
    //   this.showCountryDropdown = true;
    //   this.showIPdropdown = false;
    //   this.showFactoryDropdown = false;
    //   this.dropdownDataPrefield(this.userDetails?.userlevel_id)
    // }
    if(roleId.includes(this.userDetails?.userlevel_id)){
      this.showCountryDropdown = true;
      this.showIPdropdown = true;
      this.showFactoryDropdown = true;
      this.dropdownDataPrefield(this.userDetails?.userlevel_id)
    }
  }
  createFinancialForm(formName:any){
    const commonFormConfig = (formName == 'Monthly Progress Form') ? 
    { month: ['', Validators.required], financial_year: ['', Validators.required],} : {financial_year: ['', Validators.required]};
    
    this.commonForm = this.formBuilder.group(commonFormConfig);
    
  }

  isCheckMandatory(){
    let isDisable = false;
    let visibleChildQuestion:any = this.formQuestions.filter((question:any) => !question.hide);
    if(visibleChildQuestion.length){
      visibleChildQuestion = visibleChildQuestion.map((question:any) =>  question.short_key);
      visibleChildQuestion.forEach((checkFilled:any) => {
        let hasValue:string = this.commonForm.value[checkFilled];
        if(hasValue === undefined || hasValue === null || hasValue === ''){
        isDisable = true;
        return;
        }
      });
    }
    return this.commonForm.invalid || isDisable;
  }
 
  onYearChange(data:any){
    console.log("datataa",data);
    this.currentSelectedYear = data;
    let dataBody = {selectedYear: data}

    let createData = {
      title: '',
      data: data,
      type: 'yearChange'
    }
    let url = this.listId ? this.createFormURL + 'update/'+this.listId : this.createFormURL + 'create';
    // if(this.formName == 'Program Form'){
      this.commonService.submitFormAnswers(dataBody, url, this.isMonthly).subscribe(res => {
        if(this.currentPagePath == 'baseline_monthly_form'){
          let yearParams = `financial_year=${this.currentSelectedYear}`
          this.getMonthsData(yearParams,'yearChange');
        }
      }, (error) => {
          let objmsg = {
            message: error?.error?.message
          }
          Object.assign(createData, objmsg);
          if(error?.error?.hasOwnProperty('isValid') && !error?.error?.isValid){
            const dialogRefcomp = this.dialog.open(MasterFormPopupComponent, {
              data: createData,
              width: '600px',
              panelClass: 'signUp-dialog-class',
              disableClose: true,
              position: { top: '200px' },
            });
            dialogRefcomp.afterClosed().subscribe((confirmed: boolean) => {
              console.log(this.commonForm);
              this.commonForm.get('financial_year')?.setValue(null);
          this.selectedMonth = [];
          this.commonForm.get('month')?.setValue(null);
            });
          }
      })
    // }

    
  }

  onMonthChange(data:any){
    console.log(data);
    let yearParams = `financial_year=${this.currentSelectedYear}&month=${data}&validation=true`
    if(this.isEditMode){
      yearParams = `${yearParams}&id=${this.currentParams?.id}`
    }
    this.setValidationStatus(yearParams, 'yearChange');
  }



  getFinancialYearsData(){
    this.commonService.getAllYears(this.currentPagePath).subscribe((res:any) => {
      console.log("yearss",res);
      this.financialYears = res?.data;
      let selectedYear = this.financialYears[0]?.year;

      if(this.formName == 'Baseline Form'){
        this.commonForm.patchValue({
          financial_year: selectedYear
         });
      }
      
    })
  }

  setValidationStatus(yaerParams:string = '', type:string = ''){
    this.commonService.getMonths(yaerParams, type).subscribe((res:any) => {
      
    }, (error:any) => {
      console.log("ee",error)
      let createData = {
        title: '',
        data: '',
        type: 'yearChange'
      };
      let objmsg = {
        message: error?.error?.message
      }
      Object.assign(createData, objmsg);
      if(error?.error?.hasOwnProperty('isValid') && !error?.error?.isValid){
        const dialogRefcomp = this.dialog.open(MasterFormPopupComponent, {
          data: createData,
          width: '600px',
          panelClass: 'signUp-dialog-class',
          disableClose: true,
          position: { top: '200px' },
        });
        dialogRefcomp.afterClosed().subscribe((confirmed: boolean) => {
          console.log(this.commonForm);
          this.commonForm.get('financial_year')?.setValue(null);
          this.selectedMonth = [];
          this.commonForm.get('month')?.setValue(null);
        });
      }
    })
  }

  getMonthsData(params?:any, type?:any, preselectedMonth:string = ''){
    this.commonService.getMonths(params, type).subscribe((res:any) => {
      this.selectedMonth = res.data;
      // let currentMonth = this.selectedMonth[0]?.month;
      
      this.commonForm.patchValue({
        month: preselectedMonth ? preselectedMonth : ''
      })
    })
  }
}
