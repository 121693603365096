import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {CommonServiceService} from "../../../pages/services/common-service.service";
import * as L from "leaflet";
import { TileLayerOptions } from "leaflet";
export interface INewTileLayerOptions extends TileLayerOptions {

  id?: string;

}
@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.css']
})

export class LeafletMapComponent implements OnInit,OnChanges {
  userData = JSON.parse(sessionStorage.getItem('userdata')!);

  constructor(private commonService:CommonServiceService) { }
  countryAllData:any = [];
  stateBoundaries:any;
  HEATMAP:any;
  geoJson:any

   ngOnInit() {
     setTimeout(() => {
      this.getCountryData();
     },100)
   
  
  }

  ngOnChanges(changes:SimpleChanges){
    console.log("changes", changes)
    // if(changes){
    //     setTimeout(() => {
    //       this.mapData();
    //  },100)
    // }
  }

  getCountryData(){
    let countryId = this.userData?.country_id;
    this.commonService.countryData(countryId).subscribe((res:any) => {
      this.countryAllData = res.data;
      this.mapData();
      console.log("countyyyy",this.countryAllData);
      
    })
  }

  mapData(){
    this.commonService.getStatesGeoJson().subscribe((res:any) => {
      // console.log("myresp",res);
      this.stateBoundaries = res;
      this.makeMap(this.stateBoundaries);
    })
   

  }
 
  makeMap(statesArr:any, stateName = '', stateCode = '' ){
    
    // console.log("call Map",statesArr);
    const mapContainerId = `mapContainer`;
    let heatmap: any = null;
    let statesList = statesArr;
    // let geoJson, districts, state;
    // if(true){
      document.getElementById(
        mapContainerId
      )!.outerHTML = `<div id='mapContainer' class="bg-white" style="height:350px"></div>`;
      document.getElementById(mapContainerId)!.innerHTML = '';
      this.geoJson = this.stateBoundaries;
      heatmap = L.map(mapContainerId, {
        attributionControl: false,
        zoomControl: false,
        zoomSnap: 1.5,
        minZoom: 1,
        maxZoom: 2,
        doubleClickZoom: false,
        closePopupOnClick: false,
        trackResize: false,
        touchZoom: false,
        scrollWheelZoom: false
      }).setView([4.8, 20], 4);
      L.control.zoom({
        position: 'bottomleft'
    }).addTo(heatmap);
      L.tileLayer(
        'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYXNlZW0zMTIiLCJhIjoiY2phdzIzcW55NXQzbDJxbnFtYXl2eXNnYiJ9.z8_v4MmPVgVFr1V42xEyqA',
        <INewTileLayerOptions>{
          id: 'mapbox.light',
        }
      ).addTo(heatmap);
        const stateLayer = L.geoJSON(this.geoJson)
        .setStyle({
          weight: 0,
          fill: true,
          fillOpacity: 0.9,
          fillColor: 'rgb(236, 236, 236)',
          color: '#485058',
        })
        .addTo(heatmap);
        let countryList = this.countryAllData.map((ele:any) => {
           return ele?.name;
        })
        // console.log("countryList",countryList);
        let object:any = {'Kenya' : 23,'Ethiopia':34,'Vietnam':56}
        heatmap.eachLayer((layer:any)=>{
          // console.log("layer",layer);

             if(layer['feature']){
              let count = object[layer.feature.properties.name] ? object[layer.feature.properties.name] : 0;
              if(countryList.includes(layer.feature.properties.name) || (layer.feature.properties.hasOwnProperty('gu_a3') && countryList.includes(layer.feature.properties.gu_a3))){
                layer.setStyle({
                  fillColor: this.getColor(layer.feature.properties.name),
                  // onEachFeature: this.onEachFeature(layer.feature, layer)
                })
              }
              //  if(layer.feature.properties.name == 'Kenya'){
              //   layer.setStyle({
              //     fillColor: 'rgb(71,217,133)',
              //     // onEachFeature: this.onEachFeature(layer.feature, layer)
              //   })
              //  }
              //  if(layer.feature.properties.name == 'Ethiopia'){
              //   layer.setStyle({
              //     fillColor: 'rgb(254,237,118)',
              //     // onEachFeature: this.onEachFeature(layer.feature, layer)
              //   })
              //  }
              //  if(layer.feature.properties.name == 'Vietnam'){
              //   layer.setStyle({
              //     fillColor: 'rgb(255,110,185)',
              //     // onEachFeature: this.onEachFeature(layer.feature, layer)
              //   })
              //  }
             

             layer.bindTooltip("<div style='background:white; padding:1px 3px 1px 3px'><b>" + layer.feature.properties.name + "</b></div>", 
{
    direction: 'right',
    permanent: false,
    sticky: true,
    offset: [10, 0],
    opacity: 0.75,
    className: 'leaflet-tooltip-own' 
});
             }

             
             
            //  layer.on({
            //   mouseout: (e:any) => {
            //     // layer._path.classList.remove('float-boundary')
            //     this.onEachFeature(layer.feature, layer);
            //         },
            //   mouseover: () => {
            //     if(layer.feature.properties.name == 'Kenya'){
            //       // layer._path.classList.add('float-boundary')
            //       this.onEachFeature(layer.feature, layer);
            //     }
            //   }
            //  })
        });

        
          
      heatmap.fitBounds(stateLayer.getBounds());
      // }
      this.HEATMAP = heatmap;
    // this.mapViewModeHandler(statesList, heatmap, this.selectedLayer);
  }

  getColor(countryName:string){
    // if(data < 30)
    // return 'rgb(254,237,118)'
    // if(data < 23)
    // return 'rgb(254,237,118)'
    return '#00855A';
    if(countryName.includes('Ethiopia')){
      return '#00855A'
    }
    if(countryName.includes('Kenya')){
      return '#FED766'
    }
    if(countryName.includes('India')){
      return '#4682B4'
    }
    return '#d62728'
  }

  highlightFeature(e:any) {
    var layer = e.target;

    layer.setStyle({
        weight: 5,
        color: '#666',
        dashArray: '',
        fillOpacity: 0.7
    });

    layer.bringToFront();
  }

  resetHighlight(e:any) {
    this.geoJson.resetStyle(e.target);
  }

  onEachFeature(feature:any, layer:any) {
    layer.on({
        mouseover: this.highlightFeature(layer),
        mouseout: this.resetHighlight(layer),
    });
  }

}
