import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from '../services/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-related-stories',
  templateUrl: './related-stories.component.html',
  styleUrls: ['./related-stories.component.scss']
})
export class RelatedStoriesComponent implements OnInit {
  relatedStories: any = [];
  currentRoute: string = "";
  url: any;
  constructor(private service : CommonServiceService,private route: ActivatedRoute, private router: Router, public sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.getImpactData();
    this.currentRoute = this.router.url;
    console.log("urlll",this.currentRoute);
    
  }
  
  
  impactStoriesData: any;

getImpactData(){
  this.service.impactStoriesData().subscribe((res)=>{
    this.relatedStories = res?.data;
    this.route.queryParams
        .subscribe((params:any) => {
          this.impactStoriesData = res?.data.filter((el:any)=> el.id == params.id);
          console.log("all data",this.impactStoriesData);
          this.relatedStories = res?.data.filter((el:any)=> el?.id != params.id)
          
          if(!this.impactStoriesData[0].link.split('/').includes('live')){
            let myId:any = this.getId(this.impactStoriesData[0].link);
            if(myId !== 'error'){
              this.impactStoriesData[0].link =  'https://www.youtube.com/embed/'+myId ;
            console.log("err",myId,this.impactStoriesData[0].link)
            }
            
          }
          else if(this.impactStoriesData[0].link.split('/').includes('live')){
            if(!this.impactStoriesData[0].link.split('/').includes('embed')){
              this.impactStoriesData[0].link = this.impactStoriesData[0].link.replace('/live/', '/embed/')
            }
            else{
              this.impactStoriesData[0].link = this.impactStoriesData[0].link.replace('/live/', '/')
            }
          }
          // console.log("err",myId,this.impactStoriesData[0].link)
          // if(myId == 'error'){
          //   let link = this.impactStoriesData[0].link.split('.be/')
          //   console.log("link",link)
          //   this.impactStoriesData[0].link = 'https://www.youtube.com/embed/'+link[1];
          // }
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.impactStoriesData[0].link);

        }
      );
  })
}
getId(url:string) {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  // const regExp = /^(?:.*(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)|.*(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)))?([^#\&\?]*).*/

  var match = url.match(regExp);

  if (match && match[2].length == 11) {
      return match[2];
  } 
  else {
      return 'error';
  }
}
stripHtmlTags(input: any): string {
  const doc: any = new DOMParser().parseFromString(input, 'text/html');
  const images = doc.querySelectorAll('img');

  // Check if there is text content in the body
  const textContent = doc.body.textContent || "";

  // Truncate text content to a limit of 500 characters
  const truncatedText = textContent.length > 500 ? textContent.substring(0, 500) + '...' : textContent;

  // Clone the original body without modifying it
  const clonedBody = doc.body.cloneNode(true);

  // Clear the original body content
  doc.body.innerHTML = '';

  // Append the truncated text content to the modified body
  
  doc.body.appendChild(document.createTextNode(textContent));

  // Append the original images back to the modified body
  images.forEach((image: any) => {
    const clonedImage = image.cloneNode(true);
    clonedImage.classList.add('img-fluid');
    clonedImage.classList.add('mt-5'); // Add your desired class here
    doc.body.appendChild(clonedImage);
  });

  // Create a new container element
  const container = document.createElement('div');

  // Append the modified content to the container
  container.appendChild(doc.body);

  // Get the HTML content of the container
  const result = container.innerHTML;

  return result;
}
stripHtmlTagsForCard(input: any,limit:number): string {
  const doc: any = new DOMParser().parseFromString(input, 'text/html');

  // Extract plain text content without HTML tags
  const textContent = doc.body.textContent || "";

  // Truncate text content to a limit of 150 characters
  let truncatedText :string= ''
  if(limit > 0){
    truncatedText =  textContent.length > limit ? textContent.substring(0, limit) + '...' : textContent;
  }
  else{
    truncatedText = textContent
  }

  return truncatedText;
}
}
