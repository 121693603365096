import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactDialogComponent } from 'src/app/pages/contact-dialog/contact-dialog.component';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';

@Component({
  selector: 'app-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.scss']
})
export class ComposeMessageComponent implements OnInit, OnChanges {

  selectedIndex: number = 1;
  payloadFilters: any = "";
  dialogRef: any;
  public limit = 10;
  public skip = 0;
  public page: any = 1;
  allResponseData:any = [];
  totalDataCount:number = 0;
  searchedValue:string = "";
  tableHead:any = [{thead : 'S.No.'},{thead : 'Admins',key:'admin'},{thead : 'Contact'}];
  sortSelection: string = "";
  selectedColumn: string = "";
  selectedTab: any = "none";
  currentSeletedSubTab:string = "";
  tokenCheck: string | null;
  // permissionDetails: any = JSON.parse(sessionStorage.getItem('userPermission') || null);
  orderSorting: string = "";
  labelName: string = "Admin";
  @Input() selectedMatIndex:number = 0;
  userData:any = JSON.parse(sessionStorage.getItem("userdata")|| '{}');


  constructor(private commonService: CommonServiceService, private snackBar:MatSnackBar,public dialog: MatDialog,
    private formBuilder: FormBuilder) {
    this.tokenCheck = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';

     }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes:SimpleChanges){
    // console.log("chaa",changes);
    if(changes && changes?.selectedMatIndex && changes?.selectedMatIndex?.currentValue){
    console.log("selectedMatIndex", this.selectedMatIndex);
    this.selectedIndex = 0;
      this.getAdmin(1);
    }
  }

  commonSwitch(labelName:any, searchedValue:any = ""){
    switch (labelName) {
      case "Admin":
        this.tableHead = [{thead : 'S.No.'},{thead : 'Admins',key:'admin'},{thead : 'Contact'}];
        this.getAdmin(this.page, searchedValue, '', this.orderSorting,this.selectedColumn);
      break;
      case "Assessor":
        this.tableHead = [{thead : 'S.No.'},{thead : 'Accessor',key:'accessor'},{thead : 'Contact'}];
        this.getAdmin(this.page, searchedValue, '', this.orderSorting,this.selectedColumn);
        break;
      case "IP List":
        this.tableHead = [{thead : 'S.No.'},{thead : 'IP Name',key:'name'},{thead : 'Country Name',key:'country.name'},{thead : 'Contact'}];
        this.getIPlist(this.page, searchedValue, '', this.orderSorting,this.selectedColumn);
        break;
      case "Factory List":
        this.tableHead = [{thead : 'S.No.'},{thead : 'Factory Name',key:'name'},{thead : 'IP Name',key:'industrialpark.name'},{thead : 'Country Name',key:'industrialpark.country.name'},{thead : 'Contact'}];
        this.getFactoryListData(this.page, searchedValue, this.orderSorting,this.selectedColumn);
        break;
    
      default:
        break;
    }
  }

  tabWiseData(event:any){
    this.selectedIndex = event?.index;
    this.labelName = event?.tab?.textLabel;
    console.log(event);
    this.orderSorting = "";
    this.searchedValue = "";
    this.selectedColumn = "";
    this.commonSwitch(this.labelName)
  }

  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // panelClass: parmary
  });
  }

  // isShowContact(selectedTabName: string): boolean {
  //   let model = {'IP list': 'ip','Factory list': 'factory'};
  //   let endPoint:string = model[this.currentSeletedSubTab];
  //   // console.log("sdghsj",!!this.permissionDetails)
  //   if (!this.permissionDetails?.length) {
  //     return false;
  //   }
  
  //   const moduleJson = this.permissionDetails[0]?.authenicationJson.find((el: any) => el?.model === selectedTabName);
  
  //   if (!moduleJson) {
  //     return false;
  //   }
  
  //   // console.log("module", moduleJson, (moduleJson?.isAction || moduleJson?.is_checked || moduleJson?.authenication?.some((el: any) => el?.isAction)));
  
  //   return moduleJson?.authentication?.some((el: any) => el?.isAction && el.endpoint == endPoint);
  // }

  openContactDialog(item:any) {
    console.log(item);
    
  this.dialogRef =  this.dialog.open(ContactDialogComponent, {
      width: '500px',
      panelClass: 'contactDialog',
      data: {
        id: item.id ? item.id : 1,
        lineItemName: item.name,
        formType: this.labelName
      },
    });
  
    this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log("after")
    })
  }

  sortTable(columnName: string, sortType:string = '') {
    this.sortSelection = sortType;
    this.selectedColumn = columnName;
    this.orderSorting = sortType === 'down' ? 'DESC' : 'ASC';
    this.commonSwitch(this.labelName);
    // if ('IP list' == this.currentSeletedSubTab) {
    //   this.getIPlist(this.page, '', '', this.orderSorting,columnName);
    // }
    // else if ('Factory list' == this.currentSeletedSubTab){
    //   this.getFactoryListData(this.page, '', this.orderSorting,columnName);
    // }
  }

  onKeyDownEvent() { 
    if(this.searchedValue){
      this.commonSwitch(this.labelName, this.searchedValue);
      // if(this.selectedIndex == 3 && this.selectedTab == 'countryview'){
      //   this.getIPlist(this.page, this.searchedValue);
      // }
  
      // if((this.selectedIndex == 4 && this.selectedTab == 'countryview') || (this.selectedIndex == 3 && this.selectedTab == 'ipview')){
      //   this.getFactoryListData(this.page, this.searchedValue);
      // }
    }else{
      this.openSnackBar(['Please enter any value']);
    }
  }

  clearSearch() {
    this.searchedValue = "";
    switch (this.labelName) {
      case "Admin":
        this.tableHead = [{thead : 'S.No.'},{thead : 'Admins',key:'admin'},{thead : 'Contact'}];
        this.getAdmin(this.page, "", '', this.orderSorting, this.selectedColumn);
        break;
      case "Assessor":
        this.tableHead = [{thead : 'S.No.'},{thead : 'Accessor',key:'accessor'},{thead : 'Contact'}];  
        this.getAdmin(this.page, "", '', this.orderSorting, this.selectedColumn);
        break;
      case "IP List":
        this.tableHead = [{ thead: 'S.No.' }, { thead: 'IP Name', key: 'name' }, { thead: 'Country Name', key: 'country.name' }, { thead: 'Contact' }];
        this.getIPlist(this.page, "", '', this.orderSorting, this.selectedColumn);
        break;
      case "Factory List":
        this.tableHead = [{ thead: 'S.No.' }, { thead: 'Factory Name', key: 'name' }, { thead: 'IP Name', key: 'industrialpark.name' }, { thead: 'Country Name', key: 'industrialpark.country.name' }, { thead: 'Contact' }];
        this.getFactoryListData(this.page, "", this.orderSorting, this.selectedColumn);
        break;
    
      default:
        break;
    }
    
  }



  setPage(event: any){
    // this.selectedColumn = "";
    this.page = event.pageIndex + 1;
    this.skip = (event.pageIndex + 1) * this.limit - this.limit;
    console.log('limit',event.pageSize,event, this.skip)
    this.limit = event.pageSize;
    this.commonSwitch(this.labelName);
    // switch (this.selectedIndex) {
    //   case 0:
    //     this.getAdmin(this.page, '', '', this.orderSorting,this.selectedColumn);
    //   break;

    //   case 1:
    //     this.getAdmin(this.page, '', '', this.orderSorting,this.selectedColumn);
    //   break;

    //   case 2:
    //     this.getIPlist(this.page, '', '', this.orderSorting,this.selectedColumn);
    //   break;

    //   case 3:
    //     this.getFactoryListData(this.page, '', this.orderSorting,this.selectedColumn);
    //   break;
    
    //   default:
    //     break;
    // }

    // if(this.selectedIndex == 2){
    //   this.getIPlist(event.pageIndex + 1, '', '', this.orderSorting,this.selectedColumn);
    // }else if((this.selectedIndex == 4 && this.selectedTab == 'countryview') || (this.selectedIndex == 3 && this.selectedTab == 'ipview')){
    //   this.getFactoryListData(event.pageIndex + 1, '', this.orderSorting,this.selectedColumn);
    // }

    //event.pageIndex + 1
  }

  getAdmin(page:any, searchedValue:string = '', id:any = "",sortingOrder:string='',culomnName:string=''){
    this.commonService.allAdminMenbers(this.labelName, page, searchedValue, id, sortingOrder, culomnName).subscribe((res:any) => {
      console.log("adminRes",res);
      if(res?.success){
        this.allResponseData = res.data.filter((ele:any) => ele.id != this.userData?.id);
        this.totalDataCount = res.total;
      }
    }, error => {
      console.log(error);
      this.allResponseData = [];
    }
    )
  }


  getIPlist(page:any, searchedValue:string = '', id:any = "",sortingOrder:string='',culomnName:string=''){
    console.log("ipDashboard", this.payloadFilters)
    id = this.payloadFilters?.countryId?.length ? this.payloadFilters.countryId.join(',') : '';
    this.commonService.ipDashboardDetails(page, searchedValue, id,sortingOrder,culomnName).subscribe((res:any) => {
      console.log("sappp",res);
      if(res.success){
        this.allResponseData = res.data;
        this.totalDataCount = res.total;
        console.log("allIPListData",this.allResponseData);
      }
    }, error => {
      console.log(error)
      this.allResponseData = [];
    } 
    )
  }

  getFactoryListData(page:any, searchedValue = '',sortingOrder:string='',columnName:string = ''){
    let countryId:string = this.payloadFilters?.countryId?.length ? this.payloadFilters?.countryId.join(',') : '';
    let ipId:string = this.payloadFilters?.ipViewId?.length ? this.payloadFilters?.ipViewId.join(',') : '';
    this.commonService.factoryDashboardDetails(page, searchedValue,countryId,ipId,sortingOrder,columnName).subscribe((res:any) => {
      console.log("tappp",res);

      if(res?.success){
        this.allResponseData = res.data;
        this.totalDataCount = res.total;
        console.log("allIPListData",this.allResponseData);
      }
    },error => {
      console.log(error);
      this.allResponseData = [];
    }
      )
  }

}
