<div>
    <button class="btn d-flex align-items-center filterBtn " mat-button [matMenuTriggerFor]="menu"  #t="matMenuTrigger">
        <img [src]="'/assets/dashboardMedia/calender-icon.svg'" alt="icon.svg" style="width: 17px"> Date Filter
    </button>
    <div class="table-responsive table-bordered" (click)="$event.stopPropagation()">
        <mat-menu #menu="matMenu" class="custom-menu-class check">
            <div class="container">
                <form [formGroup]="filter_form">
                  <mat-button-toggle-group name="fontStyle" class="border-0" aria-label="Font Style" (click)="$event.stopPropagation()">
                    <!-- <mat-button-toggle [ngClass]="{'year_tabs': day =='today'}" class="rounded-pill border me-2" value="Today" (click)="setToday('today')">Today</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{'year_tabs': day =='week'}" class="rounded-pill border me-2" value="This Week" (click)="setWeek('week')">This Week</mat-button-toggle> -->
                    <mat-button-toggle [ngClass]="{'year_tabs': day =='month'}" class="rounded-pill border me-2" value="Monthly" (click)="setMonth('month')">Monthly</mat-button-toggle>
                    <mat-button-toggle [ngClass]="{'year_tabs': day =='year'}" class="rounded-pill border me-2" value="Yearly" (click)="setYear('year')">Yearly</mat-button-toggle>
                  </mat-button-toggle-group>
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="pb-0 col-12 col-sm-auto pr-sm-2 pr-0 mb-sm-0 mb-2">
                        <div class="position-relative">
                          <input class="form-control" (click)="$event.stopPropagation()"  matInput  [matDatepicker]="dp" placeholder="From Date" [formControl]="from" [min]="someDate"  readonly/>
                          <mat-datepicker-toggle class="position-absolute fromInput" matSuffix [for]="dp"></mat-datepicker-toggle>
                        </div>
                        <mat-datepicker (closed)="datePickerEventHandler('from')"   (monthSelected)="onMonthSelected($event, dp, 'from')"  (yearSelected)="day == 'year' ? onYearSelected($event, dp, 'from') : null"  #dp startView='multi-year'></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="outline" class="pb-0 col-12 col-sm-auto pr-sm-2 pr-0 mb-sm-0 mb-2">
                      <div class="position-relative">
                        <input
                        class="form-control"
                        (click)="$event.stopPropagation()"
                          matInput
                          [matDatepicker]="dp1"
                          placeholder="End Date"
                          [formControl]="to"
                          [min]="from.value"
                          readonly
                        />
                        <mat-datepicker-toggle class="position-absolute toInput" matSuffix [for]="dp1"></mat-datepicker-toggle>
                      </div>
                        <mat-datepicker (closed)="datePickerEventHandler('to')"  (monthSelected)="onMonthSelected($event, dp1, 'to')" (yearSelected)="day == 'year' ? onYearSelected($event, dp1, 'to') : null" #dp1 startView='multi-year'></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="text-end" *ngIf="filter_form?.value?.from && filter_form?.value?.to">
                    <button class="btn btn-reset rounded-pill" (click)="resetFilter()"><span>Reset</span></button
                      >
                      <button  class="btn btn-submit rounded-pill" (click)="formSubmit()"><span>Submit</span></button>
                  </div>
                </form>
              </div>
              
        </mat-menu>
    </div>
