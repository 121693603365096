<div class="Footer_bottom">
  <section>
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <svg class="me-2 pointer" (click)="backToForm()" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
          </svg>
          
        <nav aria-label="breadcrumb" class="my-3">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToIpsa()">IPSA</li>
            <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToForm()">IPSA V1.1 Forms</li>
            <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">{{showFormName}}</li>
          </ol>
        </nav>
      </div>
      <div class="row">
          <div class="col-12">
              <div class="d-md-flex justify-content-end mb-3">
                  <div class="d-flex align-items-center">
                        <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']" >
                            <ng-container *ngIf="buttonPermission?.key && buttonPermission?.key === 'csv' && buttonPermission?.isAction" >
                                <img src="../../../assets/images/download-form.svg" class="img-fluid pointer me-4 d-none" alt="" [matTooltip]="'Download CSV'" (click)="downloadCSV()">
                            </ng-container>
                        </ng-container>
                        <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                            <ng-container *ngIf="buttonPermission?.key && buttonPermission?.key === 'createui' && buttonPermission?.isAction">
                                <button class="btn rounded-pill text-white mx-4" style="background-color: #00625F;" (click)="openForm()">+ New Form</button>
                            </ng-container>
                        </ng-container> -->
                  </div>
              </div>
          </div>
      </div>
  <div class="col-sm-12">
    <mat-tab-group class="mat_text" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
        <ng-container *ngFor="let item of formListData">
        <mat-tab  [label]="item.title" *ngIf="isTabAllowed(item)">
            <!-- <div> -->
                
                <div>
                    <div class="card border-0 mb-3">
                        <div class="card-body">
                            <div class="mb-3 align-items-center">
                                <mat-paginator class="f-14" [length]="totalDataCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalDataCount !== 0"></mat-paginator>
                                <div class="position-relative" style="display: none">
                                    <img src="../../../assets/images/search-line.svg"
                                        class="img-fluid position-absolute search-icon" alt="">
                                    <input type="search" placeholder="Search by" class="form-control search-input">
                                </div>
                                
                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                    <button class="btn rounded-pill text-white" style="background-color: #00625F;" (click)="viewFormStructure(item?._id)" *ngIf="buttonPermission?.key && buttonPermission?.key === 'createui' && buttonPermission?.isAction">+ Add New Form</button>
                                    <!-- <svg *ngIf="buttonPermission?.key && buttonPermission?.key === 'createui' && buttonPermission?.isAction" xmlns="http://www.w3.org/2000/svg" matTooltip="Fill Form" (click)="viewFormStructure(item?._id)" class="me-2 pointer" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M14 2H6c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h7.81c-.53-.91-.81-1.95-.81-3c0-.33.03-.67.08-1H6v-2h7.81c.46-.8 1.1-1.5 1.87-2H6v-2h12v1.08c.33-.05.67-.08 1-.08s.67.03 1 .08V8zm-1 7V3.5L18.5 9zm5 6v3h-3v2h3v3h2v-3h3v-2h-3v-3z"/></svg> -->
                                    <span class="editForm">
                                        <img src="/assets/images/settingIcon-idh.svg" *ngIf="buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction" matTooltip="Config Form" (click)="editFormStructure(item?._id)" class="img-fluid border p-1 rounded-3 pointer"/>
                                    </span>
                                </ng-container>
                                  
                            
                            </div>
                           
                            <div class="table-responsive" *ngIf="formListingData?.length">
                                <table class="table table-borderless">
                                    <thead class="table-light">
                                        <tr>
                                            <th>S.No.</th>
                                            <th class="fw-bold" *ngFor="let item of resourceCenter">
                                              {{item?.label}}
                                            </th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white">
      
                                        <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                            <ng-container *ngIf="buttonPermission?.key && buttonPermission?.key === 'list' && buttonPermission?.isAction"> -->
                                                <tr *ngFor="let item of formDataListing; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <!-- <td>{{item?.name}}</td> -->
                                                   <ng-container *ngFor="let headResource of resourceHead">
                                                       <td><ng-container *ngIf="!item['data'][headResource]">--</ng-container><ng-container *ngIf="item['data'][headResource]">{{item['data'][headResource]}}</ng-container></td>
                                                   </ng-container>
                                                    <!-- <td>{{item?.form}} ## {{item?.created | date: 'dd/MM/yyyy'}}</td> -->
                                                    <td>
                                                        <div class="formStatus" [ngStyle]="{'background-color': item?.isDraft ? '#ffa700d9' : '#47d985'}">
                                                            <span class="status">{{item?.isDraft ? "Draft" : "Submitted"}}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <i class="img-fluid me-2 pointer fa fa-eye fas" alt="" (click)="editForm('viewMode',item)" matTooltip="View Data" ></i>
                                                      <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                      <img *ngIf="buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction" src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="editForm('editMode',item)" matTooltip="Edit Data" >
                                                      
                                                      </ng-container>
                                                    </td>
                                                </tr>
                                            <!-- </ng-container>
                                        </ng-container> -->
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="noDataFound" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </mat-tab>
    </ng-container>
      </mat-tab-group>
  </div>
      </div>
  </section>
</div>