<div class="container">
    <h2 class="fs-20 my-3 fw-600 text-center">Reset your password.</h2>
    <div class="row align-items-center">
        <div class="col-md-6">
            <div class="text-center">
                <img src="../../../assets/images/Reset-password-illus.svg" class="img-fluid mb-3">
            </div>
        </div>
        <div class="col-md-6">
            <form action="" > 
                <!-- <div class="form-floating mb-3">
                    <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center" type="button" (click)="showPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                src="{{show ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}"
                                class="img-fluid a" alt="">
                        </button>
                    </div>
                    <input type="password" [type]="!this.show ? 'password' : 'text'" class="form-control rounded-pill"
                        id="floatingPassword" placeholder="Password" [(ngModel)]="newPassword" (input)="passwordMethod()">
                    <label for="floatingPassword">New password *</label>
                    <span class="invalid-feedback mx-3 " *ngIf="isNewPassword">
                        Password is required *.
                    </span>
                    <span class="invalid-feedback mx-3 mb-0" *ngIf="!isStrongPassword && newPassword?.length">
                        Password must have at least one number, one special character, one uppercase letter, one lowercase
                        letter, and be at least 8 characters long.
                    </span>
                </div> -->

                <!-- <div class="position-relative">
                    <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center pointerHead" type="button" (click)="showPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                [src]="show ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'"
                                class="img-fluid a" alt="">
                        </button>
                    </div>
                    
                    <mat-form-field appearance="outline" class="example-full-width w-100">
                        <mat-label>New password  *</mat-label>
                        <input [type]="!this.show ? 'password' : 'text'" placeholder="New password " matInput [(ngModel)]="newPassword" (input)="passwordMethod()" autocomplete="off" [ngModelOptions]="{standalone: true}" >
                    </mat-form-field>
                    <span class="invalid-feedback mx-3 mb-0" *ngIf="isNewPassword">
                        Password is required *.
                    </span>
                    <span class="invalid-feedback mx-3 mb-0" *ngIf="!isStrongPassword && newPassword?.length">
                        Password must have at least one number, one special character, one uppercase letter, one lowercase
                        letter, and be at least 8 characters long.
                    </span>
                </div> -->
        
        
                <!-- <div class="form-floating mb-3">
                    <input type="password" [type]="!this.showSecondPass ? 'password' : 'text'" class="form-control rounded-pill"
                        id="floatingPassword" placeholder="Password" [(ngModel)]="confPassword" (input)="passwordMatchMethod()">
                    <label for="floatingPassword">Confirm password *</label>
                    <span class="invalid-feedback mx-3 mb-0" *ngIf="isConformPassword">
                        Passwords do not match.
                    </span>
                    <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center" type="button" (click)="showConfirmPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                src="{{showSecondPass ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}"
                                class="img-fluid" alt="">
                        </button>
                    </div>
                </div> -->

                <!-- <div class="position-relative">
                    <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center pointerHead" type="button" (click)="showConfirmPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                [src]="showSecondPass ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'"
                                class="img-fluid" alt="">
                        </button>
                    </div>
                    
                    <mat-form-field appearance="outline" class="example-full-width w-100">
                        <mat-label>Confirm Password *</mat-label>
                        <input [type]="!this.showSecondPass ? 'password' : 'text'" placeholder="Password" matInput [(ngModel)]="confPassword" (input)="passwordMatchMethod()" name="somethingAutofillDoesntKnow" autocomplete="doNotAutoComplete"  [ngModelOptions]="{standalone: true}" >
                    </mat-form-field>
                    <span class="invalid-feedback mx-3 mb-0" *ngIf="isConformPassword">
                        Passwords do not match.
                    </span>
                </div> -->

                <div class="position-relative">
                    <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center pointerHead" type="button" (click)="showPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                [src]="show ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'"
                                class="img-fluid a" alt="">
                        </button>
                    </div>
                    
                    <mat-form-field appearance="outline" class="example-full-width w-100">
                        <mat-label>New password *</mat-label>
                        <input [type]="!show ? 'password' : 'text'" placeholder="New password" matInput [(ngModel)]="newPassword" (input)="passwordMethod()" 
                        name="somethingAutofillDoesntKnow" autocomplete="doNotAutoComplete" 
                         [ngModelOptions]="{standalone: true}" >
                    </mat-form-field>
                    <span class="invalid-feedback mx-2 f-12" *ngIf="isNewPassword">
                        Password is required *.
                    </span>
                    <span class="invalid-feedback mx-2 f-12" *ngIf="!isStrongPassword && newPassword?.length">
                        Password must have at least one number, one special character, one uppercase letter, one lowercase
                        letter, and be at least 8 characters long except white space.
                    </span>
                </div>
                
                <div class="position-relative">
                    <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center pointerHead" type="button" (click)="showConfirmPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                [src]="showSecondPass ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'"
                                class="img-fluid" alt="">
                        </button>
                    </div>
                    
                    <mat-form-field appearance="outline" class="example-full-width w-100">
                        <mat-label>Confirm Password *</mat-label>
                        <input [type]="!showSecondPass ? 'password' : 'text'" placeholder="Confirm password" matInput [(ngModel)]="confPassword"
                        name="somethingAutofillDoesntKnow" autocomplete="doNotAutoComplete" 
                        (input)="passwordMatchMethod()"  [ngModelOptions]="{standalone: true}" >
                    </mat-form-field>
                    <span class="invalid-feedback mx-2 f-12" *ngIf="isConformPassword">
                        Password mismatch.
                    </span>
                </div>
                
        
                <div class="text-center">
                    <button class="btn text-white rounded-pill" type="button" style="background-color: #00625F; height: 48px; width: 150px;"
                        (click)="resetPassword()"
                        [disabled]="!(newPassword?.length && confPassword?.length && (confPassword?.length == newPassword?.length) && (confPassword == newPassword) && isStrongPassword)">Reset
                        Password</button>
                </div>
            </form>
        </div>
    </div>
</div>