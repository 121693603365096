<!-- <section class="main-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                    <h2 class="heading text-center">Program Form</h2>
            </div>
        </div>
    </div>
</section>

<section class="">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-6">
                 <div class="card form-card mb-3 border-0">
                    <div class="card-body">
                        <form action="">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <select class="form-select rounded-pill" id="month" [(ngModel)]="selectedMonth">
                                            <option [value]="item.value" *ngFor="let item of months">{{item.month}}</option>
                                        </select>
                                        <label for="month">Select Month</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <select class="form-select rounded-pill" id="year" [(ngModel)]="selectedYear">
                                            <option [value]="item.value" *ngFor="let item of years">{{item?.year}}</option>
                                        </select>
                                        <label for="year">Select Year</label>
                                    </div>
                                </div>
                            </div>
                            
                                <div class="mb-3">
                                <div class="form-title fw-600 mb-2">How many countries have facilities/industrial parks participating in INSTEP?</div>
                                <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [(ngModel)]="countries_with_ip" (keypress)="numberOnly($event)">
                            </div>
                            <div class="mb-3">
                                <div class="form-title fw-600 mb-2">How many industrial parks are participating in projects under the INSTEP program?</div>
                                <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [(ngModel)]="industrial_parks_in_projects" (keypress)="numberOnly($event)">
                            </div>

                            <div class="mb-3">
                                <div class="form-title fw-600 mb-2">How many t&a factories are located in industrial parks that participate the INSTEP program?</div>
                                <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [(ngModel)]="ta_factories_in_ips" (keypress)="numberOnly($event)">
                            </div>

                            <div class="mb-3">
                                <div class="form-title fw-600 mb-2">How many male workers are part of the INSTEP program?</div>
                                <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [(ngModel)]="male_workers_in_instep" (keypress)="numberOnly($event)">
                            </div>

                            <div class="mb-3">
                                <div class="form-title fw-600 mb-2">How many female workers are part of the INSTEP program?</div>
                                <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [(ngModel)]="female_workers_in_instep" (keypress)="numberOnly($event)">
                            </div>

                            <div class="mb-3">
                                <div class="form-title fw-600 mb-2">How many implementing partners have contracts with idh in the INSTEP program to implement projects at industrial parks/factories?</div>
                                <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [(ngModel)]="implementing_partners_with_idh" (keypress)="numberOnly($event)">
                            </div>

                            <div class="mb-3">
                                <div class="form-title fw-600 mb-2">How many multi-stakeholder agreements have been signed under INSTEP program?</div>
                                <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [(ngModel)]="multistakeholder_agreements" (keypress)="numberOnly($event)">
                            </div>

                            <div class="text-end mt-4">
                                <button class="btn submit-btn rounded-pill text-white" (click)="submitForm()">Submit</button>
                            </div>
                        </form>
                    </div>
                 </div>
            </div>
        </div>
    </div>
</section> -->

<div class="container-fluid">
	<div class="row">
		<div class="col-12">
			<nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-2 align-items-center">
                    <li>
                        <svg class="me-2 pointer" [routerLink]="[routeURL]"  id="keyboard_backspace_black_24dp_3_" (click)="backPath()" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                            <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
                          </svg>
                    </li>
                    <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="openFormList()">Forms</li>
                        <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backPath()">Program Form</li>
                        <li class="breadcrumb-item fs-18 fw-600 pointer active" aria-current="page">Overview</li>
                </ol>
            </nav>
		</div>
    </div>
    <!-- <div class="row mt-3" style="padding-bottom: 100px;">
        <div class="col-md-3">
            <mat-card>
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item" *ngFor="let item of formQuestions; let i = index">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne' + item.id"  aria-expanded="true" [attr.aria-controls]="'#collapseOne' + item.id">
                                {{item?.theme_name}}
                            </button>
                        </h2>
                        <div [id]="'collapseOne' + item.id" class="accordion-show show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <mat-vertical-stepper fxLayout="column" [linear]="true" #stepper>
                                    <mat-step *ngFor="let data of item.subSteppers">
                                        <form>
                                            <ng-template matStepLabel>{{data?.stepperName}}</ng-template>
                                        </form>
                                    </mat-step>
                                </mat-vertical-stepper>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="col-md-9">
            <div class="card main-card border-0 mb-3">
                <div class="card-body">
                    <div class="fs-14 fw-600 color-44 mb-3" style="padding-left: 20px;">Program Form / <span class="fw-bold">Overview</span>
                    </div>
                    <form [formGroup]="programForm" (ngSubmit)="submitProgramDataForm()">
                        <ng-container class="monthYear">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <select class="form-select rounded-pill" id="month" formControlName="month">
                                            <option [value]="item.value" *ngFor="let item of months">{{item.month}}</option>
                                        </select>
                                        <label for="month">Select Month</label>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-floating mb-3">
                                        <select class="form-select rounded-pill" id="year" formControlName="financial_year">
                                            <option [value]="item.value" *ngFor="let item of years">{{item?.year}}</option>
                                        </select>
                                        <label for="year">Select Year</label>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="accordion my-2" id="programFormAccordian">
                            <div class="accordion-item mb-2" *ngFor="let item of formQuestions; let i = index">
                                <h2 class="accordion-header" id="programFormIndicator">
                                    <button class="accordion-button accordion-bg collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseTwo' + item.id"  aria-expanded="true" [attr.aria-controls]="'#collapseTwo' + item.id">
                                        {{item.name}}
                                    </button>
                                </h2>
                                <div [id]="'collapseTwo' + item.id" class="accordion-show show" aria-labelledby="programFormIndicator" data-bs-parent="#programFormAccordian">
                                    <div class="accordion-body">
                                        <div class="formQuestions">
                                            {{item?.title}}
                                        </div>
                                        <div class="formInputField mt-2">
                                        <input type="text" class="form-control form-input rounded-pill" placeholder="Enter Number" [formControlName]="item.short_key" (keypress)="numberOnly($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-footer bg-white text-end border-0">
                    <button class="btn rounded-pill save-btn me-3 mb-4" (click)="submitProgramDataForm('is_draft')" [disabled]="programForm.invalid">Save as draft</button>
                    <button class="btn rounded-pill submit-btn mb-4" (click)="submitProgramDataForm()" [disabled]="programForm.invalid">Submit</button>
                </div>
            </div>
        </div>
    </div> -->
</div>


    <app-common-form [url]="baseUrl" [formId]="viewFormId" [formQuestions]="formQuestions" [createFormURL]="createForm" [prefillForm]="prefillForm" [updateForm]="updateForm"></app-common-form>

  