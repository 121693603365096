import { Component, ElementRef, HostListener, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router,NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { SignUpComponent } from 'src/app/pages/sign-up/sign-up.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserManagementServiceService } from 'src/app/admin/user-management/user-management-service.service';
import {environment} from "../../../../src/environments/environment";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  isUserLoggedIn: boolean = false;
  isClicked = false;
  hideSidebar: boolean = false;

  isLoggedin:boolean = false;
  activeBtn = 'home';
  userData: any;
  rightMenuBar:any = [
    {state:'/home', label:'Home',icon:'',isActive:true,isChecked:true},
    {state:'/admin-forms', label:'Forms',icon:'',model:['baselineform','programform'],isActive:false,isChecked:false},
    // {state:'/form-builder', label:'Form Builder',icon:'',isActive:true,isChecked:false},
    // {state:'', label:'Forms',icon:'',isActive:false,model:'formdata',isChecked:false, subItem:[
    //   {subState:'/admin-forms', subLabel:' Forms',subIcon:'',isSubActive:false,model:'formdata',isChecked:false},
    //   // {subState:'/ipsa-forms', subLabel:' IPSA Forms',subIcon:'',isSubActive:false,model:'',isChecked:false}
    // ]},
    {state:'', label:'Portal Management',icon:'',model:['aboutUs','user','role','country','factory','industrialpark','cms','category','subcategory','subcategoryreport','glossary','impactstories','inquiry','partners','teammember'],isActive:false,isChecked:false,subItem:[
      {subState:'/user-level', subLabel:'Manage User Role',subIcon:'',isActive:false,model:['role'],isChecked:false},
      {subState:'/manage-user', subLabel:'User Management',subIcon:'',isActive:false,model:['user'],isChecked:false},
      {subState:'/manage-portal', subLabel:'Manage portal',subIcon:'',isActive:false,model:['cms','category','subcategory','subcategoryreport','glossary','impactstories','inquiry','partners','teammember', 'aboutUs'],isChecked:false},
      {subState:'/master', subLabel:'Manage Master Data',subIcon:'',isActive:false,model:['country','factory','industrialpark'],isChecked:false},
      {subState:'/subscribers', subLabel:'Subscribers',icon:'',isActive:false,model:['subscribe'],isChecked:false},
      {subState:'/manage-dashboard', subLabel:'Manage Dashboard',icon:'',isActive:false,model:['dashboard'],isChecked:false}
    ]},
    {state:'/dashboard', label:'Dashboard',icon:'',isActive:false,isChecked:false,model:['programview','countryview','ipview','factoryview']},   //model:'indicators'
    {state:'/ipsa', label:' IPSA',icon:'',isActive:false,model:['ipsa'],isChecked:false},
    // {state:'/communications', label:'Communications',icon:'',isActive:false,model:'cms',isChecked:false},
    // {state:'/manage-portal', label:'Manage portal',icon:'',isActive:false,model:['cms','category','subcategory','subcategoryreport','glossary','impactstories','inquiry','partners','teammember'],isChecked:false}, 
    {state:'/resources', label:'Resources',icon:'',isActive:true,isChecked:false}, //model:'category'
    {state:'/impact-stories', label:'Impact Stories',icon:'',isActive:true,isChecked:false},//model:'impactstories'
    {state:'/about-us', label:'About Us',icon:'',isActive:true,isChecked:false}, //,model:'cms'
    {state:'/ourpartners', label:'Our Partners',icon:'',isActive:true,isChecked:false}, //,model:'partners'
    {state:'', label:'',icon:'../../../assets/images/notification.svg',isActive:false,isChecked:false, model:['communicationmapper']},
  ]
  formsModles:string[] = ['baselineform','programform'];
  subTabs:string[] = ['/user-level','/manage-user','/add-user','/user-policies'];
  resourceTab :string[] = ['cms','category','impactstories','glossary','inquiry','teammember','partners']
  baselineFormLink:string[] = ['/forms-list','/admin-forms','/baseline-monthly-form','/program-form','baseline-form']
  notificationList: any = [];
  currentDate: any;
  totalUnread: any = [];
  userobj: any = {};
  userAllDetails: any;
  showImage: any;
  totalCountNotification: any = [];
  tabType: string = 'all';
  unReadMessageCount: any;
  lineItemID: any;
  notificationClicked: any;
  isShowInbox: boolean = false;
  isShowCom: boolean = false;

  constructor(public router: Router, public dialog: MatDialog, private commonService: CommonServiceService, private authService: AuthGuardService,private snackBar: MatSnackBar,private route: ActivatedRoute,
    private userService:UserManagementServiceService,private elementRef: ElementRef
    ) {
      this.renderMenu();
      // this.isHideComm = window.location.hostname == "uatidhinstepui.dhwaniris.in" ? true : false;
      // console.log("origin",this.isHideComm, environment);
      this.isShowCom = environment?.isShowCom;
     }
    
  ngOnInit() { 
    
  }

  renderMenu(){
    this.authService.isLoggedin.subscribe(res => {
      this.isLoggedin = res
      console.log("VALUEEE",this.isLoggedin);
    });

    this.route.queryParams.subscribe(params => {
      console.log("allparams",params);
      
    })
   
  this.userobj = JSON.parse(sessionStorage.getItem('userdata')!);
  this.userService.isProfile.subscribe((res:any) => {
    console.log("profile",res);
    if(res){
      this.getUserInfo();
    }
  });
  console.log("this.userobj",this.userobj);
  this.commonService.isCommunication.subscribe((res:any) => {
    console.log("isCommunication",res);
    if(res){
      this.getCommunicationList()
    }
  });
  this.userData = JSON.parse(sessionStorage.getItem('userPermission')!);
  this.userData = this.userData?.length ? this.userData[0] : {'role.name': 'User', 'role_id': 2};
  // this.getUserInfo()
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {        
      console.log("event.url;",event.url)
      if(event?.url.includes('?')){
        let getCurrent:any = event?.url?.split('?');
        event.url = getCurrent
      }
      event.url = event.url.replace(/\/\d+$/, '')
      this.rightMenuBar = this.rightMenuBar.map((el:any)=>{
        if(this.subTabs.includes(event?.url) && el?.subItem?.length){
           el.isChecked = true;
            el?.subItem.forEach((e:any)=>{
                e.isChecked = e.subState === event?.url
              })
        }
        else if(el?.label === 'Forms' && this.baselineFormLink.includes(event?.url)){
          el.isChecked = true;
        }
        else{
          el.isChecked = el.state === event?.url
        }
        return el;
      })
    };
    console.log("ngOnit",this.rightMenuBar)
  });
  let userPermission =  JSON.parse(sessionStorage.getItem('userPermission')!);
  if(userPermission){
    this.rightMenuBar = this.updateIsActiveKeys(this.rightMenuBar,userPermission[0]);
    // 
    console.log("ngOnit",this.rightMenuBar)
  }
  this.isLoggedin = userPermission ? true : false;
  this.getCommunicationList();
  }

  ngOnChanges(changes: SimpleChanges): void{
    // this.isUserLoggedIn = sessionStorage.getItem('userdata') ? true : false;
    if(changes?.isTabChange && changes?.isTabChange?.firstChange == false){
      
    }
    
  }

  logoutUser(){
    sessionStorage.removeItem('userPermission');
    // LOGOUT API CALL
    this.commonService.logoutService().subscribe(res => {
      if(res.success){
    // this.commonService.updateResouceCount({});

        // this.isLoggedin = false;
        let localkeys: any = [];
        localkeys = Object.keys(sessionStorage);
        console.log("sessionKey",localkeys);
        
        localkeys.forEach((value:any) => {
          console.log("valueee",value);
          
          sessionStorage.removeItem(value);
          
        });
        this.router.navigate(["/home"]);
        this.isLoggedin = false;
      }
    },(error) => {
      let localkeys: any = [];
        localkeys = Object.keys(sessionStorage);
        console.log("sessionKey",localkeys);
        
        localkeys.forEach((value:any) => {
          console.log("valueee",value);
          
          sessionStorage.removeItem(value);
        });
        this.router.navigate(["/home"]);
        this.isLoggedin = false;
    })
  }

  openDialog() {
    this.router.navigate(['home'])
    let dialogRef = this.dialog.open(LoginComponent, {
      width: '530px',
      panelClass: 'login-dialog-class',
      disableClose: true,
      // position: {top: '170px'},
    });
    dialogRef.afterClosed().subscribe((result :any) => {
      console.log("diaClose",result);
      this.isLoggedin = result?.isLoggedIn;
      // let userPermission =  JSON.parse(sessionStorage.getItem('userPermission')!);
      if(result){
        this.openSnackBar(result?.message,'',true)
        // this.rightMenuBar = this.updateIsActiveKeys(this.rightMenuBar,userPermission[0]);
        this.renderMenu();
        window.location.reload();
        console.log("LoginComponent",this.rightMenuBar)
      }
      
    });
  }

  openSignUpForm(){
    this.router.navigate(['home'])
    let dialogRef = this.dialog.open(SignUpComponent, {
      width: '750px',
      panelClass: 'signUp-dialog-class',
      disableClose: true,
      // position: {top: '120px'},
    });
  }
  redirectUrl(url:string):void{
    if(url){
      this.rightMenuBar = this.rightMenuBar.map((el:any)=>{
        el.isChecked = el?.state === url;
        return el
      })
      this.router.navigate([url]);
    }
    else if(!url){
      this.rightMenuBar = this.rightMenuBar.map((el:any)=>{
        el.isChecked = el?.subItem?.length
        return el;
      })
    }
    this.rightMenuBar = this.rightMenuBar.map((el:any)=>{
      if(el?.subItem?.length){
        el.isChecked = false;
        el?.subItem.forEach((e:any)=>{
          e.isChecked = false
        })
      }
      
      return el;
    })
  }
  updateIsActiveKeys(mainArray: any[], secondaryArray: any): any[] {
    return mainArray.map((mainItem: any) => {
      
      secondaryArray?.authenicationJson?.forEach((secondaryItem: any) => {
        let checkPermissionModule = false;
        checkPermissionModule = (secondaryItem?.isAction || secondaryItem?.is_checked) || secondaryItem?.authentication.some((e:any) => e?.isAction);
        if(checkPermissionModule && (secondaryItem.model == 'communicationmapper'))
        this.isShowInbox = true;

        if(mainItem.hasOwnProperty('model') && mainItem.model.includes(secondaryItem.model)){
          if(checkPermissionModule)
          mainItem.isActive = true;
        
        }
        
        // if (mainItem.model === secondaryItem.model && !this.formsModles.includes(secondaryItem.model) && !this.resourceTab.includes(secondaryItem.model)) {
        //   if (checkPermissionModule) {
        //     mainItem.isActive = true;
        //   }
        // }
        // if(this.formsModles.includes(secondaryItem.model) && mainItem.model === 'formdata'){
        //   if(checkPermissionModule){
        //     mainItem.isActive = true;
        //   }
        // }
        // if(this.resourceTab.includes(secondaryItem.model) && mainItem.model === 'cms'){
        //   if(checkPermissionModule){
        //     mainItem.isActive = true;
        //   }
        // }
        // if (!mainItem.model) {
        //   if (checkPermissionModule) {
        //     mainItem.isActive = true;
        //   }
        // }
  
        if (mainItem?.subItem?.length) {
          mainItem.subItem.forEach((subItem: any) => {
            if (subItem.model.includes(secondaryItem.model)) {
              if (checkPermissionModule) {
                subItem.isActive = true;
              }
            }
          });
        }
      });
      console.log("mainItem",mainItem)
      return mainItem; // Return the updated mainItem
    });
  }
  

  openSnackBar(message:any, action = '',bottom:boolean =false) {
    this.snackBar.open(message, action, {
    duration: 3000,
    ...!bottom && {verticalPosition: 'bottom',
    horizontalPosition:'center'},
    
    panelClass: ['black-snack']
  });
}
redirectUrlSubChild(subChildUrl:string,parentModule:string){
  if(subChildUrl){
    this.rightMenuBar = this.rightMenuBar.map((el:any)=>{
      if(el && el?.subItem?.length){
        el.subItem.forEach((e:any)=>{
          e.isChecked = subChildUrl === e.subState;
        })
      }
      return el;
    })
    setTimeout(()=>{
      this.rightMenuBar = this.rightMenuBar.map((el:any)=>{
        if(el && el?.subItem?.length){
          el.subItem.forEach((e:any)=>{
            if(el.label === parentModule){
              el.isChecked = el.subItem.some((e: any) => subChildUrl === e.subState);
            }
            else{
              el.isChecked = false;
            }
          })
        }
        else{
          el.isChecked = false;
        }
        return el;
      })
    },0)
    console.log("rightMenuBar",this.rightMenuBar,parentModule)
    this.router.navigate([subChildUrl]);
  }
}
getCommunicationList(){

  //to remove calling when not logged in
  if(sessionStorage.getItem('token'))
  this.commonService.communicaionList('','',0,true,'receive', this.userData?.id).subscribe(res => {
    if(res.success){
      if(res.data?.length){
        res.data = res.data.map((el:any)=>{
          el.is_Clickable = false
          return el
        })
      }
      console.log("nottt",res.data);
      if(res.data?.length){
        this.notificationList = res?.data.filter((ele:any) => ele.is_visible == false);
        this.unReadMessageCount = res?.is_visible;
      }else{
        this.unReadMessageCount = 0;
        this.notificationList = [];
      }
      this.getNameInitials(this.notificationList);
    }
  })
}

getNameInitials(responseData:any){
  for(let i = 0; i <= responseData.length; i++){
      let fullName = responseData[i].name;
      let initials = fullName.split(' ').map((name:any) => name[0]).join('').toUpperCase()
      responseData[i]['nameInitials'] = initials;
      var color = Math.floor(0x1000000 * Math.random()).toString(16);
      responseData[i]['color'] = '#' + ('000000' + color).slice(-6);
  }
}

markAllRead(){
  let payload = {
    "markasread": true
  };
  this.commonService.markAllasRead(payload).subscribe((res:any) => {
    if(res.success){
      this.openSnackBar(res.message);
      
      this.getCommunicationList();
      this.commonService.isMarkAllRead.next(true);
    }
  })
}

openSidebar(item:{icon:string}){
  if(item.icon){
  this.hideSidebar = true;
  // Current date and time
  this.currentDate = new Date();
  console.log(this.currentDate);
  
  this.getCommunicationList();
  }
}

toggle(){
  this.hideSidebar = false;
  this.getCommunicationList();

}

tabChange(status:any){
  this.tabType = status;
  if(status == 'all'){
    this.getCommunicationList();
  }else{
    console.log("notificationList",this.notificationList)
    // this.totalCountNotification = this.notificationList.filter((ele:any) => ele.is_visible == true);
    this.totalCountNotification = [...this.notificationList];
    this.totalCountNotification = this.totalCountNotification.filter((ele:any) => ele.is_visible == false);
  }

}

getUserInfo(isPrefield:boolean=false){
  this.userService.getProfileDetailsById(this.userobj.id).subscribe((res:any)=>{
    if(res.success){
      this.userAllDetails = res.data[0]
      this.showImage = this.userAllDetails.photo
      console.log("hello",this.userAllDetails);
      console.log("hello",this.showImage)
    }
  },(error:any)=>{
    console.log(error)
  })
}
redirectCommunicationPage(item:any){
  this.communicationUpdate(item);
  this.hideSidebar = false;
  const navigationExtras: NavigationExtras = {
    queryParams: { id: item?.id },
  };
  this.router.navigate(['communications'],navigationExtras);
  this.commonService.notificationClicked.next(item?.id);
}

currentTime(item: any) {
  const date1 = new Date(item?.updatedAt);
  const date2 = new Date();
  const timeDifference = date2.getTime() - date1.getTime();
  console.log("timeDifference", timeDifference);
  return this.formatTimeDifference(timeDifference);
}

formatTimeDifference(milliseconds: any) {
  const minutes = Math.floor(milliseconds / 60000); 
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours > 0) {
    return `${hours} hours, ${remainingMinutes} minutes`;
  } else {
    return `${remainingMinutes} minutes`;
  }
}

communicationUpdate(data:any){
  this.lineItemID = data.id;
  this.notificationList = this.notificationList.map((el:any)=>{
    if(el?.id === data?.id){
      el.is_Clickable = true;
    }
    else{
      el.is_Clickable = false;
    }
    return el;
  })
  let dataVisible = data.is_visible;
  if(!data?.is_visible){
      dataVisible = true;
  }
  let payload = {
    "subject": data?.subject,
    "message": data?.message,
    "sender_id": data?.sender_id,
    "receiver_id": data?.receiver_id,
    "createdBy": data?.createdBy,
    "is_highlight": data?.is_highlight,
    "is_visible": dataVisible,
    "is_Clickable" : true

  };

  this.commonService.communicationUpdate(data.id, payload).subscribe(res => {
    if(res.success){
      this.getCommunicationList();
    }
  })
}

// @HostListener('document:click', ['$event'])
// onClick(event: MouseEvent) {
//   if (!this.elementRef.nativeElement.contains(event.target)) {
//     this.isClicked = false;
//   }
// }

// toggleClass() {
//   this.isClicked = !this.isClicked;
// }
}
