import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ipsa-forms',
  templateUrl: './ipsa-forms.component.html',
  styleUrls: ['./ipsa-forms.component.scss']
})
export class IpsaFormsComponent implements OnInit {

  formJson:any = [
    // {
    //   id: 1,
    //   formName : "Introduction to the IPSA",
    //   image: "/assets/images/ipsa-icon.svg",
    // },
    {
      id: 1,
      formName : "IPSA Pre-assessment",
      pathName:"IPSA Pre-assessment",
      image: "/assets/images/ipsa-preAssessmentIcon.svg",  
    },
    {
      id: 2,
      formName : "List of Evidence",
      pathName:"List of Evidence",
      image: "/assets/images/listEvidence-icon.svg",
    },
    {
      id: 3,
      formName : "Social Assessment",
      pathName:"Social Performance",
      image: "/assets/images/socialPerformance-icon.svg",
    },
    {
      id: 4,
      formName : "Environmental Assessment",
      pathName:"Environmental Performance",
      image: "/assets/images/environmentalPerf-icon.svg",
    },
    {
      id: 5,
      formName : "Building Safety Assessment",
      pathName:"Building Safety Performance",
      image: "/assets/images/buildingSafetyPerf-icon.svg",
    },
    // {
    //   id: 7,
    //   formName : "IPSA Dashboard",
    //   image: "/assets/images/ipsaDashboard-icon.svg",
    // }
  ]

  constructor( public router: Router) { }

  ngOnInit(): void {
  }

  toggle(data:any){
    console.log("data", data)
    // if(data.id == 7){
    //   this.router.navigate(['/ipsa-dashboard']);
    // }else if(data.id == 1){
    //   this.router.navigate(['/ipsa-intro']);
    // }
    // else{
    // }
    this.router.navigate(['/ipsa-forms-list'], {queryParams:{
      formName: data.pathName
    }
})
      
  };
  backToForm(){
    this.router.navigate(['/ipsa'])
  }
}
