<div class="Footer_bottom">
  <div class="container-fluid px-md-4">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <svg class="me-2 pointer" (click)="backToForm()" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
        </svg>
        
      <nav aria-label="breadcrumb" class="my-3">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToForm()">IPSA</li>
          <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">IPSA Dashboard</li>
        </ol>
      </nav>
      </div>

        <!-- <img src="../../../assets/images/download-form.svg" (click)="generatePdf()"class="img-fluid pointer me-5" matTooltip="Download" alt=""> -->
      </div>
  </div>
    <iframe #iframe id="iframe" title="IDH-InStep" width="100%" height="541.25" [src]="powerBIiFrameLink" frameborder="0" allowFullScreen="true"></iframe>
</div>