import { Component, OnInit } from '@angular/core';
import { ContentService } from './shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  isLoading = false;
  userData: any;
  constructor(public contentService:ContentService){
    // this.userData = JSON.parse(localStorage.getItem('userdata')!);
    // console.log("xyzzzz",this.userData)
  }
  ngOnInit(): void {
    this.contentService.loaderSubscriber.subscribe(res=>{
      console.log("loading", res)
      this.isLoading = res;
    })
  }
}
