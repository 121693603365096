import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
// import 'chartjs-plugin-datalabels';
import ChartDataLabels from 'chartjs-plugin-datalabels';




@Component({
  selector: 'app-stackbar',
  templateUrl: './stackbar.component.html',
  styleUrls: ['./stackbar.component.scss']
})
export class StackbarComponent implements OnInit {
  
  stackedBarChart: any;
  @Input() data:any = [];
  customData: any = [];
  customLabel: any = [];
  colorIndex = 0
  isTimelineGraph: boolean = false;
  @Input() indexId:string = '';
  stackBar: string = '';
  @ViewChild('stackedChartCanvas') stackedChartCanvas!: ElementRef<HTMLCanvasElement>;
  constructor(private renderer: Renderer2,) { 
    // this.stackBar = `stackedBarChart + ${this.indexId}`
  }
   chartData:any
 setColor:string[] = ['#3DB22E','#5BE249']
  ngOnInit(): void {
    if(this.data){
      this.getDataForChart(this.data);
    }
    this.createStackedBarChart();
  }
  
  createStackedBarChart(): void {
    Chart.register(ChartDataLabels);
    Chart.defaults.set('plugins.datalabels', {
      color: '#fff'
    });
    // const ctx = document.getElementById('stackedBarChart') as HTMLCanvasElement;
    // const ctx = document.getElementById('stackedBarChart_' + this.indexId) as HTMLCanvasElement;
    // const ctx = this.stackedChartCanvas.nativeElement.getContext('2d')!;

    const canvasId = 'stackedBarChart_' + this.indexId;
    const ctx = this.renderer.selectRootElement(`#${canvasId}`, true) as HTMLCanvasElement;

    console.log("this.customLabel",ctx,this.indexId,this.customLabel);
    let labels = this.isTimelineGraph ? this.customLabel : this.generateServiceLabels();
    console.log("this.customLabel",labels)
    
    let datasets :any
    let test33 = [
      { data: [114400], label: 'coal', stack: 'a' },
      { data: [100350, 185649], label: 'Variable', stack: 'a' },
      { data: [34320], label: 'KSO', stack: 'a' },
      { data: [74955, 91899], label: 'Upside 130%', stack: 'a' },
      { data: [74955, 91899], label: 'Upside1 130%', stack: 'a' },
      { data: [74955, 91899], label: 'Upside2 130%', stack: 'a' },
      { data: [74955, 91899], label: 'Upside3 130%', stack: 'a' },
      { data: [74955, 91899], label: 'Upside4 130%', stack: 'a' },
      { data: [74955, 91899], label: 'Upside5 130%', stack: 'a' }
    ];

    // border setup none
    // const chartCanvas = canvasId;
    // this.renderer.setStyle(chartCanvas, 'border', '2px solid ');
    if(this.isTimelineGraph){
      labels=labels.sort();
      this.dataSetMethods()
    }
    datasets = this.isTimelineGraph ? 
  //   Object.keys(this.chartData).map((financial_year, index) => ({
  //     label : this.chartData[financial_year].map((item:any) => this.firstLetterCaptial(item.name)),
  //     data: this.chartData[financial_year].map((item:any) => item.total_count),
  //     backgroundColor: this.generateRandomColor(), 
  //     barThickness: 60, 
  //     maxBarThickness: 70
  // })) 
  // test33

  this.dataSetMethods()
  
  : 
   datasets = Object.keys(this.chartData).map((label, index) => ({
        label,
        data: this.chartData[label][0].map((item:any) => item.total_count),
        backgroundColor: this.generateRandomColor(), 
        
      }));
  datasets = {...datasets, barThickness: 30, 
    maxBarThickness: 30,
    borderRadius: 20,}
    console.log("label",labels);
    console.log("datasets",datasets);
    this.stackedBarChart = new Chart(ctx, {
      type: 'bar',
      data:{
        labels,
        datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: { title: {
            display: true,
            text: 'Year'
          },
          stacked: true },
          y: { title: {
            display: true,
            text: 'Value'
          },
          stacked: true,ticks:{
            precision: 0
          }, }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels:{
              usePointStyle:true,
              pointStyle: 'circle',
              padding:20
              }
          },
          datalabels: {
            color: '#fff', 
            anchor: 'center', 
            align: 'end', // Set the alignment for positioning
            formatter: (value: any) => value , // Display the raw data value
            display: 'auto', // Automatically display labels when available
          font: {
            weight: 'bold',
            size: 11
          }
          }
          
        }
      }
    });
  }
 getDataForChart(data:any){
  
  const obj: any = {};
  if(data[0]?.values?.length || data[0].hasOwnProperty('values')){
    this.isTimelineGraph = true;
    data.forEach((e:any)=>{
      e?.values?.forEach((el:any)=>{
        if (!obj.hasOwnProperty(el.financial_year)) {
          obj[el.financial_year] = [];
        }
        if(el?.financial_year === '2021'){
          this.customLabel.push(el?.financial_year);
          el['name'] = e?.name;
          obj[el.financial_year ].push(el);
        }
        if(el?.financial_year === '2022'){
          this.customLabel.push(el?.financial_year);
          el['name'] = e?.name;
          obj[el.financial_year ].push(el);
        }
        if(el?.financial_year === '2023'){
          this.customLabel.push(el?.financial_year);
          el['name'] = e?.name;
          obj[el.financial_year ].push(el);
        }
        if(el?.financial_year === '2024'){
          this.customLabel.push(el?.financial_year);
          el['name'] = e?.name;
          obj[el.financial_year ].push(el);
        }
      })
      this.customLabel = this.customLabel.filter((value:any, index:number, self:any) => self.indexOf(value) === index);

    })
    
   }
   else if(!data?.short_key?.length){
    this.isTimelineGraph = false;
      data?.forEach((el:any)=>{
        if (!obj.hasOwnProperty(el.question_label)) {
          obj[el.question_label] = [];
        }
        this.customLabel.push(el.question_label)
        if(el?.short_key_men){
          // obj[el.question_label].push(el.short_key_men.map((e:any)=>e?.total_count));
          obj[el.question_label].push(el.short_key_men);
  
        }
        else if(el?.short_key_women){
          // obj[el.question_label].push(el.short_key_women.map((e:any)=>e?.total_count));
          obj[el.question_label].push(el.short_key_women);
        }
        else if(el?.short_key){
          obj[el.question_label].push(el.short_key);
        }
        
     })
     }
     else {
      this.isTimelineGraph = false;
      data?.short_key.forEach((el:any)=>{
        if (!obj.hasOwnProperty(el.question_label)) {
          obj[el.question_label] = [];
        }
        this.customLabel.push(el.question_label);
        obj[el.question_label].push(el);
        
      })
     }
   this.chartData = obj
   console.log("obj",obj)
 }
 generateServiceLabels(): string[] {
  const firstLabelArray = this.chartData[Object.keys(this.chartData)[0]][0];
  console.log("firstLabelArray",firstLabelArray)
  return firstLabelArray.map((item:any) => {
    let words:string = item.name;
    if (words.includes('_')) {
        words = words.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else {
        words = words.charAt(0).toUpperCase() + words.slice(1);
    }
    return words;
});



}


// generateRandomColor(data:any): string {
//   return `rgba(${Math.random() * 255},${Math.random() * 255},${Math.random() * 255},0.5)`;
// }
// generateRandomColor(): string {
//   const randomIndex = Math.floor(Math.random() * this.setColor.length);
//   return this.setColor[randomIndex];
// }
generateRandomColor(): string {
  const fixedColors = ['#5BE249','#3DB22E'];
  const color = fixedColors[this.colorIndex];
  this.colorIndex = (this.colorIndex + 1) % fixedColors.length; // Cycle through the colors
  return color;
}
firstLetterCaptial(value:string):string{
  if (value) {
    let sentence:any = value.split('_');
    return sentence.map((word:string) => word.charAt(0).toUpperCase()+word.slice(1)).join(' ')
  }
  return '';
}
// this is code running 
// dataSetMethods(){
  
//   const years = Object.keys(this.chartData);
  
//   const outputArray:any = [];
//   years.forEach(year => {
//     this.chartData[year].forEach((item:any) => {
//       const transformedItem = {
//         data: [item.total_count],
//         label: this.firstLetterCaptial(item.name),
//         backgroundColor: this.randomColorSet(),
//         year: year,
//         stack: 'a'
//       };
//       outputArray.push(transformedItem);
//     });
//   });
  
//   console.log('outputArray',outputArray);
//   return outputArray;
  
// }


dataSetMethods() {
  const years = Object.keys(this.chartData);
  const dataMap = new Map<string, number[]>();

  // Iterate through each year
  years.forEach(year => {
    // Iterate through each item in the current year
    this.chartData[year].forEach((item: any) => {
      const itemName = this.firstLetterCaptial(item.name);
      if (!dataMap.has(itemName)) {
        // If not, initialize an array for the item name
        dataMap.set(itemName, Array.from({ length: years.length }, () => 0));
      }

      // Update the total_count value for the corresponding year
      const index = years.indexOf(year);
      const itemData = dataMap.get(itemName);
      if (itemData) {
        itemData[index] += item.total_count;
      }
    });
  });

  // Create the final output array
  const outputArray: any[] = [];
  dataMap.forEach((data, itemName) => {
    const transformedItem = {
      data: [ ...data ],
      label: itemName,
      backgroundColor: this.randomColorSet(),
      stack: 'a',
      barThickness: 30, 
      maxBarThickness: 30,
      
    };
    outputArray.push(transformedItem);
  });

  console.log('outputArray', outputArray);
  return outputArray;
}

randomColorSet(){
  var color = Math.floor(0x1000000 * Math.random()).toString(16);
  return '#' + ('000000' + color).slice(-6);
}

// remove code  
ngAfterViewInit() {
  this.createStackedBarChart();
}
}
