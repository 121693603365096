import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ManagePortalComponent } from '../manage-portal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
declare const Quill: any; 
@Component({
  selector: 'app-manage-portal-dialog',
  templateUrl: './manage-portal-dialog.component.html',
  styleUrls: ['./manage-portal-dialog.component.scss']
})
export class ManagePortalDialogComponent implements OnInit {
  @ViewChild('editorElem') editorElement!: ElementRef;
  @ViewChild('fileDropRef', { static: false }) fileDropRef!: ElementRef;
  fileName: any = "";
  fileType: any = "";
  imageAllData: any = [];
  putAPIurl: any;
  payloadURL: string = "";
  isEditMode: boolean = false;
  imgURL: string = "";
  sectionDropdown: any = [];
  submitButtonName: string = "Submit";
  selectedSubTabName: any = "";
  showAlert: boolean = false;
  termEmpty: boolean = false;
  descriptionEmpty: boolean = false;
  tabsAllow :any = ['contactUs', 'glossary', 'teams', 'ourPartners'];
  isBydefault : boolean = false
  clickImage: boolean = false;
  fileUploadInfo:string = "*File size limit: 5MB*";

  constructor(public dialogRef: MatDialogRef<ManagePortalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar,
  private commonService: CommonServiceService) { }
  modalHead:any = "";
  selectedSection:any;
  selectedCatType:any = 'resource';
  titleData:any = "";
  teamMemberName:any = "";
  teamMemberDesignation:any = "";
  teamMemberAbout:any = "";
  descriptionData:any = "";
  rank :number = 1;
  
  termData:any = "";

  commonSection:any = {
    homepage : [
      {
        id: 1,
        sectionName: 'Slider',
      },
      {
        id: 2,
        sectionName: 'About Us',
      },
      {
        id: 3,
        sectionName: 'Our Partners',
      },
      {
        id: 4,
        sectionName: 'Our Impact',
      },
      {
        id: 5,
        sectionName: 'Impact Stories',
      },
      {
        id: 6,
        sectionName: 'Our Team',
      },
      {
        id: 7,
        sectionName: 'Contact Us',
      }
    ],
    resources: [
      {
        id: 1,
        sectionName: 'INSTEP Resources',
      },
      {
        id: 2,
        sectionName: 'IPSA',
      },
      {
        id: 3,
        sectionName: 'IPSA Report',
      },
      {
        id: 4,
        sectionName: 'Industrail Parks supported',
      },
      {
        id: 5,
        sectionName: 'Factories supported',
      },
    ],
    impactStories: [
      {
        id: 1,
        sectionName: 'Impact Stories 1',
      },
      {
        id: 1,
        sectionName: 'Impact Stories 2',
      },
      {
        id: 1,
        sectionName: 'Impact Stories 3',
      },
    ],
    glossary: [
      {
        id: 1,
        sectionName: 'Sourcing Brands'
      },
      {
        id: 1,
        sectionName: 'Goods Produced'
      },
    ],
    contactUs: [
      {
        id: 1,
        sectionName: 'Contact Us 1',
      },
      {
        id: 1,
        sectionName: 'Contact Us 2',
      }
    ]
  };
  libraryForm!: FormGroup;
  formBuilder: any;
  file: any;
  files:any = [];
  @ViewChild('fileSet', { static: true }) fileSet: any;
  fileUrl = [];
  videoUrl:string = "";

  editor: any;



  ngOnInit(): void {
    this.selectedSubTabName = this.data?.selectedSubTabName;
    // Getting two different keys for the section name which needs to be prefill in the Select Category column i.e, section.name and name.
    this.sectionDropdown = this.data?.sectionData.filter((ele:any) => ele['section.name'] || ele.name);
    this.isEditMode = this.data.editMode;
    console.log("alldata", this.data); 
    this.setModalHead(this.data);    

    if(this.isEditMode){
      this.submitButtonName = 'Update';
     
      
      switch(this.data.currentTab){
        case 'homepage':
          this.selectedSection = this.data.data.sectionId;
          this.titleData = this.data.data.title;
          this.descriptionData = this.data.data.subTitle ? this.data.data.subTitle : ''
          this.imgURL = this.data.data.banner;
          
        break;
        case 'resources':
          
          if(this.data.selectedSubTabName == 'Category'){
            // this.selectedCatType = this.data?.data?.types;
            this.selectedCatType = this.selectedCatType;
            this.titleData = this.data.data.name;
            this.imgURL = this.data?.data?.image;
           
          }else if(this.data.selectedSubTabName == 'Sub Category'){
            this.selectedSection = this.data?.data?.categoryId;
            this.titleData = this.data.data.name;
          }else{
            this.selectedSection = this.data?.data?.subCategoryId;
            this.titleData = this.data.data.name;
            this.imgURL = this.data?.data?.link;
            
          }
        break;
        case 'impactStories':
          this.selectedSection = this.data?.data?.categoryId;
          this.titleData = this.data?.data?.title;
          this.descriptionData = this.data?.data?.description ? this.data?.data?.description : '';
          this.imgURL = this.data?.data?.banner;
          this.videoUrl = this.data?.data?.link ? this.data?.data?.link : ''
          this.rank = this.data?.data?.ranking ? this.data?.data?.ranking : 0; 
         
        break;
        case 'glossary':
          this.termData = this.data?.data?.term;
          this.descriptionData = this.data?.data?.definition ? this.data?.data?.definition : '';
        break;
        case 'teams':
          this.teamMemberName = this.data?.data?.name;
          this.teamMemberDesignation = this.data?.data?.designation;
          this.teamMemberAbout = this.data?.data?.about
          this.imgURL = this.data?.data?.profile;
          
        break;

        case 'ourPartners':
          this.teamMemberName = this.data?.data?.title;
          this.teamMemberAbout = this.data?.data?.description;
          this.imgURL = this.data?.data?.image;
          this.rank = this.data?.data?.ranking ? this.data?.data?.ranking : 0; 
          
        break;

        case 'aboutUs':
          this.selectedSection = this.data.data.id;
          this.titleData = this.data.data.title;
          this.descriptionData = this.data.data.subTitle ? this.data.data.subTitle : ''
          this.imgURL = this.data.data.banner;
          this.videoUrl = this.data?.data?.link ? this.data?.data?.link : '';
        break;
      }
     
      this.termData = this.data.data.term;
    }
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  setModalHead(option:any){
    switch (option?.currentTab) {
      case 'homepage':
        this.modalHead = "Program Summary";
      break;

      case 'resources':
        this.modalHead = "Resource";
      break;

      case 'impactStories':
        this.modalHead = "Impact Story";
      break;

      case 'glossary':
        this.modalHead = "Glossary";
      break;

      case 'contactUs':
        this.modalHead = "Contact Us";
      break;

      case 'teams':
        this.modalHead = "Team Member";
      break;

      case 'ourPartners':
        this.modalHead = "Partner";
      break;
    
      default:
        break;
    }
  }

  createForm() {
    this.libraryForm = this.formBuilder.group({
      files: ['', [Validators.required]],
    });
    // console.log('data', this.libraryForm.value)
  }
  onFileDropped(event:any) {
    console.log("kokoko",event);
    this.clickImage = true;
    this.files = [];
    this.prepareFilesList(event);
    // if ($event) {
    //   this.file = $event[0];
    // }
    // let payload = {
    //   "file_name": ,
    //   "mime_type": "application/jpeg"
    // }
    // this.commonService.uploadImage(payload).subscribe((res:any) => {

    // })

  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any) {
    let maximumFileSize = 5 * 1024 * 1024; // 5MB in bytes
    let currentFileSize = files?.target?.files[0]?.size;

    if(currentFileSize > maximumFileSize){
      files.target.value = null;
      this.openSnackBar(['Please upload file under 5 MB !' || 'Failed !'] , '');
      return ;
    }

    this.clickImage = true;
    let payload = [{
        "file_name": files.target.files[0].name,
        "mime_type": files.target.files[0].type
      }];
      this.commonService.uploadImage(payload).subscribe((res:any) => {
        if(res.success){
          console.log("POSTresponse",res);
          this.imageAllData = res.data;
          this.imageAllData.forEach((ele:any) => {
            this.putAPIurl = ele?.url;
            this.payloadURL = ele?.file_url;
            this.imgURL = ele?.file_url;
          });
          this.commonService.imagePUTapi(this.putAPIurl, files.target.files[0]).subscribe((res:any) => {
            console.log("putresponse",res);
            
          })
        }
      })

    
    this.files = [];
    this.prepareFilesList(files.target.files);
    
    // console.log('files', files)
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.fileDropRef) {
      this.fileDropRef.nativeElement.value = '';
    }
//  this.files[index].progress = 0;
    this.files?.splice(index, 1);
    this.imgURL = "";
    this.payloadURL = "";
    if (this.files.length == 0) {
      this.libraryForm.reset();
      this.libraryForm.get('files')?.reset();
    }
  

    
  }

  /**
 * Simulate the upload process
 */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      console.log("filesss",files);
      
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */

  formatBytes(bytes:any, decimals:any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  paylaterClick() {
    // this.router.navigateByUrl('welcome-login');
  }

  submitClick() {
    // console.log(this.files);
    // console.log(this.fileUrl);

    // this.files.map((file) => {
    //   let awsBody = [{
    //     "file_name": file.name,
    //     "mime_type": file.type
    //   }]

    //   this.authService.awsRequest(awsBody).subscribe((response1: any) => {
    //     this.authService.awsResponseUrl(response1?.data[0]?.url, file).subscribe((response2: any) => {
    //       // console.log('awsResponseUrl', res);
    //       this.fileUrl.push(response1?.data[0]?.file_url);
    //       this.submitValues();
    //     },
    //       (error) => {
    //         Swal.fire('Error', `${error.error.message}`, 'error');
    //       })
    //   },
    //     (error) => {
    //       Swal.fire('Error', `${error.error.message}`, 'error');
    //     })
    // })

  }

  submitValues() {

    // let payload = {
    //   'payment_date': new Date(),
    //   'user_id': +sessionStorage.getItem('userId'),
    //   'coupon_id': this.couponId,
    //   'total_amount': this.total_discounted_amount,
    //   'payment_reciept': this.fileUrl.toString(),             //Converting array elements to , separated
    // }

    // this.authService.paymentReceipt(payload).subscribe((res: any) => {
    //   Swal.fire("Success", 'Your Receipt Successfully Submitted', 'success').then(() => {
    //     this.router.navigateByUrl('welcome-login');
    //   })
    //   this.libraryForm.reset();
    //   this.fileUrl = [];
    // },
    //   (error) => {
    //     Swal.fire("error", error.error.message, 'error')
    //   })
  }

  submitModalData(){

    // if(this.termData == ""){
    //   this.termEmpty = true;
      
    // }
    // if(this.descriptionData == ""){
    //   this.descriptionEmpty = true;
    //   return
    // }
      let userId = JSON.parse(sessionStorage.getItem('userdata')!);
      switch(this.data.currentTab){
        case 'homepage':
          let homepayload = {
            'title': this.titleData,
            'subTitle': this.descriptionData,
            'sectionId': parseInt(this.selectedSection),
            'createdBy': userId?.id,
            'slug': 'home',
            'banner': this.payloadURL ? this.payloadURL : this.imgURL
          };
          this.commonService.createCms(homepayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
            if(res.success){
              this.closeDialog(res);
            }
          },(error:any)=>{
            this.openSnackBar(error?.error?.message)
          })
        break;
        case 'resources':
          if(this.selectedSubTabName == 'Category'){
            let resourcePayload = {
              'name': this.titleData,
              'image': this.payloadURL ? this.payloadURL : this.imgURL,
              'types': this.selectedCatType,
              'createdBy': userId?.id,
              'isActive': true
            };
            // console.log(resourcePayload);
            // return
            this.commonService.createCategory(resourcePayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
              if(res.success){
                this.closeDialog(res);
              }
            },(error:any)=>{
              this.openSnackBar(error?.error?.message)
            }) 
          }else if(this.selectedSubTabName == 'Sub Category'){
            let resourcePayload = {
              "name": this.titleData,
              "categoryId": parseInt(this.selectedSection),
              "isActive": true,
              "createdBy": userId?.id,
          };
          // console.log(resourcePayload);
          //   return
          this.commonService.createSubCategory(resourcePayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
            if(res.success){
              this.closeDialog(res);
            }
          },(error:any)=>{
            this.openSnackBar(error?.error?.message)
          }) 
          }else{
            let resourcePayload = {
              "name": this.titleData,
              "link": this.payloadURL ? this.payloadURL : this.imgURL,
              "subCategoryId": parseInt(this.selectedSection),
              "isActive": true,
              "createdBy": userId?.id
            };
            // console.log(resourcePayload);
            // return
            this.commonService.createSubCatReport(resourcePayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
              if(res.success){
                this.closeDialog(res);
              }
            },(error:any)=>{
              this.openSnackBar(error?.error?.message)
            }) 
          }
        break;
        case 'impactStories':
          if(this.rank == null){
            this.openSnackBar(['Rank cannot be empty']);
            return;
          }
          if(this.rank < 0 || this.rank == 0){
            this.openSnackBar(['Rank Field does not allow Zero(0) && Negative(-1,-2,...)']);
            return;
          }
          let impactPayload = {
            'title': this.titleData,
            'banner': this.payloadURL ? this.payloadURL : this.imgURL,
            'description': this.descriptionData,
            'categoryId': parseInt(this.selectedSection),
            'createdBy': userId?.id,
            'link': this.videoUrl,
           ...this.isEditMode && { 'ranking':this.rank}
          };
          
          this.commonService.createImpactStory(impactPayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
            if(res.success){
              this.closeDialog(res);
            }
          },(error:any)=>{
            this.openSnackBar(error?.error?.message)
          }) 
        break;
        case 'glossary':
          let glossaryPayload = {
            'term': this.termData,
            'definition': this.descriptionData,
            'createdBy': userId?.id,
          };
          this.commonService.createGlossary(glossaryPayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
            if(res.success){
              this.closeDialog(res);
            }
          },(error:any)=>{
            this.openSnackBar(error?.error?.message)
          }) 
        break;

        case 'teams':
          let teamPayload = {
            'cmsId': 7,
            'name': this.teamMemberName,
            'designation': this.teamMemberDesignation,
            'profile': this.payloadURL ? this.payloadURL : this.imgURL,
            'about': this.teamMemberAbout,
            'isActive': true,
            'createdBy': userId?.id
          };
          this.commonService.createTeamMember(teamPayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
            if(res.success){
              this.closeDialog(res);
            }
          },(error:any)=>{
            this.openSnackBar(error?.error?.message)
          }) 
        break;

        case 'ourPartners':
          if(this.rank < 0 || this.rank == 0){
            this.openSnackBar(['Rank Field does not allow Zero(0) && Negative(-1,-2,...)']);
            return
          }
          let partnerPayload = {
            "title": this.teamMemberName,
            "description": this.teamMemberAbout,
            "image": this.payloadURL ? this.payloadURL : this.imgURL,
            "createdBy": userId?.id,
            ...this.isEditMode && {'ranking':this.rank}
          };
          this.commonService.createNewPartner(partnerPayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
            if(res.success){
              this.closeDialog(res);
            }
          },(error:any)=>{
            this.openSnackBar(error?.error?.message)
          })          
        break;
  
        case 'aboutUs':
          let aboutUsPayload = {
            'title': this.titleData,
            'subTitle': this.descriptionData,
            'sectionId': parseInt(this.selectedSection),
            'createdBy': userId?.id,
            'slug': 'home',
            'banner': this.payloadURL ? this.payloadURL : this.imgURL,
            'link': this.videoUrl
          };
          this.commonService.aboutUsMasterData(aboutUsPayload, this.data.data.id, this.isEditMode).subscribe((res:any) => {
            if(res.success){
              this.closeDialog(res);
            }
          },(error:any)=>{
            this.openSnackBar(error?.error?.message)
          })
        break;
        default:
        break;
      }
  }
  
  closeDialog(response:any = ''){
    if(response)
    this.openSnackBar(response?.message)
    this.dialogRef.close();
    console.log("library",this.libraryForm)
  }

  openDocument(){
    window.open(this.imgURL);
  }
  isValidRank(rank:any):boolean{
    return rank < 0 || rank == 0;
  }

  ngAfterViewInit() {    
    this.editor = new Quill(this.editorElement.nativeElement, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ align: [] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          // [{ 'font': [] }],
          ['link', 'image'],
          ['custom-font-size'],  // Include the custom font size in the toolbar
        ]
      }
    });
  
    const initialContent = this.descriptionData;
    this.editor.clipboard.dangerouslyPasteHTML(initialContent);
    this.editor.on('text-change', () => {
      this.descriptionData = this.editor.root.innerHTML;
    });
  
    const fontSizeConfig = {
      container: [
        { label: 'Small', value: '12px' },
        { label: 'Normal', value: '16px' },
        { label: 'Large', value: '24px' },
        { label: 'Huge', value: '36px' }
      ]
    };
  
    Quill.register('formats/size', fontSizeConfig); // Update the module name
    this.editor.getModule('toolbar').addHandler('custom-font-size', (value: any) => {
      this.editor.format('size', value); // Use 'size' instead of 'custom-font-size'
    });
  }
  
  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    panelClass: [className]
  });
}
isDisable():boolean{
  let isValid:boolean = false
  if(this.data?.currentTab == 'teams'){
     if(!this.teamMemberDesignation?.length || !this.teamMemberName?.length){
        isValid =  true;
     }
     else{
      isValid = false;
     }
  }
  if(this.data?.currentTab == 'ourPartners'){
    if(!this.teamMemberAbout?.length || !this.teamMemberName?.length || !(this.imgURL ?.length|| this.payloadURL?.length) || (!this.rank || this.rank == 0 || this.rank < 0)){
       isValid =  true;
    }
    else{
     isValid = false;
    }
 }
 if(this.data?.currentTab == 'glossary'){
  console.log('this.descriptionData',this.descriptionData);
  let textPattern = /(?:^|>)[^<]*?(?=<|$)/g;
  let matches = this.descriptionData.match(textPattern);
  let unenclosedLetters = matches.filter((match:any) => !match.includes('<p>') && !match.includes('</p>'));
  let replacedArray = unenclosedLetters.map((item:any) => item.replace(/>/g, '')).some((item:any) => item.length > 0);
  // let containsString = replacedArray.some((item:any) => item.length > 0);
  if(!this.termData?.length || !replacedArray){
     isValid =  true;
  }
  else{
   isValid = false;
  }
}
if(this.data?.currentTab == 'impactStories'){
  let textPattern = /(?:^|>)[^<]*?(?=<|$)/g;
  let matches = this.descriptionData.match(textPattern);
  let unenclosedLetters = matches.filter((match:any) => !match.includes('<p>') && !match.includes('</p>'));
  let replacedArray = unenclosedLetters.map((item:any) => item.replace(/>/g, '')).some((item:any) => item.length > 0);
  if(!(this.selectedSection !=0)|| !this.titleData?.length || !replacedArray || !(this.imgURL?.length || this.payloadURL?.length) || (!this.rank || this.rank == 0 || this.rank < 0)){
     isValid =  true;
  }
  else{
   isValid = false;
  }
}
if(this.data?.selectedSubTabName == 'Category' && this.data?.currentTab == 'resources'){
  if(!(this.selectedSection !=0)|| !this.titleData?.length || !(this.imgURL?.length|| this.payloadURL?.length)){
     isValid =  true;
  }
  else{
   isValid = false;
  }
}
if(this.data?.selectedSubTabName == 'Sub Category' && this.data?.currentTab == 'resources'){
  if(!(this.selectedSection !=0)|| !this.titleData?.length || !(this.imgURL?.length || this.payloadURL?.length)){
     isValid =  true;
  }
  else{
   isValid = false;
  }
}
if(this.data?.selectedSubTabName == 'Sub Category' && this.data?.currentTab == 'resources'){
  if(!(this.selectedSection !=0)|| !this.titleData?.length){
     isValid =  true;
  }
  else{
   isValid = false;
  }
}
if(this.data?.selectedSubTabName == 'Sub-Category Report' && this.data?.currentTab == 'resources'){
  if(!(this.selectedSection !=0)|| !this.titleData?.length || !(this.imgURL?.length || this.payloadURL?.length)){
     isValid =  true;
  }
  else{
   isValid = false;
  }
}
if(this.data?.currentTab == 'homepage' || this.data?.currentTab == 'aboutUs'){
  if(!(this.selectedSection !=0)|| !this.titleData?.length ){
     isValid =  true;
  }
  else{
   isValid = false;
  }
}
  return isValid
  
}

}
