<div class="Footer_bottom">
    <div class="themePosition">
        <div class="drawer" [@slideInOut]>
            <div class="close-icon">
                <!-- <a (click)="toggle()">
     
                    <i class="fa fa-times" style="color: aliceblue;" aria-hidden="true"></i>
                </a> -->
            </div>
     
            <div class="themeHeadingDiv d-flex my-3 justify-content-between">
                <div class="d-flex align-items-center">
                    <div class="me-2 pointer" (click)="toggle()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.9997 5.5865L11.9495 0.636719L13.3637 2.05093L8.4139 7.0007L13.3637 11.9504L11.9495 13.3646L6.9997 8.4149L2.04996 13.3646L0.635742 11.9504L5.5855 7.0007L0.635742 2.05093L2.04996 0.636719L6.9997 5.5865Z" fill="#444444"/>
                            </svg></div>
                    <p class="themeHeading fs-20 color-44 fw-600 m-0">Notifications</p>
                </div>
            
                <p class="markAll fs-16 m-0"><span>
                    <img src="../../../assets/images/tick-blue.svg" class="img-fluid me-1" alt="">
                </span> Mark all as read</p>
            </div>

            <nav>
                <div class="nav nav-tabs align-items-center position-relative" id="nav-tab" role="tablist">
                  <button class="nav-link fs-14 color-44 active d-flex align-items-center" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">All
                     <span class="messageCount rounded-circle text-white d-flex ms-2 align-items-center justify-content-center">33</span>
                </button>
                  <button class="nav-link fs-14 color-44 d-flex align-items-center" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Unread
                    <span class="messageCount rounded-circle text-white d-flex ms-2 align-items-center justify-content-center">13</span>
                  </button>
                  <button class="nav-link fs-14 color-44 d-flex align-items-center" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Requests
                    <span class="messageCount rounded-circle text-white d-flex ms-2 align-items-center justify-content-center">11</span>
                  </button>

                 <span class="position-absolute filter-div pointer">
                    <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                        <g id="Group_16821" data-name="Group 16821" transform="translate(-4157 -790)">
                          <path id="Path_6201" data-name="Path 6201" d="M0,0H20V20H0Z" transform="translate(4157 790)" fill="none"/>
                          <path id="Path_6202" data-name="Path 6202" d="M18,4V5.667h-.833L13,11.917V19H8V11.917L3.833,5.667H3V4ZM5.837,5.667l3.83,5.745v5.922h1.667V11.412l3.83-5.745Z" transform="translate(4156.5 789.333)"/>
                        </g>
                      </svg>
                 </span>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="fs-12 color-44 mt-3 mb-2 fw-500">Today</div>

                    <div class="d-flex justify-content-between align-items-center p-2 ps-0 chat-hover">
                        <div class="d-flex align-items-center">
                            <img src="../../../assets/images/ak-icon.svg" class="img-fluid me-2" alt="">
                            <div>
                                <div class="fs-14 color-44"><span class="fw-500">Pramit</span> from hnm is requesting sign up</div>
                                <p class="fs-11 color-44 m-0">2h</p>
                            </div>
                        </div>
                    
                        <div class="text-end">
                            <span class="me-3">
                                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.9997 10.1709L16.1921 0.978516L17.6063 2.39273L6.9997 12.9993L0.635742 6.6354L2.04996 5.2212L6.9997 10.1709Z" fill="#039403"/>
                                    </svg>
                                    
                            </span>
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.9997 5.5865L11.9495 0.636719L13.3637 2.05093L8.4139 7.0007L13.3637 11.9504L11.9495 13.3646L6.9997 8.4149L2.04996 13.3646L0.635742 11.9504L5.5855 7.0007L0.635742 2.05093L2.04996 0.636719L6.9997 5.5865Z" fill="#E52727"/>
                                    </svg>
                                    
                            </span>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center p-2 ps-0 chat-hover">
                        <div class="d-flex align-items-center">
                            <img src="../../../assets/images/sy-icon.svg" class="img-fluid me-2" alt="">
                            <div>
                                <div class="fs-14 color-44"><span class="fw-500">Pramit</span> from hnm has contacted factory_01 manager</div>
                                <p class="fs-11 color-44 m-0">4h</p>
                            </div>
                        </div>
                    </div>


                    <div class="fs-12 color-44 mb-2 mt-3 fw-500">Last Week</div>

                    <div class="d-flex justify-content-between align-items-center p-2 ps-0 chat-hover">
                        <div class="d-flex align-items-center">
                            <img src="../../../assets/images/sy-icon.svg" class="img-fluid me-2" alt="">
                            <div>
                                <div class="fs-14 color-44"><span class="fw-500">Pramit</span> from hnm has contacted factory_01 manager</div>
                                <p class="fs-11 color-44 m-0">19/02/2023</p>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center p-2 ps-0 chat-hover">
                        <div class="d-flex align-items-center">
                            <img src="../../../assets/images/ak-icon.svg" class="img-fluid me-2" alt="">
                            <div>
                                <div class="fs-14 color-44"><span class="fw-500">Pramit</span> from hnm is requesting sign up</div>
                                <p class="fs-11 color-44 m-0">Form <span class="dot position-relative">20/02/2023</span></p>
                            </div>
                        </div>
                    
                        <div class="text-end">
                            <span class="me-3">
                                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.9997 10.1709L16.1921 0.978516L17.6063 2.39273L6.9997 12.9993L0.635742 6.6354L2.04996 5.2212L6.9997 10.1709Z" fill="#039403"/>
                                    </svg>
                                    
                            </span>
                            <span>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.9997 5.5865L11.9495 0.636719L13.3637 2.05093L8.4139 7.0007L13.3637 11.9504L11.9495 13.3646L6.9997 8.4149L2.04996 13.3646L0.635742 11.9504L5.5855 7.0007L0.635742 2.05093L2.04996 0.636719L6.9997 5.5865Z" fill="#E52727"/>
                                    </svg>
                                    
                            </span>
                        </div>
                    </div>

                    <button class="admin-btn btn d-flex align-items-center mt-4 text-white rounded-pill fs-12">Admin View</button>
                    
                </div>
                <!-- <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
                <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div> -->
              </div>

        </div>
     
    </div>
</div>