<div class="Footer_bottom">
  <section class="first-section d-flex flex-column align-items-center justify-content-center" [ngStyle]="{'background-image': 'url(' + ( aboutUS[0].banner) + ')'}">
    <div class="container-fluid">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6">
  <!-- <div class="content-box  text-center" style="padding: 0 40px;"> -->
    <!-- <div>
        <img src="../../../assets/images/arrow-left.svg" class="img-fluid pointer" routerLink="/home" alt="">
    </div> -->
    <h1 class="heading-1 text-center text-white">{{aboutUS[0].title ? aboutUS[0].title : 'About INSTEP'}}</h1>
    <!-- <p style="font-size: 14px; color: #F9F9F9;">IN Sustainable Textile and Apparel Parks (INSTEP) is a collaborative aporoach to step up the sustainable production of textile and apparel via Industrial Parks, reaching multiple factories and shared facilities with assessments and interventions to reduce environmental impact and improve working conditions. INSTEP contributes to systemic change within the textile and apparel industry and supports brands sustainable sourcing commitments not zero goals, GHG reductions, and the UN SDGs</p> -->
  <!-- </div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="para-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8" *ngIf="aboutUS && aboutUS.length">
                <!-- <p>IN Sustainable Textile and Apparel Parks (INSTEP) is a collaborative aporoach to step up the sustainable production of textile and apparel via Industrial Parks, reaching multiple factories and shared facilities with assessments and interventions to reduce environmental impact and improve working conditions. INSTEP contributes to systemic change within the textile and apparel industry and supports brands sustainable sourcing commitments not zero goals, GHG reductions, and the UN SDGs</p> -->
                <!-- <p>{{aboutUS[0].subTitle}}</p> -->
                <!-- <p [innerHTML]="stripHtmlTags(aboutUS[0].subTitle)"></p> -->
                <!-- <p [innerHTML]="aboutUS[0].subTitle"></p> -->
                <p [innerHTML]="aboutUS[0].subTitle | sanitizeHtml"></p>

            </div>
        </div>
    </div>
  </section>
</div>