// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let isShowCom:boolean = true;
let powerBIlink:string = "";

let base_uri2 = 'https://stg_idhinstep.dhwaniris.in/web/api/';
let baseURL = "https://uat_idhinstep.dhwaniris.in/web/api/";
//  let baseURL = "http://192.168.201.106:3000/web/api/";

let origin = window.location.hostname;
let secratKey ="1234";
let captchaSecretKet = '6LdqKrMmAAAAAJ7DYHSe6wThAtPnhYyEkP099ZbP'

// UAT Origin
if(origin == 'uatidhinstepui.dhwaniris.in'){
  baseURL = "https://uat_idhinstep.dhwaniris.in/web/api/";
  powerBIlink = "https://app.powerbi.com/view?r=eyJrIjoiNjQzOGMzYzMtMDdmYi00YmNiLThmNDYtYmM0YTg4Y2JjMTExIiwidCI6IjZiZTk4NzZkLTI0NzAtNDJjYi05ZjQyLTA2MDQ1NGZhYjUzZCJ9";
}

// Staging and Local Origin
if(origin == 'stgidhinstepui.dhwaniris.in' || origin == 'localhost'){
  baseURL = "https://stg_idhinstep.dhwaniris.in/web/api/";
  isShowCom = true;
  powerBIlink = "https://app.powerbi.com/view?r=eyJrIjoiNjQzOGMzYzMtMDdmYi00YmNiLThmNDYtYmM0YTg4Y2JjMTExIiwidCI6IjZiZTk4NzZkLTI0NzAtNDJjYi05ZjQyLTA2MDQ1NGZhYjUzZCJ9";
}

// Production Origin
if(origin == 'www.idh-instep.com' || origin == 'idh-instep.com'){
  baseURL = "https://api.idh-instep.com/web/api/";
  powerBIlink = "https://app.powerbi.com/view?r=eyJrIjoiYmI1MWZkNDItNjEwNS00OTlkLWJiZWMtMTY3ODczZTdkODUyIiwidCI6IjZhZTM0N2ExLTVlM2MtNDNkZS1iMjE5LTc4NWJkYWRmZDNmYiIsImMiOjh9";
}

export const environment = {
  production: true,
  url2: base_uri2,
  baseURL: baseURL,
  secratKey:secratKey,
  captchaSecretKet:captchaSecretKet,
  isShowCom : isShowCom,
  powerBIlink: powerBIlink
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
