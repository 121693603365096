import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  loaderSubscriber =  new BehaviorSubject<any>(false)
  message =  new BehaviorSubject<any>('')
}
