import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';


@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss']
})
export class CommonFilterComponent implements OnInit {
  @Input() countryData: any = [];
  @Input() isTabChange: boolean = false;
  @Input() showCountry: boolean = false;
  @Input() removedIPchip: boolean = false;
  @Input() removedCountryChip: boolean = false;
  @Input() removedFactoryChip: boolean = false;
  @Input() selectedTab: string = '';
  @Output() resetButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkedStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() isCheckedCountry: EventEmitter<any> = new EventEmitter<any>();
  selectedCountry: any = [];
  selectedIpView: any = [];
  selectedFactroy: any = [];
  ipViewData: any = []
  factoryData: any = []
  showAllCountry: boolean = false;
  showAllIpView: boolean = false;
  showAllFactory: boolean = false;
  tempIpViewData: any = [];
  tempFactoryData: any = [];
  filterText: any = {'countryview':'Country Filter','ipview': 'IP Filter', 'factoryview':'Factory Filter'};

  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger | undefined;
  isApplied: boolean = false;
  isActive = true;
  isChangedTab:boolean = false;
  countrySwitch: any = -1;
  constructor(private commonService: CommonServiceService) {

  }

  ngOnInit(): void {
    // this.toggleShowMore('country');

  }


  apply(isAuto: boolean = false) {
    this.selectedCountry = this.countryData.filter((el: any) =>  el?.is_checked);
    this.selectedIpView = this.ipViewData.filter((el: any) =>  el?.is_checked);
    this.selectedFactroy = this.factoryData.filter((el: any) =>  el?.is_checked);
    this.applyButton.emit({ countryData: this.selectedCountry, ipViewData: this.selectedIpView, factoryData: this.selectedFactroy,isAuto:isAuto });
    this.isApplied = true;
    if(this.selectedTab != 'programview' && !isAuto)
    this.isChangedTab = true;
    if (this.matMenuTrigger) {
      
      this.matMenuTrigger.closeMenu();
    }

  }

  ngOnChanges(change:SimpleChanges){
    console.log("changesimplete",change)
    // if(change?.isTabChange && change?.isTabChange?.firstChange == false){
    //   this.apply();
    // }

    // if(change && !change?.showCountry?.currentValue){
    //   // this.ipViewData = [];
    //   // this.factoryData = [];
    // }

    if(change && (change?.countryData?.currentValue || (change?.selectedTab?.currentValue && change?.selectedTab?.currentValue.length))){
      // if(this.countryData.length == 1){
        console.log("selectedCountry", this.selectedCountry, this.countryData);
        let event:any = {checked: true};
        let selectedCountry:any = this.countryData[0]
        if(this.isChangedTab){
          selectedCountry = this.selectedCountry[0]
        }
        this.onModuleChange(event,selectedCountry,'country',true, true)
      // }
    }

    if(change && change?.removedCountryChip?.currentValue){
      this.reset();
    }

    if(change && change?.removedIPchip?.currentValue){
      console.log("this.ipViewData",this.ipViewData);
      this.selectedIpView.map((ele:any) => ele.is_checked = false);
      this.selectedIpView = []
      if(!this.selectedIpView.length){
      this.selectedFactroy.map((ele:any) => ele.is_checked = false);
        this.selectedFactroy = [];
      }
      console.log("this.ipViewData",this.ipViewData);
      this.apply();
    }

    if(change && change?.removedFactoryChip?.currentValue){
      this.selectedFactroy.map((ele:any) => ele.is_checked = false);
      this.selectedFactroy = [];
      this.apply();
    }
  }

  reset() {
    this.countryData = []
    this.selectedCountry = [];
    this.ipViewData = [];
    this.selectedIpView = [];
    this.factoryData = [];
    this.selectedFactroy = [];
    this.resetButton.emit({})
    if (this.matMenuTrigger) {
      this.matMenuTrigger.closeMenu();
    }

  }

  isCheckedModule(module: any): any {
    if (module?.is_checked) {
      module.is_checked = module.is_checked;
    }
    else {
      module.is_checked = module.is_checked;
    }
  }

  onModuleChange(event: any, module: any, type: string, isAuto: boolean =false, isCountryWise:boolean =false): void {
    console.log("countryDataEventChange", module,isAuto,"###",this.isChangedTab,event,type,this.countryData);
    // this.isCheckedCountry.emit(this.countryData);
    this.isApplied = false;
    switch (type) {
      case 'country':
        if (event?.checked) {
          let data = {
            type : 'country',
            status: true
          }
          // this.checkedStatus.emit(data);
          this.countryData = this.countryData.map((el: any) => {
            if (module?.id === el?.id || (isAuto && !this.isChangedTab)) {
              el.is_checked = event?.checked
            }
            return el
          })
          // if(isAuto)
          // this.selectedCountry = this.countryData

          let selectedMap:any = this.countryData.filter((data:any) => data.is_checked)
          selectedMap = selectedMap.length ? selectedMap.map((data:any) => data.id).join(',') : '';
          // if (event?.checked) {
            if(selectedMap)
            this.getIpViewsByCountry(selectedMap, isAuto, true, module?.id);

          // }
          this.selectedCountry = this.countryData.filter((el: any) =>  el?.is_checked);
          if(this.selectedTab == 'countryview')
          this.apply(isAuto)

        }
        else {
          console.log("this.countryData",this.countryData);
          let dataCheck = this.countryData.filter((ele:any) => ele.is_checked);
          let data = {
            type : 'country',
            status: dataCheck.length ? true : false
          }
          // this.checkedStatus.emit(data);
          this.countryData = this.countryData.map((el: any) => {
            if (module?.id === el?.id) {
              el.is_checked = event?.checked
            }
            return el
          })
          let selectedCountry:any = this.countryData.filter((el: any) => el?.is_checked);
          let mappedData:any = selectedCountry.length ? selectedCountry.map((data:any) =>  data.id) : []
          // if (selectedCountry?.length) {
          //   this.getIpViewsByCountry(module?.id)
          // }
          this.selectedCountry = this.countryData.filter((el: any) =>  el?.is_checked);
          console.log("this.selectedCountry",this.selectedCountry);
          
          let unCheckCountry = module.id;
          console.log("uncheckCountry", unCheckCountry);
          console.log("selectedIpView", this.selectedIpView)
          if (selectedCountry?.length) {

            const indexToRemove: any = [];
            const ipIdToDeleteForFactory: any = [];
            // this.ipViewData = this.ipViewData.filter((data:any) => mappedData.includes(data['country_id']))
            this.ipViewData = this.ipViewData.filter((ip:any) => ip.country_id != unCheckCountry)

            // this.ipViewData.map((d: any, i: number) => {
            //   if (d.country_id === unCheckCountry) {
            //     indexToRemove.push(i);
            //     ipIdToDeleteForFactory.push(d.id);
            //   }
            // })
            // if (indexToRemove.length) {
            //   indexToRemove.map((indexToDelete: any) => {
            //     this.ipViewData = this.ipViewData.splice(indexToDelete, 1);
            //   })
            // }
            // if (ipIdToDeleteForFactory?.length) {
            //   for (let item of ipIdToDeleteForFactory) {
            //     this.factoryData = this.factoryData.splice(item, 1);
            //   }
            // }

            this.factoryData = this.factoryData.filter((factory:any) => factory['industrialpark.country.id'] != unCheckCountry)
            this.apply(isAuto)
            setTimeout(() => {
              console.log('ipviewData', this.ipViewData)
              console.log('factoryData', this.factoryData)
            }, 2000)
          } else {
            this.ipViewData = [];
            this.factoryData = [];
            // this.selectedIpView = [];
            // this.selectedFactroy = [];
          }
          

        }
        break;

      case 'ipView':
        if (event?.checked) {
          let data = {
            type : 'IPs',
            status: true
          }
          // this.checkedStatus.emit(data);
          this.ipViewData = this.ipViewData.map((el: any) => {
            if (module?.id === el?.id || (isAuto && !this.isChangedTab)) {
              el.is_checked = event?.checked
            }
            return el
          })
          // let selectedIpView = this.ipViewData.filter((el: any) =>  el?.is_checked);

          let selectedMap:any = this.ipViewData.filter((data:any) => data.is_checked)
          selectedMap = selectedMap.length ? selectedMap.map((data:any) => data.id).join(',') : '';
          if (selectedMap) {
            this.getFactoryByIpViews(selectedMap, isAuto, isCountryWise, module?.id);
          }

        }
        else {
          let dataCheck = this.ipViewData.filter((ele:any) => ele.is_checked);
          let data = {
            type : 'IPs',
            status: dataCheck.length ? true : false
          }
          // this.checkedStatus.emit(data);
          this.ipViewData = this.ipViewData.map((el: any) => {
            if (module?.id === el?.id) {
              el.is_checked = event?.checked
            }
            return el
          })

          let selectedIpView = this.ipViewData.filter((el: any) => el?.is_checked);
          let unCheckIpView = module.id;
          console.log("uncheckCountry", unCheckIpView);
          console.log("selectedIpView", this.selectedFactroy)
          if (selectedIpView?.length) {
            const indexToRemove: any = [];
            const ipIdToDeleteForFactory: any = [];
            // this.factoryData = this.factoryData.filter((d: any, i: number) => {
            //   return d.industrialpark_id !== unCheckIpView
            // })

            this.factoryData.forEach((factory:any) => {
                if(factory.industrialpark_id == unCheckIpView)
                factory.is_checked = false
            });
            // this.selectedFactroy = this.factoryData.filter((el:any)=>el?.is_checked)
            console.log("selectedIpView", this.selectedFactroy,this.selectedFactroy)
            // this.factoryData.map((d: any, i: number) => {
            //   if (d.industrialpark_id === unCheckIpView) {
            //     indexToRemove.push(i);
            //   }
            // })
            // if (indexToRemove.length) {
            //   indexToRemove.map((indexToDelete: any) => {
            //     this.factoryData = this.factoryData.splice(indexToDelete, 1);
            //   })
            // }
          } else {
            this.factoryData = [];
            // this.selectedFactroy = []
          }

        }
        break;

      case 'factory':
        if (event?.checked) {
          let data = {
            type : 'factory',
            status: true
          }
          // this.checkedStatus.emit(data);
          this.factoryData = this.factoryData.map((el: any) => {
            if (module?.id === el?.id || (isAuto && !this.isChangedTab)) {
              el.is_checked = event?.checked
            }
            return el
          })
          let selectedFactroy = this.factoryData.filter((el: any) => el?.is_checked);
          if(this.selectedTab == 'factoryview' && isAuto)
          this.apply(isAuto)

        }
        else {
          let dataCheck = this.factoryData.filter((ele:any) => ele.is_checked);
          let data = {
            type : 'factory',
            status: dataCheck.length ? true : false
          }
          // this.checkedStatus.emit(data);
          this.factoryData = this.factoryData.map((el: any) => {
            if (module?.id === el?.id) {
              el.is_checked = event?.checked
            }
            return el
          })
          let selectedFactroy = this.factoryData.filter((el: any) => el?.is_checked);
          console.log("unsele", this.selectedFactroy);

        }
        break;
    }
  }

  selectAll(event: any, type: string) {
    console.log("eve", event);
    switch (type) {
      case 'country':
        this.showAllCountry = true;
        
        if (event.checked) {
          let data = {
            type : 'country',
            status: true
          }
          // this.checkedStatus.emit(data);
          this.countryData = this.countryData?.map((el: any) => {
            el.is_checked = event?.checked;
            return el;
          });
          let selectedMap:any = this.countryData.filter((data:any) => data.is_checked)
          selectedMap = selectedMap.length ? selectedMap.map((data:any) => data.id).join(',') : '';
          // this.selectedCountry = this.countryData.filter((el: any) => el?.is_checked)
          if (selectedMap) {
            // this.ipViewData = [];
            // this.selectedIpView = [];
            // this.selectedFactroy = [];
            // this.factoryData = [];

            // this.selectedCountry.map((data:any)=>{
              this.getIpViewsByCountry(selectedMap);
            // })
          }

        } else {
          // let dataCheck = this.countryData.filter((ele:any) => ele.is_checked);
          let data = {
            type : 'country',
            status: false
          }
          // this.checkedStatus.emit(data);
          this.countryData = this.countryData?.map((el: any) => {
            el.is_checked = event?.checked;
            return el;
          })
          // this.selectedCountry = [];
          this.ipViewData = [];
          this.factoryData = [];
          // this.selectedFactroy = [];
          // this.selectedIpView = [];
        }
        break;
      case 'ipView':
        this.showAllIpView = true;
        if (event.checked) {
          let data = {
            type : 'IPs',
            status: true
          }
          // this.checkedStatus.emit(data);
          this.ipViewData = this.ipViewData?.map((el: any) => {
            el.is_checked = event?.checked;
            return el;
          });
          // this.selectedIpView = this.ipViewData.filter((el: any) => el?.is_checked);
          let selectedMap:any = this.ipViewData.filter((data:any) => data.is_checked)
          selectedMap = selectedMap.length ? selectedMap.map((data:any) => data.id).join(',') : '';
          if (selectedMap) {
            // this.factoryData = [];
            // this.selectedFactroy = [];
            // this.getFactoryByIpViews();
            // this.selectedIpView.map((data:any)=>{
              this.getFactoryByIpViews(selectedMap);
            // })
          }

        } else {
          // let dataCheck = this.ipViewData.filter((ele:any) => ele.is_checked);
          let data = {
            type : 'IPs',
            status: false
          }
          // this.checkedStatus.emit(data);
          this.ipViewData = this.ipViewData?.map((el: any) => {
            el.is_checked = event?.checked;
            return el;
          })
          // this.selectedIpView = [];
          this.factoryData = [];
        }
        break;
      case 'factory':
        this.showAllFactory = true;
        if (event.checked) {
          let data = {
            type : 'factory',
            status: true
          }
          // this.checkedStatus.emit(data);
          this.factoryData = this.factoryData?.map((el: any) => {
            el.is_checked = event?.checked;
            return el;
          });
          // this.selectedFactroy = this.factoryData.filter((el: any) => el?.is_checked)
          // this.getIpViewsByCountry();
        } else {
          // let dataCheck = this.factoryData.filter((ele:any) => ele.is_checked);
          let data = {
            type : 'factory',
            status: false
          }
          // this.checkedStatus.emit(data);
          this.factoryData = this.factoryData?.map((el: any) => {
            el.is_checked = event?.checked;
            return el;
          })
          // this.selectedFactroy = [];
          // this.ipViewData = [];
        }
        break;
    }
    console.log("countryData", this.countryData)
  }


  isModuleOver(item: any[], type: string): boolean {
    if (type === 'country') {
      const checkedItems = this.countryData.filter((item: any) => item.is_checked);
      return checkedItems.length > 0 && checkedItems.length < this.countryData.length;
    }
    else if (type === 'ipView') {
      const checkedItems = this.ipViewData.filter((item: any) => item.is_checked);
      return checkedItems.length > 0 && checkedItems.length < this.ipViewData.length;
    }
    else {
      const checkedItems = this.factoryData.filter((item: any) => item.is_checked);
      return checkedItems.length > 0 && checkedItems.length < this.factoryData.length;
    }
  }



  // updateDisplayedItems(type: string) {
  //   if (type === 'country') {
  //     this.countryData = this.showAllCountry ? this.tempCountryData : this.tempCountryData.slice(0, 2)
  //   }
  //   else if (type === 'ipView') {
  //     this.ipViewData = this.showAllIpView ? this.tempIpViewData : this.tempIpViewData.slice(0, 4)
  //   }
  //   else if (type === 'factory') {
  //     this.factoryData = this.showAllFactory ? this.tempFactoryData : this.tempFactoryData.slice(0, 4)
  //   }

  // }
  toggleShowMore(type: string) {
    switch (type) {
      case 'country': this.showAllCountry = !this.showAllCountry;
        // this.updateDisplayedItems(type);
        break;
      case 'ipView': this.showAllIpView = !this.showAllIpView;
        // this.updateDisplayedItems(type);
        break;
      case 'factory': this.showAllFactory = !this.showAllFactory;
        // this.updateDisplayedItems(type);
        break;
    }
  }

  getIpViewsByCountry(country_id:string, isAuto: boolean = false, isCountryWise:boolean = false, currentId:any = 0) {
      this.commonService.ipNameDetails('', '', country_id).subscribe((res: any) => {
        if (res?.data?.length) {
          if(!isAuto){
            this.countrySwitch = currentId;
          }else{
            this.countrySwitch = -1;
          }
          let selectedData:any = this.isChangedTab ? JSON.parse(JSON.stringify(this.selectedIpView)) : JSON.parse(JSON.stringify(this.ipViewData));
          let countryIds = country_id.split(',')
          let checkedIps = selectedData.filter((ip:any) => ip.is_checked);
          checkedIps = checkedIps?.length ? checkedIps.map((ip:any) => ip.id) : [];
          res?.data.map((data:any)=>{
            // if(data.is_checked){
              data.is_checked =  (!isAuto && currentId == data.country_id) || checkedIps.includes(data.id)  ? true : false;
              // data.is_checked = true;
            // }
            // this.ipViewData.push(data);
          })
          console.log("getCountry", currentId, isAuto, checkedIps, res.data, selectedData,countryIds,this.isChangedTab, country_id)
          this.ipViewData = [...res?.data]

        }

      }, (error: any) => {
        console.log("err", error)
      }, () => {
        if (this.ipViewData?.length) {
          let key:string = 'id';
          this.ipViewData = [...new Map(this.ipViewData.map((item:any) =>
            [item[key], item])).values()];
          this.tempIpViewData = this.ipViewData;
          console.log("tempip", this.tempIpViewData)
          // if(this.ipViewData.length == 1){
            let event:any = {checked: true};
          //   if(isAuto)
          // this.selectedIpView = this.ipViewData
          let ipId = this.ipViewData.find((ip:any) => ip.is_checked);
        this.onModuleChange(event,ipId,'ipView', isAuto, isCountryWise);

        if(this.selectedTab == 'countryview' && isAuto)
          this.apply(isAuto)
        if(this.selectedTab == 'ipview')
        this.apply(isAuto)
          // }
          this.toggleShowMore('ipView')
        }
      })
  }

  getFactoryByIpViews(ipViewId:string, isAuto: boolean = false, isCountryWise:boolean =false, currentId:any = 0) {
    // this.selectedIpView.map(async (ipView: any) => {
      console.log("factory", this.selectedFactroy,this.factoryData,isAuto)
      this.commonService.factoryNameDetails('','', ipViewId).subscribe((res: any) => {
        if (res?.data?.length) {
          let selectedData:any = this.isChangedTab ? JSON.parse(JSON.stringify(this.selectedFactroy)) : JSON.parse(JSON.stringify(this.factoryData));
          let checkedFactorys = selectedData.filter((factory:any) => factory.is_checked);
          checkedFactorys = checkedFactorys?.length ? checkedFactorys.map((factory:any) => factory.id) : [];
          // this.factoryData = this.factoryData.concat(res?.data)
          let factoryIds = ipViewId.split(',')
          res?.data.map((data:any)=>{
            data.is_checked = (!isAuto && currentId == data.industrialpark_id) || checkedFactorys.includes(data.id) || this.countrySwitch == data['industrialpark.country.id'] ? true : false;
          })
          console.log("getCountry", isCountryWise, currentId, isAuto, checkedFactorys, res.data, selectedData,factoryIds,this.isChangedTab)
          this.factoryData = [...res?.data]
        }

      }, (error: any) => {
        console.log(error)
      }, () => {
        if (this.factoryData?.length) {
          let key:string = 'id';
          this.factoryData = [...new Map(this.factoryData.map((item:any) =>
            [item[key], item])).values()];
          this.tempFactoryData = this.factoryData;
         
          // if(this.factoryData.length == 1){
          let event:any = {checked: true};
          let factoryId = this.factoryData.find((factory:any) => factory.is_checked);
        this.onModuleChange(event,factoryId,'factory', isAuto, isCountryWise);
          // if( this.selectedTab == 'ipview' && isAuto)
          // this.apply(isAuto)
          // if(isAuto)
          // this.selectedFactroy = this.factoryData

          if(this.selectedTab == 'factoryview' && isCountryWise)
        this.apply(isAuto)

          this.toggleShowMore('factory');
          // }
        }
      })
    // })

  }
  isCountryAllSelect(data: any): boolean {
    return data.every((el: any) => el?.is_checked)
  }

  allowCloseOnClickOut(event:any= {}){
    let isCheckedCountry = this.selectedCountry.map((option:any) => option.id);
    let isCheckedIP = this.selectedIpView.map((option:any) => option.id);
    let isCheckedFactory = this.selectedFactroy.map((option:any) => option.id);
    if(!this.isApplied){
      
      this.countryData.forEach((country:any) => {
        country.is_checked = false;
        if(isCheckedCountry.includes(country.id))
        country.is_checked = true;
        
      });

      this.ipViewData.forEach((country:any) => {
        country.is_checked = false;
        if(isCheckedIP.includes(country.id))
        country.is_checked = true;
        
      });

      this.factoryData.forEach((country:any) => {
        country.is_checked = false;
        if(isCheckedFactory.includes(country.id))
        country.is_checked = true;
        
      });


    }
    console.log("selectedCountry",event, this.isApplied, isCheckedIP,this.ipViewData, this.selectedIpView)
  }

  isDisabled(){
    let isDisabled: boolean =false;
    if(this.selectedTab == 'countryview'){
      isDisabled = this.countryData.find((data:any) => data.is_checked) ? false : true;
    }
    if(this.selectedTab == 'ipview'){
      isDisabled = this.ipViewData.find((data:any) => data.is_checked) ? false : true;
    }
    if(this.selectedTab == 'factoryview'){
      isDisabled = this.factoryData.find((data:any) => data.is_checked) ? false : true;
    }

    return isDisabled;
  }

}
