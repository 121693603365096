import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as JSZip from 'jszip';
import { saveAs } from "file-saver";
import { ChartService } from 'src/app/shared/services/chart.service';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-historical-sites',
  templateUrl: './historical-sites.component.html',
  styleUrls: ['./historical-sites.component.scss']
})
export class HistoricalSitesComponent implements OnInit {

  @Input() siteAbout:string = '';
  @Input() scoreCardObj:any = {};
  @Input() allImageData:any = [];

  aboutTextLess = `The Golden Temple is famous for its full golden dome, it is one of the most sacred pilgrim spots for sikhs. the mandir is built on a 67-ft square of marble and is a two storied structure. maharaja ranjit singh had the upper half of the building built with approximately 400 kg of gold leaf. The golden temple is surrounded by a number of other famous temples like the durgiana temple. the fourth guru of sikhs, guru ram das, who had initially constructed a pool here, founded amritsar, which houses the golden temple or harmandir sahib. It is here that sage valmiki wrote the epic, ramayana. rama and sita are believed to have spent their fourteen-year exile in amritsar, the epicenter`;
  aboutTextMore = `of sikhism. to the south of the temple is a garden, and the tower of baba atal. the central sikh museum is atop the clock tower.`;
  ratingCard = [
    {
      id: '1',
      // path: '../../../assets/historical/crying-black-icon.svg',
      name: 'Site Overall',
      percentScore: "overall_score"
    },
    {
      id: '2',
      // path: '../../../assets/historical/emoji-smiley-black-icon-1.svg',
      name: 'Accessibility',
      percentScore: "overall_accessibility"
    },
    {
      id: '3',
      // path: '../../../assets/historical/emoji-smiley-black-icon.svg',
      name: 'Amentities',
      percentScore: "overall_amenities"
    },
    {
      id: '4',
      // path: '../../../assets/historical/emoji-laugh-black-icon-1.svg',
      name: 'Hygiene',
      percentScore: "overall_hygiene"
    },
    {
      id: '5',
      // path: '../../../assets/historical/emoji-laugh-black-icon.svg',
      name: 'Safety & Security',
      percentScore: "overall_safety"
    },
  ]
  ratingStatus = [
    {
      id: '1',
      path: '../../../assets/historical/emoji-laugh-black-icon-1.svg',
      name: 'Satisfactory (66% or above)'
    },
    {
      id: '2',
      path: '../../../assets/historical/confused-icon.svg',
      name: 'Moderate (34% to 65%)'
    },
    {
      id: '3',
      path: '../../../assets/historical/crying-black-icon.svg',
      name: 'Unsatisfactory (Below 33%)'
    },
    // {
    //   id: '4',
    //   path: '../../../assets/historical/crying-black-icon.svg',
    //   name: '25% to 50%'
    // },
    // {
    //   id: '5',
    //   path: '../../../assets/historical/emoji-sad-black-icon.svg',
    //   name: '<25%'
    // },
  ]
  titles = ['About', 'Tourist Overall Perception'];
  readMore = 'Read More';
  tName = 'Golden Temple';
  // imageObject = [{
  //   image: "../../../assets/historical/download (1).jpg",
  //   thumbImage: "../../../assets/historical/download (1).jpg",
  // }, {
  //   image: "../../../assets/historical/download (2).jpg",
  //   thumbImage: "../../../assets/historical/download (2).jpg",
  // }, {
  //   image: "../../../assets/historical/download (3).jpg",
  //   thumbImage: "../../../assets/historical/download (3).jpg",
  // }, {
  //   image: "../../../assets/historical/download (4).jpg",
  //   thumbImage: "../../../assets/historical/download (4).jpg",
  // }, {
  //   image: "../../../assets/historical/download.jpg",
  //   thumbImage: "../../../assets/historical/download.jpg",
  // }, {
  //   image: "../../../assets/historical/images (1).jpg",
  //   thumbImage: "../../../assets/historical/images (1).jpg",
  // }, {
  //   image: "../../../assets/historical/images (2).jpg",
  //   thumbImage: "../../../assets/historical/images (2).jpg",
  // }, {
  //   image: "../../../assets/historical/images.jpg",
  //   thumbImage: "../../../assets/historical/images.jpg",
  // }];
  id1:string = "barchartId1";
  id2:string = "barchartId2";
  type:string = "bar";
  activeButton:any;
  data:any = {
    labels: ['Golden Temple', 'Spiritual Religious Avg.', 'State Avg.', 'National Avg.'],
    datasets: [{
      label:"",
      barThickness: 20,
      data: [4,3,3,2.4],
      backgroundColor: "blue",
      borderColor:"blue",
      borderWidth: 1
    }],
  }
  chartRef:any[] = [];
  templeName = 'Accessibility';

  amenitiesJson:any = {
    type: 'bar',
    data : {
      labels: ['Akshardham Temple', 'Total Visitors', 'Avg. Visitor Per Day', 'Total Security'],
      datasets: [{
        label:"",
        barThickness: 20,
        data: [1.4,2.5,4,2],
        backgroundColor: "blue",
        borderColor: "blue",
        borderWidth: 1
      }]
    }
  };
  imageClick: boolean = false;

  constructor(private chartService:ChartService, private router: Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log("allImageData", this.allImageData);
    
  }
  imageClickHandler(e:any) {
    this.imageClick = true;
  }

  lbBox(e : any){
    this.imageClick = false;
  }
  seeMore() {
    let dots: any = document.getElementById("dots");
    let moreText: any = document.getElementById("more");
    let btnText: any = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  ngAfterViewInit(){
    // this.accessibilityData(1);
  }

  // accessibilityData(event:any){
  //   this.router.navigate(['/accessibility'])
  //   this.activeButton = event;
  //   this.destroy();
  //   setTimeout(() => {
  //     this.chartRef.push( this.chartService.verticalBar(this.id1, this.data, this.type));
  //     this.chartRef.push( this.chartService.verticalBar(this.id2, this.data, this.type));
  //   }, 500);
  // }

  // amenitiesData(event:any){
  //   this.activeButton = event;
  //   this.destroy();
  //   setTimeout(() => {
  //     this.chartRef.push( this.chartService.verticalBar(this.id1, this.amenitiesJson.data, this.amenitiesJson.type));
  //     this.chartRef.push( this.chartService.verticalBar(this.id2, this.amenitiesJson.data, this.amenitiesJson.type));
  //   }, 500);
  // }

  // hygieneData(event:any){
  //   this.activeButton = event;
  //   this.destroy();
  //   setTimeout(() => {
  //     this.chartRef.push( this.chartService.verticalBar(this.id1, this.data, this.type));
  //     this.chartRef.push( this.chartService.verticalBar(this.id2, this.data, this.type));
  //   }, 500);
  // }

  // safetySecurityData(event:any){
  //   this.activeButton = event;
  //   this.destroy();
  //   setTimeout(() => {
  //     this.chartRef.push( this.chartService.verticalBar(this.id1, this.data, this.type));
  //     this.chartRef.push( this.chartService.verticalBar(this.id2, this.data, this.type));
  //   }, 500);
  // }

  destroy(){
    this.chartRef.forEach(el => {
      el.destroy();
    })
  }

  // async getBase64ImageFromUrl(imageUrl:string) {
  //   var res = await fetch(imageUrl);
  //   var blob = await res.blob();
  //   return new Promise((resolve, reject) => {
  //     var reader = new FileReader();
  //     reader.addEventListener("load", () => {
  //       resolve(reader.result?.toString());
  //     }, false);
  //     reader.onerror = () => {
  //     return reject(this);
  //     };
  //     reader.readAsDataURL(blob);
  //   });
  // }
 
  // async downloadZipImages(){
  //   console.log(this.allImageData);
  //   let zip = new JSZip();
  //   // zip.folder('images');
  //   let i = 1;
  //   for(let el of this.allImageData){
  //     await this.getBase64ImageFromUrl(el.image).then((res:any) => {
  //       zip.folder("images")?.file("image_"+i+".jpg", res);
  //     });
  //     i++
  //   // }allFiles.forEach
  //   }
  //   zip.generateAsync({type:"blob"}).then(content => {
  //     saveAs(content, "allImages.zip");
  //   });
  // }
// ---------------------------------------

isUrl(url:any) {
  var regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(url);
}

async getBase64ImageFromUrll(imageUrl:any) {

  let isUrl = this.isUrl(imageUrl);
  console.log("URLSsss",imageUrl, isUrl);
  
  if(isUrl){
    var res = await fetch(imageUrl+"?stopGivingMeHeadaches=true");
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result?.toString());
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
}

}

  downloadZip(){
    let zip = new JSZip();
    let pdf = zip.folder("images");
    console.log("allImageData",this.allImageData);
    this.allImageData.forEach(async (iterator:any,i:any) => {
     await this.getBase64ImageFromUrll(iterator.image).then((res:any)=>{
      console.log("theResp",res);
      
        let data = iterator.image.split('.');
        let splitForDocNme = iterator.image.split('/');
        let docName = splitForDocNme[splitForDocNme['length']-1]
        let lastInd = data[data.length-1];
        let imgData = res.toString().split(',');
        console.log('docName',docName);
        console.log('lastInd',lastInd);
        pdf?.file(docName,imgData[1], { base64: true });
      })
    });
    setTimeout(() => {
      zip.generateAsync({type:"blob"})
      .then((content:any) => {
          saveAs(content, 'allImages.zip');
      });
    }, 900);
  }

}
