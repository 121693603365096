import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserManagementServiceService } from '../user-management-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { ActivatedRoute } from '@angular/router';






export interface tableConfiguration<T>{
  usePagination?:boolean,
  paginatorPosition?: "top" | "bottom",
  addButton?:string | boolean,
  title?:string,
  paginationConfig?:{totalCount?:number, limit?:number, pageSizeOptions?:any, skip?:number},
  actions?:{add?:boolean,edit?:boolean, delete?:boolean, view?:boolean, download?:boolean, refresh?:boolean},
}
export interface checkBoxObject<T>{
  create? : boolean,
  edit? : boolean,
  delete? : boolean,
  list? : boolean,
  view? : boolean,
  download? : boolean,
  module?: string,
  name? : string,
  id? : string
}
@Component({
  selector: 'app-user-management-table',
  templateUrl: './user-management-table.component.html',
  styleUrls: ['./user-management-table.component.scss']
})
export class UserManagementTableComponent implements OnInit, OnChanges {
  public searchString :string = '';
  public duplicateTableDataForSearch :any = []
  public limit = 10;
  public skip = 0;
  public page = 1;
  public pageSizeOptions = [5, 10, 25, 50, 100];
  @Input() tableHeading : any[] = [];
  @Input() tableData : any[] = [];
  @Input() isEdit:boolean = false;
  @Input() isView:boolean = false;
  @Input() paginationSkip: any;
  @Output() add: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() edit: EventEmitter<any> = new EventEmitter<any>();
  @Output() view: EventEmitter<any> = new EventEmitter<any>();
  @Output() textClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  @Output() editDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCardDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageMethod: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveDetails: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortMethod: EventEmitter<any> = new EventEmitter<any>();

  
  userLevelListChecked: any =[];
   obj:checkBoxObject<any> = {};
  pageIndex: number =  1;
  @Input() totalCount: any ;
  @Input() actionInTable: any = [] ;
  currentUrl: any;
  duplicateTotalCount: any;
  orderSorting: string = 'ASC';
  sortSelection: string = '';
  selectedColumn: string = '';

  

  constructor(
    public dialog: MatDialog,
    private userManagementService : UserManagementServiceService,
    private snackBar:MatSnackBar,
    private route : ActivatedRoute,

  ) {}

  ngOnInit(): void {
    this.duplicateTableDataForSearch = this.tableData;
    this.duplicateTotalCount = this.totalCount
    let currentUrl:any= this.route.snapshot;
    this.currentUrl = currentUrl.routeConfig.path
    console.log("data",this.tableHeading)
    console.log("data",this.tableData)
    console.log("currentUrl",this.currentUrl.routeConfig.path)
  }

  ngOnChanges(changes: SimpleChanges): void{
    console.log("allchanegs",changes);
    
}

  CheckedOptions(index:any,data:any, event:any,header?:any){
    const isChecked = (<HTMLInputElement>event.target).checked;
    if(isChecked){
      this.obj.name = data?.name,
      this.obj.module = data?.name,
      this.obj.id = data?.id
      if(header.code === 'Create New'){
        this.obj.create = isChecked
      }
      if(header.code === 'Edit'){
        this.obj.edit = isChecked
      }
      if(header.code === 'Delete'){
        this.obj.delete = isChecked
      }
      if(header.code === 'View'){
        this.obj.view = isChecked
      }
      if(header.code === 'List'){
        this.obj.list = isChecked
      }
      if(header.code === 'Download'){
        this.obj.download = isChecked
      }
      this.userLevelListChecked.push(this.obj);
    }else{
      // this.userLevelListChecked.splice(index,1);
      if(header.code === 'Create New'){
        this.obj.create = isChecked
      }
      if(header.code === 'Edit'){
        this.obj.edit = isChecked
      }
      if(header.code === 'Delete'){
        this.obj.delete = isChecked
      }
      if(header.code === 'View'){
        this.obj.view = isChecked
      }
      if(header.code === 'List'){
        this.obj.list = isChecked
      }
      if(header.code === 'Download'){
        this.obj.download = isChecked
      }
    }
    // this.updateCheckboxData.emit(this.lineItemId);
  }
  actionMethod(currentType:any,event:any,data:any){
    this.emitFunction(currentType.actionType,data)
  }
  emitFunction(type:string , data?:any, columnKey?:string, rowIndex?:number, columnIndex?:number ){
    switch(type){
      case 'delete':
        this.dialogConfirmation({type,data}, "Are you sure you want to delete?" )
        break;
      case 'edit':
        this.editDetails.emit({type,data});
        this.edit.emit({type,data})
        break;
      case 'add':
        if(typeof data == 'boolean'){
          this.add.emit({type});
          break;
        }
        this.add.emit({type,data});
        break;
      case 'view':
        this.showCardDetails.emit({type,data});
        this.view.emit({type,data});
        break;
      case 'clickableText':
        this.textClick.emit({type,data,columnKey,rowIndex,columnIndex});
        break;
      case 'save':
        this.saveDetails.emit(this.userLevelListChecked);
        break;
      // default :
      //   this.action.emit({type,data});
    }
  }
  dialogConfirmation(eventType:any , message = "Are you sure you want to apply changes?"){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        buttonText: {
          ok: "Yes",
          cancel: "No",
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if(confirmed){
        // this.skip =0;
        this.searchString = '';
        eventType.data['page'] = this.page;
        switch(eventType.type){
          case 'delete':
            this.delete.emit(eventType);
            break;
          case 'updateStatus':
            this.updateStatus.emit(eventType);
            break;
          default:
            this.action.emit(eventType);
        }
      }
    });
  }
tableSearch(event:any){
  if (event.keyCode === 13) {
    this.skip = 0;
    switch(this.currentUrl){
      case 'manage-user' : this.userTableSearch(this.searchString.trim(),'user/list'); break;
      case 'user-level' : this.userTableSearch(this.searchString.trim(),'authenication/view'); break;
                            
    }
}
}
getMapArr(type:string,arr:any,isKey= null):any{
  if(!arr || !Array.isArray(arr) ) return '----'
  if(type == 'arrayOfObject' ){
    if(!isKey) return '----'
    let arrMap = arr.map((res:any)=>res['label']);
    return arrMap.toString();
  }
}
getValue(data:any):string{
  if(data && data?.name){
    return data?.name;
  }
   else{
     return '---';
   }
}
userTableSearch(data:string,endPoint:string){
  this.userManagementService.serachData(data,endPoint).subscribe((res:any)=>{
    let data = JSON.parse(JSON.stringify(res.data))
    if(data?.length){
      console.log("seach",data);
      this.tableData = data;
      this.totalCount = data.length;
    }
    else{
      this.tableData = [];
    }
  })
}
setPage(event: any) {
  // this.selectedColumn = '';
  this.skip = (event.pageIndex + 1) * this.limit - this.limit;
  this.limit = event.pageSize;
  this.page = event.pageIndex + 1;
  console.log('page', this.page, 'skip', this.skip,event)
  this.pageMethod.emit({source:event, skip:this.skip, limit: this.limit, page:event.pageIndex + 1, orderSorting :  this.orderSorting, key:this.selectedColumn})
}
search(){
  if(this.searchString.trim()){
    this.searchString = '';
    // this.clearSearch.emit();
    this.skip = 0;
    let event = {keyCode : 13}
    this.tableSearch(event);
  }
}
getRoleModelName(data:any,dataKey:string,i:number){
  let d:any =[]
  if( data?.authenicationJson?.length){
     data?.authenicationJson.forEach((el:any)=>{
      //  console.log(i, this.isPermissionAllowed(el?.authentication,i),"e",el?.authentication)
      if(this.isPermissionAllowed(el?.authentication,i)) d.push(this.capitalizeFirstLetter(el[dataKey]))
     })
  }
   return d;
}
capitalizeFirstLetter(data:string) {
  return data.charAt(0).toUpperCase() + data.slice(1);
}
isPermissionAllowed(data:any,i:number):boolean{
  let isPermissionTrue = false;
  isPermissionTrue = data.some((el:any)=> el?.isAction)
   return isPermissionTrue;
}
openSnackBar(data: string[], duration:number) {
  this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
}
sortTable(columnName: string, sortType:string = '') {
  this.sortSelection = sortType;
  this.selectedColumn = columnName;
  this.orderSorting = sortType === 'down' ? 'DESC' : 'ASC';
  console.log("columnName",columnName)
  let event={
    page:this.page,
    orderSorting :  this.orderSorting,
    key:columnName
  }
  this.sortMethod.emit(event)
}
}
