<section>
    <div class="Footer_bottom">
        <div class="container-fluid">
            <div class="row mt-3" style="padding-bottom: 100px;">
                <div class="col-md-3 sideBar">
                    <mat-card class="position-sticky main-card top-0 mb-3">
                        <!-- <div class="accordion" id="accordionExample">
                            <div class="accordion-item" *ngFor="let item of sideBarData; let i = index">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne' + item.id"  aria-expanded="true" [attr.aria-controls]="'#collapseOne' + item.id">
                                        {{item?.sidebarHead}}
                                    </button>
                                </h2>
                                <div [id]="'collapseOne' + item.id" class="accordion-show show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <mat-vertical-stepper fxLayout="column" [linear]="true" #stepper>
                                            <mat-step *ngFor="let data of item.subSteppers">
                                                <form>
                                                    <ng-template matStepLabel>{{data?.stepperName}}</ng-template>
                                                </form>
                                            </mat-step>
                                        </mat-vertical-stepper>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <label for="" class="sideBarHead fs-18 fw-500">{{formName}}</label>
                        <div class="steppers">
                            <ng-container>
                                <mat-vertical-stepper [ngClass]="{'view-stepper': isViewMode}" fxLayout="column"
                                    [linear]="false" #stepper>
                                    <mat-step *ngFor="let item of steppers; let i = index">
                                        <form>
                                            <ng-template matStepLabel class="insideStepper">
                                                <p class="m-0" (click)="focusOnCards('stepper'+item, i)">{{item ? item :
                                                    '---'}}</p>
                                            </ng-template>
                                        </form>
                                    </mat-step>
                                </mat-vertical-stepper>
                            </ng-container>
                        </div>
                    </mat-card>
                </div>

                <div class="col-md-9">
                    <div class="card main-card border-0 mb-3">
                        <div class="card-body">
                            <!-- position-sticky top-0 -->
                            <div class="fs-19 fw-500 bg-white"
                                style="z-index: 99; color: #0A0A0A;"><span class="fw-600">{{formName}}</span></div>
                            <!-- form-height -->
                            <div class=" pe-2">
                                <form [formGroup]="commonForm" (ngSubmit)="submitProgramDataForm(urlParams)">

                                    <div class="container-fluid px-0 overflow-hidden" [ngClass]="{'pointer-none': isViewMode}">
                                        <div class="row">
                                            <div  [ngClass]="{ 'col-md-6': formName !='Baseline Form', 'col-md-12': formName =='Baseline Form' || formName =='Program Form'}">
                                                <div class="form-floating my-3" >
                                                    <!-- <select class="form-select rounded-pill" id="year"
                                                        formControlName="financial_year">
                                                        <option [value]="item.value" *ngFor="let item of years">
                                                            {{item?.year}}</option>
                                                    </select>
                                                    <label for="year">Select Year</label> -->
                                                    <mat-form-field appearance="outline" class="w-100 year_input">
                                                        <mat-label>Select Year *</mat-label>
                                                        <mat-select formControlName="financial_year"  (selectionChange)="onYearChange($event.value)" [disabled]="currentParams?.isDraft == 'false' && userDetails?.userlevel_id == 1 ? true : false">
                                                          <mat-option *ngFor="let item of financialYears" [value]="item.year">
                                                            {{item?.year}}
                                                          </mat-option>
                                                        </mat-select>
                                                        <mat-error *ngIf="commonForm.get('financial_year')?.invalid && (commonForm.get('financial_year')?.touched || commonForm.get('financial_year')?.dirty)">
                                                            This field is required *.
                                                          </mat-error>
                                                        
                                                      </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-md-6" *ngIf="formName == 'Monthly Progress Form'">
                                                <div class="form-floating my-3" >
                                                    <!-- <select class="form-select rounded-pill" id="month"
                                                        formControlName="month">
                                                        <option [value]="item.value" *ngFor="let item of months">
                                                            {{item.month}}</option>
                                                    </select>
                                                    <label for="month">Select Month</label> -->
                                                    <mat-form-field appearance="outline" class="w-100 year_input">
                                                        <mat-label>Select Month *</mat-label>
                                                        <mat-select formControlName="month" (selectionChange)="onMonthChange($event.value)" [disabled]="currentParams?.isDraft == 'false' && userDetails?.userlevel_id == 1 ? true : false">
                                                          <mat-option [value]="item.month" *ngFor="let item of selectedMonth" >
                                                            {{item?.month}}
                                                          </mat-option>
                                                        </mat-select>
                                                        <mat-error *ngIf="commonForm.get('month')?.invalid && (commonForm.get('month')?.touched || commonForm.get('month')?.dirty)">
                                                            This field is required *.
                                                          </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- country releated dropdowns -->

                                    <div class="container-fluid px-0 overflow-hidden" [ngClass]="{'pointer-none': isViewMode}" *ngIf="formName !='Program Form'">
                                        <div class="row">
                                            <div class="col-md-4" *ngIf="showCountryDropdown">
                                                <mat-form-field appearance="outline" class="w-100">
                                                  <mat-label>Country Name *</mat-label>
                                                  <mat-select formControlName="countryName" [ngModelOptions]="{standalone: true}" [ngClass]="{'is-invalid':commonForm.get('countryName')?.touched && commonForm.get('countryName')?.invalid}" (selectionChange)="countryChangeHandler($event)" [disabled]="true">
                                                    <mat-select-trigger *ngIf="commonForm.controls['countryName']?.value">
                                                      <img [src]="commonForm.controls['countryName'].value?.country_icons " alt="Image" class="option-image">
                                                      <span>{{commonForm.controls['countryName'].value?.name}}</span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let country of countrys" [value]="country">
                                                      <img [src]="country?.country_icons" alt="Image" class="option-image">
                                                      {{country?.name}}
                                                    </mat-option>
                                                  </mat-select>
                                                  <span class="invalid-feedback" *ngIf="(commonForm.get('countryName')?.touched  || commonForm.get('countryName')?.dirty) && commonForm.get('countryName')?.invalid">
                                                    Country Name is Required *.
                                                 </span>
                                                </mat-form-field>
                                              </div>
                                              <div class="col-md-4" *ngIf="showIPdropdown">
                                                <mat-form-field appearance="outline" class="w-100">
                                                  <mat-label>IP NAME </mat-label>
                                                  <mat-select formControlName="ipName" [ngModelOptions]="{standalone: true}" [ngClass]="{'is-invalid':commonForm.get('ipName')?.touched && commonForm.get('ipName')?.invalid}" (selectionChange)="iPChangeHandler($event)" [disabled]="!ipNames?.length || true">
                                                    <mat-option *ngFor="let ipName of ipNames" [value]="ipName">
                                                      {{ipName?.name}}
                                                    </mat-option>
                                                  </mat-select>
                                                  <span class="invalid-feedback" *ngIf="(commonForm.get('ipName')?.touched  || commonForm.get('ipName')?.dirty) && commonForm.get('ipName')?.invalid">
                                                    IP Name is Required *.
                                                 </span>
                                                </mat-form-field>
                                              </div>

                                              <div class="col-md-4" *ngIf="showFactoryDropdown" >
                                                <mat-form-field appearance="outline" class="w-100">
                                                  <mat-label>Factory Name </mat-label>
                                                  <mat-select formControlName="factoryName" [ngModelOptions]="{standalone: true}" [ngClass]="{'is-invalid':commonForm.get('factoryName')?.touched && commonForm.get('factoryName')?.invalid}" [disabled]="!factoryNames?.length || true">
                                                    <mat-option *ngFor="let factoryName of factoryNames" [value]="factoryName">
                                                      {{factoryName?.name}}
                                                    </mat-option>
                                                  </mat-select>
                                                  <span class="invalid-feedback" *ngIf="(commonForm.get('factoryName')?.touched  || commonForm.get('factoryName')?.dirty) && commonForm.get('factoryName')?.invalid">
                                                    Factory Name is Required *.
                                                 </span>
                                                </mat-form-field>
                                              </div>
                                        </div>
                                    </div>

                                    <!-- end -->

                                    <div class="accordion my-2" id="programFormAccordian">
                                        <div class=" card-body fs-16 fw-bold color-44 top-0 bg-white"
                                            style="padding: 0 0 10px 20px; z-index: 99; color: #0A0A0A;"
                                            *ngFor="let step of steppers; let parentIndex=index" [id]="'stepper'+ step" id="questionHead" (scroll)="onScroll($event)">
                                            <div class="fw-600 mb-4" style="color: #949494;">
                                                
                                                <h2 class="accordion-header" [id]="'programHeadIndicator' + parentIndex">
                                                    <button class="accordion-button accordion-bg collapsed"
                                                        type="button" data-bs-toggle="collapse"
                                                        [attr.data-bs-target]="'#collapseOne' + parentIndex"
                                                        aria-expanded="true"
                                                        [attr.aria-controls]="'#collapseOne' + parentIndex" (click)="focusOnCards('stepper'+step, parentIndex)">
                                                        <span>Section {{parentIndex + 1}}: &nbsp;</span> {{step}}
                                                    </button>
                                                </h2>
                                            </div>

                                            <div [id]="'collapseOne' + parentIndex" class="accordion-collapse collapse"
                                                [aria-labelledby]="'programHeadIndicator' + parentIndex"
                                                data-bs-parent="#programFormAccordian">
                                                    <div class="accordion-body" id="programHeadAccordian">
                                                    

                                            <ng-container *ngFor="let heading of collapseQuestions; let index= index" >
                                                <h2 class="accordion-header question-title-header subHeader mb-3" [id]="'programFormIndicator' + index" *ngIf="heading.theme_name == step">
                                                    <button class="accordion-button subTab collapsed"
                                                        type="button" data-bs-toggle="show"
                                                        [attr.data-bs-target]="'#collapseTwo' + index"
                                                        aria-expanded="true"
                                                        [attr.aria-controls]="'#collapseTwo' + index">
                                                        <span [innerHTML]="heading?.name"></span>
                                                    </button>
                                                </h2>

                                                <ng-container *ngFor="let item of formQuestions | filterNamePipe: heading.name: 'name'; let i = index">
                                                    <div class="accordion-item mb-2" *ngIf="step == item.theme_name">
                                                        <ng-container
                                                            *ngIf="item?.short_key !== 'investments_raised' && ((item.parent && !item.hide) || (item.parent == null))">
                                                            
                                                            <div [id]="'collapseTwo' + index" class="accordion-show show"
                                                                [aria-labelledby]="'programFormIndicator' + index"
                                                                data-bs-parent="#programHeadAccordian">
                                                                <div class="accordion-body">
                                                                    <div class="formQuestions">
                                                                        <span [innerHTML]="item?.title"></span>
                                                                    </div>
                                                                    <!-- <div> -->
                                                                    <div class="formInputField mt-2" [ngClass]="{'pointer-none': isViewMode}">
                                                                        <!-- <input type="text"
                                                                            class="form-control form-input rounded-pill"
                                                                            [placeholder]="item.place_holder" [formControlName]="item.short_key"
                                                                            (keypress)="numberOnly($event)" [ngClass]="commonForm.value[item.short_key] == '' && dataSubmitted ? 'showRed' : 'showNormal'"
                                                                            [readOnly]="urlParams.type == 'viewMode'"
                                                                            *ngIf="item?.type == 1"> -->
                                                                            <div class="position-relative" *ngIf="item?.type == 1">
                                                                                <mat-form-field appearance="outline" class="example-full-width w-100">
                                                                                  <mat-label>{{item.place_holder}} </mat-label>
                                                                                  <input type="text" 
                                                                                   matInput 
                                                                                   [placeholder]="item.place_holder"
                                                                                   [formControlName]="item.short_key"
                                                                                   [ngClass]="commonForm.get(item.short_key)?.invalid && (commonForm.get(item.short_key)?.touched || commonForm.get(item.short_key)?.dirty) && dataSubmitted ? 'showRed' : 'showNormal'"
                                                                                   [readOnly]="urlParams.type == 'viewMode'"
                                                                                   (keypress)="numberOnly($event)"
                                                                                    >
                                                                                    <mat-error *ngIf="commonForm.get(item.short_key)?.invalid && !commonForm.get(item.short_key)?.value && (commonForm.get(item.short_key)?.touched || commonForm.get(item.short_key)?.dirty)">
                                                                                        This field is required *.
                                                                                      </mat-error>
                                                                                      <mat-error *ngIf="commonForm.get(item.short_key)?.invalid && commonForm.get(item.short_key)?.value">
                                                                                        Entered value is out of range *.
                                                                                      </mat-error>
                                                                                </mat-form-field>
                                                                              </div>
    
                                                                              <!-- <mat-form-field appearance="outline" class="example-full-width w-100">
                                                                                <mat-label>{{ item.place_holder }} *</mat-label>
                                                                                <input type="text"
                                                                                       [placeholder]="item.place_holder"
                                                                                       matInput
                                                                                       [formControlName]="item.short_key"
                                                                                       [ngClass]="commonForm.get(item.short_key)?.invalid && (commonForm.get(item.short_key)?.touched || commonForm.get(item.short_key)?.dirty) && dataSubmitted ? 'showRed' : 'showNormal'"
                                                                                       [readOnly]="urlParams.type == 'viewMode'"
                                                                                       *ngIf="item?.type == 1">
                                                                                <mat-error *ngIf="commonForm.get(item.short_key)?.invalid && (commonForm.get(item.short_key)?.touched || commonForm.get(item.short_key)?.dirty)">
                                                                                  {{ item.place_holder }} is Required *.
                                                                                </mat-error>
                                                                              </mat-form-field> -->
                                                                              
    
                                                                        <mat-form-field appearance="outline" class="w-100"
                                                                            *ngIf="item?.type == 2">
                                                                            <mat-label>Select Options</mat-label>
                                                                            <mat-select [formControlName]="item.short_key"
                                                                                (selectionChange)="optionChange($event, item)" disableOptionCentering 
                                                                                panelClass="dropdown-style" multiple>
                                                                                <mat-option
                                                                                    *ngFor="let option of item?.option"
                                                                                    [value]="option.id">
                                                                                    {{option?.value}}
                                                                                </mat-option>
                                                                            </mat-select>
                                                                            <mat-error *ngIf="commonForm.get(item.short_key)?.invalid && (commonForm.get(item.short_key)?.touched || commonForm.get(item.short_key)?.dirty)">
                                                                                This field is required *.
                                                                              </mat-error>
                                                                        </mat-form-field>
    
                                                                        <mat-form-field appearance="outline" class="w-100"
                                                                            *ngIf="item?.type == 7">
                                                                            <mat-label>Select Options</mat-label>
                                                                            <mat-select [formControlName]="item.short_key"
                                                                                (selectionChange)="optionChange($event, item)" disableOptionCentering 
                                                                                panelClass="dropdown-style">
                                                                                <mat-option
                                                                                    *ngFor="let option of item?.option"
                                                                                    [value]="option.id">
                                                                                    {{option?.value}}
                                                                                </mat-option>
                                                                            </mat-select>
                                                                            <mat-error *ngIf="commonForm.get(item.short_key)?.invalid && (commonForm.get(item.short_key)?.touched || commonForm.get(item.short_key)?.dirty)">
                                                                                This field is required *.
                                                                              </mat-error>
                                                                        </mat-form-field>
    
                                                                    </div>
                                                                    <!-- upload file section start -->
    
                                                            <ng-container *ngIf="item?.type == 6">
                                                                <div *ngIf="[2,3].includes(formId)">
                                                                    <div class="uploadContainer my-2" appDnd
                                                                        (fileDropped)="onFileDropped($event, i)">
                                                                        <div class="box">
                                                                            <input type="file" #fileDropRef id="fileDropRef{{i}}"
                                                                                (change)="fileBrowseHandler($event, i, item)"
                                                                                [(ngModel)]='files[i]'/>
                                                                            <img src="../../../../assets/images/Image-upload.png"
                                                                                class="img-fluid mb-3"
                                                                                style="max-width: 200px;" alt="">
    
    
                                                                            <div class="text-center"
                                                                                style="font-size:14px; font-weight:500; margin-top:10px;">
                                                                                Upload Receipt/Screenshot
                                                                            </div>
                                                                            <div class="text-center" style="font-size: 12px;">
                                                                                <label for="fileDropRef{{i}}"
                                                                                    style="color:#503794; font-weight:500;">Browse</label>
                                                                                <span
                                                                                    style="margin-left:4px; font-weight:500;">or
                                                                                    Drag & Drop</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p class="uploadFileInfo"><i>{{fileUploadInfo}}</i></p>
                                                                    <div class="files-list mx-auto">
                                                                        <div class="single-file"
                                                                            *ngFor="let file of files; let index = index">
                                                                            <div class="file-icon" style="width: 50px" *ngIf="index === i && file?.name">
                                                                                <svg version="1.1" id="fileDropRef{{index}}"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                    x="0px" y="0px" viewBox="0 0 58 58"
                                                                                    style="enable-background:new 0 0 58 58;"
                                                                                    xml:space="preserve">
                                                                                    <polygon style="fill:#EDEADA;"
                                                                                        points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                                                                    <g>
                                                                                        <path style="fill:#CEC9AE;"
                                                                                            d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                                                                        <path style="fill:#CEC9AE;"
                                                                                            d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                                                                        <path style="fill:#CEC9AE;"
                                                                                            d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                                                                        <path style="fill:#CEC9AE;"
                                                                                            d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                                                                        <path style="fill:#CEC9AE;"
                                                                                            d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                                                                    </g>
                                                                                    <polygon style="fill:#CEC9AE;"
                                                                                        points="37.5,0 37.5,14 51.5,14 " />
                                                                                </svg>
                                                                            </div>
                                                                            <div class="info" *ngIf="index === i">
                                                                                <a style="text-decoration: none;" href=""
                                                                                    target="_blank" class="name">
                                                                                    {{ file?.name ? file?.name :
                                                                                    file?.file_Path}}
                                                                                </a>
    
                                                                                <div *ngIf="file?.progress">
                                                                                    <app-upload-progress
                                                                                        [progress]="file?.progress"></app-upload-progress>
                                                                                </div>
                                                                            </div>
    
                                                                            <div class="delete" (click)="deleteFile(index, fileDropRef)" *ngIf="index === i && file?.name">
                                                                                <img src="../../../../assets/images/delete-bin.svg"
                                                                                    alt="" style="cursor: pointer;">
                                                                            </div>
    
                                                                        </div>
    
                                                                    </div>
                                                                    <div *ngIf="(urlParams.type == 'viewMode' || urlParams.type == 'editMode') && (imgURL[item?.short_key])">
                                                                        <i matTooltip="Click to open" class="prefillImage img-fluid fa-2x text-green pointer" [ngClass]="imgURL[item?.short_key] | iconClass" (click)="openDocument(imgURL[item?.short_key])"></i>
                                                                        <!-- <img class="prefillImage img-fluid fixedImageHeight" style="cursor: pointer" matTooltip="Click to open"
                                                                            [src]="imgURL[item?.short_key].includes('pdf') ? '../../../../assets/icons/pdfIcon.svg' : imgURL[item?.short_key].includes('.docx') ? '../../../../assets/icons/msWordIcon.svg' : '../../../../assets/no_data_image/xlsx.png'"
                                                                            alt="" (click)="openDocument(imgURL[item?.short_key])"> -->

                                                                    </div>
                                                                    <!-- <div class="delete mt-2" (click)="deleteFile(index, fileDropRef)" *ngIf="urlParams.type == 'editMode' && !imageAllData.length && imgURL[i]">
                                                                        <img src="../../../../assets/images/delete-bin.svg"
                                                                            alt="" style="cursor: pointer;">
                                                                    </div> -->
                                                                </div>
                                                            </ng-container>
                                                                </div>
                                                            </div>
    
                                                            
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>
                            <!-- [disabled]="commonForm.invalid" -->
                            <div class="card-footer bg-white text-end border-0" *ngIf="urlParams.type != 'viewMode' && isformRendered">
                                <button class="btn rounded-pill save-btn me-3 mb-4"
                                    (click)="submitProgramDataForm(urlParams, 'is_draft')" *ngIf="isDraftStatus == '' || isDraftStatus == 'true' ? true : false">Save as draft</button>
                                <button class="btn rounded-pill submit-btn mb-4"
                                    (click)="submitProgramDataForm(urlParams)" [disabled]="isCheckMandatory()">{{urlParams.type == "editMode" ? "Update"
                                    : "Submit"}}</button>
                                    <!-- abhi--{{commonForm.value | json}} -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
