<div class="Footer_bottom">
  <div class="container-fluid">
  
    <div class="d-flex align-items-center">
      <svg class="me-2 pointer" (click)="backToPrevious()" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
        </svg>
        
      <nav aria-label="breadcrumb" class="my-3">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToForm()">IPSA Form</li>
          <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToPrevious()">{{showFormName}}</li>
          <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">{{formTitle}}</li>
        </ol>
      </nav>
    </div>
    
    <div class="row justify-content-center mt-3">
      <div class="col-md-3">
        <div class="card border-0 shadow-sm mb-3 position-sticky top-0">
          <div class="card-body">
            <div class="steppers">
              <ng-container>
                  <mat-vertical-stepper [ngClass]="{'view-stepper': isViewMode}" fxLayout="column"
                      [linear]="true" #stepper>
                      <div *ngFor="let item of formFetchResponseComponent">

                        <mat-step *ngIf="item?.title">
                            <form>
                                <ng-template matStepLabel class="insideStepper" >
                                    <p class="m-0" (click)="scrollToSection(item?.key)">{{item?.title}}</p>
                                </ng-template>
                            </form>
                        </mat-step>
                      </div>
                  </mat-vertical-stepper>
              </ng-container>
          </div>
          </div>
        </div>
        
      </div>
      <div class="col-md-9">
          <div class="card main-card border-0 mb-3">
              <div class="card-body">
                  <!-- <form > -->
                  <div id="formioipsa" [ngClass]="{'pointer-none': isFormDisabled}"></div>
                  <!-- <button class="btn btn-success" type="submit">Submit</button> -->
                  <!-- </form> -->
              </div>
          </div>
      </div>
      <!-- <div class="col-sm-3"></div> -->
    </div>
    </div>
</div>