<header class="bg-white" *ngIf="!isLoggedin; else userActive">
  <div class="container">
      <nav class="navbar navbar-expand-xl navbar-light bg-white">
          <a class="navbar-brand" href="#">
            <!-- <img src="../../../assets/images/IDH_Brandmark_logo.png" class="img-fluid" style="max-width: 150px; margin-top: 15px;" alt=""> -->
              <img src="../../../assets/images/IDH-finalLogo.png" class="img-fluid" style="max-width: 215px;" alt="">
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse align-items-center justify-content-end" id="navbarNav">
            <ul class="navbar-nav align-items-xl-center">
              <li class="nav-item me-3" routerLinkActive="active-tab">
                <a class="nav-link" routerLink="/home"><span>Home</span></a>
              </li>
              <!-- <li class="nav-item me-3" routerLinkActive="active-tab">
                <a class="nav-link" routerLink="/form-builder"><span>Form Builder</span></a>
              </li> -->
              <!-- <li class="nav-item me-3" [ngClass]="{'active-tab': activeBtn == 'dashboard'}" (click)="activeBtn = 'dashboard'">
                <a class="nav-link" href="#" routerLink="/dashboard"><span>Dashboard</span></a>
              </li> -->
              <li class="nav-item me-3" routerLinkActive="active-tab">
                <a class="nav-link" routerLink="/dashboard"><span>Dashboard</span></a>
              </li>
              <li class="nav-item me-3" routerLinkActive="active-tab">
                <a class="nav-link" routerLink="/resources"><span>Resources</span></a>
              </li>
              <li class="nav-item me-3" routerLinkActive="active-tab">
                <a class="nav-link"  routerLink="/impact-stories"><span>Impact Stories</span></a>
              </li>
              <li class="nav-item me-3" routerLinkActive="active-tab">
                <a class="nav-link"  routerLink="/about-us"><span>About Us</span></a>
              </li>
              <li class="nav-item me-3" routerLinkActive="active-tab">
                <a class="nav-link"  routerLink="/ourpartners"><span>Our Partners</span></a>
              </li>
<div class="d-lg-flex mt-lg-0 mt-2">
  <button class="btn me-3 rounded-pill join-btn mb-lg-0 mb-3" routerLink="/sign-up" (click)="openSignUpForm()">Join Now</button>
  <button class="btn text-white rounded-pill login-btn mb-lg-0 mb-3" (click)="openDialog()">Login</button>
</div>
            </ul>
          </div>
        </nav>
  </div>
  </header>
          <!-- <ul class="nav-links m-0">
            <li><a href="#">Home</a></li>
            <li class="center"><a href="#">Dashboard</a></li>
            <li class="upward"><a href="#">Resources</a></li>
            <li class="forward"><a href="#" routerLink="/impact-stories">Impact Stories</a></li>
            <button class="btn me-3 rounded-pill join-btn">Join Now</button>
<button class="btn me-3 text-white rounded-pill login-btn" (click)="openDialog()">Login</button>
          </ul> -->
          <ng-template #userActive>
            <header class="bg-white" *ngIf="isLoggedin">
              <div class="container-fluid">
                  <nav class="navbar navbar-expand-xl navbar-light bg-white">
                      <a class="navbar-brand" href="#">
                          <img src="../../../assets/images/IDH-finalLogo.png" class="img-fluid" style="max-width: 215px;" alt="">
                      </a>
                      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                      </button>
                      <div class="collapse navbar-collapse align-items-center justify-content-end" id="navbarNav">
                        <ul class="navbar-nav align-items-xl-center">
                          <!-- <li class="nav-item me-3">
                          <a class="nav-link" routerLinkActive="active" routerLink="/home">Home</a>
                        </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/admin-forms"><span>Forms</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/dashboard"><span>Dashboard</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/communications"><span>Communications</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/manage-portal"><span>Manage portal</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/subscribers"><span>Subscribers</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/resources"><span>Resources</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/impact-stories"><span>Impact Stories</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/about-us"><span>About Us</span></a>
                          </li>
                          <li class="nav-item me-3">
                            <a class="nav-link" routerLinkActive="active" routerLink="/ourpartners"><span>Our Partners</span></a>
                          </li>-->
                 
                          
                          <ng-container *ngFor="let item of rightMenuBar | isShowModule: isShowCom : 'communicationmapper'; let i=index">
                            <li>
                              <ng-container *ngIf="item?.subItem?.length && item?.isActive">
                                <a mat-button [matMenuTriggerFor]="menu" class="text-decoration-none m-10 nav-link-item sub-link-color" [class.border_class]="isClicked"
                                [ngClass]="[item?.isChecked ? 'subActiveTab' : 'fw-400',  item?.subItem?.length ? 'user-management dropdown-toggle' : '']">{{item?.label}}</a>
                                  <mat-menu #menu="matMenu" class="userManagementFilter">
                                    <ng-container *ngFor="let subChilds of item?.subItem ">
                                      <ng-container *ngIf="subChilds?.isActive" >
                                      <button mat-menu-item (click)="redirectUrlSubChild(subChilds?.subState,item?.label)" [ngClass]="{'subActiveTab' : subChilds?.isChecked}">{{subChilds?.subLabel}}</button>
                                    </ng-container>
                                    </ng-container>
                                  </mat-menu>
                              </ng-container>
                            </li>
                            
                            <li [ngClass]="item?.subItem?.length ? 'nav-item dropdown' :'nav-item m-10'" *ngIf="item?.isActive" (click)="openSidebar(item)">
                            <ng-container *ngIf="!item?.subItem">
                              <a (click)="redirectUrl(item?.state)" class="nav-link text-decoration-none m-10 nav-link-item link-color" [ngClass]="{'active' : item?.isChecked,  'nav-link user-management dropdown-toggle' : item?.subItem?.length}" id="navbarDropdown"
                                 role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                                 <span *ngIf="!item?.icon">{{item?.label}} <span *ngIf="(item?.label == 'Communications' && unReadMessageCount)" class="unreadCountCommunication">{{unReadMessageCount }}</span></span>
                                 <span *ngIf="item?.icon" >
                                  <img  [src]="item?.icon" class="a">
                                  <span class="unreadCount" *ngIf="notificationList.length">
                                    {{notificationList.length}}
                                  </span>
                                 </span>
                              </a>
                              </ng-container>
                              <!-- <ng-container *ngIf="item?.subItem?.length">
                                <ul class="dropdown-menu user-management mt-0" aria-labelledby="navbarDropdown">
                                  <ng-container *ngFor="let subChilds of item?.subItem let j=index">
                                    <li *ngIf="subChilds?.isSubActive">
                                      <a class="dropdown-item fs-13 pointer nav-link text-decoration-none link-color" (click)="redirectUrlSubChild(subChilds?.subState)" [ngClass]="{'active' : subChilds?.isChecked}">
                                        {{subChilds?.subLabel}}
                                      </a>
                                     </li>
                                  </ng-container>
                                </ul>
                              </ng-container> -->
                            </li>
                           </ng-container>
                   
                          <li class="nav-item dropdown">
                            <a mat-button [matMenuTriggerFor]="menu" class="nav-link user-link dropdown-toggle remove-toggle">
                              <img [src]="showImage ? showImage : '../../../assets/images/user-profile.png'" class="rounded-circle" [ngClass]="{'img-fluid-upload': showImage, 'img-fluid': !showImage}" alt="">
                            </a>
                              <mat-menu #menu="matMenu" class="profileMenu">
                                <div>
                                  <!-- <span class="username fs-14">{{userobj?.name}}</span> <br>
                                  <span class="username fs-11" style="color: #aaa3a3">{{userData['role.name']}}</span></div> -->
                                  <span class="username fs-14">{{userAllDetails?.name ? userAllDetails?.name : '---'}}</span> <br>
                                  <span class="username fs-11" style="color: #aaa3a3">{{userAllDetails['role.name'] ? userAllDetails['role.name'] : '---'}}</span></div>
                                <a mat-menu-item class=" fs-14 fw-400" routerLink="/user-profile">My Profile</a>
                                <a mat-menu-item class=" fs-14 fw-400" routerLink="/communications" *ngIf="isShowInbox">Inbox</a>
                                <a mat-menu-item class=" fs-14 fw-400" (click)="logoutUser()">Logout</a>
                              </mat-menu>
                            <!-- <a class="nav-link user-link dropdown-toggle remove-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="../../../assets/images/user-profile.png" class="img-fluid" alt="">
                            </a>
                            <ul class="dropdown-menu dropdown-position" aria-labelledby="navbarDropdown">
                              <li><a class="dropdown-item fs-14 fw-400" href="#">{{userData['role.name']}}</a>
                              <div class="mb-2" style="font-size: 10px; padding: 0 1rem;">{{userData?.role_id == 1 ? 'Admin' : 'User'}}</div>
                              </li>
                              <li class="mb-2"><a class="dropdown-item fs-14 fw-400" routerLink="/user-profile">My Profile</a></li>
                              <li style="cursor: pointer;"><a class="dropdown-item fs-14 fw-400" (click)="logoutUser()">Logout</a></li>
                            </ul> -->
                          </li>
                        </ul>
                      </div>
                    </nav>

                    <!-- <div class="Footer_bottom"> -->
    <div class="themePosition" *ngIf="hideSidebar">
      <div class="drawer" [@slideInOut]>
        <div class="close-icon">
            <!-- <a (click)="toggle()">
  
                <i class="fa fa-times" style="color: aliceblue;" aria-hidden="true"></i>
            </a> -->
        </div>
    
          <div class="themeHeadingDiv d-flex my-3 justify-content-between">
            <div class="d-flex align-items-center">
              <div class="me-2 pointer" (click)="toggle()">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.9997 5.5865L11.9495 0.636719L13.3637 2.05093L8.4139 7.0007L13.3637 11.9504L11.9495 13.3646L6.9997 8.4149L2.04996 13.3646L0.635742 11.9504L5.5855 7.0007L0.635742 2.05093L2.04996 0.636719L6.9997 5.5865Z" fill="#444444"/>
                  </svg>
              </div>
                <p class="themeHeading fs-20 color-44 fw-600 m-0">Notifications</p>
            </div>
              <p class="markAll fs-16 m-0 px-2" *ngIf="unReadMessageCount" (click)="markAllRead()">
                <span>
                  <img src="../../../assets/images/tick-blue.svg" class="img-fluid me-1" alt="">
                </span> Mark all as read</p>
          </div>

          <nav>
              <div class="nav nav-tabs align-items-center position-relative" id="nav-tab" role="tablist">
                <button class="nav-link fs-14 color-44 active d-flex align-items-center" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Unread Notifications
                  <span class="messageCount rounded-circle text-white d-flex ms-2 align-items-center justify-content-center">{{notificationList.length}}</span>
                </button>
                <div class="d-none">
                  <button class="nav-link fs-14 color-44 active d-flex align-items-center" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" (click)="tabChange('all')">Unread Notifications
                    <span class="messageCount rounded-circle text-white d-flex ms-2 align-items-center justify-content-center">{{notificationList.length}}</span>
                  </button>
                  <button class="nav-link fs-14 color-44 d-flex align-items-center" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" (click)="tabChange('unread')">Unread
                    <span class="messageCount rounded-circle text-white d-flex ms-2 align-items-center justify-content-center">{{unReadMessageCount}}</span>
                  </button>
                </div>
                <!-- <button class="nav-link fs-14 color-44 d-flex align-items-center" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Requests
                  <span class="messageCount rounded-circle text-white d-flex ms-2 align-items-center justify-content-center">11</span>
                </button> -->

                <!-- <span class="position-absolute filter-div pointer">
                  <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                    <g id="Group_16821" data-name="Group 16821" transform="translate(-4157 -790)">
                      <path id="Path_6201" data-name="Path 6201" d="M0,0H20V20H0Z" transform="translate(4157 790)" fill="none"/>
                      <path id="Path_6202" data-name="Path 6202" d="M18,4V5.667h-.833L13,11.917V19H8V11.917L3.833,5.667H3V4ZM5.837,5.667l3.83,5.745v5.922h1.667V11.412l3.83-5.745Z" transform="translate(4156.5 789.333)"/>
                    </g>
                  </svg>
                </span> -->

              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="overflow-auto" style="height: 85vh;">
                  <div class="fs-12 color-44 mt-3 mb-2 fw-500" *ngIf="notificationList?.length">Today</div>

                  <ng-container *ngFor="let item of notificationList">
                    <div class="d-flex justify-content-between py-2 border-bottom ps-0 chat-hover pointer" *ngIf="(item.createdAt | date) == (currentDate | date) && (tabType == 'unread' ? !item.is_visible : true)" [ngStyle]="{'background-color' : item.is_visible ? 'white' : '#F6F4F48E', 'font-weight' : item.is_visible ? 400 : 500}">
                        <div class="d-flex align-items-center ml-1" (click)="redirectCommunicationPage(item)">
                            <!-- <img src="../../../assets/images/ak-icon.svg" class="img-fluid pointer" alt=""> -->
                            <div class="profileNameBox mx-2" [ngStyle]="{'background-color': item?.color}">
                              <span class="nameInitials">{{item.nameInitials}}</span>
                            </div>
                            <div class="ml-2 d-flex justify-content-between">
                              <div>
                                <div class="fs-14 color-44 pointer"><span class="fw-500" >{{item?.name}}</span></div>
                                <p class="fs-13 subject m-0"><span>{{item?.subject  | truncatePipe : 20}}</span></p>
                              </div>
                            </div>
                          </div>
                          <p class="fs-11 text-secondary m-0 mx-2">{{currentTime(item)}} ago</p>
                    </div>
                  </ng-container>
  
                    <div class="fs-12 color-44 mb-2 mt-3 fw-500" *ngIf="notificationList?.length">Last Week</div>
                    <ng-container *ngFor="let item of notificationList">
  
                    <div class="d-flex justify-content-between py-2 border-bottom ps-0 chat-hover pointer" *ngIf="(item.createdAt | date) != (currentDate | date) && (tabType == 'unread' ? !item.is_visible : true)" [ngStyle]="{'background-color' : item.is_visible ? 'white' : '#F6F4F48E', 'font-weight' : item.is_visible ? 400 : 500}">
                        <!-- <div class="d-flex align-items-center" (click)="redirectCommunicationPage(item)">
                            <img src="../../../assets/images/ak-icon.svg" class="img-fluid pointer" alt="">
                            <div>
                                <div class="fs-14 color-44 pointer"><span class="fw-500">{{item?.name}}</span> is requesting for {{item?.subject}}</div>
                                <p class="fs-11 color-44 m-0"> <span class="dot position-relative">{{item?.createdAt | date}}</span></p>
                            </div>
                        </div> -->
                        <div class="d-flex align-items-center ml-1" (click)="redirectCommunicationPage(item)">
                          <div class="profileNameBox mx-2" [ngStyle]="{'background-color': item?.color}">
                            <span class="nameInitials">{{item.nameInitials}}</span>
                          </div>
                          <div class="ml-2 d-flex justify-content-between">
                            <div>
                              <div class="fs-14 color-44 pointer"><span class="fw-500" >{{item?.name}}</span></div>
                              <p class="fs-13 subject m-0"><span>{{item?.subject  | truncatePipe : 20}}</span></p>
                            </div>
                          </div>
                        </div>
                        <p class="fs-11 text-secondary m-0 mx-2"> <span class="position-relative">{{item?.createdAt | date}}</span></p>
                    </div>
                    </ng-container>

                    <div *ngIf="!notificationList?.length" class="noData">
                      <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" style="width: 300px;">
                    
                      <!-- <span>No Data Found !</span> -->
                      </div>
  
                    <!-- <button class="admin-btn btn d-flex align-items-center mt-4 text-white rounded-pill fs-12">Admin View</button> -->
                    
                </div>
                </div>
              <!-- <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
              <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div> -->
            </div>

      </div>
    </div>
<!-- </div> -->
              </div>
              </header>
          </ng-template>