import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name : 'isShowModule'
})

export class HideCommPipe implements PipeTransform{
    transform(items: any[], isShowModule: boolean = false, searchBy: string) {
        if (isShowModule) {
          return items;
        }else{
            return items.filter((data:any, index:number) => {
                return !data.model || !data.model.includes(searchBy);
            })
        }
      }
}