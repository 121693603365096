<img src="../../../assets/images/login-close.svg" alt="" mat-dialog-close="" class="close-btn position-absolute">
<div class="px-3" style=" overflow: auto; max-height: 80vh;">
    <div class="row">
        <div class="col-12">
            <h4 class="fs-24 mb-3 fw-bold">Who will be able to see the personal information that I will enter on this website?</h4>
        </div>
        <div class="col-md-12" >
            <div>
                <ul *ngFor="let item of gdprPoints">
                 <li>{{item?.policyPoint}}</li>
                </ul>
            </div>
            
           <!-- <div class="mt-3">
            <mat-checkbox class="customColorText customPolicyCheckboxColor gdprCheck mt-3" [(ngModel)]="gdprChecked" 
            (change)="selectGDPR($event)"
            ><span style="font-weight: 500;" >I have read and agree to the Privacy Policy</span></mat-checkbox>
           </div> -->
        </div>
        <div class="mt-3 ml-1">
            <span>Find more information on Privacy here: <a href="https://www.idhsustainabletrade.com/privacy-statement/" target="_blank">https://www.idhsustainabletrade.com/privacy-statement/</a>
            </span>
        </div>
        
        <div class="col-12">
            <div class="text-end">
                <button class="btn rounded-pill upload-btn text-white" (click)="submitGDPR()">Close</button>
            </div>
        </div>
    </div>
</div>