<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card my-3 border-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="my-3 d-flex align-items-center">
                                <!-- <span class="pointer" routerLink="/dashboard">
                                  <svg class="me-3" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none" />
                                    <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z"
                                      fill="black" />
                                  </svg>
                                </span> -->

                                <nav aria-label="breadcrumb">
                                  <ol class="breadcrumb m-0">
                                    <li class="breadcrumb-item fs-18 fw-500">Dashboard Module</li>
                                    <!-- <li class="breadcrumb-item fs-18"><a class="text-decoration-none text-dark fw-500">{{roleId ? 'Edit User Role' : 'Add User Role' }}</a></li> -->
                                  </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="table-responsive table-bordered">
                            <table class="table">
                                <thead class="bg-light">
                                    <tr class="text-start">
                                        <th class="fw-bold">S.No</th>
                                        <th class="fw-bold">
                                            <span class="d-flex;justify-content-between">
                                                <span class="pe-2">
                                                    <mat-checkbox
                                                    class="customPolicyCheckboxColor"
                                                    [disableRipple]="true"
                                                    (click)="$event.stopPropagation()"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="selectAll($event)"
                                                    [checked]="roleId ? true:false"
                                                    [indeterminate]="isModuleOver(customJson) "
                                                    >
                                                   </mat-checkbox>
                                               </span>
                                               <span>Module </span>
                                            </span>
                                        </th>
                                        <th class="fw-bold">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="text-start">
                                    <ng-container *ngIf="customJson?.length">
                                        <tr class="text-start" *ngFor="let item of customJson let row=index" >
                                            <td class="text-start">{{row+1}}</td>
                                            <td class="text-start" [ngStyle]="{'pointer-events': currentMode == 'view' ? 'none' : 'auto'}">
                                                <mat-checkbox
                                                class="customPolicyCheckboxColor"
                                                [disableRipple]="true"
                                                [matTooltip]="item?.model"
                                                (click)="$event.stopPropagation()"
                                                [(ngModel)]="item.isAction"
                                                [checked]="isCheckedModule(item)"
                                                [indeterminate]="isIndeterminateModuleOver(item)"
                                                [ngModelOptions]="{ standalone: true }"
                                                (change)="onModuleChange($event, item)"
                                                class="customColorText">
                                                 <span [innerHTML]="item?.label ? item?.label : item?.model"></span>
                                            </mat-checkbox>
                                            </td>
                                            <td class="text-start" [ngStyle]="{'pointer-events': currentMode == 'view' ? 'none' : 'auto'}">
                                                <div class="d-flex flex-wrap">
                                                    <div *ngFor="let actionList of item?.authentication; let i = index">
                                                        <section class="example-section">
                                                        <mat-checkbox
                                                        class="me-3 mb-3 customPolicyCheckboxColor"
                                                        (change)="onActionChange($event, item, actionList)"
                                                        [(ngModel)]="actionList.isAction"
                                                        [ngModelOptions]="{ standalone: true }"
                                                        [matTooltip]="actionList?.label"
                                                        >
                                                            <span class="matLabel" [innerHTML]="actionList?.label ? actionList?.label : actionList?.lebel"></span>
                                                        </mat-checkbox>
                                                      </section>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <div class="text-end">
                            <button  type="submit" class="btn save-btn rounded-pill text-white" (click)="createRole()" [disabled]="isDisabled()" *ngIf="currentMode != 'view'">Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>