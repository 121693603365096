import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },  
};

@Component({
  selector: 'app-common-date-filter',
  templateUrl: './common-date-filter.component.html',
  styleUrls: ['./common-date-filter.component.scss']
})
export class CommonDateFilterComponent implements OnInit {
  @Output() filter_date: EventEmitter<any> = new EventEmitter<any>();
  filter_form!: FormGroup;
  day: any = 'year';
  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }
  someDate: Date = new Date('2021');
  ngOnInit() {
    // this.setYear('year');
  }

  createForm() {
    this.filter_form = this.formBuilder.group({
      from: [''],
      to: [''],
      isMonth: [false]
    });
  }
  from: FormControl = new FormControl('');
  to: FormControl = new FormControl('');
  datePickerEventHandler(type: 'from' | 'to') {
    console.log('datePickerEventHandler called', type);
    switch (type) {
      case 'from':
        const fromValue = moment(this.from.value).format('YYYY-MM-DD');
        this.from.setValue(fromValue);
        this.filter_form.controls.from.setValue(fromValue);
        this.filter_form.controls.to.setValue(null);
        break;
      case 'to':
        const toValue = moment(this.to.value).format('YYYY-MM-DD');
        this.to.setValue(toValue);
        this.filter_form.controls.to.setValue(toValue);
        break;
    }
  }
    // Function to set date range for Today
  // setToday(day:string) {
  //   console.log("day",day)
  //   this.day = day;
  //   const today = moment(new Date()).format('YYYY-MM-DD');
  //   this.from.setValue(today);
  //   this.filter_form.controls.from.setValue(today);
  //   this.to.setValue(today);
  //   this.filter_form.controls.to. setValue(today);
  // }
  // setWeek(day:string) {
  //   console.log("week")
  //   this.day = day;
  //   const today = moment(); // Get current date
  //   const startOfWeek = today.startOf('week').format('YYYY-MM-DD'); // Start of current week
  //   const endOfWeek = today.endOf('week').format('YYYY-MM-DD'); // End of current week
  //   this.from.setValue(startOfWeek);
  //   this.to.setValue(endOfWeek);
  //   this.filter_form.patchValue({
  //     from: startOfWeek,
  //     to: endOfWeek,
  //     isMonth: false
  //   });
  // }
  setMonth(day:string) {
    this.day = day;
    const today = moment(); // Get current date
    const startOfMonth = today.startOf('month').format('YYYY-MM-DD'); // Start of current month
    const endOfMonth = today.endOf('month').format('YYYY-MM-DD'); // End of current month
    this.from.setValue(startOfMonth);
    this.to.setValue(endOfMonth);
    this.filter_form.patchValue({
      from: startOfMonth,
      to: endOfMonth,
      isMonth: true
    });
    this.resetFilter()
  }
  setYear(day:string){
    this.day = day;
    const today = moment(); // Get current date
    const startOfMonth = today.startOf('year').format('YYYY-MM-DD'); // Start of current month
    const endOfMonth = today.endOf('year').format('YYYY-MM-DD'); // End of current month
    this.from.setValue(startOfMonth);
    this.to.setValue(endOfMonth);
    this.filter_form.patchValue({
      from: startOfMonth,
      to: endOfMonth,
      isMonth: false
    });
    this.resetFilter()
  }
  formSubmit() {
    console.log('event', this.filter_form.value);
    this.filter_date.emit(this.filter_form.value)
  }

  resetFilter() {
    // this.filter_form.patchValue({from: ''});
    this.filter_form.patchValue({
      from: '',
      to: ''
    });
    this.from.setValue(null);
    this.to.setValue(null);
    this.filter_date.emit(this.filter_form.value)
    console.log('event', this.filter_form.value);
  }
  
  
  onMonthSelected(event: any, datepicker: MatDatepicker<any>, type:any) {
    const selectedDate = event as Date;
    const selectedMonth = selectedDate.getMonth();
    const selectedYear = selectedDate.getFullYear();
    console.log('Month and year selected:', selectedDate.getMonth(), selectedDate.getFullYear());
  
    // Close the datepicker after month and year selection
    if (datepicker) {
      datepicker.close();
    }

    if(this.day == 'month' && type == 'from'){
      this.from.setValue(selectedDate);
      this.to.setValue(null);
    }else{
      this.to.setValue(selectedDate);
    }
    /* when the user selects a month for the 'to' datepicker, it should be the last day of the month*/
    if (type === 'to') {
      const lastDayOfMonth = moment().year(selectedYear).month(selectedMonth).endOf('month').toDate();
      this.to.setValue(lastDayOfMonth);
    }
  }

  onYearSelected(event:any, datepicker: MatDatepicker<any>, type:any) {

    const selectedDate = event as unknown as Date;
    // Check if the selected date contains only the year component
    if (!isNaN(selectedDate.getTime()) && selectedDate.getMonth() === 0 && selectedDate.getDate() === 1) {
      console.log('Year selected:', selectedDate.getFullYear());
      
      // Close the datepicker after year selection
      if (datepicker) {
        datepicker.close();
      }
      
      if(this.day == 'year' && type == 'from'){
        this.from.setValue(selectedDate);
        this.to.setValue(null);
      }else{
        this.to.setValue(selectedDate)
      }
      // Set the last day of the selected year for the 'to' datepicker
      if (type === 'to') {
        const lastDayOfYear = moment(selectedDate).endOf('year').toDate();
        this.to.setValue(lastDayOfYear);
      }
    }
  }
}
