<div class="Footer_bottom">
    <section >
        <div class="container-fluid">
          <div class="d-flex mb-3 align-items-center" style="margin-top: 25px;">
            <div class="fs-24 fw-600 mb-0" style="color: #444444;">IPSA</div>
          </div>
          <!-- <ng-container > -->
            <div class="row justify-content-center">
              <div class="col-lg-2 col-md-3 col-sm-4 mb-3" *ngFor="let item of formJson">
                <div class="card border-0 mb-3 h-100 main_card">
                  <div class="card-body text-center">
                    <div class="fs-14 fw-500 mb-2 text-center">{{item?.formName}}</div>
                    <img [src]="item?.image" class="img-fluid mb-3" alt="">
                    <button class="btn d-flex align-items-center justify-content-center explore_btn rounded-pill m-auto" (click)="toggle(item)">
                      <span>
                        <svg class="me-1" id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="17.652" height="17.652" viewBox="0 0 17.652 17.652">
                          <path id="Path_7601" data-name="Path 7601" d="M0,0H17.652V17.652H0Z" fill="none"/>
                          <path id="Path_7602" data-name="Path 7602" d="M3,10.355H8.884V3H3Zm0,5.884H8.884V11.826H3Zm7.355,0h5.884V8.884H10.355ZM10.355,3V7.413h5.884V3Z" transform="translate(-0.793 -0.793)"/>
                        </svg>
                      </span>
                       Explore</button>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="isShowReport" >
              <div class="col-lg-2 col-md-3 col-sm-4 mb-3">
                <div class="card border-0 mb-3 h-100 main_card">
                  <div class="card-body text-center">
                    <div class="fs-14 fw-500 mb-2 text-center">IPSA Reports</div>
                    <img src="../../../../assets/images/ipsaReport-icon.svg" class="img-fluid mb-3" alt="">
                    <button class="btn d-flex align-items-center justify-content-center explore_btn rounded-pill m-auto" routerLink="/ipsa-report">
                      <span>
                        <svg class="me-1" id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="17.652" height="17.652" viewBox="0 0 17.652 17.652">
                          <path id="Path_7601" data-name="Path 7601" d="M0,0H17.652V17.652H0Z" fill="none"/>
                          <path id="Path_7602" data-name="Path 7602" d="M3,10.355H8.884V3H3Zm0,5.884H8.884V11.826H3Zm7.355,0h5.884V8.884H10.355ZM10.355,3V7.413h5.884V3Z" transform="translate(-0.793 -0.793)"/>
                        </svg>
                      </span>
                       Explore</button>
                  </div>
                </div>
              </div>
            </ng-container>
            </div>
            <!-- <div class="row">
                <div class="col-md-4" *ngFor="let item of formJson">
                    <div class="mb-3 border-0 position-relative img-opacity h-190">
                        <img [src]="item?.image" class="img-fluid w-100 border-15 h-190" alt="">
                        <div class="position-absolute" style="bottom: 20px; left: 20px;">
                            <div class="mb-2 fs-16 text-white">{{item?.formName}}</div>
                            <button class="btn d-flex align-items-center view-btn rounded-pill" (click)="toggle(item)">View</button></div>
                    </div>
                </div>
            </div> -->
          <!-- </ng-container> -->

        </div>
    </section>
</div>