<div class="Footer_bottom">
    <section class="partner-comp d-flex flex-column align-items-center justify-content-center mb-5">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="col text-center mt-3">
                        <h1 class="text-white">{{ourPartners[0].title ? ourPartners[0].title : "Our Partners"}}</h1>
                        <div>
                            <p class="text-white text-center" style="font-size: 14px;">{{ourPartners[0].subTitle}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="cardWisePartners my-2">
        <div class="container">
            <!-- ../../../assets/images/coming-soon.png -->
            <div class="row">
                <div class="col-sm-12">
                    <ng-container  *ngFor="let item of ourPartners[0].data; let i = index">
                        <mat-card class="partnerCard mb-3" *ngIf="i % 2 == 0">
                            <div class="row align-items-center">
                                <div class="col-sm-3">
                                    <img [src]="item?.image" class="partner-img img-fluid">
                                </div>
                                <div class="col-sm-9">
                                    <h2 class="ml-3">{{item?.title}}</h2>
                                    <ng-container class="mt-3">
                                        <mat-card-content class="mt-2">{{item?.description}}</mat-card-content>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-card>
    
                        <mat-card class="partnerCard mb-3" *ngIf="i % 2 != 0">
                            <div class="row align-items-center">
                                <div class="col-sm-9">
                                    <h2 class="ml-3">{{item?.title}}</h2>
                                    <ng-container class="mt-3">
                                        <mat-card-content class="mt-2">{{item?.description}}</mat-card-content>
                                    </ng-container>
                                </div>
                                <div class="col-sm-3">
                                    <img [src]="item?.image" class="partner-img img-fluid">
                                </div>
                            </div>
                        </mat-card>
                    </ng-container>
                </div>
            </div>
        </div>
    </section>
</div>