<div class="Footer_bottom">
    <section class="mt-2">
        <div class="container-fluid">
          <div class="d-flex align-items-center">
            <svg class="me-2 pointer" routerLink="/ipsa" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
              </svg>
              
            <nav aria-label="breadcrumb" class="my-3">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item fs-18 fw-400 pointer" routerLink="/ipsa">IPSA</li>
                <li class="breadcrumb-item fs-18 fw-400 active pointer">IPSA Report</li>
              </ol>
            </nav>
          </div>
            <div>
                <button class="btn d-flex align-items-center text-white filterBtn ms-auto rounded-pill mb-3" mat-button [matMenuTriggerFor]="menu"  #t="matMenuTrigger"><img src="/assets/dashboardMedia/filter.svg" class="filterIcon" alt=""> Filter</button>
                <mat-menu #menu="matMenu" class="check reportFilter">
                    <div class="card-body">
                        <div class="container" (click)="$event.stopPropagation()">
                            <form [formGroup]="userForm">
                                <div class="row">
                                  <div class="col-md-4" >
                                    <mat-form-field appearance="outline" class="w-100 p-0 mb-3">
                                      <mat-label>Select Year</mat-label>
                                      <mat-select formControlName="year"  >
                                        <mat-option *ngFor="let item of years" [value]="item?.value">
                                          {{item?.year}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                                    <div class="col-md-4" >
                                        <mat-form-field appearance="outline" class="w-100 p-0 mb-3">
                                          <mat-label>Country Name </mat-label>
                                          <mat-select formControlName="countryId"  (selectionChange)="countryChangeHandler($event)" >
                                            <mat-select-trigger *ngIf="userForm?.value?.countryId">
                                              <img [src]="userForm.value?.countryId?.country_icons " alt="Image" class="option-image">
                                              <span>{{userForm.value?.countryId?.name}}</span>
                                            </mat-select-trigger>
                                            <mat-option *ngFor="let country of countrys" [value]="country">
                                              <img [src]="country?.country_icons" alt="Image" class="option-image">
                                              {{country?.name}}
                                            </mat-option>
                                          </mat-select>
                                         
                                        </mat-form-field>
                                      </div>
                                      <div class="col-md-4" >
                                        <mat-form-field appearance="outline" class="w-100 p-0 mb-3">
                                          <mat-label>IP NAME </mat-label>
                                          <mat-select formControlName="industrialparkId"  (selectionChange)="iPChangeHandler($event)" [disabled]="!ipNames?.length">
                                            <mat-option *ngFor="let ipName of ipNames" [value]="ipName">
                                              {{ipName?.name}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                                      <div class="col-md-4" *ngIf="showFactoryDropdown">
                                        <mat-form-field appearance="outline" class="w-100 p-0 mb-3">
                                          <mat-label>Factory Name </mat-label>
                                          <mat-select formControlName="factoryId" [disabled]="!factoryNames?.length">
                                            <mat-option *ngFor="let factoryName of factoryNames" [value]="factoryName">
                                              {{factoryName?.name}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </div>
                        
                                      <div class="text-end">
                                        <!-- <button *ngIf="!userId" class="btn create-btn rounded-pill text-white" (click)="createUser()" [disabled]="userForm.invalid">Create</button>
                                          <button *ngIf="userId" type="submit" class="btn create-btn rounded-pill text-white" (click)="createUser()" [disabled]="userForm.invalid">Update</button> -->
                                          <button class="btn btn-reset rounded-pill me-2" [disabled]="isEmptyString()" (click)="resetFilter()"><span>Reset</span></button>
                                          <button  class="btn btn-search rounded-pill text-white" [disabled]="isEmptyString()" (click)="search()"><span>Search</span></button>
                                      </div>
                                </div>
                            </form>
                          </div>
                    </div>
                      
                </mat-menu>
            </div>

            <div class="card border-0 mb-3 shadow-sm">
              <mat-paginator class="f-14" [length]="totalDataCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalDataCount !== 0"></mat-paginator>
              <div class="card-body">
                <div class="table-responsive" *ngIf="reportTable?.length">
                  <table class="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th *ngFor="let heading of headings">{{heading.label}} 
                        <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="heading?.key " (click)="sortTable(heading?.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (heading?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                            <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="heading?.key " (click)="sortTable(heading?.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (heading?.key == selectedColumn ? '' : 0.3) : 0.3}">
                      </th>
                      <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']" >
                      <th *ngIf="buttonPermission?.key && buttonPermission?.key === 'report' && buttonPermission?.isAction">Action</th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of reportTable; let i = index">
                      <td>{{i + skip + 1}}</td>
                      <td>{{item?.year}}</td>
                      <td>{{item?.country_name}}</td>
                      <td>{{item?.industrialpark_name}}</td>
                      <td>{{item?.factory_name || ''}}</td>
                      <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']" >
                      <td *ngIf="buttonPermission?.key && buttonPermission?.key === 'report' && buttonPermission?.isAction" >
                        <button mat-button [matMenuTriggerFor]="downloadOption" class="downloadReport"><img src="../../../../assets/images/download-form.svg" class="img-fluid pointer" alt=""></button>
                        <mat-menu #downloadOption="matMenu">
                          <button mat-menu-item (click)="downloadReport(item, 'with')">With Evidence</button>
                          <button mat-menu-item (click)="downloadReport(item, 'without')">Without Evidence</button>
                        </mat-menu>  
                      </td>
                      </ng-container>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <div *ngIf="isNodataFound" class="d-flex justify-content-center">
                  <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">

              </div>
              </div>
            </div>
        </div>
   