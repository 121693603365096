<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedMatIndex"
            (selectedTabChange)="tabWiseData($event)" animationDuration="2000ms">
                <mat-tab >
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">inbox</mat-icon>
                        Inbox
                    </ng-template>
                    <div class="Footer_bottom">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-3 col-md-4 px-1">
                                   <div>
                                    <app-communication-sidebar (viewData)="recievedData($event)" (tabSwitched)="tabSwitched($event)" [msgHighlighted]="msgHighlighted" [msgDeleted]="msgDeleted" [msgUpdated]="msgUpdated" (setApplyFiltr)="setApplyFiltr($event)" [selectedMatIndex]="selectedMatIndex"></app-communication-sidebar>
                                   </div>
                                </div>
                            
                                <div class="col-lg-9 col-md-8 bg-white">
                                    <div class="px-3" *ngIf="itemSelected">
                                        <div class="d-flex justify-content-between pt-4 pb-3 mb-3 title-div">
                                            <h1 class="fs-24 fw-600 color-44">{{messageLineItemDetails?.subject ? messageLineItemDetails?.subject : ''}}</h1>
                                            <div>
                                                <!-- <img src="../../../assets/images/bell-icon.svg" class="img-fluid me-2 " style="cursor: pointer;" alt="" > -->
                                                <img [src]="messageLineItemDetails.is_highlight ? '../../../assets/images/yellowStar.png' : '../../../assets/images/largeStar-icon.svg'" 
                                                    class="img-fluid me-2" alt="" style="cursor: pointer; height: 24px; width: 24px;" (click)="actionsOnChat('highlight')" matTooltip="Highlight Chat">
                                                <!-- <img src="../../../assets/images/print-icon.svg" class="img-fluid me-2" alt="" style="cursor: pointer;" matTooltip="Print"> -->
                                                <img src="../../../assets/images/deleteChat-icon.svg" class="img-fluid me-2" alt="" style="cursor: pointer; display: none;" (click)="actionsOnChat('delete')" matTooltip="Delete" *ngIf="hasActionTrueForModel('communicationmapper','update')">
                                            </div>
                                        </div>
                            
                                        <div class="d-flex align-items-center mb-3">
                                            <!-- <img src="../../../assets/images/ak-icon.svg" class="img-fluid me-2" alt=""> -->
                                            <div class="profileNameBox me-2" [ngStyle]="{'background-color': messageLineItemDetails?.color}">
                                                <span class="nameInitials">{{messageLineItemDetails.nameInitials}}</span>
                                              </div>
                                            <div>
                                                <div class="fs-14 color-44">{{messageLineItemDetails.name  }}</div>
                                                <p class="fs-11 color-44 m-0">{{messageLineItemDetails.createdAt | date:'M/d/yy, h:mm a'}}</p>
                                            </div>
                                        </div>
                        
                                        <div class="card data-card">
                                            <div class="row card-head">
                                                <!-- <div class="d-flex"> -->
                                                    <div class="col-sm-6">
                                                        <div class="card-content d-none">
                                                            <div class="fs-13 color-44 mb-1">Subject</div>
                                                            <div class="fs-16 fw-600">{{messageLineItemDetails?.subject}}</div>
                                                        </div>
                            
                                                        <div class="card-content">
                                                            <div class="fs-13 color-44 mb-1">Sender</div>
                                                            <div class="fs-16 fw-600">{{messageLineItemDetails.name}}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6">
                                                        <div class="card-content float-right">
                                                            <div class="fs-13 color-44 mb-1">Recipient</div>
                                                            <div class="fs-16 fw-600">{{messageLineItemDetails.receiver_type == 'ip' ? 
                                                            messageLineItemDetails.industrialpark.name : 
                                                            messageLineItemDetails.receiver_type == 'factory' ? 
                                                            messageLineItemDetails.factory.name : 
                                                            messageLineItemDetails.receiver_type == 'admin' ? 
                                                            messageLineItemDetails.admin.name :
                                                            messageLineItemDetails.assessor.name}}</div>
                                                        </div>
                                                    </div>
                        
                                                <!-- </div> -->
                                            </div>
                                            <div class="card-body">
                                                <div class="fs-13 color-44 fw-600 mb-2">Message</div>
                                                <p class="fs-15 fw-400 color-44">{{messageLineItemDetails?.message}}</p>
                                            </div>
                                        </div>
                    
                                        <!-- Section: Timeline -->
                                        <div class="accordion mb-3" id="accordionExample" *ngIf="hasActionTrueForModel('communicationmapper','list')">
                                            <div class="accordion-item">
                                              <h2 class="accordion-header bg-light" id="headingOne">
                                                <button class="accordion-button fw-600" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                 Chats
                                                </button>
                                              </h2>
                                              <div id="collapseOne" class="accordion-collapse spacing show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body accordBody_scroll" id="accordionBody">
                                                    <section  [ngClass]="{ 'py-3': messageLineItemDetails.communicationmapper?.length }">
                                                        <ul class="timeline" *ngIf="messageLineItemDetails.communicationmapper?.length">
                                                        <li class="timeline-item mb-3" *ngFor="let mapper of messageLineItemDetails.communicationmapper">
                                                            <span class="text-muted mb-2 fw-bold">{{mapper?.logcreatedby?.name}}</span>
                                                            <span class="text-muted mb-2 float-right logDate">{{mapper?.updatedAt | date: 'medium' }}</span>
                                                            <!-- <div class="text-muted mb-2">
                                                                <span class="msgStatus px-3 py-1" [ngStyle]="{'background-color': mapper?.status_for_message == 1 ? '#7ad67a' : mapper?.status_for_message == 2 ? '#ff9c09' : 'crimson'}">
                                                                    <i>{{mapper?.status_for_message == 1 ? 'Replied' : mapper?.status_for_message == 2 ? 'Pending' : 'Reminder'}}</i>
                                                                </span>
                                                            </div> -->
                                                            <p class="text-muted"><i>{{mapper?.message}}</i></p>
                                                        </li>
                                                        </ul>
                    
                                                        <ul class="d-flex justify-content-center align-items-center" *ngIf="!messageLineItemDetails.communicationmapper?.length">
                                                            <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                                                        </ul>
                                                    </section>
                                                </div>
                                              </div>
                                            </div>
                                            </div>
                                        <!-- Section: Timeline -->

                                        <!-- Removed condition "messageLineItemDetails?.sender_id != userData?.id &&" as per the PM requirement -->
                                        <div *ngIf="currentAction">
                                            <div class="d-sm-flex flex-wrap justify-content-between bottom-section">
                                                <div class="row">
                                                    <div class="col-sm-9">
                                                        <form (ngSubmit)="replyStatus('replied')">
                                                            <input type="text" placeholder="Type a message" class="form-control rounded-pill input-text mb-3" [(ngModel)]="commentMsg">
                                                        </form>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <button class="btn fs-15 mb-3 d-flex align-items-center justify-content-center replied-btn rounded-pill me-3 ml-2" (click)="replyStatus('replied')" [disabled]="!commentMsg">
                                                            <span>
                                                                <img src="../../../assets/images/replied-icon.svg" class="img-fluid me-2" alt="">
                                                            </span>Send
                                                        </button>
                                                    </div>
                                                </div>
                        
                                               <!-- <div class="d-sm-flex justify-content-sm-end">
                                                <button class="btn fs-15 mb-3 d-flex align-items-center justify-content-center replied-btn rounded-pill me-3" (click)="replyStatus('replied')" [disabled]="!commentMsg">
                                                    <span>
                                                        <img src="../../../assets/images/replied-icon.svg" class="img-fluid me-2" alt="">
                                                    </span>Send
                                                </button>
                                                <button class="btn fs-15 mb-3 d-flex align-items-center justify-content-center pending-btn rounded-pill me-3" (click)="replyStatus('pending')" [disabled]="!commentMsg">
                                                    <span>
                                                        <img src="../../../assets/images/pending-icon.svg" class="img-fluid me-2" alt="">
                                                    </span>Pending
                                                </button>
                                                <button class="btn fs-15 mb-3 d-flex align-items-center justify-content-center reminder-btn rounded-pill me-3" (click)="replyStatus('reminder')" [disabled]="!commentMsg">
                                                    <span>
                                                        <img src="../../../assets/images/reminder-icon.svg" class="img-fluid me-2" alt="">
                                                    </span>Send Reminder
                                                </button>
                                               </div> -->
                                            </div>
                                        </div>
                        
                                    </div>
                    
                                    <div *ngIf="!itemSelected">
                                        <div class="col-12 selectItem" style="display: flex; height: 600px;">
                                            <img src="../../../assets/images/noData.svg" alt="">
                                            <span class="selectLabel" id="EmptyState_MainMessage">Select an item to read</span>
                                            <span class="notify">Nothing is selected</span>
                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="tab-icon">message</mat-icon>
                        Compose Message
                    </ng-template>
                    <app-compose-message [selectedMatIndex]="selectedMatIndex"></app-compose-message>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>