import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name : 'truncatePipe'
})

export class CustomPipe implements PipeTransform{
    // transform(value: any, ...args: any[]) {
    //     return (value * 100).toFixed(2) + '%';
    // }
    transform(value: string, len: number): any {
        if (typeof value === "string" && typeof len === "number") {
          return value && value?.length > len
            ? value.substring(0, len) + "..."
            : value;
        }
      }
}