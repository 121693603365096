import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  districtBoundaries:any;
  stateBoundaries:any;
  // stateUrl:string = "https://stgaajeevika-api.dhwaniris.in/aajeevika/content/state/list";
  // districtUrl:string = "https://stgaajeevika-api.dhwaniris.in/aajeevika/content/district/list";
  // overallData:string = "https://run.mocky.io/v3/53d34579-94d2-4979-a2af-8ce34c19cd4a";

  constructor(private httpClient: HttpClient) { }

  getState():Observable<any>{
    return this.httpClient.get(`${environment.url2}state/list?fetched=All`);
  }
  
  getDistrict(stateID:any):Observable<any>{
    return this.httpClient.get(`${environment.url2}district/list?state_id=${stateID}&fetched=All`);
  }

  async getStatesGeoJson(){
    if(!this.districtBoundaries){
        const districtResponse = await fetch('../../../assets/Const_Json/districts_boundaries.json');
        this.districtBoundaries = await districtResponse.json();
    }
    if(!this.stateBoundaries){
        const stateResponse = await fetch('../../../assets/Const_Json/state_boundaries.json');
        this.stateBoundaries = await stateResponse.json();
    }
    return Promise.resolve({districtBoundaries:this.districtBoundaries, stateBoundaries:this.stateBoundaries});
}

  getOverallScore(limit:any, page:any, obj:any):Observable<any>{
    // let url = `${environment.url2}dashboard/overall-score?`;
    // url += `limit=${limit}&page=${page}`;
    // url += obj?.state ?`&state_id=${obj.state}`: "";
    return this.httpClient.get(`${environment.url2}dashboard/overall-score?limit=${limit}&page=${page}&state_id=${obj ? obj.state : ''}&district_id=${obj ? obj.district : ''}&site_id=${obj ? obj.siteName : ''}&theme_id=${obj ? obj.thematic : ''}&overallScore=${!obj.overallSiteRange ? '' : obj.overallSiteRange}&accessibility=${!obj.accessibilityRange ? '' : obj.accessibilityRange}&amenities=${!obj.amenitiesRange ? '' : obj.amenitiesRange}&hygiene=${!obj.hygieneRange ? '' : obj.hygieneRange}&safety=${!obj.safetySecurityRange ? '' : obj.safetySecurityRange}&destination_id=${!obj.destination ? '' : obj.destination}`);
  }

  downloadCsvData(obj:any){
    let url = `${environment.url2}dashboard/overall-score-csv?state_id=${obj ? obj.state : ''}&site_id=${obj ? obj.siteName : ''}&overallScore=${!obj.overallSiteRange ? '' : obj.overallSiteRange}&accessibility=${obj ? obj.accessibilityRange : ''}&amenities=${obj ? obj.amenitiesRange : ''}&hygiene=${obj ? obj.hygieneRange : ''}&safety=${obj ? obj.safetySecurityRange : ''}`;
    return url;
  }

  getThemeticData(stateID:any):Observable<any>{
    return this.httpClient.get(`${environment.url2}theme/list?state_id=${stateID}&fetched=All`);
  }

  getOverallScoreMap(obj:any):Observable<any>{
    return this.httpClient.get(`${environment.url2}dashboard/overall-score-map?state_id=${obj ? obj.state : ''}&district_id=${obj ? obj.district : ''}&site_id=${obj ? obj.siteName : ''}&theme_id=${obj ? obj.thematic : ''}&overallScore=${!obj.overallSiteRange ? '' : obj.overallSiteRange}&accessibility=${!obj.accessibilityRange ? '' : obj.accessibilityRange}&amenities=${!obj.amenitiesRange ? '' : obj.amenitiesRange}&hygiene=${!obj.hygieneRange ? '' : obj.hygieneRange}&safety=${!obj.safetySecurityRange ? '' : obj.safetySecurityRange}&destination_id=${!obj.destination ? '' : obj.destination}`);
  }

  getSiteDetails(id:any):Observable<any>{
    return this.httpClient.get(`${environment.url2}dashboard/site-details?site_id=${id}`);
  }

  getdestinationAPIData(stateID:any):Observable<any>{
    return this.httpClient.get(`${environment.url2}destination/list?state_id=${stateID}&fetched=All`);
  }

  getsiteNameData(stateID:any):Observable<any>{
    return this.httpClient.get(`${environment.url2}sites/list?state_id=${stateID}&fetched=All`);
  }

  countApi(docId: any = '', requestType: any = '') {
    let payload = {
      requestId: requestType,
      id: docId,
    };

    const url =
      environment.url2 +
      `aajeevika/content/downloadviewcountfile?id=${payload.id}&requestId=${payload.requestId}`;
    return this.httpClient.post(url, payload);
  }

  //get document list
  getContent(type: string, parameters: string = '') {
    const url =
      `${environment.url2}` + type + '?' + parameters;
    return this.httpClient.get(url);
  }
}
