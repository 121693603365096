import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';


import { Chart } from 'chart.js';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';

Chart.register(TreemapController, TreemapElement);


@Component({
  selector: 'app-tree-chart',
  templateUrl: './tree-chart.component.html',
  styleUrls: ['./tree-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class TreeChartComponent implements OnInit, AfterViewInit {
  @ViewChild('chartArea') private chartArea!: ElementRef<HTMLCanvasElement>;

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {

  }

  getColor(value: any, legend:any) {
    // return this.data?.colorDetails?.find((item: any) => value >= item.min && value <= item.max)?.color || "#F3FAFF";
    return this.colorFromRaw(value, legend)
  }

  findIndexInCategory(array:any, number:number) {
    const sortedArray = array.slice().sort((a:any, b:any) => a - b);

    for (let i = 0; i < sortedArray.length; i++) {
        if (number <= sortedArray[i]) {
            return i;
        }
    }
    if(number > sortedArray[4])
    return 4

    return sortedArray.length; // If number is greater than all elements
}

  colorFromRaw(value:any, legend:any) {
    console.log("colorfromIndex",value, legend);
    // if (ctx.type !== 'data') {
    //   return 'transparent';
    // }
    // const value = ctx.raw.v;
    // let alpha = (1 + Math.log(value)) / 5;
    let item = this.findIndexInCategory(legend,value);
    console.log("colorfromIndex item",item);
    switch (item) {
      case 0:
        console.log('case - 0');
        return '#e9ffdb';
      case 1:
        return '#d0f0c0';
      case 2:
        return '#ace1af';
        case 3:
        return '#a8e4a0';
        case 4:
        return '#008000';
      default:
        return '#e9ffdb';
    }
  }

  generateLegend(data:any) {
    console.log("legend", data)
    const totalCountArray = data.short_key.map((item:any) => item.total_count);
    let minValue:number =  Math.min(...totalCountArray);
    let maxValue:number =  Math.max(...totalCountArray);
    const step = (maxValue - minValue) / 5;
    const legend = [];

    for (let i = 0; i < 5; i++) {
        const value = minValue + step * i;
        legend.push(value.toFixed(2)); // Adjust the precision as needed
    }

    return legend;
}


  ngAfterViewInit(): void {
    // console.clear();
    console.log(this.chartArea.nativeElement, 'canvas');
    let ctx = this.chartArea.nativeElement.getContext('2d')!;

    const tree = this.data.short_key.map((item: any) => ({ value: item.total_count, what: item.question_label, unit:item?.value_format}));
    let unitValue = this.data.short_key.length ? this.data.short_key[0]?.value_format : '';
    
    let legend:any = this.generateLegend(this.data);
    console.log("legend", legend)
  
    const config = {
      type: 'treemap',
      data: {
        datasets: [
          {
            label: "",
            tree,
            key: 'value',
            groups: ['what'],
            borderWidth: 0,
            borderRadius: 6,
            spacing: 1,
            backgroundColor: (ctx: any) => {
              if (ctx.type !== 'data') {
                return 'transparent';
              }

              return this.getColor(ctx.raw._data.value, legend);
            },
            labels: {
              align: 'left',
              display: true,
              formatter(ctx: any) {
                if (ctx.type !== 'data') {
                  return;
                }
                console.log("ctxRawSplitter", ctx,  ctx.raw._data)
                let valueUnit = ctx.raw.v+ ' (' +ctx.raw._data?.children[0]?.unit + ')';
                return [...splitLabelToFit(ctx.raw["_data"].what, ctx.raw.v*0.5, ctx),valueUnit] ;
                // return [ctx.raw._data.what, ctx.raw.v];
              },
              color: ['black', 'black'],
              font: [{ size: 12, weight: 'bold' }, { size: 12 }],
              position: 'top'
            }
          },
        ],
      },
      options:{
        plugins: {
          legend:{
            display: false
          },
          datalabels: {
              display: false,
          },
          tooltip: {
            callbacks: {
              footer: footer,
            }
          }
      }
      }
    };

    function footer(tooltipItems:any){
      let sum = 0;
    
      // tooltipItems.forEach(function(tooltipItem:any) {
      //   sum += tooltipItem.parsed.y;
      // });
      console.log("ctxRawSplittertooltip", tooltipItems)
      return unitValue ? '('+unitValue +')' : '';
    };

    function splitLabelToFit(label: string, maxWidth: number, ctx:any) {
      console.log("splitter", label,maxWidth,ctx)
      const words = label.split(' ');
      const lines = [];
      let currentLine = '';
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const newLine = currentLine + ' ' + word;
        const width = ctx.chart.ctx.measureText(newLine).width;
        if (width < maxWidth) {
          currentLine = newLine;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      }
      lines.push(currentLine);
      return lines;
    }


    new Chart(ctx, config as any);
  }
}