<div class="Footer_bottom">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card border-0 mb-3">
                    <div class="card-body">
                        <div class="d-sm-flex mb-3 justify-content-between align-items-center">
                            <div>
                                <mat-paginator class="f-14" [length]="totalCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalCount !== 0 && tableData?.length"></mat-paginator>
                            </div>
                            <div class="position-relative">
                                <div class="row">
                                    <div class="col-sm-auto">
                                        <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-icon" alt="">
                                        <input [autofocus]="true" (keydown.enter)="tableSearch($event)"  type="text" placeholder="Search by" [(ngModel)]="searchString" class="form-control search-input"/>
                                    </div>
                                    <div class="col-sm-auto">
                                        <button class="btn btn-success btn-sm clearSearchBtn" (click)="search()" *ngIf="searchString">Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="bg-light">
                                    <tr>
                                        <th class="fw-600">S.No</th>
                                        <th class="fw-600" *ngFor="let item of tableHeading">
                                         {{item.code}}
                                         <!-- <i class="fa fa-fw fa-sort pointerHead" *ngIf="item?.sort" (click)="sortTable(item?.key)">
                                        </i> -->
                                        <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="item?.key && item?.key != 'action' && item?.key != 'label'" (click)="sortTable(item?.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                        <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="item?.key && item?.key != 'action' && item?.key != 'label'" (click)="sortTable(item?.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white">
                                    <tr *ngFor="let data of tableData; let rowIndex = index" >
                                        <td class="sticky-col first-col" >
                                            {{skip+rowIndex + 1}}
                                        </td>
                                        <ng-container *ngFor="let header of tableHeading; let columnIndex = index">
                                            <td>
                                                <ng-container *ngIf="header.isClickable;else notClickable">
                                                    <span (click)="emitFunction('clickableText',data,header.key,rowIndex,columnIndex)" class="form-route text-decoration-none" [matToottip]="data[header.key]">{{data[header.key] ? data[header.key] : '---'}}</span>
                                                </ng-container>
                                                <ng-template #notClickable>
                                                    <ng-container *ngIf="header?.key !== 'action' && !header?.imageKey && header?.type != 'arrayOfObject' && (header?.key=== 'name' ) && header?.type !== 'date'">
                                                        <span [matToottip]="data[header.key]">{{data[header.key] ? data[header.key]  : '---'}}</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="header?.key !== 'action' && !header?.imageKey && header?.type != 'arrayOfObject' && (header?.key !== 'name' && !(header?.key == 'label')) && header?.type !== 'date'">
                                                        <span [matToottip]="data[header.key]">{{data[header.key] ? data[header.key]  : '---'}}</span>
                                                    </ng-container>
                                            
                                                    <ng-container *ngIf="header?.key !== 'action' && !header?.imageKey && header?.type != 'arrayOfObject' && header?.key === 'label' && header?.type !== 'date'">
                                                        <span [matToottip]="getRoleModelName(data,header?.key,rowIndex)">{{ getRoleModelName(data,header?.key,rowIndex)  }}</span>
                                                    </ng-container>
                                                    <!-- <ng-container *ngIf="header?.key !== 'action' && !header?.imageKey && header?.type != 'arrayOfObject' && !(header?.key === 'model' || header?.key=== 'name' || header?.key === 'role.name') && header?.type !== 'date'">
                                                        <span [matToottip]="getValue(data[header?.key])">{{data[header.key] ? getValue(data[header?.key]) : '---'}}</span>234
                                                     </ng-container> -->
                                                    <ng-container *ngIf="header?.key !== 'action' && header?.imageKey &&  header?.type !== 'date'">
                                                        <!-- <img > -->
                                                        <span [matTooltip]="getValue(data[header?.key])">{{data[header.key] ? data[header.key] : '---'}}</span>
                                                    </ng-container>
                                                         <!-- Array of Objects this require subKey in headers -->
                                                    <ng-container *ngIf="header?.type == 'arrayOfObject'" >
                                                        <span class="pointer-hand two-line-text" [matTooltip]="getMapArr(header?.type,data[header?.key],header?.subKey)" matTooltipPosition="above" [matTooltip]="getMapArr(header?.type,data[header?.key],header.subKey)">
                                                            {{getMapArr(header?.type,data[header?.key],header.subKey)}}
                                                        </span>
                                                    </ng-container>
                                                    <ng-container *ngIf="header?.type == 'date'" >
                                                        <span  matTooltipPosition="above">{{( data[header?.key] | date:'dd-MM-yyyy hh:mm a' ) }}</span>
                                                    </ng-container>
            
                                                    <ng-container *ngIf="header?.key == 'action'">
                                                        <ng-container *ngFor=" let action of actionInTable let i=index">
                                                            <img [src]="action?.imgIcon" [matTooltip]="action?.toolTip" class="fontSize pointer p-2" (click)="actionMethod(action,$event,data)" *ngIf="action?.isPermission" [ngStyle]="{'width': action?.key == 'view' ? '36px' : '' }">
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-template>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody> 
                            </table>
                        </div>
        
                        <div class="d-flex justify-content-center align-items-center p-5" *ngIf="!tableData?.length">
                            <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        
    </div>
</div>