<div class="Footer_bottom">
    <section class="pt-4" *ngIf="userDetail">
        <div class="container-fluid">
            <div class="fs-18 fw-600 mb-3" style="color: #141414;">My Profile</div>
            <div class="card border-0 profile-card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="me-4 col-md-auto position-relative">
                            <img [src]="showImage ? showImage : '../../../assets/images/user.png'" class="img-fluid profile-img border rounded-circle mb-3" alt="">
                            <div class="edit-box position-absolute d-sm-none d-flex align-items-center justify-content-center rounded-circle" (click)="viewUserDetail()">
                                <svg id="edit_black_24dp_4_" data-name="edit_black_24dp(4)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_8043" data-name="Path 8043" d="M0,0H20V20H0Z" fill="none"/>
                                    <path id="Path_8044" data-name="Path 8044" d="M12.334,8.081l.776.776L5.464,16.5H4.688v-.776l7.646-7.646M15.372,3a.845.845,0,0,0-.591.245L13.237,4.789,16.4,7.954,17.946,6.41a.84.84,0,0,0,0-1.19L15.971,3.245a.829.829,0,0,0-.6-.245ZM12.334,5.692,3,15.026v3.165H6.165L15.5,8.857Z" transform="translate(-0.595 -0.595)" fill="#fff"/>
                                  </svg>
                            </div>
                        </div>
                        <div class="col">
                            <div class="d-flex justify-content-between mb-2">
                                <h5 class="fs-24 fw-bold">{{userAllDetails?.name ? userAllDetails?.name : '---'}}</h5>
                                <button class="btn text-white fs-12 edit-btn rounded-pill d-none d-sm-flex justify-content-center align-items-center" (click)="viewUserDetail()">Edit Profile
                                    <span class="ms-2">
                                        <svg id="edit_black_24dp_4_" data-name="edit_black_24dp(4)" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                            <path id="Path_8043" data-name="Path 8043" d="M0,0H20V20H0Z" fill="none"/>
                                            <path id="Path_8044" data-name="Path 8044" d="M12.334,8.081l.776.776L5.464,16.5H4.688v-.776l7.646-7.646M15.372,3a.845.845,0,0,0-.591.245L13.237,4.789,16.4,7.954,17.946,6.41a.84.84,0,0,0,0-1.19L15.971,3.245a.829.829,0,0,0-.6-.245ZM12.334,5.692,3,15.026v3.165H6.165L15.5,8.857Z" transform="translate(-0.595 -0.595)" fill="#fff"/>
                                          </svg>
                                          
                                    </span>
                                </button>
                            </div>
    
                            <div class="d-md-flex personal-imformation">
                                <div class="email-div me-md-5">
                                    <div class="fs-12 color-44">Email ID</div>
                                    <!-- <p class="fs-16 fw-500 color-44 m-0">rajesh.kumar@gmail.com</p> -->
                                    <p class="fs-16 fw-500 color-44 m-0">{{userAllDetails?.email ? userAllDetails?.email : '---'}}</p>
                                </div>
                                <div class="role-div me-md-5">
                                    <div class="fs-12 color-44">Role</div>
                                    <!-- <p class="fs-16 fw-500 color-44 m-0">Admin</p> -->
                                    <p class="fs-16 fw-500 color-44 m-0">{{userAllDetails['role.name'] ? userAllDetails['role.name'] : '---'}}</p>
                                </div>
                                <div class="number-div me-md-5" *ngIf="userAllDetails.mobile">
                                    <div class="fs-12 color-44">Mobile Number</div>
                                    <p class="fs-16 fw-500 color-44 m-0">{{userAllDetails?.country_code ? '+' +userAllDetails.country_code : ''}} {{userAllDetails.mobile ? userAllDetails.mobile : '---'}}</p>
                                </div>
                                
                                <div class="location-div me-md-5" *ngIf="[7,5,4].includes(userAllDetails?.userlevel_id)">
                                    <div class="fs-12 color-44">Country</div>
                                    <p class="fs-16 fw-500 color-44 m-0">{{userAllDetails['country.name'] ? userAllDetails['country.name'] : '---'}}</p>
                                </div>

                                <div class="location-div me-md-5" *ngIf="[7,5].includes(userAllDetails?.userlevel_id)">
                                    <div class="fs-12 color-44">Industrial Park </div>
                                    <p class="fs-16 fw-500 color-44 m-0">{{userAllDetails['industrialpark.name'] ? userAllDetails['industrialpark.name'] : '---'}}</p>
                                </div>

                                <div class="location-div me-md-5" *ngIf="[7].includes(userAllDetails?.userlevel_id)">
                                    <div class="fs-12 color-44">Factory</div>
                                    <p class="fs-16 fw-500 color-44 m-0">{{userAllDetails['factory.name'] ? userAllDetails['factory.name'] : '---'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="card border-0 profile-card mb-3" *ngIf="userData?.userlevel_id == 1">
                <div class="d-flex justify-content-between align-items-center border-bottom" style="padding: 1rem;">
                    <h6 class="fs-16 fw-600">User Permissions</h6>
                    <div class="px-2 position-relative d-none">
                        <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute filter-search-icon" alt="">
                        <input type="search" placeholder="Search" class="form-control filter-search">
                    </div>
                </div>
                <div class="card-body py-0">
                    <div class="row">
                        <div class="col-sm-auto" style="max-height: 70vh; overflow: scroll;">
                            <ul class="nav nav-pills nav-fill flex-column me-4" style="padding: 1rem 0;">
                                <ng-container *ngFor="let item of permissionAllowedModule; let index=index">
                                    <li class="nav-item mb-2 pointer">
                                        <a class="nav-link" [ngClass]="{'active' : index==currentIndex}" (click)="changeTabs($event,index)">{{item?.label ? item?.label : '---'}}</a>
                                    </li>
                                </ng-container>
                                <!-- <li class="nav-item mb-2">
                                    <a class="nav-link active pointer" aria-current="page" href="#">Homepage</a>
                                </li>
                                <li class="nav-item mb-2 pointer">
                                    <a class="nav-link">Resources</a>
                                </li>
                                <li class="nav-item mb-2 pointer">
                                    <a class="nav-link">Impact Story</a>
                                </li>
                                <li class="nav-item mb-2 pointer">
                                    <a class="nav-link">Glossary</a>
                                </li>
                                <li class="nav-item mb-2 pointer">
                                    <a class="nav-link">Reports</a>
                                </li>
                                <li class="nav-item mb-2 pointer">
                                    <a class="nav-link">Forms</a>
                                </li> -->
                            </ul>
                        </div>
    
                        <div class="col border-start col-border p-0">

                            <h6 class="fs-16 fw-600 m-0 border-bottom" style="color: #141414; padding: 1rem;">{{getInitialModulePermission.label}}</h6>
                            <div style="padding: 1rem;">
                                <ng-container *ngFor="let item of getInitialModulePermission.authentication">
                                    <div class="d-inline-block rounded-pill mb-2 me-2" *ngIf="item?.isAction">
                                        <div class="d-flex align-items-center justify-content-center px-3 rounded-pill fs-14 fw-500 topic-name">{{item?.label ?  item?.label : '---'}}</div>
                                    </div>
                                </ng-container>
                            </div>
                            <!-- <h6 class="fs-16 fw-600 m-0 border-bottom" style="color: #141414; padding: 1rem;">Homepage</h6>
                            <div style="padding: 1rem;">
                                <div class="d-inline-block rounded-pill mb-2 me-2">
                                    <div class="d-flex align-items-center justify-content-center px-3 rounded-pill fs-14 fw-500 topic-name">Edit Text</div>
                                </div>
    
                                <div class="d-inline-block rounded-pill mb-2 me-2">
                                    <div class="d-flex align-items-center justify-content-center px-3 rounded-pill fs-14 fw-500 topic-name">Upload Image</div>
                                </div>
    
                                <div class="d-inline-block rounded-pill mb-2 me-2">
                                    <div class="d-flex align-items-center justify-content-center px-3 rounded-pill fs-14 fw-500 topic-name">Download</div>
                                </div>
    
                                <div class="d-inline-block rounded-pill mb-2 me-2">
                                    <div class="d-flex align-items-center justify-content-center px-3 rounded-pill fs-14 fw-500 topic-name">Layout</div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section *ngIf="!userDetail">
        <div class="container-fluid">
            <div class="d-flex align-items-center">
                <svg class="me-2 pointer" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" (click)="viewUserDetail()">
                    <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                    <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
                  </svg>
                  
                <nav aria-label="breadcrumb" class="my-3">
                    <ol class="breadcrumb m-0">
                      <li class="breadcrumb-item fs-18 fw-400 pointer profile-tab">My Profile </li>
                      <!-- <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">Rajesh Kumar</li> -->
                      <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">{{userAllDetails?.name ? userAllDetails?.name : '---'}}</li>
                    </ol>
                  </nav>
            </div>

            <form [formGroup]="userFormDetails">
                <div class="card border-0  mb-3 profile-card-two">
                    <div class="card-body pt-sm-5">
                        <div class="row">
                            <div class="col-md-auto">
                                <div class="position-relative">
                                    <!-- <img src="../../../assets/images/userLarge-img.png" class="img-fluid mb-3 user-img" alt="">
                                    <img src="../../../assets/images/camera-icon.svg" class="img-fluid position-absolute cameraIcon" alt=""> -->
                                   
                                    <div class="row mb-3">
                                        <div class="">
                                            <img src="{{showImage ?  showImage : '../../../assets/images/userLarge-img.png'}}" class="img-fluid mb-3 border user-img rounded-circle"/>
                                            <img src="../../../assets/images/camera-icon.svg" class="img-fluid position-absolute cameraIcon pointer" alt="" (click)="imageInputClickHandler($event)">
                                            <!-- <button mat-stroked-button color="primary" (click)="imageInputClickHandler($event)"
                                                ><img src="../../../assets/images/camera-icon.svg" class="img-fluid position-absolute cameraIcon" alt="">
                                            </button> -->
                                        </div>
                                        
                                        
                                        <div class="col-sm-12">
                                            <input type="file" #fileicon id="form-icon-input" (change)="imageInputChangeHandler($event)"
                                                hidden placeholder="Choose File icon" accept=".png,.jpg,.jpeg" style="display: none">
                                        </div>
                
                                    </div>
                                </div>
                            </div>
    
                            <div class="col">
                                <div class="row">
                                    <div class="col-md-6 my-1">
                                        <!-- <div class="form-floating">
                                            <input type="text" class="form-control rounded-pill input-height" id="floatingInput" placeholder="User Name" formControlName="userName" [ngClass]="{'is-invalid':userFormDetails.get('userName')?.touched && userFormDetails.get('userName')?.invalid}">
                                            <label for="floatingInput">User Name * </label>
                                            <span class="invalid-feedback p-3" *ngIf="(userFormDetails.get('userName')?.touched  || userFormDetails.get('userName')?.dirty) && userFormDetails.get('userName')?.invalid">
                                                Name is Required *.
                                            </span>
                                        </div> -->
                                        <div class="position-relative">
                                            <mat-form-field appearance="outline" class="example-full-width w-100">
                                              <mat-label>User Name *</mat-label>
                                              <input type="text" placeholder="Username" matInput formControlName="userName" [ngModelOptions]="{standalone: true}" appAlphabatesOnly>
                                            </mat-form-field>
                                              <mat-error class="invalid-feedback"
                                                *ngIf="(userFormDetails.get('userName')?.touched  || userFormDetails.get('userName')?.dirty) && userFormDetails.get('userName')?.invalid">
                                                Username is Required *.
                                              </mat-error>
                                            
                                          </div>
                                    </div>
                                    <div class="col-md-6 my-1">
                                        <!-- <div class="form-floating">
                                            <input type="email" class="form-control rounded-pill input-height" id="floatingInput" placeholder="Email" formControlName="email" [ngClass]="{'is-invalid':userFormDetails.get('email')?.touched && userFormDetails.get('email')?.invalid}">
                                            <label for="floatingInput">Email * </label>
                                            <span class="invalid-feedback p-3" *ngIf="(userFormDetails.get('email')?.touched  || userFormDetails.get('email')?.dirty) && userFormDetails.get('email')?.invalid">
                                                Email is Required *.
                                             </span>
                                        </div> -->
                                        <div class="position-relative">
                                            <mat-form-field appearance="outline" class="example-full-width w-100">
                                              <mat-label>Email *</mat-label>
                                              <input type="email" placeholder="example@gmail.com" matInput formControlName="email" [ngModelOptions]="{standalone: true}">
                                            </mat-form-field>
                                              <!-- <mat-error class="invalid-feedback " *ngIf="(userFormDetails.get('email')?.touched  || userFormDetails.get('email')?.dirty) && userFormDetails.get('email')?.invalid">
                                                Email is Required *.
                                              </mat-error> -->
                                              <mat-error class="invalid-feedback" *ngIf="userFormDetails.get('email')?.touched && userFormDetails.get('email')?.hasError('required')">
                                                Email is Required *.
                                              </mat-error>
                                              <mat-error class="invalid-feedback" *ngIf="userFormDetails.get('email')?.dirty && userFormDetails.get('email')?.hasError('email')">
                                                Please enter a valid email address.
                                              </mat-error>
                                              
                                             
                                            
                                          </div>
                                    </div>
                                    <div class="col-md-6 my-1">
                                        <!-- <div class="form-floating">
                                            <input type="text" formControlName="userRole" class="form-control rounded-pill input-height" id="floatingInput" placeholder="User Role" [ngClass]="{'is-invalid':userFormDetails.get('userRole')?.touched && userFormDetails.get('userRole')?.invalid}" readonly="readonly" >
                                            <label for="floatingInput">User Role </label>
                                            <span class="invalid-feedback" *ngIf="(userFormDetails.get('userRole')?.touched  || userFormDetails.get('userRole')?.dirty) && userFormDetails.get('userRole')?.invalid">
                                                User Role is Required *.
                                             </span>
                                        </div> -->
                                        <div class="position-relative cursorHead">
                                            <mat-form-field appearance="outline" class="example-full-width w-100">
                                              <mat-label>User Role </mat-label>
                                              <input type="text" placeholder="User Role" matInput formControlName="userRole" [ngModelOptions]="{standalone: true}" readonly class="cursorHead">
                                            </mat-form-field>
                                              <mat-error class="invalid-feedback "
                                              *ngIf="(userFormDetails.get('userRole')?.touched  || userFormDetails.get('userRole')?.dirty) && userFormDetails.get('userRole')?.invalid">
                                              User Role is Required *.
                                              </mat-error>
                                            
                                          </div>
                                    </div>
                                    <div class="col-md-6 my-1">
                                        <!-- <div class="form-floating">
                                            <input type="text" formControlName="mobile" maxlength="10" class="form-control rounded-pill input-height" id="floatingInput" placeholder="Mobile Number" [ngClass]="{'is-invalid':userFormDetails.get('mobile')?.touched && userFormDetails.get('mobile')?.invalid}" (keypress)="numberOnly($event)">
                                            <label for="floatingInput">Mobile Number *</label>
                                            <span class="invalid-feedback p-3" *ngIf="(userFormDetails.get('mobile')?.touched  || userFormDetails.get('mobile')?.dirty) && userFormDetails.get('mobile')?.invalid">
                                                Phone Number is Required *.
                                             </span>
                                        </div> -->

                                        <div class="row ">
                                            <div class="col-md-4">
                                              <div class="position-relative">
                                                <mat-form-field appearance="outline" class="example-full-width w-100">
                                                  <mat-label>Select Country Code</mat-label>
                                                  <mat-select formControlName="country_code" >
                                                    <mat-option [value]="item?.code" *ngFor="let item of countryCodeArray">
                                                      <span class="w-50">+{{item?.code}}</span>
                                                      <span class="w-50">&nbsp; &nbsp; &nbsp; &nbsp; | &nbsp; {{item?.name}}</span>
                                                    </mat-option>
                                                  </mat-select>
                                                </mat-form-field>
                                                  <!-- <mat-error class="invalid-feedback mx-3 mb-0"
                                                *ngIf="(userFormDetails.get('country_code')?.touched  || userFormDetails.get('country_code')?.dirty) && userFormDetails.get('country_code')?.invalid">
                                                Country Code is Required *.
                                              </mat-error> -->
                                                
                                              </div>
                                  
                                            </div>
                                            <div class="col-md-8">
                                              <div class="position-relative">
                                                <mat-form-field appearance="outline" class="example-full-width w-100">
                                                  <mat-label>Mobile Number</mat-label>
                                                  <input type="number" placeholder="Mobile Number" matInput formControlName="mobile" [ngModelOptions]="{standalone: true}"
                                                  onKeyPress="if(this.value.length == 12 && event.keyCode!=8) return false;" appSpecialCharactersValidation>
                                                </mat-form-field>
                                                  <!-- <mat-error class="invalid-feedback" *ngIf="((userFormDetails.get('mobile')?.touched  || userFormDetails.get('mobile')?.dirty) && userFormDetails.get('mobile')?.invalid) && !((userFormDetails.get('mobile') && userFormDetails.get('mobile')?.hasError('invalidMobileNumber')) && !(userFormDetails.get('mobile')?.hasError('pattern')))">
                                                    Mobile Number is Required *.
                                                  </mat-error> -->
                                                
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 my-1">
                                        <!-- <div class="form-floating">
                                            <input type="text" class="form-control rounded-pill input-height" id="floatingInput" placeholder="Country" formControlName="countryName" [ngClass]="{'is-invalid':userFormDetails.get('countryName')?.touched && userFormDetails.get('countryName')?.invalid}" readonly="readonly">
                                            <label for="floatingInput">Country</label>
                                            <span class="invalid-feedback" *ngIf="(userFormDetails.get('countryName')?.touched  || userFormDetails.get('countryName')?.dirty) && userFormDetails.get('countryName')?.invalid">
                                                Country is Required *.
                                             </span>
                                        </div> -->
                                        <div class="position-relative cursorHead">
                                            <mat-form-field appearance="outline" class="example-full-width w-100">
                                              <mat-label>Country </mat-label>
                                              <input type="text" placeholder="Country" matInput formControlName="countryName" [ngModelOptions]="{standalone: true}" readonly class="cursorHead">
                                            </mat-form-field>
                                              <mat-error class="invalid-feedback"
                                                *ngIf="(userFormDetails.get('countryName')?.touched  || userFormDetails.get('countryName')?.dirty) && userFormDetails.get('countryName')?.invalid">
                                                Country is Required *.
                                              </mat-error>
                                            
                                          <!-- </div>
                                    </div>

                                    <div class="col-md-6 my-1">
                                        <div class="position-relative">
                                            <mat-form-field appearance="outline" class="example-full-width w-100">
                                              <mat-label>Password *</mat-label>
                                              <input type="password" placeholder="Enter Password" matInput formControlName="password" [ngModelOptions]="{standalone: true}" >
                                            </mat-form-field>
                                              <mat-error class="invalid-feedback"
                                                *ngIf="(userFormDetails.get('password')?.touched  || userFormDetails.get('password')?.dirty) && userFormDetails.get('password')?.invalid">
                                                Password is Required *.
                                            </mat-error> -->
                                          </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="text-end mt-3">
                                <button class="btn update-btn rounded-pill text-white mb-4" (click)="updateUser()" [disabled]="userFormDetails.invalid">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </section>
</div>