import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartData, ChartOptions, TooltipItem } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @ViewChild('pieChartCanvas') pieChartCanvas!: ElementRef<HTMLCanvasElement>;
  private pieChartContext!: CanvasRenderingContext2D;
  private pieChart: Chart | unknown;
  private chart: Chart | any;
  @Input() data : any[] = [];
  @Input() label : any[] = [];
  @Input() title : string = '';
  showNoData: boolean = false;

  @Input() chartConfig: {
    tooltip?: "both" | "percentage" | "value";
    dataLabel?: "both" | "percentage" | "value";
  } = {
    tooltip: "both",
    dataLabel: "both"
  };

  customData: any = [];
  customLabel: any = [];
  isPercentage:boolean = false;
  constructor() { }

  ngOnInit(): void {
    console.log(this.data,"pieeee")
    if(this.data){
      this.getDataForChart(this.data);
      this.getLabelForChart(this.data)
    }
    if (this.chart) {
      this.chart.destroy();
    }
    setTimeout(() => {
      this.createPieChart();
    },100)
    
  }

  private createPieChart(): void {
    console.log("this.customData",this.customData,this.customLabel)
    Chart.register(ChartDataLabels);
    Chart.defaults.set('plugins.datalabels', {
      color: '#fff'
    });
  const numericalData = this.convertIntoPercentage(this.customData);
    console.log()
    this.pieChart = new Chart(this.pieChartContext, {
      type: 'pie',
      data: {
        labels: this.customLabel,
        datasets: [
          {
            data: this.customData,
            backgroundColor: [ "#00855A", "#FED766", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf", "#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5", "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5", "#fdd0a2", "#b3e2cd", "#fccde5", "#d9d9d9", "#e5d8bd", ],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            color: 'white',
            font: {
              weight: 'bold',
              size: 16
            },
            formatter: (value: any, context: any) => {
              if ( this.chartConfig?.dataLabel &&
                ["both", "percentage", "value"].includes(
                  this.chartConfig?.dataLabel || ""
                )
              ) {
                const meta = context.dataset.data._chartjs.listeners[0].getMeta();

                const { data } = meta;
                const index = meta._dataset.data.findIndex(
                  (el: any) => el == value
                );
                let model = data[index],
                  total = Number(meta.total),
                  start_angle = model.startAngle,
                  end_angle = model.endAngle;
                //calculating Area
                let angle = end_angle - start_angle;
                let donutSectorArea =
                  (angle / 2) *
                  (model.outerRadius - model.innerRadius) *
                  (model.outerRadius + model.innerRadius);

                var percent = (value / total) * 100;
                if (
                  value != 0 &&
                  ((donutSectorArea && donutSectorArea > 1000) ||
                    (!donutSectorArea && percent > 1.5))
                ) {
                  let formatedValue = this.formatNumberWithCommas(value);
                  return (this.chartConfig?.dataLabel == 'both' && !this.isPercentage) ? `${formatedValue} (${percent.toFixed(2)}%)` : (this.chartConfig?.dataLabel == 'percentage' && !this.isPercentage) ? `${percent.toFixed(2)}%` : (this.chartConfig?.dataLabel == 'value') ? formatedValue : this.isPercentage ? `${value}%` : "";
                } else {
                  return "";
                }
              }
            }
         },
          legend:{
            position: 'bottom',
            labels:{
            display:true,
            usePointStyle:true,
            pointStyle: 'circle',
            padding:15,
            boxWidth: 5,
            font: {
              size: 11
            }
            }
          },
          labels:{
            render: 'label'
          },
          render:'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2,
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<'pie'>) => {
                if ( !this.chartConfig?.tooltip || !["both", "percentage", "value"].includes( this.chartConfig?.tooltip || "" )){
                  return "";
                }
                if (tooltipItem.dataset && tooltipItem.parsed) {
                  const dataset = tooltipItem.dataset;
                  const total = dataset.data.reduce(
                    (acc: number, val: number) => Number(acc) + Number(val),
                    0
                  );

                  const currentValue = dataset.data[tooltipItem.dataIndex || 0];
                  const percentage = ((currentValue / total) * 100).toFixed(2);
                  let val = (this.chartConfig?.tooltip == 'both' && !this.isPercentage) ? `${currentValue} (${percentage}%)` : (this.chartConfig?.tooltip == 'percentage' && !this.isPercentage) ? `${percentage}%` : (this.chartConfig?.tooltip == 'value') ? currentValue : this.isPercentage ? `${currentValue}%` : "";
                  return `${tooltipItem.label} : ${val} `;
                }
                return "";
              },
            },
          },
          afterLabel: (context: any) => {
            const dataset = context.dataset;
            const total = dataset.data.reduce((acc: number, value: number) => acc + value, 0);
            return `Total: ${total.toFixed(2)}%`;
          },
        } as any
      },
    });
  }

  formatNumberWithCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

 
  ngAfterViewInit(): void {
    if(this.pieChartCanvas?.nativeElement)
    this.pieChartContext = this.pieChartCanvas.nativeElement.getContext('2d')!;
    this.createPieChart();
    // this.createChart()
  }
  public chartClicked(e:any):void {
    console.log(e);
  }

  public chartHovered(e:any):void {
    console.log(e);
  }
  getLabelForChart(data:any){
     data?.forEach((el:any)=>{
      if(el?.question_label){
        let question = el?.question_label;
        // .toLowerCase().replace(/(^|\s)\S/g, (L:any) => L.toUpperCase())
        this.customLabel.push(question)
      }
    })
    this.showNoData = this.customData.every((element:any) => element === '0' || element === '0.00');
  }
  getDataForChart(data:any){
      data?.forEach((el:any)=>{
      if(el?.total_count) {
        el.total_count = el?.value_format === "percentage" ? parseFloat(el?.total_count).toFixed(2) : el?.total_count;
        this.customData.push(el?.total_count)
      }
    })
    this.isPercentage = data.some((el:any)=>  el?.value_format === "percentage");
    this.showNoData = this.customData.every((element:any) => element === '0' || element === '0.00');
  }
 
convertIntoPercentage(data: any) {
  let sum = data.reduce((a: any, b: any) => a + b);
  let percentages = data.map((e: any) => ((e / sum) * 100).toFixed(2) );
  return percentages;
}




}
