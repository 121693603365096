import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-time-chart',
  templateUrl: './time-chart.component.html',
  styleUrls: ['./time-chart.component.scss']
})
export class TimeChartComponent implements OnInit {
  @ViewChild('timeChartCanvas') timeChartCanvas!: ElementRef<HTMLCanvasElement>;
  @Input() data:any
  customLabel: any =[];
  customData: any= [];
  customShowLabel: any=[];
  constructor() { }

  ngOnInit(): void {
    console.log("timebarData",this.data);
    this.dataSet(this.data);
    // this.dataLabel(this.data);
  }

  barMethod(){
    Chart.register(ChartDataLabels);
    Chart.defaults.set('plugins.datalabels', {
      color: '#fff'
    });
    var ctx = this.timeChartCanvas.nativeElement.getContext('2d')!;
    

// var data = {
//     labels: ["2021-2022", "2021-2023", "2021-2024"],
//     datasets: [
//         {
//             label: "Male",
//             backgroundColor: "blue",
//             data: [3,7,4]
//         },
//         {
//             label: "Female",
//             backgroundColor: "red",
//             data: [4,3,5]
//         },
//         {
//             label: "Others",
//             backgroundColor: "green",
//             data: [7,2,6]
//         }
//     ]

const filteredDatasets = this.filterOutDataSet(this.customData, this.customLabel);
console.log("filteredDatasets",filteredDatasets)
var data = {
  labels:this.customLabel,
  datasets:filteredDatasets
}

 new Chart(ctx, {
    type: 'bar',
    data: data,
    options: {
        barValueSpacing: 20,
        scales: {
          xAxes: [{
            barPercentage: 0.4,
            categoryPercentage: 0.4,
            maxBarThickness: 100,
          }],
            yAxes: [{
                ticks: {
                    min: 5,
                },
                barThickness: 20
            }]
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels:{
              usePointStyle:true,
              pointStyle: 'circle',
              padding:20
              }
          },
        },
    }
});
  }

  ngAfterViewInit(){
    this.barMethod()
   }
  

  dataSet(data: any) {
    let obj: any = {};
    data.forEach((e: any,index:number) => {
      e?.values?.forEach((el: any) => {
        if (!obj.hasOwnProperty(el.financial_year)) {
          obj[el.financial_year] = {
            key:el?.financial_year,
            label:this.firstLetterCaptial(e.name),
            backgroundColor: this.getBackgroundColor(el.financial_year),
            data: [],
          };
        }
        this.customLabel.push(el.financial_year)
        this.customShowLabel.push(e.name)
  
        obj[el.financial_year].data.push(el.total_count);
      });
    });
    this.customLabel = this.customLabel.filter((value:any, index:number, self:any) => self.indexOf(value) === index);
    this.customShowLabel = this.customShowLabel.filter((value:any, index:number, self:any) => self.indexOf(value) === index);


    this.customData = {
      labels: this.customLabel,
      datasets: Object.values(obj), 
    };
  
    console.log("customData", this.customShowLabel);
    console.log("customData", this.customData);

  }
  
  filterOutDataSet(data: any, labelsToKeep: string[]) {
    return data?.datasets.filter((el: any) => {
      return labelsToKeep.includes(el?.key);
    });
  }

  getBackgroundColor(financialYear: string): string {
    // You can add your logic here to determine the background color based on the financial year
    if (financialYear === "2023-2024") {
      return "#3DB22E";
    } else if (financialYear === "2021-2022") {
      return "#5BE249";
    } else if (financialYear === "2020-2021") {
      return "#FEED76";
    }
    else if (financialYear === "2020-2021") {
      return "#008000";
    }
    else if (financialYear === "2022-2023") {
      return "#e9ffdb";
    }
    return "gray";
  }
  firstLetterCaptial(value:string):string{
    if (value) {
      let sentence:any = value.split('_');
      return sentence.map((word:string) => word.charAt(0).toUpperCase()+word.slice(1)).join(' ')
    }
    return '';
  }

}
