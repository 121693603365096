import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {

  transform(value: string): any {
    if (value) {
      let sentence:any = value.split('_');
      return sentence.map((word:string) => word.charAt(0).toUpperCase()+word.slice(1)).join(' ')
    }
  }

}
