import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementServiceService } from 'src/app/admin/user-management/user-management-service.service';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss']
})
export class FormListComponentIPSA implements OnInit {
  formId: any;
  apiUrl: string = '';
  noDataFound: boolean = false;
  jwtToken: any;
  formDataListing: any = [];
  sectionCardId: any;
  selectedTab: any;
  resourceHead: any;
  showFormName: any;
  userData:any = JSON.parse(sessionStorage.getItem("userdata")|| '{}')
  countryDetails: any;
  factoryDetails: any;
  ipNameDetails: any;

  constructor(private commonService: CommonServiceService, public router: Router, private route: ActivatedRoute) {
    this.getMasterData()
    let pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
    let currentBaseProgramForm = this.getCurrentProgramFormMethod(pageAction[0])
    
    this.allowedAction = pageAction[0].authenicationJson.filter((el:any)=>{
      if(el?.model === currentBaseProgramForm.model) return el;
    });
    console.log("baseForm",this.allowedAction, currentBaseProgramForm)
    this.route.queryParams.subscribe(params => {
      this.urlData = params;
      sessionStorage.setItem("ipsaListingPrams", JSON.stringify(this.urlData));
    }) 
  }
  urlParams: {formId:number} = {
    formId : 1
  };
  formListingData: any = [
    {
        "id": 84,
        "month": "May",
        "financial_year": "2021-2022",
        "country_id": 5,
        "industrialpark_id": null,
        "factory_id": null,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 283,
        "createdAt": "2024-01-10T12:00:13.740Z",
        "updatedAt": "2024-01-10T12:00:13.740Z",
        "name": "Dhwani12",
        "country_name": "Ethiopia"
    },
    {
        "id": 83,
        "month": "February",
        "financial_year": "2020-2021",
        "country_id": 6,
        "industrialpark_id": 5,
        "factory_id": null,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 283,
        "createdAt": "2024-01-10T10:02:03.943Z",
        "updatedAt": "2024-01-10T10:02:03.943Z",
        "name": "Dhwani12",
        "country_name": "Ethiopia"
    },
    {
        "id": 82,
        "month": "April",
        "financial_year": "2021-2022",
        "country_id": 4,
        "industrialpark_id": null,
        "factory_id": null,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 283,
        "createdAt": "2024-01-10T09:44:19.081Z",
        "updatedAt": "2024-01-10T09:44:19.081Z",
        "name": "Dhwani12",
        "country_name": "Ethiopia"
    },
    {
        "id": 81,
        "month": "June",
        "financial_year": "2020-2021",
        "country_id": 4,
        "industrialpark_id": 1,
        "factory_id": null,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 283,
        "createdAt": "2024-01-10T09:19:37.856Z",
        "updatedAt": "2024-01-10T09:19:37.856Z",
        "name": "Dhwani12",
        "country_name": "Ethiopia"
    },
    {
        "id": 80,
        "month": "April",
        "financial_year": "2020-2021",
        "country_id": 5,
        "industrialpark_id": 4,
        "factory_id": null,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": false,
        "isActive": true,
        "createdBy": 283,
        "createdAt": "2024-01-10T09:18:46.214Z",
        "updatedAt": "2024-01-10T09:18:46.214Z",
        "name": "Dhwani12",
        "country_name": "Ethiopia"
    },
    {
        "id": 79,
        "month": "April",
        "financial_year": "2020-2021",
        "country_id": null,
        "industrialpark_id": null,
        "factory_id": null,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 283,
        "createdAt": "2024-01-10T09:15:49.876Z",
        "updatedAt": "2024-01-10T09:15:49.876Z",
        "name": "Dhwani12",
        "country_name": "Ethiopia"
    },
    {
        "id": 77,
        "month": "March",
        "financial_year": "2020-2021",
        "country_id": 5,
        "industrialpark_id": 3,
        "factory_id": 8,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 1,
        "createdAt": "2024-01-10T06:50:25.087Z",
        "updatedAt": "2024-01-10T09:06:25.970Z",
        "name": "ADMIN"
    },
    {
        "id": 78,
        "month": "February",
        "financial_year": "2020-2021",
        "country_id": 4,
        "industrialpark_id": 1,
        "factory_id": 2,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": false,
        "isActive": true,
        "createdBy": 1,
        "createdAt": "2024-01-10T06:53:26.457Z",
        "updatedAt": "2024-01-10T06:53:26.457Z",
        "name": "ADMIN"
    },
    {
        "id": 76,
        "month": "February",
        "financial_year": "2020-2021",
        "country_id": 4,
        "industrialpark_id": 1,
        "factory_id": 1,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 1,
        "createdAt": "2024-01-10T06:43:30.710Z",
        "updatedAt": "2024-01-10T06:43:30.710Z",
        "name": "ADMIN"
    },
    {
        "id": 75,
        "month": "February",
        "financial_year": "2020-2021",
        "country_id": 4,
        "industrialpark_id": 1,
        "factory_id": 2,
        "countries_with_ip": 1,
        "industrial_parks_in_projects": 1,
        "ta_factories_in_ips": 1,
        "male_workers_in_instep": 1,
        "female_workers_in_instep": 1,
        "implementing_partners_with_idh": 1,
        "multistakeholder_agreements": 1,
        "investments_raised": null,
        "is_draft": true,
        "isActive": true,
        "createdBy": 1,
        "createdAt": "2024-01-10T06:28:28.190Z",
        "updatedAt": "2024-01-10T06:28:28.190Z",
        "name": "ADMIN"
    }
];
  totalDataCount: number = 0;
  public limit = 10;
  public skip = 0;
  public page = 1;
  countryData: any = [];
  viewForm : boolean = true;
  formName:string = '';
  formList = ['','Social Performance', 'Environmental Performance', 'Building Safety Performance', 'IPSA Pre-assessment', 'List of Evidence'];
  formApis = ['','programform/list?page=', 'baselineform/list?page=','baselineform/list?page='];
  routeUrl = ['','/program-form', '/baseline-form','/baseline-monthly-form'];
  allowedAction :any = [];
  baseFormModule : string[] = ['ipsa'];
  downloadResponse:any = [];
  formListData:any = [];
  urlData:any;
  selectedTabIndex:any = 0;

  ngOnInit(): void {
       console.log("formlist")
    // this.getCountryList();
   
    this.jwtToken = sessionStorage.getItem('jwtToken')!;
    ;

  }
 
  onTabChanged(event:any){
    this.formDataListing = [];
    console.log(event, this.formListData);
    this.selectedTab = event.tab.textLabel;
    
    this.getDataFormList();
  }
  resourceCenter:any = [
    {
      label : 'S.No.'
    },
    // {
    //   label : 'Submitted By'
    // },
    {
      label : 'Date Of Submission'
    },
    {
      label : 'Action'
    },

  ];

  

//  getCountryList(){
//     this.commonService.countryData().subscribe((res:any) => {
//       if(res.success){
//         this.countryData = res?.data;
//       }
//     });
//     setTimeout(() => {
//       this.route.queryParams.subscribe(params => {
//         console.log("formId", params)
//         if(!params)
//         return;
//         if((['1','2','3'].includes(params.formId))){
//           this.formName = this.formList[params.formId];
//           this.apiUrl = this.formApis[params.formId];
//           this.formId = params.formId;
//           this.programFormListing(1, this.countryData);
//         }
//       })      
//     }, 1000);
//   }

  // programFormListing(page:any, countryData:any[]){
  //   let userData:any = JSON.parse(sessionStorage.getItem("userdata")|| '{}');
  //   this.commonService.getProgramFormListing(this.apiUrl,page, userData.role_id, userData.id, this.formId).subscribe((res:any) => {
  //     if(res?.data?.length){
  //       this.formListingData = res?.data;
  //       if(!this.formListingData.length){
  //         this.noDataFound = true;
  //       }
  //       console.log("datataa", this.formListingData);
  //       this.totalDataCount = res.total;
  //       for (let i = 0; i <= this.formListingData.length; i++) {
  //         let obj = countryData.find((ele: any) => ele.name == this.formListingData[i].country_name);
  //         this.formListingData[i]['country_icons'] = obj.country_icons;
  //     }
  //     }
      
  //   }, error => {
  //     this.noDataFound = true;
  //   })
  //   console.log("datataa",this.formListingData);
  // }

  getFormsList(){
    let payload = {
        "endpoint": "/form?limit=50",
        "headers": {
            "Content-Type": "application/json",
            "x-jwt-token": this.jwtToken
        },
        "method": "GET"
    };
    this.commonService.getAllFormList(payload).subscribe(res => {
      if(res){
        if(res.length && res[0].hasOwnProperty('x-jwt-token'))
        sessionStorage.setItem('jwtToken', res[0]['x-jwt-token']);

        console.log(res, this.urlData?.formName);
        this.showFormName = this.urlData?.formName.replace('Performance','Assessment');
        this.formListData = res.filter((ele:any) => ele.display == this.urlData?.formName)
        console.log("finalForm",this.formListData);
        this.selectedTab = this.formListData[0].title;
        this.getDataFormList();
      }
    })
  }

  getDataFormList(){

    let data = this.formListData.find((ele:any) => ele.title == this.selectedTab);
    // let singleFormData = this.formDataListing.filter((details:any) => details.form == data._id)
    
    let currentHeading = this.extractInputFields(data);
   
    this.resourceCenter = currentHeading.filter((head:any, index:number) => this.selectedTab.toLowerCase().indexOf('factor') > -1 ? index <4 : index <3);
    this.resourceCenter.forEach((element:any) => {
      element.label = element.label.replace('Select','');
    });
    this.resourceHead = this.resourceCenter.map((head:any) => head.key+'table');
    console.log("currentHeading",this.resourceCenter,currentHeading);
    let formpath = this.formListData.find((ele:any) => ele.title == this.selectedTab)
    let endpoint = `/${formpath.path}/submission`
    let payload = {
      "endpoint": endpoint,
      "headers": {
          "Content-Type": "application/json",
          "X-jwt-token": this.jwtToken
      },
      "requestBody": {},
      "method": "GET"
  };

    this.commonService.prefillFormIO(payload).subscribe(res => {
      if(res && res?.length){
        if(res.length && res[0].hasOwnProperty('x-jwt-token'))
        sessionStorage.setItem('jwtToken', res[0]['x-jwt-token']);
        // for(let key in res[0].data){
          res.forEach((row:any) => {
          this.resourceHead.forEach((key:any) => {
            let keyValue = key.split('table')[0]
            let refId = row.data[keyValue];
            let value = row.data[keyValue];
            if(keyValue == 'country' && refId){
              let countryValue = this.countryData.find((country:any) => country.id.toString() == refId.toString());
              value = countryValue && countryValue.hasOwnProperty('id') ? countryValue.name : refId;
            }
            if(keyValue == 'industrialpark' && refId){
              let ipValue = this.ipNameDetails.find((ip:any) => ip.id.toString() == refId.toString());
              value = ipValue && ipValue.hasOwnProperty('id') ? ipValue.name : refId;
            }
           if(keyValue == 'factory' && refId){
              let factoryValue = this.factoryDetails.find((factory:any) => factory.id.toString() == refId.toString());
              value = factoryValue && factoryValue.hasOwnProperty('id') ? factoryValue.name : refId;
            }
            row.data[key] = value;
          });
        });
        this.formDataListing = res;
        
      }
    })
  }

  backToForm(){
    this.router.navigate(['/ipsa-forms'])
  }
  backToIpsa(){
    this.router.navigate(['/ipsa'])
  }
  setPage(event: any) {
    this.skip = (event.pageIndex + 1) * this.limit - this.limit;
    console.log('limit',event.pageSize,event)
    this.limit = event.pageSize;
    // this.programFormListing(event.pageIndex + 1, this.countryData);
  }

  openForm(){
    // this.router.navigate(['ipsa-forms-design']);
    console.log("myparamsss",this.urlData);
    
    this.router.navigate(['form-builder'],{queryParams:{
      formName: this.urlData?.formName
    }});
  }

  editForm(editMode:string = 'editMode', data:any){
    let id = data?.form;
    this.commonService.updatePrefillIpsa(data);
    this.router.navigate(['ipsa-forms-design'],{queryParams:{
      formName: this.urlData?.formName,
      formId: id,
      editMode: editMode,
      isDraft: data?.isDraft
    }})

  }
  getCurrentProgramFormMethod(data: any) {
    let getCurrentTab: any = null; 
  
    this.baseFormModule.some((el: any) => {
      getCurrentTab = data.authenicationJson.find((e: any) => {
        return e.model === el &&
          (e.isAction || e.is_checked || (e.authentication && e.authentication.some((inside: any) => inside.isAction)));
      });
  
      return getCurrentTab !== undefined; 
    });
  
    return getCurrentTab;
  }

   downloadCSV() {
    let currentBaseUrl:string = ''
    let format:string = 'csv'
    if(this.formName == 'Baseline Form'){
      currentBaseUrl = 'baselineform'
    }
    else if(this.formName == 'Monthly Progress Form'){
      currentBaseUrl = 'baselineform'
      format = 'csv?is_month=true'
    }
    else{
      currentBaseUrl = 'programform'
    }
    console.log("currentBaseUrl",currentBaseUrl)
      this.commonService.downloadDashboardCSV(currentBaseUrl,format).subscribe((res: any) => {
        this.downloadResponse = res;
        console.log("dataaa", this.downloadResponse);
        this.commonService.downloadCSVFile(this.downloadResponse, this.formName)
  
      }, (error => {
        console.log("eerorDashboard", error)
      }))
    }

  viewFormStructure(selectedCardID:any){
    this.sectionCardId = selectedCardID;
    console.log(this.formListData);
    let selectedCard = this.formListData.find((ele:any) => ele._id == selectedCardID);
    this.commonService.formComponentData.next(selectedCard.components);
    console.log("selectedCard",selectedCard);
    
    this.router.navigate(['ipsa-forms-design'],{queryParams:{
      formName: this.urlData?.formName,
      formId: selectedCardID
    }});
  }

  editFormStructure(selectedCardID:string){
    this.router.navigate(['form-builder'],{queryParams:{
      formName: this.urlData?.formName,
      formId: selectedCardID
    }});
  }

  // fetchLabelKeyWithData(formData:any) {
  //   const result:any = [];
  //   let count = 0;
    
  //   // Iterate through tabs
  //   formData.components.forEach((tab:any) => {
  //       // Iterate through sections
  //       tab.components.forEach((section:any) => {
  //           // Iterate through questions
  //           section.components.forEach((question:any) => {
  //               // Check if the question has a "data" key
  //               if (question.data) {
  //                   // Push label and key to result array
  //                   result.push({ label: question.label, key: question.key });
  //                   count++;
  //                   // Break the loop after the first four objects are added
  //                   if (count === 4) {
  //                       return result;
  //                   }
  //               }
  //           });
  //       });
  //   });
    
  //   return result;
  // }

  extractInputFields(jsonObj:any) {
    let inputFields:any = [];

    // Recursive function to traverse the JSON object
    function traverse(obj:any) {
        // Check if obj is an array
        if (Array.isArray(obj)) {
            obj.forEach(item => traverse(item));
        } else if (typeof obj === 'object') {
            // Check if the current object has an "input" key set to true
            if (obj.input === true && obj.key.indexOf('uploadAvailableDocuments') < 0 ) {
              let label = obj.label.replace(/[0-9.]/g, '')
                inputFields.push({ label: label, key: obj.key });
            }
            // Traverse each property of the object
            Object.keys(obj).forEach(key => traverse(obj[key]));
        }
    }

    traverse(jsonObj);
    return inputFields;
}

isTabAllowed(item:any):any{
  console.log("isAllowed", item.title,this.userData);
  let isAllowed:boolean = false
  if(this.userData.userlevel_id == 1){
    isAllowed = true
  }else{
    if(item.title.toLowerCase().indexOf('industr') > -1 ){
      isAllowed = true;
    }if(item.title.toLowerCase().indexOf('factor') > -1 ){
      isAllowed = true;
    }
    
  }
  return isAllowed;
}

getMasterData(){
this.commonService.fetchDataSequentially()
.then(response => {
  this.countryData = response?.countryList;
  this.ipNameDetails= response?.ipLIst;
  this.factoryDetails = response?.factoryList;
   this.getFormsList();
  console.log('Final Response:', this.ipNameDetails,this.factoryDetails, response);
})
.catch(error => {
  console.error('Error fetching data:', error);
});
}


}


