import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'app-communication-sidebar',
  templateUrl: './communication-sidebar.component.html',
  styleUrls: ['./communication-sidebar.component.scss']
})
export class CommunicationSidebarComponent implements OnInit, OnChanges {
  messengerList: any = [];
  messageLineItemDetails:any = [];
  @Output() viewData : EventEmitter<any> = new EventEmitter();
  @Output() setApplyFiltr : EventEmitter<any> = new EventEmitter();
  @Output() tabSwitched : EventEmitter<any> = new EventEmitter();
  @Input() msgHighlighted:boolean = false;
  @Input() msgDeleted:boolean = false;
  @Input() msgUpdated:boolean = false;
  @Input() selectedMatIndex:number = 0;
  userData:any = JSON.parse(sessionStorage.getItem("userdata")|| '{}');
  fromJson:any = [
    // {
    //   "name" : "Buyer",
    //   "id" : 1,
    //   "isChecked" : false
    // },
    // {
    //   "name" : "Government",
    //   "id" : 2,
    //   "isChecked" : false
    // },
    // {
    //   "name" : "Public User",
    //   "id" : 3,
    //   "isChecked" : false
    // },
    { id: '1', name: 'Buyer', isChecked: false },
    { id: '2', name: 'Goverment', isChecked: false },
    { id: '3', name: 'Public user', isChecked: false },
    { id: '4', name: 'IndustrialPark', isChecked: false },
    { id: '5', name: 'Country', isChecked: false },
    { id: '6', name: 'Admin', isChecked: false },
    { id: '7', name: 'Factory', isChecked: false }
  ];

  toJson:any = [
    {
      "name" : "IP Staff",
      "id" : "ip",
      "isChecked" : false
    },
    // {
    //   "name" : "Country Manager",
    //   "id" : "countryManager",
    // },
    {
      "name" : "Factory Staff",
      "id" : "factory",
      "isChecked" : false
    },
  ];

  statusJson:any = [
    {
      "name" : "Replied",
      "id" : 1,
      "isChecked" : false
    },
    {
      "name" : "Pending",
      "id" : 2,
      "isChecked" : false
    },
    {
      "name" : "Reminder",
      "id" : 3,
      "isChecked" : false
    }
  ]
  filterForm: FormGroup;
  selectedFromID: any;
  selectedFactory: string = "";
  filterParam: any;
  selectedIP: string = "";
  approveStatus: string = "";
  rejectStatus: string = "";
  pendingStatus: string = "";
  showInfo: boolean = false;
  totalCount: number  = 1;
  allListData: any[] = [];
  appliedFilter: boolean = false;
  lineItemID: number = 0;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger | undefined;
  searchedValue: string = "";
  starMessage: boolean = false;

  
  // isClassAdded = false;

  constructor(private commonService: CommonServiceService, private formbuilder: FormBuilder,public router: Router,private route: ActivatedRoute) {
    this.filterForm = this.formbuilder.group({

    })
   

   }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes",changes);
    this.buttonType = this.buttonType ? this.buttonType : 'receive';
    if(changes && changes.msgHighlighted && changes.msgHighlighted.currentValue){
        this.allListData = [];
        this.starMessage = changes.msgHighlighted.currentValue;
        this.getCommunicationList('','','',1,this.buttonType,true);
    }
    if(changes && changes.msgDeleted && changes.msgDeleted.currentValue){
        this.allListData = [];
        this.getCommunicationList();
    }

    if(changes && changes.msgUpdated && changes.msgUpdated.currentValue){
      this.getCommunicationList(this.lineItemID);
    }

    if(changes && changes.selectedMatIndex && changes.selectedMatIndex.currentValue){
      this.fetchStatus("receive");
    }
  }

  

  ngOnInit(): void {
    this.commonService.notificationClicked.subscribe((value:any) => {
      if(value){
        this.showData(value);
      }
      
    });
    this.commonService.isMarkAllRead.subscribe((value:any) => {
      console.log("isMarkAllRead",value)
      
      if(value){
        console.log("isMarkAllRead",value)
        let test = "isMArkaAllRead" + value
        this.getCommunicationList('','','',1,'receive',true);
      }
      
    });

    let id:any = this.route.snapshot.queryParamMap.get('id');
        id = id ? parseInt(id) : this.lineItemID;
        console.log('event.url:', id);
        this.showData(id);
  }

  showData(id:any){
    if(id){
      this.lineItemID = parseInt(id);
      this.getCommunicationList()
      setTimeout(()=>{
        this.getCommunicationList(this.lineItemID);
      },1000)
      // this.setApplyFiltr.emit({id:id});
    }
    else{
      this.getCommunicationList("","","",1,"receive")
    }
  }
 totalScrollData:number = 0;
  page:number = 1;
  count:number = 0;

  scrollHandler(event:any){
    // this.dataVisible = false;
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      
      console.log("End", this.count++, this.totalCount, this.totalScrollData);
      if(this.totalCount != this.totalScrollData){
        this.page = this.page + 1
        this.getCommunicationList("", "", "", this.page);
      }
    }
  }

  showMoreChats(){
    this.page = this.page + 1
    this.getCommunicationList("", "", "", this.page);
  }
  
  // onScroll(): void {
  //   if (this.bottomReached()) {
  //     this.page = this.page + 1
  //     if(this.totalCount != this.totalScrollData)
  //     this.getCommunicationList("", "", this.page);

  //     // this.messengerList = [...this.messengerList, this.count++];
  //   }
  // }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }

  onValueChange(event:any, data:any, type:any){
    console.log("allData", data);
    if(type == 'from'){
      // this.fromJson.map((ele:any) => {
      //   ele.id === data.id ? ele.isChecked = true : ele.isChecked = false;
      // })
      this.selectedFromID = 'from=' + data.id;
      console.log("selectedFromID", this.selectedFromID);
      // this.filterParam = 'from='+this.selectedFromID;
    }else if(type == 'to'){
      console.log("allData", data);

      if(data.id == 'ip' && data.isChecked){
        this.selectedIP = 'receiver_type=' + data.id;
      }else{
        if(data.id == 'ip' && !data.isChecked){
          this.selectedIP = "";
        }
      }

      if(data.id == 'factory' && data.isChecked){
        this.selectedFactory = 'receiver_type=' + data.id;
      }else{
        if(data.id == 'factory' && !data.isChecked){
          this.selectedFactory = "";
        }
      }
    }else{
      console.log(data);
      if(data.id == 1 && data.isChecked){
        this.approveStatus = 'status=' + data.id;
      }else{
        if(data.id == 1 && !data.isChecked){
          this.approveStatus = "";
        }
      }
      if(data.id == 2 && data.isChecked){
        this.pendingStatus = 'status=' + data.id;
      }else{
        if(data.id == 2 && !data.isChecked){
          this.pendingStatus = "";
        }
      }

      if(data.id == 3 && data.isChecked){
        this.rejectStatus = 'status=' + data.id;
      }else{
        if(data.id == 3 && !data.isChecked){
          this.rejectStatus = "";
        }
      }
    }
  }


  applyFilter(){
    let filterParams = "";
    console.log(this.selectedFromID , this.selectedIP, this.selectedFactory, this.approveStatus, this.rejectStatus);
    if(this.selectedFromID && this.selectedIP && this.selectedFactory && this.approveStatus && this.rejectStatus && this.pendingStatus){
      filterParams = this.selectedFromID + '&' + this.selectedIP + '&' + this.selectedFactory + '&' + this.approveStatus + '&' + this.rejectStatus + '&' + this.pendingStatus;
    }

    if(this.selectedFromID){
      filterParams = this.selectedFromID;
    }

    if(this.selectedIP){
      if(filterParams){
        filterParams = filterParams + '&' + this.selectedIP;
      }else{
        filterParams = this.selectedIP;
      }
    }

    if(this.selectedFactory){
      if(filterParams){
        filterParams = filterParams + '&' + this.selectedFactory;
      }else{
        filterParams = this.selectedFactory;
      }
    }

    if(this.approveStatus){
      if(filterParams){
        filterParams = filterParams + '&' + this.approveStatus;
      }else{
        filterParams = this.approveStatus;
      }
    }

    if(this.rejectStatus){
      if(filterParams){
        filterParams = filterParams + '&' + this.rejectStatus;
      }else{
        filterParams = this.rejectStatus;
      }
    }

    if(this.pendingStatus){
      if(filterParams){
        filterParams = filterParams + '&' + this.pendingStatus;
      }else{
        filterParams = this.pendingStatus;
      }
    }

    if(filterParams){
      this.appliedFilter = true;
      this.setApplyFiltr.emit(false);
      this.getCommunicationList('', filterParams);
    }
    if (this.matMenuTrigger) {
      this.matMenuTrigger.closeMenu();
    }
  }

  resetFilters(type:any){
    this.buttonType = type;
    this.appliedFilter = false;
    this.messengerList = [];
    this.allListData = [];
  let fromJson = JSON.parse(JSON.stringify(this.fromJson));
  this.fromJson = [];
    this.fromJson  = fromJson;
    this.toJson.forEach((ele:any) => ele.isChecked = false);
    this.statusJson.forEach((ele:any) => ele.isChecked = false);

    this.selectedFromID = '';
    this.selectedIP = '';
    this.selectedFactory = ''; 
    this.approveStatus = ''; 
    this.rejectStatus = '';  
    this.pendingStatus = '';
    this.setApplyFiltr.emit(false);
    this.getCommunicationList('','','', 1 ,type);
    if (this.matMenuTrigger) {
      this.matMenuTrigger.closeMenu();
    }

  }


  getCommunicationList(id:any = "", searchedValue:any = "", filterParam:any = "", page:number = 1, status:string = "",isMarkAllReadyRead:boolean=false){
    console.log("status",status, searchedValue)
    this.commonService.communicaionList(id, filterParam, page, false, status, this.userData?.id, searchedValue, this.starMessage).subscribe(res => {
      if(res.success){
        if(id){
          // res.data['is_Clickable'] = true;
          this.allListData.map((ele:any) => {
            if(ele.id === this.lineItemID){
              ele.is_Clickable = true;
              ele.is_visible = true;
            }
            return ele;
          })
          console.log("allListData id",this.allListData);
          this.messageLineItemDetails = res.data;
          let fullName = this.messageLineItemDetails.name;
          let initials = fullName.split(' ').map((name:any) => name[0]).join('').toUpperCase()
          console.log("initials",initials,res.data);
          this.messageLineItemDetails['nameInitials'] = initials;
          var color = Math.floor(0x1000000 * Math.random()).toString(16);

          this.messageLineItemDetails['color'] = '#' + ('000000' + color).slice(-6);
         
          this.viewData.emit(this.messageLineItemDetails);

        }else{
          this.totalScrollData += res.data.length;
          this.totalCount = res.total;
          if(res.data?.length){
            res.data = res.data.map((el:any)=> {
              if(el?.id === this.lineItemID){
                el['is_Clickable']=true
              }
              else{
                el['is_Clickable']=false
              }
              return el;
            })
          }
          this.messengerList = res?.data;
          console.log("this.messengerList",this.messengerList, "length-", this.messengerList.length);
          
          
          if(this.appliedFilter){
            this.allListData = res?.data;
            if(this.allListData.length == 0){
              this.showInfo = true;
            }else{
              this.showInfo = false;
            }
            // this.allListData = [];
          }else{
            console.log("justtt",this.allListData);
            if(this.dataVisible || this.buttonType){
              this.allListData = [];
            }
            if(this.messengerList.length == 0 && this.allListData.length == 0){
              this.showInfo = true;
            }
            else{
              this.showInfo = false;
            }
            this.allListData = [...this.allListData, ...this.messengerList];
            if(isMarkAllReadyRead){
              this.allListData = this.allListData.map((el:any)=>{
                el.is_visible =  true;
                return el
              })
            }
            this.allListData = this.allListData.map((el:any)=>{
              if(el.id === this.lineItemID){
                el.is_Clickable = true
              }
              else{
                el.is_Clickable = false
              }
              return el
            })
          }
          // Array.prototype.push.apply(this.allListData,this.messengerList);
          console.log("allListData",this.allListData);
          console.log("isMArkaAllRead",this.allListData)

          for(let i = 0; i <= this.messengerList.length; i++){
            let fullName = this.messengerList[i].name;
            let initials = fullName.split(' ').map((name:any) => name[0]).join('').toUpperCase()
            console.log("initials",initials);
            this.messengerList[i]['nameInitials'] = initials;
            var color = Math.floor(0x1000000 * Math.random()).toString(16);

            this.messengerList[i]['color'] = '#' + ('000000' + color).slice(-6);
            
            console.log("ressssssss",this.messengerList);
          }
        }

      }
    })
  }

  dataVisible:boolean = false;
  communicationUpdate(data:any){
    this.lineItemID = data.id;
     this.dataVisible = data.is_visible;
    this.allListData = this.allListData.map((el:any)=>{
      if(el.id === data.id){
        el.is_Clickable = true;
      }
      else{
        el.is_Clickable = false
      }
      return el
    })
    if(!data?.is_visible){
        this.dataVisible = true;
    }
    let payload = {
      "subject": data?.subject,
      "message": data?.message,
      "sender_id": data?.sender_id,
      "receiver_id": data?.receiver_id || this.userData?.id,
      "createdBy": data?.createdBy,
      "is_highlight": data?.is_highlight,
      "is_visible": this.dataVisible

    };

    this.commonService.communicationUpdate(data.id, payload).subscribe(res => {
      if(res.success){
        
        this.getCommunicationList(data.id);
        this.commonService.isCommunication.next(true)
        // setTimeout(() => {
        //   if(!this.appliedFilter){
        //     this.getCommunicationList();
        //   }
        // }, 500);
      }
    })
  }

  openSelectedMsg(data:any){
    this.communicationUpdate(data);
    // setTimeout(() => {
    //   this.getCommunicationList()      
    // }, 1000);
  }

buttonType:string = "receive";
fetchStatus(type:any){
  this.buttonType = type;
  this.getCommunicationList('','','', 1 ,type);
  this.searchedValue = "";
  this.tabSwitched.emit(true);
}


onKeyDownEvent(){
  this.getCommunicationList('',this.searchedValue,'', 1 ,this.buttonType);
}

clearSearch(){
  this.searchedValue = "";
  this.getCommunicationList('','','', 1 ,this.buttonType);
}
}
