import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, throwError, Observable, Subject } from "rxjs";
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { saveAs } from "file-saver";
import { ContentService } from 'src/app/shared/services/content.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class UserManagementServiceService {
  userManagementEndPoint :string = '';
  private isProfiledBehavior = new BehaviorSubject<boolean>(false);
    isProfiled = this.isProfiledBehavior.asObservable();
    public isProfile:any = new BehaviorSubject('false')
  constructor(private httpClient: HttpClient,private contentService: ContentService,private snackBar:MatSnackBar,) { }
  getToken() {
    return sessionStorage.getItem("token");
  }
  
  setHeaders() {
  let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token")!
  });
  return { headers };
  } 
  
  setHeadersJwt() {
    let headers = new HttpHeaders({
        "Content-Type": "application/json",
        "x-access-token": sessionStorage.getItem("token")!,
        "x-jwt-token": sessionStorage.getItem('jwtToken')!
    });
    return { headers };
    } 
  countryDetails(countryId:number = 0):Observable<any>{
    let filter = countryId > 0 ? '?id='+countryId : '';
    return this.httpClient.get(environment.baseURL + 'country/list'+filter,this.setHeaders()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
  }
  ipNameDetails(selectedCountryId:any){
    return this.httpClient.get(environment.baseURL + 'industrialpark/list?country_id=' + selectedCountryId,this.setHeaders()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
    
  }
  factoryNameDetails(id:string){
    return this.httpClient.get(environment.baseURL + 'factory/list?industrialpark_id=' + id,this.setHeaders()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
  }
  UserRoleDetails(id:number = 0){
    let endPoint: string = `role/list?isUserMgmt=true&fetched=all`;
    return this.httpClient.get(environment.baseURL + endPoint,this.setHeaders()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
  }
  createUser(userPayload: any) {
    return this.httpClient.post(environment.baseURL + 'user/create',(userPayload),this.setHeadersJwt()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
}
getUserDataById(id : string){
  return this.httpClient.get(environment.baseURL + 'user/list' + `?id=${id}`,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
getUserPermission(){
  return this.httpClient.get(environment.baseURL + 'authenication/list' ,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
createRole(userPayload: any) {
  return this.httpClient.post(environment.baseURL + 'authenication/create',JSON.stringify(userPayload),this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
  allUserDetails(page = 1, limit = 10, sortingOrder: string = '', columnName: string = '') {
    if (['DESC', 'ASC'].includes(sortingOrder)) {
      return this.httpClient.get(environment.baseURL + 'user/list' + `?isUserMgmt=true&limit=${limit}&page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders()).pipe(map((response: any) => response))
        .pipe(
          catchError(this.handlehttpError)
        );
    }
    else {
      return this.httpClient.get(environment.baseURL + 'user/list' + `?isUserMgmt=true&limit=${limit}&page=${page}`, this.setHeaders()).pipe(map((response: any) => response))
        .pipe(
          catchError(this.handlehttpError)
        );
    }
  }
updateUserRole(data:any, id:string) {
  return this.httpClient.post(environment.baseURL + 'authenication/update/' + id,JSON.stringify(data),this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
deleteRole(id:string,payload:any){
  return this.httpClient.post(environment.baseURL + 'authenication/update/'+id,JSON.stringify(payload),this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
deleteByUserId(id:string,payload:any){
  return this.httpClient.put(environment.baseURL + 'user/update/'+id,payload,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
updateUser(id:string,data:any) {
  return this.httpClient.put(environment.baseURL + 'user/update/' + id,JSON.stringify(data),this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
serachData(searchString:string,endPoint:string){
  return this.httpClient.get(environment.baseURL +`${endPoint}?isUserMgmt=true&searchKey=${searchString}&fetched=all`,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
allRoleDetails(page=1,limit=10,sortingOrder: string = '', columnName: string = ''){
  if (['DESC', 'ASC'].includes(sortingOrder)) {
    return this.httpClient.get(environment.baseURL + 'authenication/view'+`?isUserMgmt=true&limit=${limit}&page=${page}&order=${sortingOrder}&key=${columnName}`,this.setHeaders()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
  }
  else{
    return this.httpClient.get(environment.baseURL + 'authenication/view'+`?isUserMgmt=true&limit=${limit}&page=${page}`,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
  }
  
}
getRoleDataById(id : string){
  return this.httpClient.get(environment.baseURL + 'authenication/view' + `?role_id=${id}`,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}
  handlehttpError(request: HttpErrorResponse) {
    return throwError(request.error ? request.error : request.message ? request.message : "SERVER ERROR");
}
actionTablePermission(actionArray: any[], permissionArray: any): any[] {
  return actionArray.map((mainItem: any) => {
    permissionArray?.authentication?.forEach((secondaryItem: any) => {
      if(mainItem.key === secondaryItem.key){
        mainItem.isPermission = secondaryItem.isAction;
      }
    });
    return mainItem; // Return the updated mainItem
  });
}
getProfileDetailsById(id : string){
  return this.httpClient.get(environment.baseURL + 'user/view' + `?id=${id}`,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}

targettedUserData(body:any):Observable<any>{
  return this.httpClient.post(environment.baseURL + `ipsa/create`, body);
}

updateUserForIpsa(body:any):Observable<any>{
  return this.httpClient.post(environment.baseURL + `ipsa/create`, body);
}

ipsaReportList(filterParams:any = ''):Observable<any>{
  return this.httpClient.get(environment.baseURL + 'ipsa/submissionlist'+filterParams,this.setHeaders()).pipe(map((response: any) => response))
  .pipe(
    catchError(this.handlehttpError)
  );
}

  ipsaReportDownload(payload: any, item: any) {
    this.contentService?.loaderSubscriber?.next(true)
    let xhr = new XMLHttpRequest();
    xhr.open('POST', environment.baseURL + 'ipsa/report', true);
    xhr.setRequestHeader("x-access-token", sessionStorage.getItem("token")!);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader("Accept", "application/json, text/plain, */*");
    xhr.responseType = 'blob';
    let _this = this;
    xhr.onload = function (this, event) {
      console.log("response", this)
      if(this.status == 200){
      let blob = this.response;
      let fileName: string = item?.year + '_' + item?.country_name + '_' + item?.industrialpark_name;
      fileName += item?.factory_name ? '_' + item?.factory_name + '.pdf' : '.pdf';
      saveAs(blob, fileName);
      }else{
        _this.snackBar.open(this.statusText, '', {
          duration: 3000,
          // verticalPosition: 'top',
          // horizontalPosition:'right',
          panelClass: ['primary']
        });
      }
      _this.contentService?.loaderSubscriber?.next(false)
    }
    xhr.send(payload);
  }

  getMasterLevel(){
    return this.httpClient.get(environment.baseURL + 'userlevel/list?isUserMgmt=true' ,this.setHeaders()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
  }

  getDashboardModule(){
    // return this.httpClient.get('https://run.mocky.io/v3/bb9a767a-4df8-4417-83df-f3ce0eae1abc');
    return this.httpClient.get(environment.baseURL + `dashboard/authentication/view`,this.setHeaders()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
  }

  setDashboardModule(userPayload: any) {
    return this.httpClient.put(environment.baseURL + `dashboard/authentication/update`,(userPayload),this.setHeadersJwt()).pipe(map((response: any) => response))
    .pipe(
      catchError(this.handlehttpError)
    );
  }

}
