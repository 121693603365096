<div class="container-fluid">
	<div class="row">
		<div class="col-12">
            <div class="d-flex mt-3 align-items-center">
                <svg class="me-2 pointer" id="keyboard_backspace_black_24dp_3_" (click)="backPath()" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                    <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
                  </svg>
                  
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb my-0">
                        <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="openFormList()">Forms</li>
                        <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backPath()">Monthly Progress Form</li>
                        <!-- <li class="breadcrumb-item fs-18 fw-600 pointer active" aria-current="page">Overview</li> -->
                    </ol>
                </nav>
            </div>
		</div>
    </div>
</div>


<ng-container *ngIf="!formQuestions.length">
    <app-common-form [url]="baseUrl" [formId]="viewFormId" [formQuestions]="formQuestions" [prefillForm]="prefillForm" [isMonthly]="is_monthly" [createFormURL]="createForm"></app-common-form>
</ng-container>