<div class="Footer_bottom">

    <section >
        <div class="container">
          <div class="d-flex mb-3 align-items-center" style="margin-top: 25px;">
            <!-- <svg class="me-3" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="#444444"/>
              </svg> -->

            <div class="fs-24 fw-600 mb-0" style="color: #444444;">Forms</div>
          </div>
            <div class="row">
                <div class="col-md-4 mb-3" *ngIf="getCurrentProgramFormMethod(['programform'])">
                    <div class="card border-0 mb-3 h-100 shadow-sm">
                        <img src="../../../assets/images/formImg-1.png" class="img-fluid w-100 h-190 card-img-top" alt="...">
                        <div class="card-body">
                            <h6 class="fw-600">Program Form</h6>
                            <div class="fs-12 text-secondary">General program impact indicators, to be completed by INSTEP staff</div>
                        </div>
                        <div class="card-footer border-0 bg-white text-center">
                            <button class="btn d-flex m-auto mb-2 align-items-center justify-content-center formView-btn rounded-pill" (click)="toggle(1)">View</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mb-3" *ngIf="getCurrentProgramFormMethod(['baselineform'])">
                    <div class="card border-0 mb-3 h-100 shadow-sm">
                        <img src="../../../assets/images/formImg-2.png" class="img-fluid w-100 h-190 card-img-top" alt="...">
                        <div class="card-body">
                            <h6 class="fw-600">Baseline Form</h6>
                            <div class="fs-12 mb-2 text-secondary"><span class="fw-600 text-dark">Industrial park:</span> Initial form to be completed by the industrial park within one month of onboarding to the INSTEP program</div>
                            <div class="fs-12 text-secondary"><span class="fw-600 text-dark">Factory:</span> Initial form to be completed by the factory within one month of onboarding to the INSTEP program</div>
                        </div>
                        <div class="card-footer border-0 bg-white text-center">
                            <button class="btn d-flex m-auto mb-2 align-items-center justify-content-center formView-btn rounded-pill" (click)="toggle(2)">View</button>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 mb-3" *ngIf="getCurrentProgramFormMethod(['baselineform'])">
                    <div class="card border-0 mb-3 h-100 shadow-sm">
                        <img src="../../../assets/images/formImg-3.png" class="img-fluid w-100 h-190 card-img-top" alt="...">
                        <div class="card-body">
                            <h6 class="fw-600">Monthly Progress Form</h6>
                            <div class="fs-12 mb-2 text-secondary"><span class="fw-600 text-dark">Industrial park:</span> Form to be completed on monthly basis by the industrial park (to be completed by 25th of the next month)</div>
                            <div class="fs-12 text-secondary"><span class="fw-600 text-dark">Factory:</span> Form to be completed on monthly basis by the factory (to be completed by 25th of the next month)"</div>
                        </div>
                        <div class="card-footer border-0 bg-white text-center">
                            <button class="btn d-flex m-auto mb-2 align-items-center justify-content-center formView-btn rounded-pill" (click)="toggle(3)">View</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

</div>