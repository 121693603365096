<div class="Footer_bottom">
  <section class="first-section">
    <div class="container-fluid pe-0 overflow-hidden">
      <div class="row justify-content-end align-items-center">
        <div class="col-md-5">
  <div class="content-box" style="padding: 0 40px;">
    <div>
        <img src="../../../assets/images/arrow-left.svg" class="img-fluid pointer" [routerLink]="currentRoute.includes('impact-stories') ? '/impact-stories' : '/home'" alt="">
    </div>
    <h1 class="heading-1 text-white">{{impactStoriesData[0].title}}</h1>
    <p style="font-size: 14px; color: #F9F9F9;">{{impactStoriesData[0].createdAt | date: 'd MMM y'}}</p>
  </div>
        </div>
        <div class="col-md-6 ps-0 text-end">
          <img [src]="impactStoriesData[0].banner" class="img-fluid w-100 banner-img" alt="">
        </div>
      </div>
    </div>
  </section>
  
  <section class="para-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
              <!-- stripHtmlTags(impactStoriesData[0].description) -->
                <!-- <p class="para-1 mb-3 color-44">{{impactStoriesData[0].description}}</p> -->
                <!-- <p class="para-1 mb-3 color-44" [innerHTML]="stripHtmlTags(impactStoriesData[0].description)"></p> -->
                <p class="para-1 mb-3 color-44" [innerHTML]="impactStoriesData[0].description"></p>

                <!-- <p class="para-2 mb-5 color-44">The partnership between the state government and IDH was formalised in 2016, when the PCI Strategy became public policy. The initiative to achieve the Production, Conservation and Inclusion targets was launched at the Global Climate Conference (COP 21) in Paris.</p>
                <p class="para-3 mb-3 color-44">The governance which was developed from the Strategy became robust and resulted in the creation of the PCI Institute, a private, non-profit organisation able to raise and manage public and private funds to implement sustainability measures in the field.</p>
                <p class="para-4 mb-3 color-44">Since 2016, IDH has invested more than 10 million euros in Mato Grosso, including the Strategy, the PCI Institute, three Regional Compacts and programmes co-financed with the private sector, as well as supporting the State Secretariat for the Environment in implementing the Forest Code.</p>
                <p class="para-5 color-44"> “We believe that Mato Grosso is very well positioned, with the PCI Institute as the main point of anchorage for resources from the private sector to finance the actions that will make it possible to achieve the established goals. The state offers countless opportunities for our way of working, which seeks to make an impact on a large scale, involving markets, farmers, companies and governments. And our role, beyond institutional support, is to build this connection and attract investors and markets,” Mariuzzo pointed</p> -->
            </div>
        </div>
    </div>
  </section>

  <section class="my-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 col-12" *ngIf="impactStoriesData[0].link">
          <!-- <iframe src="https://youtu.be/YWtAj_HOs_E?si=4Pavrw86xE1VzJvn" frameborder="0" height="300px" width="500px" allowfullscreen></iframe> -->
          <!-- https://www.youtube.com/embed/YWtAj_HOs_E?si=TE3NWaa4AylqOCv9 -->
          <iframe class="iframe_video w-100" width="500" height="300" [src]="url" 
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </section>


  <section>
    <div class="container">
      <div class="row" >
        <div class="col-12">
          <h2 class="text-center related-title" style="color: #00625F;">Related Stories</h2>
        </div>
        <!-- <ng-container *ngFor="let item of relatedStories; let i = index">
          <div class="col-lg-8" *ngIf="i % 2 == 0 && (i == 0 || i > 6)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid image-round h-446 w-100" alt="">
              <div class="text-white position-absolute img-content">
                <h4 class="text-white">{{item?.title}}</h4>
                <p class="fs-16 text-truncate" style="max-width: 600px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
              </div>
            </div>
          </div>
          </ng-container>
          
        <ng-container *ngFor="let item of relatedStories; let i = index">
          <div class="col-lg-4" *ngIf="i % 2 == 1 && (i == 1 || i > 7)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid w-100 h-365 image-round" alt="">
              <div class="text-white position-absolute img-content">
                <h6 class="text-white">{{item?.title}}</h6>
                <p class="text-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngFor="let item of relatedStories; let i = index">
        <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 2 || i > 8)">
          <div class="position-relative img-opacity mb-3">
            <img [src]="item?.banner" class="img-fluid h-281 image-round" alt="">
            <div class="text-white position-absolute img-content">
              <h6 class="text-white">{{item?.title}}</h6>
              <p class="text-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
              <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
            </div>
          </div>
        </div>
        <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 3 || i > 9)">
          <div class="position-relative img-opacity mb-3">
            <img [src]="item?.banner" class="img-fluid h-281 image-round" alt="">
            <div class="text-white position-absolute img-content">
              <h6 class="text-white">{{item?.title}}</h6>
              <p class="text-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
              <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
            </div>
          </div>
        </div>
        <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 4 || i > 10)">
          <div class="position-relative img-opacity mb-3">
            <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
            <div class="text-white position-absolute img-content">
              <h6 class="text-white">{{item?.title}}</h6>
              <p class="text-truncate" style="font-size: 13px; max-width: 300px;">{{item?.description}}</p>
              <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
            </div>
          </div>
        </div>
        </ng-container>

        <ng-container *ngFor="let item of relatedStories; let i = index">
          <div class="col-lg-6" *ngIf="i % 2 == 1 && (i == 5 || i > 11)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
              <div class="text-white position-absolute img-content">
                <h6 class="text-white">{{item?.title}}</h6>
                <p class="text-truncate" style="font-size: 13px; max-width: 400px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
              </div>
            </div>
          </div>
          <div class="col-lg-6" *ngIf="i % 2 == 1 && (i == 6 || i > 12)">
            <div class="position-relative img-opacity mb-3">
              <img [src]="item?.banner" class="img-fluid w-100 h-281 image-round" alt="">
              <div class="text-white position-absolute img-content">
                <h6 class="text-white">{{item?.title}}</h6>
                <p class="text-truncate" style="font-size: 13px; max-width: 400px;">{{item?.description}}</p>
                <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: item?.id}">Read More</button>
              </div>
            </div>
          </div>
        </ng-container> -->
      
        <ng-container *ngFor="let items of relatedStories; let i = index">
          <div class="col-md-4" style="margin-bottom: 35px;" *ngIf="i <= 5">
    <div class="card-bg h-100">
      <div class="card h-100 card-hover border-0 shadow-sm">
        <div class="position-relative main-img">
    
          <img [src]="items?.banner" class="card-img-top h-100 rounded-0 img-fluid card-img" alt="...">
        </div>
        <div class="card-body" style="background-color: #00625F;">
          <h6 class="text-white">{{items?.title}}</h6>
          <!-- <p class="card-text text-white">{{items?.description | truncatePipe : 150}}</p> -->
          <p class="card-text text-white" [innerHTML]="stripHtmlTagsForCard(items?.description,150)"></p>
          <!-- <p class="card-text text-white" [innerHTML]="impactStoriesData[0].description"></p> -->

        </div>
        <div class="text-center card-footer" style="background-color: #00625F;">
          <button class="btn read-btn mb-2 text-white rounded-pill" style="background-color: #47d985; border: 1px solid #47d985;" routerLink="/related-stories" [queryParams]="{ id: items?.id}">Read More</button>
        </div>
       </div>
    </div>
          </div>
          </ng-container>
      </div>
    </div>
</section>
  <!-- <section class="story-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2 class="mb-3" style="color: #00625F;">Related Stories</h2>
            </div>

            <div class="col-lg-8">
                <div class="position-relative">
                  <img src="../../../assets/images/story-1.png" class="img-fluid mb-3 h-446" alt="">
                  <div class="text-white position-absolute img-content">
                    <h4 class="text-white">IDH and the Government of Mato Grosso renew partnership for investments in the state</h4>
                    <p class="text-truncate col-8-truncate" style="font-size: 16px; max-width: 600px;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <button class="btn read-btn text-white mb-3 rounded-pill">Read More</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="position-relative">
                  <img src="../../../assets/images/story-2.png" class="img-fluid mb-3 w-100 h-365" alt="">
                  <div class="text-white position-absolute img-content">
                    <h6 class="text-white">Lorem ipsum</h6>
                    <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">Lorem ipsum dolor sit amet, consec...</p>
                    <button class="btn read-btn text-white mb-3 rounded-pill">Read More</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="position-relative">
                  <img src="../../../assets/images/story-3.png" class="img-fluid mb-3 h-281" alt="">
                  <div class="text-white position-absolute img-content">
                    <h6 class="text-white">Lorem ipsum</h6>
                    <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">Lorem ipsum dolor sit amet, consec...</p>
                    <button class="btn read-btn text-white mb-3 rounded-pill">Read More</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="position-relative">
                  <img src="../../../assets/images/story-4.png" class="img-fluid mb-3 h-281" alt="">
                  <div class="text-white position-absolute img-content">
                    <h6 class="text-white">Lorem ipsum</h6>
                    <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">Lorem ipsum dolor sit amet, consec...</p>
                    <button class="btn read-btn text-white mb-3 rounded-pill">Read More</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="position-relative">
                  <img src="../../../assets/images/story-5.png" class="img-fluid w-100 h-365 mb-3 m-83" alt="">
                  <div class="text-white position-absolute img-content">
                    <h6 class="text-white">Lorem ipsum</h6>
                    <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">Lorem ipsum dolor sit amet, consec...</p>
                    <button class="btn read-btn text-white mb-3 rounded-pill">Read More</button>
                  </div>
                </div>
              </div>
        </div>
     </div>
    </section> -->
</div>