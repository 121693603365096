import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { environment } from "../../../environments/environment";
import { Data } from '@angular/router';
var Crypto = require("crypto-js");
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CommonServiceService {

  // isLoggedin = new Subject<boolean>();
  userData:any;
  constructor(private http: HttpClient) { 
    this.userData = JSON.parse(sessionStorage.getItem('userdata')!);
  }
  baseURL = environment.baseURL;

  resourceCount: BehaviorSubject<any> = new BehaviorSubject('');
  public isCommunication:any = new BehaviorSubject('')
  public notificationClicked:any = new BehaviorSubject('')
  public isMarkAllRead:any = new BehaviorSubject(false)
  formComponentData: BehaviorSubject<any> = new BehaviorSubject({});
  
  data = this.resourceCount.asObservable();
  
  updateResouceCount(data:Data){
    this.resourceCount.next(data);
   }

   prefillIpsaForm = this.formComponentData.asObservable();

  updatePrefillIpsa(data:any){
    console.log("jsonSchemaIpsa", data)
    this.formComponentData.next(data);
   }

  //Dashboard Page variables start
  districtBoundaries:any;
  summaryCard:any = [
    {
      "achievedValue": 3,
      "achievedOutof": 5,
      "cardName": "Participating countries",
      "achievedPercentage": 60,
      "sourceimg": '../../assets/summaryCard1.svg'
    },
    {
      "achievedValue": 20,
      "achievedOutof": 30,
      "cardName": "Industrial Park",
      "achievedPercentage": 66,
      "sourceimg": '../../assets/summaryCard1.svg'
    },
    {
      "achievedValue": 200,
      "achievedOutof": 300,
      "cardName": "Textile",
      "achievedPercentage": 40,
      "sourceimg": '../../assets/summaryCard3.svg'
    },
    {
      "achievedValue": 20,
      "achievedOutof": 50,
      "cardName": "Participating countries",
      "achievedPercentage": 80,
      "sourceimg": '../../assets/summaryCard4.svg'
    },
    {
      "achievedValue": 32,
      "achievedOutof": 40,
      "cardName": "Sourcing Brands",
      "achievedPercentage": 60,
      "sourceimg": '../../assets/summaryCard5.svg'
    },
    {
      "achievedValue": 5,
      "achievedOutof": 5,
      "cardName": "IP remedition plan",
      "achievedPercentage": 75,
      "sourceimg": '../../assets/summaryCard6.svg'
    },
    {
      "achievedValue": 13,
      "achievedOutof": 15,
      "cardName": "Multi stackholder",
      "achievedPercentage": 75,
      "sourceimg": '../../assets/summaryCard7.svg'
    },
    {
      "achievedValue": 25,
      "achievedOutof": 100,
      "cardName": "Participating countries",
      "achievedPercentage": 68,
      "sourceimg": '../../assets/summaryCard8.svg'
    },
    {
      "achievedValue": 50,
      "achievedOutof": 100,
      "cardName": "Co-Investments Raises",
      "achievedPercentage": 25,
      "sourceimg": '../../assets/summaryCard9.svg'
    },
  ];
  //Dashboard Page variables end
  // setFooter(){
  //   let checkToken = localStorage.getItem('token') ? true : false;
  //   this.resourceCount.next(checkToken);
  // }

  getToken() {
    // Get Token..
    return sessionStorage.getItem("token");
}

setHeadersJwt() {
  let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-access-token": sessionStorage.getItem("token")!,
      "x-jwt-token": sessionStorage.getItem('jwtToken')!
  });
  return { headers };
  } 

  setHeaders(isCsv:boolean = false, type='') {
    if(this.getToken()){
  let headers = new HttpHeaders({
      // "Content-Type": isCsv ? 'text/plain; charset=utf-8' :"application/json",
      "x-access-token": sessionStorage.getItem("token")! ? sessionStorage.getItem("token")! : '',
  });
  if(type === 'pdf'){
    headers.set('Content-Type', 'application/pdf');

  }
  else if(type === 'csv'){
    headers.set('Content-Type', 'text/plain; charset=utf-8')
  }
  else{
    headers.set('Content-Type', 'application/json')
  }
  let options: any = { headers };
  if(isCsv){
    options.responseType = 'text';
  }
  return options;
}
}
setHeadersCaptcha() {
  let headers = new HttpHeaders({
      "Content-Type":"application/json",
  });
  return { headers };
}
  setFormIoHeaders(){
    let headers = new HttpHeaders({
      "x-jwt-token": sessionStorage.getItem("x-jwt-token")!,
    });
    return { headers };
  }
  downloadDashboardCSV(endPoints:string,item:string){
    let isCsv: boolean = true; 
    return this.http.get(this.baseURL + endPoints + '/' + item, this.setHeaders(isCsv,'csv'));
  }

  loginService(data:any):Observable<any>{
    return this.http.post(this.baseURL + 'auth/login', data);
  }

  loginFormIOservice(data:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, data, {...this.setHeaders(), observe: 'response'});
  }

  createNewFormIO(data:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, data, this.setHeadersJwt());
  }

  getAllFormList(payload:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`,payload, this.setHeadersJwt());
  }

  logoutService():Observable<any>{
    return this.http.post(this.baseURL + 'auth/logout','',this.setHeaders());
  }
 
  //get impact data
  impactStoriesData():Observable<any>{
    return this.http.get(this.baseURL + 'user/impactstories?fetched=all',this.setHeaders());
  }

  impactStoriesMasterData(page:any, searchString:any,sortingOrder:string='',columnName:string=''):Observable<any>{
    console.log(searchString);
    if(searchString == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `impactstories/list?page=${page}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `impactstories/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `impactstories/list?page=${page}&fetched=all&searchKey=${searchString}`, this.setHeaders());
    }
  }

  resourceCenterData():Observable<any>{
    return this.http.get(this.baseURL + 'user/category?fetched=all',this.setHeaders());
  }

  resourceCenterMasterData(page:any, type:any = "", searchString:any = "",isDropDown:boolean,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(searchString == "" && !isDropDown && !sortingOrder?.length){
      return this.http.get(this.baseURL + `category/list?fetched=all&types=${type}`, this.setHeaders());
    }
    else if(isDropDown){
      return this.http.get(this.baseURL + `category/list?fetched=all&types=${type}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      // return this.http.get(this.baseURL + `impactstories/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());
      return this.http.get(this.baseURL + `category/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());

    }
    else{
      return this.http.get(this.baseURL + `category/list?page=${page}&types=${type}&fetched=all&searchKey=${searchString}`, this.setHeaders());
    }
  }

  // Glossary data
  glossaryData(page:any, searchString:any,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(searchString == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `user/glossary?page=${page}`,this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `user/glossary?page=${page}&key=${columnName}&order=${sortingOrder}`,this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `user/glossary?page=${page}&searchKey=${searchString}`,this.setHeaders());
    }
  }

  glossaryMasterData(page:any, searchString:any, sortingOrder:string='',columnName:string=''):Observable<any>{
    
    if(searchString == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `glossary/list?page=${page}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `glossary/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `glossary/list?page=${page}&fetched=all&searchKey=${searchString}`, this.setHeaders());
    }
  }

  homePageData(pageName:any = ''):Observable<any>{
    if(pageName){
      return this.http.get(this.baseURL + 'user/cms' + '?fetched=all',this.setHeaders());
    }else{
      return this.http.get(this.baseURL + 'user/cms',this.setHeaders());
    }
  }

  homePageMasterData(page:any, searchString?:any,sortingOrder:string='',columnName:string='', currentPage:string = ''):Observable<any>{
    console.log(searchString);
    if(searchString == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `cms/list?page=${page}&currentPage=${currentPage}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `cms/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `cms/list?page=${page}&fetched=all&searchKey=${searchString}`, this.setHeaders());
    }
  }

  aboutUsMasterData(payloadData?:any, id?:any, isEditMode:boolean = false):Observable<any>{
    if(isEditMode){
      return this.http.post(this.baseURL + `aboutUs/update/${id}`, payloadData, this.setHeaders());
    }else{
      return this.http.get(this.baseURL + `aboutUs/list`, this.setHeaders());
    }
  }

  employeeData():Observable<any>{
    return this.http.get(this.baseURL + 'user/employee',this.setHeaders());
  }

  inquiryMasterData(page:any, searchString:any,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(searchString == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `inquiry/list?page=${page}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      // return this.http.get(this.baseURL + `cms/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());
      return this.http.get(this.baseURL + `inquiry/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());

    }
    else{
      return this.http.get(this.baseURL + `inquiry/list?page=${page}&fetched=all&searchKey=${searchString}`, this.setHeaders());
    }
  }

  ipList(){
    return this.http.get(this.baseURL + 'industrialpark/list/4', this.setHeaders())
  }

  ipListDetail(body:any, formType:string = ""):Observable<any>{
    let endpoint = "";
    switch (formType) {
      case "Admin":
        endpoint = `communication/create/admin`;
      break;

      case "Assessor":
        endpoint = `communication/create/assessor`;
      break;

      case "IP List":
        endpoint = `communication/create/ip`;
      break;

      case "Factory List":
        endpoint = `communication/create/factory`;
      break;
    
      default:
        break;
    }

    return this.http.post(this.baseURL + endpoint, body, this.getToken() ? this.setHeaders(): {})

    // if(formType == 'IP list'){
    //   return this.http.post(this.baseURL + 'communication/create/ip', body,this.getToken() ? this.setHeaders(): {})
    // }else{
    // return this.http.post(this.baseURL + 'communication/create/factory', body,this.getToken() ? this.setHeaders(): {})
    // }
  }

  contactUsInfo(data:any):Observable<any>{
    return this.http.post(this.baseURL + 'user/inquiry', data,this.setHeaders())
  }

  subscribeUser(data:any):Observable<any>{
    return this.http.post(this.baseURL + 'user/subscribe', data,this.setHeaders());
  }
  
  getSubCategoryData(catID:any):Observable<any>{
    return this.http.get(this.baseURL + 'user/subcategory/'+catID,this.setHeaders());
  }

  // getPdfData(id:any){
  //   return this.http.get(this.baseURL + '/subcategoryreport?subCategoryId=' + id)
  // }
  deleteLineItems(endPoints:string, lineItemID:number):Observable<any>{
    return this.http.delete(this.baseURL + endPoints + lineItemID,this.setHeaders());
  }

  uploadImage(body:any):Observable<any>{
    return this.http.post(this.baseURL + 'getS3Url', body)
  }

  createCms(body:any, lineItemID:any, isEditMode:boolean):Observable<any>{
    console.log("mybody",body);
    if(isEditMode){
      return this.http.post(this.baseURL + 'cms/update/' + lineItemID, body, this.setHeaders());
    }else{
      return this.http.post(this.baseURL + 'cms/create', body, this.setHeaders());
    }
  }

  createCategory(body:any, lineItemID:any, isEditMode:boolean):Observable<any>{
    if(isEditMode){
      return this.http.post(this.baseURL + 'category/update/' + lineItemID, body, this.setHeaders());
    }else{
      return this.http.post(this.baseURL + 'category/create', body, this.setHeaders());
    }
  }

  createSubCategory(body:any, lineItemID:any, isEditMode:boolean):Observable<any>{
    if(isEditMode){
      return this.http.post(this.baseURL + 'subcategory/update/' + lineItemID, body, this.setHeaders());
    }else{
      return this.http.post(this.baseURL + 'subcategory/create',body, this.setHeaders());
    }
  }

  createSubCatReport(body:any, lineItemID:any, isEditMode:boolean):Observable<any>{
    if(isEditMode){
      return this.http.post(this.baseURL + 'subcategoryreport/update/' + lineItemID, body, this.setHeaders());
    }else{
      return this.http.post(this.baseURL + 'subcategoryreport/create',body, this.setHeaders());
    }
  }

  createImpactStory(body:any, lineItemID:any, isEditMode:boolean):Observable<any>{
    if(isEditMode){
      return this.http.post(this.baseURL + 'impactstories/update/' + lineItemID, body, this.setHeaders());
    }else{
      return this.http.post(this.baseURL + 'impactstories/create', body, this.setHeaders());
    }
  }

  createGlossary(body:any, lineItemID:any, isEditMode:boolean):Observable<any>{
    if(isEditMode){
      return this.http.post(this.baseURL + 'glossary/update/' + lineItemID, body, this.setHeaders());
    }
    else{
      return this.http.post(this.baseURL + 'glossary/create', body, this.setHeaders());
    }
  }

  imagePUTapi(imgURL:any, file:any):Observable<any>{
    // const fd = new FormData();
    console.log("zzzzxxx",imgURL)
    // fd.append('image', file, file['name'].replaceAll(/–/g, ''));
    return this.http.put(imgURL, file);
  }
  

  updateCMSlineitem(endPoints:string, body:any, lineItemID:any):Observable<any>{
    return this.http.post(this.baseURL + endPoints + lineItemID, body, this.setHeaders());
  }

  getSubscriberData(searchString:any, page:number, orderSorting?:any, key?:any):Observable<any>{
    if(searchString == "" && !orderSorting){
      return this.http.get(this.baseURL + 'subscribe/list?page='+page, this.setHeaders());
    } else if(orderSorting){
      return this.http.get(this.baseURL + `subscribe/list?page=${page}&order=${orderSorting}&key=${key}`, this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `subscribe/list?searchKey=${searchString}`, this.setHeaders());
    }
  }

  getDocumentsData(lineItemID:any):Observable<any>{
    return this.http.get(this.baseURL + 'user/subcategoryreport/' + lineItemID,this.setHeaders());
  }

  getSubCategoryAllData(page:any, searchedValue:any,isDropDown:boolean,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(searchedValue == "" && !isDropDown && !sortingOrder?.length){
      return this.http.get(this.baseURL + `subcategory/list?page=${page}`, this.setHeaders());
    }
    else if(isDropDown){
      return this.http.get(this.baseURL + `subcategory/list?fetched=all`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `subcategory/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());

    }
    else{
      return this.http.get(this.baseURL + `subcategory/list?fetched=all&searchKey=${searchedValue}`, this.setHeaders());
    }
  }

  getSubCategoryReportAllData(page:any, searchedValue:any,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(searchedValue == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `subcategoryreport/list?page=${page}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder))
    {
      return this.http.get(this.baseURL + `subcategoryreport/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `subcategoryreport/list?fetched=all&searchKey=${searchedValue}`, this.setHeaders());
    }
  }

//--------------------------------------------------------------------------------------------------------
  // DASHBOARD SERVICE FUNCTIONS STARTS FROM HERE
  getStatesGeoJson(){
    // const districtResponse =  fetch("../assets/customJson/custom.geo.json");
    // this.districtBoundaries =  districtResponse.json();
    return this.http.get("../assets/customJson/custom.geo.json")
    // return Promise.resolve({districtBoundaries:this.districtBoundaries});
  }

  getProgramViewImpactSummary(tokenCheck:any = ""):Observable<any>{
    // return this.http.get('../assets/customJson/programViewIS.json');
    // if(tokenCheck){
      return this.http.get(this.baseURL + 'programview/impact-summary/card', this.setHeaders());
    // }else{
    //   return this.http.get(this.baseURL + 'programview/impact-summary/card');
    // }
  }

  getProgramViewSocialImpact():Observable<any>{
    return this.http.get('../../assets/customJson/programViewSI.json');
  }

  getProgramViewEnvironmentalImpact():Observable<any>{
    return this.http.get('../../assets/customJson/programViewEI.json');
  }

  // DASHBOARD SERVICE FUNCTIONS ENDS HERE
//-----------------------------------------------------------------------------------------------------------

  //FORMS MASTER QUESTIONS
  // programFormQues():Observable<any>{
  //   return this.http.get(this.baseURL + 'programform/view?formId=1', this.setHeaders());
  // }

  // baselineFormQues():Observable<any>{
  //   return this.http.get(this.baseURL + 'baselineform/view?formId=2', this.setHeaders());
  // }

  getFormQuestions(url:string,formId:number, isMonthly:string = "", factoryParams:string = ''):Observable<any>{
    
    
    if(formId == 3)
    formId =2
    return this.http.get(this.baseURL + url + formId + (isMonthly ? '&' + isMonthly : '')+ factoryParams, this.setHeaders());
  }

  submitFormAnswers(body:any, url:string, isMonthly:string = ""):Observable<any>{
       return this.http.post(this.baseURL + url + (isMonthly ? '?' + isMonthly : ''), body, this.setHeaders());

    // if(formType == 'baselineform'){
    //   return this.http.post(this.baseURL + 'baselineform/create', body, this.setHeaders());
    // }else{
    //   return this.http.post(this.baseURL + 'programform/create', body, this.setHeaders());
    // }
  }

  teamAllData(page:any, searchedValue:any,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(searchedValue == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `teammember/list?page=${page}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `teammember/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `teammember/list?fetched=all&searchKey=${searchedValue}`, this.setHeaders());
    }
  }

  partnerAllData(page:any, searchedValue:any,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(searchedValue == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `partners/list?page=${page}`, this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `partners/list?page=${page}&key=${columnName}&order=${sortingOrder}`, this.setHeaders());

    }
    else{
      return this.http.get(this.baseURL + `partners/list?fetched=all&searchKey=${searchedValue}`, this.setHeaders());
    }
  }

  createTeamMember(body:any, lineItemID:any = '', isEditMode:boolean):Observable<any>{
    // return this.http.post(this.baseURL + 'teammember/create', body, this.setHeaders());
    if(isEditMode){
      return this.http.post(this.baseURL + 'teammember/update/' + lineItemID, body, this.setHeaders());
    }
    else{
      return this.http.post(this.baseURL + 'teammember/create', body, this.setHeaders());
    }
  }

  createNewPartner(body:any, lineItemID:any = '', isEditMode:boolean):Observable<any>{
    if(isEditMode){
      return this.http.post(this.baseURL + 'partners/update/' + lineItemID, body, this.setHeaders());
    }
    else{
      return this.http.post(this.baseURL + 'partners/create', body, this.setHeaders());
    }
  }

  removeTeamMember(id:any, bodyData:any):Observable<any>{
    return this.http.post(this.baseURL + 'teammember/update/' + id, bodyData, this.setHeaders());
  }

 
  ourPartnerData():Observable<any>{
    return this.http.get(this.baseURL + '', this.setHeaders());
  }

  aboutUsData():Observable<any>{
    return this.http.get(this.baseURL + 'user/cms?sectionId=2', this.setHeaders());
  }

  ourPartnerPageData():Observable<any>{
    return this.http.get(this.baseURL + 'user/cms?sectionId=4', this.setHeaders());
  }

  countryData(countryId:number = 0):Observable<any>{
    let filter = countryId ? '?id='+countryId : '';
    return this.http.get(this.baseURL + 'country/list'+filter, this.setHeaders());
  }
  createSignUpUser(data:any):Observable<any>{
    return this.http.post(this.baseURL + 'user/register', data, this.setHeaders())
  }
  forgetPassworEmailRequest(data:any):Observable<any>{
    return this.http.post(this.baseURL + 'user/sendmail', data, this.setHeaders())
  }
  handlehttpError(request: HttpErrorResponse) {
    return throwError(request.error ? request.error : request.message ? request.message : "SERVER ERROR");
}

  getProgramFormListing(url:string = 'programform/list?page=',page:any, roleId:any, id:any, formId:any,sortingOrder:string='',columnName:string=''):Observable<any>{
    if(formId == '3'){
      page = page == -1 ? '&is_month=true ': page+'&is_month=true'
    }
    
    if(page == -1){
      return this.http.get(this.baseURL + url, this.setHeaders());
    }
    else{
      if(['DESC','ASC'].includes(sortingOrder)){
        return this.http.get(this.baseURL + url + page + `&key=${columnName}&order=${sortingOrder}`, this.setHeaders())
      }
      return this.http.get(this.baseURL + url + page, this.setHeaders());
    }
  }

  getPageDetails(sectionId:number):Observable<any>{
    return this.http.get(this.baseURL + 'user/cms?sectionId='+sectionId, this.setHeaders());
  }
  // download Csv
  downloadCSVFile(data:any, fileName:string = 'csvFile.csv') {
 
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
   
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
   
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  
  getSecratKey(secratKey:any,password:any){
    let ciphertext = Crypto.HmacSHA256(password, secratKey).toString();
    return ciphertext;
  }
  
  captchaData():Observable<any>{
    return this.http.get(this.baseURL + 'user/captcha/create');
  }
  refreshCaptch(captchId:string){
    return this.http.get(this.baseURL + 'user/captcha/refresh/' + captchId,this.setHeadersCaptcha());
  }
  destroyRefreshCaptcha(captchId:string){
    return this.http.post(this.baseURL + 'user/captcha/destroy/'+ captchId,this.setHeadersCaptcha())
  }
  getRoleDataById(id : string){
    return this.http.get(this.baseURL + `authenication/view?role_id=${id}`, this.setHeaders());
  }
  actionTablePermission(actionArray: any[], permissionArray: any): any[] {
    return actionArray.map((mainItem: any) => {
      permissionArray?.authentication?.forEach((secondaryItem: any) => {
        if(mainItem.key === secondaryItem.key){
          mainItem.isPermission = secondaryItem.isAction;
        }
      });
  
      return mainItem; 
    });
  }
  direactUrlAccess(data:string[],permissionArray:any):boolean{
   return permissionArray?.authenicationJson.some((el:any)=> data.includes(el.model) && ((el?.isAction || el?.is_checked) || (el?.authentication?.length && el?.authentication.some((e:any)=>e?.isAction))))
  }
  // dashboard releated api intergations
  socailImpactCardDetails(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/social-impact/card', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/social-impact/card');
  }
  socailImpactCardDetailsForPieChart(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/social-impact/pie-chart', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/social-impact/pie-chart');
  }
  environmentalImpactCardDetails(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/environmental-impact/card', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/environmental-impact/card');
  }
  environmentalImpactCardDetailsForPieChart(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/environmental-impact/pie-chart', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/environmental-impact/pie-chart');
  }
  environmentalImpactCardDetailsForDoughnutChart(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/environmental-impact/donut-chart', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/environmental-impact/donut-chart');
  }
  environmentalImpactCardDetailsForBarChart(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/environmental-impact/bar-chart', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/environmental-impact/bar-chart');
  }
  environmentalImpactCardDetailsForTreeChart(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/environmental-impact/tree-map', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/environmental-impact/tree-map');
  }
  socailIpmactDetailsForStackedBarChart(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/social-impact/stacked-bar-chart', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/social-impact/stacked-bar-chart');
  }
  socailIpmactDetailsForBarChart(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/social-impact/bar-chart', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/social-impact/bar-chart');
  }
  socailIpmactDetailsForTable(){
    // if(this.getToken()){
       return this.http.get(this.baseURL + 'programview/social-impact/table', this.setHeaders())
    // }
    // return this.http.get(this.baseURL + 'programview/social-impact/table');
  }
  
  details(section: string = '', item: string = '',countryId:string=''){
   
    return this.http.get(this.baseURL + section + '/' + item + countryId, this.getToken() ? this.setHeaders(): {});
  }
  ipNameDetails(page:any = 1,searchString:string = '', id:string,sortingOrder:string='',columnName:string=''){
    if(id){
      return this.http.get(environment.baseURL + 'industrialpark/list?country_id=' + id + '&page=' + page + '&searchKey=' + searchString ,this.getToken() ? this.setHeaders(): {})
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
        return this.http.get(environment.baseURL + 'industrialpark/list' + `?page=${page}&key=${columnName}&order=${sortingOrder}` ,this.getToken() ? this.setHeaders(): {})
    }

    else{
      return this.http.get(environment.baseURL + 'industrialpark/list?page='  + page + '&searchKey=' + searchString ,this.getToken() ? this.setHeaders(): {})
    }
    
  }

  factoryNameDetails(page:any = 1,searchString:string = '', id:string,sortingOrder:string='',columnName:string = ''){
    if(id){
    return this.http.get(environment.baseURL + 'factory/list?industrialpark_id=' + id + '&page=' + page + '&searchKey=' + searchString ,this.getToken() ? this.setHeaders(): {})
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(environment.baseURL + 'factory/list' + `?page=${page}&key=${columnName}&order=${sortingOrder}` ,this.getToken() ? this.setHeaders(): {})
    }
    else{
      return this.http.get(environment.baseURL + 'factory/list?' + '&page=' + page + '&searchKey=' + searchString ,this.getToken() ? this.setHeaders(): {})
    }
  }

  // download excel format
  downloadDashboardXLS(){
    let isCsv: boolean = false; 
    return this.http.get(this.baseURL + 'programform/csv', this.setHeaders());
  }

  downloadExcelFile(data: any[], fileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    console.log("xls",excelBuffer, fileName)
    this.saveAsExcelFile(excelBuffer, fileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, `${fileName}.xlsx`);
  }


  // download Pdf Formate'
  downloadDashboardPDF(){
    let isCsv: boolean = false; 
    return this.http.get(this.baseURL + 'programform/csv', this.setHeaders(isCsv,'pdf'));
  }

  downloadPDFFile(res:any, fileName:string='pdfFormate'){
   let blob = new Blob([res], { type: 'application/pdf' });
    let pdfUrl = window.URL.createObjectURL(blob);

    var PDF_link = document.createElement('a');
    PDF_link.href = pdfUrl;
    PDF_link.download = `${fileName}.pdf`;
    PDF_link.click();
    document.body.removeChild(PDF_link);
  }
  
  communicaionList(id:any, filterParam:any = "", page:number = 1,isFetchAll:boolean=false, status:any = "", userId:any = "", searchedValue:any = "", starMessage:boolean = false):Observable<any>{
    if(id){
      return this.http.get(this.baseURL + `communication/list?id=${id}`, this.setHeaders());
    }
    else if(status){
      let endpoints = "";
      if(status == 'send'){
        console.log("searchedValue",searchedValue);

        endpoints = `fetched=all&sender_id=${this.userData.id}`;
        endpoints = searchedValue !== "" ? `${endpoints}&searchKey=${searchedValue}` : endpoints;
        return this.http.get(this.baseURL + `communication/list?${endpoints}`, this.setHeaders());
      }else{
        console.log("searchedValue",searchedValue);
        
        endpoints = `fetched=all&is_received=true&sender_id=${userId}`;
        endpoints = searchedValue !== "" ? `${endpoints}&searchKey=${searchedValue}` : endpoints;
        return this.http.get(this.baseURL + `communication/list?${endpoints}`, this.setHeaders());
      }
    }
    else{
      if(filterParam){
        return this.http.get(this.baseURL + `communication/list?${filterParam}`, this.setHeaders());
      } else if(isFetchAll && page==0){
        return this.http.get(this.baseURL + `communication/list?is_received=true`, this.setHeaders());
      }
      else{
        return this.http.get(this.baseURL + `communication/list?page=${page}`, this.setHeaders());
      }
    }
  }

  communicationUpdate(lineitemID:any, body:any):Observable<any>{
    return this.http.post(this.baseURL + `communication/update/${lineitemID}`, body, this.setHeaders());
  }

  communicationmapper(body:any):Observable<any>{
    return this.http.post(this.baseURL + `communicationmapper/create`, body, this.setHeaders());
  }
  resetPassword(body:any):Observable<any>{
    return this.http.post(this.baseURL + `user/forgotpassword`, body);
  }

  markAllasRead(body:any):Observable<any>{
    return this.http.post(this.baseURL + `communication/create/ip`, body, this.setHeaders())
  }
  timeLine(params:any={}):Observable<any>{
    if(Object.keys(params).length){
      return this.http.get(this.baseURL + `programview/social-impact/timeline?month=${params?.month}&financial_year=${params?.financial_year}`, this.getToken() ? this.setHeaders(): {})
    }else{
      return this.http.get(this.baseURL + `programview/social-impact/timeline`, this.getToken() ? this.setHeaders(): {})
    }
  }

  // ------------------------------------------------------------------------------------------------------------------
  // Form.IO APIs

  formIOSetHeaders() {
    let headers = new HttpHeaders({
        "x-jwt-token": "",
    });
    return {headers}
  }

  formIoBaseURL:string = "https://stgformbuilder.dhwaniris.in/#/"
  publishForms(body:any):Observable<any>{
    return this.http.post(this.formIoBaseURL + `form`, body, this.formIOSetHeaders())
  }

  jsonSchema:any ={
    "0": {
        "title": "Social Infrastructure",
        "collapsible": false,
        "key": "socialInfrastructure",
        "type": "panel",
        "label": "Panel",
        "input": false,
        "tableView": false,
        "components": [
            {
                "label": "Social Infrastructure",
                "components": [
                    {
                        "label": "Social Infrastructure",
                        "key": "socialInfrastructure",
                        "components": [
                            {
                                "label": "Subtabs",
                                "components": [
                                    {
                                        "label": "Worker accommodation",
                                        "key": "workerAccommodation",
                                        "components": [
                                            {
                                                "title": "Worker accommodation",
                                                "collapsible": false,
                                                "key": "workerAccommodation1",
                                                "type": "panel",
                                                "label": "Panel",
                                                "input": false,
                                                "tableView": false,
                                                "components": [
                                                    {
                                                        "label": "Does the Industrial Park have any initiatives or programmes to support its workers access decent affordable housing near the Industrial Park? For example, a rental coupon system, housing allowance etc ?",
                                                        "widget": "choicesjs",
                                                        "tableView": true,
                                                        "data": {
                                                            "values": [
                                                                {
                                                                    "label": "No",
                                                                    "value": "1"
                                                                },
                                                                {
                                                                    "label": "Demonstrates awareness of requirement",
                                                                    "value": "2"
                                                                },
                                                                {
                                                                    "label": "Evidence of a plan in place towards full compliance",
                                                                    "value": "3"
                                                                },
                                                                {
                                                                    "label": "Fully compliant",
                                                                    "value": "4"
                                                                },
                                                                {
                                                                    "label": "N/A",
                                                                    "value": "N/A"
                                                                }
                                                            ]
                                                        },
                                                        "validate": {
                                                            "required": true
                                                        },
                                                        "key": "doesTheIndustrialParkHaveAnyInitiativesOrProgrammesToSupportItsWorkersAccessDecentAffordableHousingNearTheIndustrialParkForExampleARentalCouponSystemHousingAllowanceEtc",
                                                        "type": "select",
                                                        "input": true
                                                    },
                                                    {
                                                        "label": "Please specify",
                                                        "placeholder": "Enter your comment",
                                                        "applyMaskOn": "change",
                                                        "tableView": true,
                                                        "key": "pleaseSpecify",
                                                        "type": "textfield",
                                                        "input": true
                                                    },
                                                    {
                                                        "label": "HTML",
                                                        "tag": "br",
                                                        "attrs": [
                                                            {
                                                                "attr": "",
                                                                "value": ""
                                                            }
                                                        ],
                                                        "refreshOnChange": false,
                                                        "key": "html1",
                                                        "type": "htmlelement",
                                                        "input": false,
                                                        "tableView": false
                                                    },
                                                    {
                                                        "label": "HTML",
                                                        "tag": "hr",
                                                        "attrs": [
                                                            {
                                                                "attr": "",
                                                                "value": ""
                                                            }
                                                        ],
                                                        "refreshOnChange": false,
                                                        "key": "html2",
                                                        "type": "htmlelement",
                                                        "input": false,
                                                        "tableView": false
                                                    },
                                                    {
                                                        "label": "HTML",
                                                        "tag": "br",
                                                        "attrs": [
                                                            {
                                                                "attr": "",
                                                                "value": ""
                                                            }
                                                        ],
                                                        "refreshOnChange": false,
                                                        "key": "html",
                                                        "type": "htmlelement",
                                                        "input": false,
                                                        "tableView": false
                                                    },
                                                    {
                                                        "label": "Does the Industrial Park have access to a suitable and sufficient land area to develop social housing?.",
                                                        "widget": "choicesjs",
                                                        "tableView": true,
                                                        "data": {
                                                            "values": [
                                                                {
                                                                    "label": "No",
                                                                    "value": "1"
                                                                },
                                                                {
                                                                    "label": "Demonstrates awareness of requirement",
                                                                    "value": "2"
                                                                },
                                                                {
                                                                    "label": "Evidence of a plan in place towards full compliance",
                                                                    "value": "3"
                                                                },
                                                                {
                                                                    "label": "Fully compliant",
                                                                    "value": "4"
                                                                },
                                                                {
                                                                    "label": "N/A",
                                                                    "value": "N/A"
                                                                }
                                                            ]
                                                        },
                                                        "validate": {
                                                            "required": true
                                                        },
                                                        "key": "doesTheIndustrialParkHaveAnyInitiativesOrProgrammesToSupportItsWorkersAccessDecentAffordableHousingNearTheIndustrialParkForExampleARentalCouponSystemHousingAllowanceEtc1",
                                                        "type": "select",
                                                        "input": true
                                                    },
                                                    {
                                                        "label": "Please specify",
                                                        "placeholder": "Enter your comment",
                                                        "applyMaskOn": "change",
                                                        "tableView": true,
                                                        "key": "pleaseSpecify1",
                                                        "type": "textfield",
                                                        "input": true
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "label": "Accessibility",
                                        "key": "accessibility",
                                        "components": [
                                            {
                                                "title": "Accessibility",
                                                "collapsible": false,
                                                "key": "accessibility1",
                                                "type": "panel",
                                                "label": "Panel",
                                                "input": false,
                                                "tableView": false,
                                                "components": [
                                                    {
                                                        "label": "Are there working street lights from the Industrial Park to public transport drop off/pick up points?",
                                                        "widget": "choicesjs",
                                                        "tableView": true,
                                                        "data": {
                                                            "values": [
                                                                {
                                                                    "label": "No",
                                                                    "value": "1"
                                                                },
                                                                {
                                                                    "label": "Demonstrates awareness of requirement",
                                                                    "value": "2"
                                                                },
                                                                {
                                                                    "label": "Evidence of a plan in place towards full compliance",
                                                                    "value": "3"
                                                                },
                                                                {
                                                                    "label": "Fully compliant",
                                                                    "value": "4"
                                                                },
                                                                {
                                                                    "label": "N/A",
                                                                    "value": "Na"
                                                                }
                                                            ]
                                                        },
                                                        "validate": {
                                                            "required": true
                                                        },
                                                        "key": "areThereWorkingStreetLightsFromTheIndustrialParkToPublicTransportDropOffPickUpPoints",
                                                        "type": "select",
                                                        "input": true
                                                    },
                                                    {
                                                        "label": "Please specify",
                                                        "placeholder": "Enter your comment",
                                                        "applyMaskOn": "change",
                                                        "tableView": true,
                                                        "key": "pleaseSpecify2",
                                                        "type": "textfield",
                                                        "input": true
                                                    },
                                                    {
                                                        "label": "HTML",
                                                        "tag": "br",
                                                        "attrs": [
                                                            {
                                                                "attr": "",
                                                                "value": ""
                                                            }
                                                        ],
                                                        "refreshOnChange": false,
                                                        "key": "html5",
                                                        "type": "htmlelement",
                                                        "input": false,
                                                        "tableView": false
                                                    },
                                                    {
                                                        "label": "HTML",
                                                        "tag": "hr",
                                                        "attrs": [
                                                            {
                                                                "attr": "",
                                                                "value": ""
                                                            }
                                                        ],
                                                        "refreshOnChange": false,
                                                        "key": "html4",
                                                        "type": "htmlelement",
                                                        "input": false,
                                                        "tableView": false
                                                    },
                                                    {
                                                        "label": "HTML",
                                                        "tag": "br",
                                                        "attrs": [
                                                            {
                                                                "attr": "",
                                                                "value": ""
                                                            }
                                                        ],
                                                        "refreshOnChange": false,
                                                        "key": "html3",
                                                        "type": "htmlelement",
                                                        "input": false,
                                                        "tableView": false
                                                    },
                                                    {
                                                        "label": "Is the Industrial Park located near public transportation (i.e., bus, subway or light- rail)?",
                                                        "widget": "choicesjs",
                                                        "tableView": true,
                                                        "data": {
                                                            "values": [
                                                                {
                                                                    "label": "No",
                                                                    "value": "1"
                                                                },
                                                                {
                                                                    "label": "Demonstrates awareness of requirement",
                                                                    "value": "2"
                                                                },
                                                                {
                                                                    "label": "Evidence of a plan in place towards full compliance",
                                                                    "value": "3"
                                                                },
                                                                {
                                                                    "label": "Fully compliant",
                                                                    "value": "4"
                                                                },
                                                                {
                                                                    "label": "N/A",
                                                                    "value": "Na"
                                                                }
                                                            ]
                                                        },
                                                        "validate": {
                                                            "required": true
                                                        },
                                                        "key": "areThereWorkingStreetLightsFromTheIndustrialParkToPublicTransportDropOffPickUpPoints1",
                                                        "type": "select",
                                                        "input": true
                                                    },
                                                    {
                                                        "label": "Please specify",
                                                        "placeholder": "Enter your comment",
                                                        "applyMaskOn": "change",
                                                        "tableView": true,
                                                        "key": "pleaseSpecify3",
                                                        "type": "textfield",
                                                        "input": true
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    {
                                        "label": "Access control",
                                        "key": "accessControl",
                                        "components": []
                                    },
                                    {
                                        "label": "Workplace amenities",
                                        "key": "workplaceAmenities",
                                        "components": []
                                    }
                                ],
                                "key": "tabs",
                                "type": "tabs",
                                "input": false,
                                "tableView": false
                            }
                        ]
                    },
                    {
                        "label": "Occupational Health and Safety (OHS)",
                        "key": "occupationalHealth",
                        "components": []
                    },
                    {
                        "label": "Indoor Environment",
                        "key": "indoorEnvironment",
                        "components": []
                    }
                ],
                "key": "socialInfrastructure1",
                "conditional": {
                    "show": true
                },
                "type": "tabs",
                "input": false,
                "tableView": false
            }
        ]
    },
    "1": {
        "type": "button",
        "label": "Submit",
        "key": "submit",
        "disableOnInvalid": true,
        "input": true,
        "tableView": false
    },
    "components": [
        {
            "label": "First Name",
            "placeholder": "Enter you first name",
            "applyMaskOn": "change",
            "tableView": true,
            "validate": {
                "required": true,
                "customMessage": "    <div class=\"col-sm-3\"></div>",
                "minLength": 2,
                "maxLength": 20
            },
            "errorLabel": "Name field can not be empty !",
            "key": "firstName",
            "type": "textfield",
            "input": true
        },
        {
            "label": "Last Name",
            "placeholder": "Enter you last name",
            "applyMaskOn": "change",
            "tableView": true,
            "validate": {
                "required": true,
                "customMessage": "    <div class=\"col-sm-3\"></div>",
                "minLength": 2,
                "maxLength": 20
            },
            "errorLabel": "Name field can not be empty !",
            "key": "firstName3",
            "type": "textfield",
            "input": true
        },
        {
            "label": "Email ID",
            "placeholder": "Enter you email id",
            "applyMaskOn": "change",
            "tableView": true,
            "validate": {
                "required": true,
                "customMessage": "    <div class=\"col-sm-3\"></div>",
                "minLength": 2,
                "maxLength": 20
            },
            "errorLabel": "Name field can not be empty !",
            "key": "firstName2",
            "type": "textfield",
            "input": true
        },
        {
            "label": "Password",
            "placeholder": "**********",
            "applyMaskOn": "change",
            "tableView": false,
            "case": "mixed",
            "truncateMultipleSpaces": true,
            "validate": {
                "required": true,
                "minLength": 8,
                "maxLength": 15
            },
            "key": "password",
            "type": "password",
            "input": true,
            "protected": true
        },
        {
            "type": "button",
            "label": "Submit",
            "key": "submit",
            "disableOnInvalid": true,
            "input": true,
            "tableView": false
        }
    ]
}

  // getIpsaFormStructure():Observable<any>{
  //   let url = "https://run.mocky.io/v3/d763cdbd-9117-4842-b0c2-df55b1d89d5f";
  //   return this.http.get(url);
  // }
  getIpsaFormStructure(body:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, body, this.setHeadersJwt());
  }

  postIpsaFormData(body:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, body, this.setHeadersJwt());
  }

  prefillFormIO(body:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, body, this.setHeadersJwt());
  }
  formGetMethod(endPoints: string, queryParam: any):any {
    return this.http.get(
      `${this.baseURL}${endPoints}`,
      {
        params: queryParam,
        ...this.setHeaders()
      },
     
    );
  };

  formPostMethod(body: any, endPoints:string):any {
    return this.http.post(
      `${this.baseURL}${endPoints}`,
      body,
      this.setHeaders()
    );
  }
  formUpdateMethod(body: any, endPoints:string, id:string, action?:any):any {
    if(action == 'deleteAction'){
      return this.http.post(`${this.baseURL}${endPoints}/${id}?deleted=true`, body,this.setHeaders());
    }else{
      return this.http.post(`${this.baseURL}${endPoints}/${id}`, body,this.setHeaders());
    }
  }

  registerIpsaUser(body:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, body, this.setHeaders());
  }

  ipDashboardDetails(page:any = 1,searchString:string = '', id:string,sortingOrder:string='',columnName:string=''){
    let params:string = `?page${page}&isDashboard=true`;

    if(id){
      params += `&country_id=${id}`;
      // return this.http.get(environment.baseURL + 'industrialpark/list?country_id=' + id + '&page=' + page + '&searchKey=' + searchString +'&isDashboard=true',this.getToken() ? this.setHeaders(): {})
    }
    if(searchString){
      params += `&searchKey=${searchString}`;
    }
    if(['DESC','ASC'].includes(sortingOrder)){
      params +=  `&key=${columnName}&order=${sortingOrder}`;
        // return this.http.get(environment.baseURL + 'industrialpark/list' + `?page=${page}&country_id=${id}&key=${columnName}&order=${sortingOrder}&isDashboard=true` ,this.getToken() ? this.setHeaders(): {})
    }

      return this.http.get(environment.baseURL + 'industrialpark/list?page='  + params ,this.getToken() ? this.setHeaders(): {})
    
  }

  factoryDashboardDetails(page:any = 1,searchString:string = '', countyrId:string='',ipId:string='',sortingOrder:string='',columnName:string = ''){
    let params:string = `?page=${page}&isDashboard=true`;

    if(ipId)
    params += `&industrialpark_id=${ipId}`;

    if(countyrId)
    params += `&country_id=${countyrId}`;

    if(searchString)
    params += `&searchKey=${searchString}`;

    if(['DESC','ASC'].includes(sortingOrder)){
      params +=  `&key=${columnName}&order=${sortingOrder}`;
     
    }
    return this.http.get(environment.baseURL + 'factory/list' + params ,this.getToken() ? this.setHeaders(): {});
  }

  targettedUserData(body:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, body);
  }

  updateUserForIpsa(body:any):Observable<any>{
    return this.http.post(this.baseURL + `ipsa/create`, body);
  }

  fetchDataSequentially(): Promise<any>{
    return Promise.all([
      this.http.get(this.baseURL + 'country/list').toPromise(),
      this.http.get(this.baseURL + 'industrialpark/list').toPromise(),
      this.http.get(this.baseURL + 'factory/list').toPromise()
    ])
    .then(([response1, response2, response3]:any) => {
      console.log('Response from API 1:', response1);
      console.log('Response from API 2:', response2);
      console.log('Response from API 3:', response3);
      let finalResponse :any = {'countryList':response1?.data,'ipLIst':response2?.data,'factoryList':response3?.data};
      return finalResponse; // or any other response you want to return
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      throw error;
    });
  }

  getAllYears(currentForm:any): Observable<any>{
    return this.http.get(this.baseURL + `year/list?${currentForm}=true`, this.setHeaders());
  }

  getMonths(params?:any, type:any = ''): Observable<any>{
    if(type == 'yearChange'){
      return this.http.get(this.baseURL + `month/list?${params}`, this.setHeaders()); 
    }else {
      return this.http.get(this.baseURL + `month/list`, this.setHeaders());
    }
  }

  downloadImage(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  allAdminMenbers(label:string, page:number, searchedValue:string, id:any, sortingOrder:string, culomnName:string):Observable<any>{
    let endpoint = label == "Admin" ? `admin/list` : `assessor/list`;
    // return this.http.get(this.baseURL + `${endpoint}?page=${page}`,this.setHeaders());
    if(searchedValue == "" && !sortingOrder?.length){
      return this.http.get(this.baseURL + `${endpoint}?page=${page}`,this.setHeaders());
    }
    else if(['DESC','ASC'].includes(sortingOrder)){
      return this.http.get(this.baseURL + `${endpoint}?page=${page}&key=${culomnName}&order=${sortingOrder}`, this.setHeaders());
    }
    else{
      return this.http.get(this.baseURL + `${endpoint}?fetched=all&searchKey=${searchedValue}`, this.setHeaders());
    }
  }
}
