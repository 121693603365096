import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { ContentService } from '../../services/content.service';
import { MasterFormPopupComponent } from 'src/app/admin/manage-portal/master-form-popup/master-form-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/pages/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  sortSelection: string = '';
  selectedColumn: string = '';

  constructor(
    private commonService: CommonServiceService,
    private contentService: ContentService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }
  @Input() selectedTab: any;
  @Input() headers: any;
  @Input() dataRefresh: boolean = false;
  @Output() sortMethod: EventEmitter<any> = new EventEmitter<any>();
  orderSorting: string = '';
  public limit = 10;
  public skip = 0;
  public page = 1;
  public pageSizeOptions = [5, 10, 25, 50, 100];
  pageIndex: number = 1;
  totalLength: number = 0;
  searchString: any = "";
  tableData: any;
  action = [
    { actionType: 'edit', icon: 'fa fa-edit', disabled: false, toolTip: 'Edit', imgIcon: '/assets/images/edit.svg', isPermission: true, label: 'Update', key: 'update' },
    { actionType: 'delete', icon: 'fa fa-trash', disabled: false, toolTip: 'Delete', imgIcon: '/assets/images/delete-bin.svg', isPermission: true, label: 'Delete', key: 'delete' },
  ];

  editAction:any = { actionType: 'edit', icon: 'fa fa-edit', disabled: false, toolTip: 'Edit', imgIcon: '/assets/images/edit.svg', isPermission: true, label: 'Update', key: 'update' };
  deleteAction:any  = { actionType: 'delete', icon: 'fa fa-trash', disabled: false, toolTip: 'Delete', imgIcon: '/assets/images/delete-bin.svg', isPermission: true, label: 'Delete', key: 'delete' };
  @Input() allowedAction: any =[]

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChange) {
    console.log('changes dataRefresh', changes);
    this.searchString = '';
    this.selectedColumn = '';
    this.fetchData()
  }

  fetchData(isSearch:boolean =false){
    this.page = 1;
    this.skip = 0;
    this.limit = 10;
    this.pageIndex = 0;
    this.totalLength = 0;
    console.log('page>>>', this.page, 'skip>>>', this.skip,"limit>>>",this.limit,"pageindex",this.pageIndex)
    this.getTableData(this.selectedTab, isSearch);
  }

  getTableData(selectedTab: any, filters?:boolean) {
    this.contentService.loaderSubscriber.next(true);
    let queryParams:any = {
      page: this.page,
      limit: this.limit,
    }
    let sortObj = {
      order:  this.orderSorting,
      key:this.selectedColumn
    }
    if(this.orderSorting && this.selectedColumn != ''){
      Object.assign(queryParams, sortObj);
    }
    if(filters) queryParams['searchKey'] = this.searchString;
    this.commonService.formGetMethod(this.selectedTab?.apiEndPoint, queryParams).subscribe((res: any) => {
      console.log('res', res);
      this.contentService.loaderSubscriber.next(false);
      this.tableData = res;
      this.totalLength = res?.total;
    },
      (error: any) => {
        console.log('res', error);
        this.contentService.loaderSubscriber.next(false);
      }
    )
    // formGetMethod
  }


  setPage(event: any) {
    // this.selectedColumn = '';
    this.skip = (event.pageIndex + 1) * this.limit - this.limit;
    this.limit = event.pageSize;
    this.page = event.pageIndex + 1;
    console.log('page', this.page, 'skip', this.skip, event)
    this.getTableData(this.selectedTab);
    // this.pageMethod.emit({source:event, skip:this.skip, limit: this.limit,page:event.pageIndex + 1})
  }


  serByName(e: any) {
    // this.dateResetFilters();
    // this.tableFilter['name'] = this.filterForm?.controls?.["name"].value

     this.fetchData(true)
  }
  openForm(event: any, actionData: any, row: any) {
    if (actionData?.actionType == 'edit') {
      let createData = {
        title: 'Edit '+this.selectedTab?.addButtonLabel,
        data: this.selectedTab,
        rowData: row,
        edit : true
      }
      const dialogRefcomp = this.dialog.open(MasterFormPopupComponent, {
        data: createData,
        width: '600px',
        panelClass: 'signUp-dialog-class',
        disableClose: true,
        position: { top: '120px' },
      });
      dialogRefcomp.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.getTableData(this.selectedTab);
        }
      });
    } else {
      this.dialogConfirmation(actionData, '', row)
    }
  }

  dialogConfirmation(eventType?: any, message = "Are you sure you want to apply changes?", rowData?: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        buttonText: {
          ok: "Yes",
          cancel: "No",
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // eventType.data['page'] = this.pageEvent;
        switch (eventType.actionType) {
          case 'delete':
            this.deleteRows(rowData, 'deleteAction');
            break;
          default:

        }
      }
    });
  }

  deleteRows(row: any, action:any) {
    console.log('rowsss', row);
    let body = {
      isActive: false
    };
    let updateEndPoints = `${this.getActionTab()}/update`
    this.commonService.formUpdateMethod(body, updateEndPoints, row?.id, action).subscribe((res: any) => {
      this.openSnackBar([res?.message || 'Success !'], '', 'success');
      this.getTableData(this.selectedTab);
    },
      (error: any) => {
        this.openSnackBar([error?.error?.message || 'Failed !'], "");
      }
    )
  }
  getActionTab() {
    const selectedKey = this.selectedTab?.key;
    switch (selectedKey) {
      case 'countryMaster':
        return 'country';
      case 'ipMaster':
        return 'industrialpark';
      default:
        return 'factory';
    }
  }
  openSnackBar(message: any, action = '', className = 'parmary') {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: [className]
    });
  }
  clearFilter() {
    this.searchString = "";
    this.skip = 0;
    this.getTableData(this.selectedTab);
  }
  sortTable(columnName: string, sortType:string = '') {
    this.sortSelection = sortType;
    this.selectedColumn = columnName;
    this.orderSorting = sortType === 'down' ? 'DESC' : 'ASC';
    console.log("columnName",columnName)
    let event={
      limit: this.limit,
      page:this.page,
      order:  this.orderSorting,
      key:columnName
    }
    this.commonService.formGetMethod(this.selectedTab?.apiEndPoint, event).subscribe((res: any) => {
      console.log('res', res);
      this.contentService.loaderSubscriber.next(false);
      this.tableData = res;
      this.totalLength = res?.total;
    },
      (error: any) => {
        console.log('res', error);
        this.contentService.loaderSubscriber.next(false);
      }
    )
  }
}
