import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ManagePortalComponent } from '../admin/manage-portal/manage-portal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomPipe } from '../pages/historical-sites/customPipe';
import {MatRadioModule} from '@angular/material/radio';
import { PaginatorComponent } from '../pages/paginator/paginator.component';
import {MatStepperModule} from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MaxLengthDirective } from './directive/max-length.directive';
import { NumberValidationDirective } from './directive/number-validation.directive';
import { SpecialCharactersValidationDirective } from './directive/special-characters-validation.directive';
import { StringPipePipe } from './directive/string-pipe.pipe';
import { CommonFilterComponent } from './common-filter/common-filter.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import { TableComponent } from './components/table/table.component';
import { CommonDateFilterComponent } from './common-date-filter/common-date-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AlphabetOnlyDirective } from '../alphaOnly.directive';


@NgModule({
    declarations: [
        ManagePortalComponent,
        CustomPipe,
        PaginatorComponent,
        MaxLengthDirective,
        NumberValidationDirective,
        SpecialCharactersValidationDirective,
        AlphabetOnlyDirective,
        StringPipePipe,
        CommonFilterComponent,
        TableComponent,
        CommonDateFilterComponent
    
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatRadioModule,
        FormsModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
        MatCardModule,
        MatTableModule,
        MatButtonToggleModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        MatStepperModule,
        MatMenuModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule
        
    ],
    exports: [
        CommonModule,
        CustomPipe,
        MatStepperModule,
        SpecialCharactersValidationDirective,
        AlphabetOnlyDirective,
        MaxLengthDirective,
        NumberValidationDirective,
        StringPipePipe,
        CommonFilterComponent,
        TableComponent,
        CommonDateFilterComponent
        
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
      ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: true }
          }
    ]
})
export class SharedModule { }
