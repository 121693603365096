import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonServiceService } from '../../services/common-service.service';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentService } from 'src/app/shared/services/content.service';
import {environment} from '../../../../environments/environment'
declare function makeFormioIpsa(args:any, args2:any, args3:any,updateLoader: (isLoading: any) => void,updateMessage: (isLoading: any) => void , baseUrl:string) : any;
declare function postFormData(args1:any, args2:any) : any;

@Component({
  selector: 'app-ipsa-form-design',
  templateUrl: './ipsa-form-design.component.html',
  styleUrls: ['./ipsa-form-design.component.scss']
})
export class IpsaFormDesignComponent implements OnInit {
    jwtToken: any;
    urlData: any;
  formFetchResponse: any;
  formSubmissionData: any;
  formioipsa: any;
  isViewMode:boolean =false;
  formFetchResponseComponent: any;
  formTitle: string = '';

  customForm:any = {
    "type": "form",
    "display": "form",
    "components": [
      {
        "type": "email",
        "placeholder": "Enter your email address",
        "key": "email",
        "label": "Email",
        "inputType": "email",
        "input": true,
        "validate": {
          "required": true
        }
      },
      {
        "type": "password",
        "placeholder": "Enter your password",
        "key": "password",
        "label": "Password",
        "inputType": "password",
        "input": true,
        "validate": {
          "required": true
        }
      },
      {
        "type": "button",
        "theme": "primary",
        "disableOnInvalid": true,
        "action": "submit",
        "size": "md",
        "key": "submit",
        "label": "Submit",
        "input": true,
        "block": true
      }
    ]
  }
  prefilledData: any;
  isFormDisabled: boolean =false;
  showFormName: any;
  listingParams: any;

  constructor(private commonService: CommonServiceService, public router: Router, private route: ActivatedRoute,private snackBar: MatSnackBar,private contentService: ContentService) {
    this.route.queryParams.subscribe(params => {
      this.urlData = params;
      this.showFormName = this.urlData?.formName.replace('Performance','Assessment');
      if(params.editMode == 'viewMode')
      this.isFormDisabled = true;
    }) 
  this.commonService.formComponentData.subscribe((res:any) => {
    console.log("foooorrr",res);

  });
  this.jwtToken = sessionStorage.getItem('jwtToken')!;
  this.commonService.prefillIpsaForm.subscribe((ipsaData:any) => {
    console.log("jsonSchemaIpsa", ipsaData)
    if(ipsaData?.hasOwnProperty('data'))
    this.prefilledData= ipsaData;
  }
  )

  const storedData = sessionStorage.getItem("ipsaListingPrams");
  this.listingParams = storedData ? JSON.parse(storedData) : null;  
   }

  ngOnInit(): void {
    this.fetchFormResponse();
  }

  fetchFormResponse(){
    // console.log("jsnsch",this.commonService.jsonSchema)
    let endpoint = `/form/${this.urlData.formId}`
    let payload = {
        'method': 'GET',
        'endpoint': endpoint,
        'headers': {
          "Content-Type": "application/json",
          'x-jwt-token': this.jwtToken
        }
      };
    this.commonService.getIpsaFormStructure(payload).subscribe(res => {
      if(res){
        console.log("allress",res);
        // if(res.length && res[0].hasOwnProperty('x-jwt-token'))
        // sessionStorage.setItem('jwtToken', res[0]['x-jwt-token']);
        this.formFetchResponse = res;
        this.formFetchResponseComponent = this.formFetchResponse.components;
        this.formFetchResponseComponent.forEach((ele:any) => {
          if(ele.key == "saveDraft"){
            if(this.urlData.isDraft == "false"){
              ele.disabled = true;
            }else{
              ele.disabled = false;
            }
          }
          if(ele.key == "update"){
            if(this.urlData.isDraft == "false"){
              ele.hidden = false;
            }else{
              ele.hidden = true;
            }
          }
          if(ele.key == "submit"){
            if(this.urlData.isDraft == "false"){
              ele['hidden'] = true;
            }else{
              ele['hidden'] = false;
            }
          }
          if(this.urlData?.editMode == "viewMode"){
            if(ele.key == "submit" || ele.key == "update" || ele.key == "saveDraft"){
                ele['hidden'] = true;
          }}
        })
        this.formTitle = res?.title;
        let token = res['x-jwt-token'];
        let formPath = this.formFetchResponse.path;
        let payload = {
          "endpoint": this.prefilledData ? `/${formPath}/submission/${this.prefilledData?._id}` : `/${formPath}/submission`,
          "headers": {
            "Content-Type": "application/json",
            'x-jwt-token': token
          },
          "requestBody": {
            "data": {
              "name": "john",
              "emailId": "IPPArk"
            }
          },
          "method": this.prefilledData ? "PUT" : "POST"
        };
        sessionStorage.setItem('jwtToken', res['x-jwt-token']);
        // makeFormioIpsa(res, payload, this.prefilledData?.data);

        makeFormioIpsa(res, payload, this.prefilledData?.data, (isLoading: boolean) => {
          this.contentService.loaderSubscriber.next(isLoading);
        },(isLoading: boolean) => {
          this.contentService.message.next(isLoading);
        }, environment.baseURL)
          .then((success: any) => {
            console.log("success", success);
            this.contentService.message.subscribe(res=>{
              console.log("message", res)
              this.openSnackBar([res?.data?.error], 3000);
            })
            if (success) {
              // Handle success
              // this.openSnackBar([res?.data?.error], 3000);
              // window.history.back()
              this.router.navigate(
                ['/ipsa-forms-list'],
                { queryParams: { formName: this.listingParams?.formName } }
              );
              // console.log('Form submitted successfully!');
            } else {
              // Handle failure
              console.log('Failed to submit form.');
              this.openSnackBar(['Failed to submit form.'], 2000)
            }
          }).catch((error: any) => {
            console.log("abhiiiierror", error);
            if (error?.data?.error == "Draft Success") {
              // window.history.back();
              this.router.navigate(
                ['/ipsa-forms-list'],
                { queryParams: { formName: this.listingParams?.formName } }
              );
            }
            this.contentService.message.subscribe(res=>{
              console.log("message", res)
              this.openSnackBar([res?.data?.error], 3000);
            if (res?.data?.error == "Draft Success") {
              this.router.navigate(
                ['/ipsa-forms-list'],
                { queryParams: { formName: this.listingParams?.formName } }
              );
            }
            })

            this.openSnackBar([error?.data?.error], 3000);
          });
      }
    }, (error) => {
      console.log("myErr",error);
      
    })
  }

  renderedFormKeys(){
    this.formFetchResponse.components.forEach((data:any) => {
      let obj = {};
      Object.assign(obj, data.key);
    });
  }

  submitform(event:any){
    console.log(event);
    return
    
    let token = sessionStorage.getItem('jwtToken');
    let formPath = this.formFetchResponse.path;
    let payload = {
      "endpoint": `/${formPath}/submission`,
      "headers": {
          "Content-Type": "application/json",
          'x-jwt-token': token
      },
      "requestBody": {
           "data": {
          "name": "john",
          "emailId": "IPPArk"
      }
      },
      "method": "POST"
  };
  
  this.commonService.postIpsaFormData(payload).subscribe(res => {
    if(res){
      this.formSubmissionData = res;
      // postFormData(formPath, res);

      sessionStorage.setItem('jwtToken', res['x-jwt-token'])
      this.router.navigate(['/ipsa-forms-list']);

    }
  })
  }

  prefillForm(){
    let endpoint = `${this.formFetchResponse.path}/submission/${this.formSubmissionData._id}`
    let payload = {
      "endpoint": endpoint,
      "headers": {
          "Content-Type": "application/json",
          "X-jwt-token": this.jwtToken
      },
      "requestBody": {},
      "method": "GET"
  }

    this.commonService.prefillFormIO(payload).subscribe(res => {
      console.log(res);
      
    })
  }

  //     let jsonSchema:any ={
//       "0": {
//           "title": "Social Infrastructure",
//           "collapsible": false,
//           "key": "socialInfrastructure",
//           "type": "panel",
//           "label": "Panel",
//           "input": false,
//           "tableView": false,
//           "components": [
//               {
//                   "label": "Social Infrastructure",
//                   "components": [
//                       {
//                           "label": "Social Infrastructure",
//                           "key": "socialInfrastructure",
//                           "components": [
//                               {
//                                   "label": "Subtabs",
//                                   "components": [
//                                       {
//                                           "label": "Worker accommodation",
//                                           "key": "workerAccommodation",
//                                           "components": [
//                                               {
//                                                   "title": "Worker accommodation",
//                                                   "collapsible": false,
//                                                   "key": "workerAccommodation1",
//                                                   "type": "panel",
//                                                   "label": "Panel",
//                                                   "input": false,
//                                                   "tableView": false,
//                                                   "components": [
//                                                       {
//                                                           "label": "Does the Industrial Park have any initiatives or programmes to support its workers access decent affordable housing near the Industrial Park? For example, a rental coupon system, housing allowance etc ?",
//                                                           "widget": "choicesjs",
//                                                           "tableView": true,
//                                                           "data": {
//                                                               "values": [
//                                                                   {
//                                                                       "label": "No",
//                                                                       "value": "1"
//                                                                   },
//                                                                   {
//                                                                       "label": "Demonstrates awareness of requirement",
//                                                                       "value": "2"
//                                                                   },
//                                                                   {
//                                                                       "label": "Evidence of a plan in place towards full compliance",
//                                                                       "value": "3"
//                                                                   },
//                                                                   {
//                                                                       "label": "Fully compliant",
//                                                                       "value": "4"
//                                                                   },
//                                                                   {
//                                                                       "label": "N/A",
//                                                                       "value": "N/A"
//                                                                   }
//                                                               ]
//                                                           },
//                                                           "validate": {
//                                                               "required": true
//                                                           },
//                                                           "key": "doesTheIndustrialParkHaveAnyInitiativesOrProgrammesToSupportItsWorkersAccessDecentAffordableHousingNearTheIndustrialParkForExampleARentalCouponSystemHousingAllowanceEtc",
//                                                           "type": "select",
//                                                           "input": true
//                                                       },
//                                                       {
//                                                           "label": "Please specify",
//                                                           "placeholder": "Enter your comment",
//                                                           "applyMaskOn": "change",
//                                                           "tableView": true,
//                                                           "key": "pleaseSpecify",
//                                                           "type": "textfield",
//                                                           "input": true
//                                                       },
//                                                       {
//                                                           "label": "HTML",
//                                                           "tag": "br",
//                                                           "attrs": [
//                                                               {
//                                                                   "attr": "",
//                                                                   "value": ""
//                                                               }
//                                                           ],
//                                                           "refreshOnChange": false,
//                                                           "key": "html1",
//                                                           "type": "htmlelement",
//                                                           "input": false,
//                                                           "tableView": false
//                                                       },
//                                                       {
//                                                           "label": "HTML",
//                                                           "tag": "hr",
//                                                           "attrs": [
//                                                               {
//                                                                   "attr": "",
//                                                                   "value": ""
//                                                               }
//                                                           ],
//                                                           "refreshOnChange": false,
//                                                           "key": "html2",
//                                                           "type": "htmlelement",
//                                                           "input": false,
//                                                           "tableView": false
//                                                       },
//                                                       {
//                                                           "label": "HTML",
//                                                           "tag": "br",
//                                                           "attrs": [
//                                                               {
//                                                                   "attr": "",
//                                                                   "value": ""
//                                                               }
//                                                           ],
//                                                           "refreshOnChange": false,
//                                                           "key": "html",
//                                                           "type": "htmlelement",
//                                                           "input": false,
//                                                           "tableView": false
//                                                       },
//                                                       {
//                                                           "label": "Does the Industrial Park have access to a suitable and sufficient land area to develop social housing?.",
//                                                           "widget": "choicesjs",
//                                                           "tableView": true,
//                                                           "data": {
//                                                               "values": [
//                                                                   {
//                                                                       "label": "No",
//                                                                       "value": "1"
//                                                                   },
//                                                                   {
//                                                                       "label": "Demonstrates awareness of requirement",
//                                                                       "value": "2"
//                                                                   },
//                                                                   {
//                                                                       "label": "Evidence of a plan in place towards full compliance",
//                                                                       "value": "3"
//                                                                   },
//                                                                   {
//                                                                       "label": "Fully compliant",
//                                                                       "value": "4"
//                                                                   },
//                                                                   {
//                                                                       "label": "N/A",
//                                                                       "value": "N/A"
//                                                                   }
//                                                               ]
//                                                           },
//                                                           "validate": {
//                                                               "required": true
//                                                           },
//                                                           "key": "doesTheIndustrialParkHaveAnyInitiativesOrProgrammesToSupportItsWorkersAccessDecentAffordableHousingNearTheIndustrialParkForExampleARentalCouponSystemHousingAllowanceEtc1",
//                                                           "type": "select",
//                                                           "input": true
//                                                       },
//                                                       {
//                                                           "label": "Please specify",
//                                                           "placeholder": "Enter your comment",
//                                                           "applyMaskOn": "change",
//                                                           "tableView": true,
//                                                           "key": "pleaseSpecify1",
//                                                           "type": "textfield",
//                                                           "input": true
//                                                       }
//                                                   ]
//                                               }
//                                           ]
//                                       },
//                                       {
//                                           "label": "Accessibility",
//                                           "key": "accessibility",
//                                           "components": [
//                                               {
//                                                   "title": "Accessibility",
//                                                   "collapsible": false,
//                                                   "key": "accessibility1",
//                                                   "type": "panel",
//                                                   "label": "Panel",
//                                                   "input": false,
//                                                   "tableView": false,
//                                                   "components": [
//                                                       {
//                                                           "label": "Are there working street lights from the Industrial Park to public transport drop off/pick up points?",
//                                                           "widget": "choicesjs",
//                                                           "tableView": true,
//                                                           "data": {
//                                                               "values": [
//                                                                   {
//                                                                       "label": "No",
//                                                                       "value": "1"
//                                                                   },
//                                                                   {
//                                                                       "label": "Demonstrates awareness of requirement",
//                                                                       "value": "2"
//                                                                   },
//                                                                   {
//                                                                       "label": "Evidence of a plan in place towards full compliance",
//                                                                       "value": "3"
//                                                                   },
//                                                                   {
//                                                                       "label": "Fully compliant",
//                                                                       "value": "4"
//                                                                   },
//                                                                   {
//                                                                       "label": "N/A",
//                                                                       "value": "Na"
//                                                                   }
//                                                               ]
//                                                           },
//                                                           "validate": {
//                                                               "required": true
//                                                           },
//                                                           "key": "areThereWorkingStreetLightsFromTheIndustrialParkToPublicTransportDropOffPickUpPoints",
//                                                           "type": "select",
//                                                           "input": true
//                                                       },
//                                                       {
//                                                           "label": "Please specify",
//                                                           "placeholder": "Enter your comment",
//                                                           "applyMaskOn": "change",
//                                                           "tableView": true,
//                                                           "key": "pleaseSpecify2",
//                                                           "type": "textfield",
//                                                           "input": true
//                                                       },
//                                                       {
//                                                           "label": "HTML",
//                                                           "tag": "br",
//                                                           "attrs": [
//                                                               {
//                                                                   "attr": "",
//                                                                   "value": ""
//                                                               }
//                                                           ],
//                                                           "refreshOnChange": false,
//                                                           "key": "html5",
//                                                           "type": "htmlelement",
//                                                           "input": false,
//                                                           "tableView": false
//                                                       },
//                                                       {
//                                                           "label": "HTML",
//                                                           "tag": "hr",
//                                                           "attrs": [
//                                                               {
//                                                                   "attr": "",
//                                                                   "value": ""
//                                                               }
//                                                           ],
//                                                           "refreshOnChange": false,
//                                                           "key": "html4",
//                                                           "type": "htmlelement",
//                                                           "input": false,
//                                                           "tableView": false
//                                                       },
//                                                       {
//                                                           "label": "HTML",
//                                                           "tag": "br",
//                                                           "attrs": [
//                                                               {
//                                                                   "attr": "",
//                                                                   "value": ""
//                                                               }
//                                                           ],
//                                                           "refreshOnChange": false,
//                                                           "key": "html3",
//                                                           "type": "htmlelement",
//                                                           "input": false,
//                                                           "tableView": false
//                                                       },
//                                                       {
//                                                           "label": "Is the Industrial Park located near public transportation (i.e., bus, subway or light- rail)?",
//                                                           "widget": "choicesjs",
//                                                           "tableView": true,
//                                                           "data": {
//                                                               "values": [
//                                                                   {
//                                                                       "label": "No",
//                                                                       "value": "1"
//                                                                   },
//                                                                   {
//                                                                       "label": "Demonstrates awareness of requirement",
//                                                                       "value": "2"
//                                                                   },
//                                                                   {
//                                                                       "label": "Evidence of a plan in place towards full compliance",
//                                                                       "value": "3"
//                                                                   },
//                                                                   {
//                                                                       "label": "Fully compliant",
//                                                                       "value": "4"
//                                                                   },
//                                                                   {
//                                                                       "label": "N/A",
//                                                                       "value": "Na"
//                                                                   }
//                                                               ]
//                                                           },
//                                                           "validate": {
//                                                               "required": true
//                                                           },
//                                                           "key": "areThereWorkingStreetLightsFromTheIndustrialParkToPublicTransportDropOffPickUpPoints1",
//                                                           "type": "select",
//                                                           "input": true
//                                                       },
//                                                       {
//                                                           "label": "Please specify",
//                                                           "placeholder": "Enter your comment",
//                                                           "applyMaskOn": "change",
//                                                           "tableView": true,
//                                                           "key": "pleaseSpecify3",
//                                                           "type": "textfield",
//                                                           "input": true
//                                                       }
//                                                   ]
//                                               }
//                                           ]
//                                       },
//                                       {
//                                           "label": "Access control",
//                                           "key": "accessControl",
//                                           "components": []
//                                       },
//                                       {
//                                           "label": "Workplace amenities",
//                                           "key": "workplaceAmenities",
//                                           "components": []
//                                       }
//                                   ],
//                                   "key": "tabs",
//                                   "type": "tabs",
//                                   "input": false,
//                                   "tableView": false
//                               }
//                           ]
//                       },
//                       {
//                           "label": "Occupational Health and Safety (OHS)",
//                           "key": "occupationalHealth",
//                           "components": []
//                       },
//                       {
//                           "label": "Indoor Environment",
//                           "key": "indoorEnvironment",
//                           "components": []
//                       }
//                   ],
//                   "key": "socialInfrastructure1",
//                   "conditional": {
//                       "show": true
//                   },
//                   "type": "tabs",
//                   "input": false,
//                   "tableView": false
//               }
//           ]
//       },
//       "1": {
//           "type": "button",
//           "label": "Submit",
//           "key": "submit",
//           "disableOnInvalid": true,
//           "input": true,
//           "tableView": false
//       },
//       "components": [
//           {
//               "label": "First Name",
//               "placeholder": "Enter you first name",
//               "applyMaskOn": "change",
//               "tableView": true,
//               "validate": {
//                   "required": true,
//                   "customMessage": "    <div class=\"col-sm-3\"></div>",
//                   "minLength": 2,
//                   "maxLength": 20
//               },
//               "errorLabel": "Name field can not be empty !",
//               "key": "firstName",
//               "type": "textfield",
//               "input": true
//           },
//           {
//               "label": "Last Name",
//               "placeholder": "Enter you last name",
//               "applyMaskOn": "change",
//               "tableView": true,
//               "validate": {
//                   "required": true,
//                   "customMessage": "    <div class=\"col-sm-3\"></div>",
//                   "minLength": 2,
//                   "maxLength": 20
//               },
//               "errorLabel": "Name field can not be empty !",
//               "key": "firstName3",
//               "type": "textfield",
//               "input": true
//           },
//           {
//               "label": "Email ID",
//               "placeholder": "Enter you email id",
//               "applyMaskOn": "change",
//               "tableView": true,
//               "validate": {
//                   "required": true,
//                   "customMessage": "    <div class=\"col-sm-3\"></div>",
//                   "minLength": 2,
//                   "maxLength": 20
//               },
//               "errorLabel": "Name field can not be empty !",
//               "key": "firstName2",
//               "type": "textfield",
//               "input": true
//           },
//           {
//               "label": "Password",
//               "placeholder": "**********",
//               "applyMaskOn": "change",
//               "tableView": false,
//               "case": "mixed",
//               "truncateMultipleSpaces": true,
//               "validate": {
//                   "required": true,
//                   "minLength": 8,
//                   "maxLength": 15
//               },
//               "key": "password",
//               "type": "password",
//               "input": true,
//               "protected": true
//           },
//           {
//               "type": "button",
//               "label": "Submit",
//               "key": "submit",
//               "disableOnInvalid": true,
//               "input": true,
//               "tableView": false
//           }
//       ]
//   }

  backToForm(){
    this.router.navigate(['/ipsa-forms'],{queryParams:{
      formName: this.urlData?.formName
    }});
  }
  backToPrevious(){
    this.router.navigate(['/ipsa-forms-list'],{queryParams:{
      formName: this.urlData?.formName
    }});
  }

  prefillJSON:any =
  {
      "_id": "5fc7c4082a992abe68b247b9",
      "owner": "5fc7beeb2a992abe68b2475c",
      "roles": [],
      "_vid": 0,
      "_fvid": 1,
      "state": "submitted",
      "data": {
        "name": "john",
        "emailId": "IPPArk"
      },
      "access": [],
      "form": "5fc7c25a2a992abe68b247b4",
      "project": "5fc7befe2a992abe68b24765",
      "externalIds": [],
      "created": "2020-12-02T16:42:48.128Z",
      "modified": "2020-12-02T16:42:48.129Z"
  }

  scrollToSection(id:string){
    console.log("click", id)
    document.getElementsByClassName(`formio-component-${id}`)[0].scrollIntoView()
  }

  openSnackBar(data: string[], duration: number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }
}
