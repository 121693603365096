<img src="../../../assets/images/login-close.svg" class="close-btn position-absolute" (click)="close()" alt="">
<div class="card login_form border-0">
    <!-- <div class="card-body"> -->
    <div class="position-relative">
        <h2 class="mb-4 text-center fw-bold fs-32 login_title">Welcome to IDH-INSTEP</h2>
    </div>
    <div class="">
        <div class="col-12">
            <div class="d-flex align-items-center mb-4">
                <!-- <span>
                    <img src="../../../assets/images/user-icon.png" class="img-fluid me-2" alt="">
                </span> -->
                <div class="fw-bold">Existing Members</div>
            </div>

            <form action="" (ngSubmit)="loginuser()">
                <div class="position-relative py-2">
                    <div class="user-input border-end position-absolute">
                        <img src="../../../assets/images/user-input.svg" class="img-fluid" alt="">
                    </div>
                    <mat-form-field appearance="outline" class="example-full-width w-100 mb-0 pb-0">
                        <mat-label>Email Id *</mat-label>
                        <input type="name" placeholder="example@gmail.com" matInput [(ngModel)]="username" [ngModelOptions]="{standalone: true}" (input)="validateMethod()">
                    </mat-form-field>
                    <span class="login-invalid p-2 f-12" *ngIf="isEmail"> Please enter valid email.</span>
                </div>
               

                <div class="position-relative py-2">
                    <div class="user-input border-end position-absolute">
                        <img src="../../../assets/images/lock-password.svg" class="img-fluid" alt="">
                    </div>
                    <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center pointerHead" type="button" (click)="showPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                src="{{show ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}"
                                class="img-fluid" alt="">
                        </button>
                    </div>
                    
                    <mat-form-field appearance="outline" class="example-full-width w-100">
                        <mat-label>Password *</mat-label>
                        <input  [type]="!this.show ? 'password' : 'text'" placeholder="Password" matInput [(ngModel)]="password" [ngModelOptions]="{standalone: true}" >
                    </mat-form-field>
                </div>

                
                <!-- <div class="form-floating mb-3"> -->
                    <!-- <div class="user-input border-end position-absolute">
                        <img src="../../../assets/images/lock-password.svg" class="img-fluid" alt="">
                    </div> -->
                    <!-- <div class="eye-input position-absolute">
                        <button class="eye-icon d-flex align-items-center" type="button" (click)="showPassword()">
                            <img src="../../../assets/images/eye-password.svg"
                                src="{{show ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}"
                                class="img-fluid" alt="">
                        </button>
                    </div> -->
                    <!-- <input type="password" class="form-control rounded-pill" id="floatingPassword"
                        [type]="!this.show ? 'password' : 'text'" placeholder="Password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" >
                    <label for="floatingPassword">Password *</label> -->
                    
                    <!-- recaptcha part -->
                    <div class="row mt-2">
                        <!-- <div class="col-sm-7 form-floating captcha_floating position-relative">
                            <input type="text" class="form-control rounded-pill mb-md-0 mb-3" id="floatingCaptcha"  placeholder="Enter captcha" [(ngModel)]="captcha">
                            <label for="floatingCaptcha" class="captcha_label">Enter Captcha</label>
                            <span *ngIf="loginFailedDueToCaptcha" class="">Please Verfiy captcha</span>
                        </div>
                        <div class="col-sm-5 justify-content-center d-flex">
                            <span class="ms-lg-4 reset-icon">
                              <i class="fa fa-refresh mt-4 pointerHead" aria-hidden="true" (click)="refreshCaptcha()"></i>
                            </span>
                            <span class="ms-4 captcha">
                              <img [src]="imageCaptcha" class="img-fluid" alt="">
                            </span>
                        </div> -->

                        <div class="position-relative col-sm-7">
                            <mat-form-field appearance="outline" class="example-full-width w-100">
                                <mat-label>Enter Captcha *</mat-label>
                                <input type="text" placeholder="Enter captcha" matInput [(ngModel)]="captcha" [ngModelOptions]="{standalone: true}" >
                                <span *ngIf="loginFailedDueToCaptcha" class="">Please Verfiy captcha</span>
                            </mat-form-field>
                        </div>
                        <div class="col-sm-5 justify-content-center d-flex">
                            <span class="ms-lg-4 reset-icon">
                              <i class="fa fa-refresh mt-4 pointerHead" aria-hidden="true" (click)="refreshCaptcha()"></i>
                            </span>
                            <span class="ms-4 captcha">
                              <img [src]="imageCaptcha" class="img-fluid" alt="">
                            </span>
                        </div>
                        
                    </div>

                    <!-- recaptcha part -->
                <!-- </div> -->
                <div class="fw-500 text-end mb-3">
                    <a  class="text-decoration-none fs-14 forget pointerHead" (click)="openForgotDialog()">Forgot
                        Password?</a>
                </div>

                <button class="btn login-btn w-100 mb-2 text-white rounded-pill" type="submit" [disabled]="!(username?.length && password?.length && captcha?.length)">Login</button>
                <div class="fs-15 fw-600 text-center">New User? <span class="pointer" style="color: #00625F;" (click)="openSignUpForm()">Register</span></div>
            </form>
        </div>

        <!-- <div class="col-auto">
            <div class="vertical-divider">
                <div class="center-element">OR</div>
            </div>
        </div>

        <div class="col md-5">
            <div class="d-flex align-items-center mb-5">
                <span>
                    <img src="../../../assets/images/user-icon.png" class="img-fluid me-2" alt="">
                </span>
                <div class="fw-bold">New Members</div>
            </div>

            <div class="position-relative d-flex rounded-pill align-items-center mb-3">
                <img src="../../../assets/images/user-icon.png" class="img-fluid user-btn position-absolute" alt="">
                <button class="btn border w-100 rounded-pill btn-height text-white social_hover" (click)="openSignUpForm()">Signup</button>
            </div>

            <div class="position-relative d-flex align-items-center mb-3">
        <img src="../../../assets/images/google.png" class="img-fluid user-btn position-absolute" alt="">
        <button class="btn border w-100 rounded-pill btn-height google-btn">Continue with Google</button>
       </div>

       <div class="position-relative d-flex align-items-center mb-3">
        <img src="../../../assets/images/fb-icon.svg" class="img-fluid user-btn position-absolute" alt="">
        <button class="btn border w-100 rounded-pill btn-height fb-btn">Continue with Facebook</button>
       </div>

       <div class="position-relative d-flex align-items-center mb-3">
        <img src="../../../assets/images/apple.svg" class="img-fluid user-btn position-absolute" alt="">
        <button class="btn border w-100 rounded-pill btn-height apple-btn">Continue with Apple</button>
       </div>

        </div> -->
    </div>
</div>
<!-- </div> -->