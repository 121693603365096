import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from '../services/common-service.service';

@Component({
  selector: 'app-ourpartners',
  templateUrl: './ourpartners.component.html',
  styleUrls: ['./ourpartners.component.scss']
})
export class OurpartnersComponent implements OnInit {

  partnerData = [
    {
      'name': 'Partner 1',
      'id' : 1,
      'desc': "Indicates the conformance level of supplier's chemical inventory list as per conformant chemical products registered in the ZHDC gateway and level of conformance to ZDHC MRSL 1.Not evaluated: Products are not uploaded in ZDHC gateway. This also includes products that have been registered along with valid SDS details to the ZDHC gateway, however, do not have an implied MRSL compliance level. 2. Level 0: The chemical formulator and product SDS are uploaded to the gateway and the formulator has submitted a self-declaration of ZDHC MRSL. 3. Level 1: The chemical formulator has provided a third party review of documents or an analytical testing report for the product where the data meet QA and QC requirements in the MRSL Conformance Guidance to be accepted as evidence of conformance. 4. Level 2: All requirements of Level 1 passed and passed a review of the product stewardship practices of the chemical supplier by a third-party certifier. 5. Level 3: All requirements of Level 2 passed and passed a site visit to the chemical formulator to evaluate their stewardship first-hand."
    },
    {
      'name': 'Partner 2',
      'id' : 2,
      'desc': "‘Goods’ refer to the tangible products or items that are manufactured by the factories within the park. This is specific to the manufacturing process operated in a particular factory and can include a variety of items such as raw materials, finished fabrics, packaged goods etc. ’Goods produced’ refer to the sum total of all good produced by each individual factory operating within the industrial park"
    },
    {
      'name': 'Partner 3',
      'id' : 3,
      'desc': "Remediation Plans are Routinely Suggested to Ips To Enable Them to Meet Global Sustainable Production Standards. Remediation Measures Suggeste Include Improving Safety Measures, Adopting Clean Energy Technologies, Providing Worker Trainings, Etc"
    },
    {
      'name': 'Partner 4',
      'id' : 4,
      'desc': "Refers to Total Volume of Goods Produced By Ips Labelled as 'Fully IPSA Conformant */ Have Achieved A Score of 3 as per The Industrial Park Sustainability Assessment"
    },
  ]
  ourPartners: any = [];
  constructor(private commonService: CommonServiceService) { }

  ngOnInit(): void {
    this.getOurPartnerData();
  }

  getOurPartnerData(){
    this.commonService.ourPartnerPageData().subscribe((res:any) => {
      this.ourPartners = res?.data;
      console.log("partner",this.ourPartners);
    })
  }
  stripHtmlTagsForCard(input: any,limit:number): string {
    const doc: any = new DOMParser().parseFromString(input, 'text/html');
  
    // Extract plain text content without HTML tags
    const textContent = doc.body.textContent || "";
  
    // Truncate text content to a limit of 150 characters
    let truncatedText :string = ''
    if(limit > 0){
      truncatedText  = textContent.length > limit ? textContent.substring(0, limit) + '...' : textContent;
    }
    else{
      truncatedText = textContent;
    }
   
    return truncatedText;
  }
}
