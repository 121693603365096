import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { CommonServiceService } from './pages/services/common-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  // modulePermission:string[] = ['cms','category','impactstories','glossary','inquiry','teammember','partners','role','authenication','subscribe','category','subcategory','subcategoryreport'];
  modelePermission:any[] = [
    {model:'baselineform',state:'/admin-forms'},
    {model:'role',state:'/manage-user'},
    {model:'role',state:'/user-level'},
    {model:'cms',state:'/manage-portal'},
    {model:'subscribe',state:'/subscribers'},
    {model:'role',state:'/user-policies'},
    {model:'user',state:'/add-user'},
  ]
  withoutPermissionModule:string[] = ['home','dashboard','resources','impact-stories','about-us','ourpartners']
  baselineFormLink:string[] = ['/forms-list','/admin-forms','/baseline-monthly-form','/program-form','/baseline-form']
  managePortalPermission:string[] = ['cms','category','impactstories','glossary','inquiry','teammember','partners']
  permissionJson :any =  JSON.parse(sessionStorage.getItem('userPermission')!)
  constructor(private authService: AuthGuardService, private router: Router,private commonService:CommonServiceService) {}
 
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (!this.authService.isLoggedIn() && !sessionStorage.getItem("userPermission")) {
        this.router.navigate(['/home']); // go to login if not authenticated
        return false;
      }
      else if(sessionStorage.getItem("userPermission")){
        return true;
        console.log("ActivatedRouteSnapshot",state)
        let pathName:string = state.url;
        if(pathName.includes('?')){
          let getPath = pathName.split('?');
          pathName = getPath[0]
        }
        pathName = pathName.replace(/\/\d+$/, '')
        if(this.withoutPermissionModule.includes(pathName)){
          return true;
        }
        else{
          let isAdminForms:boolean = this.commonService.direactUrlAccess(['baselineform','programform'],this.permissionJson[0])
          console.log("path",isAdminForms)
          if(isAdminForms && this.baselineFormLink.includes(pathName)){
            return true
          }
          if(pathName === '/manage-portal'){
            let isManagePortal =  this.commonService.direactUrlAccess(this.managePortalPermission,this.permissionJson[0])
            if(isManagePortal){
              return true
            }
          }
          let checkPermissionForModuleName = this.modelePermission.find(el => pathName == el.state);
          let checkPermission = this.commonService.direactUrlAccess([checkPermissionForModuleName?.model], this.permissionJson[0]);
          if (!checkPermission) {
            this.router.navigate(['/home']);
            return false;
            
        }
        }
        return true;
      }
    return true;
  }
}
