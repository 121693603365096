<div class="Footer_bottom">
    <section>
        <div class="container-fluid">
            <div class="my-3 d-flex align-items-center justify-content-between">
                <h4 class="fs-18 fw-600 m-0">User Role</h4>
                <!-- <button class="btn rounded-pill text-white" style="background-color: #00625F;" tabindex="0" (click)="redirectUserRoleCreate()" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'create' && buttonPermission?.isAction)">+ Add User Level</button> -->
                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                    <button class="btn rounded-pill text-white " style="background-color: #00625F;" tabindex="0" (click)="redirectUserRoleCreate()" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'create' && buttonPermission?.isAction)">+ Add User Role</button>
                </ng-container>
            </div>
        </div>
    </section>
    <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
        <ng-container *ngIf="(buttonPermission?.key && ['list','listui'].includes(buttonPermission?.key) && buttonPermission?.isAction)">
            <app-user-management-table [tableHeading]="userTableHeading1" [tableData]="allRoleDetails" (editDetails)="tableAction($event.data,'edit')" (delete)="tableAction($event.data,'delete')" (view)="tableAction($event.data,'view')" [actionInTable]="action" (pageMethod)="pageHandler($event)" [totalCount]="totalCount" *ngIf="allRoleDetails?.length" (clearSearch)="clearSearchBox($event)" (sortMethod)="sortTable($event)"></app-user-management-table>
        </ng-container>
        <ng-container *ngIf="(buttonPermission?.key && ['list','listui'].includes(buttonPermission?.key) && !buttonPermission?.isAction)">
            <div class="d-flex justify-content-center m-5">
                Permission not allowed to view table list
            </div>
        </ng-container>
    </ng-container>
    <div class="d-flex justify-content-center align-items-center p-5" *ngIf="!allRoleDetails?.length">
        <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
    </div>
</div>