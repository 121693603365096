import { Component, ElementRef, Input, OnChanges, OnInit, AfterViewInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { ConfirmationDialogComponent } from '../user-management/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit, OnChanges, AfterViewInit {
  // viewData: any;
  // @Input() viewData:any
  itemSelected:boolean = false;
  commentMsg:string = "";
  msgHighlighted:boolean = false;
  msgDeleted: boolean = false;
  dialogRef: any;
  msgUpdated: boolean = false;
  selectedMatIndex:number = 0;
  userData:any = JSON.parse(sessionStorage.getItem("userdata")|| '{}');

  pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
  currentAction: any;
  @ViewChild('accordionBody', { static: false }) accordionBody: ElementRef;

  constructor(private commonService: CommonServiceService, private snackBar: MatSnackBar, public dialog: MatDialog) { 
    this.accordionBody = new ElementRef(null); // Initialize with a default value
  }

  message_status = {
    Replied: '1',
    Pending: '2',
    Rejected: '3'
  };
  ngOnChanges(changes: SimpleChanges): void {

  }
  messageLineItemDetails:any = [];
  ngOnInit(): void {
    this.sendReplyStatus('communicationmapper','create');
  }


  ngAfterViewInit() {

  }


  timeline = [{
    action : 'Our company starts its operations',
    date : '11 March 2020',
    status : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit necessitatibus adipisci, ad alias, voluptate pariatur officia repellendus repellat inventore fugit perferendis totam dolor voluptas et corrupti distinctio maxime corporis optio?'
  },
  {
    action : 'First customer',
    date : '19 March 2020',
    status : 'Quisque ornare dui nibh, sagittis egestas nisi luctus nec. Sed aliquet laoreet sapien, eget pulvinar lectus maximus vel. Phasellus suscipit porta mattis.'
  },
]

  setApplyFiltr(event:any){
    console.log("event-id",event)
    if(event?.id){
      this.itemSelected = true;
    }
    else{
      this.itemSelected = false;
    }
    
  }
  recievedData(event:any) {
    if(Object.keys(event).length){
      this.itemSelected = true;
    }
    console.log("Event ", event);
    this.messageLineItemDetails = event;
    console.log("this.messageLineItemDetails", this.messageLineItemDetails);
    this.msgDeleted = false;
    this.msgUpdated = false;
    // this.scrollToBottom();

    setTimeout(() => {
      let accordionScroll = document.getElementById('accordionBody')!;
      console.log("jiji",accordionScroll);
      
      accordionScroll.scrollTop += 2000;
      
    }, 2000);
    
  }

  tabSwitched(event:any){
    if(event){
      this.itemSelected = !event;
    }
  }

  replyStatus(status:string){
    this.msgUpdated = false;
    /*notifying message status constants as per backend
     Message_Status: {
       Replied: '1',
       Pending: '2',
       Rejected: '3'
     }*/
    let status_for_message:any;
    switch (status) {
      case 'replied':
        status_for_message = 1;
      break;

      case 'pending':
        status_for_message = 2;
      break;

      case 'reminder':
        status_for_message = 3;
      break;
    
      default:
        break;
    }

    let payload = {
      "communication_id": this.messageLineItemDetails.id,
      "message": this.commentMsg,
      "status_for_message": status_for_message,
      "createdBy": this.messageLineItemDetails.createdBy,
      "logCreatedBy": this.userData?.id
    }

    this.commonService.communicationmapper(payload).subscribe(res => {
      if(res.success){
        this.commentMsg = "";
        this.msgUpdated = true;
        this.openSnackBar(["Updated Successfully !"], 3000)
      }
    })    
  }

  openSnackBar(data: string[], duration: number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }

  actionsOnChat(status:any){
    this.msgHighlighted = false;
    this.msgDeleted = false;

    if(status == 'highlight'){
      this.messageLineItemDetails.is_highlight = !this.messageLineItemDetails?.is_highlight;
    }
    

    if(status == 'delete'){
      let message = "Are you sure want to delete this message ?";
       this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message,
          buttonText: {
            ok: "Yes",
            cancel: "No",
          },
        },
      });
    
      this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if(confirmed){
          this.itemSelected = false;
          this.communicationActionUpdate(status);
        }
      })
    }else{
      this.communicationActionUpdate(status);
    }
  }

  communicationActionUpdate(status:any){
    let payload = {
      "subject": this.messageLineItemDetails?.subject,
      "message": this.messageLineItemDetails?.message,
      "sender_id": this.messageLineItemDetails?.sender_id,
      "receiver_id": this.messageLineItemDetails?.receiver_id,
      "createdBy": this.messageLineItemDetails?.createdBy,
      "is_highlight": this.messageLineItemDetails?.is_highlight,
      "isActive" : status == 'delete' ? false : true
    };
    this.commonService.communicationUpdate(this.messageLineItemDetails.id, payload).subscribe(res => {
      if (res.success) {
        console.log("allress",res);
        
        if (status == 'highlight') {
            this.msgHighlighted = true;
            if(res.data.is_highlight){
              this.openSnackBar(["Message Highlighted !"], 3000);
            }else{
              this.openSnackBar(["Message Unhighlighted !"], 3000);
            }
        }else{
            this.msgDeleted = true;
            this.openSnackBar(["Message Deleted Successfuly !"], 3000);
        }
      }
    })
  }

  hasActionTrueForModel(modelName:string='', key:string= '') {
    // Find the object with the specified modelName
    if(this.pageAction && this.pageAction[0] && this.pageAction[0].authenicationJson){
    const modelObject = this.pageAction[0].authenicationJson.find((obj:any) => obj.model === modelName);
    
    // If the object is found and it has an authentication array
    if (modelObject && Array.isArray(modelObject.authentication)) {
        // Check if any action is true in the authentication array
        return modelObject.authentication.some((auth:any) => auth.isAction === true);
    }
  }
    
    // If the object is not found or it does not have an authentication array
    return false;
}

/**
 * The function `sendReplyStatus` checks if the key is 'create' and sets the current action based on
 * the authentication data for the specified model.
 * @param {any} modelName - The `modelName` parameter in the `sendReplyStatus` function is used to
 * specify the name of the model for which you want to retrieve authentication information.
 * @param {any} key - The `key` parameter is used to determine the type of action being performed. In
 * this case, it is checking if the key is equal to 'create'.
 */
sendReplyStatus(modelName:any, key:any){
  if(key == 'create'){
    const currentModelName = this.pageAction[0]?.authenicationJson.find((ele:any) => ele?.model === modelName)
     currentModelName.authentication.forEach((data:any) => {
      if( data?.key == key){
        this.currentAction =  data?.isAction;
      }
    })
  }
}

tabWiseData(event:any){
  this.selectedMatIndex = event?.index;
}
  
}
