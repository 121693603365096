<ng-container>
    <div class="">
        <div class="my-2">
            <mat-tab-group class="mat-tab-mb mat-custum-tab" [selectedIndex]="selectedIndex"
                (selectedTabChange)="tabWiseData($event)">
                <mat-tab label="Admin"> 
                    <ng-container [ngTemplateOutlet]="tableView"></ng-container>
                </mat-tab>
                <mat-tab label="Assessor">
                    <ng-container [ngTemplateOutlet]="tableView"></ng-container>
                </mat-tab>
                <mat-tab label="IP List" *ngIf="userData?.userlevel_id != 5">
                    <ng-container [ngTemplateOutlet]="tableView"></ng-container>
                </mat-tab>
                <mat-tab label="Factory List" *ngIf="userData?.userlevel_id != 7"> 
                    <ng-container [ngTemplateOutlet]="tableView"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</ng-container>

<ng-container>
    <ng-template #tableView>
        <div class="card border-0 shadow-sm mb-3">
            <div class="card-body">
                <div class="row flex-md-row flex-column-reverse align-items-center mb-3 justify-content-between">
                    <div class="col">
                        <mat-paginator class="f-14 rounded-3 paginate" [length]="totalDataCount" [pageSize]="limit"
                            aria-label="Select page" (page)="setPage($event)" showFirstLastButtons
                            *ngIf="totalDataCount !== 0 && allResponseData?.length"></mat-paginator>
                    </div>
                    <div class="col-md-auto searchbar d-flex">
                        <div class="position-relative">
                            <img src="../../../assets/images/search-line.svg"
                                class="img-fluid position-absolute search-icon" alt="">
                            <input type="text" placeholder="Search" class="form-control search-input me-2"
                                (keydown.enter)="onKeyDownEvent()" [(ngModel)]="searchedValue">
                        </div>
                        <button class="btn btn-success btn-sm clearSearchBtn" (click)="clearSearch()"
                            *ngIf="searchedValue">Clear</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-borderless">
                        <thead class="table-light">
                            <tr class="py-2 border-bottom">
                                <th class="fw-600 border-end border_end_0"
                                    [ngClass]="{'borderEnd' : item.thead == 'Contact'}" *ngFor="let item of tableHead">
                                    {{item?.thead}}
                                    <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp"
                                        *ngIf="item?.key " (click)="sortTable(item?.key, 'up')" matTooltip="Ascending"
                                        [ngStyle]="{'opacity': sortSelection == 'up' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                    <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown"
                                        *ngIf="item?.key" (click)="sortTable(item?.key, 'down')" matTooltip="Descending"
                                        [ngStyle]="{'opacity': sortSelection == 'down' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom align-middle" *ngFor="let item of allResponseData; let i = index">
                                <td class="border-end py-3">{{i+1}}</td>
                                <td class="border-end py-3">{{item?.name}}</td>
                                <td class="border-end py-3" *ngIf="labelName == 'IP List' || labelName == 'Factory List'  ">{{labelName == 'IP List' ?
                                    item['country.name'] : labelName == 'Factory List' ?
                                    item['industrialpark.name'] : ""}}</td>
                                <td class="border-end py-3" *ngIf="labelName == 'Factory List'">
                                    {{item['industrialpark.country.name']}}</td>
                                <td class="py-3">
                                    <svg (click)="openContactDialog(item)" class="pointer" id="email_black_24dp_2_"
                                        data-name="email_black_24dp(2)" xmlns="http://www.w3.org/2000/svg" width="20"
                                        height="20" viewBox="0 0 20 20">
                                        <path id="Path_7890" data-name="Path 7890" d="M0,0H20V20H0Z" fill="none" />
                                        <path id="Path_7891" data-name="Path 7891"
                                            d="M17.009,4H3.668A1.666,1.666,0,0,0,2.008,5.669L2,15.684a1.673,1.673,0,0,0,1.668,1.669H17.009a1.673,1.673,0,0,0,1.668-1.669V5.669A1.673,1.673,0,0,0,17.009,4Zm0,3.338-6.671,4.173L3.668,7.338V5.669l6.671,4.173,6.671-4.173Z"
                                            transform="translate(-0.338 -0.676)" fill="#47D985" />
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="noDataFoundImg" *ngIf="!allResponseData.length">
                        <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>