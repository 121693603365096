import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @ViewChild('lineChartCanvas') lineChartCanvas!: ElementRef<HTMLCanvasElement>;
  private lineChartContext!: CanvasRenderingContext2D;
  @Input() data:any[] = []
  customData: any = [];
  customLabel: any = [];
  colorIndex = 0;
  lineChartData: any = [];
  constructor() { }

  ngOnInit(): void {
    console.log("line",this.data)
    // if(this.data?.length){
    //   this.getDataForChart(this.data);
    //   this.getLabelForChart(this.data);
    //   this.lineChart();
    // }
  }
  ngAfterViewInit(){
    this.lineChart(this.data);
  }

  ngOnChanges(changes:SimpleChanges){
    if(changes?.data?.currentValue){
      this.data = changes.data.currentValue;
      this.lineChartCanvas?.nativeElement?.getContext('2d') && this.lineChart(this.data);
    }
  }

  lineChart(data:any[]) {
    let labels = this.customLabel;
    this.lineChartContext = this.lineChartCanvas.nativeElement.getContext('2d')!;
   
    let datasets = this.dataSetMethods(data);
    this.customLabel =  ['', ...this.customLabel];
    console.log("dataset",datasets, this.customLabel)
    // console.log("dataset",this.customLabel)
    
     new Chart(this.lineChartContext, {
      type: 'line',
      data: {
        // labels: labels.length ? labels : ['2019', '2020', '2021', '2022', '2023'],
        // datasets: [
        //   {
        //     label: 'Numbers of Votes',
        //     data: [1.9, 1.8, 0.5, 0.3, 0.2],
        //     borderColor: this.generateRandomColor(),
        //     borderWidth: 1
        //   },
        //   {
        //     label: 'Numbers of Votes',
        //     data: [0.9, 0.8, 0.5, 1.3, 1.2],
        //     borderColor: this.generateRandomColor(),
        //     borderWidth: 1
        //   }
        // ]
        labels :this.customLabel,
        datasets
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
                pointStyle: "circle",
                padding:15,
            boxWidth: 8,
            font: {
              size: 11
            }
            }
          },
          datalabels: {
            display: false,
            radius:10
          },
          title: {
            display: true,

          },
        },
        elements: {
          point: {
            radius:5
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Year'
            },
            grid: {
              display: true,
            },
            beginAtZero: false,
          },
          y: {
            title: {
              display: true,
              text: 'Value'
            },
            ticks:{
              precision: 0
            },
            grid: {
              display: true,
            },
             border: {
               dash: [2,4],
             }, 
            beginAtZero: false,
            
          }
        }
      }
    })
  }

 dataSetMethods(data:any[]) {
  const fixedColors = ['#2EB2AA','#458AAD','#BE7FDB','#58B22E','#DB8A7F','#66BEFE','#FED766','#6DBC48','#7F85DB','#FE66C1',"#00A86B","#00625F", "#008080","#4169E1","#4682B4"];
  let dataSets:any[] = [
  ];
  let labelObj:any = {};
  data.forEach((el:any, index) => {
    let color = fixedColors[index];
    let obj:any = {
      backgroundColor: color,
      borderColor: color,
      barThickness: 60, 
      maxBarThickness: 75,
    };
    obj["label"] = el.question_label || el.name;
    let values = el.values.sort((a:any,b:any) => a.financial_year-b.financial_year);
    obj["data"] = values.map((item:any) => {
      labelObj[item.financial_year] = 1;
      return item.total_count;
    });

    obj["data"] = ['', ...obj["data"]];

    dataSets.push(obj);
  });
  this.customLabel = Object.keys(labelObj).sort((a,b) => Number(a)-Number(b));
  return dataSets;
}

 generateRandomColor(): string {
  const fixedColors = ['#2EB2AA','#458AAD','#BE7FDB','#58B22E','#DB8A7F','#66BEFE','#FED766','#6DBC48','#7F85DB','#FE66C1'];
  const color = fixedColors[this.colorIndex];
  this.colorIndex = (this.colorIndex + 1) % fixedColors.length; // Cycle through the colors
  return color;
}

randomColorSet(){
  var color = Math.floor(0x1000000 * Math.random()).toString(16);
  return '#' + ('000000' + color).slice(-6);
}

firstLetterCaptial(value:string):string{
  if (value) {
    let sentence:any = value.split('_');
    return sentence.map((word:string) => word.charAt(0).toUpperCase()+word.slice(1)).join(' ')
  }
  return '';
}

}



