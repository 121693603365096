import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ipsa',
  templateUrl: './ipsa.component.html',
  styleUrls: ['./ipsa.component.scss']
})
export class IpsaComponent implements OnInit {
  isShowReport: boolean = false;

  constructor(public router: Router) { let pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
  let currentBaseProgramForm = this.getCurrentProgramFormMethod(pageAction[0])
  
  this.allowedAction = pageAction[0].authenicationJson.filter((el:any)=>{
    if(el?.model === currentBaseProgramForm.model) return el;
  });
  this.isShowReport = this.allowedAction.length ? this.allowedAction[0]['authentication'].find((auth:any) => ['report','submissionlist'].includes(auth.key) && auth.isAction) : false;
  console.log("baseForm",this.allowedAction,currentBaseProgramForm);

}


  ngOnInit(): void {
  }

  formJson:any = [
    {
      id: 1,
      formName : "Introduction to the IPSA",
      image: "../../../assets/images/ipsa-icon.svg",
    },
    {
      id: 2,
      formName : "IPSA V1.1 Forms",
      image: "../../../assets/images/ipsaForms-icon.svg",
    },
    {
      id: 3,
      formName : "IPSA Dashboard",
      image: "../../../assets/images/ipsaDashboard-icon.svg",
    }
  ];

  baseFormModule : string[] = ['ipsa'];
  allowedAction :any = [];

  toggle(data:any){
    console.log("data", data)
    if(data.id == 3){
      this.router.navigate(['/ipsa-dashboard']);
    }else if(data.id == 1){
      this.router.navigate(['/ipsa-intro']);
    }else{
      this.router.navigate(['/ipsa-forms']);
    }
      
  }

  getCurrentProgramFormMethod(data: any) {
    let getCurrentTab: any = null; 
  
    this.baseFormModule.some((el: any) => {
      getCurrentTab = data.authenicationJson.find((e: any) => {
        return e.model === el &&
          (e.isAction || e.is_checked || (e.authentication && e.authentication.some((inside: any) => inside.isAction)));
      });
  
      return getCurrentTab !== undefined; 
    });
  
    return getCurrentTab;
  }

}
