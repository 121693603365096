<!-- <div class="card border-0">
    <div class="card-body"> -->
        <img src="../../../assets/images/login-close.svg" alt="" mat-dialog-close="" class="close-btn position-absolute">
        <div class="d-flex mb-3 align-items-center justify-content-between pt-3 pb-1 border-bottom" style="padding: 20px 20px 10px 20px">
            <h6 class="fs-16 fw-600">Contact {{data.lineItemName}}</h6>
            <!-- <img src="../../../assets/images/cross-icon.svg" class="img-fluid" mat-dialog-close alt=""> -->
        </div>

        <div style="padding: 10px 20px 20px 20px;">

            <form [formGroup]="contactForm">
                <div class="form-floating mb-3">
                    <input type="text" class="form-control " id="floatingInput" formControlName="name" placeholder="Name" [attr.disabled]="userData">
                    <label for="floatingPassword">Name *</label>
                    <p class="text-danger fs-12 ms-3 mt-2" *ngIf="(contactForm.get('name')?.touched  || contactForm.get('name')?.dirty) && contactForm.get('name')?.invalid">Name is required *</p>
                </div>
                <div class="form-floating mb-3">
                    <input type="email" class="form-control " id="floatingInput" formControlName="email" placeholder="Email" [attr.disabled]="userData">
                    <label for="floatingInput">Email *</label>
                    <!-- <p class="text-danger fs-12 ms-3 mt-2" *ngIf="(contactForm.get('email')?.touched  || contactForm.get('email')?.dirty) && contactForm.get('email')?.invalid">Email is required *</p> -->
                    <p class="text-danger fs-12 ms-3 mt-2" *ngIf="contactForm.get('email')?.hasError('required') && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)">Email is required *</p>
                    <!-- <p class="text-danger fs-12 ms-3 mt-2" *ngIf="contactForm.get('email')?.hasError('email') && !contactForm.get('email')?.hasError('required') && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)">Email must be a valid email address *</p> -->
                    <p class="text-danger fs-12 ms-3 mt-2" *ngIf="contactForm.get('email')?.hasError('email') && !contactForm.get('email')?.hasError('required') && (contactForm.get('email')?.touched || contactForm.get('email')?.dirty)">
                        Email must be a valid email address *
                      </p>
                </div>

                <div class="form-floating mb-3">
                    <input type="text" class="form-control " id="floatingInput" formControlName="subject" placeholder="Name">
                    <label for="floatingPassword">Subject *</label>
                    <p class="text-danger fs-12 ms-3 mt-2" *ngIf="(contactForm.get('subject')?.touched  || contactForm.get('subject')?.dirty) && contactForm.get('subject')?.invalid">Subject is Required *</p>
                </div>
                <div class="form-floating mb-3">
                    <select class="form-select "  id="floatingSelect" aria-label="Floating label select example" formControlName="from">
                      <option *ngFor="let option of dropdownOptions" [value]="option.value">{{option?.label}}</option>
                    </select>
                    <label for="floatingSelect">Type of user *</label>
                    <p class="text-danger fs-12 ms-3 mt-2" *ngIf="(contactForm.get('from')?.touched  || contactForm.get('from')?.dirty) && contactForm.get('from')?.invalid">From is required *</p>
                  </div>

                    <div class="form-floating mb-3">
                        <textarea class="form-control" placeholder="Leave a comment here" formControlName="message" id="floatingTextarea" style="resize: none; height: 155px; border-radius: 12px;"></textarea>
                        <label for="floatingTextarea">Query *</label>
                        <p class="text-danger fs-12 ms-3 mt-2" *ngIf="(contactForm.get('message')?.touched  || contactForm.get('message')?.dirty) && contactForm.get('message')?.invalid">Message is required *</p>
                    </div>
                <div class="text-end">
                    <button class="btn submit-btn me-2 rounded-pill text-white" (click)="resetForm()" style="background-color: #00625F; width: 102px; height: 48px;">Reset</button>
                    <button class="btn submit-btn rounded-pill text-white" (click)="formSubmit()" [disabled]="contactForm.invalid" style="background-color: #00625F; width: 102px; height: 48px;">Submit</button>
                </div>
            </form>
        </div>
    <!-- </div>
</div> -->