<div class="Footer_bottom">
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="d-md-flex justify-content-between my-3">
                        <h4 class="fs-18 fw-600">User Management</h4>
                        <div class="d-flex align-items-center">
                                <img src="../../../assets/images/download-form.svg" class="img-fluid pointer me-4 d-none" alt="" >
                                <button class="filter-dropdown rounded-pill me-3 d-none" mat-button [matMenuTriggerFor]="menu" >
                                    <span class="">
                                        <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                            <g id="Group_16821" data-name="Group 16821" transform="translate(-4157 -790)">
                                              <path id="Path_6201" data-name="Path 6201" d="M0,0H20V20H0Z" transform="translate(4157 790)" fill="none"/>
                                              <path id="Path_6202" data-name="Path 6202" d="M18,4V5.667h-.833L13,11.917V19H8V11.917L3.833,5.667H3V4ZM5.837,5.667l3.83,5.745v5.922h1.667V11.412l3.83-5.745Z" transform="translate(4156.5 789.333)"/>
                                            </g>
                                          </svg>
                                          
                                    </span>
                                    Filters</button>
                                <mat-menu #menu="matMenu">
    
                                </mat-menu>
                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                    <button class="btn rounded-pill text-white" routerLink="/add-user" style="background-color: #00625F;" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'create' && buttonPermission?.isAction)">+ Add User</button>
                                </ng-container>
    
                        </div>
                    </div>
                </div>
            </div>
    
            <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                <ng-container *ngIf="(buttonPermission?.key && buttonPermission?.key === 'list' && buttonPermission?.isAction)">
                    <app-user-management-table [tableHeading]="tableHeading1" [tableData]="manageUsertableData1" (editDetails)="tableAction($event.data,'edit')" (delete)="tableAction($event.data,'delete')" (view)="tableAction($event.data, 'view')"  [totalCount]="totalCount" *ngIf="manageUsertableData1?.length" [actionInTable]="action" (pageMethod)="pageHandler($event)" (clearSearch)="clearSearchBox($event)" (sortMethod)="sortTable($event)"></app-user-management-table>
                </ng-container>
                <ng-container *ngIf="(buttonPermission?.key && buttonPermission?.key === 'list' && !buttonPermission?.isAction)">
                    <div class="d-flex justify-content-center m-5">
                        Permission not allowed to view table list
                    </div>
                </ng-container>
            </ng-container>
    
            <div class="d-flex justify-content-center align-items-center p-5" *ngIf="!manageUsertableData1?.length">
                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
            </div>
        </div>
    </section>
</div>