import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserWelcomeComponent } from 'src/app/layout/footer/user-welcome/user-welcome.component';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-program-form',
  templateUrl: './program-form.component.html',
  styleUrls: ['./program-form.component.scss']
})
export class ProgramFormComponent implements OnInit {
  programForm!: FormGroup;
  finalBody: any = {};
  urlData: string = "";
  routeURL: string ="";

  
  constructor(private commonService: CommonServiceService, private route: ActivatedRoute, public dialog: MatDialog, private snackBar: MatSnackBar, private router: Router
    , private formbuilder: FormBuilder,) {
      this.route.queryParams.subscribe(params => {
        this.routeURL = `/forms-list?formId=${params?.formId}`
      })
      
      this.programForm = this.formbuilder.group({
        month: ['', Validators.required],
        financial_year: ['', Validators.required],
        // countries_with_ip: ['', Validators.required],
        // industrial_parks_in_projects: ['', Validators.required],
        // ta_factories_in_ips: ['', Validators.required],
        // male_workers_in_instep: ['', Validators.required],
        // female_workers_in_instep: ['', Validators.required],
        // implementing_partners_with_idh: ['', Validators.required],
        // multistakeholder_agreements: ['', Validators.required],
        // investments_raised: ['', Validators.required],
      })

      
      // this.getProgramFormQues()
    }
  formQuestions: any = [];
  
  months = [
    {
      month: 'January',
      value: 'January'
    },
    {
      month: 'February',
      value: 'February'
    },
    {
      month: 'March',
      value: 'March'
    },
    {
      month: 'April',
      value: 'April'
    },
    {
      month: 'May',
      value: 'May'
    },
    {
      month: 'June',
      value: 'June'
    },
    {
      month: 'July',
      value: 'July'
    },
    {
      month: 'August',
      value: 'August'
    },
    {
      month: 'September',
      value: 'September'
    },
    {
      month: 'October',
      value: 'October'
    },
    {
      month: 'November',
      value: 'November'
    },
    {
      month: 'December',
      value: 'December'
    }
  ];

  years = [
    {
      year: '2020-2021',
      value: '2020-2021'
    },
    {
      year: '2021-2022',
      value: '2021-2022'
    },
    {
      year: '2022-2023',
      value: '2022-2023'
    },
    {
      year: '2023-2024',
      value: '2023-2024'
    },
  ];
  
  selectedMonth:any = '';
  selectedYear:any = '';
  baseUrl:string ='programform/view?formId=';
  viewFormId:number = 1;
  createForm:string = "programform/";
  prefillForm:string = "programform/list";
  updateForm:string = "programform/update";
  
  ngOnInit(): void {
    // this.getProgramFormQues();
    // let a = new Date().getMonth() + 1;
    // console.log(a);    
  }
  
   getProgramFormQues(){
      this.commonService.getFormQuestions(this.baseUrl, this.viewFormId).subscribe(res => {
      this.formQuestions = res?.data;
      // let dynamicControls: any = {};
      // this.programForm.addControl("month", new FormControl("", Validators.required));
      // this.programForm.addControl("financial_year", new FormControl("", Validators.required));
    //   this.formQuestions.forEach((element:any) => {
    //     this.programForm.addControl(element.short_key, new FormControl('', Validators.required))
    // });
    
    })
  }

  // submitProgramDataForm(isDraft:any = ""){
  //   console.log("valuess", this.programForm.value);
  //   let userId = JSON.parse(localStorage.getItem('userdata')!);
  //   this.finalBody = Object.assign(this.programForm.value, {"createdBy": userId?.id})
  //   if(isDraft){
  //     this.finalBody = Object.assign(this.finalBody, {"is_draft": true})
  //   }else{
  //     this.finalBody = Object.assign(this.finalBody, {"is_draft": false})
  //   }
  //   this.commonService.submitFormAnswers(this.finalBody, "programform").subscribe(res => {
  //     if(res.success){
  //       let dialogRef = this.dialog.open(UserWelcomeComponent, {
  //         width: '500px',
  //         position: {top: '150px'},
  //         data: {flag: 'programForm'}
  //       });
  //       dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //         this.router.navigate(['/admin-forms'])
  //       });
  //     }
  //   },(error) => {this.openSnackBar(error.error.message);});
  // }

  // openSnackBar(message:any, action = '') {
  //   this.snackBar.open(message, action, {
  //     duration: 4000,
  //     verticalPosition: 'top',
  //     horizontalPosition:'right',
  //     panelClass: ['primary']
  //   });
  // }

  // numberOnly(event:any): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }


  // IPSA REPLICA fro the Program Data Form


  // sideBarData:any = [
  //   {
  //     "id": 1,
  //     "sidebarHead": "Program",
  //     "subSteppers": [
  //         {
  //           "stepperName":"Overview"   
  //         },
  //         // {
  //         //   "stepperName":"IPSA"                
  //         // }
  //     ]
  //   }
  // ];

  openFormList(){
    this.router.navigate(['/admin-forms'])
  }
  
  backPath(){
    window.history.back();
  }

}