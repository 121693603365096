import { Component, Inject, OnInit } from '@angular/core';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators, FormControlName, FormBuilder } from '@angular/forms';
import { CommonServiceService } from '../services/common-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit {
  ipListDetail:any = [];
  ipContactList:any = [];
  contactForm : FormGroup;
  userData = JSON.parse(sessionStorage.getItem('userdata')!);
  tokenCheck = sessionStorage.getItem('token');
  constructor(public dialog: MatDialog, private fb: FormBuilder , private commonService: CommonServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, public dialogRef: MatDialogRef<DashboardComponent>) { 
     
    console.log("userData",this.userData, this.tokenCheck, this.data);
    
    this.contactForm = this.fb.group({
      name : ['', [Validators.required]],
      email : ['', [Validators.required, Validators.email]],
      subject : ['', [Validators.required]],
      from : ['', [Validators.required]],
      message : ['', [Validators.required,]],
      createdBy : [this.userData && this.userData?.id ? this.userData.id : ''],
      sender_id : [this.userData && this.userData?.id ? this.userData.id : ''],
      receiver_id : [this.data?.id ? this.data?.id : '']
    });

    
  }

  dropdownOptions:any = [
    { value: '9', label: 'Buyer' },
    { value: '10', label: 'Goverment' },
    { value: '6', label: 'Public user' }
    // Add more options as needed
  ];

  userPermission:any = sessionStorage.getItem('userPermission') ? JSON.parse(sessionStorage.getItem('userPermission')!): {};
  ngOnInit(): void {
    if(this.tokenCheck){
      this.dropdownOptions = [{value: this.userPermission[0].role_id, label: this.userPermission[0]['role.name']}];
      this.contactForm.patchValue({
        name : this.userData.name,
        email : this.userData.email,
        from: this.userPermission[0].role_id
      });
    }
      

    console.log("ddd", this.dropdownOptions)
    
  }
  closeDialog(){
    this.dialogRef.close();
  }

  openSnackBar(data: string[], duration: number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }
  

  formSubmit(){
    console.log(this.contactForm.value, this.data.formType);
    if(this.userData == undefined){
      delete this.contactForm.value.createdBy ;
      delete this.contactForm.value.sender_id ;
    }
    this.commonService.ipListDetail(this.contactForm.value, this.data.formType).subscribe((res:any)=>{
      if(res.success){
        this.ipContactList = res.data
        console.log(res);
        this.closeDialog();
        this.openSnackBar(['Message sent successfully !'], 3000);
      }
    })
    
  }

  resetForm() {
    console.log(this.contactForm.value)
    let controlNames = ['subject', 'message'];
    if(!this.tokenCheck){
      this.contactForm.reset();
    }else{
      // controlNames.map((values: string) => this.contactForm.value[values]).reset()
      controlNames.forEach((controllers: string) => {
        let control = this.contactForm.get(controllers);
        if (control) {
          control.reset();
        }
      });
    }
  }
  get f() {
    return this.contactForm.controls;
  }



}
