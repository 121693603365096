<div class="card mb-3 border-0 shadow-sm">
    <div class="card-body">
        <div class="d-md-flex mb-3 justify-content-between align-items-center">
            <div>
                <mat-paginator class="f-14 rounded-3 mb-3 pagination" [length]="totalLength" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons 
                *ngIf="totalLength !== 0 && tableData?.data.length" ></mat-paginator>
            </div>
            <!-- <div class="position-relative"> -->
                <div class="d-flex justify-content-end mb-3">
                    <div class="position-relative">
                        <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-icon" alt="">
                        <input [autofocus]="true" (keydown.enter)="serByName($event)"  type="text" placeholder="Search by" [(ngModel)]="searchString" class="form-control search-input me-3"/>
                    </div>
                    <!-- *ngIf="searchString" -->
                    <div class="" >
                        <button class="btn btn-success btn-sm clearSearchBtn" (click)="clearFilter()" *ngIf="searchString">Clear</button>
                    </div>
                </div>
            <!-- </div> -->
        </div>
        <section>
            <div class="table-responsive">
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th *ngFor="let item of headers">
                                {{item?.display_name}}
                                <!-- <i class="fa fa-fw fa-sort pointerHead" (click)="sortTable(item?.key)">
                                </i> -->
                                <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="item?.key " (click)="sortTable(item?.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="item?.key" (click)="sortTable(item?.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                            </th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="tableData?.data?.length">
                        <tr class="border-bottom" *ngFor="let row of tableData?.data; let rowIndex = index;">
                            <td>
                                {{skip+rowIndex + 1}}
                            </td>
                            <td *ngFor="let column of headers; let j = index;">
                                <!-- {{column?.key}}   {{row[column?.key]}} -->
                                <span *ngIf="column?.display_name != 'Country'">
                                <!-- <img src="{{row?.country_icons}}" alt=""> -->
                                </span>
                               <ng-container *ngIf="column?.key != 'createdAt';else date">
                               <span *ngIf="column?.display_name != 'Country';else withImg"> {{row[column?.key]}}</span> 
                               </ng-container> 
                               <ng-template #date>{{row[column?.key] | date: 'dd/MM/yyyy'}}</ng-template>
                               <ng-template #withImg>
                                <img src="{{row?.country_icons}}" alt="" width="20px" height="20px" class="me-2">
                                {{row[column?.key]}}
                               </ng-template>
                                <!-- {{column?.key}} -->
                            </td>
                            <td>
                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                    <ng-container *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                        <img [src]="editAction?.imgIcon || ''" [matTooltip]="editAction?.toolTip || ''"  class="fontSize p-2 pointer" (click)="openForm($event , editAction, row)" >
    
                                        <img [src]="deleteAction?.imgIcon || ''" [matTooltip]="deleteAction?.toolTip || ''"  class="fontSize p-2 pointer" (click)="openForm($event , deleteAction, row)" >
                                    </ng-container>
                                </ng-container>
                            </td>
                
                        </tr>
                
                    </tbody>
                   
                    <!-- <div class="text-danger text-center" *ngIf="!tableData?.data?.length">
                        No Data Found!
                    </div> -->
                
                </table>
            </div>
            <div *ngIf="tableData?.data?.length < 1" class="d-flex justify-content-center">
                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
            </div>
        </section>
    </div>
</div>
