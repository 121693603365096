<!-- <div *ngIf="!userData"> -->
<div>
<!-- <ng-container>
    <section class="email-section">
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-5">
              <h5 class="mb-0 fs-24 fw-bold color-44">Get started with INSTEP</h5>
              <p class="color-44 mb-md-0 mb-3">Subscribe to us</p>
            </div>
            <div class="col-md-5">
                <form action="" (ngSubmit)="subscribeUser()">
                    <div class="form-floating position-relative">
                        <img src="../../../assets/images/rightArrow.svg" class="right-arrow position-absolute pointer" alt="" (click)="subscribeUser()" [ngStyle]="{'cursor': (userEmail && !isEmail) ? 'pointer' : 'not-allowed'}">
                        <input type="email" class="form-control border-0 email" id="floatingInput" placeholder="example@gmail.com" [(ngModel)]="userEmail" (input)="validateMethod()">
                        <label for="floatingInput">Enter Email</label>
                        <span class="footer-invalid p-2 f-12" *ngIf="isEmail">
                            Please enter valid email *
                         </span>
                      </div>
                </form>
            </div>
          </div>
        </div>
      </section>
</ng-container> -->

<footer>
    <div class="container py-2">
        <div class="row align-items-center">
            <div class="col-md-4">
                <img src="/assets/images/idh_footer_logo.png" class="img-fluid" style="max-width: 220px; height: 80px;" alt="">
            </div>

            <div class="col-md-2">
                <h5 class="fs-24 fw-bold text-white">Quick links</h5>
                    <ul class="nav flex-column mt-2">
                        <li class="nav-item">
                            <a routerLink="/home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a  routerLink="/about-us">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/impact-stories">Impact Stories</a>
                        </li>
                    </ul>
            </div>


            <div class="col-md-5 offset-md-1">
                <h5 class="mb-0 fs-24 fw-bold text-white">Get started with INSTEP</h5>
                    <p class="text-white mb-2">Subscribe to us</p>
                        <form action="" (ngSubmit)="subscribeUser()">
                            <div class="form-floating position-relative">
                                <img src="../../../assets/images/rightArrow.svg" class="right-arrow position-absolute pointer" alt="" (click)="subscribeUser()" [ngStyle]="{'cursor': (userEmail && !isEmail) ? 'pointer' : 'not-allowed'}">
                                <input type="email" class="form-control border-0 email" id="floatingInput" placeholder="example@gmail.com" [(ngModel)]="userEmail" (input)="validateMethod()">
                                <label for="floatingInput">Enter Email</label>
                                <span class="text-white fs-15 pointer privacyState" (click)="privacyStatement()"><u>Privacy Statement</u></span>
                                <span class="footer-invalid p-2 f-12" *ngIf="isEmail">Please enter valid email *</span>
                            </div>
                        </form>
            </div>

            
        </div>
    </div>
</footer>
<div class="back-to-top" (click)="scrollToTop()">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/><path d="M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20 M12,22c5.52,0,10-4.48,10-10c0-5.52-4.48-10-10-10 C6.48,2,2,6.48,2,12C2,17.52,6.48,22,12,22L12,22z M11,12l0,4h2l0-4h3l-4-4l-4,4H11z"/></g></svg>
</div>

</div>
