import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonServiceService } from '../services/common-service.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  openEmailDialog : boolean = false;
  public forgetPasswordForm !: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private snackBar:MatSnackBar,
    private commonService : CommonServiceService
  ) {
    this.createForgetPaaswordForm();
   }

  ngOnInit(): void {
  }
  createForgetPaaswordForm():void {
    this.forgetPasswordForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
    });
}

  emailSend(){
    if(this.forgetPasswordForm?.invalid){
      this.openSnackBar(['all field is required !!'],'','warning')
    }
    console.log("singnup",this,this.forgetPasswordForm)
    let payload = {
      email : this.forgetPasswordForm?.controls['email'].value,
    }
    this.commonService.forgetPassworEmailRequest(payload).subscribe((res:any) => {
      if(res.success){
        this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
      this.openEmailDialog = true;
    },(error:any)=>{console.log(error);
    
      this.openEmailDialog = false;
      this.openSnackBar([error?.error?.message || 'Failed !'] ,"");
    });
  }
  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: [className]
  });
}
get f() {
  return this.forgetPasswordForm.controls;
}

}
