import { Component, HostListener, OnInit } from '@angular/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-forms',
  templateUrl: './admin-forms.component.html',
  styleUrls: ['./admin-forms.component.scss']
})
export class AdminFormsComponent implements OnInit {
  formListingData: any = [];
  totalDataCount: number = 0;
  public limit = 10;
  public skip = 0;
  public page = 1;
  countryData: any = [];

  // isClassAdded = false;

  baseFormModule : string[] = ['baselineform','programform'];
  allowedAction :any = [];
  pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);

  constructor(private commonService: CommonServiceService, public router: Router) { 
  }

  ngOnInit(): void {
  }


 
  toggle(formId:number){
    this.router.navigate(['/forms-list'], {queryParams:{formId: formId}})
  }


  getCurrentProgramFormMethod(data: any) {
    let getCurrentTab: any = null; 
    
    data.some((el: any) => {
      getCurrentTab = this.pageAction[0].authenicationJson.find((e: any) => {
        return e.model === el &&
          (e.isAction || e.is_checked || (e.authentication && e.authentication.some((inside: any) => inside.isAction)));
      });
  
      return getCurrentTab !== undefined; 
    });
  
    return getCurrentTab;
  }



}
