<!-- <div *ngIf="!openEmailDialog">
    <div class="position-relative">
        <h2 class="text-center fw-bold mb-4">Forgot password</h2>
        <img src="../../../assets/images/login-closee.svg" class="close-btn position-absolute" mat-dialog-close alt="">
        </div>
    <form [formGroup]="forgetPasswordForm">
        <div class="form-floating mb-4">
            <input type="email" class="form-control rounded-pill" id="floatingInput" placeholder="name@example.com" formControlName="email">
            <label for="floatingInput">Email address*</label>
            <span class="invalid-feedback m-3"
          *ngIf="(forgetPasswordForm.get('email')?.touched  || forgetPasswordForm.get('email')?.dirty) && forgetPasswordForm.get('email')?.invalid">
          Email is Required *.
        </span>
          </div>
         
          <button class="btn w-100 text-white mb-3 rounded-pill forgot-submit" (click)="emailSend()" [disabled]="forgetPasswordForm?.invalid">Submit</button>
    </form>
</div> -->

<section class="forgotPassword_section">
    <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center">
            <div>
                <div class="card m-auto p-2 border-0 shadow-sm" style="border-radius: 10px; max-width: 520px; width: 100%;">
                    <div class="card-body">
                                    <!-- <div class="position-relative"> -->
                    <h2 class="fs-24 fw-bold mb-2">Forgot password</h2>
                    <p class="fs-14 color-44">Enter the following detail to continue</p>
                    <!-- <img src="../../../assets/images/login-closee.svg" class="close-btn position-absolute" mat-dialog-close alt="">
                    </div> -->
                    <form [formGroup]="forgetPasswordForm">
                        <!-- <div class="form-floating mb-3">
                            <input type="email" class="form-control rounded-pill" id="floatingInput" placeholder="name@example.com" formControlName="email">
                            <label for="floatingInput">Email address *</label>
                            <span class="invalid-feedback"
                          *ngIf="(forgetPasswordForm.get('email')?.touched  || forgetPasswordForm.get('email')?.dirty) && forgetPasswordForm.get('email')?.invalid">
                          Please enter valid email *.
                        </span>
                          </div> -->
                          <div class="position-relative my-2">
                            <mat-form-field appearance="outline" class="example-full-width w-100">
                              <mat-label>Email address *</mat-label>
                              <input type="email" placeholder="example@gmail.com" matInput formControlName="email" [ngModelOptions]="{standalone: true}">
                              <!-- <mat-error class="invalid-feedback mx-3 mb-0" *ngIf="(forgetPasswordForm.get('email')?.touched  || forgetPasswordForm.get('email')?.dirty) && forgetPasswordForm.get('email')?.invalid">
                                Please enter valid email *.
                              </mat-error> -->

                              <mat-error class="invalid-feedback mx-3 mb-0" *ngIf="forgetPasswordForm.get('email')?.touched && forgetPasswordForm.get('email')?.invalid">
                                <ng-container *ngIf="forgetPasswordForm.get('email')?.errors?.required">
                                  Email is required.
                                </ng-container>
                                <ng-container *ngIf="forgetPasswordForm.get('email')?.errors?.email && !forgetPasswordForm.get('email')?.errors?.required">
                                  Please enter a valid email.
                                </ng-container>
                              </mat-error>
                             
                            </mat-form-field>
                          </div>
                         
                          <button class="btn w-100 text-white rounded-pill mb-2 forgot-submit" (click)="emailSend()" [disabled]="forgetPasswordForm.invalid">Get verified link</button>
                          <div class="home_btn rounded-pill text-center">
                            <span class="pointer fw-500" routerLink="/home">Back to home</span>
                          </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div *ngIf="openEmailDialog">
    <div class="text-center">
        <img src="../../../assets/images/Reset-password-illus.svg" class="img-fluid mb-3">
    </div>
<h2 class="fs-20 mb-3 fw-600 text-center">Reset password link has been sent to your email.</h2>
<div class="text-center">
    <button class="btn text-white rounded-pill" style="background-color: #00625F; height: 48px; width: 130px;" mat-dialog-close>OK</button>
</div>
</div>
