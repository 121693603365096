import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button'
import { NgImageSliderModule } from 'ng-image-slider';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from './shared/shared.module';
import {MatTabsModule} from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
// import { AccessibilityComponent } from './pages/accessibility/accessibility.component'
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { HistoricalSitesComponent } from './pages/historical-sites/historical-sites.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { DocumentsComponent } from './pages/documents/documents.component';
import { IframeDialogComponent } from './pages/documents/iframe-dialog/iframe-dialog.component';
// import { PaginatorComponent } from './pages/paginator/paginator.component';
import { LoginComponent } from './pages/login/login.component';
import { ImpactStoriesComponent } from './pages/impact-stories/impact-stories.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { RelatedStoriesComponent } from './pages/related-stories/related-stories.component';
import { SubscribersComponent } from './admin/subscribers/subscribers.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ManagePortalDialogComponent } from './admin/manage-portal/manage-portal-dialog/manage-portal-dialog.component';
import { ResourcesViewComponent } from './pages/resources-view/resources-view.component';
import {ConfirmationDialogComponent} from './pages/confirmation-dialog/confirmation-dialog.component'
import {SnackBarComponent} from './shared/snack-bar/snack-bar.component';
import { UserWelcomeComponent } from './layout/footer/user-welcome/user-welcome.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {LeafletMapComponent} from './shared/leafletMap/leaflet-map/leaflet-map.component';
import { AdminFormsComponent } from './admin/admin-forms/admin-forms.component';
import { ProgramFormComponent } from './admin/admin-forms/program-form/program-form.component';
// import {ManagePortalComponent} from '../app/admin/manage-portal/manage-portal.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { OurpartnersComponent } from './pages/ourpartners/ourpartners.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { CommonFormComponent } from './admin/admin-forms/common-form/common-form.component';
import { BaselineFormComponent } from './admin/admin-forms/baseline-form/baseline-form.component';
import { BaselineMonthlyFormComponent } from './admin/admin-forms/baseline-monthly-form/baseline-monthly-form.component';
import { FormListComponent } from './admin/admin-forms/form-list/form-list.component';
import { CommunicationsComponent } from './admin/communications/communications.component';
import { CommunicationSidebarComponent } from './admin/communication-sidebar/communication-sidebar.component';
import { UserProfileComponent } from './admin/user-profile/user-profile.component';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { ManageUserComponent } from './admin/user-management/manage-user/manage-user.component';
import { UserLevelComponent } from './admin/user-management/user-level/user-level.component';
import { AddUserComponent } from './admin/user-management/add-user/add-user.component';
import { MatSelectModule } from '@angular/material/select';
import { UserPoliciesComponent } from './admin/user-management/user-policies/user-policies.component';
import { UserManagementTableComponent } from './admin/user-management/user-management-table/user-management-table.component';
import { UserManagementModule } from './admin/user-management/user-management/user-management.module';
import { PieChartComponent } from './pages/chartFolder/pie-chart/pie-chart.component';
import { BarChartComponent } from './pages/chartFolder/bar-chart/bar-chart.component';
import { TreeChartComponent } from './pages/chartFolder/tree-chart/tree-chart.component';
import { DoughnutComponent } from './pages/chartFolder/doughnut/doughnut.component';
import { ChartModule } from 'angular2-chartjs';
import { StackbarComponent } from './pages/chartFolder/stackbar/stackbar.component';
import { FilterPipe } from './filter.pipe';
import { FilterNamePipe } from './filter2.pipe';
// import { ChartTreeMapModule } from 'chartjs-chart-treemap';

import 'chartjs-chart-treemap';
import { TitleCasePipe } from './title-case.pipe';
import { ContactDialogComponent } from './pages/contact-dialog/contact-dialog.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import {MatChipsModule} from '@angular/material/chips';
import { FormBuilderComponent } from './pages/form-builder/form-builder.component';
import { IpsaFormsComponent } from './pages/ipsa-forms/ipsa-forms.component';
import { FormListComponentIPSA } from './pages/ipsa-forms/form-list/form-list.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { IpsaFormDesignComponent } from './pages/ipsa-forms/ipsa-form-design/ipsa-form-design.component';
import { TimeChartComponent } from './pages/chartFolder/time-chart/time-chart.component';
import { LineChartComponent } from './pages/chartFolder/line-chart/line-chart.component';
import { ReversePipe } from './reverse.pipe';
import { GroupedChartComponent } from './pages/chartFolder/grouped-chart/grouped-chart.component';
import { IpsaDashboardComponent } from './pages/ipsa-dashboard/ipsa-dashboard.component';
import { IpsaIntroComponent } from './pages/ipsa-intro/ipsa-intro.component';
import { IpsaComponent } from './pages/ipsa-forms/ipsa/ipsa.component';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { IpsaReportComponent } from './pages/ipsa-forms/ipsa-report/ipsa-report.component';
import { IconClassPipe } from './icon-class.pipe';
import { HideCommPipe } from './layout/header/hideCommPipe';
import { IndicatorPermissionComponent } from './admin/user-management/indicator-permission/indicator-permission.component';
import { GDPRComponent } from './pages/gdpr/gdpr.component';
import { ComposeMessageComponent } from './admin/compose-message/compose-message.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    // AccessibilityComponent,
    HistoricalSitesComponent,
    AboutUsComponent,
    DocumentsComponent,
    IframeDialogComponent,
    // PaginatorComponent,
    LoginComponent,
    ImpactStoriesComponent,
    ResourcesComponent,
    RelatedStoriesComponent,
    SubscribersComponent,
    ManagePortalDialogComponent,
    ResourcesViewComponent,
    ConfirmationDialogComponent,
    SnackBarComponent,
    UserWelcomeComponent,
    DashboardComponent,
    LeafletMapComponent,
    AdminFormsComponent,
    ProgramFormComponent,
    OurpartnersComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    CommonFormComponent,
    BaselineFormComponent,
    BaselineMonthlyFormComponent,
    FormListComponent,
    CommunicationsComponent,
    CommunicationSidebarComponent,
    UserProfileComponent,
    NotificationsComponent,
    // ManagePortalComponent
    ManageUserComponent,
    UserLevelComponent,
    AddUserComponent,
    UserPoliciesComponent,
    UserManagementTableComponent,
    PieChartComponent,
    BarChartComponent,
    TreeChartComponent,
    DoughnutComponent,
    StackbarComponent,
    FilterPipe,
    FilterNamePipe,
    TitleCasePipe,
    ContactDialogComponent,
    ResetPasswordComponent,
    FormBuilderComponent,
    IpsaFormsComponent,
    FormListComponentIPSA,
    IpsaFormDesignComponent,
    TimeChartComponent,
    LineChartComponent,
    ReversePipe,
    GroupedChartComponent,
    IpsaDashboardComponent,
    IpsaIntroComponent,
    IpsaComponent,
    SanitizeHtmlPipe,
    IpsaReportComponent,
    IconClassPipe,
    HideCommPipe,
    IndicatorPermissionComponent,
    GDPRComponent,
    ComposeMessageComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgImageSliderModule,
    MatPaginatorModule,
    MatTooltipModule,
    SharedModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatTableModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDividerModule,
    MatIconModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBgT0XnBlw6zJnT5EVrZlmA0Tnbb0CVSb0'
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressBarModule,
    // MaterialModule,
    UserManagementModule,
    MatSelectModule,
    ChartModule,
    MatSlideToggleModule
    // ChartTreeMapModule
  ],
  entryComponents:[
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  {
    provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
    useValue: {duration: 5000}
  },
  { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
  exports:[MatDialogModule,MatButtonModule,NgImageSliderModule,MatPaginatorModule,MatTooltipModule, MatSlideToggleModule],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
    ],
})
export class AppModule { }
