import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  @ViewChild('barChartCanvas') barChartCanvas!: ElementRef<HTMLCanvasElement>;
  private barChartContext!: CanvasRenderingContext2D;

  // public chart: Chart;
  @Input() data:any[] = []
  customData: any = [];
  customLabel: any = [];
  constructor() { }

  ngOnInit() {
    if(this.data?.length){
      this.getDataForChart(this.data);
      this.getLabelForChart(this.data);
      this.barMethod();
    }
    
  }
  barMethod(){
    console.log("bar",this.customData,this.customLabel)
    // this.chart = 
    Chart.register(ChartDataLabels);
    Chart.defaults.set('plugins.datalabels', {
      color: '#fff'
    });
    this.barChartContext = this.barChartCanvas.nativeElement.getContext('2d')!;
    new Chart(this.barChartContext, {
      type: "bar",
      data: {
        // labels: ["Financial Literacy", "Day Cares", "Paid Maternity/ Paternity Leaves", "Subsidized Food", "Healthcare"],
        labels:this.customLabel,
        datasets: [
          {
            label: "Workers with Access to Services",
            data:this.customData,
            backgroundColor: [
              "rgb(61, 178, 46)",
              "rgb(91, 226, 73)"
            ],
            borderColor: [
              "rgba(61, 178, 46)",
              "rgb(91, 226, 73)"
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          y:{
            beginAtZero: true,
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            labels:{
              usePointStyle:true,
              pointStyle: 'circle',
              padding:20
              }
          },
        },
        indexAxis: 'x',
        barThickness: 40, 
        maxBarThickness: 60
      } 
    });
  }
  
  getLabelForChart(data:any){
    data?.forEach((el:any)=>{
     if(el?.question_label){
       let question = el?.question_label.toLowerCase().replace(/(^|\s)\S/g, (L:any) => L.toUpperCase())
       this.customLabel.push(question)
     }
   })
 }
 getDataForChart(data:any){
     data?.forEach((el:any)=>{
     if(el?.total_count) {
       this.customData.push(el?.total_count)
     }
   })
 }
 ngAfterViewInit(){
  this.barMethod()
 }
}
