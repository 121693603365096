import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: "app-grouped-chart",
  templateUrl: "./grouped-chart.component.html",
  styleUrls: ["./grouped-chart.component.scss"],
})
export class GroupedChartComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() type: "group" | "groupStack" = "group";

  @Input() colors = [
    "#003c30",
    "#01665e",
    "#35978f",
    "#80cdc1",
    "#c7eae5",
    "#e6f5d0",
    "#f6e8c3",
    "#dfc27d",
    "#bf812d",
    "#8c510a",
    "#543005",'#9e0142','#d53e4f','#f46d43','#fdae61','#fee08b','#ffffbf','#e6f598','#abdda4','#66c2a5','#3288bd','#5e4fa2'
];

  @ViewChild("groupedChartCanvas")
  groupedChartCanvas!: ElementRef<HTMLCanvasElement>;

  labels: string[] = [];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    stack?: string;
  }[] = [];

  stackedBarChart: any;
  @Input() isMonth: boolean = false;
  showChart: boolean = true;

  constructor() {}

  ngOnInit(): void {}


  ngOnChanges(changes:SimpleChanges){
    if(changes.data?.currentValue){
      this.data = changes.data.currentValue;
      if(this.groupedChartCanvas?.nativeElement)
      setTimeout(() => {
        this.makeChart(this.groupedChartCanvas.nativeElement.getContext("2d"));
      },100)
      
    }
  }

  ngAfterViewInit(): void {
    if(this.groupedChartCanvas?.nativeElement)
    setTimeout(() => {
      this.makeChart(this.groupedChartCanvas.nativeElement.getContext("2d"));
    },100)
  }

  makeChart(ctx: CanvasRenderingContext2D | null) {
    if (ctx) {
        this.prepareData().then((data) => {
          const allZeros = data.datasets.every((dataset:any) => dataset.data.every((value:any) => value === 0));
          this.showChart = allZeros ? false : true;
          if(!allZeros){
          // data = {
          //   ...data,
          //   labels: data.labels.filter((_, index) => data.datasets[0].data[index] !== 0),
          //   datasets: data.datasets.map(dataset => ({
          //     ...dataset,
          //     data: dataset.data.filter((value:any) => value !== 0),
          //   })),
          // };;
       console.log("proper", data)
      Chart.register(ChartDataLabels);
      this.stackedBarChart = new Chart(ctx, {
        type: "bar",
        data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
          },
          scales: {
            x: {
              title: {
                display: false,
                text: this.data[0]?.value_format == 'kwh' ? 'Energy' : !this.isMonth ? 'Year' : 'Month, Year'
              },
              stacked: this.type == 'groupStack', // Enable grouped bars
            },
            y: {
              title: {
                display: true,
                text: 'Value'
              },
              ticks:{
                precision: 0
              },
              stacked: this.type == 'groupStack', // Enable stacked bars
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              labels: {
                usePointStyle: true,
                pointStyle: "circle",
                padding: 15,
                boxWidth: 8,
                font: {
                  size: 11
                },
              },
            },
            datalabels: {
              display: true,
              color: "#fff",
              anchor: "center", // Adjust the anchor position as needed
              align: "center", // Adjust the align as needed
              offset: 0,
              rotation: -90, //rotating text
              font: {
                size: 9,
                weight: "bold",
              },
              formatter: (value, context) => {
                if(!(value - Math.floor(value) !== 0)){
                  let formattedNumber = this.formatNumberWithCommas(value);
                  console.log("valueGettt",formattedNumber);
                  // You can customize the label content here
                  return formattedNumber;
                }
              },
            },
          },
        },
      });
    }
    });
    }
  }

  formatNumberWithCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  async prepareData() {
    let datasets:any[] = [];
    let labelObj:any = {};
    // let labels:any = []
    if(!this.type || this.type == 'group'){


      // if(this.data[0]?.value_format == 'kwh'){
        this.data.forEach((item:any) => {
          item.values.forEach((entry:any, index:number) => {
              if (!datasets[entry.financial_year]) {
                  datasets[entry.financial_year] = {
                      label: entry.financial_year,
                      backgroundColor : this.colors[index],
                      barThickness:15,
                      maxBarThickness: 20,
                      data: [],
                      ...(item.values?.length > 1) && {hidden:  entry.financial_year != '2023' || index !=0 && entry.financial_year != '2023'},
                  };
              }
              datasets[entry.financial_year].data.push(parseInt(entry.total_count));
          });
      });
      
      // Convert datasets object to array
      datasets = Object.values(datasets);
    // }
    // else{
    //   this.data.forEach((el:any,index:number) => {
    //     let obj:any = {
    //         backgroundColor : this.colors[index]
    //     };
    //     obj["label"] = el.question_label || el.name;
    //     obj["borderRadius"] = 0;
    //     let values = el.values.sort((a:any,b:any) => a.financial_year-b.financial_year);
    //     obj["data"] = values.map((item:any) => {
    //         labelObj[item.financial_year] = 1;
    //         return item.total_count;
    //     });
    //     obj["hidden"] = index != 0;
    //     obj["barThickness"] = 15;
    //     obj["maxBarThickness"] = 20;
    //     datasets.push(obj);
    // });
    // }

      
    } else if(this.type == 'groupStack') {
        this.data.forEach((ele:any,index:number) => {
            let stack = ele.question_label || `stack ${index}`
            let dataArr:any[] = ele.short_key || ele.short_key_women || ele.short_key_men;
            dataArr.forEach((el:any,short_key_index:number) => {
                let obj:any = {
                    backgroundColor : this.colors[short_key_index],
                    stack
                };
                obj["label"] = this.firstLetterCaptial(el.question_label || el.name);
                let values = el.values.sort((a:any,b:any) => a.financial_year-b.financial_year);
                obj["data"] = values.map((item:any) => {
                    labelObj[item.financial_year] = 1;
                    return item.total_count;
                });
                obj["hidden"] = short_key_index != 0;
                obj["barThickness"] = 15;
          obj["maxBarThickness"] = 20;
                datasets.push(obj);
            });
        });
    }
    let labels =  this.data.map(item => item.question_label);
    return { labels, datasets }
  }
  firstLetterCaptial(value: string): string {
    if (value) {
      let sentence: any = value.split("_");
      return sentence
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return "";
  }
}
