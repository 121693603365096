<div class="Footer_bottom">
  <ng-container *ngFor="let item of homeData">
    <section class="first-section" *ngIf="item?.order == 1 && item?.slug == 'home'">
      <div class="container-fluid px-0 overflow-hidden">
        <div class="row justify-content-end align-items-center">
          <div class="col-md-5">
            <div class="heading-box" style="padding: 0 40px;">
              <h1 class="heading-1 text-white fw-500" style="font-size: 42px;">{{item?.title}}</h1>
              <!-- <p class="text-white">{{item?.subTitle}}</p> -->
              <!-- <p class="text-white" [innerHTML]="stripHtmlTagsForCard(item?.subTitle,-1)"></p> -->
              <p class="text-white" [innerHTML]="item?.subTitle"></p>
              <button class="btn button d-none d-flex align-items-center justify-content-center mb-3 text-white started-btn rounded-pill" (click)="openSignUpForm()">
                <span class="text">{{item?.linkTitle}}</span>
              <span class="d-none"><svg id="Group_7" data-name="Group 7" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_1" data-name="Path 1" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_2" data-name="Path 2" d="M16,9.414,7.4,18.021,5.983,16.607,14.589,8H7V6H18V17H16Z"/>
              </svg>
              </span>
              </button>
            </div>
          </div>
          <div class="col-md-6 pe-0 text-end">
            <img [src]="item?.banner" class="img-fluid w-100 home-banner" alt="">
          </div>
        </div>
      </div>
    </section>
  
    <section class="about-section" *ngIf="item?.order == 2 && item?.slug == 'home'">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-6">
            <h2 class="fs-32 fw-600 mb-3" style="color: #00625F;">{{item?.title}}</h2>
            <!-- <p class="color-44 fs-14 mb-sm-4">{{item?.subTitle | truncatePipe : 500}}</p> -->
          
              <p class="color-44 fs-14 mb-sm-4" [innerHTML]="stripHtmlTagsForCard(item?.subTitle,500)"></p>
              <!-- <p class="color-44 fs-14 mb-sm-4" [innerHTML]="item?.subTitle"></p> -->

            
            <!-- <td [matTooltip]="item?.description?.length < 75 ? '' : item?.description">{{item?.description | truncatePipe : 75}}</td> -->

            <!-- <p style="font-size: 14px;">INSTEP contributes to systemic change within the textile and apparel industry and supports brands’ sustainable sourcing commitments, net zero goals, GHG reductions, and the UN SDGs.</p> -->
            <button class="btn learn-btn mb-3 rounded-pill" routerLink="/about-us">{{item?.linkTitle}}</button>
          </div>
          <div class="col-md-6">
              <div class="colored-box position-relative">
                <div class="hover-image">
                  <!-- <img [src]="item?.banner" class="img-fluid" alt=""> -->
                  <iframe class="iframe_video w-100" width="500" height="300" [src]="url"
                  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowfullscreen></iframe>
                </div>
              </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="my-4" *ngIf="item?.order == 3 && item?.slug == 'home'">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 col-12" >
            <iframe class="iframe_video w-100" width="500" height="300" src="https://www.youtube.com/embed/YtLBXI51_yY?si=I1ChDQmCzcKap1sN" 
              title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section> -->
  
    <section *ngIf="item?.order == 3 && item?.slug == 'home'">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <!-- <div class="instep-banner hover-div text-center"> -->
              <!-- <button class="btn hidden-button">View in full screen</button> -->
          <!-- <div class="shadow-box">
          </div> -->
              <img [src]="item?.banner" class="img-fluid shadow-sm" alt="">
            <!-- </div> -->
          </div>
        </div>
      </div>
    </section>
  
    <!-- OUR PARTNER SECTION -->
    <section class="about-section" *ngIf="item?.order == 4 && item?.slug == 'home'">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-10 text-center">
            <h2 class="ps-sm-3 mb-sm-4 fw-600" style="color: #00625F;">{{item?.title}}</h2>
            <p class="color-44 fs-14 mb-4 text-center" [innerHTML]="stripHtmlTagsForCard(item?.subTitle,-1)"></p>
          </div>
          <div class="col-12">
            <div id="container">
            <div class="photobanner mb-3">
              <ng-container *ngFor="let item of partnerData">
                <img class="me-5 partner-img" [src]="item?.image">
                </ng-container>
              </div>
            </div>
            <!-- <div class="bg mb-4">
              <marquee direction="left" scrollamount="30"  behavior="scroll" onMouseOver="this.stop()" onMouseOut="this.start()" loop="infinite">
                <div class="d-flex">
                  <ng-container *ngFor="let item of partnerData">
                  <img class="me-5 partner-img" [src]="item?.image">
                  </ng-container>
                </div>
              </marquee>
            </div> -->
<div class="text-center">
  <button class="btn learn-btn my-3 rounded-pill" routerLink="/ourpartners">{{item?.linkTitle}}</button>
</div>
          </div>
        </div>
      </div>
    </section>

  
  
    <!-- OUR IMPACT SECTION -->
    <section class="impact-section" *ngIf="item?.order == 5 && item?.slug == 'home'">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <h2 class="text-primary fs-32 fw-600 text-center mb-5">{{item?.title}}</h2>
          </div>
          <div class="col-lg-3 col-md-6" *ngFor="let item of ourImpactData">
            <div class="mb-3 border-0 position-relative">
              <img [src]="item.image" class="img-fluid impact-img" alt="">
                <div class="position-absolute impact-content" style="top: 10px; left: 20px;">
                  <p class="m-0" style="font-size: 14px; color: white;">{{item.name}}</p>
                  <!-- <div class="fw-bold" style="font-size: 40px; color: white;">{{item['impactstories.count']}}</div> -->
                  <div class="fw-bold" style="font-size: 40px; color: white;">{{item['indicatorsCount'] ? item['indicatorsCount'] : 0}}</div>
                  <!-- <button class="btn d-flex align-items-center explore-btn rounded-pill" (click)="toDashboard()">
                    <span>
                      <svg class="me-1" id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="17.652" height="17.652" viewBox="0 0 17.652 17.652">
                        <path id="Path_7601" data-name="Path 7601" d="M0,0H17.652V17.652H0Z" fill="none"/>
                        <path id="Path_7602" data-name="Path 7602" d="M3,10.355H8.884V3H3Zm0,5.884H8.884V11.826H3Zm7.355,0h5.884V8.884H10.355ZM10.355,3V7.413h5.884V3Z" transform="translate(-0.793 -0.793)"/>
                      </svg>
                    </span> Explore</button> -->
                </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <button class="btn d-flex align-items-center justify-content-center explore-btn rounded-pill m-auto" (click)="toDashboard()">
              <span>
                <svg class="me-1" id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="17.652" height="17.652" viewBox="0 0 17.652 17.652">
                  <path id="Path_7601" data-name="Path 7601" d="M0,0H17.652V17.652H0Z" fill="none"/>
                  <path id="Path_7602" data-name="Path 7602" d="M3,10.355H8.884V3H3Zm0,5.884H8.884V11.826H3Zm7.355,0h5.884V8.884H10.355ZM10.355,3V7.413h5.884V3Z" transform="translate(-0.793 -0.793)"/>
                </svg>
              </span>
               Explore</button>
          </div>
          <!-- <div class="col-md-3">
            <div class="mb-3 border-0 position-relative">
              <img src="../../../assets/images/new-impact2.png" class="img-fluid impact-img" alt="">
              <div class="position-absolute impact-content" style="top: 10px; left: 20px;">
                <p class="m-0" style="font-size: 14px; color: white;">Factories supported</p>
                <div style="font-size: 40px; color: white;">400</div>
                <button class="btn d-flex align-items-center explore-btn rounded-pill">
                  <span>
                    <svg class="me-1" id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="17.652" height="17.652" viewBox="0 0 17.652 17.652">
                      <path id="Path_7601" data-name="Path 7601" d="M0,0H17.652V17.652H0Z" fill="none"/>
                      <path id="Path_7602" data-name="Path 7602" d="M3,10.355H8.884V3H3Zm0,5.884H8.884V11.826H3Zm7.355,0h5.884V8.884H10.355ZM10.355,3V7.413h5.884V3Z" transform="translate(-0.793 -0.793)"/>
                    </svg>
                  </span> Explore</button>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="mb-3 border-0 position-relative">
              <img src="../../../assets/images/new-impact3.png" class="img-fluid impact-img" alt="">
                <div class="position-absolute impact-content" style="top: 10px; left: 20px;">
                  <p class="m-0" style="font-size: 14px; color: white;">Workers impacted</p>
                  <div style="font-size: 40px; color: white;">200K</div>
                  <button class="btn d-flex align-items-center explore-btn rounded-pill">
                    <span>
                      <svg class="me-1" id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="17.652" height="17.652" viewBox="0 0 17.652 17.652">
                        <path id="Path_7601" data-name="Path 7601" d="M0,0H17.652V17.652H0Z" fill="none"/>
                        <path id="Path_7602" data-name="Path 7602" d="M3,10.355H8.884V3H3Zm0,5.884H8.884V11.826H3Zm7.355,0h5.884V8.884H10.355ZM10.355,3V7.413h5.884V3Z" transform="translate(-0.793 -0.793)"/>
                      </svg>
                    </span> Explore</button>
                </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="mb-3 border-0 position-relative">
              <img src="../../../assets/images/new-impact4.png" class="img-fluid impact-img" alt="">
                <div class="position-absolute impact-content" style="top: 10px; left: 20px;">
                  <p class="m-0" style="font-size: 14px; color: white;">Brand partnerships</p>
                  <div style="font-size: 40px; color: white;">20</div>
                  <button class="btn d-flex align-items-center explore-btn rounded-pill">
                    <span>
                      <svg class="me-1" id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="17.652" height="17.652" viewBox="0 0 17.652 17.652">
                        <path id="Path_7601" data-name="Path 7601" d="M0,0H17.652V17.652H0Z" fill="none"/>
                        <path id="Path_7602" data-name="Path 7602" d="M3,10.355H8.884V3H3Zm0,5.884H8.884V11.826H3Zm7.355,0h5.884V8.884H10.355ZM10.355,3V7.413h5.884V3Z" transform="translate(-0.793 -0.793)"/>
                      </svg>
                    </span> Explore</button>
                </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
  
  
    <!-- IMPACT STORY SECTION -->
    <!-- <section *ngIf="item?.order == 6 && item?.slug == 'home'">
      <div class="container">
        <div class="d-flex mb-3 align-items-center justify-content-between">
          <h3 class="text-primary">{{item?.title}}</h3>
          <button class="btn border-0 fw-bold" routerLink="/impact-stories">View All></button>
        </div>
        <div class="row">
          <ng-container *ngFor="let items of impactData; let i = index">
            <div class="col-lg-8" *ngIf="i % 2 == 0 && (i == 0 || i > 6)">
              <div class="position-relative img-opacity mb-3">
                <img [src]="items?.banner" class="img-fluid h-446 w-100 image-round" alt="">
                <div class="text-white position-absolute img-content">
                  <h4 class="text-white main-image">{{items?.title}}</h4>
                  <p class="fs-16 text-truncate col-8-truncate" style="max-width: 600px;">{{items?.description}}</p>
                  <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: items?.id}">Read More</button>
                </div>
              </div>
            </div>
          </ng-container>
  
          <ng-container *ngFor="let items of impactData; let i = index">
            <div class="col-lg-4"*ngIf="i % 2 == 1 && (i == 1 || i > 7)">
              <div class="position-relative img-opacity mb-3">
                <img [src]="items?.banner" class="img-fluid image-round w-100 h-365" alt="">
                <div class="text-white position-absolute img-content">
                  <h6 class="text-white">{{items?.title}}</h6>
                  <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{items?.description}}</p>
                  <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: items?.id}">Read More</button>
                </div>
              </div>
            </div>
          </ng-container>
  
          <ng-container *ngFor="let items of impactData; let i = index">
            <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 2 || i > 8)">
              <div class="position-relative img-opacity mb-3">
                <img [src]="items?.banner" class="img-fluid image-round w-100 h-281" alt="">
                <div class="text-white position-absolute img-content">
                  <h6 class="text-white">{{items?.title}}</h6>
                  <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{items?.description}}</p>
                  <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: items?.id}">Read More</button>
                </div>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 3 || i > 9)">
              <div class="position-relative img-opacity mb-3">
                <img [src]="items?.banner" class="img-fluid w-100 image-round h-281" alt="">
                <div class="text-white position-absolute img-content">
                  <h6 class="text-white">{{items?.title}}</h6>
                  <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{items?.description}}</p>
                  <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: items?.id}">Read More</button>
                </div>
              </div>
            </div>
            <div class="col-lg-4" *ngIf="i % 2 == 0 && (i == 4 || i > 10)">
              <div class="position-relative img-opacity mb-3">
                <img [src]="items?.banner" class="img-fluid image-round h-281 w-100" alt="">
                <div class="text-white position-absolute img-content">
                  <h6 class="text-white">{{items?.title}}</h6>
                  <p class="text-truncate col-4-truncate" style="font-size: 13px; max-width: 300px;">{{items?.description}}</p>
                  <button class="btn read-btn text-white mb-3 rounded-pill" routerLink="/related-stories" [queryParams]="{ id: items?.id}">Read More</button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section> -->
  
    <section id="impact" class="pb-5" *ngIf="item?.order == 6 && item?.slug == 'home'">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="text-center impact-title">
              <h2 class="text-primary ps-sm-3 mb-sm-4 fw-600">{{item?.title}}</h2>
              <p class="color-44 fs-14 mb-4 text-center" [innerHTML]="stripHtmlTagsForCard(item?.subTitle,-1)"></p>
      
              <!-- <button class="btn border-0 fw-bold" routerLink="/impact-stories">View All></button> -->
            </div>
          </div>
        </div>
      </div>
  <!-- <div class="impact-bg"> -->
    <div class="container">
      <div class="row text-center">
        <ng-container *ngFor="let items of impactData; let i = index">
        <div class="col-md-4" style="margin-bottom: 35px;" *ngIf="i <= 2">
          <div class="card-bg h-100">
            <div class="card h-100 card-hover border-0 shadow-sm">
              <div class="position-relative main-img">
                <img [src]="items?.banner" class="card-img-top h-100 rounded-0 img-fluid card-img" alt="...">
              </div>
              <div class="card-body" style="background-color: #00625F;">
                <h6 class="text-white">{{items?.title}}</h6>
                <!-- <p class="card-text text-white">{{items?.description | truncatePipe : 150}}</p> -->
                <p class="card-text text-white" [innerHTML]="stripHtmlTagsForCard(items?.description ,150)"></p>
                <!-- <p class="card-text text-white" [innerHTML]="item?.subTitle"></p> -->

              </div>
              <div class="text-center card-footer" style="background-color: #00625F;">
                <button class="btn read-btn text-white rounded-pill mb-2" style="background-color: #47d985 !important; border: 1px solid #47d985;" routerLink="/related-stories" [queryParams]="{ id: items?.id}">Read More</button>
              </div>
            </div>
          </div>
        </div>
        </ng-container>
        <div class="col-12 text-center">
          <button class="btn rounded-pill view-btn" routerLink="/impact-stories">View All</button>
        </div>
        <!-- <div class="col-md-4 mb-3">
          <div class="card-bg">
            <div class="card card-hover border-0 shadow-sm">
              <div class="position-relative main-img">
          
                <img src="../../../assets/images/impact-two.png" class="card-img-top h-100 rounded-0 img-fluid card-img" alt="...">
              </div>
              <div class="card-body">
                <h4>IDH and the Government of Mato Grosso renew partnership for investments in the state</h4>
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                <div class="text-center">
                  <button class="btn read-btn text-white rounded-pill">Read More</button>
                </div>
              </div>
             </div>
          </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div class="card-bg">
                    <div class="card card-hover border-0 shadow-sm">
                      <div class="position-relative main-img">
                  
                        <img src="../../../assets/images/impact-three.png" class="card-img-top h-100 rounded-0 img-fluid card-img" alt="...">
                      </div>
                      <div class="card-body">
                        <h4>IDH and the Government of Mato Grosso renew partnership for investments in the state</h4>
                        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                        <div class="text-center">
                          <button class="btn read-btn text-white rounded-pill">Read More</button>
                        </div>
                      </div>
                     </div>
                  </div>
                        </div> -->
      </div>
    </div>
  <!-- </div> -->
    </section>
  
    <!-- OUR TEAM SECTION -->
    <section class="team-section" *ngIf="item?.order == 7 && item?.slug == 'home'">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="text-center">
              <h2 class="text-primary fs-32 mb-sm-4 fw-600">{{item?.title}}</h2>
              <p class="text-center" style="font-size: 14px; color: #444444;">{{item?.subTitle}}</p>
            </div>
          </div>
          <div class="col-12" style="justify-content: end; display: flex;">
            <button id="prev-btn" class="btn mb-3 me-2" style="color: #fff; background-color: #004545;" (click)="nextImages('backward')"><</button>
            <button class="btn mb-3" style="color: #fff; background-color: #004545;" (click)="nextImages('forward')">></button>
          </div>
          <ng-container *ngIf="item?.model == 'Teammember'">
            <div class="col-md-3" *ngFor="let emp of imgData; let i = index" id="trans">
              <div class="member-img" style="width: 100%;">
                <img [src]="emp?.profile" class="img-fluid mb-3" alt="" style="width: 100%; aspect-ratio: 1/1;">
                <div class="overlay mb-3">
                  <div class="fw-bold mb-3">About {{emp?.name}}</div>
                  <p class="text-start">{{emp?.about}}</p>
                </div>
              </div>
              <div class="color-44 fs-16 fw-500">{{emp?.name}}</div>
              <p class="color-44" style="font-size: 13px;">{{emp?.designation}}</p>
  
              <!-- <div class="d-flex justify-content-end">
                <button class="carousel-control-prev me-3" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div class="overlay mb-3">
                          <div class="fw-bold mb-3">About Ashok Rao</div>
                          <p class="text-start">xyz</p>
                        </div>
                        <div style="font-size: 16px; color: #444444;">Ashok Rao</div>
              <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div style="font-size: 16px; color: #444444;">Rakhi Sharma</div>
                        <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div style="font-size: 16px; color: #444444;">Hitesh Kumar</div>
                        <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div style="font-size: 16px; color: #444444;">Yash Dutt</div>
                        <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row">
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div style="font-size: 16px; color: #444444;">Ashok Rao</div>
              <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div style="font-size: 16px; color: #444444;">Rakhi Sharma</div>
                        <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div style="font-size: 16px; color: #444444;">Hitesh Kumar</div>
                        <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                      <div class="col-md-3 col-6">
                        <img src="../../../assets/images/member-1.png" class="d-block w-100" alt="...">
                        <div style="font-size: 16px; color: #444444;">Yash Dutt</div>
                        <p style="font-size: 13px; color: #444444;">Manager</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </ng-container>
  
          <!-- <div class="col-md-3">
            <div class="member-img">
      <img src="../../../assets/images/member-1.png" class="img-fluid mb-3" alt="">
  
      <div class="overlay mb-3">
        <div class="fw-bold mb-3">About Ashok Rao</div>
        <p class="text-start">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum nesciunt amet a quaerat at voluptatibus eveniet voluptatem, sapiente non maiores molestiae repellat. Assumenda ullam beatae modi mollitia cupiditate, qui eos!</p>
          </div>
    </div>
    <div style="font-size: 16px; color: #444444;">Ashok Rao</div>
    <p style="font-size: 13px; color: #444444;">Manager</p>
  
          </div>
  
          <div class="col-md-3">
            <div class="member-img">
      <img src="../../../assets/images/member-1.png" class="img-fluid mb-3" alt="">
  
      <div class="overlay mb-3">
        <div class="fw-bold mb-3">About Ashok Rao</div>
        <p class="text-start">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum nesciunt amet a quaerat at voluptatibus eveniet voluptatem, sapiente non maiores molestiae repellat. Assumenda ullam beatae modi mollitia cupiditate, qui eos!</p>
          </div>
    </div>
    <div style="font-size: 16px; color: #444444;">Ashok Rao</div>
    <p style="font-size: 13px; color: #444444;">Manager</p>
  
          </div>
  
          <div class="col-md-3">
            <div class="member-img">
      <img src="../../../assets/images/member-1.png" class="img-fluid mb-3" alt="">
  
      <div class="overlay mb-3">
        <div class="fw-bold mb-3">About Ashok Rao</div>
        <p class="text-start">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum nesciunt amet a quaerat at voluptatibus eveniet voluptatem, sapiente non maiores molestiae repellat. Assumenda ullam beatae modi mollitia cupiditate, qui eos!</p>
          </div>
    </div>
    <div style="font-size: 16px; color: #444444;">Ashok Rao</div>
    <p style="font-size: 13px; color: #444444;">Manager</p>
  
          </div> -->
        </div>
      </div>
    </section>
  
    <!-- CONTACT US SECTION -->
    <section class="contact-section" *ngIf="item?.order == 8 && item?.slug == 'home'">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6">
            <h2 class="fs-32 fw-600 text-primary" style="margin-bottom: 8px;">{{item?.title}}</h2>
            <p class="text-primary" style="font-size: 14px; margin-bottom: 40px;">{{item?.subTitle}}</p>
            <form action="" [formGroup]="contactUsForm">
              <div >
                <mat-form-field appearance="outline" class="example-full-width w-100 mb-0 pb-0">
                  <mat-label>Name</mat-label>
                  <input type="text" placeholder="Enter your name" matInput formControlName="fullname" minlength="2" maxlength="20" (keypress)="alphabetsOnly($event)" required>
                </mat-form-field>
                <mat-error class="home-invalid f-12" *ngIf="contactUsForm.controls['fullname'].value == '' && contactUsForm.controls['fullname'].touched">
                  Name is Required *
                </mat-error> 
                
              </div>
  
              <div >
               <mat-form-field appearance="outline" class="example-full-width w-100 pb-0 ">
                <mat-label>Email</mat-label>
                <input type="email" (input)="validateMethod('emailId')" placeholder="example@gmail.com" matInput formControlName="emaiID" required>
              </mat-form-field>
              <mat-error class="home-invalid f-12" *ngIf="contactUsForm.controls['emaiID'].value == '' && contactUsForm.controls['emaiID'].touched">
                Email is required *
             </mat-error> 
             <mat-error class="home-invalid f-12 " *ngIf="contactUsForm.controls['emaiID'].value != '' && contactUsForm.controls['emaiID'].touched && contactUsForm.controls['emaiID'].status == 'INVALID'">
              Please enter a valid email.
           </mat-error>
              </div>
  
              <div >
                <mat-form-field appearance="outline" class="example-full-width w-100 mb-0 pb-0" >
                  <mat-label>Message</mat-label>
                  <textarea matInput placeholder="Enter your message" formControlName="message" style="height: 15vh;" (keypress)="restrictSpecialChar($event)" required></textarea>
                </mat-form-field>
              <mat-error class="home-invalid f-12" *ngIf="contactUsForm.controls['message'].value == '' && contactUsForm.controls['message'].touched">
                Message is Required *
             </mat-error>
             
              </div>  
  
              <button type="submit" class="btn btn-primary w-100 rounded-pill submit-btn my-2" [disabled]="contactUsForm.invalid" (click)="gdprConfirmation()">Submit</button>
  
              <div class="row">
                <!-- <div class="col-md-4 mb-md-0 mb-3">  
                  <div class="row align-items-center">
                    <div class="col-auto pe-0">
                    <img src="../../../assets/images/location-icon.svg" class="img-fluid" alt="">
                    </div>
                    <div class="col ps-1">
                      <div class="fw-bold" style="color: #0D0D0D; font-size: 15px;">Location:</div>
                      <div class="fs-13" style="color: #0D0D0D;">Lorem ipsum dolor</div>
                    </div>
                  </div>
                </div> -->
                <div class="col-md-4 mb-md-0 mb-3">
                  <div class="row align-items-center">
                    <div class="col-auto pe-1">
                      <img src="../../../assets/images/email-icon.svg" class="img-fluid" alt="">
                    </div>
                    <div class="col ps-1">
                      <div class="fw-bold" style="color: #0D0D0D; font-size: 15px;">Email:</div>
                      <div class="fs-13" style="color: #0D0D0D;"><a class="fs-13 text-decoration-none" style="color: #0D0D0D;" href="mailto:info@idh-instep.com?subject=Hello">info@idh-instep.com</a></div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <span class="fs-15 pointer privacyState" (click)="privacyStatement()"><u>Privacy Statement</u></span>
                </div>
                <!-- <div class="col-md-4 mb-md-0 mb-3">
                  <div class="row align-items-center">
                    <div class="col-auto pe-0">
                      <img src="../../../assets/images/phone-icon.svg" class="img-fluid" alt="">
                    </div>
                    <div class="col ps-1">
                      <div class="fw-bold" style="color: #0D0D0D; font-size: 15px;">Phone:</div>
                      <div class="fs-13" style="color: #0D0D0D;">+31(0)30 230 5660</div>
                    </div>
                  </div>
                </div> -->
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <img [src]="item?.banner" class="img-fluid contact-img" style="height: 570px;" alt="">
          </div>
        </div>
      </div>
    </section>
  
    <!-- <section class="email-section" *ngIf="item?.order == 8 && item?.slug == 'home'">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-5">
            <h5 class="mb-0">Get started with INSTEP</h5>
            <p class="mb-0" style="color: Subscribe to us;">Subscribe to us</p>
          </div>
          <div class="col-md-5">
            <div class="form-floating position-relative mb-3">
              <img src="../../../assets/images/rightArrow.svg" class="right-arrow position-absolute pointer" alt="" (click)="subscribeUser()">
              <input type="email" class="form-control email" id="floatingInput" placeholder="name@example.com" [(ngModel)]="userEmail">
              <label for="floatingInput">Email address</label>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </ng-container>
</div>