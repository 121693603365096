import { Component, EventEmitter, Input, OnInit,  Output,  SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @ViewChild('Paginator') paginator : MatPaginator | undefined;
  @Input() page:number = 1;
  @Input() totalCount:number = 0;
  @Input() pageSize:number = 0;
  @Output() nextPage : EventEmitter<any> = new EventEmitter();


  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(change:SimpleChanges){
    console.log("changes", change)
    if(change && change?.page?.currentValue){
      if(change.page.currentValue == 1){
        if(this.paginator){
          this.paginator.pageIndex = 0;
        }
      }
    }
    if(change && change?.totalCount?.currentValue){
      this.totalCount = change?.totalCount?.currentValue;
    }
    if(change && change?.pageSize?.currentValue){
      this.pageSize = change?.pageSize?.currentValue;
    }
  }

  pageEvent(event:any){
    this.page = event.pageIndex + 1;
    this.nextPage.emit(this.page);
  }

}
