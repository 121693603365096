import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute , Router} from '@angular/router';
import { CommonServiceService } from '../services/common-service.service';
import { LoginComponent } from '../login/login.component';
import { environment } from 'src/environments/environment';

import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GDPRComponent } from '../gdpr/gdpr.component';

function mobileNumberValidator(control:any) {
  const mobileNumber = control.value;

  if (mobileNumber && !/^[6879]\d{9}$/.test(mobileNumber)) {
    return { invalidMobileNumber: true };
  }

  return null;
}
function passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  if (!password || !confirmPassword) {
    return null;
  }

  return password.value === confirmPassword.value ? null : { 'passwordMismatch': true };
}
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  public singupForm !: FormGroup;
  ipNames :any = [
    {label:'Government official',id:'1',value:'Govt official'},
    {label:'Researcher',id:'2',value:'researcher'},
    {label:'Brand representative',id:'3',value:'brand representative'},
    {label:'Other',id:'4',value:'other'}
  ];
  isSignUpSuccess: boolean = false;
  constructor(public dialogRef: MatDialogRef<SignUpComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private snackBar:MatSnackBar,
    private route : ActivatedRoute,
    private router : Router,
    private commonService : CommonServiceService,
    ) {

      this.createSignUpForm();
      this.initializeOtpForm();
      this.inputChanges();
     }

  show: boolean = false;
  show2: boolean = false;
  countryCodeArray = [
    {
      name: 'Ethiopia',
      id: '5',
      code: '251'
    },
    {
      name: 'Vietnam',
      id: '6',
      code: '84'
    },
    {
      name: 'Kenya',
      id: '4',
      code: '254'
    },
  ];
  openRequestDialog : boolean = false;
  otpForm!: FormGroup;
  errorMessage:string= '';
  isDisabledVerify : boolean = true;
  isDisabledResendOtp:boolean = false;
  txnId:string = "";


  isOtpSent:boolean = false;
  otp: string = '';
  countryCode:string = '';
  countdown: number = 0;
  private countdownSubscription!: Subscription;
  onOtpChange(otp: any) {
    this.otp = otp;
  }
 
  ngOnInit(): void {
  }
  createSignUpForm():void {
    this.singupForm = this.formBuilder.group({
      userName: ["",Validators.required],
        email: ["", [Validators.required, Validators.email]],
        // mobileNumber: ["",[Validators.required,mobileNumberValidator,Validators.pattern(/^[6-9]\d{9}$/)]],
        mobileNumber:[""],
        // userInfo: ['Govt official',Validators.required],
        // password:["",Validators.required],
        password: ['', [
          Validators.required,
          Validators.minLength(8),
          this.validatePassword
        ]],
        confirmPassword: ["", Validators.required],
        country_code: [""]
    },{ validator: passwordMatchValidator });
}
sinUpUser(){
  console.log(this.singupForm,"this.userForm");
  if(this.singupForm?.invalid){
    this.openSnackBar(['all field is required !!'],'','warning');
    return
  }
  console.log("singnup",this,this.singupForm)
  const random = Math.random();
  let password = this.singupForm?.controls['password'].value ;
  let cipherText = this.commonService.getSecratKey(environment.secratKey,password);
  // let randomKey = random.toString();
  // let encryptCiphertextRandom = this.commonService.getSecratKey(randomKey, cipherText);
  let payload = {
    name : this.singupForm?.controls['userName']?.value,
    email : this.singupForm?.controls['email']?.value,
    mobile: this.singupForm?.controls['mobileNumber']?.value ? this.singupForm?.controls['mobileNumber']?.value : null,
    // designation : this.singupForm?.controls['userInfo']?.value,
    password : cipherText,
    country_code: this.singupForm?.controls['country_code']?.value ? this.singupForm?.controls['country_code']?.value : null
    // random : randomKey
  }
  console.log("payload",payload);
  let ipsaPayload:any = {
    name: payload.name,
    email: payload.email,
    password: password
  }
  this.registerIdhUser(payload, ipsaPayload);
  

//   let ipsaPayload = {
//     "endpoint": `/user/register`,
//     "headers": {
//         "Content-Type": "application/json",
//     },
//     "requestBody": {
//          "data": {
//         "name": payload.name,
//         "email": payload.email,
//         "password": password
//     }
//     },
//     "method": "POST"
// };
  // this.commonService.registerIpsaUser(ipsaPayload).subscribe((ipsres:any) => {
  //   if(ipsres.hasOwnProperty('_id')){
  //     this.commonService.createSignUpUser(payload).subscribe((res:any) => {
  //       if(res.success){
  //       this.openSnackBar([res?.message || 'Success !'] , '','success');
  //         this.txnId = res?.data?.txn_id;
          
  //       }
  //       this.isSignUpSuccess = true;
  //     },(error:any)=>{
  //       this.isSignUpSuccess = false;
  //       this.openSnackBar([error?.error?.message || 'Failed !'] ,"");
  //     });
    
  //   }if(ipsres?.message){
  //     this.openSnackBar([ipsres?.message || 'Something went wrong!!!'] , '','success');
  //   }
  // })

    // this.commonService.createSignUpUser(payload).subscribe((res:any) => {
    //   if(res.success){
    //     this.openSnackBar([res?.message || 'Success !'] , '','success');
    //     this.txnId = res?.data?.txn_id;
        
    //   }
    //   this.isSignUpSuccess = true;
    // },(error:any)=>{
    //   this.isSignUpSuccess = false;
    //   this.openSnackBar([error?.error?.message || 'Failed !'] ,"");
    // });
}
get f() {
  return this.singupForm.controls;
}

  showPassword(type:any){
    if(type == 'pass'){
      this.show = !this.show
    }else{
      this.show2 = !this.show2
    }
  }

  close(){
    this.dialogRef.close();
  }
  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: [className]
  });
}

  backtoHome(){
    this.dialogRef.close();
  }
  openDialog(event:Event) {
    event.preventDefault();
    this.dialogRef.close();
    let dialogRef = this.dialog.open(LoginComponent, {
      width: '530px',
      panelClass: 'login-dialog-class',
      disableClose: true,
      // position: {top: '170px'},
    });
    
    // dialogRef.afterClosed().subscribe((result :any) => {
    //   console.log("diaClose",result);
    //   this.isLoggedin = result;
    // });
  }
 
  // openDialog() {
  //   this.router.navigate(['home'])
  //   let dialogRef = this.dialog.open(LoginComponent, {
  //     width: '975px',
  //     panelClass: 'login-dialog-class',
  //     disableClose: true,
  //     position: {top: '170px'},
  //   });
  //   this.dialogRef.close();
  // }

  openRequest(){
    this.openRequestDialog = true;
  }

  initializeOtpForm(){
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      otp2: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      otp3: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      otp4: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      otp5: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      otp6: ['', [Validators.required, Validators.pattern(/^\d$/)]],
    });
  }
  focusNext(event: Event, currentInput: HTMLInputElement | any, nextInput: HTMLInputElement): void {
    const maxLength = parseInt(currentInput.maxLength, 10);
    const currentLength = currentInput.value.length;

    if (currentLength >= maxLength) {
      nextInput.focus();
    }
  }

  handleBackspace(event: KeyboardEvent, currentInput: HTMLInputElement, previousInput: HTMLInputElement,otp1:any): void {
    if (event.key === 'Backspace' && currentInput.value.length === 0 && !otp1) {
      previousInput.focus();
    }
  }

  combineAllNumber() {
    // Access the values using the form controls
    const otp1 = this.otpForm["controls"]['otp1'].value;
    const otp2 = this.otpForm["controls"]['otp2'].value;
    const otp3 = this.otpForm["controls"]['otp3'].value;
    const otp4 = this.otpForm["controls"]['otp4'].value;
    const otp5 = this.otpForm["controls"]['otp5'].value;
    const otp6 = this.otpForm["controls"]['otp6'].value;

    // Combine the values into a single string
    const combinedValue = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;

    // Log or use the combined value as needed
    console.log(combinedValue);
    return combinedValue;
  }
  verifyOtp(){
  //  this.encryptedMobile = this.encryptUsingAES256(this.numberForm['controls']['number'].value);
   const otp = this.combineAllNumber();
   this.isDisabledVerify = true;
   let endPoints = "otp/verify";
    const params = {
      code: otp,
      txn_id: this.txnId,
    };
    this.commonService.formPostMethod(params, endPoints).subscribe(
      (res: any) => {
      this.isDisabledVerify = true;
        this.openSnackBar([res?.message || "Success !"], "", "success");
        this.router.navigate(['/home']);
        this.dialogRef.close();
      },
      (error: any) => {
        this.isDisabledVerify = false;  
        this.errorMessage = error?.error?.message;
        this.openSnackBar([error?.error?.message || "Failed !"], "");
        console.log("login error", error);
      }
    );
  }

  resendOtp(){
    this.errorMessage = '';
    this.isDisabledResendOtp = true;
    this.otpForm.reset();
    const params = {
      txn_id: this.txnId
     }
    this.commonService.formPostMethod(params, '/otp/refresh').subscribe((res:any)=>{
      this.openSnackBar([res?.message || 'Success !'] , '','success');
      this.isOtpSent = true;
      this.startCountdown(60);
    },
    (error:any)=>{
      this.openSnackBar([error?.error?.message || 'Failed !'] ,"");
    }
    )
  }
  inputChanges(){
    this.otpForm.valueChanges.subscribe(()=>{
      if(this.otpForm['status'] == "VALID"){
        this.isDisabledVerify = false;
      }else {
        this.isDisabledVerify = true;

      }
    });
  }
  startCountdown(time:number = 60): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    const timer$ = interval(1000).pipe(take(time)); // Countdown for 60 seconds
    this.countdownSubscription = timer$.subscribe((second) => {
      console.log("Second", second, time);
      this.isDisabledResendOtp = false;
      this.countdown = time - second;
      if(time-second == 1)
      setTimeout(() => {
        this.countdown = 0;
      },1000)
      
    });
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }
  private validatePassword(control: any): { [key: string]: any } | null {
    const password = control.value;
    if (!/[A-Z]/.test(password)) {
      return { 'uppercase': true };
    }
    if (!/[a-z]/.test(password)) {
      return { 'lowercase': true };
    }
    if (!/\d/.test(password)) {
      return { 'number': true };
    }
    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      return { 'specialCharacter': true };
    }
    if(/\s/g.test(password)){
      return  { 'space': true };
    }
    return null;
  }

  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  registerUser(payload:any, userResponse:any){
    let ipsaPayload = {
      "endpoint": `/admin/submission?query=true`,
      "headers": {
          "Content-Type": "application/json",
      },
      "requestBody": {
           "data": {
          "email": payload.email,
          "password": payload.password
      }
      },
      "method": "POST"
  };
    this.commonService.registerIpsaUser(ipsaPayload).subscribe((ipsres:any) => {
      if(ipsres.hasOwnProperty('_id')){
        this.openSnackBar([userResponse?.message || 'Success !'] , '','success');
        this.txnId = userResponse?.data?.txn_id;
        this.isSignUpSuccess = true;
      
      }
    }, error => {
        this.openSnackBar([error?.error?.message ? error?.error?.message : 'failed !'])
      
      console.log(error, "ipsa");
    })
  }


  getTargetterUserData(data:any, userResponse:any){
    let endpoint = `/user/submission?data.email=${data.email}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {},
      "method": "GET"
    };
    this.commonService.targettedUserData(payload).subscribe((res:any) => {
      if(res.length){
        this.updateUserData(res, data, userResponse);
      }else{
        this.registerUser(data, userResponse)
      }
    },(error) => console.log(error));
  }

  updateUserData(userData:any, data:any, userResponse:any){
    let endpoint = `/user/submission/${userData[0]._id}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {
        "data": {
          "email": data.email,
          "password": data.password
    }
    },
      "method": "PUT"
    }
      this.commonService.updateUserForIpsa(payload).subscribe(res => {
        if(res){
          this.openSnackBar([userResponse?.message || 'Success !'] , '','success');
        this.txnId = userResponse?.data?.txn_id;
        this.isSignUpSuccess = true;
        
      }
      
        
      })
  }

  registerIdhUser(payload:any, ipsaPayload:any){
    this.commonService.createSignUpUser(payload).subscribe((res:any) => {
      if(res.success){
        this.getTargetterUserData(ipsaPayload, res)
      }
    },(error:any)=>{
      this.isSignUpSuccess = false;
      this.openSnackBar([error?.error?.message || 'Failed !'] ,"");
    });
  }

  privacyStatement(){
    let dialogRef = this.dialog.open(GDPRComponent, {
      width: '700px',
      panelClass: 'gdpr-portalDialog',
      data: {}
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    })
  }
}
