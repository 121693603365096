import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';

@Component({
  selector: 'app-master-form-popup',
  templateUrl: './master-form-popup.component.html',
  styleUrls: ['./master-form-popup.component.scss']
})
export class MasterFormPopupComponent implements OnInit {
  files: any;
  formBuilder: any;

  constructor(
    public dialogRef: MatDialogRef<MasterFormPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public addData: any,
    private _fb: FormBuilder,
    private commonService: CommonServiceService,
    private snackBar: MatSnackBar,
  ) {
    this.getCountry();
    //this.getIP(null);
    this.intilizeForms();
  }
  countryForm !: FormGroup;
  ipForm !: FormGroup;
  libraryForm!: FormGroup; 
  factoryForm!: FormGroup;
  countryList: any = [];
  ipList: any = [];
  userId:any= '';
  imgURL: string = "";
  imageAllData: any = [];
  putAPIurl: any;
  payloadURL: string = "";
  isEditMode: boolean = false;
  @ViewChild('fileSet', { static: true }) fileSet: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef!: ElementRef;
  fileUploadInfo:string = "*File size limit: 5MB*";

  ngOnInit() {
    console.log('addData', this.addData);
    this.isEditMode = this.addData?.edit;
    this.payloadURL = this.imgURL = this.addData?.rowData?.country_icons ? this.addData?.rowData?.country_icons : "";
    let userData:any =(sessionStorage.getItem('userdata'));
    this.userId = JSON.parse(userData)?.id;

  }
  close() {
    this.dialogRef.close();
  }
  intilizeForms(){
    if (this.addData?.data?.key == 'countryMaster') {
      this.initilizeCountryForm();
    }else if(this.addData?.data?.key == 'ipMaster'){
      this.initilizeIPForm()
    }else{
      this.initilizeFactoryForm();
    }
  }
  initilizeCountryForm() {
    this.countryForm = this._fb.group({
      name: ["", Validators.required],
      countryImage :["",Validators.required]
      // email: ["", [Validators.required, Validators.email]],
    });
    if(this.addData?.rowData){
      console.log(this.addData?.rowData,"check1212")
      this.countryForm.patchValue({
        name: this.addData?.rowData?.name,
        countryImage: this.addData?.rowData?.country_icons
      })
    }
  }

  // {
  //   "name": "factory abc",
    // "industrialpark_id": 1,
    // "type": "Type A",
    // "operation": "Textile",
  //   "createdBy": 1
  // }

  // {
  //   "name": "Industrial Park 11",
  //   "country_id": 7,
  //   "createdBy": 1
  // }
  initilizeIPForm() {
    this.ipForm = this._fb.group({
      name: ["", Validators.required],
      country_id: ["", [Validators.required]],
    });
    if(this.addData?.rowData){
      this.ipForm.patchValue({
        name: this.addData?.rowData?.name,
        country_id: this.addData?.rowData?.country_id
      })
    }
  }
  initilizeFactoryForm() {
    this.factoryForm = this._fb.group({
      name: ["", Validators.required],
      country_id: ["", [Validators.required]],
      industrialpark_id: ["", Validators.required],
      type: ["A", Validators.required],
      operation: ["Tex", Validators.required],
      // email: ["", [Validators.required, Validators.email]],
    });
    if(this.addData?.rowData){
      this.factoryForm.patchValue({
        name: this.addData?.rowData?.name,
        country_id: this.addData?.rowData?.['industrialpark.country.id'],
        industrialpark_id: this.addData?.rowData?.['industrialpark_id']
      })
      if(this.addData?.rowData?.['industrialpark.country.id']){
        this.getIP(this.addData?.rowData?.['industrialpark.country.id'])
      }
     
    }
  }

  getCountry() {
    this.commonService.formGetMethod('country/list?fetched=all', {}).subscribe((res: any) => {
      this.countryList = res?.data;
    },
      (error: any) => {
        this.openSnackBar([error?.error?.message || 'Failed !'], "");
      }
    )
  }
  getIP(id:null) {
    let params:any = {};
    if(id != null){
      params['country_id'] = id
    }
   
    this.commonService.formGetMethod('industrialpark/list', params).subscribe((res: any) => {
      this.ipList = res?.data
    },
      (error: any) => {
        this.openSnackBar([error?.error?.message || 'Failed !'], "");
      })
  }

  createCountryForm() {
    console.log("checkvalue")
    let payload = {
      name: this.countryForm?.controls['name']?.value,
      country_icons: this.payloadURL,
      createdBy: this.userId,
    }
    let apiEndPoints = this.addData?.rowData ? `country/update/${this.addData?.rowData?.id}` : 'country/create'
    if (!this.countryForm?.invalid) {
      this.commonService.formPostMethod(payload, apiEndPoints).subscribe((res: any) => {
        this.openSnackBar([res?.message || 'Success !'], '', 'success');
        this.dialogRef.close({event:'dataAdded'});
      },
        (error: any) => {
          this.openSnackBar([error?.error?.message || 'Failed !'], "");
        })
    }
  }
  createIPForm() {  
    let payload = {
      name: this.ipForm?.controls['name']?.value,
      "country_id": this.ipForm?.controls['country_id']?.value,
      createdBy: this.userId
    }
    let apiEndPoints = this.addData?.rowData ? `industrialpark/update/${this.addData?.rowData?.id}` : 'industrialpark/create'
    if (!this.ipForm?.invalid) {
      this.commonService.formPostMethod(payload, apiEndPoints).subscribe((res: any) => {
        this.openSnackBar([res?.message || 'Success !'], '', 'success');
        this.dialogRef.close({event:'dataAdded'});
      },
        (error: any) => {
          this.openSnackBar([error?.error?.message || 'Failed !'], "");
        })
    }
  }
  createFactoryForm() {
    let payload = {
       name: this.factoryForm?.controls['name']?.value,
      "country_id": this.factoryForm?.controls['country_id']?.value,
      "industrialpark_id": this.factoryForm?.controls['industrialpark_id']?.value,
      "type": 'Type A',
      "operation": "Textile",
      createdBy: this.userId
    }
    let apiEndPoints = this.addData?.rowData ? `factory/update/${this.addData?.rowData?.id}` : 'factory/create'
    if (!this.ipForm?.invalid) {
      this.commonService.formPostMethod(payload, apiEndPoints).subscribe((res: any) => {
        this.openSnackBar([res?.message || 'Success !'], '', 'success');
        this.dialogRef.close({event:'dataAdded'});
      },
        (error: any) => {
          this.openSnackBar([error?.error?.message || 'Failed !'], "");
        })
    }
  }

  openSnackBar(message: any, action = '', className = 'parmary') {
    this.snackBar.open(message, action, {
      duration: 3000,
      // verticalPosition: 'top',
      // horizontalPosition:'right',
      panelClass: [className]
    });
  }
  countryChanges(e:any){
    console.log('eeeeee', e);
    let id = e.value;
    this.getIP(id);
  }
  onFileDropped(event:any) {
    console.log("kokoko",event);
    
    this.files = [];
    this.prepareFilesList(event);
    // if ($event) {
    //   this.file = $event[0];
    // }
    // let payload = {
    //   "file_name": ,
    //   "mime_type": "application/jpeg"
    // }
    // this.commonService.uploadImage(payload).subscribe((res:any) => {

    // })
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      console.log("filesss",files);
      
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }
    /**
 * Simulate the upload process
 */
    uploadFilesSimulator(index: number) {
      setTimeout(() => {
        if (index === this.files.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1);
            } else {
              this.files[index].progress += 5;
            }
          }, 200);
        }
      }, 1000);
    }
      /**
   * handle file from browsing
   */
      fileBrowseHandler(files: any) {
        let maximumFileSize = 5 * 1024 * 1024; // 5MB in bytes
        let currentFileSize = files?.target?.files[0]?.size;

        if(currentFileSize > maximumFileSize){
          files.target.value = null;
          this.openSnackBar(['Please upload file under 5 MB !' || 'Failed !'] , '');
          return ;
        }
    
        let payload = [{
            "file_name": files.target.files[0].name,
            "mime_type": files.target.files[0].type
          }];
          this.commonService.uploadImage(payload).subscribe((res:any) => {
            if(res.success){
              console.log("POSTresponse",res);
              this.imageAllData = res.data;
              this.imageAllData.forEach((ele:any) => {
                this.putAPIurl = ele?.url;
                this.payloadURL = ele?.file_url;
                this.imgURL = ele?.file_url;
              });
              this.commonService.imagePUTapi(this.putAPIurl, files.target.files[0]).subscribe((res:any) => {
                console.log("putresponse",res);
                
              })
            }
          })
        
        this.files = [];
        this.prepareFilesList(files.target.files);
        
        // console.log('files', files)
      }
    
    /**
   * Delete file from files list
   * @param index (File index)
   */
    deleteFile(index: number) {
      if (this.fileDropRef) {
        this.fileDropRef.nativeElement.value = '';
      }
      
      this.files?.splice(index, 1);
      this.imgURL = "";
      this.payloadURL = "";
      if (this.files.length == 0) {
        this.libraryForm.reset();4
        this.libraryForm.get('files')?.reset();
      }
    }
    
  openDocument(){
    window.open(this.imgURL);
  }
  
  closeDialog(){
    this.dialogRef.close();
  }
}
