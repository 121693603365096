<!-- <div class="d-flex align-items-center"> -->
    <div class="d-flex align-items-center">
        <ng-container *ngFor="let item of countryData; let index=index">
            <button class="btn border matCheckbox me-3"  [class.active]="item.is_checked">
                <mat-checkbox class="customPolicyCheckboxColor" [disableRipple]="true"
                [matTooltip]="item?.name" (click)="$event.stopPropagation()"
                [(ngModel)]="item.is_checked" [checked]="isCheckedModule(item)"
                [ngModelOptions]="{ standalone: true }" [disabled]="selectedCountry?.length ==1 && item.is_checked"
                (change)="onModuleChange($event, item,'country')" class="customColorText d-flex customFlag">
                <span><img width="20" [src]="item?.country_icons" alt=""></span>
                {{ item?.name ? item?.name : '---' }}
            </mat-checkbox>
            </button>
        </ng-container>
        <button class="btn d-flex align-items-center me-1" mat-button [matMenuTriggerFor]="menu" (menuClosed)="allowCloseOnClickOut()"  #t="matMenuTrigger"
        [ngClass]="{'filterBtnWithClick': t.menuOpen, 'filterBtn': !t.menuOpen, 'd-none': ['countryview'].includes(selectedTab)}">
           <img [src]="t.menuOpen ? '../../../assets/images/filterIcon.svg' : '../../../assets/dashboardMedia/filter.svg'" alt="" style="width: 17px" [ngStyle]="{'color': t.menuOpen ? '#00625F' : ''}"> {{filterText[selectedTab]}}
       </button>
        <mat-menu #menu="matMenu" class="filter_matTab">
            <div (click)="$event.stopPropagation();">
                <div class="accordion" id="accordionExample">
                    <!-- my work start -->
                    <!-- <div class="accordion-item border-0" *ngIf="['ipview','factoryview','countryview'].includes(selectedTab)">
                        <h2 class="accordion-header border-0" id="headingOne">
                            <button class="accordion-button border-0 pt-0 pb-1" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Country
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body form-body mb-2 py-1"> -->

                                <!-- country work start -->
                                <!-- <ng-container *ngIf="countryData?.length">
                                    <ng-container>
                                        <span class="d-flex;justify-content-between">
                                            <span class="pe-2">
                                                <mat-checkbox class="customPolicyCheckboxColor" [disableRipple]="true"
                                                    (click)="$event.stopPropagation()"
                                                    [ngModelOptions]="{ standalone: true }" (change)="selectAll($event,'country')" [disabled]="countryData?.length ==1 "
                                                    [checked] = "isCountryAllSelect(countryData)"
                                                    [indeterminate]="isModuleOver(countryData,'country') ">
                                                    Select All
                                                </mat-checkbox>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngFor="let item of countryData; let index=index">
                                        <div *ngIf="index < (showAllCountry ? countryData.length : 3)">
                                            <mat-checkbox class="customPolicyCheckboxColor" [disableRipple]="true"
                                                [matTooltip]="item?.name" (click)="$event.stopPropagation()"
                                                [(ngModel)]="item.is_checked" [checked]="isCheckedModule(item)"
                                                [ngModelOptions]="{ standalone: true }" [disabled]="countryData?.length ==1 "
                                                (change)="onModuleChange($event, item,'country')" class="customColorText">
                                                {{ item?.name ? item?.name : '---' }}
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="countryData?.length > 3">
                                        <button class="show-btn px-0" mat-button (click)="toggleShowMore('country')">Show {{ showAllCountry ? 'Less' : 'More'
                                            }}</button>
                                    </ng-container>
                                </ng-container>
                               
                            </div>
                        </div>
                    </div> -->

                    <div class="accordion-item border-0" *ngIf="['ipview','factoryview'].includes(selectedTab)">
                        <h2 class="accordion-header border-0" id="headingTwo">
                            <button class="accordion-button border-0 pt-0 pb-1" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                IPs
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body form-body mb-2 py-1">
                                <!-- Ip view work start -->


                                <ng-container *ngIf="ipViewData?.length">
                                    <ng-container>
                                        <span class="d-flex;justify-content-between">
                                            <span class="pe-2">
                                                <mat-checkbox class="customPolicyCheckboxColor" [disableRipple]="true"
                                                    (click)="$event.stopPropagation()"
                                                    [ngModelOptions]="{ standalone: true }" (change)="selectAll($event,'ipView')"
                                                    [checked]="isCountryAllSelect(ipViewData)"
                                                    [indeterminate]="isModuleOver(ipViewData,'ipView') " [disabled]="ipViewData?.length ==1 ">
                                                    Select All
                                                </mat-checkbox>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngFor="let item of ipViewData; let index=index">
                                        <div *ngIf="index < (showAllIpView ? ipViewData.length : 3)">
                                            <mat-checkbox class="customPolicyCheckboxColor" [disableRipple]="true"
                                                [matTooltip]="item?.model" (click)="$event.stopPropagation()"
                                                [(ngModel)]="item.is_checked" [checked]="isCheckedModule(item)"
                                                [ngModelOptions]="{ standalone: true }" [disabled]="ipViewData?.length ==1 "
                                                (change)="onModuleChange($event, item,'ipView')" class="customColorText">
                                                <img [src]="item?.country_icons" alt="" class="countryFlag">&nbsp;
                                                <span [matTooltip]="'Country: '+item['country.name']">{{ item?.name ? item?.name : '---' }}</span>
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>
                                    
                                    <ng-container *ngIf="ipViewData?.length > 3">
                                        <button class="show-btn px-0" mat-button (click)="toggleShowMore('ipView')">Show {{ showAllIpView ? 'Less' : 'More'
                                            }}</button>
                                    </ng-container>
                                </ng-container>
                                <!-- Ip view work end -->
                                
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0" *ngIf="['factoryview'].includes(selectedTab)">
                        <h2 class="accordion-header border-0" id="headingThree">
                            <button class="accordion-button border-0 pt-0 pb-1" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                <!-- Status -->
                                Factory
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body form-body py-1">
                                <!-- factory work start -->

                                <ng-container *ngIf="factoryData?.length">
                                    <ng-container>
                                        <span class="d-flex;justify-content-between">
                                            <span class="pe-2">
                                                <mat-checkbox class="customPolicyCheckboxColor" [disableRipple]="true"
                                                    (click)="$event.stopPropagation()"
                                                    [ngModelOptions]="{ standalone: true }" (change)="selectAll($event,'factory')"
                                                    [checked]="isCountryAllSelect(factoryData)"
                                                    [indeterminate]="isModuleOver(ipViewData,'factory') " [disabled]="factoryData?.length ==1 ">
                                                    Select All
                                                </mat-checkbox>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngFor="let item of factoryData; let index=index">
                                        <div *ngIf="index < (showAllFactory ? factoryData.length : 3)">
                                            <mat-checkbox class="customPolicyCheckboxColor" [disableRipple]="true"
                                                [matTooltip]="item?.model" (click)="$event.stopPropagation()"
                                                [(ngModel)]="item.is_checked" [checked]="isCheckedModule(item)"
                                                [ngModelOptions]="{ standalone: true }" [disabled]="factoryData?.length ==1 "
                                                (change)="onModuleChange($event, item,'factory')" class="customColorText">
                                                <img [src]="item?.country_icons" alt="" class="countryFlag">&nbsp;
                                                <span [matTooltip]="'IP: '+item['industrialpark.name']">{{ item?.name ? item?.name : '---' }}</span>
                                            </mat-checkbox>
                                        </div>
                                    </ng-container>
    
                                    <ng-container *ngIf="factoryData?.length > 3">
                                        <button class="show-btn px-0" mat-button (click)="toggleShowMore('factory')">Show {{ showAllFactory ? 'Less' : 'More'
                                            }}</button>
                                    </ng-container>
                                </ng-container>
                                
                                <!-- factor work end -->
                            </div>
                        </div>
                    </div>

                </div>
                <hr class="mb-0">
                <div class="text-end">
                    <!-- <button class="btn border-0 fw-600 reset-btn" (click)="reset()">Reset</button> -->
                    <button class="btn border-0 fw-600 apply-btn py-0" (click)="apply()" [disabled]="isDisabled()">Apply</button>
                </div>

            </div>
        </mat-menu>
    </div>