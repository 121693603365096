<div class="Footer_bottom">
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="d-md-flex justify-content-between my-3">
                        <div class="tabs-list rounded-pill mb-3 overflow-auto">
                            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                                <!-- <mat-button-toggle class="me-1" value="bold" checked (click)="selectedTab('homepage')">Homepage</mat-button-toggle>
                                <mat-button-toggle class="me-1" value="bold" (click)="selectedTab('resources')">Resources</mat-button-toggle>
                                <mat-button-toggle class="me-1" value="bold" (click)="selectedTab('impactStories')">Impact Story</mat-button-toggle>
                                <mat-button-toggle class="me-1" value="bold" (click)="selectedTab('glossary')">Glossary</mat-button-toggle>
                                <mat-button-toggle class="me-1" value="bold" (click)="selectedTab('contactUs')">Contact Us</mat-button-toggle>
                                <mat-button-toggle class="me-1" value="bold" (click)="selectedTab('teams')">Teams</mat-button-toggle>
                                <mat-button-toggle class="me-1" value="bold" (click)="selectedTab('ourPartners')">Our Partners</mat-button-toggle> -->
                                <ng-container *ngFor="let tab of pageAccoradingAction let i=index">
                                    <!-- {{tab | json}} -->
                                    <mat-button-toggle value="bold" [checked]="tab?.isChecked"
                                        (click)="selectedTab(tab.selectedTabs)"
                                        *ngIf="hasActionTrueForModel(tab?.model)">{{tab.label}}</mat-button-toggle>
                                </ng-container>
                            </mat-button-toggle-group>
                        </div>
                        <div class="">
                            <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                <button class="btn rounded-pill text-white" style="background-color: #00625F;" (click)="openForm(false, '')" *ngIf="((currentTab != 'contactUs' && currentTab != 'aboutUs') && (buttonPermission?.key && buttonPermission?.key === 'create' && buttonPermission?.isAction))">+ Add New</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- <div class="row" style="z-index: 999;">
                    <div class="col-12">
                        <div class="position-relative searchbar" *ngIf="currentTab == 'resources'">
                            <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-icon" alt="">
                            <input type="search" placeholder="Search by" class="form-control search-input" (keydown.enter)="onKeyDownEvent()" [(ngModel)]="searchedValue">
                        </div>
                    </div>
                </div> -->
                <div class="row bg-white my-3 justify-content-between" *ngIf="currentTab == 'resources'">
                    <div class="col-auto" >
                        <mat-paginator class="f-14" [length]="totalDataCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalDataCount !== 0 && (resourceCenter?.length || subcategorydata?.length || subcategoryReportdata?.length)"></mat-paginator>
                    </div>
                    <div class="col-auto my-2">
                        <div class="position-relative searchbar d-flex" *ngIf="currentTab == 'resources'">
                            <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-icon" alt="">
                            <input type="text" placeholder="Search by" class="form-control search-input me-2" (keydown.enter)="onKeyDownEvent()" [(ngModel)]="searchedValue">
                            <button class="btn btn-success btn-sm clearSearchBtn" (click)="clearSearch()" *ngIf="searchedValue">Clear</button>
                        </div>
                    </div>
                </div>
    
                <!-- RESOURCE TAB CODE PART -->
                    <mat-tab-group class="mat-tab-mb" *ngIf="currentTab == 'resources'" [selectedIndex]="0" (selectedTabChange)="tabWiseData($event)">
                        <ng-container *ngFor="let item of subtabs" >
                            <mat-tab [label]="item.tabName" *ngIf="item?.isActive">
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th class="" *ngFor="let item of resourceSubTableHead[selectedSubTabName]">
                                                    {{item?.thead}}
                                                    <!-- <i class="fa fa-fw fa-sort pointerHead" *ngIf="item?.key" (click)="sortTable(item?.key)">
                                                    </i> -->
                                                    <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="item?.key && currentTab != 'aboutUs'" (click)="sortTable(item?.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                                    <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="item?.key && currentTab != 'aboutUs'" (click)="sortTable(item?.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                                </th>
                                            </tr>
                                        </thead>
                                        <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                                <ng-container *ngIf="hasActionTrueForModel('category'); else permissionIsNotAllowed">
                                                    <tbody class="bg-white" *ngIf="selectedSubTabName == 'Category'">
                                                        <tr *ngFor="let item of resourceCenter; let i = index">
                                                            <td>{{i + skip + 1}}</td>
                                                            <td [matTooltip]="item?.name?.length < 75 ? '' : item?.name">{{item?.name | truncatePipe : 75}}</td>
                                                            <td>{{item?.types}}</td>
                                                            <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                                            <td><img [src]="item?.image" class="img-fluid thumbnailImg" alt=""></td>
                                                            <td>
                                                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                                    <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                    <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                        (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                        <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                        <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </ng-container>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </ng-container>
                                                <!-- <ng-template #permissionIsNotAllowed> -->
                                                    <!-- <div>
                                                        Permission Not allowed to view the table
                                                    </div> -->
                                                <!-- </ng-template> -->
                                        <!-- </ng-container> -->
                                        
                                        <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->

                                                <ng-container *ngIf="hasActionTrueForModel('subcategory'); else permissionIsNotAllowed">
                                                    <tbody class="bg-white" *ngIf="selectedSubTabName == 'Sub Category'">
                                                        <tr *ngFor="let item of subcategorydata; let i = index">
                                                            <td>{{i + skip + 1}}</td>
                                                            <td [matTooltip]="item?.name?.length < 75 ? '' : item?.name">{{item?.name | truncatePipe : 75}}</td>
                                                            <td>{{item['category.name']}}</td>
                                                            <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                                            <td><img src="../../../assets/images/folder-solid.svg" class="img-fluid thumbnailFolder" alt=""></td>
                                                            <td>
                                                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                                    <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                    <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                        (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                        <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                        <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </ng-container>
                                                                <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)">
                                                                <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                                    <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                    <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                                </mat-radio-group> -->
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </ng-container>
                                                <ng-template #permissionIsNotAllowed>
                                                    <!-- <div>
                                                        Permission Not allowed to view the table
                                                    </div> -->
                                                </ng-template>
                                        <!-- </ng-container> -->
                                        
                                        <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                                <ng-container *ngIf="hasActionTrueForModel('subcategoryreport'); else permissionIsNotAllowed">
                                                    <tbody class="bg-white" *ngIf="selectedSubTabName == 'Sub-Category Report'">
                                                        <tr *ngFor="let item of subcategoryReportdata; let i = index">
                                                            <td>{{i + skip + 1}}</td>
                                                            <td [matTooltip]="item?.name?.length < 75 ? '' : item?.name">{{item?.name | truncatePipe : 75}}</td>
                                                            <td>{{item['subcategory.name']}}</td>
                                                            <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                                            <td><img src="../../../assets/icons/document.png" class="img-fluid thumbnailFile" alt="" matTooltip="View Document" (click)="openFile(item)"></td>
                                                            <td>
                                                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                                    <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                    <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                        (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                        <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                        <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                                    </mat-radio-group>
                                                                </ng-container>
                                                                <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)">
                                                                <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                                    <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                    <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                                </mat-radio-group> -->
                                                            </td>
                                                        </tr>
                                                </tbody>
                                                </ng-container>
                                                <ng-template #permissionIsNotAllowed>
                                                    <!-- <div>
                                                        Permission Not allowed to view the table
                                                    </div> -->
                                                </ng-template>
                                        <!-- </ng-container> -->
                                        
                                    </table>
                                    <div *ngIf="isNoDataFound && currentTab == 'resources' && selectedSubTabName == 'Category'" class="d-flex justify-content-center">
                                        <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                                    </div>
                                    <div *ngIf="isNoDataFound && currentTab == 'resources' && selectedSubTabName == 'Sub Category'" class="d-flex justify-content-center">
                                        <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                                    </div>
                                    <div *ngIf="isNoDataFound && currentTab == 'resources' && selectedSubTabName == 'Sub-Category Report'" class="d-flex justify-content-center">
                                        <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                                    </div>
                                </div>
                            </mat-tab>
                        </ng-container>
                    </mat-tab-group>
                  
    
                <!-- GENERIC TABLE -->
                <div class="card my-3 border-0" *ngIf="currentTab != 'resources'">
                    <div class="card-body">
                        <div class="d-sm-flex mb-3 justify-content-between align-items-center" *ngIf="currentTab != 'resources' && currentTab != 'aboutUs'">
                            <!-- <div class="mb-sm-0 mb-2">Showing Result <span class="fw-500">1-{{totalDataCount >= 10 ? 10 : totalDataCount}}</span> of {{totalDataCount}}</div> -->
                            <div>
                                <mat-paginator class="f-14" [length]="totalDataCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalDataCount !== 0 && (homeData?.length || impactData?.length || glossaryData?.length || contactUsTable?.length || allTeamData?.length || partnerData?.length)"></mat-paginator>
                            </div>
                            <div class="position-relative d-flex">
                                <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-icon" alt="">
                            <input type="text" placeholder="Search by" class="form-control search-input me-2" (keydown.enter)="onKeyDownEvent()" [(ngModel)]="searchedValue">
                                <button class="btn btn-success btn-sm clearSearchBtn" (click)="clearSearch()" *ngIf="searchedValue">Clear</button>
                            </div>
                        </div>
                    
                        <div class="table-responsive">
                            <table class="table table-borderless">
                                <thead>
                                    <tr>
                                        <th *ngFor="let heading of commonTableHead[currentTab]">{{heading?.thead}}
                                            <!-- <i class="fa fa-fw fa-sort pointerHead" *ngIf="heading?.key" (click)="sortTable(heading?.key)">
                                            </i> -->
                                            <img #imageElement src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="heading?.key && currentTab != 'aboutUs'" (click)="sortTable(heading?.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (heading?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                            <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="heading?.key && currentTab != 'aboutUs'" (click)="sortTable(heading?.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (heading?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                        </th>
                                    </tr>
                                </thead>

                                
                                <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                        <ng-container *ngIf="hasActionTrueForModel('cms'); else permissionIsNotAllowed">
                                            <tbody *ngIf="currentTab == 'homepage'">
                                                <tr *ngFor="let item of homeData; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <td [matTooltip]="item?.title?.length < 75 ? '' : item?.title">{{item?.title | truncatePipe : 75}}</td>
                                                    <td>{{item['section.name'] ? item['section.name'] : '---'}}</td>
                                                    <!-- <td [matTooltip]="item?.subTitle?.length < 75 ? '' : item?.subTitle">{{item?.subTitle | truncatePipe : 75}}</td> -->
                                                    <td [matTooltip]="item?.subTitle?.length < 75 ? '' : stripHtmlTags(item?.subTitle)" >
                                                        <div [innerHTML]=" stripHtmlTags(item?.subTitle) | truncatePipe : 75"></div>
                                                        <!-- <div [innerHTML]="item?.subTitle "></div> -->
                                                      
                                                    </td>

                                                    <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                                    <td>
                                                        <img *ngIf="item.banner"  [src]="item?.banner.includes(',') ? item.imgArray[0] : item?.banner" 
                                                            class="img-fluid thumbnailImg" alt="">
                                                            {{!item.banner ? 'N/A' : '' }}
                                                        </td>
                                                    <td>
                                                        <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                            <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)" matTooltip="Edit">
                                                            <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                            </mat-radio-group>
                                                        </ng-container>
                                                        <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" matTooltip="Edit">
                                                        <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                            <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                            <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                        </mat-radio-group> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #permissionIsNotAllowed>
                                            <!-- <div>
                                                Permission Not allowed to view the table
                                            </div> -->
                                        </ng-template>
                                <!-- </ng-container> -->
                                
                                <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                        <ng-container *ngIf="hasActionTrueForModel('impactstories'); else permissionIsNotAllowed">
                                            <tbody *ngIf="currentTab == 'impactStories'">
                                                <tr *ngFor="let item of impactData; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <td [matTooltip]="item?.title?.length < 75 ? '' : item?.title">{{item?.title | truncatePipe : 75}}</td>
                                                    <!-- <td [matTooltip]="item?.description?.length < 75 ? '' : stripHtmlTags(item?.description)" > -->
                                                    <td>
                                                        <!-- <div [innerHTML]="item?.description | truncatePipe : 75"></div> -->
                                                        <div [innerHTML]=" stripHtmlTags(item?.description) | truncatePipe : 75"></div>

                                                        
                                                    </td>
                                                    <!-- <td [matTooltip]="item?.description?.length < 75 ? '' : item?.description" >
                                                        {{item?.description | truncatePipe : 75}}
                                                        
                                                    </td> -->
                                                    <td>{{item['category.name']}}</td>
                                                    <td>{{item?.ranking ? item?.ranking : '---'}}</td>
                                                    <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                                    <td><img [src]="item?.banner" class="img-fluid thumbnailImg" alt=""></td>
                                                    <td>
                                                        <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                            <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)" matTooltip="Edit">
                                                            <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                            </mat-radio-group>
                                                        </ng-container>
                                                        <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)">
                                                        <mat-radio-group class="mat-radio-active-icon"  [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                            <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                            <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                        </mat-radio-group> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #permissionIsNotAllowed>
                                            <!-- <div>
                                                Permission Not allowed to view the table
                                            </div> -->
                                        </ng-template>
                                <!-- </ng-container> -->
                                
                                <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                        <ng-container *ngIf="hasActionTrueForModel('glossary'); else permissionIsNotAllowed">
                                            <tbody *ngIf="currentTab == 'glossary'">
                                                <tr *ngFor="let item of glossaryData; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <td [matTooltip]="item?.term?.length < 75 ? '' : item?.term">{{item?.term | truncatePipe : 75}}</td>
                                                    <!-- <td [matTooltip]="item?.definition?.length < 75 ? '' : item?.definition">{{item?.definition | truncatePipe : 75}}</td> -->
                                                    <td [matTooltip]="item?.definition?.length < 75 ? '' : stripHtmlTags(item?.definition)">
                                                        <!-- <div [innerHTML]="item?.definition | truncatePipe : 75"></div> -->
                                                        <div [innerHTML]=" stripHtmlTags(item?.definition) | truncatePipe : 75"></div>

                                                    </td>
                                                    <td>
                                                        <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)">
                                                        <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                            <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                            <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                        </mat-radio-group> -->
                                                        <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                            <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)" matTooltip="Edit">
                                                            <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                            </mat-radio-group>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #permissionIsNotAllowed>
                                            <!-- <div>
                                                Permission Not allowed to view the table
                                            </div> -->
                                        </ng-template>
                                <!-- </ng-container> -->
                                
                                <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                        <ng-container *ngIf="hasActionTrueForModel('inquiry'); else permissionIsNotAllowed">
                                            <tbody *ngIf="currentTab == 'contactUs'">
                                                <tr *ngFor="let item of contactUsTable; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <td>{{item?.fullname}}</td>
                                                    <td>{{item?.email}}</td>
                                                    <td [matTooltip]="item?.message?.length < 75 ? '' : item?.message">{{item?.message | truncatePipe : 75}}</td>
                                                    <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                                    <!-- <td><img src="../../../assets/images/user-profile.png" class="img-fluid" alt=""></td> -->
                                                    <!-- <td>
                                                        <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="">
                                                        <img src="../../../assets/images/delete-bin.svg" class="img-fluid pointer" alt="" (click)="deleteLineItem(item)">
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #permissionIsNotAllowed>
                                            <!-- <div>
                                                Permission Not allowed to view the table
                                            </div> -->
                                        </ng-template>
                                <!-- </ng-container> -->
                                
                                <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                        <ng-container *ngIf="hasActionTrueForModel('teammember'); else permissionIsNotAllowed">
                                            <tbody *ngIf="currentTab == 'teams'">
                                                <tr *ngFor="let item of allTeamData; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <td>{{item?.name}}</td>
                                                    <td>{{item?.designation}}</td>
                                                    <td [matTooltip]="item?.about?.length < 75 ? '' : item?.about">{{item?.about | truncatePipe : 75}}</td>
                                                    <!-- <td [matTooltip]="item?.name?.length < 75 ? '' : item?.name">{{item?.name | truncatePipe : 75}}</td> -->
                                                    <td><img *ngIf="item.profile"  [src]="item?.profile" class="img-fluid thumbnailImg" alt="">
                                                        {{!item.profile ? 'N/A' : '' }}
                                                    </td>
                                                    <td>
                                                        <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" matTooltip="Edit">
                                                        <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                            <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                            <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                        </mat-radio-group> -->
                                                        <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                            <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)" matTooltip="Edit">
                                                            <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                            </mat-radio-group>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #permissionIsNotAllowed>
                                            <!-- <div>
                                                Permission Not allowed to view the table
                                            </div> -->
                                        </ng-template>
                                <!-- </ng-container> -->
                                
                                <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']"> -->
                                        <ng-container *ngIf="hasActionTrueForModel('partners'); else permissionIsNotAllowed">
                                            <tbody *ngIf="currentTab == 'ourPartners'">
                                                <tr *ngFor="let item of partnerData; let i = index">
                                                    <td>{{i + skip + 1}}</td>
                                                    <td>{{item?.title}}</td>
                                                    <td [matTooltip]="item?.description?.length < 75 ? '' : item?.description">{{item?.description | truncatePipe : 75}}</td>
                                                    <td>{{item?.ranking ? item?.ranking : '---'}}</td>
                                                    <td><img *ngIf="item.image"  [src]="item?.image" class="img-fluid thumbnailImg" alt="">
                                                        {{!item.image ? 'N/A' : '' }}
                                                    </td>
                                                    <td>
                                                        <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                            <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)" matTooltip="Edit">
                                                            <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                                (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                                <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                                <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                            </mat-radio-group>
                                                        </ng-container>
                                                        <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" matTooltip="Edit">
                                                        <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                            <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                            <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                        </mat-radio-group> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </ng-container>
                                        <ng-template #permissionIsNotAllowed>
                                            <!-- <div>
                                                Permission Not allowed to view the table
                                            </div> -->
                                        </ng-template>
                                <!-- </ng-container>  -->

                                <ng-container *ngIf="hasActionTrueForModel('aboutUs'); else permissionIsNotAllowed">
                                    <tbody *ngIf="currentTab == 'aboutUs'">
                                        <tr *ngFor="let item of aboutUsData; let i = index">
                                            <td>{{i + skip + 1}}</td>
                                            <td [matTooltip]="item?.title?.length < 75 ? '' : item?.title">{{item?.title | truncatePipe : 75}}</td>
                                            <!-- <td>{{item['section.name']}}</td> -->
                                            <!-- <td [matTooltip]="item?.subTitle?.length < 75 ? '' : item?.subTitle">{{item?.subTitle | truncatePipe : 75}}</td> -->
                                            <td [matTooltip]="item?.subTitle?.length < 75 ? '' : stripHtmlTags(item?.subTitle)" >
                                                <div [innerHTML]=" stripHtmlTags(item?.subTitle) | truncatePipe : 75"></div>
                                                <!-- <div [innerHTML]="item?.subTitle "></div> -->
                                              
                                            </td>

                                            <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                            <td>
                                                <img *ngIf="item.banner"  [src]="item?.banner.includes(',') ? item.imgArray[0] : item?.banner" 
                                                    class="img-fluid thumbnailImg" alt="">
                                                    {{!item.banner ? 'N/A' : '' }}
                                                </td>
                                            <td>
                                                <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                    <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)" matTooltip="Edit">
                                                    <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive"
                                                        (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)">
                                                        <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                        <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                    </mat-radio-group>
                                                </ng-container>
                                                <!-- <img src="../../../assets/images/edit.svg" class="img-fluid me-2 pointer" alt="" (click)="openForm(true, item)" matTooltip="Edit">
                                                <mat-radio-group class="mat-radio-active-icon" [(ngModel)]="item.isActive" (ngModelChange)="onRadioChange($event, item.id)" [ngModelOptions]="{standalone: true}">
                                                    <mat-radio-button [value]="true" color="primary">Active</mat-radio-button>&nbsp;
                                                    <mat-radio-button [value]="false" color='warn'>Inactive</mat-radio-button>
                                                </mat-radio-group> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </ng-container>
                                <ng-template #permissionIsNotAllowed>
                                    <!-- <div>
                                        Permission Not allowed to view the table
                                    </div> -->
                                </ng-template>
                                
                               
                            </table>
                            <div *ngIf="isNoDataFound && currentTab == 'homepage'" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                            <div *ngIf="isNoDataFound && currentTab == 'impactStories'" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                            <div *ngIf="isNoDataFound && currentTab == 'glossary'" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                            <div *ngIf="isNoDataFound && currentTab == 'contactUs'" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                            <div *ngIf="isNoDataFound && currentTab == 'teams'" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                            <div *ngIf="isNoDataFound && currentTab == 'ourPartners'" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                            <div *ngIf="isNoDataFound && currentTab == 'aboutUs'" class="d-flex justify-content-center">
                                <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</div>