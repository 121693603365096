<div class="Footer_bottom">
    <section class="subscriber-section">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <h4 class="fs-24 fw-bold">Subscribers</h4>
                </div>
                <div class="col">
                    <div class="card my-3 border-0">
                        <div class="card-body">
                            <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                <ng-container
                                    *ngIf="buttonPermission?.key && buttonPermission?.key === 'list' && buttonPermission?.isAction">

                                    <div class="row justify-content-start align-items-center mb-3">
                                        <div class="col">
                                            <mat-paginator class="f-14 d-flex justify-content-start" [length]="totalDataCount"
                                                [pageSize]="limit" aria-label="Select page" (page)="setPage($event)"
                                                showFirstLastButtons *ngIf="totalDataCount !== 0"></mat-paginator>
                                        </div>
                                        <div class="col-auto text-end">
                                            <div class="position-relative d-flex justify-content-end">
                                                <img src="../../../assets/images/search-line.svg"
                                                    class="img-fluid position-absolute search-icon" alt="">
                                                <input type="text" placeholder="Search by"
                                                    class="form-control search-input me-2" (keydown.enter)="onKeyDownEvent()"
                                                    [(ngModel)]="searchedValue">
                                                <button class="btn btn-success btn-sm clearSearchBtn"
                                                    (click)="clearSearch()" *ngIf="searchedValue">Clear</button>
                                            </div>
        
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table table-borderless">
                                            <thead>
                                                <tr class="py-2">
                                                    <th class="fw-bold" *ngFor="let item of tableHeading">
                                                        {{item?.thead}}
                                                        <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="item?.key " (click)="sortTable(item.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                                        <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="item?.key" (click)="sortTable(item.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of subscribers; let i = index">
                                                    <td>{{i + 1}}</td>
                                                    <td>{{item?.email}}</td>
                                                    <td>{{item?.updatedAt | date: 'dd/MM/yyyy'}}</td>
                                                    <td>
                                                        <!-- <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                                            <button class="btn btn-warning" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction)"
                                                            style="border-radius: 20px; border: none; background-color: #feed76;">
                                                            <img src="../../../assets/images/idh_Subscribed.svg">
                                                            Subscribed
                                                        </button>
                                                        <button class="btn btn-warning" *ngIf="(buttonPermission?.key && buttonPermission?.key === 'update' && buttonPermission?.isAction  && !item.isSubscribe)"
                                                        style="border-radius: 20px; border: none; background-color: #e8e8e8;">
                                                        <img src="../../../assets/images/idh_unsubscribed.svg">
                                                        Unsubscribed
                                                    </button>
                                                        </ng-container> -->
                                                        <button class="btn btn-warning" *ngIf="item?.isSubscribe"
                                                            style="border-radius: 20px; border: none; background-color: #feed76;">
                                                            <img src="../../../assets/images/idh_Subscribed.svg">
                                                            Subscribed
                                                        </button>

                                                        <button class="btn btn-warning" *ngIf="!item?.isSubscribe"
                                                            style="border-radius: 20px; border: none; background-color: #e8e8e8;">
                                                            <img src="../../../assets/images/idh_unsubscribed.svg">
                                                            Unsubscribed
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <ng-container *ngIf="!subscribers?.length">
                                        <div  class="d-flex justify-content-center align-items-center p-5">
                                            <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                                        </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                            
                            <ng-container *ngFor="let buttonPermission of allowedAction[0]['authentication']">
                                <ng-container *ngIf="buttonPermission?.key && buttonPermission?.key === 'list' && !buttonPermission?.isAction">
                                    <div class="d-flex justify-content-center m-5">
                                        Permission not allowed to view table List.
                                    </div>
                                </ng-container>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>