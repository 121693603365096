import { Component, OnInit, Inject} from '@angular/core';
import { FooterComponent } from '../footer.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-welcome',
  templateUrl: './user-welcome.component.html',
  styleUrls: ['./user-welcome.component.scss']
})
export class UserWelcomeComponent implements OnInit {
  currentFlag: any;
  responseMeaasge:any;
  // contactMessage:string = "Thankyou for Contacting Us !!";
  // subscribeMessage:string = "Thank You For Subscribing !!";

  constructor(public dialogRef: MatDialogRef<FooterComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    
    this.currentFlag = this.data.flag;
    console.log(this.currentFlag);
    this.messageAsPerFlag(this.data.flag);
  }

  messageAsPerFlag(flagName:any){
    switch (flagName) {
      case 'contactUs':
        this.responseMeaasge = "Thankyou for Contacting Us !!";
        break;

      case 'programForm':
        this.responseMeaasge = "Form Submitted Successfully !!";
        break;

      case 'subscriber':
        this.responseMeaasge = "Thank You For Subscribing !!";
        break;
    
      default:
        break;
    }
  }

  closeModal(){
  this.dialogRef.close()
}
}
