import { Component, OnInit } from '@angular/core';
import { UserManagementServiceService } from '../user-management-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-policies',
  templateUrl: './user-policies.component.html',
  styleUrls: ['./user-policies.component.scss']
})
export class UserPoliciesComponent implements OnInit {

  public permissionDetails: any =[];
  public userForm !: FormGroup ;
  userRoles :any = [{name:'IP',value:'ip'}];
  roleId: string = '';
  adminData: any = sessionStorage.getItem('userdata');
  public allowedMessage:string = 'Role Creation or Update don`t use Number & Special Characters(!,@,#,$,%,^,&,* ...) !!'
  currentMode: any = 'edit';
  userMasterLevel: any;
  selectedLevel: any = '';
  roleLevelId: any;
  constructor(
    private userManagementServiceService: UserManagementServiceService,
    private snackBar:MatSnackBar,
    private router: Router,
    private route : ActivatedRoute,

  ) { this.getMasterLevel()}
  public roleName:string = '';
   ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      console.log("allparamss",queryParams);
      this.currentMode = queryParams?.mode;
      
    })
    
    this.adminData = JSON.parse(this.adminData)
    this.roleId = this.route.snapshot.params.id;
    if(this.roleId){
        this.getRoleBasedOnId(this.roleId)
    }else{
        this.getUserLevelPerrmissonData();
    }
  }
  getMasterLevel(){
    this.userManagementServiceService.getMasterLevel().subscribe((res:any)=>{
      // if(res.success){
        // this.openSnackBar([res?.message || 'Success !'] , 3000);
      this.userMasterLevel = res?.data;
        // this.permissionDetails = this.permissionDetails.map((el:any,index:number)=>{
        //   if(el){
        //     el.authentication = JSON.parse(JSON.stringify(el.authentication));
        //   }
        //   console.log(index,el)
        //   return el;
        // })
        console.log("res",res, this.userMasterLevel)
      // }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    })
  }

  getUserLevelPerrmissonData(){
    this.userManagementServiceService.getUserPermission().subscribe((res:any)=>{
      // if(res.success){
        // this.openSnackBar([res?.message || 'Success !'] , 3000);
      let selfPermission:any = JSON.parse(sessionStorage.getItem('userPermission')!);
        // if(this.adminData?.role_id != 1)
        let filteredPermission:any = selfPermission[0].authenicationJson.filter((item:any) => item.authentication.some((auth:any) => auth.isAction === true))
        filteredPermission = filteredPermission && filteredPermission.length ? filteredPermission.map((module:any) => module.model) : [];
        this.permissionDetails = JSON.parse(JSON.stringify(res['data']));
        if(this.adminData?.userlevel_id != 1){
          this.permissionDetails = this.permissionDetails.filter((permission:any) => filteredPermission.includes(permission.model));
          this.permissionDetails.forEach((element:any) => {
            let selfAction:any = selfPermission[0].authenicationJson.find((self:any) => self.model == element.model);
            selfAction = selfAction.hasOwnProperty('model') ? selfAction.authentication.filter((action:any) => action.isAction) : [];
            selfAction = selfAction?.length ? selfAction.map((ele:any) => ele.key) : [];
            element.authentication =  element.authentication.filter((action:any) => selfAction.includes(action.key))

          });
        }
        // this.permissionDetails = this.permissionDetails.map((el:any,index:number)=>{
        //   if(el){
        //     el.authentication = JSON.parse(JSON.stringify(el.authentication));
        //   }
        //   console.log(index,el)
        //   return el;
        // })
        console.log("res",JSON.stringify(res['data']))
      // }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    })
  }
  isCheckedModule(module:any):any {
    if (module.authentication && module.authentication.length > 0 && module.authentication.every((item:any) => item.isAction)) {
        module.isAction = true;
        module.is_checked = true;
    } else {
        module.isAction = false;
        module.is_checked = false
    }
}
isIndeterminateModuleOver(module:any) {
  return (
      module.authentication &&
      module.authentication.length &&
      module.authentication.some((data:any) => data.isAction) &&
      !module.authentication.every((data:any) => data.isAction)
  );
}
onModuleChange(event:any, module:any): void {
  console.log(module);
  if (event) {
    module.isAction = event.checked;
    module.is_checked = event.checked;
      module.authentication.map((item:any) => {
          item.isAction = event.checked;
      });
  }
}
onActionChange(event:any, page:any, selectedData:any) {
  if (
    (page.authentication.length == 0 && page.isAction) ||
    (page.authentication.length && page.authentication.every((item:any) => item.isAction))
) {
    page.isAction = true;
} else {
    page.isAction = false;
}
  console.log("event", selectedData);
}
selectAll(event:any) {
  console.log("eve", event);
  event.checked = this.roleId ? !event.checked : event.checked;
  if (event.checked) {
      this.permissionDetails = this.permissionDetails?.map((item: any) => {
          item.isAction = this.roleId  ? !event.checked : event.checked;
          item.is_checked = this.roleId  ? !event.checked : event.checked;
          if (item && item?.authentication && item?.authentication?.length) {
              item?.authentication?.map((innnerItem: any) => {
                  innnerItem.isAction = this.roleId  ? !event.checked : event.checked;
              });
          }
          return item;
      });
  } else {
      this.permissionDetails = this.permissionDetails?.map((item: any) => {
          item.isAction = this.roleId ? !event.checked : event.checked;
          item.is_checked = this.roleId  ? !event.checked : event.checked;
          if (item && item?.authentication && item?.authentication?.length) {
              item?.authentication?.map((innnerItem: any) => {
                  innnerItem.isAction = this.roleId  ? !event.checked : event.checked;
              });
          }
          return item;
      });
  }
}

createRole():void{
  console.log("ipsaIndex", this.permissionDetails)
  let ipsaIndex = this.permissionDetails.findIndex((permission:any) => permission.model == 'ipsa');
  if(ipsaIndex == -1){
    let ipsaPermission = {
      "model": "ipsa",
      "label": "IPSA",
      "authentication": [
          {
              "key": "createui",
              "label": "Create",
              "isAction": false
          },
          {
              "key": "list",
              "label": "List",
              "isAction": false
          },
          {
              "key": "update",
              "label": "Update",
              "isAction": false
          },
          {
              "key": "report",
              "label": "Report",
              "isAction": false
          },
          {
              "key": "submissionlist",
              "label": "Report Submisson List",
              "isAction": false
          }
      ],
      "is_checked": false,
      "isAction": false
  }
  
  this.permissionDetails.push(ipsaPermission)
  }

  console.log("ipsaIndex",ipsaIndex, this.permissionDetails)
  this.permissionDetails.forEach((form:any) => {
    form.authentication.forEach((auth:any) => {
      if (auth?.isAction) {
        const index = form.authentication.findIndex((a: any) => a.key === 'list' || a.key === 'listui');

        if (index !== -1) {
          form.authentication[index].isAction = true;  // Update isAction to false
        }
      }
    });
  });
let payload:any = {
    role_name: this.roleName,
    authenicationJson : this.permissionDetails,
    userlevel_id: this.selectedLevel,
    ...!(this.roleId) && {createdBy : this.adminData ? this.adminData?.id : ''}
}
if(this.roleId){
  payload ={...payload, role_id: this.roleLevelId}
  this.updateRole(payload)
}else{
this.userManagementServiceService.createRole(payload).subscribe((res:any) => {
    if(res.success){
      this.openSnackBar([res?.message || 'Success !'] , '','success');
    }
    this.router.navigate(['/user-level'])
  },(error:any)=>{
    this.openSnackBar([error?.message || 'Failed !'] , '');
  });
}

}
updateRole(payload:any):void{
  // this.permissionDetails.forEach((form:any) => {
  //   form.authentication.forEach((auth:any) => {
  //     if (auth.isAction) {
  //       form.authentication.find((a:any) => a.key === 'list' || a.key === 'listui').isAction = true;
  //     }
  //   });
  // });
  //   let payload = {
  //       authenicationJson:this.permissionDetails,
  //       role_name:this.roleName
  //   };
    this.userManagementServiceService.updateUserRole(payload,this.roleId).subscribe((res:any) => {
        if(res.success){
          this.openSnackBar([res?.message || 'Success !'] , '','success');
        }
        this.router.navigate(['/user-level'])
      },(error:any)=>{
        this.openSnackBar([error?.message || 'Failed !'] , '');
      });

}
isDisabled():boolean{
    // console.log("sss",this.permissionDetails)
    return !(this.permissionDetails.some((el:any)=>el?.isAction || el?.authentication?.some((e:any)=>e?.isAction)) && this.roleName && this.selectedLevel) 
}

async getRoleBasedOnId(roleId : string){
    await this.userManagementServiceService.getRoleDataById(roleId).subscribe((res:any) => {
      if(res.success){
        res = JSON.parse(JSON.stringify(res.data[0]));
        if(res){
            this.roleName = res['role.name'] ? res['role.name'] :"name not availabe";
            this.selectedLevel = res['userlevel_id'];
            this.roleLevelId = res['role_id'];
            this.permissionDetails = res.authenicationJson;
        }else{
          this.openSnackBar(['Data Not Available !'] , '','info');
          return
        }
         
      }
    },(error:any)=>{
      this.openSnackBar([error?.message ? error?.message : error?.data || 'Failed !'] , '');
    })
  }
  isModuleOver(item:any):boolean{
   return item?.length && item?.authenicationJson?.length &&  item?.authenicationJson.every((el:any)=>(el?.is_checked || el?.isAction))
  }
 
  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: [className]
  });
  }
  preventKeyboardInput(event: KeyboardEvent): void {
    if(event)
    return
    // event.preventDefault();
  }
  selectRole(selectedValue:any){
    console.log(selectedValue);
    console.log("userForm",this.userForm);
    
    /* used this switch case as per the defined constant
      ROLE: {
        ADMIN: '1',
        SUB_ADMIN: '2',
        PROGRAM_LEVEL: '3',
        COUNTRY_LEVEL: '4',
        IP_LEVEL: '5',
        PUBLIC_USER: '6',
        FACTORY_LEVEL: '7',
        INSTEP_USER: '8'
      } */
      

     
    }
    
}
