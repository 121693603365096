<!-- <div class="chart-container">
    <canvas  id="MyChart" baseChart ></canvas>
</div> -->
<div class="d-lg-inline-block w-100">
    <div *ngIf="showNoData; else showCanvas" class="d-flex justify-content-center">
        <img alt="no data" src="assets/no_data_image/error-logo.png" class="noDataImage pt-100">
    </div>
    <ng-template #showCanvas>
    <canvas #pieChartCanvas width="400" height="400"></canvas>
    </ng-template>
</div>
