import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonServiceService } from '../services/common-service.service';

@Component({
  selector: 'app-impact-stories',
  templateUrl: './impact-stories.component.html',
  styleUrls: ['./impact-stories.component.scss']
})
export class ImpactStoriesComponent implements OnInit {
  impactData: any = [];
  prevRoute: string = "";
  mainHead: any = [];
  isLoadMore:boolean = false;
  hideImpactData: boolean = true;

  constructor(private commonService: CommonServiceService, private router: Router) { }

  ngOnInit(): void {
    this.getimpactStoriesData();
    this.prevRoute = this.router.url
    console.log("url",this.router.url);
    this.impactStoryPageHeading();
  }

  getimpactStoriesData(){
    this.commonService.impactStoriesData().subscribe(response => {
      console.log(response);
      if(response.success){
        this.impactData = response?.data;
      }
    },(error) => console.error(error))
  }

  impactStoryPageHeading(sectionId = 12){
    this.commonService.getPageDetails(sectionId).subscribe(res => {
      if(res.success){
        // .filter((ele:any) => ele.slug == 'impact_stories')
        this.mainHead = res.data;
        console.log("mainhead",this.mainHead);
        if(!this.mainHead.length){
          this.hideImpactData = false;
        }
        
      }
    })
  }

}
