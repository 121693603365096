import { Component, OnInit } from '@angular/core';
import { CommonServiceService } from '../../pages/services/common-service.service';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/pages/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {
  subscribers: any = [];
  tableHeading = [
    {
      thead: 'S.No.'
    },
    {
      thead: 'Email',
      key: 'email'
    },
    {
      thead: 'Date',
      key: 'updatedAt'
    },
    {
      thead: 'Status'
    },
    // {
    //   thead: 'Action'
    // },
  ];
  searchData: any = [];
  searchedValue: any = '';
  public limit = 10;
  public skip = 0;
  public page = 1;
  totalDataCount: number = 0;
  permissionDetails: any = JSON.parse(sessionStorage.getItem('userPermission')!);
  allowedAction: any;
  action=[
    {actionType:'edit',icon:'fa fa-edit',disabled:false, toolTip:'Edit',imgIcon:'../../../../assets/images/edit.svg',isPermission:false,label:'Update',key:'update'},
    {actionType:'delete',icon:'fa fa-trash',disabled:false,toolTip:'Delete',imgIcon:'../../../../assets/images/delete-bin.svg',isPermission:false,label:'Delete',key:'delete'},
  ]
  sortSelection: string = '';
  selectedColumn: string = '';
  orderSorting: any = '';

  constructor(private commonService: CommonServiceService, private snackBar: MatSnackBar, public dialog: MatDialog) {
    let contactUsAction = this.getPermissionPerPage(this.permissionDetails[0],'subscribe');
    this.allowedAction = contactUsAction;
    this.action = this.commonService.actionTablePermission(this.action,this.allowedAction);
  }
  
  ngOnInit(): void {
    this.subscriberData(1);
  }
  
  subscriberData(page:any, searchString:string = "", orderSorting:any = '', searchKey:any = ''){
    this.commonService.getSubscriberData(searchString, page, orderSorting, searchKey).subscribe(res => {
      this.subscribers = res.data;
      this.totalDataCount = res.total;
      this.searchData = [...this.subscribers];
      console.log(this.subscribers);
      
    })
  }

  onKeyDownEvent(){
    this.skip =0;
    this.subscriberData(1, this.searchedValue)
    // SEARCH FUNCTIONALITY FROM FRONTEND FOR 1 DISPLAY PAGE
    // if(this.searchedValue){
    //   this.subscribers = this.searchData.filter((ele:any) => {
    //     if(typeof(ele) == 'object'){
    //       if(ele.email.toLowerCase().includes(this.searchedValue.toLowerCase())){
    //         return ele;
    //       }
    //     }
    //   });
    //   console.log("kokok",this.subscribers);
    // }else{
    //   this.subscribers = this.searchData;
    //   console.log("enter some value");
    // }  
  }

  openSnackBar(data: string[], duration:number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }
  
  deleteSubscriber(data:any){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Delete Functionaly is in Progress",
        buttonText: {
          ok: "OK",
          cancel: "Cancle",
        },
      },
    });
    // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //   if (confirmed) {
    //     this.commonService.deleteLineItems( data?.id).subscribe(res => {
    //       if(res.success){
    //         this.openSnackBar([res?.message], 3000);
    //         this.subscriberData();
    //       }
    //     })
    //   }
    // })
  }

  //CLEAR SEARCH
  clearSearch(){
    if(this.searchedValue.trim()){
      this.searchedValue = "";
      this.onKeyDownEvent();
    }
    
  }

  setPage(event: any) {
    // this.selectedColumn = '';
    this.page = event.pageIndex + 1;
    this.skip = (event.pageIndex + 1) * this.limit - this.limit;
    console.log('limit',event.pageSize,event, this.skip)
    this.limit = event.pageSize;
    this.subscriberData(event.pageIndex + 1,'', this.orderSorting, this.selectedColumn);
  }
  getPermissionPerPage(data:any,modelName:string):any{
    return data.authenicationJson.filter((el:any)=>{
      if(el.model === modelName) return el;
    })
  }

  sortTable(columnName: any, sortType:string = '') {
    this.sortSelection = sortType;
    this.selectedColumn = columnName;
    this.orderSorting = sortType === 'down' ? 'DESC' : 'ASC';
    console.log("columnName",columnName);
    this.subscriberData(this.page,'', this.orderSorting, columnName);
  }
}
