<div class="Footer_bottom">
    <section class="resources-comp d-flex flex-column align-items-center justify-content-center" [ngStyle]="{'background-image': 'url(' + (pageHeading != undefined ? pageHeading.banner : '') + ')'}">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="col text-center">
                        <h1 class="text-white">{{pageHeading != undefined ? pageHeading.title : ''}}</h1>
                        <!-- <p class="text-white text-center" style="font-size: 14px;">{{pageHeading != undefined ? pageHeading.subTitle : ''}}</p> -->
                        <!-- <p class="text-white text-center" style="font-size: 14px;" [innerHTML]="stripHtmlTags(pageHeading.subTitle,-1)"></p> -->
                        <p class="text-white text-center" style="font-size: 14px;" [innerHTML]="pageHeading.subTitle"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="resources-center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h3 class="color-44 mb-3">Resource Center</h3>
                </div>
                <ng-container *ngIf="resourceCenter.length">
                    <ng-container *ngFor="let items of resourceCenter.slice(0,8); let i = index">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12" *ngIf="items.types == 'resource'">
                            <div class="mb-3 border-0 position-relative img-opacity">
                                <img [src]="items.image" class="img-fluid w-100 border-15" style="height: 190px;" alt="">
                                <div class="position-absolute" style="bottom: 20px; left: 20px;">
                                    <div class="mb-2" style="font-size: 16px; color: white;">{{items?.name}}</div>
                                    <!-- <button class="btn d-flex align-items-center view-btn rounded-pill" routerLink="/resources-view" [queryParams]="{id: items?.id,name:items?.name}">View</button> -->
                                    <button class="btn d-flex align-items-center view-btn rounded-pill" (click)="redirectUrl(items)">View</button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isLoadMore">
                        <ng-container *ngFor="let items of resourceCenter.slice(8, resourceCenter.length); let i = index">
                            <div class="col-lg-3 col-md-4 col-sm-6 col-12" *ngIf="items.types == 'resource'">
                                <div class="mb-3 border-0 position-relative img-opacity">
                                    <img [src]="items.image" class="img-fluid w-100 border-15" style="height: 190px;" alt="">
                                    <div class="position-absolute" style="bottom: 20px; left: 20px;">
                                        <div class="mb-2" style="font-size: 16px; color: white;">{{items?.name}}</div>
                                        <button class="btn d-flex align-items-center view-btn rounded-pill" (click)="redirectUrl(items)">View</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <span class="loadmoreTab" (click)="isLoadMore = !isLoadMore" *ngIf="isShowMore()"><u>{{!isLoadMore ? 'Show More..' : 'Show Less'}}</u></span>
                </ng-container>
            </div>
        </div>
    </section>
    
    <section class="Glossary">
        <div class="container">
            <h3 class="color-44 mb-3">Glossary</h3>
            <!-- <div class="d-flex justify-content-between mb-3">
            <mat-paginator class="f-14" [length]="totalDataCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalDataCount !== 0"></mat-paginator>
                <div class="position-relative">
                    <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-icon" alt="">
                    <input type="search" placeholder="Search by" class="form-control search-input me-2" (keydown.enter)="onKeyDownEvent($event)" [(ngModel)]="searchedValue">
                    <button class="btn btn-success btn-sm clearSearchBtn">Clear</button>
                </div>
            </div> -->
            <div class="row">
                <div class="col">
                    <div class="card border-0 mb-5">
                        <div class="card-body">
                            <div class="row align-items-center mb-3">
                                <div class="col">
                                    <mat-paginator class="f-14 d-flex justify-content-start" [length]="totalDataCount" [pageSize]="limit" aria-label="Select page" (page)="setPage($event)" showFirstLastButtons *ngIf="totalDataCount !== 0"></mat-paginator>
                                </div>
                                <div class="col-auto">
                                    <div class="position-relative d-flex">
                                        <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-icon" alt="">
                                        <!-- <input type="search" placeholder="Search by" class="form-control search-input" (keydown.enter)="onKeyDownEvent()" [(ngModel)]="searchedValue"> -->
                                        <input type="text" placeholder="Search by" class="form-control search-input me-2" (keydown.enter)="onKeyDownEvent()" [(ngModel)]="searchedValue">
                                        <button class="btn btn-success btn-sm clearSearchBtn" (click)="clearSearch()" *ngIf="searchedValue">Clear</button>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table no-last-tr-border table-borderless">
                                    <thead class="bg-light border-0">
                                        <tr class="py-2">
                                            <th class="fw-bold" *ngFor="let item of tableHeading">
                                                {{item?.thead}}
                                                <img src="../../../assets/icons/sort-up.png" alt="" class="sortArrowsUp" *ngIf="item?.key " (click)="sortTable(item.key, 'up')" matTooltip="Ascending" [ngStyle]="{'opacity': sortSelection == 'up' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                                <img src="../../../assets/icons/sort-down.png" alt="" class="sortArrowsDown" *ngIf="item?.key" (click)="sortTable(item.key, 'down')" matTooltip="Descending" [ngStyle]="{'opacity': sortSelection == 'down' ? (item?.key == selectedColumn ? '' : 0.3) : 0.3}">
                                            </th>

                                            <!-- <th style="width: 30%;">Term <i class="fa fa-fw fa-sort pointerHead"  (click)="sortTable('term')"></i></th>
                                            <th>Definition<i class="fa fa-fw fa-sort pointerHead"  (click)="sortTable('definition')"></i></th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <ng-container *ngIf="glossaryData && glossaryData.length > 0">
                                    <tr class="border-bottom" *ngFor="let item of glossaryData">
                                        <td>{{item?.term}}</td>
                                        <td [matTooltip]="item?.definition?.length < 200 ? '' : item?.definition">
                                            <!-- {{item?.definition | truncatePipe : 200}} -->
                                            <div [innerHTML]="stripHtmlTags(item?.definition,200)"></div>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                                </table>
                                <ng-container *ngIf="!glossaryData.length">
                                        <!-- Display your image when glossaryData is empty -->
                                        <div class="d-flex justify-content-center">
                                            <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
                                        </div>
                                </ng-container>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
