import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import * as html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import { ContentService } from 'src/app/shared/services/content.service';
import {environment} from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare const downloadPdfToIframe:any;

@Component({
  selector: 'app-ipsa-dashboard',
  templateUrl: './ipsa-dashboard.component.html',
  styleUrls: ['./ipsa-dashboard.component.scss']
})
export class IpsaDashboardComponent implements OnInit {
  @ViewChild('iframe', { static: false }) iframe!: ElementRef<HTMLIFrameElement>;
  powerBIiFrameLink: any | SafeResourceUrl;
  

  constructor(public router: Router,private contentService: ContentService, private sanitizer: DomSanitizer) {
    let unSanitisedURL = environment?.powerBIlink;

    // Sanitizing the URL to prevent security vulnerabilities
    this.powerBIiFrameLink = this.sanitizer.bypassSecurityTrustResourceUrl(unSanitisedURL);
   }

  ngOnInit(): void {
  }

  backToForm(){
    this.router.navigate(['/ipsa'])
  }

  // ngAfterViewInit(): void {
  //   this.iframe.nativeElement.addEventListener('load', () => {
  //     this.generatePdf();
  //   });
  // }

  generatePdf(): void{
    console.log("hello");

    // downloadPdfToIframe('file')
    // const iframeElement = document.getElementById('iframe');
    //     const filename = 'filename';
    // this.contentService.loaderSubscriber.next(true);
    window.print();
        // this.convertIframeToPdf(this.iframe.nativeElement, 'filename', (success) => {
        //   this.contentService.loaderSubscriber.next(false);
        //   if (success) {
        //     console.log('PDF generated successfully.');
        //   } else {
        //     console.log('PDF generation failed.');
        //   }
        // });
  
  //   let pdf = new (window as any).jspdf.jsPDF();
  //   pdf.text("Some text inside PDF", 10, 10);
  //   let doc = document.querySelector('#biIpsa') as HTMLCanvasElement;
  //   let yOffset =10;
  //   pdf.text(20, yOffset + 10, 'hello');

  //   // Add chart image as JPEG to PDF
  //   let imgData = doc.toDataURL('image/jpeg', 1.0);
  //   pdf.addImage(imgData, 'JPEG', 15, yOffset + 20, 180, 120); // Change dimensions as needed
  //   console.log("pdfafterImage");
  //   yOffset += 140; // Increase Y offset for next chart
  //   if (yOffset >= 250) {
  //     // Add a new page if the space is not enough
  //     pdf.addPage();
  //     yOffset = 10;
  //   }
  // pdf.save('_dashboard.pdf');
    // $('#biIpsa').attr('src', pdf.output('datauristring'));
  }
  
 

  convertIframeToPdf(iframe: HTMLIFrameElement, filename: string, callback: (success: boolean) => void): void {
    const iframeWindow = iframe.contentWindow;
console.log("hello1", iframe, iframeWindow)
    if (iframeWindow) {
      // iframeWindow.addEventListener('load', () => {
        console.log("hello2", iframeWindow)
        const iframeDocument = iframeWindow.document;
        console.log("hello1", iframeWindow)
        if (iframeDocument) {
          const body = iframeDocument.body;

          new (window as any).html2canvas(body, { scrollY: -window.scrollY }).then((canvas:any) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jspdf.jsPDF();

            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(filename + '.pdf');

            callback(true); // PDF generation successful
          })
          .catch((error:any) => {
            console.error('Error converting iframe to PDF:', error);
            callback(false); // PDF generation failed
          });
        } else {
          console.error('Error accessing iframe content.');
          callback(false); // PDF generation failed
        }
      // });
    } else {
      console.error('Error accessing iframe window.');
      callback(false); // PDF generation failed
    }
  }

  

}
