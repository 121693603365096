import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
 
@Directive({
  selector: '[appAlphabatesOnly]'
})
export class AlphabetOnlyDirective {
  constructor(private ngControl: NgControl) { }
 
  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.ngControl.value;
    const newValue = initialValue.replace(/[^a-zA-Z\s]*/g, '');
    if (initialValue !== newValue) {
      this.ngControl.reset(newValue);
    }
  }
}