
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { interval, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { SweetAlert } from "sweetalert/typings/core";
import { AuthGuardService } from './shared/services/auth-guard.service';
import { ContentService } from './shared/services/content.service';
const swal: SweetAlert = require("sweetalert");

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  getToken() {
    let token = sessionStorage.getItem('token');
    return token;
  }

  constructor(private router: Router, private authService: AuthGuardService,private contentService: ContentService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let isToken = sessionStorage.getItem('isToken'); 
    if (this.getToken() && !request.url.indexOf('dhwani-staging.s3.ap-south-1.amazonaws.com') && (this.router.url.includes('admin') || this.router.url.includes('login') || this.router.url.includes('master')) ) {
      request = request.clone({
        setHeaders: ({
          'Authorization': `Bearer ${this.getToken()}`
        })
      })
    }
    this.contentService?.loaderSubscriber?.next(true)

    return this.handler(next, request);
  }

  handler(next: HttpHandler, request: HttpRequest<unknown>) {
    return next.handle(request)
      .pipe(
        tap((event) => {
          // this.contentService.loaderSubscriber.next(false)
          if (event instanceof HttpResponse) {
            // console.log("interceptorResponse", event)
            // if(event.url?.includes('/ipsa/create')){
            //   sessionStorage.setItem("formIOData", JSON.stringify(event));
            // }
            this.contentService.loaderSubscriber.next(false)
          }
        },
          (error: any) => {
            this.contentService.loaderSubscriber.next(false)
            // this.spinnerService.resetSpinner();
            console.log("interceptor", error)
            if(error?.error?.message == "User UnAuthorized Token"){
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('userdata');
              sessionStorage.clear();
              this.authService.updateHeader(false);
              this.router.navigate(['/home']);
            }
            // if(error.error.message == "User UnAuthorized"){
            //   swal("", `User UnAuthorized, Please request to admin for permission`, "info");
            //   localStorage.removeItem('token');
            //   localStorage.removeItem('userdata');
            //   sessionStorage.clear();
            //   this.authService.updateHeader(false);
            //   this.router.navigate(['/home']);
            // }
            if (error?.status == '401') {
              swal("", `${error?.error?.errorMsg}`, "error");
              sessionStorage.removeItem('token');
              sessionStorage.clear();
              sessionStorage.removeItem('userPermission');
              // FOR TESTING PURPOSE, CLEARING SESSION BEFORE API CALL
              let localkeys: any = [];
              localkeys = Object.keys(sessionStorage);
              console.log("sessionKey", localkeys);

              localkeys.forEach((value: any) => {
                console.log("valueee", value);

                sessionStorage.removeItem(value);
              });
              this.authService.updateHeader(false);
              swal("", `Session Expired, Please login again`, "error");
              this.router.navigate(["/home"]);
              // if (error.error.url !== '/aajeevika/feedback/save') {
                // this.router.navigate(['/login'])
              // }
            }
            if (error?.error?.responseCode == '403' && error?.error?.logout) {
              swal("", `Session Expired, Please login again`, "error");

              this.authService.isLogIn.next(false);
              sessionStorage.removeItem('token');
              sessionStorage.clear();
              this.router.navigate(['/home'])
            }
            if (error?.error?.responseCode == '400') {
              if (error?.error?.errorMap) {
                for (const property in error?.error?.errorMap) {
                  console.log(`${property}: ${error?.error?.errorMap[property]}`);
                  swal("", `${property}: ${error?.error?.errorMap[property]}`, "error");
                }
              }
              else swal("", `${error?.error?.errorMsg}`, "error");
            }

            if (error?.error?.responseCode == '422') {
              swal("", `${error?.error?.errorMsg}`, "error");
            }
            throw error;
          }
        ),

        // Handling errors
        catchError((error: any) => {
          this.contentService.loaderSubscriber.next(false)
          console.log("interceptorError", error);
          return throwError(error);
        })
      )
  }
}
