import { Component, OnInit, SecurityContext } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ManagePortalDialogComponent } from './manage-portal-dialog/manage-portal-dialog.component';
import { CommonServiceService } from '../../pages/services/common-service.service';
import { ConfirmationDialogComponent } from '../../pages/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-manage-portal',
  templateUrl: './manage-portal.component.html',
  styleUrls: ['./manage-portal.component.scss'],
})
export class ManagePortalComponent implements OnInit {
  homeData: any = [];
  currentTab: any = "homepage";
  glossaryData: any = [];
  resourceCenter: any = [];
  impactData: any = [];
  searchedValue: any = "";
  contactUsTable: any = [];
  sectionwiseData: any = [];
  totalDataCount: number = 0;
  selectedIndex: number = 0;
  partnerData: any = [];
  paginatorSize: any;
  searchData: any = [];
  selectedRadioValue: any;
  subcategorydata: any = [];
  subcategoryReportdata: any = [];
  resourceSubTableHead: any = {
    "Category": [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Title',
        key:'name'
      },
      {
        thead: 'Category',
        key:'types'
      },
      {
        thead: 'Upload Date',
        key:'updatedAt'
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      },
    ],
    "Sub Category": [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Title',
        key:'name'
      },
      {
        thead: 'Category',
        key:'category.name'
      },
      {
        thead: 'Upload Date',
        key:'updatedAt'
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      },
    ],
    "Sub-Category Report": [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Title',
        key:'name'
      },
      {
        thead: 'Sub Category',
        key:'subcategory.name'
      },
      {
        thead: 'Upload Date',
        key:'updatedAt'
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      },
    ],

  }
  commonTableHead: any = {
    homepage: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Title',
        key:'title'
      },
      {
        thead: 'Category',
        key:'name'
      },
      {
        thead: 'Description',
        key:'subTitle'
      },
      {
        thead: 'Upload Date',
        key:'updatedAt'
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      }
    ],
    resources: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Title',
        key:''
      },
      {
        thead: 'Category',
        key:''
      },
      {
        thead: 'Upload Date',
        key:''
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      },
    ],
    impactStories: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Title',
        key:'title'
      },
      {
        thead: 'Description',
        key:'description'
      },
      {
        thead: 'Category',
        key:'category.name'
      },
      {
        thead : 'Rank',
        key:'ranking'
      },
      {
        thead : 'Upload Date',
        key:'updatedAt'
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      },
    ],
    glossary: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Term',
        key:'term'
      },
      {
        thead: 'Description',
        key:'definition'
      },
      {
        thead: 'Action'
      },
    ],
    contactUs: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Full Name',
        key:'fullname'
      },
      {
        thead: 'Email',
        key: 'email'
      },
      {
        thead: 'Message',
        key:'message'
      },
      {
        thead: 'Date',
        key:'updatedAt'
      },
      // {
      //   thead : 'Action'
      // },
    ],
    teams: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Name',
        key:'name'
      },
      {
        thead: 'Designation',
        key:'designation'
      },
      {
        thead: 'About',
        key:'about'
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      },
    ],
    ourPartners: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Name',
        key:'title'
      },
      {
        thead: 'About',
        key:'description'
      },
      {
        thead : 'Rank',
        key:'ranking'
      },
      {
        thead : 'Thumbnail'
      },
      {
        thead: 'Action'
      },
    ],
    aboutUs: [
      {
        thead: 'S.No.'
      },
      {
        thead: 'Title',
        key:'title'
      },
      // {
      //   thead: 'Category',
      //   key:'name'
      // },
      {
        thead: 'Description',
        key:'subTitle'
      },
      {
        thead: 'Upload Date',
        key:'updatedAt'
      },
      {
        thead: 'Thumbnail'
      },
      {
        thead: 'Action'
      }
    ],

  };
  selectedSubTabName: any = "";
  allTeamData: any = [];
  filteredData: any = [];
  public limit = 10;
  public skip = 0;
  public page: any = 1;
  pageAccoradingAction = [
    { state: '', label: 'Homepage', icon: '', isActive: false, model: ['cms'], selectedTabs: 'homepage', isChecked: false },
    { state: '', label: 'Resources', icon: '', isActive: false, model: ['category','subcategory','subcategoryreport'], selectedTabs: 'resources', isChecked: false },
    { state: '', label: 'Impact Story', icon: '', isActive: false, model: ['impactstories'], selectedTabs: 'impactStories', isChecked: false },
    { state: '', label: 'Glossary', icon: '', isActive: false, model:['glossary'], selectedTabs: 'glossary', isChecked: false },
    { state: '', label: 'Contact Us', icon: '', isActive: false, model: ['inquiry'], selectedTabs: 'contactUs', isChecked: false },
    { state: '', label: 'Teams', icon: '', isActive: false, model: ['teammember'], selectedTabs: 'teams', isChecked: false },
    { state: '', label: 'Our Partners', icon: '', isActive: false, model: ['partners'], selectedTabs: 'ourPartners', isChecked: false },
    { state: '', label: 'About Us', icon: '', isActive: false, model: ['aboutUs'], selectedTabs: 'aboutUs', isChecked: false },
  ]
  subtabs: any = [
    { state: '', label: 'Category', icon: '', isActive: false, model: 'category', selectedTabs: 'homepage', isChecked: true, tabName: "Category" },
    { state: '', label: 'Sub Category', icon: '', isActive: false, model: 'subcategory', selectedTabs: 'homepage', isChecked: false, tabName: "Sub Category" },
    { state: '', label: 'Sub-Category Report', icon: '', isActive: false, model: 'subcategoryreport', selectedTabs: 'homepage', isChecked: false, tabName: "Sub-Category Report" }
  ]
  permissionDetails: any = JSON.parse(sessionStorage.getItem('userPermission')!);
  allowedAction: any;
  action = [
    { actionType: 'edit', icon: 'fa fa-edit', disabled: false, toolTip: 'Edit', imgIcon: '../../../../assets/images/edit.svg', isPermission: false, label: 'Update', key: 'update' },
    { actionType: 'delete', icon: 'fa fa-trash', disabled: false, toolTip: 'Delete', imgIcon: '../../../../assets/images/delete-bin.svg', isPermission: false, label: 'Delete', key: 'delete' },
  ]
  resourceChildTabs :string[] = ['subcategory','subcategoryreport'];
  resourceTab :string[] = ['cms','category','subcategory','subcategoryreport','glossary','impactstories','inquiry','partners','teammember', 'aboutUs'];
  getCurrentTabForActive :string[] = []

  sanitizedHtmlContent: SafeHtml | undefined;
  isNoDataFound: boolean = false;
  orderSorting: string = '';
  pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
  aboutUsData: any = [];
  commonHomepageData: any = [];
  sortSelection: string = "";
  selectedColumn: string = '';
  homeAllSectionData: any [] = [];

  constructor(public dialog: MatDialog, private commonService: CommonServiceService, private snackBar: MatSnackBar,private sanitizer: DomSanitizer
  ) {
    console.log("getCurrentTabActive")
    let pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
    let getCurrentTabActive = this.getCurrentTabActiveMethod(pageAction[0]);
    console.log("getCurrentTabActive",getCurrentTabActive)
    this.activeTab(getCurrentTabActive.model)
    console.log("getCurrentTabActive",getCurrentTabActive)
    this.allowedAction = pageAction[0].authenicationJson.filter((el: any) => {
      if (el?.model === getCurrentTabActive.model) return el;
    })

  }

  tabWiseData(event: any, page = '1', isPageCalling = false, index = -1, selectedSubTab = '') {
    this.searchedValue = "";
    this.selectedColumn = '';
    this.skip = 0;
    this.limit = 10;
    this.totalDataCount = 0;
    this.action = this.resetAction(this.action);
    // this.selectedSubTabName = "Category";
    console.log("tabEvent", event);

    if (isPageCalling) {
      this.selectedIndex = index;
      this.selectedSubTabName = selectedSubTab;
    }
    else {
      this.selectedIndex = event.index;
      this.selectedSubTabName = event?.tab?.textLabel;
    }
    
    switch (this.selectedSubTabName) {
      case 'Category':
        this.page = page;
        console.log("category-reset", this.action);
        let categoryAction = this.getPermissionPerPage(this.permissionDetails[0], 'category');
        this.allowedAction = categoryAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> category", categoryAction);
        console.log("tab--> action", this.action);
        this.getresourceCenterData(page, 'resource');
        break;
      case 'Sub Category':
        this.page = page;
        console.log("subcategory-reset", this.action);
        let subcategoryAction = this.getPermissionPerPage(this.permissionDetails[0], 'subcategory');
        this.allowedAction = subcategoryAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        // console.log("tab--> subcategory", categoryAction);
        // console.log("tab--> action", this.action);
        this.getresourceCenterData(page, 'resource', '', true);
        console.log("tab--> action fsfargvsfg", this.action);
        this.subCategoryData(page);
        // setTimeout(()=>{
        //   this.getresourceCenterData(page, 'resource', '', true);
        //   this.subCategoryData(page);
        // },100)
        break;
      case 'Sub-Category Report':
        this.page = page;
        console.log("subcategoryreoort-reset", this.action);
        let subcategoryreport = this.getPermissionPerPage(this.permissionDetails[0], 'subcategoryreport');
        this.allowedAction = subcategoryreport;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> subcategoryreport", subcategoryreport);
        console.log("tab--> action", this.action);
        this.subCategoryData(page, '', true);
        this.subCategoryReportAllData(page);
        // setTimeout(()=>{
        //   this.subCategoryData(page, '', true);
        //   this.subCategoryReportAllData(page);
        // },100)
        break;
    }

  }

  subCategoryData(page: any, searchString: any = "", isDropDown: boolean = false,sortingOrder:string='',columnName:string='') {
    this.commonService.getSubCategoryAllData(page, searchString, isDropDown,sortingOrder,columnName).subscribe(response => {
      if (response.success) {
        if(response?.data?.length){
          this.isNoDataFound = false
          this.subcategorydata = response?.data;
        this.totalDataCount = response.total;
        }
        else{
          this.isNoDataFound = true;
          this.subcategorydata = [];
        }
        
      }
    })
  }

  subCategoryReportAllData(page: any, searchString: any = "",orderSorting:string='',columnName:string='') {
    this.commonService.getSubCategoryReportAllData(page, searchString,orderSorting,columnName).subscribe(response => {
      if (response.success) {
        if(response?.data?.length){
          this.isNoDataFound = false
          this.subcategoryReportdata = response?.data;
        this.totalDataCount = response.total;
        }
        else{
          this.isNoDataFound =  true;
          this.subcategoryReportdata = [];
        }
      }
    })
  }

  pageChangeEvent(event: any) {
    let page = event.pageIndex + 1;
    switch (this.currentTab) {
      case 'homepage':
        this.getHomePageData(page);
        break;
      case 'resources':
        this.getresourceCenterData(page, 'resource');
        break;

      case 'contactUs':
        this.getInquiryData(page);
        break;
    }
  }
  ngOnInit(): void {
    let userPermission = JSON.parse(sessionStorage.getItem('userPermission')!);
    // this.pageAccoradingAction = this.updateIsActiveKeys(this.pageAccoradingAction, userPermission[0]);
    console.log("after-manage-port", this.pageAccoradingAction)
    this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0])
    console.log("action-manage-portal", this.action);
    this.selectedTab(this.currentTab)
    // this.getHomePageData(this.page);
  }

  openForm(isEditMode: boolean, data: any) {
    // this.router.navigate(['home'])
    // console.log('aakash')
    console.log(this.selectedSubTabName, this.currentTab);
    if (this.currentTab != 'resources') {
      this.selectedSubTabName = ''
    }

    switch (this.currentTab) {
      case 'homepage':
         /*Removing this condition "ele.sectionId != 4", "ele.sectionId != 7" & "ele.sectionId != 8" to pass the Line 
         item like (Our Partner, Our Team, Contact Us) data to the SectionWiseData [] whcih 
         is getting used on the Modal page */
         
        //  this.filteredData = this.homeData;

        this.sectionwiseData = [...this.homeAllSectionData];
        break;

      case 'resources':
        this.sectionwiseData = [...this.resourceCenter];
        if (this.selectedSubTabName == 'Category') {

        } else if (this.selectedSubTabName == 'Sub Category') {

        }
        else {
          this.sectionwiseData = [...this.subcategorydata];
        }

        break;

      case 'impactStories':
        // this.sectionwiseData = [...this.impactData];
        this.sectionwiseData = [...this.resourceCenter];

        break;

      case 'glossary':
        this.sectionwiseData = [...this.glossaryData];

        break;

      case 'teams':
        this.sectionwiseData = [...this.allTeamData];
        break;

      case 'ourPartners':
        this.sectionwiseData = [...this.partnerData];
        break;

      case 'aboutUs':
      // this.filteredData = this.commonHomepageData.filter((ele: any) => ele.sectionId != 7 && ele.sectionId != 8 && ele.sectionId != 4)

      this.sectionwiseData = [...this.aboutUsData];
      break;

      default:
        break;
    }
    let dialogRef = this.dialog.open(ManagePortalDialogComponent, {
      width: '700px',
      panelClass: 'manage-portalDialog',
      data: {
        currentTab: this.currentTab,
        editMode: isEditMode,
        data: data,
        sectionData: this.sectionwiseData,
        selectedSubTabName: this.selectedSubTabName
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log("connn", confirmed);
      switch (this.currentTab) {
        case 'homepage':
          this.getHomePageData(this.page, this.currentTab);
          break;

        case 'resources':
          if (this.selectedSubTabName == 'Category') {
            this.getresourceCenterData(this.page, 'resource');
          } else if (this.selectedSubTabName == 'Sub Category') {
            this.subCategoryData(this.page);
          } else {
            this.subCategoryReportAllData(this.page);
          }
          break;

        case 'impactStories':
          this.getimpactStoriesData(this.page);
          break;

        case 'glossary':
          this.getGlossaryData(this.page);
          break;

        case 'teams':
          this.getTeamsData(this.page);
          break;

        case 'ourPartners':
          this.getPartnerData(this.page);
          break;

        case 'aboutUs':
          this.getAboutUsData();
          break;

        default:
          break;
      }
    });
  }

  getAboutUsData(){
    // this.getHomePageData(page);
    this.commonService.aboutUsMasterData().subscribe(res => {
      if(res.success){
        this.isNoDataFound = false;
        console.log('ressssssss',res);
        this.aboutUsData = res?.data;
      }else{
        this.isNoDataFound = true;
        this.aboutUsData = [];
      }
    }, (error) => console.error(error))
  }

  getHomePageData(page: any, currentPage:any = "", searchString: any = "",sortingOrder:string='',columnName:string='') {
    console.log(searchString, typeof (searchString));
    this.commonService.homePageMasterData(page, searchString,sortingOrder,columnName, currentPage).subscribe(response => {
      if (response.success) {
        if(response?.data?.length){
          this.commonHomepageData = response?.data;
          this.isNoDataFound = false;
          // if(this.currentTab == 'aboutUs'){
          //   this.aboutUsData = response?.data.filter((ele:any) => ele.sectionId == 2);
          //   console.log("this.aboutUsData",this.aboutUsData);
            
          // }
          this.homeData = response?.data.filter((ele:any) => ele.sectionId != 2);
          this.homeAllSectionData = response?.setionData.filter((ele:any) => ele.sectionId != 2);
        console.log(this.homeData);
        this.totalDataCount = response?.total - 1;
        let multiImageObj = this.homeData.find((ele: any) => ele?.order == 4);
        let finalData = multiImageObj.banner.split(',');
        this.homeData.map((data: any) => {
          if (data.order == 4) {
            data['imgArray'] = finalData;
          }
        });
        }
        else{
          this.isNoDataFound = true;
          this.homeData = [];
        }
        // this.getAboutUsData(this.commonHomepageData);
      }
    }, (error) => console.error(error))
  }

  selectedTab(selectedTabName: any) {
    this.selectedColumn = "";
    this.skip = 0;
    this.limit = 10;
    this.totalDataCount = 0;
    this.page = 1;
    this.searchedValue = "";
    this.action = this.resetAction(this.action);
    console.log("selectedTab", this.action,)
    switch (selectedTabName) {
      case 'homepage':
        this.currentTab = selectedTabName;
        this.activeTab(selectedTabName);
        let homeAction = this.getPermissionPerPage(this.permissionDetails[0], 'cms');
        this.allowedAction = homeAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> homepage", homeAction);
        console.log("tab--> action", this.action);
        this.getHomePageData(this.page, selectedTabName);
        break;

      case 'resources':
        this.currentTab = selectedTabName;
        this.activeTab(selectedTabName);
        let resourcesAction = this.getPermissionPerPage(this.permissionDetails[0], 'category');
        this.allowedAction = resourcesAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> resources", resourcesAction);
        console.log("tab--> action", this.action);
        this.subtabs = this.resourceTabs(this.subtabs, this.permissionDetails[0]);
        console.log("subtabs", this.subtabs)
        this.getresourceCenterData(this.page, 'resource');
        this.selectedSubTabName = 'Category'
        break;

      case 'impactStories':
        this.currentTab = selectedTabName;
        // Relatable data in regards with type=impact stories, hence calling the resource API as well
        this.activeTab(selectedTabName);
        let impactStoriesAction = this.getPermissionPerPage(this.permissionDetails[0], 'impactstories');
        this.allowedAction = impactStoriesAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> impactStories", impactStoriesAction);
        console.log("tab--> action", this.action);
        this.getresourceCenterData(1, 'impact stories');
        this.getimpactStoriesData(this.page);
        // Clearing the selectedSubName Variable due to dependency of the Drowpdown condition
        this.selectedSubTabName = "";

        break;

      case 'glossary':
        this.currentTab = selectedTabName;
        this.activeTab(selectedTabName);
        let glossaryAction = this.getPermissionPerPage(this.permissionDetails[0], 'glossary');
        this.allowedAction = glossaryAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> glossary", glossaryAction);
        console.log("tab--> action", this.action);
        this.getGlossaryData(this.page);
        break;

      case 'contactUs':
        this.currentTab = selectedTabName;
        this.activeTab(selectedTabName);
        let contactUsAction = this.getPermissionPerPage(this.permissionDetails[0], 'inquiry');
        this.allowedAction = contactUsAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> contactUs", contactUsAction);
        console.log("tab--> action", this.action);
        this.getInquiryData(this.page);
        break;

      case 'teams':
        this.currentTab = selectedTabName;
        this.activeTab(selectedTabName);
        let temasAction = this.getPermissionPerPage(this.permissionDetails[0], 'teammember');
        this.allowedAction = temasAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> teams", temasAction);
        console.log("tab--> action", this.action);
        this.getTeamsData(this.page);
        break;

      case 'ourPartners':
        this.currentTab = selectedTabName;
        this.activeTab(selectedTabName);
        let ourPartnersAction = this.getPermissionPerPage(this.permissionDetails[0], 'partners');
        this.allowedAction = ourPartnersAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        console.log("tab--> ourPartners", ourPartnersAction);
        console.log("tab--> action", this.action);
        this.getPartnerData(this.page);
        break;

      case 'aboutUs':
        this.currentTab = selectedTabName;
        this.activeTab(selectedTabName);
        let aboutUsAction = this.getPermissionPerPage(this.permissionDetails[0], 'aboutUs');
        this.allowedAction = aboutUsAction;
        this.action = this.commonService.actionTablePermission(this.action, this.allowedAction[0]);
        this.getAboutUsData();
        break;
    }
  }

  getresourceCenterData(page: any, type: any, searchString: any = "", isDropDown: boolean = false,orderSorting:string='',columnName:string='') {
    this.commonService.resourceCenterMasterData(page, type, searchString, isDropDown,orderSorting,columnName).subscribe(res => {
      console.log("f",isDropDown)
      console.log("tab--> action fsfargvsfg 555", this.action);
      if (res.success) {
        if(res.data?.length){
          this.isNoDataFound = false;
          this.resourceCenter = res.data;
        // this.totalDataCount = res?.total;
        this.searchData = [...this.resourceCenter];
        }
        else{
          this.isNoDataFound = true;
          this.resourceCenter = [];
        }
      }
    })
  }

  getimpactStoriesData(page: any, searchString: any = "",sortingOrder:string='',columnName:string='') {
    this.commonService.impactStoriesMasterData(page, searchString,sortingOrder,columnName).subscribe(response => {
      console.log(response);
      if (response.success) {
        if(response?.data?.length){
          this.isNoDataFound = false;
          this.impactData = response?.data;
          console.log("impacttt",this.impactData);
  
          // this.impactData.map((ele:any) => ele.subTitle.replace(/<[^>]*>/g, ''));
          console.log("impacttt",this.impactData);
          
          this.totalDataCount = response?.total;
          this.searchData = [...this.impactData];
        }
        else{
          this.isNoDataFound = true;
          this.impactData = [];
        }
       
      }
    }, (error) => console.error(error))
  }

  getGlossaryData(page: any, searchString: any = "",sortingOrder:string='',columnName:string='') {
    this.commonService.glossaryMasterData(page, searchString,sortingOrder,columnName).subscribe((res) => {
      console.log("glosary data", res);
      if (res.success) {
        if(res.data?.length){
          this.isNoDataFound = false;
          this.glossaryData = res.data;
        this.totalDataCount = res?.total;
        this.searchData = [...this.glossaryData];
        }
        else{
          this.isNoDataFound = true;
          this.glossaryData = [];
        }
      }
    }, (error) => console.error(error));
  }

  getInquiryData(page: any, searchString: any = "",sortingOrder:string='',columnName:string='') {
    this.commonService.inquiryMasterData(page, searchString,sortingOrder,columnName).subscribe(res => {
      if (res.success) {
        if(res.data?.length){
          this.isNoDataFound = false;
          this.contactUsTable = res.data;
        this.totalDataCount = res?.total;
        this.searchData = [...this.contactUsTable];
        }
        else{
          this.isNoDataFound = true;
          this.contactUsTable = [];
        }
      }
    })
  }

  openSnackBar(data: string[], duration: number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }

  deleteLineItem(data: any) {
    let endPoints: any = {
      homepage: 'cms/delete/',
      resources: 'category/delete/',
      impactStories: 'impactstories/delete/',
      glossary: 'glossary/delete/',
      teams: 'teammember/update/'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: "Are you sure you want to delete?",
        buttonText: {
          ok: "Yes",
          cancel: "No",
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      console.log(confirmed);
      if (confirmed) {
        switch (this.currentTab) {
          case 'homepage':
            this.commonService.deleteLineItems(endPoints[this.currentTab], data?.id).subscribe(res => {
              if (res.success) {
                this.openSnackBar([res?.message], 3000);
                this.getHomePageData(this.page, this.currentTab);
              }
            })
            break;

          case 'resources':
            this.commonService.deleteLineItems(endPoints[this.currentTab], data?.id).subscribe(res => {
              if (res.success) {
                this.openSnackBar([res?.message], 3000);
                this.getresourceCenterData(1, 'resource');
              }
            })
            break;

          case 'impactStories':
            this.commonService.deleteLineItems(endPoints[this.currentTab], data?.id).subscribe(res => {
              if (res.success) {
                this.openSnackBar([res?.message], 3000);
                this.getimpactStoriesData(this.page);
              }
            })
            break;

          case 'glossary':
            this.commonService.deleteLineItems(endPoints[this.currentTab], data?.id).subscribe(res => {
              if (res.success) {
                this.openSnackBar([res?.message], 3000);
                this.getGlossaryData(this.page);
              }
            })
            break;

          case 'teams':
            let bodyData = {
              isActive: false
            };
            this.commonService.removeTeamMember(data?.id, bodyData).subscribe((res: any) => {
              if (res.success) {
                this.openSnackBar([res?.message], 3000);
                this.getTeamsData();
              }
            })
            break;

          default:
            break;
        }
      }
    });
  }

  //CLEAR SEARCH
  clearSearch() {
    if(this.searchedValue.trim()){
      this.searchedValue = "";
      this.onKeyDownEvent();
    }
  }

  // COMMON SEARCH FUNCTIONALITY
  onKeyDownEvent() {
    this.limit = 10;
    this.skip = 0;
    this.totalDataCount = 0;
    switch (this.currentTab) {
      case 'homepage':
        this.getHomePageData(1, this.currentTab, this.searchedValue);
        break;
      case 'resources':
        if (this.selectedSubTabName == 'Category') {
          this.getresourceCenterData(1, 'resource', this.searchedValue);
        } else if (this.selectedSubTabName == 'Sub Category') {
          this.subCategoryData(1, this.searchedValue);
        } else {
          this.subCategoryReportAllData(1, this.searchedValue);
        }
        break;
      case 'impactStories':
        this.getimpactStoriesData(1, this.searchedValue);
        break;
      case 'glossary':
        this.getGlossaryData(1, this.searchedValue);
        break;
      case 'contactUs':
        this.getInquiryData(1, this.searchedValue);
        break;
      case 'teams':
        this.getTeamsData(1, this.searchedValue);
        break;
      case 'ourPartners':
        this.getPartnerData(1, this.searchedValue);
        break;
    }

    // Search Functionality handled from Frontend
    // console.log(this.homeData, this.searchData);
    // if(this.searchedValue){
    //   this.homeData = this.searchData.filter((ele:any) => {
    //     if(typeof(ele) == 'object'){
    //       if(ele.name.toLowerCase().includes(this.searchedValue.toLowerCase()) || ele.title.toLowerCase().includes(this.searchedValue.toLowerCase())){
    //         return ele;
    //       }
    //     }
    //   });
    //   console.log("kokok",this.homeData);
    // }else{
    //   this.homeData = this.searchData;
    //   console.log("enter some value");
    // }  
  }

  onRadioChange(event: any, lineItemID: any) {
    let endPoints: any = {
      homepage: 'cms/update/',
      resources: 'category/update/',
      impactStories: 'impactstories/update/',
      glossary: 'glossary/update/',
      teams: 'teammember/update/',
      ourPartners: 'partners/update/'
    };
    console.log(event);
    let bodyData = {
      isActive: event
    };
    switch (this.currentTab) {
      case 'homepage':
        this.commonService.updateCMSlineitem(endPoints[this.currentTab], bodyData, lineItemID).subscribe(res => {
          if (res.success) {
            this.openSnackBar([res?.message], 3000);
          }
        })
        break;
      case 'resources':
        let resourceEndpoints: any = {
          'Category': 'category/update/',
          'Sub Category': 'subcategory/update/',
          'Sub-Category Report': 'subcategoryreport/update/',
        };
        this.commonService.updateCMSlineitem(resourceEndpoints[this.selectedSubTabName], bodyData, lineItemID).subscribe(res => {
          if (res.success) {
            this.openSnackBar([res?.message], 3000);
          }
        })
        break;
      case 'impactStories':
        this.commonService.updateCMSlineitem(endPoints[this.currentTab], bodyData, lineItemID).subscribe(res => {
          if (res.success) {
            this.openSnackBar([res?.message], 3000);
          }
        })
        break;
      case 'glossary':
        this.commonService.updateCMSlineitem(endPoints[this.currentTab], bodyData, lineItemID).subscribe(res => {
          if (res.success) {
            this.openSnackBar([res?.message], 3000);
          }
        })
        break;

      case 'teams':
        this.commonService.updateCMSlineitem(endPoints[this.currentTab], bodyData, lineItemID).subscribe(res => {
          if (res.success) {
            this.openSnackBar([res?.message], 3000);
          }
        })
        break;

      case 'ourPartners':
        this.commonService.updateCMSlineitem(endPoints[this.currentTab], bodyData, lineItemID).subscribe(res => {
          if (res.success) {
            this.openSnackBar([res?.message], 3000);
          }
        })
        break;
      
        case 'aboutUs':
        this.commonService.aboutUsMasterData(bodyData, lineItemID, true).subscribe(res => {
          if (res.success) {
            this.openSnackBar([res?.message], 3000);
          }
        })
        break;
    }

  }

  openFile(item: any) {
    window.open(item?.link);
  }

  getTeamsData(page: any = "", searchString: any = "",sortingOrder:string='',columnName:string='') {
    this.commonService.teamAllData(page, searchString,sortingOrder,columnName).subscribe((res: any) => {
      console.log("TeamsData", res);
      if(res.data?.length){
        this.isNoDataFound = false;
        this.allTeamData = res.data;
      this.totalDataCount = res.total;
      }
      else{
        this.isNoDataFound = true;
        this.allTeamData = [];
      }

    })
  }

  getPartnerData(page: any = "", searchString: any = "",sortingOrder:string='',columnName:string='') {
    this.commonService.partnerAllData(page, searchString,sortingOrder,columnName).subscribe((res: any) => {
      console.log("partnrData", res);
      if(res?.data?.length){
        this.isNoDataFound = false;
        this.partnerData = res?.data;
      this.totalDataCount = res.total;
      }
      else{
        this.isNoDataFound = true;
        this.partnerData = [];
      }
    })
  }



  setPage(event: any) {
    // this.selectedColumn = '';
    this.skip = (event.pageIndex + 1) * this.limit - this.limit;
    console.log('limit', event.pageSize, event, this.skip, this.currentTab, this.selectedSubTabName)
    this.limit = event.pageSize;
    let tabdata = '';
    if (this.currentTab === 'resources') {
      if (!this.selectedSubTabName) {
        tabdata = 'resources'
      }
      else {
        tabdata = this.selectedSubTabName;
      }
    }
    else {
      tabdata = this.currentTab;
    }
    console.log("cu", tabdata);
    switch (tabdata) {
      case 'homepage':
        this.page = event.pageIndex + 1;
        this.getHomePageData(event.pageIndex + 1,tabdata, '', this.orderSorting, this.selectedColumn);
        break;
      case 'Category':
        this.page = event.pageIndex + 1;
        this.getresourceCenterData(event.pageIndex + 1, 'resource', '',false, this.orderSorting, this.selectedColumn);
        break;
      case 'impactStories':
        this.page = event.pageIndex + 1;
        this.getimpactStoriesData(event.pageIndex + 1, '', this.orderSorting, this.selectedColumn);
        break;
      case 'glossary':
        this.page = event.pageIndex + 1;
        this.getGlossaryData(event.pageIndex + 1, '', this.orderSorting, this.selectedColumn);
        break;
      case 'contactUs':
        this.page = event.pageIndex + 1;
        this.getInquiryData(event.pageIndex + 1, '', this.orderSorting, this.selectedColumn);
        break;
      case 'teams':
        this.page = event.pageIndex + 1;
        this.getTeamsData(event.pageIndex + 1, '', this.orderSorting, this.selectedColumn);
        break;
      case 'ourPartners':
        this.page = event.pageIndex + 1;
        this.getPartnerData(event.pageIndex + 1, '', this.orderSorting, this.selectedColumn);
        break;
      // case 'Category':
      //   this.tabWiseData(event,event.pageIndex + 1,true,0,'Category');
      //   break;
      case 'Sub Category':
        // this.tabWiseData(event,event.pageIndex + 1,true,1,'Sub Category');
        this.page = event.pageIndex + 1;
        this.subCategoryData(event.pageIndex + 1, '',false, this.orderSorting, this.selectedColumn);
        break;
      case 'Sub-Category Report':
        // this.tabWiseData(event,event.pageIndex + 1,true,2,'Sub-Category Report');
        this.page = event.pageIndex + 1;
        this.subCategoryReportAllData(event.pageIndex + 1, '', this.orderSorting, this.selectedColumn)
        break;
    }
  }
  getPermissionPerPage(data: any, modelName: string): any {
    return data.authenicationJson.filter((el: any) => {
      if (el.model === modelName) return el;
    })
  }
  resetAction(data: any): any[] {
    data.forEach((el: any) => {
      el.isPermission = false;
    });

    return data;
  }
  resourceTabs(mainArray: any[], secondaryArray: any): any[] {
    return mainArray.map((mainItem: any) => {
      secondaryArray?.authenicationJson?.forEach((secondaryItem: any) => {
        let checkPermissionModule = false;
        checkPermissionModule = (secondaryItem?.isAction || secondaryItem?.is_checked) || secondaryItem?.authentication.some((e: any) => e?.isAction);
        if (mainItem.model === secondaryItem.model) {
          if (checkPermissionModule) {
            mainItem.isActive = true;
          }
        }
      });

      return mainItem;
    });
  }
  activeTab(selectedTabName: string) {
    this.pageAccoradingAction = this.pageAccoradingAction.map((el: any) => {
      el.isChecked = el.isActive = el?.selectedTabs === selectedTabName || el?.model.includes(selectedTabName);
      if(el?.selectedTabs === selectedTabName || el?.model.includes(selectedTabName))  this.currentTab =  el?.selectedTabs;
      return el;
    })
  }
  updateIsActiveKeys(mainArray: any[], secondaryArray: any): any[] {
    return mainArray.map((mainItem: any) => {
      
      secondaryArray?.authenicationJson?.forEach((secondaryItem: any) => {
        let checkPermissionModule = false;
        checkPermissionModule = (secondaryItem?.isAction || secondaryItem?.is_checked) || secondaryItem?.authentication.some((e: any) => e?.isAction);
        console.log("mainItem", checkPermissionModule, mainItem.model,secondaryItem );
        let permitModule = secondaryItem.model;
        if (mainItem.model.includes(permitModule)) {
          if (checkPermissionModule) {
            mainItem.isActive = true;
          }
        }
      });

      return mainItem; // Return the updated mainItem
    });
  }
  getCurrentTabActiveMethod(data: any) {
    let getCurrentTab: any = null; 
  
    this.resourceTab.some((el: any) => {
      getCurrentTab = data.authenicationJson.find((e: any) => {
        return e.model.includes(el) &&
          (e.isAction || e.is_checked || (e.authentication && e.authentication.some((inside: any) => inside.isAction)));
      });
  
      return getCurrentTab !== undefined; 
    });
  
    return getCurrentTab;
  }

  stripHtmlTags(input: any): string {
    const sanitizedHtml = this.sanitizer.sanitize(SecurityContext.HTML, input);
  
    const text = sanitizedHtml ? sanitizedHtml.replace(/<[^>]*>/g, '') : '---';
  
    return text;
   
  
  }
  sortTable(columnName: string, sortType:string = '') {
    this.sortSelection = sortType;
    this.selectedColumn = columnName;
    this.orderSorting = sortType == 'down' ? 'DESC' : 'ASC';
    switch (this.currentTab) {
      case 'homepage': this.getHomePageData(this.page, this.currentTab, '', this.orderSorting, columnName);
        break;
      case 'resources':
        if (this.selectedSubTabName == 'Category') {
          this.getresourceCenterData(this.page, 'resource', '', false, this.orderSorting, columnName);
        } else if (this.selectedSubTabName == 'Sub Category') {
          this.subCategoryData(this.page, '', false, this.orderSorting, columnName);
        } else {
          this.subCategoryReportAllData(this.page, '', this.orderSorting, columnName,);
        }
        break;
      case 'impactStories':
        this.getimpactStoriesData(this.page,'',this.orderSorting, columnName);
        break;

      case 'glossary':
        this.getGlossaryData(this.page,'',this.orderSorting, columnName);
        break;

      case 'teams':
        this.getTeamsData(this.page,'',this.orderSorting, columnName);
        break;

      case 'ourPartners':
        this.getPartnerData(this.page,'',this.orderSorting, columnName);
        break;
        case 'contactUs':this.getInquiryData(this.page,'',this.orderSorting, columnName)
          break;

    }
    
  
  }

  hasActionTrueForModel(modelName:any=[], key:string= '') {
    // Find the object with the specified modelName
    if(this.pageAction && this.pageAction[0] && this.pageAction[0].authenicationJson){
    const modelObject = this.pageAction[0].authenicationJson.find((obj:any) => modelName.includes(obj.model) );
    
    // If the object is found and it has an authentication array
    if (modelObject && Array.isArray(modelObject.authentication)) {
        // Check if any action is true in the authentication array
        return modelObject.authentication.some((auth:any) => auth.isAction === true);
    }
  }
    
    // If the object is not found or it does not have an authentication array
    return false;
}
  

}
