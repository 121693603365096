<div class="Footer_bottom">
    <section class="bg-white">
        <div class="container-fluid mt-2">
            <div class="d-flex align-items-center">
                <svg class="me-2 pointer" (click)="backToForm()" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                    <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
                  </svg>
                  
                <nav aria-label="breadcrumb" class="my-3">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToForm()">IPSA</li>
                    <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">Introduction to the IPSA</li>
                  </ol>
                </nav>
              </div>
            <div class="card border-0 rounded-0">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-md-7">
                            <div class="section_one">
                                <h5 class="fs-20 fw-600">Introduction to the Industrial Park Sustainability Assessment (IPSA) and its relevance</h5>
                                <p>As Textile & Apparel (T&A) industrial level production becomes a more active contributor to developing and emerging trade economies, the sector also faces the challenges that growing industrial sectors often bring. These challenges include increased social and environmental risks through impacts such as pollution, workforce issues, and the relationship with global buyers and investors, who are increasingly enshrining sustainability at the core of their business practices. It is therefore important that effective safeguards are in place to help T&A producers, especially those operating in Industrial Park (IP) settings.
                                </p>
                                <p>In this context, IDH’s In Sustainable Textile and Apparel Parks (INSTEP) program aims to support countries improve the social and environmental performance of their industrial production to address public concerns, meet with global buyer requirements and promote the sustainability of IPs development. 
                                </p>
                                <p>As part of the INSTEP program, IDH has developed the Industrial Park Sustainability Assessment (IPSA). The IPSA is a tool that assesses the performance of IPs and T&A factories in a transparent manner. It provides a holistic and comprehensive platform to not only measure their performance, but to also support continuous improvement and transformation.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <!-- <img src="../../../assets/images/ipsa_img.png" class="img-fluid rounded-3" alt=""> -->
                            <div class="colored-box position-relative">
                                <div class="hover-image">
                                  <img src="../../../assets/images/ipsa_img.png" class="img-fluid" alt="">
                                </div>
                              </div>
                        </div>
                    </div>


                    <div class="section_two">
                        <h5 class="fs-20 fw-600">Application of the IPSA</h5>
                        <p>The IPSA is for use in Kenya, Ethiopia and Vietnam. It is designed to assess the social and environmental dimensions of IPs and T&A factories that operate within IP settings in the in-scope countries. The IPSA includes a high-level building safety dimension that is focused on the assessment of maintenance aspects that could impact the occupational health and safety of occupants at factory building level. 
                        </p>
                        <p>The IPSA is applicable to existing IPs and T&A factories. It can also act as a guide in the design phase of a new IP that aspires to operate in line with sustainability principles. 
                        </p>
                        <p>Results from an IPSA assessment will support discussions, among IP and T&A factory management, towards the development of a roadmap for continuous improvement and transformation.
                        </p>
                    </div>
        
                    <div class="fs-20 fw-600 mb-2">IPSA Dimensions and Themes</div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead class="table-light">
                                <tr>
                                    <th class="bg_pink">Dimensions</th>
                                    <th class="bg_purple text-white">Social</th>
                                    <th class="bg_green text-white">Environmental</th>
                                    <th class="bg_skyBlue text-white">Building Safety</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="tr_first">
                                    <td rowspan="9" class="" style="vertical-align: middle;">Themes</td>
                                    <td class="bg_puprle_100 custom_padding">Social infrastructure</td>
                                    <td class="bg_green_100 custom_padding">Integrated water management</td>
                                    <td class="bg_skyBlue_100 custom_padding">Building maintenance / repair</td>
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_200 custom_padding"></td>
                                    <td class="bg_green_200 custom_padding">Industrial wastewater</td>
                                    <td class="bg_skyBlue_200 custom_padding">Electrical safety</td>
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_100 custom_padding">Indoor environment</td>
                                    <td class="bg_green_100 custom_padding">Solid waste management</td>
                                    <td class="bg_skyBlue_100 custom_padding">Fire safety</td>
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_200 custom_padding">Worker engagement</td>
                                    <td class="bg_green_200 custom_padding">Energy management</td>
                                    <td rowspan="6" class="bg_skyBlue_100 custom_padding"></td>
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_100 custom_padding">Gender empowerment</td>
                                    <td class="bg_green_100 custom_padding">Chemicals management</td>
                                    <!-- <td></td> -->
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_200 custom_padding">General social management and monitoring</td>
                                    <td class="bg_green_200 custom_padding">Emissions</td>
                                    <!-- <td></td> -->
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_100 custom_padding"></td>
                                    <td class="bg_green_100 custom_padding">Climate resilience</td>
                                    <!-- <td></td> -->
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_100 custom_padding"></td>
                                    <td class="bg_green_200 custom_padding">Environmental transparency</td>
                                    <!-- <td></td> -->
                                </tr>
                                <tr>
                                    <!-- <td></td> -->
                                    <td class="bg_puprle_100 custom_padding"></td>
                                    <td class="bg_green_100 custom_padding">General environmental management</td>
                                    <!-- <td></td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>
            </div>
    </section>
</div>