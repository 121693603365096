import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgImageSliderComponent } from 'ng-image-slider';
import { DomSanitizer } from '@angular/platform-browser';
import { HomeService } from 'src/app/shared/services/home.service';

@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss']
})
export class IframeDialogComponent implements OnInit {
  docExtensions = [
    'pdf',
    'txt',
  ];
  pptExtension = ['ppt','pptx'];
  wordExtension = ['doc','docm','docx']
  excelExtensions = [ 'csv','xls', 'xlsb','xlsx',];
  imgExtensions = ['jpeg', 'jpg', 'png', 'gif', 'raw', 'image'];
  videoExtensions = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
    'webm',
    // 'html5',
    'mpeg-2',
  ];

  @ViewChild('nav') slider: NgImageSliderComponent | undefined;
  constructor(
    public dialogRef: MatDialogRef<IframeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer,
    private homeService: HomeService
  ) {}

  getDownloadLink() {
    return 'https://www.akamai.com/site/im-demo/perceptual-standard.jpg?imbypass=true';
  }
  ngOnInit(): void {
    console.log("download", this.data)
  }

  downloadCount(id: any, requestType: any) {
    let el = document.getElementsByClassName('carousel-item active');
    console.log('el', el);
    let downloadIndex = '';
    if (el) {
      downloadIndex = el[1] && el[1].id || el[0].id;
      this.data.fileList,
      this.data.fileList[downloadIndex],
      this.downloadFile(this.data.fileList[downloadIndex]);
    }

    // this.contentService.countApi(id, requestType).subscribe(
    this.homeService.countApi(this.data.fileList[downloadIndex].id, requestType).subscribe(
      (res:any) => {},
      (error:any) => {
        console.log(error);
      }
    );
  }

  url: any;

  downloadFile(fileData: any) {
    console.log('downloadFile', fileData);
    // let link = document.createElement('a');
    this.url = fileData.fileUrl;
  }

  

  getReturnType(type: string) {
    // console.log(type);
    if (this.imgExtensions.includes(type.toLowerCase())) {
      return 'image';
    }
    if (this.videoExtensions.includes(type.toLowerCase())) {
      return 'video';
    }
    if (this.docExtensions.includes(type.toLowerCase())) {
      return 'pdf';
    }
    if(this.excelExtensions.includes(type.toLowerCase())) {
      return 'excel';
    }
    if(this.wordExtension.includes(type.toLowerCase())) {
      return 'word';
    }
    if(this.pptExtension.includes(type.toLowerCase())) {
      return 'ppt';
    }
    return 'default';
  }
  geturl(url:string){
    let newUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url+'#toolbar=0');
    return newUrl

  }
  getDocUrl(url:string){
    let prefixUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';
    let suffixUrl =  url;
    let fullUrl = prefixUrl + suffixUrl;
    return this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
  }

}
