import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FooterComponent } from 'src/app/layout/footer/footer.component';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GDPRComponent implements OnInit {

  gdprPoints:any = [
    {
      "policyPoint" : "IDH will be the only organization with access to your data and we will keep this information under strict confidentiality.",
    },
    {
      "policyPoint" : "IDH is an international organization that seeks to improve and scale up sustainable production & trade to deliver large-scale impact on the Sustainable Development Goals.",
    },
    {
      "policyPoint" : "The personal information entered on this website will allow communication between IDH and users of the INSTEP Portal.",
    },
    {
      "policyPoint" : "IDH will store your answers and location details for a maximum of 10 years in order to be able to follow up at a later stage.",
    },
  ];

  gdprChecked:boolean = false;

  constructor(public dialogRef: MatDialogRef<GDPRComponent>, private snackBar: MatSnackBar) {
    this.gdprChecked = JSON.parse(sessionStorage.getItem("gdprConfirmation")!);
   }

  ngOnInit(): void {
  }

  
  isDisable(){
    return !this.gdprChecked
  }
  
  selectGDPR(event:any){
    console.log(event);
    this.gdprChecked = event?.checked;
  }
  submitGDPR(){
    // sessionStorage.setItem("gdprConfirmation", JSON.stringify(this.gdprChecked));
    this.closeDialog(true);
  }

  closeDialog(response:any){
    if(response)
    // this.openSnackBar("GDPR confirmation saved !")
    this.dialogRef.close(true);
    
  }

  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    panelClass: [className]
  });
}
}
