<div class="Footer_bottom">
  <div class="container-fluid px-md-4">
    <div class="row align-items-center">
      <div class="col-12">
        <nav aria-label="breadcrumb" class="mt-2">
          <ol class="breadcrumb mb-3 align-items-center">
            <li>
              <svg routerLink="/resources" class="me-2 pointer" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
              </svg>
              
            </li>
            <li class="breadcrumb-item fs-18 fw-500 m-0 pointer" routerLink="/resources">Resource Center</li>
            <li class="breadcrumb-item fs-18 fw-500 m-0 pointer" [ngStyle]="{'color': this.showInstepResource ? '#00625F' : '#242424'}" (click)="changeTab()">{{subCategoryName}}</li>
            <li class="breadcrumb-item fs-18 fw-500 m-0 pointer" [ngStyle]="{'color': this.showInstepSlideDeck ? '#00625F' : '#242424'}" *ngIf="showInstepSlideDeck" >{{subCategoryReportName}}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
  <section class="resource-view" *ngIf="subcategorydata.length > 0">
    <div class="container-fluid px-sm-4">
      <div class="row">
        <div class="col-12">
          <!-- <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item fs-12 fw-400 mb-2 pointer" routerLink="/resources">Global Assessment Framework</li>
              <li class="breadcrumb-item fs-12 fw-500 mb-2 pointer" routerLink="/resources-view" style="color: #00625F;">INSTEP Resources</li>
              <li class="breadcrumb-item fs-12 fw-500 mb-2 pointer" style="color: #00625F;" *ngIf="allDocuments.length > 0">INSTEP slide deck</li>
            </ol>
          </nav> -->
          <!-- <h6 class="fs-18 fw-600 mb-3" style="color: #444444">{{subCategoryName}}</h6> -->
        </div>
  
        <div class="col-md-3" *ngFor="let item of subcategorydata">
          <div class="card folder-card border-0 mb-3 pointer" (click)="showDocuments(item)">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <img src="../../../assets/images/folder-solid.svg" alt="" />
                </div>
  
                <div class="col">
                  <div class="fs-14 fw-600">{{item?.name }}</div>
                  <div class="fs-12">
                    <span class="fw-400" style="color: #6d6d6d">Created on: </span>
                    <span class="fw-400" style="color: #444444">{{item?.createdAt | date: 'dd/MM/yyyy'}}</span>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="fs-12 mb-3 fw-400">{{item['subcategoryreport.count']}} files</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  <section class="resources-pdf" *ngIf="allDocuments.length > 0">
    <div class="container-fluid px-md-4">
      <div class="row">
        <div class="col-12">
          <!-- <ul class="d-flex list-unstyled mb-2">
            <li class="fs-12">
              <a href="" class="text-decoration-none me-2 text-dark">Global Assessment Framework /</a>
            </li>
            <li class="fs-12">
              <a href="" class="text-decoration-none me-2 text-dark">INSTEP Resources /</a>
            </li>
            <li class="fs-12">
              <a href="" class="text-decoration-none me-2 fw-600" style="color: #00625F;">INSTEP slide deck</a>
            </li>
          </ul> -->
          <!-- <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item fs-12 fw-400 mb-2 pointer" routerLink="/resources">Global Assessment Framework</li>
              <li class="breadcrumb-item fs-12 fw-400 mb-2 pointer" (click)="changeTab()">INSTEP Resources</li>
              <li class="breadcrumb-item fs-12 fw-500 mb-2 pointer" style="color: #00625F;">INSTEP slide deck</li>
            </ol>
          </nav> -->
  
          <!-- <div class="mb-3 fs-18 fw-600">{{subCategoryReportName}}</div> -->
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6 mb-3" *ngFor="let item of allDocuments">
          <div class="card rounded-top">
            <div class="card-body p-0" style="height: 300px;">
              <ng-container *ngIf="item?.isImg ; else pdfView">
                <img [src]="item.link" class="img-fluid rounded-top w-100" style="height: 300px;" alt="">
              </ng-container>
              <ng-template #pdfView>
                <ng-container *ngIf="item?.isPdfKey ; else otherFileType">
                  <iframe [src]="geturl(item.link)"  class="img-fluid rounded-top w-100" style="height: 300px;" [title]="item?.name" >
                  </iframe>
                </ng-container>
                <ng-template #otherFileType>
                  <div *ngIf="isExcelOrCsv(item.link)">
                    <img *ngIf="isExcelOrCsvTypeShowPreview(item.link,'xlsx')" src="../../../assets/no_data_image/xlsx.png" class="img-fluid rounded-top w-100" style="height: 300px;" alt="">
                    <img *ngIf="isExcelOrCsvTypeShowPreview(item.link,'csv')" src="../../../assets/no_data_image/csv.png" class="img-fluid rounded-top w-100" style="height: 300px;" alt="">
                    <img *ngIf="isExcelOrCsvTypeShowPreview(item.link,'docx')" src="../../../assets/no_data_image/docx.png" class="img-fluid rounded-top w-100" style="height: 300px;" alt="">
                  </div>
                  <iframe [src]="geturl(item.link)"  class="img-fluid rounded-top w-100" style="height: 300px;" [title]="item?.name" sandbox="allow-same-origin">
                  </iframe>
                </ng-template>
              </ng-template>
            </div>
          </div>
          <!-- <div>
            <iframe [src]="item.link" class="w-100" frameborder="0"></iframe>
          </div> -->
          <div class="bottom-card bg-white d-flex align-items-center justify-content-between">
            <div class="color-44">
              <div class="fs-14 docName" [matTooltip]="item?.name?.length < 30 ? '' : item?.name">{{item?.name | truncatePipe : 30}}</div>
              <div class="fs-12">{{item?.updatedAt | date:'dd/MM/yyyy'}}</div>
            </div>
            <div>
              <img src="../../../assets/images/download-pdf.svg" class="img-fluid pointer mb-3 downldbtn" alt="" (click)="downloadDocs(item)">
              <!-- <img [src]="item.link" class="img-fluid pointer" alt=""> -->
            </div>
          </div>
        </div>
        
        <!-- <div class="col-md-4">
          <div class="card rounded-top">
            <img src="../../../assets/images/resources-pdff.png" class="img-fluid" alt="">
          </div>
          <div class="bottom-card bg-white d-flex align-items-center justify-content-between">
            <div class="color-44">
              <div class="fs-14">Good Practices Sharing Session - Deck</div>
              <div class="date fs-12">25/08/2022</div>
            </div>
            <div>
              <img src="../../../assets/images/download-pdf.svg" class="img-fluid pointer" alt="">
            </div>
          </div>
        </div> -->
  
      </div>
    </div>
  </section>
  <div class="col-lg-12 noDataFound" *ngIf="showNoDataFound">
    <img src="../../../assets/images/nodatafound.png" alt="" class="noDataImage">
  </div>
</div>