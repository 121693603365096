<img src="../../../assets/images/login-close.svg" alt="" mat-dialog-close="" (click)="close()" class="close-btn position-absolute">
<div class="scrollar">
    <div class="bg-c container-fluid">
        <div class="row">
            <div class="col">
                <div class="title fs-20 mb-2 wt fs-bold">
                    {{addData?.title}}
                </div>
            </div>
            <!-- <div class="col-4 text-end">
                <span class="wt">
                    <svg class="close-btn" (click)="close()" id="close_black_24dp" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24">
                        <path id="Path_89" data-name="Path 89" d="M0,0H24V24H0Z" fill="none" />
                        <path id="Path_90" data-name="Path 90"
                            d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
                            fill="black" />
                    </svg>
                </span>
            </div> -->
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="p-1" *ngIf="addData?.data?.key == 'countryMaster'">
                    <form [formGroup]="countryForm" *ngIf="countryForm">
                        <div class="form-floating mb-3">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Name *</mat-label>
                                <input matInput type="text" placeholder="Enter Name" formControlName="name"
                                appAlphabatesOnly>
                            </mat-form-field>
                            <span class="invalid-feedback mb-0"
                                *ngIf="(countryForm.get('name')?.touched  || countryForm.get('name')?.dirty) && countryForm.get('name')?.invalid">
                                Name is required *.
                            </span>
                            <div class="mt-2">
                                <ng-container>
                                    <h6 class="fs-16 fw-bold mb-3">Country image* </h6>
                                    <div class="receiptCard mt-2 mt-md-3">
                                        <div class="uploadContainer my-2" appDnd (fileDropped)="onFileDropped($event)">
                                            <div class="box">
                                                <input type="file"
                                                    accept="image/png, image/gif, image/jpeg" #fileDropRef id="fileDropRef"
                                                    formControlName='countryImage' (change)="fileBrowseHandler($event)" />
                                                <img src="../../../../assets/images/Image-upload.png" class="img-fluid mb-3"
                                                    style="max-width: 200px;" alt="">
    
                                                <!-- <h3 class="mt-4">Drag and drop file here</h3>
                                                    <h3 class="mt-4">or</h3> -->
                                                <div style="font-size:14px; font-weight:500; margin-top:10px;">Upload
                                                    Receipt/Screenshot
                                                </div>
                                                <!-- <label for="fileDropRef">Browse for file</label> -->
                                                <div style="font-size: 12px;">
                                                    <label for="fileDropRef"
                                                        style="color:#131314; font-weight:500">Browse</label>
                                                    <span style="margin-left:4px; font-weight:500;">or Drag & Drop</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p class="uploadFileInfo"><i>{{fileUploadInfo}}</i></p>
                                        </div><br>
                                        <div class="files-list mx-auto">
                                            <div class="single-file" *ngFor="let file of files; let i = index">
                                                <div class="file-icon" style="width: 50px">
                                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;"
                                                        xml:space="preserve">
                                                        <polygon style="fill:#EDEADA;"
                                                            points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                                        <g>
                                                            <path style="fill:#CEC9AE;"
                                                                d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                                            <path style="fill:#CEC9AE;"
                                                                d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                                            <path style="fill:#CEC9AE;"
                                                                d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                                            <path style="fill:#CEC9AE;"
                                                                d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                                            <path style="fill:#CEC9AE;"
                                                                d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                                        </g>
                                                        <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                                                    </svg>
                                                </div>
                                                <div class="info">
                                                    <a style="text-decoration: none;" href="" target="_blank" class="name">
                                                        {{ file?.name ? file?.name : file?.file_Path}}
                                                    </a>
                                                    <!-- <p class="size">
                                                            {{ formatBytes(file?.size) }}
                                                        </p> -->
                                                    <div *ngIf="file?.progress">
                                                        <app-upload-progress
                                                            [progress]="file?.progress"></app-upload-progress>
                                                    </div>
                                                </div>
    
                                                <div class="delete" (click)="deleteFile(i)">
                                                    <img src="../../../../assets/images/delete-bin.svg" alt=""
                                                        style="cursor: pointer;">
                                                </div>
    
                                            </div>
    
                                        </div>
                                        <div *ngIf="isEditMode" class="d-flex">
                                            <img *ngIf="imgURL?.length" class="prefillImage"
                                                [src]="imgURL.includes('pdf') ? '../../../../assets/icons/pdfIcon.svg' : imgURL.includes('.docx') ? '../../../../assets/icons/msWordIcon.svg' : imgURL"
                                                alt="" (click)="openDocument()">
                                            <div *ngIf="imgURL?.length" [matTooltip]="'Delete'" class="delete ms-2" (click)="deleteFile(0)">
                                                    <img src="../../../../assets/images/delete-bin.svg" alt="" style="cursor: pointer;">
                                              </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="btn-c">
                            <button type="button" class="btn text-white rounded-pill signup-btn w-100"
                                (click)="createCountryForm()" [disabled]="countryForm.invalid">{{addData?.rowData ? 'Update'
                                : 'Create'}} </button>
                        </div>
                    </form>
                </div>
                <div class="p-1" *ngIf="addData?.data?.key == 'ipMaster'">
                    <form [formGroup]="ipForm" *ngIf="ipForm">
                        <div class="form-floating mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Country Name *</mat-label>
                                <mat-select formControlName="country_id" placeholder="Select Country" aria-label="ip">
                                    <mat-option *ngFor="let item of countryList" [value]="item?.id">
                                        {{ item?.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span class="invalid-feedback mx-3 mb-0"
                                *ngIf="(ipForm.get('country_id')?.touched  || ipForm.get('country_id')?.dirty) && ipForm.get('country_id')?.invalid">
                                Country Name is required *.
                            </span>
                        </div>
                        <div class="form-floating mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>IP Name *</mat-label>
                                <input matInput type="text" formControlName="name" placeholder="Enter IP Name"
                                    appAlphabatesOnly>
                            </mat-form-field>
                            <span class="invalid-feedback mx-3 mb-0"
                                *ngIf="(ipForm.get('name')?.touched  || ipForm.get('name')?.dirty) && ipForm.get('name')?.invalid">
                                IP Name is required *.
                            </span>
                        </div>
                        <div class="btn-c">
                            <button type="button" class="btn text-white rounded-pill signup-btn w-100"
                                (click)="createIPForm()" [disabled]="ipForm?.invalid">{{addData?.rowData ? 'Update' :
                                'Create'}}</button>
                        </div>
                    </form>
                </div>
                <div class="p-1" *ngIf="addData?.data?.key == 'factoryMaster'">
                    <form [formGroup]="factoryForm" *ngIf="factoryForm">
                        <!-- Country Select -->
                        <div class="mt-1">
                        <mat-form-field appearance="outline" class="form-floating  mb-3 w-100">
                            <mat-label>Country Name *</mat-label>
                            <mat-select formControlName="country_id" placeholder="Select Country"
                                (selectionChange)="countryChanges($event)">
                                <mat-option *ngFor="let item of countryList" [value]="item?.id">
                                    {{item?.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-error
                        *ngIf="(factoryForm.get('country_id')?.touched || factoryForm.get('country_id')?.dirty) && factoryForm.get('country_id')?.invalid">
                        Country Name is required *.
                    </mat-error>
                </div>
    
                        <!-- IP Select -->
                        <div class="mt-1">
                        <mat-form-field appearance="outline" class="form-floating w-100 mb-3">
                            <mat-label>IP Name *</mat-label>
                            <mat-select formControlName="industrialpark_id" placeholder="Select IP">
                                <mat-option *ngFor="let ipItem of ipList" [value]="ipItem?.id">
                                    {{ipItem?.name}}
                                </mat-option>
                            </mat-select>
                         
                        </mat-form-field>
                        <mat-error
                        *ngIf="(factoryForm.get('industrialpark_id')?.touched || factoryForm.get('industrialpark_id')?.dirty) && factoryForm.get('industrialpark_id')?.invalid">
                        IP Name is required *.
                    </mat-error>
                    </div>
    
                        <!-- Text Input -->
                        <div class="mt-1">
                        <mat-form-field appearance="outline" class="form-floating w-100 mb-3">
                            <input matInput type="text" formControlName="name" placeholder="Enter Factory Name"
                                appAlphabatesOnly>
                            <mat-label>Factory Name *</mat-label>
                           
                        </mat-form-field>
                        <mat-error
                        *ngIf="(factoryForm.get('name')?.touched || factoryForm.get('name')?.dirty) && factoryForm.get('name')?.invalid">
                        Factory Name is required *.
                    </mat-error>
                    </div>
                        <div class="btn-c">
                            <button type="button" class="btn text-white rounded-pill signup-btn w-100"
                                (click)="createFactoryForm()" [disabled]="factoryForm?.invalid">{{addData?.rowData ?
                                'Update' : 'Create'}}</button>
                        </div>
                    </form>
                </div>

                <div class="p-1" *ngIf="addData?.type == 'yearChange'">
                    <!-- Selected year <i>({{addData.data}})</i> can not be used ! -->
                    <div class="form-floating mb-3">{{addData?.message}}</div>
                    <div class="">
                        <button type="button" class="btn text-white rounded-pill signup-btn w-100" (click)="closeDialog()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>