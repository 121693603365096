import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserManagementServiceService } from '../user-management/user-management-service.service';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  userDetail : boolean = true;
  userdata:any = sessionStorage.getItem('userdata')
  userAllDetails: any = [];
  public userFormDetails !: FormGroup ;
  permissionModule = JSON.parse(sessionStorage.getItem('userPermission')!);
  userData = JSON.parse(sessionStorage.getItem('userdata')!);
  permissionAllowedModule: any = [];
  currentIndex: number = 0;
  getInitialModulePermission: any = [];
  files:any = [];
  imageAllData: any = [];
  putAPIurl: any;
  payloadURL: any = '';
  isEditMode: boolean = false;
  imgURL: any = '';
  showImage: any;

  countryCodeArray = [
    {
      name: 'Ethiopia',
      id: '5',
      code: '251'
    },
    {
      name: 'Vietnam',
      id: '6',
      code: '84'
    },
    {
      name: 'Kenya',
      id: '4',
      code: '254'
    },
  ];
  userAllData: any = [];
  constructor(
    private userService:UserManagementServiceService,
    private formBuilder: FormBuilder,
    private snackBar:MatSnackBar,
    private commonService: CommonServiceService
  ) {
    
  }

  ngOnInit(): void {
    this.createUserForm()
    this.userdata = JSON.parse(this.userdata)
    this.getUserInfo();
    this.getPermissionModule()
  }

  viewUserDetail(){
    this.userDetail=!this.userDetail;
   if(!this.userDetail){
    this.prefieldData()
   }
  }
  createUserForm():void {
    this.userFormDetails = this.formBuilder.group({
      userName: ["",Validators.required],
        email: ['', [Validators.required, Validators.email]],
        mobile: [""],
        // password: ["",Validators.required],
        userRole: [''],
        countryName: [''],
        country_code: [""]
    });
  }

  updateUser(){
    console.log(this.userFormDetails,"this.userForm");
    if(this.userFormDetails?.invalid){
      this.openSnackBar(['All field are required !!'],'','warning')
    }
    let payload = {
      name : this.userFormDetails?.controls['userName'].value,
      email : this.userFormDetails?.controls['email'].value,
      // password : this.userFormDetails?.controls['password'].value,
      mobile : this.userFormDetails?.controls['mobile'].value ? this.userFormDetails?.controls['mobile'].value : null,
      photo : this.payloadURL,
      country_code:this.userFormDetails?.controls['country_code'].value ? this.userFormDetails?.controls['country_code'].value : null
    };
    

    console.log("payload",payload)
      this.userService.updateUser(this.userdata.id,payload).subscribe((res:any) => {
        if(res.success){
          this.userDetail = true;
          this.getUserInfo(true);
          this.getTargetterUserData(payload.email, res?.message);
        }
      },(error:any)=>{
        this.showImage = '';
        this.openSnackBar([error?.message || 'Failed !'] , '');
      });
  }

  getTargetterUserData(userEmail:any, responseMsg:any){
    let endpoint = `/admin/submission?data.email=${this.userAllDetails.email}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {},
      "method": "GET"
    };
    this.commonService.targettedUserData(payload).subscribe((res:any) => {
      if(res.length){
        this.userAllData = res;
        this.updateUserData(this.userAllData, userEmail, responseMsg);
      }
    },(error) => console.log(error));
  }

  updateUserData(userData:any, userEamil:any, responseMsg:any){
    let endpoint = `/admin/submission/${userData[0]._id}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {
        "data": {
          "email": userEamil,
    }
    },
      "method": "PUT"
    }
      this.commonService.updateUserForIpsa(payload).subscribe(res => {
        if(res){
          this.openSnackBar([responseMsg || 'Created Successfully '] , '','success');
        }
      })

  }

  get f() {
    return this.userFormDetails.controls;
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getUserInfo(isPrefield:boolean=false){
    this.userService.getProfileDetailsById(this.userdata.id).subscribe((res:any)=>{
      if(res.success){
        this.userAllDetails = res.data[0];
        this.showImage = this.userAllDetails.photo;
        this.userService.isProfile.next(true)
        if(isPrefield){
          this.prefieldData()
        }
      }
    },(error:any)=>{
      console.log(error)
    })
  }
  getPermissionModule(){
    this.permissionAllowedModule = this.permissionModule[0].authenicationJson.filter((el:any)=> {
      if(el?.isAction  || el?.authentication.some((e:any)=>e?.isAction)) return el;
    })
    this.getInitialModulePermission = this.permissionAllowedModule[this.currentIndex];
    console.log("permission",this.permissionAllowedModule)
    console.log("permission",this.getInitialModulePermission)
  }
  changeTabs(event:any,index:number){
    this.currentIndex = index;
    this.getInitialModulePermission = this.permissionAllowedModule[this.currentIndex];
  }

  // file upload
  imageInputClickHandler(event:any) {
    document?.getElementById("form-icon-input")?.click();
}

  onFileDropped(event:any) {
    
    this.files = [];
    this.prepareFilesList(event);
    

  }
  async imageInputChangeHandler(event:any) {
    this.fileBrowseHandler(event)
  }
   fileBrowseHandler(files: any) {
    let maximumFileSize = 5 * 1024 * 1024; // 5MB in bytes
    let currentFileSize = files?.target?.files[0]?.size;

    if(currentFileSize > maximumFileSize){
      files.target.value = null;
      this.openSnackBar(['Please upload file under 5 MB !' || 'Failed !'] , '');
      return ;
    }
    console.log("files",files.target.files[0].type)
    let isFileExtensionValid: boolean = this.imageFileExtension(files.target.files[0].type);
    if (!isFileExtensionValid) {
        this.openSnackBar(['Please enter image icon in png,jpg,jpeg']);
        return;
    }
    let payload = [{
        "file_name": files.target.files[0].name,
        "mime_type": files.target.files[0].type
      }];
      this.commonService.uploadImage(payload).subscribe((res:any) => {
        if(res.success){
          console.log("POSTresponse",res);
          this.imageAllData = res.data;
          this.imageAllData.forEach((ele:any) => {
            this.putAPIurl = ele?.url;
            this.payloadURL = ele?.file_url;
          });
          this.commonService.imagePUTapi(this.putAPIurl, files.target.files[0]).subscribe((res:any) => {
            console.log("putresponse",res);
            this.showImage = this.payloadURL;
            
          })
        }
      })

    
    this.files = [];
    this.prepareFilesList(files.target.files);
    // console.log('files', files)
  }
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      console.log("filesss",files);
      
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.payloadURL = '';
    this.imgURL = '';
    if (this.files.length == 0) {
      // this.libraryForm.reset();
    }
  }
  openDocument(){
    window.open(this.imgURL);
  }
  imageFileExtension(type: string): boolean {
    if (type == "image/png" || type == "image/jpeg" || type == "image/jpg") {
        return true;
    }
    return false;
}
prefieldData(){
  this.payloadURL = this.userAllDetails.photo;
  this.showImage = this.userAllDetails.photo
  console.log("this.userAllDetails",this.userAllDetails)
    this.userFormDetails.patchValue({
      userName:this.userAllDetails.name ? this.userAllDetails.name : '',
      email:this.userAllDetails.email ? this.userAllDetails.email : '',
      // password:this.userAllDetails.password ? this.userAllDetails.password : '',
      mobile:this.userAllDetails.mobile ? this.userAllDetails.mobile : '',
      userRole:this.userAllDetails['role.name'] ? this.userAllDetails['role.name'] : '',
      countryName:this.userAllDetails['country.name'] ? this.userAllDetails['country.name'] : '',
      country_code:this.userAllDetails['country_code'] ? this.userAllDetails['country_code'] : '',

    })
}  
openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    panelClass: [className]
  });
}
}
