import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchTerm: any, searchBy: string) {
    if (!searchTerm) {
      return items;
    }
    // console.log("itemfilter", items, searchTerm, searchBy)
    // when there is partial or full match of the search term
    return items.filter(item => {
      const currentItem = item[searchBy];
      return currentItem.toString().toLowerCase().includes(searchTerm.trim().toLowerCase());
    });
  }

}
