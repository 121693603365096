<img src="../../../assets/images/login-closee.svg" class="close-btn position-absolute" (click)="close()" alt="">
<div class="position-relative card border-0 signup_form">
  <div class="position-relative" *ngIf="!isSignUpSuccess">
      <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 15px;">
        <!-- <span>
          <img src="../../../assets/images/idh-userIcon.png" class="img-fluid me-3" style="max-width: 50px;" alt="">
        </span> -->
        <h2 class="text-center title fw-bold m-0">New Member</h2>
      </div>
      <p class="sub-title fs-14 text-center">Please fill up the form to sign up</p>
      
    </div>
    
    <form [formGroup]="singupForm" *ngIf="!isSignUpSuccess" class="form">
      <!-- <button id="yourButton" >click</button> -->
      <div class="row">
        <div class="col-md-6 my-2">
          <!-- <div class="form-floating mb-3">
            <input type="text" class="form-control rounded-pill" placeholder="Name" formControlName="userName" 
          appSpecialCharactersValidation >
        <label>Name *</label>
            <span class="invalid-feedback mx-3 mb-0"
          *ngIf="(singupForm.get('userName')?.touched  || singupForm.get('userName')?.dirty) && singupForm.get('userName')?.invalid">
          Name is Required *.
        </span>
          </div> -->
          <div class="position-relative">
            <mat-form-field appearance="outline" class="example-full-width w-100">
              <mat-label>Name *</mat-label>
              <input type="text" appAlphabatesOnly placeholder="Name" matInput formControlName="userName" [ngModelOptions]="{standalone: true}" appSpecialCharactersValidation>
              <mat-error class="invalid-feedback mx-3 mb-0"
                *ngIf="(singupForm.get('userName')?.touched  || singupForm.get('userName')?.dirty) && singupForm.get('userName')?.invalid">
                Name is Required *.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-6 my-2">
    
          <!-- <div class="form-floating mb-3">
            <input type="email" class="form-control rounded-pill" placeholder="example@gmail.com" formControlName="email">
        <label>Email ID *</label>
            <span class="invalid-feedback mx-3 mb-0"
          *ngIf="(singupForm.get('email')?.touched  || singupForm.get('email')?.dirty) && singupForm.get('email')?.invalid">
          Email is Required *.
        </span>
          </div> -->
          <div class="position-relative">
            <mat-form-field appearance="outline" class="example-full-width w-100">
              <mat-label>Email *</mat-label>
              <input type="email" placeholder="example@gmail.com" matInput formControlName="email" [ngModelOptions]="{standalone: true}">
              <!-- <mat-error class="invalid-feedback  mt-0" *ngIf="(singupForm.get('email')?.touched  || singupForm.get('email')?.dirty) && singupForm.get('email')?.invalid">
                Email is Required *.
              </mat-error> -->
              <mat-error class="invalid-feedback mx-3 mb-0" *ngIf="singupForm.get('email')?.touched && singupForm.get('email')?.invalid">
                <ng-container *ngIf="singupForm.get('email')?.errors?.required">
                  Email is required.
                </ng-container>
                <ng-container *ngIf="singupForm.get('email')?.errors?.email && !singupForm.get('email')?.errors?.required">
                  Please enter a valid email.
                </ng-container>
              </mat-error>
              
             
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12 my-2">
          <div class="row">
            <div class="col-md-4">
              <!-- <div class="form-floating">
                <select class="form-select rounded-pill" id="floatingSelectGridCode" formControlName="country_code" >
                  <option selected disabled>Select Country Code</option>
                  <option [value]="item?.code" *ngFor="let item of countryCodeArray">
                   <span class="w-50">+{{item?.code}}</span>
                   <span class="w-50">&nbsp; &nbsp; &nbsp; &nbsp; | &nbsp; {{item?.name}}</span> 
                  </option>
                </select>
                <span class="invalid-feedback mx-3 mb-0"
                *ngIf="(singupForm.get('country_code')?.touched  || singupForm.get('country_code')?.dirty) && singupForm.get('country_code')?.invalid">
                Country Code is Required *.
              </span>
              </div> -->
              <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>Select Country Code</mat-label>
                  <mat-select formControlName="country_code" >
                    <mat-option [value]="item?.code" *ngFor="let item of countryCodeArray">
                      <span class="w-50">+{{item?.code}}</span>
                      <span class="w-50">&nbsp; &nbsp; &nbsp; &nbsp; | &nbsp; {{item?.name}}</span>
                    </mat-option>
                  </mat-select>
                  <!-- <mat-error class="invalid-feedback  mt-0"
                *ngIf="(singupForm.get('country_code')?.touched  || singupForm.get('country_code')?.dirty) && singupForm.get('country_code')?.invalid">
                Country Code is Required *.
              </mat-error> -->
                </mat-form-field>
              </div>
  
            </div>
            <div class="col-md-8">
              <!-- <div class="form-floating mb-3">
                <input type="number" class="form-control rounded-pill" placeholder="Mobile Number"
              formControlName="mobileNumber" onKeyPress="if(this.value.length == 12 && event.keyCode!=8) return false;"
              appSpecialCharactersValidation>
            <label>Mobile Number *</label>
            <span class="invalid-feedback mx-3 mb-0"
              *ngIf="((singupForm.get('mobileNumber')?.touched  || singupForm.get('mobileNumber')?.dirty) && singupForm.get('mobileNumber')?.invalid) && !((singupForm.get('mobileNumber') && singupForm.get('mobileNumber')?.hasError('invalidMobileNumber')) && !(singupForm.get('mobileNumber')?.hasError('pattern')))">
              Mobile Number is Required *.
            </span>
              </div> -->

              <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>Mobile Number</mat-label>
                  <input type="tel" placeholder="Mobile number" maxlength="12" matInput formControlName="mobileNumber" [ngModelOptions]="{standalone: true}"
                  (keypress)="numberOnly($event)" >
                  <!-- <mat-error class="invalid-feedback mt-0" *ngIf="((singupForm.get('mobileNumber')?.touched  || singupForm.get('mobileNumber')?.dirty) && singupForm.get('mobileNumber')?.invalid) && !((singupForm.get('mobileNumber') && singupForm.get('mobileNumber')?.hasError('invalidMobileNumber')) && !(singupForm.get('mobileNumber')?.hasError('pattern')))">
                    Mobile Number is Required *.
                  </mat-error> -->
                </mat-form-field>
              </div>
            </div>
            
          </div>
         
        </div>
        <!-- <div class="col-md-6 my-2"> -->
          <!-- <div class="form-floating mb-3">
            <select class="form-select rounded-pill" placeholder="Select Profile" aria-label="Floating label select example"
              formControlName="userInfo">
              <option *ngFor="let ipName of ipNames" [value]="ipName.value">
                {{ipName?.label}}
              </option>
            </select>
            <label>I am a</label>
            <span class="invalid-feedback mx-3 mb-0"
          *ngIf="(singupForm.get('userInfo')?.touched  || singupForm.get('userInfo')?.dirty) && singupForm.get('userInfo')?.invalid">
          User Type is Required *.
        </span>
      </div> -->
      <!-- <div class="position-relative">
        <mat-form-field appearance="outline" class="example-full-width w-100">
          <mat-label>I am a *</mat-label>
          <mat-select formControlName="userInfo" placeholder="Select Profile">
            <mat-option *ngFor="let ipName of ipNames" [value]="ipName.value">
              {{ipName?.label}}
            </mat-option>
          </mat-select>
          <mat-error class="invalid-feedback mx-3 mb-0"
          *ngIf="(singupForm.get('userInfo')?.touched  || singupForm.get('userInfo')?.dirty) && singupForm.get('userInfo')?.invalid">
          User Type is Required *.
      </mat-error>
        </mat-form-field>
      </div>
        </div> -->
        <div class="col-md-6 my-2">
          <!-- <div class="form-floating mb-3">
            <div class="eye-input position-absolute">
              <button class="eye-icon d-flex align-items-center" type="button" (click)="showPassword('pass')">
                <img src="../../../assets/images/eye-password.svg"
              src="{{show ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}" class="img-fluid" alt="">
              </button>
            </div>
            <input class="form-control rounded-pill" [type]="!this.show ? 'password' : 'text'" placeholder="xxxxx"
          formControlName="password" autocomplete="off">
            <label>Password *</label>
        <span class="invalid-feedback mx-3 mb-0" *ngIf="(singupForm.get('password')?.touched  || singupForm.get('password')?.dirty) && (singupForm.get('password')?.hasError('required') || singupForm.get('password')?.hasError('minlength') || singupForm.get('password')?.hasError('uppercase') || singupForm.get('password')?.hasError('lowercase') || singupForm.get('password')?.hasError('number') || singupForm.get('password')?.hasError('specialCharacter'))">
          Password must have at least one number, one special character, one uppercase letter, one lowercase letter, and be at least 8 characters long.
        </span>

          </div> -->

          <div class="position-relative">
            <div class="eye-input position-absolute">
                <button class="eye-icon d-flex align-items-center pointerHead" type="button" (click)="showPassword('pass')">
                    <img src="../../../assets/images/eye-password.svg"
                        src="{{show ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}"
                        class="img-fluid" alt="">
                </button>
            </div>
            
            <mat-form-field appearance="outline" class="example-full-width w-100">
                <mat-label>Password *</mat-label>
                <input  [type]="!this.show ? 'password' : 'text'" placeholder="Password" matInput formControlName="password" autocomplete="off" [ngModelOptions]="{standalone: true}" >
                <mat-error class="invalid-feedback mx-3 mb-0" *ngIf="(singupForm.get('password')?.touched  || singupForm.get('password')?.dirty) && (singupForm.get('password')?.hasError('required') || singupForm.get('password')?.hasError('minlength') || singupForm.get('password')?.hasError('uppercase') || singupForm.get('password')?.hasError('lowercase') || singupForm.get('password')?.hasError('number') || singupForm.get('password')?.hasError('specialCharacter') || singupForm.get('password')?.hasError('space'))">
                  Password must have at least one number, one special character, one uppercase letter, one lowercase letter, and be at least 8 characters long except white space.
                </mat-error>
            </mat-form-field>
        </div>
        </div>
        <div class="col-md-6 my-2">
          <!-- <div class="form-floating mb-3">
            <div class="eye-input position-absolute">
              <button class="eye-icon d-flex align-items-center" type="button" (click)="showPassword('cpass')">
                <img src="../../../assets/images/eye-password.svg"
              src="{{show2 ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}" class="img-fluid" alt="">
              </button>
            </div>
            <input class="form-control rounded-pill" [type]="!this.show2 ?'password' : 'text'"
          placeholder="Confirm Password" autocomplete="new-password" formControlName="confirmPassword">
            <label>Confirm Password *</label>
        <span class="invalid-feedback mx-3 mb-0"
          *ngIf="(singupForm.hasError('passwordMismatch') && singupForm.get('confirmPassword')?.dirty)">
          Passwords do not match.
        </span>
          </div> -->
          <div class="position-relative">
            <div class="eye-input position-absolute">
                <button class="eye-icon d-flex align-items-center pointerHead" type="button" (click)="showPassword('cpass')">
                    <img src="../../../assets/images/eye-password.svg"
                        src="{{show2 ? '/assets/images/eye-password.svg' : '/assets/images/hide.svg'}}"
                        class="img-fluid" alt="">
                </button>
            </div>
            
            <mat-form-field appearance="outline" class="example-full-width w-100">
                <mat-label>Confirm Password *</mat-label>
                <input  [type]="!this.show2 ?'password' : 'text'" placeholder="Confirm password" matInput formControlName="confirmPassword" autocomplete="new-password" [ngModelOptions]="{standalone: true}" >
            </mat-form-field>
            <span class="invalid-feedback mx-3" *ngIf="(singupForm.hasError('passwordMismatch') && singupForm.get('confirmPassword')?.dirty)">
              Password mismatch.
              </span>
        </div>
        </div>
      </div>
    
    
      <button type="button" class="btn text-white rounded-pill signup-btn w-100 mt-4 mb-2"  (click)="sinUpUser()"
    [disabled]="singupForm.invalid">Signup</button>
      <div class="fs-14 color-44 row">
        <div class="col-sm-7">
          <span class="extUser">Existing User? 
          <a class="fw-500 pointer" style="color: #00625F;" (click)="openDialog($event)">Login</a></span>
        </div>
        <div class="col-sm-5">
          <span class="fs-15 pointer privacyState" (click)="privacyStatement()"><u>Privacy Statement</u></span>
        </div>

        <!-- <div class="fs-15">
        </div> -->
      </div>
    </form>

    <div *ngIf="isSignUpSuccess">
      <div>
        <div class="col-12 text-center">
          <h2 class="text-center title fw-bold">OTP Verification</h2>
          <p class="fs-14 text-center g-clr">Your OTP has been sent successfully!</p>
        </div>
        <div class="col-12 text-center">
          <p class="fs-14 fs-14 text-center">Please enter the 6-digit OTP, we have sent on your email id <span *ngIf="singupForm" class="em-cls">{{singupForm?.controls?.email?.value}}</span></p>
                    
          <div class="verification-code">
              <!-- Six input fields for OTP digits -->
              <form [formGroup]="otpForm">
              <input type="text" #otp1 class="otp-input"  maxlength="1"  (input)="focusNext($event, otp1, otp2)" (keydown)="handleBackspace($event, otp1, otp2,'opt1')" formControlName="otp1">
              <input type="text" #otp2 class="otp-input"  maxlength="1"  (input)="focusNext($event, otp2, otp3)" (keydown)="handleBackspace($event, otp2, otp1,'')" formControlName="otp2">
              <input type="text" #otp3 class="otp-input"  maxlength="1"  (input)="focusNext($event, otp3, otp4)" (keydown)="handleBackspace($event, otp3, otp2,'')" formControlName="otp3">
              <input type="text" #otp4 class="otp-input"  maxlength="1" (input)="focusNext($event, otp4, otp5)" (keydown)="handleBackspace($event, otp4, otp3,'')" formControlName="otp4">
              <input type="text" #otp5 class="otp-input"  maxlength="1" (input)="focusNext($event, otp5, otp6)" (keydown)="handleBackspace($event, otp5, otp4,'')" formControlName="otp5">
              <input type="text" #otp6 class="otp-input"  maxlength="1"  (keydown)="handleBackspace($event, otp6, otp5,'')" formControlName="otp6">

          </form>

          <div class="text-danger mt-3 ps-2" *ngIf="errorMessage">
              {{errorMessage}}
          </div>
          </div>
        </div>

        <div class="col-12 mt-4">
          <button class="btn text-white rounded-pill signup-btn w-100" (click)="verifyOtp()" [disabled]="isDisabledVerify">Verify</button>

          <div class="fs-14 fw-400 text-center" style="color: #4F4C4D;">OTP will be valid for 10 minutes.</div>
          <div class="fs-14 fw-400 color-secondary text-center ">
             <button *ngIf="countdown < 1" class="btn-resend"(click)="resendOtp()" [disabled]="isDisabledResendOtp">Resend OTP</button> 
             <div *ngIf="countdown > 0" style="color:#4F4C4D">Resend OTP in {{ countdown }} seconds</div>
          </div> 
        </div>
      </div>
      <!-- <h2 class="text-center fw-bold fs-24" style="margin-bottom:32px;">Sign Up Request Received</h2>
      <div class="text-center">
        <img src="../../../assets/images/Signup-request.png" class="img-fluid text-center mb-4">
      </div>
      <div class="text-center">
        <button class="btn return-btn text-white rounded-pill" (click)="backtoHome()">Return to Homepage</button>
      </div> -->
    </div>
</div>



<!-- Sign Up Request Received -->


 


