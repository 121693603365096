<!-- A button that closes the dialog. this button will be used in the case of approval (only for reject) -->
<div class="row justify-content-end" *ngIf="data?.showCancelBtn">
    <button class="close-button" mat-icon-button tabindex="-1" (click)="closeDirectly('yes')">
        <mat-icon class="close-icon">close</mat-icon>
    </button>    
</div>
<mat-dialog-content [ngClass]="{'top-margin': data?.showCancelBtn}">
    <h5 align="center" class="customColorText" style="font-weight: 600; font-size: 15px;">
        <mat-icon role="img" class="mat-icon notranslate material-icons warn-icon" aria-hidden="true" *ngIf="!data?.showTextBox">info</mat-icon>
        {{message}}
    </h5>
    <em align="center" class="customColorText" *ngIf="data?.alternateMessage">
        {{data?.alternateMessage}}
    </em>
</mat-dialog-content>
<textarea *ngIf="data?.showTextBox" class="w-full p-2 shadow-none" style="width: 100%; outline: none;" placeholder="Write your comments" (input)="onChangeTextBox($event)"></textarea>
<mat-dialog-actions align="center" class="mt-3">
    <button *ngIf="!data?.showCancelBtn"class="text-uppercase mr-2" mat-raised-button mat-dialog-close>{{cancelButtonText}}</button>
    <button (click)="onConfirmClick()" class="text-uppercase" mat-raised-button color="primary" tabindex="1">{{confirmButtonText}}</button>
    <!-- A button that closes the dialog. this button will be used in the case of approval (only for reject) -->
    <button *ngIf="data?.showCancelBtn" mat-raised-button tabindex="-1" class="custom-secondary-btn-color" (click)="closeDirectly('no')">{{cancelButtonText}}</button>
</mat-dialog-actions>