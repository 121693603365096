import { Component, OnInit, SecurityContext, ViewEncapsulation } from '@angular/core';
import { CommonServiceService } from '../services/common-service.service';
import { MatDialog } from '@angular/material/dialog';
import { UserWelcomeComponent } from 'src/app/layout/footer/user-welcome/user-welcome.component';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GDPRComponent } from '../gdpr/gdpr.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  [x: string]: any;
  homeData: any = [];
  impactData: any = [];
  employeeData: any = [];
  partnerData: any = [];
  fullname:any = "";
  emaiID:any = "";
  message:any = "";
  initialIndex:number = 0;
  imageCount:number = 4;
  endIndex:number = this.imageCount;
  imgData: any = [];
  userEmail: any = "";
  imageClick : boolean = false;
  submitStatus: boolean = true;
  ourImpactData: any = [];
  url: any;
  indicatorCountData:any = [
    { name:'Industrial Parks Supported',key:'industrial_parks_in_projects',id:'11'},
    { name:'Factories Supported',key:'ta_factories_in_ips',id:'12'},
    { name:'Workers Impacted',key:'male_workers_in_instep',id:'13'},
]
isFullname:boolean = false;
isEmail:boolean = false;
isMessage:boolean = false;
isValidEmail:boolean = false;
public contactUsForm!:FormGroup;
gdprChecked:boolean = false;




  constructor(private commonService: CommonServiceService,  private formBuilder: FormBuilder , 
    public dialog: MatDialog, private snackBar: MatSnackBar, private router: Router,
    private sanitizer: DomSanitizer) {
    this.contactUsFormControls();
  }
  public isLoggedin:any = new BehaviorSubject('false')

  
  contactUsFormControls(){
    this.contactUsForm = this.formBuilder.group({
      fullname:['', [Validators.required]],
      emaiID:['', [Validators.required, Validators.email]],
      message:  ['', [ Validators.required]]
    });
  }

  alphabetsOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return /^[a-zA-Z\s]+$/.test(String.fromCharCode(charCode));
  }

  restrictSpecialChar(event:any): boolean{
    const charCode = (event.which) ? event.which : event.keyCode;
    return /^[^*|\":<>[\]{}`\\()';@&$]+$/.test(String.fromCharCode(charCode));
  }

  ngOnInit(): void {
    // this.splitData();
    let userToken = sessionStorage.getItem('token');
    userToken ? this.isLoggedin.next(true): this.isLoggedin.next(false);
    this.getHomePageData();
    this.outImpactSectionData();
  }

  toDashboard(){
    this.router.navigate(['/dashboard']);
  }

  outImpactSectionData(){
    this.commonService.resourceCenterData().subscribe(res => {
      if(res.success){
        console.log("ressss",res);
        this.ourImpactData = res.data.filter((ele:any) => ele.types == 'impact stories');
        this.setKeyForIndicatorsCount()
        this.getSummaryCardsData()
        console.log("imimimi",this.ourImpactData);
        
      }
    },(error) => console.error(error));
  }

  getHomePageData(){
    this.commonService.homePageData().subscribe(response => {
      if(response.success){
        this.homeData = response?.data;
        this.employeeData = this.homeData.find((ele:any) => ele?.model == 'Teammember');
        this.employeeData = this.employeeData.data;
        this.imgData = this.employeeData.slice(this.initialIndex, this.endIndex);
        console.log("imgg",this.imgData);

        let videoLink = this.homeData.filter((ele:any) => ele?.order == 2);
        
        if(!videoLink[0].link.split('/').includes('live')){
          let myId:any = this.getId(videoLink[0].link);
          if(myId !== 'error'){
            videoLink[0].link =  'https://www.youtube.com/embed/'+myId ;
          console.log("err",myId,videoLink[0].link)
          }
          
        }
        else if(videoLink[0].link.split('/').includes('live')){
          if(!videoLink[0].link.split('/').includes('embed')){
            videoLink[0].link = videoLink[0].link.replace('/live/', '/embed/')
          }
          else{
            videoLink[0].link = videoLink[0].link.replace('/live/', '/')
          }
        }
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(videoLink[0].link + '?rel=0');


        let partnerData = this.homeData.find((ele:any) => ele?.model == 'Partners');
        let partnerImage = partnerData ? partnerData.data : [];
        this.partnerData = [...partnerImage];
        if(partnerImage.length && partnerImage.length < 6){
          this.partnerData = [...partnerImage, ...partnerImage]
        }

        console.log("homedata",this.homeData, this.partnerData);
        // this code part is for multiple image
        let multiImageObj = this.homeData.find((ele:any) => ele?.order == 4);
        let finalData =  multiImageObj.banner.split(',');
        this.homeData.map((data:any) => {
          if(data.order == 4){
            data['imgArray'] = finalData;
          }
        });
        console.log("homedata",this.homeData);
      }
    },(error) => console.error(error))
    this.getimpactStoriesData();
  }

  getId(url:string) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    // const regExp = /^(?:.*(?:youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)|.*(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)))?([^#\&\?]*).*/
  
    var match = url.match(regExp);
  
    if (match && match[2].length == 11) {
        return match[2];
    } 
    else {
        return 'error';
    }
  }

  getimpactStoriesData(){
    this.commonService.impactStoriesData().subscribe(response => {
      console.log(response);
      if(response.success){
        this.impactData = response?.data;
      }
    },(error) => console.error(error))
    // this.getemployeeData();
  }

  getemployeeData(){
    this.commonService.employeeData().subscribe(response => {
      if(response.success){
        this.employeeData = response?.data;
        this.imgData = this.employeeData.slice(this.initialIndex, this.endIndex);
        console.log("imgg",this.imgData);
        
      }
    },(error) => console.error(error))
  }

  gdprConfirmation(){
    // this.gdprChecked = JSON.parse(sessionStorage.getItem("gdprConfirmation")!);
    // if(!this.gdprChecked){
    //   let dialogRef = this.dialog.open(GDPRComponent, {
    //     width: '700px',
    //     panelClass: 'gdpr-portalDialog',
    //     data: {}
    //   });
    //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //     if(confirmed)
    //     this.postContactUsInfo();
    //   })
    // }else{
      this.postContactUsInfo();
    // }
  }

  privacyStatement(){
    let dialogRef = this.dialog.open(GDPRComponent, {
      width: '700px',
      panelClass: 'gdpr-portalDialog',
      data: {}
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    })
  }

  postContactUsInfo(){
    console.log(this.contactUsForm);
    
    // let customMessage:any= {fullname: 'Full Name', emaiID: 'Email Id', message: 'Message'}
    
  //  let snackMessage:string =  this.createCustomMessage(customMessage);
    // console.log("snackMessage",snackMessage)
    // if(!this.fullname){
    //   this.isFullname = true
    // }
    // if(!this.emaiID){
    //   this.isEmail = true
    // }
    // if(!this.message){
    //   this.isMessage = true
    // }
    // if(snackMessage){
    //   // this.openSnackBar('Please fill '+snackMessage + ' fields.','',true);
    //   this.openSnackBar('Please fill all required fields.','',true);
    //   return
    // }
   
   
    // let hasNumber = /\d/;
    // if(hasNumber.test(this.fullname)){
    //   this.isFullname = true;
    //   this.openSnackBar('Name can not include number !','',true);
    //   return
    // }
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(this.emaiID)){
    //   // this.isEmail = true;
    //   this.isValidEmail = true;
    //   this.openSnackBar('Please add valid email!','',true);
    //   return
    // }
    // let regex = new RegExp(/[`@#$%^&*~?":{}|<>]/);
    // if(regex.test(this.message)){
    //   this.isMessage = true;
    //   this.openSnackBar('No special characters allowed in message field');
    //   return
    // }
    let data = {
      "fullname": this.contactUsForm.controls['fullname'].value,
      "email": this.contactUsForm.controls['emaiID'].value,
      "message": this.contactUsForm.controls['message'].value
  };  
    this.commonService.contactUsInfo(data).subscribe(res => {
      if(res.success){
        // this.dialog.open(UserWelcomeComponent, {
        //   width: '500px',
        //   position: {top: '150px'},
        //   data: {
        //     flag: 'contactUs'
        //   }
        // });
        this.openSnackBar(['Thankyou for Contacting Us !!'],'',false);
        this.contactUsForm.reset({ emitEvent: false });
        Object.keys(this.contactUsForm.controls).forEach(controlName => {
          const control = this.contactUsForm.get(controlName)!;
          control.setErrors(null);
          control.markAsPristine();
          control.markAsUntouched();
        });
        // this.fullname = '';
        // this.emaiID = '';
        // this.message = '';
      }
    }, (error) => {
      console.log("error",error)
      // this.openSnackBar(error?.error?.data?.errors[0]?.message,'',true);
      this.openSnackBar(error?.error?.message ? error?.error?.message :'failed !','',true);
    })
  }

  openSnackBar(message:any, action = '',isError:boolean=false) {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: isError ? ['primary'] : ''
  });
}
  
  nextImages(buttonClicked:any){
    // document.getElementById("trans")!.style.transition = "all 5s";
    console.log(buttonClicked, this.imgData , this.initialIndex, this.endIndex);
    if(buttonClicked == 'forward'){
      if(this.initialIndex + this.imageCount < this.employeeData.length){
        this.initialIndex = this.initialIndex + this.imageCount;
        this.endIndex = this.endIndex + this.imageCount;
      }
      else return;
    }
    else{
      if(this.initialIndex - this.imageCount >= 0){
        this.initialIndex = this.initialIndex - this.imageCount;
        this.endIndex = this.endIndex - this.imageCount;
      }
      else return;
    }
    this.imgData = this.employeeData.slice(this.initialIndex, this.endIndex);
    console.log(buttonClicked, this.imgData , this.initialIndex, this.endIndex);
  }
  subscribeUser(){
    let data = {
      email: this.userEmail
    };
    this.commonService.subscribeUser(data).subscribe(res => {
      if (res.success) {
        this.userEmail = "";
        
      }
    })
  }

  // imageObject = [{
  //     image: '../../../assets/images/member-1.png',
  //     thumbImage: '../../../assets/images/member-1.png',
  // },
  //  {
  //   image: '../../../assets/images/member-1.png',
  //   thumbImage: '../../../assets/images/member-1.png',
  // },
  //  {
  //   image: '../../../assets/images/member-1.png',
  //   thumbImage: '../../../assets/images/member-1.png',
  // },
  // {
  //   image: '../../../assets/images/member-1.png',
  //   thumbImage: '../../../assets/images/member-1.png',
  // },
  //  {
  //   image: '../../../assets/images/member-1.png',
  //   thumbImage: '../../../assets/images/member-1.png',
  // },
  //  {
  //   image: '../../../assets/images/member-1.png',
  //   thumbImage: '../../../assets/images/member-1.png',
  // }];

 

  imageClickHandler(e : any) {
    console.log('image click', e);
  }
  lbBox(e : any){
    this.imageClick = false;
}

openMail(){
  window.open("mailto:info@idh-instep.com?subject=Hello", '_self');
}
openSignUpForm(){
  this.router.navigate(['home'])
  let dialogRef = this.dialog.open(SignUpComponent, {
    width: '750px',
    panelClass: 'signUp-dialog-class',
    disableClose: true,
    // position: {top: '120px'},
  });
}
getSummaryCardsData(){ 
 this.commonService.getProgramViewImpactSummary().subscribe(response => {
  if(response.success){
    if(response?.data?.length){
      let femaleWorkerCount =  response?.data?.find((el:any)=> el?.short_key === 'female_workers_in_instep');
      response.data = response.data.map((el:any)=>{
        if(el?.short_key === 'male_workers_in_instep'){
          el['male_workers_in_instep'] = parseInt(el[el?.short_key]) + parseInt(femaleWorkerCount['female_workers_in_instep']);
        }
        return el
      })
      this.setIndicatorsCount(response.data)
    }
  }
  console.log("alldata",response);
 }, (error) => {
  console.log("summaryCardError", error);
})
}
 setIndicatorsCount(data:any){
  this.ourImpactData = this.ourImpactData.map((el:any)=>{
    data.forEach((dc:any)=>{
      if(dc.short_key === el?.key){
        // for static value statement
        if(el.key == "industrial_parks_in_projects"){
          el['indicatorsCount'] = 12 ;
        }else if(el.key == "ta_factories_in_ips"){
        // for static value statement
          el['indicatorsCount'] = 118;
        }else{
          // original Statement
          el['indicatorsCount'] = dc[dc?.short_key];
        }
      }
    })
    return el
  })
 }
 setKeyForIndicatorsCount(){
  this.ourImpactData = this.ourImpactData.map((el:any)=>{
    this.indicatorCountData.forEach((e:any)=>{
      console.log(e.id === el.unique_id,el.unique_id)
      if(e.id == el.unique_id){
        el['key'] = e.key;
      }
    })
    return el;
  })
 }

// stripHtmlTags(input: any): string {
//   const doc :any = new DOMParser().parseFromString(input, 'text/html');
//   const images = doc.querySelectorAll('img');

//   const textContent = doc.body.textContent || "";
  

//   // if (images.length > 0) {
//   //   images.forEach((image:any) => {
//   //     image.classList.add('editorClass');
//   //   });
//   //   return doc.body.innerHTML;
//   // }

//   return textContent?.length > 500 ? textContent.substring(0, 500) + '...' : textContent; 
// }

stripHtmlTags(input: any): string {
  const doc: any = new DOMParser().parseFromString(input, 'text/html');
  const images = doc.querySelectorAll('img');

  // Check if there is text content in the body
  const textContent = doc.body.textContent || "";

  // Truncate text content to a limit of 500 characters
  const truncatedText = textContent.length > 500 ? textContent.substring(0, 500) + '...' : textContent;

  // Clone the original body without modifying it
  const clonedBody = doc.body.cloneNode(true);

  // Clear the original body content
  doc.body.innerHTML = '';

  // Append the truncated text content to the modified body
  doc.body.appendChild(document.createTextNode(truncatedText));

  // Append the original images back to the modified body
  images.forEach((image: any) => {
    const clonedImage = image.cloneNode(true);
    clonedImage.classList.add('img-fluid'); // Add your desired class here
    clonedImage.classList.add('mt-4');
    doc.body.appendChild(clonedImage);
  });

  // Create a new container element
  const container = document.createElement('div');

  // Append the modified content to the container
  container.appendChild(doc.body);

  // Get the HTML content of the container
  const result = container.innerHTML;

  return result;
}

stripHtmlTagsForCard(input: any,limit:number): string {
  const doc: any = new DOMParser().parseFromString(input, 'text/html');

  // Extract plain text content without HTML tags
  const textContent = doc.body.textContent || "";

  // Truncate text content to a limit of 150 characters
  let truncatedText :string = ''
  if(limit > 0){
    truncatedText  = textContent.length > limit ? textContent.substring(0, limit) + '...' : textContent;
  }
  else{
    truncatedText = textContent;
  }
 
  return truncatedText;
}

createCustomMessage(customMessage:any){
  let snackMessage:string = '';
  for (let key in customMessage){
     if(!this[key]){
      if(snackMessage)
      snackMessage += ', ';
         snackMessage += customMessage[key];
     }
  }
  return snackMessage;
}
validateMethod(type:string){
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if(type=== 'fullname'){
    this.isFullname = false;
  }
  if(type=== 'emailId' && this.emaiID){
      this.isValidEmail = emailRegex.test(this.emaiID)
    }
  if(type=== 'message'){
    this.isMessage = false;
  }
}
}