import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringPipe'
})
export class StringPipePipe implements PipeTransform {

  transform(value:any, nestedKeys: any[]): any {
    if (value && value?.length && (!nestedKeys || !nestedKeys.length)) {
        if(!value[0].hasOwnProperty('name')) return value.map((res:any) => res);
        return value.map((res: any) => "  " + res.name);
    } else if (value && value?.length && nestedKeys.length) {
        let res: any[] = value;
        for (let key of nestedKeys) {
            res = res.map((item) => (item ? item[key] : item));
        }
        return res;
    }
}

}
