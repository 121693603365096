import { IterableDiffer, IterableDiffers, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse',
  pure: true,
})
export class ReversePipe implements PipeTransform {
  private differ: IterableDiffer<any>;
  private cached: any[] = [];

  constructor(private differs: IterableDiffers) {
    this.differ = this.differs.find([]).create();
  }

  transform(items: any[]) {
    console.log("itemfilter", items)
    // when there is partial or full match of the search term
    return items.reverse();
  }
}
