import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';

@Component({
  selector: 'app-baseline-monthly-form',
  templateUrl: './baseline-monthly-form.component.html',
  styleUrls: ['./baseline-monthly-form.component.scss']
})
export class BaselineMonthlyFormComponent implements OnInit {

  selectedMonth:any = '';
  selectedYear:any = '';
  baseUrl:string ='baselineform/view?formId=';
  viewFormId:number = 3;
  is_monthly:string = 'is_month=true';
  createForm:string = "baselineform/";
  prefillForm:string = "baselineform/list";

  constructor(private commonService: CommonServiceService, public dialog: MatDialog, private snackBar: MatSnackBar, private router: Router
    , private formbuilder: FormBuilder,) {

      // this.getProgramFormQues();
     }

  ngOnInit(): void {
  }

  formQuestions:any = [];

  getProgramFormQues(){
    this.commonService.getFormQuestions(this.baseUrl, this.viewFormId, this.is_monthly).subscribe(res => {
    this.formQuestions = res?.data;
  //   this.formQuestions.forEach((element:any) => {
  //     this.baselineForm.addControl(element.short_key, new FormControl('', Validators.required))
  // });
  
  })
}

openFormList(){
  this.router.navigate(['/admin-forms'])
}

backPath(){
  window.history.back();
}

}
