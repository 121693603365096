<img src="../../../assets/images/login-close.svg" alt="" mat-dialog-close="" class="close-btn position-absolute">
<div class="px-3" style=" overflow: auto; max-height: 80vh;">
    <div class="row">
        <div class="col-12">
            <h4 class="fs-24 mb-3 fw-bold">{{isEditMode ? 'Update' : 'Add'}} {{modalHead}}</h4>
        </div>
        <div class="col-md-6" *ngIf="data?.selectedSubTabName == 'Category' && data?.currentTab == 'resources'">
            <!-- <div class="form-floating mb-3">
                <select class="form-select rounded-pill" id="floatingSelect" aria-label="Floating label select example" >
                    <option value="resource" selected>Resource</option>
                </select>
                <label for="floatingSelect">Select Category</label>
              </div> -->
              <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>Select Category *</mat-label>
                  <mat-select placeholder="Select Profile" [value]="'resource'">
                    <mat-option value="resource" selected>Resource</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
        </div>

        <!-- OUR TEAM FORM FIELDS -->
        <!-- <ng-container *ngIf="data?.currentTab == 'teams'"> -->
            <div class="col-md-6" *ngIf="data?.currentTab == 'teams' || data?.currentTab == 'ourPartners'">
                <!-- <div class="form-floating mb-3">
                    <input type="name" class="form-control rounded-pill" id="floatingInput" placeholder="Name" [(ngModel)]="teamMemberName">
                    <label for="floatingInput">Name</label>
                </div> -->
                <div class="position-relative">
                    <mat-form-field appearance="outline" class="example-full-width w-100">
                      <mat-label>Name *</mat-label>
                      <input type="text" placeholder="Name" matInput [(ngModel)]="teamMemberName" [ngModelOptions]="{standalone: true}" appAlphabatesOnly>
                      <!-- <mat-error class="invalid-feedback mx-3 mb-0"
                        *ngIf="(singupForm.get('userName')?.touched  || singupForm.get('userName')?.dirty) && singupForm.get('userName')?.invalid">
                        Name is Required *.
                      </mat-error> -->
                    </mat-form-field>
                  </div>
            </div>
            <div class="col-md-6" *ngIf="data?.currentTab == 'teams'">
                <!-- <div class="form-floating mb-3">
                    <input type="name" class="form-control rounded-pill" id="floatingInput" placeholder="Designation" [(ngModel)]="teamMemberDesignation">
                    <label for="floatingInput">Designation</label>
                </div> -->

                <div class="position-relative">
                    <mat-form-field appearance="outline" class="example-full-width w-100">
                      <mat-label>Designation *</mat-label>
                      <input type="text" placeholder="Designation" matInput [(ngModel)]="teamMemberDesignation" [ngModelOptions]="{standalone: true}">
                      <!-- <mat-error class="invalid-feedback mx-3 mb-0"
                        *ngIf="(singupForm.get('userName')?.touched  || singupForm.get('userName')?.dirty) && singupForm.get('userName')?.invalid">
                        Name is Required *.
                      </mat-error> -->
                    </mat-form-field>
                  </div>

            </div>
            <div class="col-12" *ngIf="data?.currentTab == 'teams' || data?.currentTab == 'ourPartners'">
                <div class="mb-3">
                    <!-- <textarea class="form-control" placeholder="About" id="floatingTextarea" rows="4" [(ngModel)]="teamMemberAbout"></textarea> -->
                    <mat-form-field appearance="outline" class="example-full-width w-100" >
                        <mat-label>About {{data?.currentTab == 'ourPartners' ? '*' : '' }}</mat-label>
                        <textarea matInput placeholder="About" rows="4" [(ngModel)]="teamMemberAbout"></textarea>
                        <!-- <span class="invalid" *ngIf="isMessage">
                          Message is Required *
                       </span>  -->
                      </mat-form-field>
                    <!-- <label for="floatingTextarea">About</label> -->
                    <!-- <label for="" style="color: red;" *ngIf="descriptionEmpty">*Description is Empty!!</label> -->
                  </div>
            </div>
        <!-- </ng-container> -->

        <div class="col-md-6" *ngIf="!tabsAllow.includes(data?.currentTab) && (data?.selectedSubTabName != 'Category')">
            <!-- <div class="form-floating mb-3">
                <select class="form-select rounded-pill" id="floatingSelect" aria-label="Floating label select example" [(ngModel)]="selectedSection">
                    <option [value]="item?.id" *ngFor="let item of sectionDropdown">{{item?.name}}</option>
                </select>
                <label for="floatingSelect"> {{data?.currentTab == 'resources' ? selectedSubTabName == 'Sub Category' ? 'Select Category' : 'Select Sub Category' : 'Select Category'}} </label>
              </div> -->
              <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>{{data?.currentTab == 'resources' ? selectedSubTabName == 'Sub Category ' ? 'Select Category *' : 'Select Sub Category *' : 'Select Category *'}}</mat-label>
                  <mat-select [(ngModel)]="selectedSection" >
                    <mat-option [value]="item?.sectionId || item?.id" *ngFor="let item of sectionDropdown">
                      {{item['section.name'] || item.name}}
                    </mat-option>
                  </mat-select>
                  <!-- <mat-error class="invalid-feedback mx-3 mb-0"
                  *ngIf="(singupForm.get('userInfo')?.touched  || singupForm.get('userInfo')?.dirty) && singupForm.get('userInfo')?.invalid">
                  User Type is Required *.
              </mat-error> -->

                </mat-form-field>
              </div>
        </div>
        <div class="col-md-6" *ngIf="data?.currentTab != 'glossary' && data?.currentTab != 'contactUs' && data?.currentTab != 'teams' && data?.currentTab != 'ourPartners'">
            <!-- <div class="form-floating mb-3">
                <input type="name" class="form-control rounded-pill" id="floatingInput" placeholder="Title" [(ngModel)]="titleData">
                <label for="floatingInput">Title</label>
              </div> -->

              <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>Title *</mat-label>
                  <input type="text" placeholder="Title" matInput [(ngModel)]="titleData" [ngModelOptions]="{standalone: true}" appAlphabatesOnly>
                </mat-form-field>
              </div>
        </div>
        <!-- <ng-container *ngIf="data?.currentTab == 'contactUs'">
            <div class="col-md-6">
                <div class="form-floating mb-3">
                    <input type="name" class="form-control rounded-pill" id="floatingInput" placeholder="Full Name" [(ngModel)]="fullName">
                    <label for="floatingInput">Full Name</label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-floating mb-3">
                    <input type="email" class="form-control rounded-pill" id="floatingInput" placeholder="Email" [(ngModel)]="emailID">
                    <label for="floatingInput">Email ID</label>
                </div>
            </div>
            <div class="col-12">
                <div class="mb-3">
                    <textarea class="form-control" placeholder="Message" id="floatingTextarea" rows="4" [(ngModel)]="messageData"></textarea>
                </div>
            </div>
        </ng-container> -->
        <!-- <div class="col-12" *ngIf="data?.currentTab != 'glossary'">
            <div class="date-input mb-3">
                <input type="date" class="form-control rounded-pill" id="floatingInput" [(ngModel)]="createdDate">
            </div>
        </div> -->
        <div class="col-md-12" *ngIf="data?.currentTab == 'glossary'">
            <!-- <div class="form-floating mb-3">
                <input type="name" class="form-control rounded-pill" id="floatingInput" placeholder="Term" [(ngModel)]="termData">
                <label for="floatingInput">Term</label>
            </div> -->
            <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>Term *</mat-label>
                  <input type="text" placeholder="Term" matInput [(ngModel)]="termData" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
                <span style="color: red;" *ngIf="termEmpty">*Term is required</span>
              </div>
            
        </div>
        <div class="col-md-12" *ngIf="data?.currentTab == 'impactStories' || data?.currentTab == 'aboutUs'">
            <!-- <div class="form-floating mb-3">
                <input type="text" class="form-control rounded-pill" id="floatingInput" placeholder="https://example.com/xyz/" [(ngModel)]="videoUrl">
                <label for="floatingInput">Video URL</label>
            </div> -->
            <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>Video URL </mat-label>
                  <input type="text" placeholder="https://example.com/xyz/" matInput [(ngModel)]="videoUrl" [ngModelOptions]="{standalone: true}">
                </mat-form-field>
              </div>
        </div>
        <!-- this div is used for ranking field for impactStory and ourPartners -->
        <div class="col-12" *ngIf=" isEditMode && (data?.currentTab == 'ourPartners' || data?.currentTab == 'impactStories')">
            <!-- <div class="mb-3">
                <input type="number" class="form-control rounded-pill" placeholder="Rank" [(ngModel)]="rank">
                <span  *ngIf="isValidRank(rank)" style="color: red;">* Rank Field does not allow Zero(0) && Negative(-1,-2,...)</span>
              </div> -->
              <div class="position-relative">
                <mat-form-field appearance="outline" class="example-full-width w-100">
                  <mat-label>Rank *</mat-label>
                  <input class="removeNumberArrow" type="number" placeholder="Rank" matInput [(ngModel)]="rank" [ngModelOptions]="{standalone: true}" (keypress)="numberOnly($event)">
                  <span  *ngIf="isValidRank(rank)" style="color: red;">* Rank Field does not allow Zero(0) && Negative(-1,-2,...)</span>
                </mat-form-field>
              </div>
        </div>
        <div class="col-12" *ngIf="data?.currentTab == 'homepage' || data?.currentTab == 'glossary' || data?.currentTab == 'impactStories' || data?.currentTab == 'aboutUs'">
            <div class="mb-3" >
               <!-- <div #editorElem  style="height: 20vh;"></div> -->
               <h6 class="fs-16 fw-bold mb-3"> Description {{['impactStories','glossary'].includes(data?.currentTab) ? '*' : ''}}</h6>
               <div #editorElem class="custom-font-size" style="height: 25vh;"></div>

                <!-- <textarea class="form-control" placeholder="Descriptions" id="floatingTextarea" rows="4" [(ngModel)]="descriptionData"></textarea>
                <label for="" style="color: red;" *ngIf="descriptionEmpty">*Description is Empty!!</label> -->
              </div>
        </div>
        <!-- <div class="col-12" *ngIf="showAlert">
            <label for="" style="color: red;">**Some Feilds are blank !!</label>
        </div> -->
        <div class="col-12">
        <ng-container *ngIf="(data?.currentTab != 'glossary' && data?.currentTab != 'contactUs') && (data?.selectedSubTabName != 'Sub Category')">
            <h6 class="fs-16 fw-bold mb-3">Featured image {{(['ourPartners','impactStories','resources'].includes(data?.currentTab) || ['Sub Category','impactStories'].includes(data?.selectedSubTabName)) ? '*' : '' }}</h6>
            <!-- <div class="d-flex text-center justify-content-center align-items-center flex-column draggable-box py-3 mb-3">
    <img src="../../../../assets/images/Image-upload.png" class="img-fluid mb-3" style="max-width: 200px;" alt="">
    <p class="fs-12 mb-2 fw-400">Drag & Drop or <span style="color: #47D985;">Browse</span></p>
    <div class="fs-12" style="color: #6A6A6A;">Supported Size: 52 MB</div>
    
    
            </div> -->

            <div class="receiptCard mt-2 mt-md-3">
                <form action="" [formGroup]="libraryForm">
    
                    <div class="uploadContainer my-2" appDnd (fileDropped)="onFileDropped($event)">
                        <div class="box">
                            <input type="file" accept="image/png, image/gif, image/jpeg" #fileDropRef
                                id="fileDropRef" formControlName='files'
                                (change)="fileBrowseHandler($event)" 
                                />
                                <img src="../../../../assets/images/Image-upload.png" class="img-fluid mb-3" style="max-width: 200px;" alt="">
    
                            <!-- <h3 class="mt-4">Drag and drop file here</h3>
                            <h3 class="mt-4">or</h3> -->
                            <div style="font-size:14px; font-weight:500; margin-top:10px;">Upload Receipt/Screenshot
                            </div>
                            <!-- <label for="fileDropRef">Browse for file</label> -->
                            <div style="font-size: 12px;">
                                <label for="fileDropRef"
                                    style="color:#503794; font-weight:500; cursor:pointer;">Browse</label>
                                <span style="margin-left:4px; font-weight:500;">or Drag & Drop</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p class="uploadFileInfo"><i>{{fileUploadInfo}}</i></p>
                    </div><br>
                    <div class="files-list mx-auto">
                        <div class="single-file" *ngFor="let file of files; let i = index">
                            <div class="file-icon" style="width: 50px">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58"
                                    style="enable-background:new 0 0 58 58;" xml:space="preserve">
                                    <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                    <g>
                                        <path style="fill:#CEC9AE;"
                                            d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                    </g>
                                    <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                                </svg>
                            </div>
                            <div class="info">
                                <a style="text-decoration: none;" [href]="imgURL"
                                    target="_blank" class="name">
                                    {{ file?.name ? file?.name : file?.file_Path}}
                                </a>
                                <!-- <p class="size">
                                    {{ formatBytes(file?.size) }}
                                </p> -->
                                <div *ngIf="file?.progress">
                                    <app-upload-progress [progress]="file?.progress"></app-upload-progress>
                                </div>
                            </div>
    
                            <div class="delete" [matTooltip]="'Delete'" (click)="deleteFile(i)">
                                  <img src="../../../../assets/images/delete-bin.svg" alt="" style="cursor: pointer;">
                            </div>
                            
                        </div>
    
                    </div>
                    <div *ngIf="isEditMode && !clickImage" class="d-flex">
                        <img *ngIf="imgURL?.length" class="prefillImage" [src]="imgURL.includes('pdf') ? '../../../../assets/icons/pdfIcon.svg' : imgURL.includes('.docx') ? '../../../../assets/icons/msWordIcon.svg' : imgURL" alt="" (click)="openDocument()">
                        <div *ngIf="imgURL?.length" [matTooltip]="'Delete'" class="delete ms-2" (click)="deleteFile(0)">
                            <img src="../../../../assets/images/delete-bin.svg" alt="" style="cursor: pointer;">
                      </div>
                    </div>
                </form>
            </div>
        </ng-container>
        <div class="text-end">
            <button class="btn rounded-pill me-3 border bg-white" (click)="closeDialog()">Cancel</button>
            <button class="btn rounded-pill upload-btn text-white" (click)="submitModalData()" [disabled]="isDisable()">{{submitButtonName}}</button>
        </div>
        </div>
    </div>
</div>


