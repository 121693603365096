import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserWelcomeComponent } from 'src/app/layout/footer/user-welcome/user-welcome.component';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';

@Component({
  selector: 'app-baseline-form',
  templateUrl: './baseline-form.component.html',
  styleUrls: ['./baseline-form.component.scss']
})
export class BaselineFormComponent implements OnInit {

baselineForm!: FormGroup;
finalBody: any = {};
months = [
    {
      month: 'January',
      value: 'January'
    },
    {
      month: 'February',
      value: 'February'
    },
    {
      month: 'March',
      value: 'March'
    },
    {
      month: 'April',
      value: 'April'
    },
    {
      month: 'May',
      value: 'May'
    },
    {
      month: 'June',
      value: 'June'
    },
    {
      month: 'July',
      value: 'July'
    },
    {
      month: 'August',
      value: 'August'
    },
    {
      month: 'September',
      value: 'September'
    },
    {
      month: 'October',
      value: 'October'
    },
    {
      month: 'November',
      value: 'November'
    },
    {
      month: 'December',
      value: 'December'
    }
  ];

  years = [
    {
      year: '2020-2021',
      value: '2020-2021'
    },
    {
      year: '2021-2022',
      value: '2021-2022'
    },
    {
      year: '2022-2023',
      value: '2022-2023'
    },
    {
      year: '2023-2024',
      value: '2023-2024'
    },
  ];

selectedMonth:any = '';
selectedYear:any = '';
baseUrl:string ='baselineform/view?formId=';
viewFormId:number = 2;
createForm:string = "baselineform/";
prefillForm:string = "baselineform/list";


constructor(private commonService: CommonServiceService, public dialog: MatDialog, private snackBar: MatSnackBar, private router: Router
    , private formbuilder: FormBuilder,) { 
        this.baselineForm = this.formbuilder.group({
            month: ['', Validators.required],
            financial_year: ['', Validators.required],
          });
    // this.getProgramFormQues();
    }

  formQuestions:any = []

getProgramFormQues(){
    this.commonService.getFormQuestions(this.baseUrl, this.viewFormId).subscribe(res => {
    this.formQuestions = res?.data;
//     this.formQuestions.forEach((element:any) => {
//       this.baselineForm.addControl(element.short_key, new FormControl('', Validators.required))
//   });
  
  })
}

  ngOnInit(): void {
  }

//   submitFormData(isDraft:any = ""){
//     console.log("valuess", this.baselineForm.value);
//     let userId = JSON.parse(localStorage.getItem('userdata')!);
//     this.finalBody = Object.assign(this.baselineForm.value, {"createdBy": userId?.id})
//     if(isDraft){
//       this.finalBody = Object.assign(this.finalBody, {"is_draft": true})
//     }else{
//       this.finalBody = Object.assign(this.finalBody, {"is_draft": false})
//     }
//     this.commonService.submitFormAnswers(this.finalBody, 'baselineform').subscribe(res => {
//       if(res.success){
//         let dialogRef = this.dialog.open(UserWelcomeComponent, {
//           width: '500px',
//           position: {top: '150px'},
//           data: {flag: 'baselineForm'}
//         });
//         dialogRef.afterClosed().subscribe((confirmed: boolean) => {
//           this.router.navigate(['/admin-forms'])
//         });
//       }
//     },(error) => {this.openSnackBar(error.error.message);});
//   }

//   openSnackBar(message:any, action = '') {
//     this.snackBar.open(message, action, {
//       duration: 4000,
//       verticalPosition: 'top',
//       horizontalPosition:'right',
//       panelClass: ['primary']
//     });
//   }

//   numberOnly(event:any): boolean {
//     const charCode = (event.which) ? event.which : event.keyCode;
//     if (charCode > 31 && (charCode < 48 || charCode > 57)) {
//       return false;
//     }
//     return true;
//   }

//   sideBarData:any = [
//     {
//       "id": 1,
//       "sidebarHead": "Program",
//       "subSteppers": [
//           {
//             "stepperName":"Overview"   
//           },
//           {
//             "stepperName":"Option one"                
//           },
//           {
//             "stepperName":"Option Two"                
//           },
//       ]
//     }
//   ];

openFormList(){
  this.router.navigate(['/admin-forms'])
}

backPath(){
  window.history.back();
}

}
