import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonServiceService } from '../services/common-service.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-resources-view',
  templateUrl: './resources-view.component.html',
  styleUrls: ['./resources-view.component.scss']
})
export class ResourcesViewComponent implements OnInit {
  subcategorydata: any = [];
  // getPdfData : any;
  resourcesFlag : boolean = false;
  allDocuments: any = [];
  categoryID: any;
  showNoDataFound: boolean = false;
  showInstepSlideDeck: boolean = false;
  showInstepResource: boolean = false;
  subCategoryReportName: string = '';
  subCategoryName :string = ''
  imageExtenion = ['png','jpeg','jpg','gif']

  constructor(private commonService: CommonServiceService, private route: ActivatedRoute,public sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((res:any) => {
      console.log("myress",res);
      this.subCategoryName = res.params.name
      this.subCategoryData(res.params.id);
    })
  }

  subCategoryData(catID:any){
    this.categoryID = catID;
    this.commonService.getSubCategoryData(this.categoryID).subscribe(response => {
      if(response.success){
        this.showInstepResource = true;
        this.subcategorydata = response?.data;
        console.log("gsdkwe",response?.data)
        this.allDocuments = [];
        if(!this.subcategorydata.length){
          this.showNoDataFound = true;
        }
      }
    })
  }

  ShowPdf(){
this.resourcesFlag = true;
// this.commonService.getPdfData().subscribe((res : any) =>{
//   console.log(res)
//       })
  }

  changeTab(){
    this.showInstepSlideDeck = false;
    this.subCategoryData(this.categoryID);
    // this.resourcesFlag = false;
    this.allDocuments = [];
    this.showNoDataFound = false;

  }

  // pdfFolder(){

  // }

  showDocuments(data:any){
    this.commonService.getDocumentsData(data.id).subscribe(res => {
      if(res.success){
        this.allDocuments = res?.data;
        this.subCategoryReportName = data?.name
        if(res.data?.length){
          this.addNewKeyForPdf(this.allDocuments)
        }
        this.showInstepResource = false;
        this.showInstepSlideDeck = true;
        console.log("allDocuments",res)
        if(this.allDocuments.length == 0){
          this.showNoDataFound = true;
        }
        this.subcategorydata = [];
      }
    }, (error) => {this.showNoDataFound = true;})
  }


  downloadDocs(data: any) {
    console.log('Download clicked');
    let dataUrl = data?.link;
    
    /* extracting the filename from a given URL stored in the `dataUrl` variable. */
    const filename = dataUrl.substring(dataUrl.lastIndexOf('/') + 1);

    
    /* extracting the file extension from a given filename. */
    const fileExtension = filename.substring(filename.lastIndexOf('.') + 1);
    
    /* checking if the file extension of a given URL is either 'jpg', 'jpeg', or
    'png'. If the file is an image (with one of these extensions), it downloads the image using the
    `commonService.downloadImage` method, creates a URL for the image blob, creates an anchor
    element to trigger the download, sets the download filename as 'image.jpg', and then initiates
    the download by simulating a click on the anchor element. */
    if(fileExtension == 'jpg' || fileExtension == 'jpeg' ||fileExtension == 'png'){
      this.commonService.downloadImage(data?.link).subscribe(blob => {
        const imageUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = imageUrl;
        anchor.download = 'image.jpg';
        anchor.click();
        URL.revokeObjectURL(imageUrl); // Clean up the object URL
      });
    }else{
      window.open(data.link, '_blank');
    }
    
  }
  
  addNewKeyForPdf(data:any){
    this.allDocuments = this.allDocuments.map((el:any)=>{
      if(el?.link){
        let currentExtension = el?.link.split('.').reverse()[0];
        el['isImg'] = this.imageExtenion.includes(currentExtension)
       el['isPdfKey'] = el?.link.split('.').reverse()[0] === 'pdf';
      }
      return el;
    })
  }
  geturl(url:string){
    let newUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url+'#toolbar=0');
    return newUrl
  }
  isExcelOrCsv(url: string): boolean {
    return url.toLowerCase().endsWith('.xlsx') || url.toLowerCase().endsWith('.csv') || url.toLowerCase().endsWith('.docx');
  }
  isExcelOrCsvTypeShowPreview(url: string,type:string=''): boolean {
    if(url.toLowerCase().endsWith('.xlsx') && type==='xlsx'){
      return url.toLowerCase().endsWith('.xlsx');
    }
    else if(url.toLowerCase().endsWith('.csv') && type==='csv'){
      return url.toLowerCase().endsWith('.csv');
    }
    else if(url.toLowerCase().endsWith('.docx') && type==='docx'){
      return url.toLowerCase().endsWith('.docx');
    }
    return false
  }

}
