import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../services/common-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  show: boolean = false;
  showSecondPass: boolean = false;
  userAllData: any = [];
  newPassword:string = "";
  confPassword:string = "";
  transactionID: any;
  isNewPassword: boolean = false;
  isStrongPassword: boolean = false;
  isConformPassword: boolean = false;
  decodedEmail: string = "";

  constructor(private commonService:CommonServiceService, private route: ActivatedRoute, private router:Router, private snackBar: MatSnackBar) {
    this.route.queryParamMap.subscribe((res:any) => {
      if(res && res.params && res.params.data){
        this.decodedEmail = atob(res.params.data);
      }
    })
   }

  ngOnInit(): void {
    this.transactionID = this.route.snapshot.params.id;
    console.log("this.transactionID",this.transactionID);
    
  }

  showPassword(){
    this.show = !this.show
  }

  showConfirmPassword(){
    this.showSecondPass = !this.showSecondPass
  }

  resetPassword(){
    if(this.newPassword == "" || this.confPassword == ""){
      this.openSnackBar(['Fields can not be empty !'], 3000);
      return
    }
    if(this.newPassword != this.confPassword){
      this.openSnackBar(['Password mismatch !'], 3000);
      return
    }
    const random = Math.random();
    let cipherText1 = this.commonService.getSecratKey(environment.secratKey,this.newPassword);
    let cipherText2 = this.commonService.getSecratKey(environment.secratKey,this.confPassword);
    // let randomKey = random.toString();
    // let encryptedPassword1 = this.commonService.getSecratKey(randomKey, cipherText1);
    // let encryptedPassword2 = this.commonService.getSecratKey(randomKey, cipherText2);
    let payload = {
      "txn_id": this.transactionID,
      "password": cipherText1,
      "confirm_password": cipherText2
    }
    this.commonService.resetPassword(payload).subscribe(res => {
      if(res.success){
        this.getTargetterUserData();
      }
    }, error => {
      this.openSnackBar([error?.error?.message ? error?.error?.message : 'failed !'],3000)
      console.log(error);
    })
  }

  getTargetterUserData(){
    let endpoint = `/admin/submission?data.email=${this.decodedEmail}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {},
      "method": "GET"
    };
    this.commonService.targettedUserData(payload).subscribe((res:any) => {
      if(res.length){
        this.userAllData = res;
        this.updateUserData(this.userAllData);
      }
    },(error) => console.log(error));
  }

  updateUserData(userData:any){
    let endpoint = `/admin/submission/${userData[0]._id}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {
        "data": {
          "email": this.decodedEmail,
          "password": this.confPassword
    }
    },
      "method": "PUT"
    }
      this.commonService.updateUserForIpsa(payload).subscribe(res => {
        if(res){
          this.openSnackBar(['Password update successfully !'],3000);
          this.router.navigate(['/home'])
        }
      })
  }

  openSnackBar(data: string[], duration: number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }
  passwordMethod(){
    if(!this.newPassword){
      this.isNewPassword =  true
    }
    else{
      this.isNewPassword =  false;
      this.passwordValidator()
    }
  }
  passwordMatchMethod(){
    if(this.newPassword == this.confPassword){
      this.isConformPassword = false;
    }
    else{
      this.isConformPassword = true;
    }

  }
  passwordValidator() {
    const value = this.newPassword;
    const hasNumber = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasSpace = /\s/g.test(value);
    const isLengthValid = value.length >= 8;
    this.isStrongPassword = hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase && isLengthValid && !hasSpace;
  }


}
