import { Component, OnInit } from '@angular/core';
import { UserManagementServiceService } from '../user-management-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { tableConfiguration } from '../user-management-table/user-management-table.component';
import { Router } from '@angular/router';
const editIconImage = '../../../../assets/images/edit.svg';
const deleteIconImage = '../../../../assets/images/delete-bin.svg';

@Component({
  selector: 'app-user-level',
  templateUrl: './user-level.component.html',
  styleUrls: ['./user-level.component.scss']
})
export class UserLevelComponent implements OnInit {
    allRoleDetails: any;
    allowedAction: any;
  constructor(
    private userManagementServiceService: UserManagementServiceService,
    private snackBar:MatSnackBar,
    private router : Router,

  ) {
    let pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
    this.allowedAction = pageAction[0].authenicationJson.filter((el:any)=>{
      if(el?.model === 'role') return el;
    })
   }

  ngOnInit(): void {
    this.action =  this.userManagementServiceService.actionTablePermission(this.action,this.allowedAction[0]);
    this.getAllRoleList()
  }

  totalCount = 0;

  // action=[
  //   {actionType:'edit',icon:'fa fa-edit',disabled:false, toolTip:'Edit',imgIcon:'../../../../assets/images/edit.svg'},
  //   {actionType:'delete',icon:'fa fa-trash',disabled:false,toolTip:'Delete',imgIcon:'../../../../assets/images/delete-bin.svg'},
  // ]
  action=[
    {actionType:'edit',icon:'fa fa-edit',disabled:false, toolTip:'Edit',imgIcon:'../../../../assets/images/edit.svg',isPermission:false,label:'Update',key:'update'},
    {actionType:'delete',icon:'fa fa-trash',disabled:false,toolTip:'Delete',imgIcon:'../../../../assets/images/delete-bin.svg',isPermission:false,label:'Delete',key:'delete'},
    {actionType:'view',icon:'fa fa-eye',disabled:false,toolTip:'View',imgIcon:'../../../../assets/images/view-icon.svg',isPermission:true,label:'View',key:'view'},
  ]
  userTableHeading1 = [
    {title: '', code: 'User Role', key: 'role.name' , subKey: '', type:'text', sort: true,},
    {title: '', code: 'Module', key: 'label' , subKey: '', type:'text', sort: true},
    {title: '', code: 'Actions', key: 'action' , subKey: '', type:'text', sort: false},
  ]

  redirectUserRoleCreate():void{
    this.router.navigate(['/user-policies']);
  }
  tableAction(data:any, action: string) {
    if (action === 'edit') {
      this.router.navigate(['/user-policies', data.role_id]);
    }
    if (action === 'delete') {
      this.deleteModule(data);
    }
    if(action === 'view'){
      let queryParams = {
        mode : 'view'
      }
      this.router.navigate(['/user-policies', data.role_id], { queryParams: queryParams });
    }
  }

  deleteModule(data:any) {
    console.log("data",data)
    let payload = {
        isActive : data.id ? false : true,
        role_name: data['role.name'] ? data['role.name'] : ''
      }
      console.log(data,"update")
      this.userManagementServiceService.deleteRole(data?.id,payload).subscribe((res:any) => {
        if(res.success){
          // this.openSnackBar([res?.message || 'Success !'] , '','success');
          this.openSnackBar([ 'Deleted Successfully '] , '','success');
          let page = data?.page ? data?.page : 1;
          this.getAllRoleList(page);
        }
      },(error:any)=>{
        this.openSnackBar([error?.message || 'Failed !'] , '','');
      })
  }
  // openSnackBar(data: string[], duration:number) {
  //   this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  // }
  getAllRoleList(page=1,limit=10,sortingOrder:string='',columnName:string=''):void{
    this.userManagementServiceService.allRoleDetails(page,limit,sortingOrder,columnName).subscribe((res:any) => {
      // this.contentService.loaderSubscriber.next(true);
      if(res.success){
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
        console.log(res.data,"1")
        this.allRoleDetails = JSON.parse(JSON.stringify(res.data));
        this.totalCount = res.total ? res.total : 0
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '','');
      this.allRoleDetails = []
    })
  }
  clearSearchBox(event:any){
    this.getAllRoleList()
  }
  pageHandler(event:any):void{
    this.getAllRoleList(event?.page,event?.limit, event?.orderSorting,event?.key)
  }
  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: [className]
  });
  }
  sortTable(event:any){
    this.getAllRoleList(event.page,10,event?.orderSorting,event?.key)
  }
}
