
<div class="d-flex justify-content-between align-items-center mt-3">
  <div class="statusButton">
    <div class="row">
      <div class="col-sm-7">
        <button class="receiveButton rounded-pill ml-2" mat-button (click)="fetchStatus('receive')" [ngStyle]="{'background-color': buttonType == 'receive' ? '#47d985' : '#f9f9f9'}">Received Message</button>
      </div>
      <div class="col-sm-5">
        <button class="sendButton rounded-pill" mat-button (click)="fetchStatus('send')" [ngStyle]="{'background-color': buttonType == 'send' ? '#47d985' : '#f9f9f9'}">Sent Message</button>
      </div>
    </div>
      <!-- <button class="btn dropdown-toggle newest-dropdown fs-13 text-green position-relative" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Newest</button> -->
      <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" href="#">Action</a></li>
        <li><a class="dropdown-item" href="#">Another action</a></li>
        <li><a class="dropdown-item" href="#">Something else here</a></li>
      </ul> -->
    </div>

    <!-- <button class="filter-dropdown rounded-pill" mat-button [matMenuTriggerFor]="menu" *ngIf="userData?.userlevel_id == 1">
      <span class="">
          <svg class="filter-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <g id="Group_16821" data-name="Group 16821" transform="translate(-4157 -790)">
                <path id="Path_6201" data-name="Path 6201" d="M0,0H20V20H0Z" transform="translate(4157 790)" fill="none"/>
                <path id="Path_6202" data-name="Path 6202" d="M18,4V5.667h-.833L13,11.917V19H8V11.917L3.833,5.667H3V4ZM5.837,5.667l3.83,5.745v5.922h1.667V11.412l3.83-5.745Z" transform="translate(4156.5 789.333)" fill="#444"/>
              </g>
            </svg>
            
            
      </span>
      Filters
    </button> -->
      <mat-menu #menu="matMenu" class="sidebarMenu">
            <div (click)="$event.stopPropagation();">
                <!-- <div class="my-3 px-2 position-relative">
                    <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute filter-search-icon" alt="">
                    <input type="search" placeholder="Search" class="form-control filter-search rounded-pill">
                  </div> -->
                  <div class="accordion" id="accordionExample">
                    <form action="" [formGroup]="filterForm">
                    <div class="accordion-item border-0">
                      <h2 class="accordion-header border-0" id="headingOne">
                        <button class="accordion-button border-0 pt-0 pb-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          From
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body form-body mb-2 py-1">
                              <div class="form-check mb-2" *ngFor="let item of fromJson; let i = index">
                                    <input class="form-check-input" type="radio" [value]="item.name" [id]="'radio' + i" name="radioGroup" (change)="onValueChange($event, item, 'from')">
                                    <label class="form-check-label fs-14" [for]="'radio' + i">{{item?.name}}</label>
                              </div>
                                  <!-- <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
                                    <label class="form-check-label fs-14" for="flexCheckDefault2">Government</label>
                                  </div>
                                  <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3">
                                    <label class="form-check-label fs-14" for="flexCheckDefault3">Public User</label>
                                  </div> -->
                            <!-- </form> -->
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item border-0">
                      <h2 class="accordion-header border-0" id="headingTwo">
                        <button class="accordion-button border-0 pt-0 pb-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                          To
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body form-body mb-2 py-1">
                            <!-- <form action=""> -->
                                <div class="form-check mb-2" *ngFor="let item of toJson; let i = index">
                                  <input class="form-check-input" type="checkbox" (click)="$event.stopPropagation()" 
                                  [(ngModel)]="item.isChecked" [ngModelOptions]="{ standalone: true }" 
                                  (change)="onValueChange($event, item, 'to')"> 
                                    <label class="form-check-label fs-14" [for]="'to' + i">{{item.name}}</label>
                                  </div>
                                  <!-- <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault2">
                                    <label class="form-check-label fs-14" for="flexCheckDefault2">Country Manager</label>
                                  </div>
                                  <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault3">
                                    <label class="form-check-label fs-14" for="flexCheckDefault3">Factory Staff</label>
                                  </div> -->
                            <!-- </form> -->
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item border-0">
                      <h2 class="accordion-header border-0" id="headingThree">
                        <button class="accordion-button border-0 pt-0 pb-1" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                          Status
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body form-body py-1">
                            <!-- <form action=""> -->
                                <div class="form-check mb-2" *ngFor="let item of statusJson; let i = index">
                                    <input class="form-check-input" type="checkbox" (click)="$event.stopPropagation()" 
                                    [(ngModel)]="item.isChecked" [ngModelOptions]="{ standalone: true }" 
                                    (change)="onValueChange($event, item, 'status')">
                                    <label class="form-check-label fs-14" [for]="'status' + i">{{item.name}}</label>
                                  </div>
                                  <!-- <div class="form-check mb-2">
                                    <input class="form-check-input" type="checkbox" value="" id="reject">
                                    <label class="form-check-label fs-14" for="flexCheckDefault2">Rejected</label>
                                  </div> -->
                            <!-- </form> -->
                        </div>
                      </div>
                    </div>
                    </form>
                  </div>

                    <!-- <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Date Range
                            </button>
                          </h2>
                          <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <input type="date" name="" placeholder="From" class="form-control" id="">
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="date" name="" placeholder="To" class="form-control" id="">
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                        </div> -->
                    <hr>
                    <div class="text-end">
                        <button class="btn border-0 fw-600 reset-btn" (click)="resetFilters('receive')">Reset</button>
                        <button class="btn border-0 fw-600 apply-btn" (click)="applyFilter()">Apply</button>
                    </div>

            </div>
        </mat-menu>
</div>
<hr class="mb-1">
  <div class="col-auto my-2 p-0">
    <div class="position-relative searchbar d-flex">
        <img src="../../../assets/images/search-line.svg" class="img-fluid position-absolute search-iconn" alt="">
        <input type="text" placeholder="Search" class="form-control search-input me-2" (keydown.enter)="onKeyDownEvent()" [(ngModel)]="searchedValue">
        <button class="btn btn-success btn-sm clearSearchBtn" (click)="clearSearch()" *ngIf="searchedValue">Clear</button>
    </div>
</div>
<!-- <hr class="mt-1"> -->
<div class="sidebarContent" #customDiv (scroll)="scrollHandler($event)">
  <ng-container *ngFor="let item of allListData" >
  <div class="d-flex justify-content-between align-items-center p-3 chat-hover chatContainer" (click)="openSelectedMsg(item)" [ngClass]="{'active-chat': item?.is_Clickable}">
    <div class="d-flex align-items-center">
      <div class="d-flex">
        <div class="profileNameBox me-2" [ngStyle]="{'background-color': item?.color}">
          <span class="nameInitials">{{item.nameInitials}}</span>
        </div>
        <div>
          <div class="fs-14 color-44" [ngStyle]="{'font-weight' : (item && item?.is_visible) || buttonType == 'send' ? 400 : 700}">{{buttonType == "receive" ? item.name : item?.admin != null ? item?.admin?.name : item?.assessor != null ? item?.assessor?.name : item?.factory != null ? item?.factory?.name : item?.industrialpark?.name}}</div>
          <p class="fs-11 color-44 m-0" [ngStyle]="{'font-weight' : (item && item?.is_visible) || buttonType == 'send' ? 400 : 700}">{{item?.subject |
            truncatePipe : 75}}</p>
        </div>
      </div>

    </div>

    <div class="text-end">
      <div class="fs-11 color-44">{{item.createdAt | date:'M/d/yy, h:mm a'}}</div>
      <img [src]="item.is_highlight ? '../../../assets/images/yellow-star.svg' : '../../../assets/images/grey-star.svg'"
        class="img-fluid" alt="">
    </div>
    

  </div>
</ng-container>
<div *ngIf="showInfo" class="noData">
  <img alt="no data" src="../../../../assets/no_data_image/error-logo.png" class="noDataImage">
</div>
<span class="loadmoreTab" (click)="showMoreChats()" *ngIf="allListData.length >= 10"><u>{{ allListData.length == totalCount ? '' : 'Load More..'}}</u></span>
</div>