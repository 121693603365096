<ng-container *ngIf="!validationMessage">
    <div class="row justify-content-end" *ngIf="data?.showCancelBtn">
        <button class="close-button"  tabindex="-1" (click)="closeDirectly('yes')">
             <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content >
        <h5 align="center" class="customColorText" style="font-size: 15px;">
            <mat-icon role="img" class="mat-icon notranslate material-icons warn-icon customPolicyIconColor" aria-hidden="true"
                *ngIf="!data?.showTextBox">info</mat-icon>
            {{message}}
        </h5>
        <em align="center" class="customColorText" *ngIf="data?.alternateMessage">
            {{data?.alternateMessage}}
        </em>
    </mat-dialog-content>
    <textarea *ngIf="data?.showTextBox" class="w-full p-2 shadow-none" style="width: 100%; outline: none;"
        placeholder="Write your comments" (input)="onChangeTextBox($event)"></textarea>
    <mat-dialog-actions align="center" class="mt-3">
        <button *ngIf="data?.showCancelBtn" tabindex="-1" class="btn customBtnBorder text-uppercase mr-2  mx-2"
        (click)="closeDirectly('no')">{{cancelButtonText}}</button>
        <button *ngIf="!data?.showCancelBtn" class="btn customBtnBorder text-uppercase mr-2 mx-2"
            mat-dialog-close>{{cancelButtonText}}</button>
        <button (click)="onConfirmClick()" class="btn customBtn text-uppercase mx-2"
            tabindex="1">{{confirmButtonText}}</button>
    </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="validationMessage">
    <div [innerHTML]="validationMessage"></div>
</ng-container>