import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonServiceService } from '../services/common-service.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  aboutUS: any;

  constructor(private commonService: CommonServiceService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getAboutUsPageData();
  }

  getAboutUsPageData(){
    this.commonService.aboutUsData().subscribe((res:any) => {
      this.aboutUS = res?.data;
      console.log("aboutt",this.aboutUS);
    })
  }
  stripHtmlTags(input: any): string {
    const doc :any = new DOMParser().parseFromString(input, 'text/html');
    const images = doc.querySelectorAll('img');
  
    const textContent = doc.body.textContent || "";
    
  
    // if (images.length > 0) {
      images.forEach((image:any) => {
        image.classList.add('img-fluid');
      });
      return doc.body.innerHTML;
    // }
  
    // return textContent?.length > 500 ? textContent.substring(0, 500) + '...' : textContent; 
  }
  showInnerHTMLData(data:string){
    let trustedHtml:string = ''
    // console.log("before",data)
    trustedHtml = this.sanitizer.bypassSecurityTrustHtml(data) as string;
    // console.log("before after",trustedHtml)
    return trustedHtml
  }
}
