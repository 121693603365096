import { DomElementSchemaRegistry } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { CommonServiceService } from '../services/common-service.service';

declare function formBuild(args1:any) : any;
declare function makeFormio() : any;
declare var jsonSchema : any;

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {

  isToggleChecked:boolean = false;
  showSplit:boolean = false;
  urlData: any;
  formTitle:any = "";
  formName:any = "";
  formAPIpath:any = "";
  jwtToken:string = "";
  showFormName: any;

  constructor(private commonService:CommonServiceService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router) { 
    console.log("jsonSchema",jsonSchema);
    this.route?.queryParams.subscribe(params => {
      this.urlData = params;
      this.showFormName = this.urlData?.formName.replace('Performance','Assessment');
      console.log("myparamsss",params);
    })
    this.jwtToken = sessionStorage.getItem('jwtToken')!;
  }

  customjs = {}

  ngOnInit(): void {
    if(this.urlData?.formId){
      this.fetchFormResponse()
    }else{
      setTimeout(() => {
        formBuild(this.customjs);
      },2000)
    }
    
    
  }
  backToForm(){
    this.router.navigate(['/ipsa-forms'])
  }
  backToPrevious(){
    this.router.navigate(['/ipsa-forms-list'],{queryParams:{
      formName: this.urlData?.formName
    }});
  }

  fetchFormResponse(){
    // console.log("jsnsch",this.commonService.jsonSchema)
    let endpoint = `/form/${this.urlData.formId}`
    let payload = {
        'method': 'GET',
        'endpoint': endpoint,
        'headers': {
          "Content-Type": "application/json",
          'x-jwt-token': this.jwtToken
        }
      };
    this.commonService.getIpsaFormStructure(payload).subscribe(res => {
      if(res){
        if(res.length && res[0].hasOwnProperty('x-jwt-token'))
        sessionStorage.setItem('jwtToken', res[0]['x-jwt-token']);
        this.formTitle = res?.title;
        this.formAPIpath = res?.path;
        this.formName = res?.name;
       formBuild(res)
        // this.formioipsa = res;

        // this.prefillForm();
      }
    })
  }

  onCreate(){    
    let payload = {
      'method': this.urlData.formId ? 'PUT' : 'POST',
      'endpoint': this.urlData.formId ? `/form/${this.urlData.formId}` : `/form`,
      'headers': {
        'Content-Type': 'application/json',
        'x-jwt-token': this.jwtToken
      },
      requestBody: 
      {
        "title": this.formTitle,
        "display": this.urlData.formName,
        "type": "form",
        "name": this.formName,
        "path": this.formAPIpath,
        "components": jsonSchema.components
    }
    
    };
    this.commonService.createNewFormIO(payload).subscribe(res => {
      if(res){
        if(res.length && res[0].hasOwnProperty('x-jwt-token'))
        sessionStorage.setItem('jwtToken', res[0]['x-jwt-token']);
        let message = 'Successfully Submitted';
        if(res?.status == 400)
        message = res?.message;
        this.openSnackBar([message], 2000)

        if(res?.status != 400)
        setTimeout(() => {
          this.router.navigate(['ipsa-forms-list'], {queryParams:{formName: this.urlData?.formName}});
        }, 2000);
      }
    })
    // makeFormio();
    // this.openSnackBar(!Object.keys(jsonSchema).length || !jsonSchema.length ? ['Unknown component'] : ['Form render successfull !'], 3000);

  }

  publishForm(){
    let payload = jsonSchema;
    this.commonService.publishForms(payload).subscribe(res => {
      if(res){
        console.log("form-response",res);
        
      }
    })
  }

  openSnackBar(data: string[], duration: number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }

  onToggle(event:any){
  if(event.checked){
    this.onRender();
  } 
  }

  screenSplit(event:any){
    if(event.checked){
      // this.isToggleChecked = true;
      this.onRender();
    }
  }

  onRender(){
    makeFormio();
  }

}
