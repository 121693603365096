import { Component, OnInit, SecurityContext } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { CommonServiceService } from '../services/common-service.service';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';



@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  glossaryData: any = [];
  resourceCenter: any = [];
  searchedValue: any = "";
  searchData: any = [];
  pageHeading: any = [];
  isLoadMore:boolean = false;
  totalDataCount: number = 0;
  public limit = 10;
  public skip = 0;
  public page = 1;
  orderSorting: string = '';
  tableHeading:any = [
    {
      thead: 'Term',
      key: 'term'
    },
    {
      thead: 'Definition',
      key: 'definition'
    },
  ]
  sortSelection: string = '';
  selectedColumn: any;

  constructor(private service : CommonServiceService, private snackBar: MatSnackBar,private router: Router,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getresourceCenterData();
    this.resourcePageHeading();
    // this.getGlossaryData();
  }

  openSnackBar(data: string[], duration:number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }
  // glossaryData : any

//   resourceCenter =[ 
//     {
//     img : '../../../assets/images/resource-img1.png',
//     title : 'INSTEP Resources' ,
//     button : 'View'
//   },
//   {
//     img : '../../../assets/images/resource-img2.png',
//     title : 'IPSA' ,
//     button : 'View'
//   },
//   {
//     img : '../../../assets/images/resource-img3.png',
//     title : 'IPSA Reports' ,
//     button : 'View'
//   }
// ]

glossaryHeader = [{
  term : 'Term',
  definition : 'Definition'
}];

//   glossaryData = {
// "timestamp": 1694781220,
// "success": true,
// "message": "FETCHED DATA.",
// "data": [

//     {
// "id": 1,
// "term": "lorem lorem 42",
// "definition": "lorem lorem lorem lorem ",
// "isActive": true,
// "createdBy": 1,
// "createdAt": "2023-09-15T12:32:44.411Z",
// "updatedAt": "2023-09-15T12:32:44.411Z"
// }

// ],
// "total": 0
// }

getGlossaryData(page:any, searchString = "",sortingOrder:string='',columnName:string=''){
  this.service.glossaryData(page, searchString,sortingOrder,columnName).subscribe((res)=>{
    console.log("glosary data",res);
    if(res.success){
      this.glossaryData = res.data;
      this.totalDataCount = res.total;
      this.searchData = [...this.glossaryData];
    }
  },(error) => console.error(error));

}

getresourceCenterData(){
  this.service.resourceCenterData().subscribe(res => {
    if(res.success){
      this.resourceCenter = res.data;
      console.log("resourceCenter",this.resourceCenter);
      
    }
  })
  this.getGlossaryData(1);
}

onKeyDownEvent(){

    this.getGlossaryData(1, this.searchedValue);
  
  // FRONTEND SEARCH CODE PART
  // console.log(this.glossaryData, this.searchData);
  // if(this.searchedValue){
  //   this.glossaryData = this.searchData.filter((ele:any) => {
  //     if(typeof(ele) == 'object'){
  //       if(ele.term.toLowerCase().includes(this.searchedValue.toLowerCase()) || ele.definition.toLowerCase().includes(this.searchedValue.toLowerCase())){
  //         return ele;
  //       }
  //     }
  //   });
  //   console.log("kokok",this.glossaryData);    
  // }else{
  //   this.glossaryData = this.searchData;
  //   console.log("enter some value");
  // }  
}

resourcePageHeading(sectionId = 9){
  this.service.getPageDetails(sectionId).subscribe(res => {
    if(res.success){
      this.pageHeading = [...res.data];
      console.log("uiui",this.pageHeading);
      // .find((ele:any) => ele.slug == 'resources')
      this.pageHeading = this.pageHeading && this.pageHeading.length ? this.pageHeading[0] : [];
      console.log("uiui",this.pageHeading, res.data);
      
    }
  })
}

setPage(event: any) {
  // this.selectedColumn = '';
  this.page = event.pageIndex + 1;
  this.skip = (event.pageIndex + 1) * this.limit - this.limit;
  console.log('limit',event.pageSize,event, this.skip)
  this.limit = event.pageSize;
  this.getGlossaryData(event.pageIndex + 1, '',this.orderSorting, this.selectedColumn)
}

  //CLEAR SEARCH
  clearSearch(){
    this.searchedValue = "";
    this.onKeyDownEvent();
  }
  redirectUrl(item:any){
    console.log("rere",item)
    this.router.navigate(['/resources-view'], {
      queryParams: { id: item?.id, name:item?.name },
    });
    console.log(item);
    
  }

  stripHtmlTags(input: any,limit:number): string {
    const doc: any = new DOMParser().parseFromString(input, 'text/html');
  
    // Extract plain text content without HTML tags
    const textContent = doc.body.textContent || "";
  
    // Truncate text content to a limit of 150 characters
    let truncatedText :string = ''
    if(limit > 0){
      truncatedText  = textContent.length > limit ? textContent.substring(0, limit) + '...' : textContent;
    }
    else{
      truncatedText = textContent;
    }
   
    return truncatedText;
  }
  sortTable(columnName: any, sortType:string = '') {
    this.sortSelection = sortType;
    this.selectedColumn = columnName;
    this.orderSorting = sortType === 'down' ? 'DESC' : 'ASC';
    this.getGlossaryData(this.page,'',this.orderSorting,columnName)
  }

  isShowMore(){
    return this.resourceCenter.filter((data:any) => data?.types == 'resource').length > 4 ? true : false;
  }

}
