import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[appMaxLength]'
})
export class MaxLengthDirective {

  constructor(
    private el: ElementRef,
    private snackBar : MatSnackBar
  ) { }
  @HostListener('input',['$event'])
  onInput(event:KeyboardEvent):void{
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value
    if(inputValue?.length >= 200){
      this.openSnackBar(['text enter only 200 characters !'],'')
    }
  }
  openSnackBar(message:any, action = '') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: ['primary']
  });
}

}
