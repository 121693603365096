import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
@Directive({
  selector: '[appSpecialCharactersValidation]'
})
export class SpecialCharactersValidationDirective {
  constructor(
    private el: ElementRef,
    private snackBar:MatSnackBar,
    ) {}

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;

    // Define a regular expression pattern to allow only letters and numbers
    const pattern = /^[a-zA-Z0-9\s]*$/;

    if (!pattern.test(inputValue)) {
      // Remove special characters from the input value
      this.openSnackBar(['do not use Special Characters !'] )
      const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
      inputElement.value = sanitizedValue;
    }
  }
  // openSnackBar(data: string[], duration:number) {
  //   this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  // }
  openSnackBar(message:any, action = '') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: ['primary']
  });
}
}


