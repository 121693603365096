import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Chart, TooltipItem } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

@Component({
  selector: "app-doughnut",
  templateUrl: "./doughnut.component.html",
  styleUrls: ["./doughnut.component.scss"],
})
export class DoughnutComponent implements OnInit {
  @ViewChild("dChart") dChartCanvas!: ElementRef<HTMLCanvasElement>;
  private dChartContext!: CanvasRenderingContext2D;
  private dChart: Chart | unknown;
  private chart: Chart | any;
  @Input() data: any[] = [];
  @Input() colors = [ "#00855A", "#FED766", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf", "#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5", "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5", "#fdd0a2", "#b3e2cd", "#fccde5", "#d9d9d9", "#e5d8bd", ];
  @Input() chartConfig: {
    tooltip?: "both" | "percentage" | "value";
    dataLabel?: "both" | "percentage" | "value";
    centerText?: boolean;
  } = {
    tooltip: "both",
    dataLabel: "both",
    centerText: true
  };
  customData: any = [];
  customLabel: any = [];
  isPercentage: boolean = false;
  isProgress: boolean = false;
  showNoData: boolean = false;
  constructor() {}

  ngOnInit(): void {
    console.log(this.data, "doughnut", this.chartConfig);
    if (this.data) {
      this.getDataForChart(this.data);
      this.getLabelForChart(this.data);
    }
    if (this.chart) {
      this.chart.destroy();
    }
    this.createdChart();
  }

  centerText(chart: any) {
    if (!chart?.config?.options?.centerText?.show) {
      return;
    }
    const ctx = chart.ctx;
    const width = chart.width;
    const height = chart.height;
    const centerX = width / 2;
    const centerY = height / 2;

    // Calculate total value (assuming single dataset)
    const datasets = chart.config.data.datasets;
    let totalValue = 0;
    datasets[0].data.forEach((value: any) => {
      totalValue += Number(value);
    });

    // Calculate and format percentage
    const percentage =
      chart.config.options.centerText &&
      chart.config.options.centerText.calculate
        ? chart.config.options.centerText.calculate(datasets[0].data).toString()
        : `${((totalValue / totalValue) * 100).toFixed(2)}%`;

    // Draw text
    ctx.save();
    if (chart?.config?.options?.centerText?.title) {
      ctx.font =
        chart?.config?.options?.centerText?.title &&
        chart?.config?.options?.centerText?.title.font
          ? chart?.config?.options?.centerText?.title.font
          : "16px sans-serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle =
        chart?.config?.options?.centerText?.title &&
        chart?.config?.options?.centerText?.title.color
          ? chart?.config?.options?.centerText?.title.color
          : "black";
      let text = chart?.config?.options?.centerText?.title?.text;
      let yOffset = chart?.config?.options?.centerText?.title?.yOffset || 0;
      let _centerY = centerY-5.5;
      let _centerX = centerX-(Number(text.length/2));
      ctx.fillText(text, _centerX, _centerY + yOffset);
    }
    ctx.font =
      chart.config.options.centerText && chart.config.options.centerText.font
        ? chart.config.options.centerText.font
        : "16px sans-serif";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle =
      chart.config.options.centerText && chart.config.options.centerText.color
        ? chart.config.options.centerText.color
        : "black";
    let _centerY = centerY-7;
    let _centerX = centerX-(Number(percentage.length/2));
    ctx.fillText(percentage, _centerX, _centerY);

    ctx.restore();
  }

  private createdChart(): void {
    console.log("this.customData", this.customData, this.customLabel);
    Chart.register(ChartDataLabels);
    Chart.register({
      id: "centerText",
      beforeDraw: this.centerText,
    });
    // this.sectorText();
    Chart.defaults.set("plugins.datalabels", {
      color: "#fff",
    });
    const numericalData = this.convertIntoPercentage(this.customData);
    console.log();
    this.dChart = new Chart(this.dChartContext, {
      type: "doughnut",
      data: {
        labels: this.customLabel,
        datasets: [
          {
            data: this.customData,
            backgroundColor: this.isProgress ? ["#00855A","#e5e5e5"] : this.colors,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            color: "white",
            font: {
              weight: "bold",
              size: 11,
            },
            formatter: (value: any, context: any) => {
              if ( this.chartConfig?.dataLabel &&
                ["both", "percentage", "value"].includes(
                  this.chartConfig?.dataLabel || ""
                )
              ) {
                const meta = context.dataset.data._chartjs.listeners[0].getMeta();

                const { data } = meta;
                const index = meta._dataset.data.findIndex(
                  (el: any) => el == value
                );
                let model = data[index],
                  total = Number(meta.total),
                  start_angle = model.startAngle,
                  end_angle = model.endAngle;
                //calculating Area
                let angle = end_angle - start_angle;
                let donutSectorArea =
                  (angle / 2) *
                  (model.outerRadius - model.innerRadius) *
                  (model.outerRadius + model.innerRadius);

                var percent = (value / total) * 100;
                if (
                  value != 0 &&
                  ((donutSectorArea && donutSectorArea > 1000) ||
                    (!donutSectorArea && percent > 1.5))
                ) {
                  if(this.isProgress){
                    return ""
                  }
                  let formatedValue = this.formatNumberWithCommas(value);
                  return (this.chartConfig?.dataLabel == 'both' && !this.isPercentage) ? `${formatedValue} (${percent.toFixed(2)}%)` : (this.chartConfig?.dataLabel == 'percentage' && !this.isPercentage) ? `${percent.toFixed(2)}%` : (this.chartConfig?.dataLabel == 'value') ? formatedValue : this.isPercentage ? `${value}%` : "";
                } else {
                  return "";
                }
              }
            },
          },
          legend: {
            position: "bottom",
            labels: {
              display: true,
              usePointStyle: true,
              pointStyle: "circle",
              padding: 15,
              boxWidth: 5,
              font: {
                size: 11
              }
            },
          },
          precision: 2,
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<"doughnut">) => {
                if ( !this.chartConfig?.tooltip || !["both", "percentage", "value"].includes( this.chartConfig?.tooltip || "" )){
                  return "";
                }
                if (tooltipItem.dataset && tooltipItem.parsed) {
                  const dataset = tooltipItem.dataset;
                  const total = dataset.data.reduce(
                    (acc: number, val: number) => Number(acc) + Number(val),
                    0
                  );

                  const currentValue = dataset.data[tooltipItem.dataIndex || 0];
                  const percentage = ((currentValue / total) * 100).toFixed(2);
                  let val = (this.chartConfig?.tooltip == 'both' && !this.isPercentage) ? `${currentValue} (${percentage}%)` : (this.chartConfig?.tooltip == 'percentage' && !this.isPercentage) ? `${percentage}%` : (this.chartConfig?.tooltip == 'value') ? currentValue : this.isPercentage ? `${currentValue}%` : "";
                  return `${tooltipItem.label} : ${val} `;
                }
                return "";
              },
            },
          },
        } as any,
        centerText: {
          // this custom before using it we need to register this key refer line no 100-103
          show: this.chartConfig.centerText,
          calculate: (values: number[]) => {
           let symbol:string = this.isPercentage ? '%' : '';
           if(this.isProgress){
             let total:number = values.reduce((p, c) => Number(p) + Number(c), 0);
             let percentage:number = (values[0] / total) * 100;
            return values[0] + ' ('+percentage.toFixed(2)+'%)'
          }
            return values.reduce((p, c) => {
              let totalNumber = Number(p) + Number(c);
              if(symbol != '%'){
                let formattedNumber = this.formatNumberWithCommas(totalNumber);
                return formattedNumber
              }else{
                return totalNumber
              }
            }) + symbol;
          },
          font: "bold 14px Arial",
          color: "black",
          title: {
            font: "11px Arial",
            color: "black",
            text: this.isPercentage ? "Total Percentage" : this.isProgress ? "" : "Total",
            yOffset: -20,
          },
        }
      } as any,
    });
  }

  formatNumberWithCommas(number:any) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  ngAfterViewInit(): void {
    if(this.dChartCanvas?.nativeElement){
    this.dChartContext = this.dChartCanvas.nativeElement.getContext("2d")!;
    this.createdChart();
    }
    // this.createChart()
  }
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
  getLabelForChart(data: any) {
    data?.forEach((el: any) => {
      if (el?.question_label) {
        let question = el?.question_label
          // .toLowerCase()
          // .replace(/(^|\s)\S/g, (L: any) => L.toUpperCase());
          // question = question.replace('Of', 'of');
        this.customLabel.push(question);
      }
    });
    this.showNoData = this.customData.every((element:any) => element === '0' || element === '0.00');
  }
  getDataForChart(data: any) {
    data?.forEach((el: any) => {
      if (el?.total_count) {
        el.total_count = el?.value_format === "percentage" ? parseFloat(el?.total_count).toFixed(2) : el?.total_count;
        this.customData.push(el?.total_count);
      }
    });
    this.isPercentage = data.some(
      (el: any) => el?.value_format === "percentage"
    );
    this.showNoData = this.customData.every((element:any) => element === '0' || element === '0.00');

    this.isProgress = data.some((el: any) => el?.name == 'number_of_factories_working_zdhc_mrsl_conformance')
  }

  convertIntoPercentage(data: any) {
    let sum = data.reduce((a: any, b: any) => a + b);
    let percentages = data.map((e: any) => ((e / sum) * 100).toFixed(2));
    return percentages;
  }
}
