import { Directive , ElementRef, HostListener} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[appNumberValidation]'
})
export class NumberValidationDirective {
  constructor(
    private snackBar:MatSnackBar,
    private el: ElementRef
    ) {}
    @HostListener('input', ['$event']) onInput(event: KeyboardEvent): void {
      const inputElement = this.el.nativeElement as HTMLInputElement;
      const currentValue = inputElement.value;
      const sanitizedValue = currentValue.replace(/[0-9]/g, ''); // Remove numeric characters
      if (currentValue !== sanitizedValue) {
        this.openSnackBar(['do not use Number !'] )
        inputElement.value = sanitizedValue; // Update the input value
        event.preventDefault(); // Prevent numeric characters from being entered
      }
    }
  openSnackBar(message:any, action = '') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: ['primary']
  });
}

}




