import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {}

  transform(untrustedHtml: string): any {
    return this._sanitizer.sanitize(SecurityContext.HTML, untrustedHtml);
  }

}
