// @ts-nocheck
import { Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { CommonServiceService } from '../services/common-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

Chart.register(...registerables);
import {
  MatDialog
} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
declare const downloadAsImage: any;
declare const downloadDashboardPDF:any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnChanges {
  // @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  // @ViewChild('dashboard') canvasDashboard: ElementRef<HTMLCanvasElement>;
  payloadFilters:any =''
  performanceBarChartJS: Chart<"bar", any, string> | undefined;
  ipsaBar:any = {
    "heading": "Country wise performance on IPSA social indicators",
    "values": [
        {
            "key": "Kenya",
            "value": "62"
        },
        {
            "key": "Ethiopia",
            "value": "72"
        },
        {
            "key": "Vietnam",
            "value": "54"
        }
    ]
};
  countryViewTableHead:any = [
    {
      thead: 'IP Unique ID'
    },
    {
      thead: 'Environmental'
    },
    {
      thead: 'Social'
    },
    {
      thead: 'Building Safety'
    },
  ];

  countryViewTableBody:any = [
    {
      ipUniqueName: 'IP Name 1',
      env: 2.5,
      social: 2,
      buildingSafe: 2
    },
    {
      ipUniqueName: 'IP Name 2',
      env: 2.5,
      social: 2,
      buildingSafe: 2
    },
    {
      ipUniqueName: 'IP Name 3',
      env: 2.5,
      social: 2,
      buildingSafe: 2
    },
    {
      ipUniqueName: 'IP Name 4',
      env: 2.5,
      social: 2,
      buildingSafe: 2
    }
  ]
  selectedIndex: number = 0;
  searchedValue: any = "";
  selectedFilters:any = {};
  ipListDetail:any = [];
  colors = [ "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf", "#aec7e8", "#ffbb78", "#98df8a", "#ff9896", "#c5b0d5", "#c49c94", "#f7b6d2", "#c7c7c7", "#dbdb8d", "#9edae5", "#fdd0a2", "#b3e2cd", "#fccde5", "#d9d9d9", "#e5d8bd", ];
  doughnutConfig = {
    tooltip: "both",
    dataLabel: "both",
    centerText: true
  }

  programView: boolean = true;
  countryView: boolean = false;
  ipView: boolean = false;
  factoryView: boolean = false;
  envButton: boolean = true;
  buildingBtn: boolean = false;
  socialBtn: boolean = false;
  performanceBarChartJS: Chart<"bar", any, string> | undefined;
  chartLabel: any = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'];
  chartData = [4, 3, 2, 8, 15, 7];
  initialData: any[] = [70, 110, 60];
  showPerformanceCard: boolean = true;
  showbelowTest: boolean = true;
  selectedTab: any = "hubMap";
  linechartGraph: Chart<"line", any, string> | undefined;
  countryViewEICards: any = [
    {
      "cardTitle": "Monthly Energy Consumption Per MT Output",
      "sourceImg": "../../../assets/dashboardMedia/countryViewEI1.svg"
    },
    {
      "cardTitle": "GHG Emissions Per MT Output By Year",
      "sourceImg": "../../../assets/dashboardMedia/countryViewEI2.svg"
    },
    {
      "cardTitle": "ZDHC Analyst in Waste Water",
      "sourceImg": "../../../assets/dashboardMedia/countryViewEI3.svg"
    },
    {
      "cardTitle": "ZDHC MRSL Conformance",
      "sourceImg": "../../../assets/dashboardMedia/countryViewEI4.svg"
    },
  ];
  showNoDataFound: boolean = false;
  mapLabel: any = [
    {
      "countryName": "Kenya",
      "workers": "7,657",
      "image": '../../../assets/dashboardMedia/Kenya.png'
    },
    {
      "countryName": "Ethiopia",
      "workers": "6,734",
      "image": '../../../assets/dashboardMedia/Ethiopia.png'
    },
    {
      "countryName": "Vietnam",
      "workers": "8,876",
      "image": '../../../assets/dashboardMedia/Vietnam.png'
    },
  ];

  category:any = [
    {
    "name" : "Country",
    "id" : 1
    },
    {
    "name" : "IPs",
    "id" : 2
    },
    {
    "name" : "Factory",
    "id" : 3
    },
  ]
  downloadResponse: any;
  showOthers: boolean = false;
  tokenCheck: any;
  prviewDashboardCard: string[] = ['investments_raised', 'male_workers_in_instep', 'female_workers_count','male_workers_count'];

  currentTabs = ['hubMap','programView', 'countryView', 'ipView', 'factoryView'];
  permissionDetails: any = JSON.parse(sessionStorage.getItem('userPermission') || null);
  socialImpact: any[] = [
    // { label: 'Overall', theme_name: 'General' },
    { label: 'Worker Welfare', theme_name: 'Worker welfare' },
    { label: 'Women Empowerment', theme_name: 'Women empowerment' },
    { label: 'OHS', theme_name: 'OHS', },

  ]
  environmentalImpact: any[] = [
    { label: 'Energy', theme_name: 'Energy' },
    { label: 'Water', theme_name: 'Water' },
    { label: 'Chemical', theme_name: 'Chemical' }

  ]

  downloadType: any[] = [
    { label: 'Charts PDF', type: 'pdf' },
    { label: 'Snapshot', type: 'png' },
    // { label: 'CSV', type: 'csv' },
    // { label: 'XLS', type: 'xls' }
  ]

  get thirdTabEntries() {
    if (this.selectedIndex == 1) return this.socialImpact;
    if (this.selectedIndex == 2) return this.environmentalImpact;
    return [];
  }
  sortSelection: string = "";
  selectedColumn: string = "";
  socailImpactSelectedIndex: any = 0;
  defaultImage = '../../../assets/images/box-bold.svg'
  customChartData: any = [];
  customChartLabel: any = []
  customChartTitle: string = ''
  selectedSectionTabs1: string = 'General';
  selectedSectionTabs2: string = 'Overview';
  selectedSectionTabs: string = 'Overview';
  maleIndicatorCount:number = 0;
  // prviewDashboardCard:string[] = ['investments_raised','female_workers_in_instep']
  countryAllData: any = [];
  selectDownloadOptions: string[] = [];
  isTabChange:boolean = false;
  femaleWorkforceCount:number = 0;
  maleFemaleRatio:any = "";
  allIPListData:any = [];
  dialogRef: any;
  public limit = 10;
  public skip = 0;
  public page: any = 1;
  userData:any = {};
  secondTab = ['Impact Summary','Social Impact','Environmental Impact'];
  orderSorting:string = '';
  pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
  resourceTab :string[] = ['hubMap', 'programview','countryview','ipview','factoryview'];
  isMonth: boolean =false;
  information: string = "Please note: The displayed data is dummy data - stay tuned for real data"
  isShowComm:boolean = true;
  constructor(private commonService: CommonServiceService, private snackBar:MatSnackBar,public dialog: MatDialog,private formBuilder: FormBuilder) {
    this.tokenCheck = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    console.log("tokenCheck", this.tokenCheck);

    this.userData = JSON.parse(sessionStorage.getItem('userdata')!);
    console.log("userData",this.userData);
   let currentTab: string = 'hubMap';
    // if(this.tokenCheck){
    // let getCurrentTabActive = this.getCurrentTabActiveMethod(this.pageAction[0]);
    // console.log("getCurrentTabActive",getCurrentTabActive);
    // currentTab = getCurrentTabActive?.model;
    // }

    this.getCountryData();
    this.timelineForm();
    this.headTabChange(currentTab, 0, true)

    this.isShowComm = environment.isShowCom
  }



  public pieChartLabels: string[] = ['Chrome', 'Safari', 'Firefox', 'Internet Explorer', 'Other'];
  public pieChartType: string = 'pie';

  isDisplay:boolean = true;
  isDownload:boolean = true

  filterConfig = {
    
  }

  dashboardForm: FormGroup;
  months = [
    {
      month: 'January',
      value: 'January'
    },
    {
      month: 'February',
      value: 'February'
    },
    {
      month: 'March',
      value: 'March'
    },
    {
      month: 'April',
      value: 'April'
    },
    {
      month: 'May',
      value: 'May'
    },
    {
      month: 'June',
      value: 'June'
    },
    {
      month: 'July',
      value: 'July'
    },
    {
      month: 'August',
      value: 'August'
    },
    {
      month: 'September',
      value: 'September'
    },
    {
      month: 'October',
      value: 'October'
    },
    {
      month: 'November',
      value: 'November'
    },
    {
      month: 'December',
      value: 'December'
    }
  ];

  // years = [
  //   {
  //     year: '2020-2021',
  //     value: '2020-2021'
  //   },
  //   {
  //     year: '2021-2022',
  //     value: '2021-2022'
  //   },
  //   {
  //     year: '2022-2023',
  //     value: '2022-2023'
  //   },
  //   {
  //     year: '2023-2024',
  //     value: '2023-2024'
  //   },
  // ];

  years = [
    {
      year: '2021',
      value: '2021'
    },
    {
      year: '2022',
      value: '2022'
    },
    {
      year: '2023',
      value: '2023'
    },
    {
      year: '2024',
      value: '2024'
    },
  ];

  timeBar:any[] = [];
  ngOnInit(): void {
    // this.ipListDataa();
    // this.getData();
    // this.getCountryData();
    // this.timelineForm();
    console.log("origin", window.location.origin);
    if (window.location.origin == "https://stgidhinstepui.dhwaniris.in" || window.location.origin == "http://localhost:4200") {
      this.showOthers = true;

    }
    

  }

  ngAfterViewInit() {
    // this.lineChart('test');
    // this.performanceBarChart(this.initialData, 'barchart');
    // // let test = document.getElementById('test');
    // // this.comparisonBarChartCVEI(test);
    // let test2 = document.getElementById('test2');
    // this.comparisonBarChartCVEI(test2)
    // this.lineChart('test');
    // this.comparisonBarChartCVBelow('barchart1')
  }

  res: any = {data:[]};
  pieResponse: any = {data:[]};
  donutResponse: any = {data:[]};
  barResponse: any = {data:[]};
  tableResponse: any = {data:[]};
  stackedResponse: any = {data:[]};
  timeResponse: any = {data:[]};
  groupBarResponse: any = {data:[]};
  ipsaResponse:any = {data:[]};
  chemicalTableResponse:any = {data:[]}
  isDisplay: boolean = false;
  get sectionCardsData() {
    return this.res?.data;
  }

  get cardsData() {
    // Commenting line number 389 for future reference
    // return this?.res?.data || [];

    
    /* the below code represents to update the Static value for the starting 3 cards */
    const data =  this?.res?.data || [];
    const customKeys = {
      'countries_with_ip': 3,
      'industrial_parks_in_projects': 12,
      'ta_factories_in_ips': 118
    };

    return data.map(item => {
      if(customKeys[item.short_key]) {
        item[item.short_key] = customKeys[item.short_key];
      }
      return item;
    });
  }

  get pieData() {
    return this?.pieResponse?.data || [];
  }

  get donutData() {
    return this?.donutResponse?.data || [];
  }

  get barData() {
    return this?.barResponse?.data || [];
  }
  get treeData() {
    return this?.treeResponse?.data || [];
  }

  get tableData() {
    return this?.tableResponse?.data || [];
  }

  get stackedData() {
    return this?.stackedResponse?.data || [];
  }

  get timeBarData() {
    return this?.timeResponse?.data || [];
  }

  get groupBarData() {
    return this?.groupBarResponse?.data || [];
  }

  get socialIpsaData() {
    return this?.ipsaResponse?.data[3] || {};
  }

  get environmentalIpsaData() {
    return this?.ipsaResponse?.data[4] || {};
  }

  get buildingIpsaData() {
    return this?.ipsaResponse?.data[5] || {};
  }

  get ipsIpsaData() {
    return this?.ipsaResponse?.data[1] || {};
  }

  get factoriesIpsaData() {
    return this?.ipsaResponse?.data[2] || {};
  }

  get averageIpsaData() {
    return this?.ipsaResponse?.data[0] || {};
  }

  get tableChemicalData() {
    return this?.chemicalTableResponse?.data || [];
  }
//   calculateRatio(num_1, num_2){
//     for(let num=num_2; num>1; num--) {
//         if((num_1 % num) == 0 && (num_2 % num) == 0) {
//             num_1=num_1/num;
//             num_2=num_2/num;
//         }
//     }
//     var ratio = num_1+":"+num_2;
//     return ratio;
// }

  getData(ids:any=[], isAuto:boolean = false) {
    this.showCountry =false;
    this.showIPss = false;
    this.factory = false;
    this.isMonth = ids?.isMonth;
    console.log(ids,"check12selected", this.selectedIndex, '###',this.currentSeletedSubTab);
    let filteredTabs = {programview: [],countryview:[{country_id:'countryId'}],ipview: [{country_id:'countryId'},{industrialpark_id:'ipViewId'}],ipview: [{country_id:'countryId'},{industrialpark_id:'ipViewId'}],factoryview:[{country_id:'countryId'},{industrialpark_id:'ipViewId'},{factory_id:'factoryId'}]};
    let payload :string = `?from=${ids?.from  ? ids?.from  : "" }&to=${ids?.to ? ids?.to : "" }&is_month=${ids?.isMonth ? true : false}`;

    if(this.selectedTab == 'hubMap')
    return true;

    if(filteredTabs[this.selectedTab].length){
      filteredTabs[this.selectedTab].forEach(element => {
        for(let key in element){
          let existKey = element[key];
          if(ids[existKey]?.length){
            if(key.includes('country_id'))
            this.showCountry = true;

            if(key.includes('industrialpark_id')){
              this.showCountry = true;
            this.showIPss = true;
            }
            if(key.includes('factory_id')){
              this.showCountry = true;
            this.showIPss = true;
            this.factory = true;
            }
            payload += `&${key}=${ids[existKey]}`;
          }
        }
      });
    }
    // if(ids?.factoryId?.length){
    //   payload +=`?country_id=${ids.countryId}&industrialpark_id=${ids.ipViewId}&factory_id=${ids.factoryId}&from=${ids?.from  ? ids?.from  : "" }&to=${ids?.to ? ids?.to : "" }`      
    // }
    // else if(ids?.ipViewId?.length){
    //   payload += `?country_id=${ids.countryId}&industrialpark_id=${ids.ipViewId}&from=${ids?.from  ? ids?.from  : "" }&to=${ids?.to ? ids?.to : "" }`
    // }
    // else if(ids?.countryId?.length){
    //   payload += `?country_id=${ids.countryId}&from=${ids?.from  ? ids?.from  : "" }&to=${ids?.to ? ids?.to : "" }`
    // }
    // else if((ids?.to || ids?.from) && !ids?.ipViewId && !ids?.factoryId && !ids?.countryId ){
    //   payload +=`?from=${ids?.from  ? ids?.from  : "" }&to=${ids?.to ? ids?.to : "" }`
    // }
   
  
    setTimeout(()=>{
      console.log("params123",payload)
    },1000)
    
    // let apiEndPoint = { 'card': 'res', 'pie-chart': 'pieResponse', 'donut-chart': 'donutResponse', 'bar-chart': 'barResponse', 'tree-map': 'treeResponse', 'table': 'tableResponse', 'stacked-bar-chart': 'stackedResponse', 'timeline': 'timeResponse', 'group-bar-chart': 'groupBarResponse' }
    // 'stacked-bar-chart': 'stackedResponse'

    let tabWiseApiEndPoint = [{ 'card': 'res', 'pie-chart': 'pieResponse','group-bar-chart': 'groupBarResponse'},{ 'card': 'res', 'pie-chart': 'pieResponse', 'timeline': 'timeResponse', 'group-bar-chart': 'groupBarResponse' },{ 'pie-chart': 'pieResponse','donut-chart': 'donutResponse','timeline': 'timeResponse', 'group-bar-chart': 'groupBarResponse' }]
    let apiEndPoint = tabWiseApiEndPoint[this.selectedIndex];
    let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
    console.log("mytoken", token,apiEndPoint);
    if(this.selectedIndex > 2){
        if(this.selectedIndex == 3 && this.selectedTab == 'countryview'){
          this.tableHead = [{thead : 'S.No.'},{thead : 'IP Name',key:'name'},{thead : 'Country Name',key:'country.name'},{thead : 'Contact'}];
          this.getIPlist(this.page);
        }
    
        if((this.selectedIndex == 4 && this.selectedTab == 'countryview') || (this.selectedIndex == 3 && this.selectedTab == 'ipview')){
          this.tableHead = [{thead : 'S.No.'},{thead : 'Factory Name',key:'name'},{thead : 'IP Name',key:'industrialpark.name'},{thead : 'Country Name',key:'industrialpark.country.name'},{thead : 'Contact'}];
          this.getFactoryListData(this.page);
        }
      return true;
    }

    if(['programview/impact-summary'].includes(this.apiRoute)){
      apiEndPoint = {...apiEndPoint, 'ipsa': 'ipsaResponse'}
    }
    if(['ipview/environmental-impact'].includes(this.apiRoute)){
      apiEndPoint = {...apiEndPoint, 'table': 'chemicalTableResponse'}
    }

    // if(isAuto){
    //   apiEndPoint = {'ipsa': 'ipsaResponse'}
    // }
    // return true;
    let apiRoute = this.apiRoute;
    for (let key in apiEndPoint) {
      let responseData = apiEndPoint[key];
      console.log("performance", this.apiRoute, key, payload)
      
      // if(isAuto)
      // apiRoute = 'programview/impact-summary';
      this.commonService.details(apiRoute, key,payload).subscribe(res => {
        console.log(res,"res>>>>>")
        if (key == 'card' && res?.data && res.data.length) {
          let maleWorkerCountInstep = res?.data.find((el: any) => el?.short_key === 'male_workers_in_instep');
          this.maleIndicatorCount = maleWorkerCountInstep ? maleWorkerCountInstep['male_workers_in_instep'] : 0;

          let femaleWorkForce = res?.data.find((el: any) => el?.short_key === 'female_workers_count');
          this.femaleWorkforceCount = femaleWorkForce ? femaleWorkForce['female_workers_count'] : 0;
          let maleWorkerCount = res?.data.find((el: any) => el?.short_key === 'worker_count');
          this.maleFemaleRatio = maleWorkerCount ? maleWorkerCount['worker_count'] : 0;
          // res?.data = res?.data.map((el: any) => {
          //   if (el.short_key === 'male_workers_in_instep') {
          //     el['male_workers_in_instep'] = el['male_workers_in_instep'];
          //     el['female_workers_in_instep'] = femaleWorkerCount['female_workers_in_instep'];
          //   }
          //   if (el.short_key === 'male_workers_count') {
          //     el['male_workers_count'] = el['male_workers_count'];
          //     el['female_workers_count'] = femaleWorkForce['female_workers_count'];
          //   }
          //   return el
          // })
        }
        if (key == 'stacked-bar-chart') {
          let response = { data: [] };
          res.data.forEach((data) => {
            if (data?.theme_id == 3) {
              let details = [];
              data.details.forEach((detail) => {
                if (detail.hasOwnProperty('short_key_men')) {
                  detail.short_key_men.forEach(element => {
                    element.name = element.name.replace('_male', '')
                  });
                }
                details.push(detail);
                let name = detail.question_label.includes('female') ? 'Female ' + data.name : 'Male ' + data.name;
                // data.name = name;

                let detailData = { name: name, theme_id: data.theme_id, theme_name: data.theme_name, details: [detail] }
                response.data.push(detailData)
              })
              res = response;
            }
          })
        }

        if (key == 'timeline') {
          let indexDeletion = -1;
          res.data.forEach((data, index) => {
            if (data?.theme_id == 6) {
              let totalEnergy = this.calculateTotalEnergyByYear(data.short_key)
              indexDeletion = index;
              let nonRenewEnergy: any = ['coal', 'diesel', 'petrol', 'fuel_oil', 'natural_gas', 'lpg', 'liquified_natural_gas', 'purchased_non_renewable_electricity']
              let details = [];
              let non_short_key = data.short_key.filter((shortKey: any) => nonRenewEnergy.includes(shortKey.name));
              let renew_short_key = data.short_key.filter((shortKey: any) => !nonRenewEnergy.includes(shortKey.name));
              let renewData = { ...data, short_key: renew_short_key, name: 'Renewable ' + data.name };
              let nonRenewData = { ...data, short_key: non_short_key, name: 'Non - Renewable ' + data.name };
              let totalEnergyData = {...data, short_key:[{name: 'Total Energy',question_label: "Total Energy",value_format : "kwh",values:totalEnergy}],name: 'Total '+ data.name}
              res.data.push(nonRenewData);
              res.data.push(renewData);
              res.data.push(totalEnergyData);
            }
          });
          if(indexDeletion > -1)
          res.data.splice(indexDeletion,1)
        }
        if (key == 'group-bar-chart') {
          res.data.forEach((data:any) => {
            if (data?.theme_id == 7) {
              // let totalEnergy = this.calculateTotalEnergyByYear(data.short_key)
              
              if(['Wastewater Reused and Discharged (m<sup>3</sup>)','Wastewater Treated (m<sup>3</sup>)'].includes(data.name)){
              data.details.forEach((waterGraph:any) => {
                let waterDetails = {...data, name: waterGraph.question_label + ' (m<sup>3</sup>)', details: [waterGraph]};
                res.data.push(waterDetails)
              })
            }
              
            }
          });

          res.data = res.data.filter((waterData:any) => !['Wastewater Reused and Discharged (m<sup>3</sup>)','Wastewater Treated (m<sup>3</sup>)'].includes(waterData.name))
          
        }
        this[responseData] = res;

        if(responseData == 'ipsaResponse'){
          setTimeout(() => {
            this.performanceCardBtn('social', 'barchart');
          },100)
          
        }
        console.log('response from common api', key, res,responseData, this[responseData]);
      },((error) => {
        this[responseData] = {data:[]};
      }))
    }

  }
  setPage(event: any){
    // this.selectedColumn = "";
    this.page = event.pageIndex + 1;
    this.skip = (event.pageIndex + 1) * this.limit - this.limit;
    console.log('limit',event.pageSize,event, this.skip)
    this.limit = event.pageSize;
    if(this.selectedIndex == 3 && this.selectedTab == 'countryview'){
      this.getIPlist(event.pageIndex + 1, '', '', this.orderSorting,this.selectedColumn);
    }else if((this.selectedIndex == 4 && this.selectedTab == 'countryview') || (this.selectedIndex == 3 && this.selectedTab == 'ipview')){
      this.getFactoryListData(event.pageIndex + 1, '', this.orderSorting,this.selectedColumn);
    }

    //event.pageIndex + 1
  }

  performanceBarChart(datasetArray: any, canvasID: any) {
    if (this.performanceBarChartJS) {
      this.performanceBarChartJS.destroy();
    }
    let labels = datasetArray.values.map((label:any) => label.key);
    let countData = datasetArray.values.map((label:any) => label.value);

    console.log("dataperformance", labels, countData, datasetArray, canvasID)
    this.performanceBarChartJS = new Chart(canvasID, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: '',
          barThickness: 20,
          data: countData,
          backgroundColor: [
            '#01665e',
            '#01665e',
            '#01665e',
          ],
          borderColor: [
            '#01665e',
            '#01665e',
            '#01665e',
          ],
          borderWidth: 1,
        //  borderRadius = 20
        }]
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
            text: ""
          },
          datalabels: {
            display: true,
            color: "#fff",
            anchor: "center", // Adjust the anchor position as needed
            align: "center", // Adjust the align as needed
            offset: 0,
            // rotation: -45, //rotating text
            font: {
              size: 12,
              weight: "bold",
            },
            formatter: (value, context) => {
              // You can customize the label content here
              return value.toLocaleString();
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Value'
            },
            grid: {
              display: true,
            },
            // border: {
            //   dash: [2,4],
            // },
          },
          y: {
            title: {
              display: true,
              text: 'Country'
            },
            grid: {
              display: false
            },
            beginAtZero: true
          }
        }
      }
    });
  }

  lineChart(id: any = '', dataset: any = [], labels: any = []) {
    this.linechartGraph = new Chart(id, {
      type: 'line',
      data: {
        labels: labels.length ? labels : ['2019', '2020', '2021', '2022', '2023'],
        datasets: [
          {
            label: 'Numbers of Votes',
            data: dataset,
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          },
          {
            label: 'Numbers of Votes',
            data: [0.9, 0.8, 0.5, 1.3, 1.2],
            borderColor: 'rgba(51, 236, 103, 1)',
            borderWidth: 1
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: true,
            },
            //  border: {
            //    dash: [2,4],
            //  }, 
            beginAtZero: true
          }
        }
      }
    })
  }



  // Country View -> Environmental Impact
  comparisonBarChartCVEI(id: any = '', dataset: any = []) {
    const myChart = new Chart(id, {
      type: 'bar',
      data: {
        labels: ['2019', '2020', '2021', '2022', '2023'],
        datasets: [
          {
            label: '',
            type: 'line',
            barThickness: 20,
            data: [35, 35, 35, 35, 35],
            backgroundColor: [
              'rgb(69,138,173)',
              'rgb(69,138,173)',
              'rgb(69,138,173)',
              'rgb(69,138,173)',
              'rgb(69,138,173)',
            ],
            borderColor: [
              'rgb(69,138,173)',
              'rgb(69,138,173)',
              'rgb(69,138,173)',
              'rgb(69,138,173)',
              'rgb(69,138,173)',
            ],
            borderWidth: 1,
          },
          {
            label: '',
            type: 'line',
            barThickness: 20,
            data: [15, 15, 15, 15, 15],
            backgroundColor: [
              'rgb(150,255,145)',
              'rgb(150,255,145)',
              'rgb(150,255,145)',
              'rgb(150,255,145)',
              'rgb(150,255,145)',

            ],
            borderColor: [
              'rgb(150,255,145)',
              'rgb(150,255,145)',
              'rgb(150,255,145)',
              'rgb(150,255,145)',
              'rgb(150,255,145)',
            ],
            borderWidth: 1,
          },
          {
            label: '',
            barThickness: 20,
            data: dataset,
            backgroundColor: [
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',

            ],
            borderColor: [
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)'
            ],
            borderWidth: 1,
          },
          {
            label: '',
            barThickness: 20,
            data: [24, 25, 25, 25, 23],
            backgroundColor: [
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
            ],
            borderColor: [
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: ""
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: true,
            },
            //  border: {
            //    dash: [2,4],
            //  }, 
            beginAtZero: true,
            borderDash: [5, 5]
          }
        }
      }
    });
  }

  comparisonBarChartCVBelow(id: any = '', dataset: any = []) {
    const myChart = new Chart(id, {
      type: 'bar',
      data: {
        labels: ['2019', '2020', '2021', '2022', '2023'],
        datasets: [
          {
            label: '',
            type: 'line',
            barThickness: 20,
            data: [35, 35, 35, 35, 35],
            backgroundColor: [
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',

            ],
            borderColor: [
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',
              'rgb(127,133,219)',

            ],
            borderWidth: 1,
          },
          {
            label: '',
            barThickness: 20,
            data: dataset,
            backgroundColor: [
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',

            ],
            borderColor: [
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)',
              'rgb(61,178,46)'
            ],
            borderWidth: 1,
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
            text: ""
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: true,
            },
            //  border: {
            //    dash: [2,4],
            //  }, 
            beginAtZero: true
          }
        }
      }
    });
  }

  ngOnChanges() {
    // this.performanceBarChart(this.initialData, 'barchart');
  }

  get apiRoute() {
    let subRoute = {
      0: 'impact-summary',
      1: 'social-impact',
      2: 'environmental-impact'
    }

    if(this.selectedTab != 'programview')
    subRoute[0] = 'performance-impact';


    return [this.selectedTab, (subRoute[this.selectedIndex] ?? '')].join('/');
  }

  showExtraTab:boolean = false;
  showIPTab:boolean = false;
  showHubMap:boolean = false;

  headTabChange(dataType: any, index = 0, isAuto:boolean = false) {
    console.log("performance", dataType, this.payloadFilters)
    this.showExtraTab = false;
    this.showIPTab = false;
    this.showHubMap = false;

    if(dataType == 'hubMap'){
      this.showHubMap = true;
    }

    if(dataType == 'countryview'){
      this.showExtraTab = true;
    }
    if(dataType == 'ipview'){
      this.showExtraTab = false;
      this.showIPTab = true;
    }
    this.selectedTab = dataType;
    this.selectedIndex = 0;
    this.selectedSectionTabs = '';
    // this.performanceBarChart();
    // this.performanceCardBtn('enviornmental', 'barchart');
    // this.getData();
    let event = {
      index: index
    }
    if(dataType == 'programview'){
      this.tabWiseData(event);
  }else{
    this.tabWiseData(event, true);
  }

 
    // this.tabWiseData(event);
  }

  performanceCardBtn(buttonType: string, canvasID: any) {
    switch (buttonType) {
      case 'enviornmental':
        this.envButton = true;
        this.buildingBtn = false;
        this.socialBtn = false;
        let graphData1: any = this.environmentalIpsaData;
        if(this.environmentalIpsaData.hasOwnProperty('heading'))
        this.performanceBarChart(graphData1, canvasID);
        break;

      case 'building':
        this.envButton = false;
        this.buildingBtn = true;
        this.socialBtn = false;
        let graphData2: any = this.buildingIpsaData;
        if(this.buildingIpsaData.hasOwnProperty('heading'))
        this.performanceBarChart(graphData2, canvasID);
        break;

      case 'social':
        this.envButton = false;
        this.buildingBtn = false;
        this.socialBtn = true;
        let graphData3 = this.socialIpsaData;
        if(this.socialIpsaData.hasOwnProperty('heading'))
        this.performanceBarChart(graphData3, canvasID);
        break;
    }
  }

  currentSeletedSubTab:string = "";
  tabWiseData(event: any, isAuto: boolean = false) {
    this.selectedColumn = '';
    this.currentSeletedSubTab = event?.tab?.textLabel;
    // this.showCountry = false;
    // this.showIPs = false;
    // this.factory = false;
    // console.log("this.performanceBarChartJS", this.performanceBarChartJS);
    // console.log("selectedIndex",event);
    this.selectedIndex = event.index;
    // this.isDownload = !(['IP list','Factory list'].includes(event?.tab?.textLabel))
    // console.log('selectedIndex', event?.tab?.textLabel,isDownload);
    if(['Impact Summary','Social Impact','Environmental Impact'].includes(event?.tab?.textLabel)){
      // this.dashboardForm.reset();
    }
    if(['IP list','Factory list'].includes(event?.tab?.textLabel)){
      this.isDownload = false;
      this.orderSorting = "ASC"
    }else{
      this.isDownload = true;
    }
    
    this.selectedSectionTabs = this.selectedIndex == 0 ? 'Overview' : ['Worker welfare', 'Energy'][this.selectedIndex - 1];
    // this.isTabChange = !this.isTabChange;
    this.getdataOnTab(isAuto);
    this.performanceCardBtn('social', 'barchart');
    console.log('selectedIndex',this.selectedIndex, this.selectedSectionTabs, event?.tab?.textLabel, this.isDownload);
    // if(event.index < 3)
    // this.performanceBarChart();
    // this.getData(this.payloadFilters);
    // this.timeBarDetails();
    
  }


  allResponseData:any = [];
  totalDataCount:number = 0;
  getIPlist(page:any, searchedValue:string = '', id:any = "",sortingOrder:string='',culomnName:string=''){
    console.log("ipDashboard", this.payloadFilters)
    id = this.payloadFilters?.countryId?.length ? this.payloadFilters.countryId.join(',') : '';
    this.commonService.ipDashboardDetails(page, searchedValue, id,sortingOrder,culomnName).subscribe(res => {
      if(res.success){
        this.allResponseData = res.data;
        this.totalDataCount = res.total;
        console.log("allIPListData",this.allResponseData);
      }
    }, error => console.log(error)
    )
  }

  getFactoryListData(page:any, searchedValue = '',sortingOrder:string='',columnName:string = ''){
    let countryId:string = this.payloadFilters?.countryId?.length ? this.payloadFilters?.countryId.join(',') : '';
    let ipId:string = this.payloadFilters?.ipViewId?.length ? this.payloadFilters?.ipViewId.join(',') : '';
    

    this.commonService.factoryDashboardDetails(page, searchedValue,countryId,ipId,sortingOrder,columnName).subscribe(res => {
      this.allResponseData = res.data;
      this.totalDataCount = res.total;
      console.log("allIPListData",this.allResponseData);
    })
  }

  onKeyDownEvent() { 
    if(this.searchedValue){
      if(this.selectedIndex == 3 && this.selectedTab == 'countryview'){
        this.getIPlist(this.page, this.searchedValue);
      }
  
      if((this.selectedIndex == 4 && this.selectedTab == 'countryview') || (this.selectedIndex == 3 && this.selectedTab == 'ipview')){
        this.getFactoryListData(this.page, this.searchedValue);
      }
    }else{
      this.openSnackBar(['Please enter any value'], 3000);
    }
  }

  clearSearch() {
    this.searchedValue = "";
    if(this.selectedIndex == 3 && this.selectedTab == 'countryview'){
      this.getIPlist(1);
    }

    if((this.selectedIndex == 4 && this.selectedTab == 'countryview') || (this.selectedIndex == 3 && this.selectedTab == 'ipview')){
      this.getFactoryListData(1);
    }
    
  }
  getCurrentTabActive(currentTab: string): void {
    this.dashboardPageAction = this.dashboardPageAction.map((el: any) => {
      if (el?.selectedTabs === currentTab) {
        el.isChecked = true;
      }
      else {
        el.isChecked = false;
      }
      return el;
    })
  }

  socailImpactTabActive(data: any, currentTab: string) {
    this.socialImpact = this.socialImpact.map((el: any) => {
      el.isChecked = el?.theme_name === currentTab;
      return el;
    })

  }
  tabChangesDataForSocailIpmacts(theme_name) {
    this.selectedSectionTabs = 'General';
    return;
    this.selectedSectionTabs = type;
    this.socailImpactSelectedIndex = index;
    this.socailImpactTabActive('', type);
  }
  tabChangesDataForEnvironmentalImpact(index: number = 0, type: string = '') {
    this.selectedSectionTabs = type;
    this.environmentalImpactSelectedIndex = index;
    this.environmentalImpactTabActiveMethod('', type);
  }

  environmentalImpactTabActiveMethod(data: any, currentTab: string) {
    console.log("cu", this.environmentalImpact, currentTab)
    this.environmentalImpact = this.environmentalImpact.map((el: any) => {
      el.isChecked = el?.theme_name === currentTab;
      return el;
    })

  }
// filter works start 
reset(event:any){
  this.showCountry = false;
  this.showIPss = false;
  this.factory = false;
  this.payloadFilters = {};
  this.getCountryData();
  this.getData(this.payloadFilters);
}

showCountry:boolean = false;
showIPss:boolean = false;
factory:boolean = false;

checkedStatus(event:any){
  console.log("eventCOuntrychip", event)
  // switch (event.type) {
  //   case 'country':
  //     this.showCountry = event?.status;
  //     break;
  //   case 'IPs':
  //     this.showIPss = event?.status;
  //     break;
  //   case 'factory':
  //     this.factory = event?.status;
  //     break;
  
  //   default:
  //     break;
  // }
  console.log("Myevent",event);

}

// permission


activeSubTab(selectedTabName: string): boolean {
  // console.log("sdghsj",!!this.permissionDetails)
  if (!this.permissionDetails?.length) {
    return false;
  }

  const moduleJson = this.permissionDetails[0]?.authenicationJson.find((el: any) => el?.model === selectedTabName);

  if (!moduleJson) {
    return false;
  }

  // console.log("module", moduleJson, (moduleJson?.isAction || moduleJson?.is_checked || moduleJson?.authenication?.some((el: any) => el?.isAction)));

  return moduleJson?.isAction || moduleJson?.is_checked || moduleJson?.authentication?.some((el: any) => el?.isAction);
}

isShowContact(selectedTabName: string): boolean {
  let model = {'IP list': 'ip','Factory list': 'factory'};
  let endPoint:string = model[this.currentSeletedSubTab];
  // console.log("sdghsj",!!this.permissionDetails)
  if (!this.permissionDetails?.length) {
    return false;
  }

  const moduleJson = this.permissionDetails[0]?.authenicationJson.find((el: any) => el?.model === selectedTabName);

  if (!moduleJson) {
    return false;
  }

  // console.log("module", moduleJson, (moduleJson?.isAction || moduleJson?.is_checked || moduleJson?.authenication?.some((el: any) => el?.isAction)));

  return moduleJson?.authentication?.some((el: any) => el?.isAction && el.endpoint == endPoint);
}


getCountryData(){
  let countryId = this.userData?.country_id;
  this.commonService.countryData(countryId).subscribe((res:any) => {
    this.countryAllData = res.data;
    console.log("countyyyy",this.countryAllData);
    
  },(ereor)=>{
    console.log(err);
    this.countryAllData=[];
  })
}

ipListDataa(){
  this.commonService.ipList().subscribe((res:any)=>{
    this.ipListDetail = res.data
    console.log(res);
  })
}
isCheckedCountry(event:any){
  console.log("eventCOuntrychip",event);
  let data = event.filter(ele => ele.is_checked);
  if(!data.length){
    this.showCountry = false;
    this.showIPss = false;
    this.factory = false;
  }
}

removedFactoryChip:boolean = false;
removedIPchip:boolean = false;
removedCountryChip:boolean = false;

removeChip(cat:any){
  console.log("this.selectedFilters", this.selectedFilters);
  
  switch (cat) {
    case 'country':
      this.showCountry = false;
      this.showIPss = false;
      this.factory = false;
      this.removedCountryChip = true;
      this.countryAllData.map(ele => ele.is_checked = false);
      // this.reset();
      break;
    case 'ips':
    this.showIPss = false;
    this.factory = false;
    this.removedIPchip = true;
    this.removedFactoryChip = true;
      break;
    case 'factory':
    this.factory = false;
    this.removedFactoryChip = true;
      break;
  
    default:
      break;
  }
}

// download 
choseDownloadType(type: string): void {
  console.log("type download",type)
  if (this.isSelected(type)) {
    this.selectDownloadOptions = this.downloadType.filter(item => item?.type !== type);
  } else {
    this.selectDownloadOptions.push(type);
  }
  console.log("selectDownloadOptions",this.selectDownloadOptions)
}

isSelected(type: string): boolean {
  return this.downloadType.includes(type);
}

downloadPNG(){}
// downloadXLS(){
//   dataToDownload = [
//     { Name: 'John', Age: 30, City: 'New York' },
//     { Name: 'Jane', Age: 25, City: 'San Francisco' },
    
//   ];
//   this.commonService.downloadExcelFormat(dataToDownload,'xlsFormat')
// }


//  this is api call based on xls

// downloadXLS(): Promise<void> {
//   return this.commonService.downloadDashboardXLS().toPromise()
//     .then((res: any) => {
//       // this.downloadResponse = res;
//       let xlsData:any = res;
//       let fileName: string = 'ProgramImpactSummaryForXLS.csv';
//       console.log("downloadResponse", xlsData);
//       this.commonService.downloadExcelFile(xlsData, fileName);
//       console.log("dataaa", xlsData);
//     })
//     .catch((error: any) => {
//       console.log("errorDashboard", error);
//       if (error?.error?.text) {
//         let xlsData:any  = error?.error?.text;
//         let fileName: string = 'ProgramImpactSummaryForXLS.csv';
//         console.log("xlsData", xlsData);
//         this.commonService.downloadExcelFile(xlsData, fileName);
//       }
//     });
// }


downloadXLS(): Promise<void> {
  const dataToDownload = [
    { Name: 'John', Age: 30, City: 'New York' },
    { Name: 'Jane', Age: 25, City: 'San Francisco' },
  ];

  return this.commonService.downloadExcelFile(dataToDownload, 'xlsFormat')
    .toPromise()
    .then(() => this.openSnackBar(['Xls Formate download'],'',''))
    .catch(error => {console.error('Error during XLS download:', error)
    this.openSnackBar(['Xls Formate download Failed'],'','')
  });
}

downloadCSV(): Promise<void> {
  return this.commonService.downloadDashboardCSV().toPromise()
    .then((res: any) => {
      this.downloadResponse = res;
      let fileName: string = 'ProgramImpactSummary.csv';
      console.log("downloadResponse", this.downloadResponse);
      this.commonService.downloadCSVFile(this.downloadResponse, fileName);
      console.log("dataaa", this.downloadResponse);
    })
    .catch((error: any) => {
      console.log("errorDashboard", error);
      if (error?.error?.text) {
        this.downloadResponse = error?.error?.text;
        let fileName: string = 'ProgramImpactSummary.csv';
        console.log("downloadResponse", this.downloadResponse);
        this.commonService.downloadCSVFile(this.downloadResponse, fileName);
      }
    });
}

downloadPDF(): Promise<void> {
  return this.commonService.downloadDashboardPDF().toPromise()
    .then((res: any) => {
      let pdfData:any = res;
      let fileName = 'ProgramImpactSummary';
        console.log("pdfData", pdfData);
        this.commonService.downloadPDFFile(pdfData, fileName);
      console.log("dataaa", pdfData);
    })
    .catch((error: any) => {
      console.log("errorDashboard", error);
      if (error?.error?.text) {
        let pdfData:any = error?.error?.text;
        // let fileName: string = 'ProgramImpactSummary.pdf';
        let fileName = 'ProgramImpactSummary';
        console.log("pdfData", pdfData);
        this.commonService.downloadPDFFile(pdfData, fileName);
      }
    });
}

downloadDashboardPDF() {
  // var dashboard = document.getElementById('dashboard');
  console.log("pdf")
  let pdf = new (window as any).jspdf.jsPDF();
  console.log("pdfcanvas")
  let charts = document.querySelectorAll('#dashboard canvas') as HTMLCanvasElement;
  console.log("pdfcanvas", charts.toString())
  if(!charts.length){
    this.openSnackBar(['Sorry, There is no chart to download!!!']);
    return true;
  }
  let yOffset = 10; // Initial Y offset
console.log("pdf")
  charts.forEach(function (chartCanvas) {
  //   const ctx = chartCanvas.getContext('2d');
  // ctx.fillStyle = 'white'; // Set a background color
  // ctx.fillRect(0, 0, chartCanvas.width, chartCanvas.height); 
    let title = chartCanvas.parentNode.parentNode.parentNode.getElementsByClassName('mat-card-title')[0].textContent;
    console.log("pdfafter")
    // Add title
    pdf.text(20, yOffset + 10, title);

    // Add chart image as JPEG to PDF
    let imgData = chartCanvas.toDataURL('image/jpeg', 1.0);
    pdf.addImage(imgData, 'JPEG', 15, yOffset + 20, 180, 120); // Change dimensions as needed
    console.log("pdfafterImage")
    yOffset += 140; // Increase Y offset for next chart
    if (yOffset >= 250) {
      // Add a new page if the space is not enough
      pdf.addPage();
      yOffset = 10;
    }
  });
  console.log("pdfafterOutside")
  pdf.save(selectedSectionTabs+'_dashboard.pdf');
  console.log("pdfafterOutsidesave")
}

downloadAsImages() {
  // var contentToConvert = document.getElementById('dashboard')
  this.canvasDashboard.nativeElement.willReadFrequently = true;
  new (window as any).html2canvas(this.canvasDashboard.nativeElement).then(function(canvas) {
      var image = canvas.toDataURL('image/png');

      var link = document.createElement('a');
      link.href = image;
      link.download = selectedSectionTabs+'_dashboard.png';
      link.click();
  });
}


downloadDashboardImage(type:string) {
  console.log("pdf", this.selectedSectionTabs,this.selectedIndex,this.selectedTab);
  if(!this.selectedTab && !this.selectedIndex && !this.selectedSectionTabs)
  return true;
  let parentTab = this.selectedTab ? this.selectedTab.split('view')[0].charAt(0).toUpperCase() + this.selectedTab.split('view')[0].slice(1) : '';
  let fileName = parentTab+'_'+this.secondTab[this.selectedIndex]+'_'+this.selectedSectionTabs;
  if(type == 'png'){
  downloadAsImage(fileName);
  return true;
  }
  downloadDashboardPDF(fileName);
  return true;
}



// download formatting
downloadFormatter(): void {
  if (!this.selectDownloadOptions?.length) {
    this.openSnackBar(['Please choose at least one option for downloading format!']);
    return;
  }
  console.log("csv",this.selectDownloadOptions)
  let uniqueArray = this.selectDownloadOptions.filter((value, index, self) => self.indexOf(value) === index);
  console.log(uniqueArray)
  const downloadPromises: Promise<void>[] = [];

  if (uniqueArray.includes('csv')) {
    downloadPromises.push(this.downloadCSV());
  }

  if (uniqueArray.includes('pdf')) {
    downloadPromises.push(this.downloadPDF());
  }

  if (uniqueArray.includes('png')) {
    downloadPromises.push(this.downloadPNG());
  }

  if (uniqueArray.includes('xls')) {
    downloadPromises.push(this.downloadXLS());
  }

  console.log("downloadPromises",downloadPromises)


  Promise.all(downloadPromises)
    .then(() => console.log('All downloads completed successfully',downloadPromises))
    .catch(error => console.error('Error during downloads:', error));
}


downloadPNG(): Promise<void> {
  // Implement the download logic for PNG and return a Promise
  console.log('Downloading PNG');
  return new Promise<void>((resolve, reject) => {
    // Your PNG download logic here
    setTimeout(() => {
      console.log('PNG download complete');
      resolve();
    }, 1000);
  });
}

subSubChildTab(item:any,index:number){
  console.log("theme", item)
  this.selectedSectionTabs = item?.theme_name;
  this.socailImpactSelectedIndex = index;
  // this.isTabChange = !this.isTabChange;
}
openSnackBar(message:any, action = '',className='parmary') {
  this.snackBar.open(message, action, {
  duration: 3000,
  panelClass: parmary
});
}

tableHead:any = [];

factoryListData = [
  {
    sNo : 1,
    factoryName : 'Factory A',
    factoryType : 'Type A',
    factoryOperation : 'Textile',
    contactIcon : '../../../assets/images/call-icon.svg'
  },
  {
    sNo : 2,
    factoryName : 'Factory B',
    factoryType : 'Type B',
    factoryOperation : 'Textile',
    contactIcon : '../../../assets/images/call-icon.svg'
  },
  {
    sNo : 3,
    factoryName : 'Factory C',
    factoryType : 'Type C',
    factoryOperation : 'Textile',
    contactIcon : '../../../assets/images/call-icon.svg'
  },
  {
    sNo : 4,
    factoryName : 'Factory D',
    factoryType : 'Type D',
    factoryOperation : 'Textile',
    contactIcon : '../../../assets/images/call-icon.svg'
  },
  {
    sNo : 5,
    factoryName : 'Factory E',
    factoryType : 'Type E',
    factoryOperation : 'Textile',
    contactIcon : '../../../assets/images/call-icon.svg'
  }
]

openContactDialog(item) {
  console.log(item);
  
this.dialogRef =  this.dialog.open(ContactDialogComponent, {
    width: '500px',
    panelClass: 'contactDialog',
    data: {
      id: item.id ? item.id : 1,
      lineItemName: item.name,
      formType: this.currentSeletedSubTab
    },
  });

  this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    console.log("after")
  })
}
timeBarDetails(){
  this.commonService.timeLine().subscribe((res:any)=>{
    this.timeBar = res.data;
  },(error:any)=>{
    console.log(error);
    this.timeBar = [];
  })
}
timelineForm(){
  this.dashboardForm = this.formBuilder.group({
    month: [''],
    financial_year: [''],

  })
}
financialYearFilter(){
  let quaryParams:any;
  console.log("dashboardForm",this.dashboardForm)
  quaryParams = {
    month : this.dashboardForm.controls['month'].value ? this.dashboardForm.controls['month'].value : '',
    financial_year : this.dashboardForm.controls['financial_year'].value ? this.dashboardForm.controls['financial_year'].value : '' 
  }
  this.commonService.timeLine(quaryParams).subscribe((res:any)=>{
   this.timeBar = res.data;
  },(error:any)=>{
    console.log(error);
    this.timeBar = [];
  })
}
sanitizeId(id: string): string {
  // Replace spaces and special characters with underscores or any other desired pattern
  return id.replace(/[^a-zA-Z0-9]/g, '_');
}
  sortTable(columnName: string, sortType:string = '') {
    this.sortSelection = sortType;
    this.selectedColumn = columnName;
    this.orderSorting = sortType === 'down' ? 'DESC' : 'ASC';
    if ('IP list' == this.currentSeletedSubTab) {
      this.getIPlist(this.page, '', '', this.orderSorting,columnName);
    }
    else if ('Factory list' == this.currentSeletedSubTab){
      this.getFactoryListData(this.page, '', this.orderSorting,columnName);
    }
  }


apply(event:any){
  console.log("appliedApply", event)
  this.selectedFilters = event;
  let countryId = event.countryData.map((el:any)=>el?.id);
  let ipViewId = event.ipViewData.map((el:any)=>el?.id)
  let factoryId = event.factoryData.map((el:any)=>el?.id)
  let payloadData = {
    countryId,
    ipViewId,
    factoryId
  }
  this.commonFilterData = payloadData;
  // if(event.isAuto){
  //   this.datefilterData = {}
  // }
  let payload = {...this.datefilterData,...payloadData};
  this.payloadFilters = payload;
  if(event.isAuto){
    return true;
  //   let event:any = {index: 0};
  //  this.tabWiseData(event, true)
  }else{
    this.getData(payload);
  }
  
  this.removedFactoryChip = false;
  this.removedCountryChip = false;
  this.removedIPchip = false;

}

applyDateFilter(event:any){
  console.log("appliedDate", event)
  this.datefilterData = event;
  let payload = {...this.datefilterData,...this.commonFilterData};
  this.payloadFilters = payload;
  this.getData(payload)
}

calculateTotalEnergyByYear(data) {
  const totalsByYear = {};

  // Iterate over each energy source
  data.forEach(energySource => {
      // Iterate over the values for each energy source
      energySource.values.forEach(value => {
          const year = value.financial_year;
          const count = parseInt(value.total_count);

          // Add count to totalsByYear
          totalsByYear[year] = (totalsByYear[year] || 0) + count;
      });
  });

  // Convert totalsByYear object to an array of objects
  const result = Object.entries(totalsByYear).map(([year, total_count]) => ({
      "total_count": total_count.toString(),
      "financial_year": year
  }));

  return result;
}

navigateToList(id){
  let event:any = {index: id};
  if(this.selectedTab == 'programview'){
  this.headTabChange('countryview', id)
  }else{
    this.tabWiseData(event)
  }
}

getdataOnTab(isAuto:boolean =false){
  this.getData(this.payloadFilters, isAuto);
this.removedFactoryChip = false;
this.removedCountryChip = false;
this.removedIPchip = false;
}

activeTab(selectedTabName: string) {
  this.pageAccoradingAction = this.pageAccoradingAction.map((el: any) => {
    el.isChecked = el?.selectedTabs === selectedTabName || el?.model === selectedTabName;
    if(el?.selectedTabs === selectedTabName || el?.model === selectedTabName)  this.currentTab =  el?.selectedTabs;
    return el;
  })
}

getCurrentTabActiveMethod(data: any) {
  let getCurrentTab: any = null; 

  this.resourceTab.some((el: any) => {
    getCurrentTab = data.authenicationJson.find((e: any) => {
      return e.model === el &&
        (e.isAction || e.is_checked || (e.authentication && e.authentication.some((inside: any) => inside.isAction)));
    });

    return getCurrentTab !== undefined; 
  });

  return getCurrentTab;
}

}
