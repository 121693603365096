import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
    constructor(private _router:Router ) {
    }
    private isLoggedinBehavior = new BehaviorSubject<boolean>(false);
    isLoggedin = this.isLoggedinBehavior.asObservable();
    public isLogIn:any = new BehaviorSubject('false')

    isLoggedIn() {
      const token = sessionStorage.getItem('token');
      token ? this.isLogIn.next(true): this.isLogIn.next(false)
     return token ? true: false
    }

    updateHeader(resourceCount:any){
      this.isLoggedinBehavior.next(resourceCount);
     }

}
