<div class="row">
  <div *ngIf="data?.fileList?.length"> 
    <h6>{{data?.fileList[0]?.file}}</h6>
  </div>
    <div class="col-12" style="height: 400px;overflow-y: auto;overflow-x: hidden;">
        <mat-dialog-content class="mat-typography" style="overflow: hidden;">
            <ng-container *ngIf="data?.fileList && data.fileList.length ">
              <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" >
                  <div class="carousel-inner"  >
                    <ng-container *ngIf="data.link">
                      <iframe [src]="data.link" title="Youtube" width="750" height="800"></iframe>
                    </ng-container>
                    <ng-container *ngFor="let item of data.fileList;let i = index">
                      <div [id]="i" class="carousel-item" style="text-align: center;" [ngClass]="{'active': i==0}">
          
                        <ng-container *ngIf="getReturnType(item.fileType) == 'image'" >
                          <img [src]="item.fileUrl" class="d-block"  style="height: 550px;
                          width: 100%;">
                        </ng-container>
          
                        <ng-container *ngIf=" getReturnType(item.fileType) == 'video'">
                          <video  style="height: 550px;
                          width: 100%;" controls>
                            <source [src]="item.fileUrl" >
                            Your browser does not support the video tag.
                          </video>
                        </ng-container>
          
                        <ng-container *ngIf="getReturnType(item.fileType) == 'pdf'">
                          <iframe [src]="geturl(item.fileUrl)" title="Doc" width="750" height="800"></iframe>
                        </ng-container>
                        <ng-container *ngIf="getReturnType(item.fileType) == 'excel' || getReturnType(item.fileType) == 'word' || getReturnType(item.fileType) == 'ppt'">
                          <iframe [src]="getDocUrl(item.fileUrl)" width='100%' [height]="getReturnType(item.fileType) == 'ppt' ?  '400px' : '556px'" frameborder='0'> </iframe>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="data?.fileList?.length>1">
                  <button  class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </ng-container>
              </div>
          
            </ng-container>
            <div id="carouselExampleCaptions" *ngIf="data.link" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner"  >
                  <iframe [src]="sanitizer.bypassSecurityTrustResourceUrl(data.link.replace('watch?v=', 'embed/'))" title="Youtube" width="750" height="800"></iframe>
                </div>
                </div>
            <ng-container *ngIf="!data?.fileList?.length && !data.link" >
              <div style="width: 500px; height: auto; text-align: center;">
                <h1>
                  No Data Found
                </h1>
              </div>
            </ng-container>
          </mat-dialog-content>
        </div>
        <div class="col-12">
              <mat-dialog-actions align="end">
                  <div class="m-2"  style="cursor: pointer;" *ngIf="!data.link">
                    <a (click)="downloadCount('' ,1)" [href]="url" download="file" target="_blank">
                        <img src="/assets/images/download.svg" alt="">
                    </a>
                  </div>
                <button mat-button mat-dialog-close>Close</button>
              </mat-dialog-actions>
        </div>
</div>