import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { UserManagementServiceService } from  "src/app/admin/user-management/user-management-service.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { ActivatedRoute , Router} from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-ipsa-report',
  templateUrl: './ipsa-report.component.html',
  styleUrls: ['./ipsa-report.component.scss']
})
export class IpsaReportComponent implements OnInit {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger | undefined;
  public userForm !: FormGroup ;
  countrys :any = [];
  ipNames :any = [];
  factoryNames :any = [];
  userRoles :any = [];
  userId: string = "";
  adminData: any = JSON.parse(sessionStorage.getItem('userdata')!);
  showCountryDropdown: boolean = false;
  showIPdropdown: boolean = false;
  showFactoryDropdown: boolean = false;
  selectedCountry: any;
  totalDataCount: number = 0;
  public limit = 10;
  public skip = 0;
  public page = 1;

  headings:any = [{
    label:'Year',
    key:'year'
  },
  {
    label:'Country',
    key:'country_name'
  },
  {
    label:'IP',
    key:'industrialpark_name'
  },
  {
    label:'Factory',
    key:'factory_name'
  }]

  years = [
    // {
    //   year: '2020',
    //   value: '2020'
    // },
    // {
    //   year: '2021',
    //   value: '2021'
    // },
    {
      year: '2022',
      value: '2022'
    },
    {
      year: '2023',
      value: '2023'
    },
    {
      year: '2024',
      value: '2024'
    },
  ];

  countryCodeArray = [
    {
      name: 'Ethiopia',
      id: '5',
      code: '251'
    },
    {
      name: 'Vietnam',
      id: '6',
      code: '84'
    },
    {
      name: 'Kenya',
      id: '4',
      code: '254'
    },
  ];
  userAllData: any;
  userEmailId: string = "";
  isNodataFound: boolean = false;
  sortSelection: any;
  orderSorting: string = "";
  selectedColumn: string = '';
  filterParams: any;

  baseFormModule : string[] = ['ipsa'];
  allowedAction :any = [];
 
  constructor(
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementServiceService,
    private snackBar:MatSnackBar,
    private route : ActivatedRoute,
    private router : Router,
  ) { 
    let pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
  let currentBaseProgramForm = this.getCurrentProgramFormMethod(pageAction[0])
  
  this.allowedAction = pageAction[0].authenicationJson.filter((el:any)=>{
    if(el?.model === currentBaseProgramForm.model) return el;
  });
    this.createUserForm();
    // this.getUserRoleDetails();
    this.getCountryDetails();
    this.reportList();
  }

  @ViewChild('userRole') select: MatSelect | undefined;

  preventKeyboardInput(event: KeyboardEvent): void {
    if(event)
    return
    // event.preventDefault();
  }

  ngOnInit(): void {
   
    this.userId = this.route.snapshot.params.id;
    if(this.userId){
      setTimeout(() => {
        // this.prifieldMethod(this.userId)
      }, 1000);
    }
  }
  createUserForm():void {
    this.userForm = this.formBuilder.group({
        // password: ["", Validators.required]
        year:[''],
        industrialparkId: [''],
        countryId: [''],
        factoryId: [''],
    });
}

  async getCountryDetails(){
   await this.userManagementService.countryDetails().subscribe((res:any) => {
      if(res.success){
        this.countrys = res.data;
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    })

  }
  async getIpNameDetails(data:any = [], selectedCountryId:string){
    console.log(data, "123");
    
   await this.userManagementService.ipNameDetails(selectedCountryId).subscribe((res:any) => {
      if(res?.success){
        if(this.countrys?.length){
          this.ipNames = res?.data;
        }
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    },()=>{
      if(this.userId){
        if(data[0]['country_id'] && data[0]['industrialpark_id'] && !data[0]['factory_id']){
          // this.dropdownDataPrefield(data[0]);
        }
      }
    })
    
  }
  async getFactoryNameDetails(data:any = [], seleectedId:string){
   await this.userManagementService.factoryNameDetails(seleectedId).subscribe((res:any) => {
      if(res.success){
        if(this.ipNames?.length){
          this.factoryNames = res.data;
        }
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    }, () => {
      if(this.userId){
        // this.dropdownDataPrefield(data[0]);
      }
    })
  }


  createUser(){
    console.log(this.userForm,"this.userForm");
    if(this.userForm?.invalid){
      this.openSnackBar(['All field are required !!'],'','warning')
    }
    // let payload = {
    //   name : this.userForm?.controls['userName'].value,
    //   email : this.userForm?.controls['email'].value,
    //   mobile : this.userForm?.controls['mobile'].value ? this.userForm?.controls['mobile'].value : null,
    //   country_code: this.userForm?.controls['country_code']?.value ? this.userForm?.controls['country_code']?.value : null,
    //   country_id : this.showCountryDropdown && this.userForm?.controls['countryId'] ? this.userForm?.controls['countryId'].value?.id : null,
    //   factory_id : this.showFactoryDropdown && this.userForm?.controls['factoryId'] ? this.userForm?.controls['factoryId'].value?.id : null,
    //   industrialpark_id : this.showIPdropdown && this.userForm?.controls['industrialparkId'] ? this.userForm?.controls['industrialparkId'].value?.id : null,
    //   role_id:this.userForm?.controls['userRole'].value?.id,
    //   // password : Math.random().toString(36).slice(-8),
    //   // password : this.userForm?.controls['password'].value,
    //   createdBy:this.adminData ? this.adminData?.id : '',
    // };
    // let country = null;
    // let ipLevel = null;
    // let factoryLevel = null;
    // if(this.showCountryDropdown){
    //   country = {country_id : this.userForm?.controls['countryId'] && this.userForm?.controls['countryId'].value?.id ? this.userForm?.controls['countryId'].value?.id : null,}
      
    // }
    // Object.assign(payload, country);
    // if(this.showIPdropdown){
    //   ipLevel = {industrialpark_id : this.userForm?.controls['industrialparkId'] && this.userForm?.controls['industrialparkId'].value?.id? this.userForm?.controls['industrialparkId'].value?.id : null,}
      
    // }
    // Object.assign(payload, ipLevel);
    // if(this.showFactoryDropdown){
    //   factoryLevel = {factory_id : this.userForm?.controls['factoryId'] && this.userForm?.controls['factoryId'].value?.id ? this.userForm?.controls['factoryId'].value?.id : null,}
    // }
    //   Object.assign(payload, factoryLevel);
    // }

    console.log("payload")
  }


  get f() {
    return this.userForm.controls;
  }
  
  countryChangeHandler(event: MatSelectChange) : void {
    this.showFactoryDropdown = false;
    this.selectedCountry = event.value.id;
    console.log("userForm",this.userForm);
    
    if(event?.value?.id){
      this.getIpNameDetails("",event?.value?.id);
      this.ipNames = [];
      this.factoryNames = [];
      this.userForm.patchValue({
        industrialparkId:[],
        factoryId:[]
      })
    }
  }
  iPChangeHandler(event: MatSelectChange) : void {
    this.showFactoryDropdown = true;
    if(event?.value?.id){
      this.getFactoryNameDetails("", event?.value?.id);
      this.factoryNames = [];
      this.userForm.patchValue({
        factoryId:[]
      })
    }
  }
  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: [className]
  });
}

numberOnly(event:any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

resetFilter() {
  this.userForm.reset();
  this.search();
}

reportTable:any = [];


search(){
  console.log("search",this.userForm.value);
  this.selectedColumn = '';
  this.filterParams = '';
  if (this.matMenuTrigger) {
      
    this.matMenuTrigger.closeMenu();
  }
  let filterParams:any = `?`;
  for(let key in this.userForm.value){
    if(this.userForm.value[key]){
    let value = this.userForm.value[key];
    if(key != 'year' && this.userForm.value[key].hasOwnProperty('id'))
    value = this.userForm.value[key]['id'];
      if(filterParams.length > 1){
        filterParams += `&`
      }
    if(value)
    filterParams += `${key}=${value}`
    }
  }
  this.filterParams = filterParams
  console.log("search",filterParams)
  this.reportList()
}

async reportList(sortColumn:any = ''){
  let filterParams = this.filterParams ? this.filterParams : '';
  if(filterParams){
    filterParams += sortColumn ? `&${sortColumn}` : ``;
  }else{
    filterParams += sortColumn ? `?${sortColumn}` : ``;
  }
  await this.userManagementService.ipsaReportList(filterParams).subscribe((res:any) => {
     if(res.success){
       this.reportTable = res.data;
       if(this.reportTable.length == 0)
       this.isNodataFound = true;
      console.log("res", res, filterParams);
      this.totalDataCount = res.total;
       // this.openSnackBar([res?.message || 'Success !'] , '','success');
     }
   },(error:any)=>{
     this.openSnackBar([error?.message || 'Failed !'] , '');
   })

 }

 downloadReport(item:any, type:any){
   let payload:any = JSON.stringify({
    "year": item?.year,
    "countryId": item?.countryId,
    "industrialparkId": item?.industrialparkId,
    "factoryId": item?.factoryId,
    "versionFlag": type == 'with' ? true : false
})
  this.userManagementService.ipsaReportDownload(payload, item);

 }

 setPage(event: any) {
  this.skip = (event.pageIndex + 1) * this.limit - this.limit;
  console.log('limit',event.pageSize,event)
  this.limit = event.pageSize;
  // this.programFormListing(event.pageIndex + 1);
  this.reportList()
}

isEmptyString(){
  let isEmptyString = true;
  for(let key in this.userForm.value){
    if(this.userForm.value[key]){
      isEmptyString = false;
    }
  }
  return isEmptyString;
}

sortTable(columnName: string, sortType:string = ''){
  this.sortSelection = sortType;
    this.selectedCountry = columnName;
    this.selectedColumn = columnName;
    this.orderSorting = sortType == 'down' ? 'DESC' : 'ASC';
    let sortColumn:string = `${columnName}=${this.orderSorting}`;
    this.reportList(sortColumn)
    console.log("sorted", columnName, sortType)
}

getCurrentProgramFormMethod(data: any) {
  let getCurrentTab: any = null; 

  this.baseFormModule.some((el: any) => {
    getCurrentTab = data.authenicationJson.find((e: any) => {
      return e.model === el &&
        (e.isAction || e.is_checked || (e.authentication && e.authentication.some((inside: any) => inside.isAction)));
    });

    return getCurrentTab !== undefined; 
  });

  return getCurrentTab;
}

}
