import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconClass'
})
export class IconClassPipe implements PipeTransform {

  private iconMapping :any= {
    'pdf': 'fa fa-file-pdf-o',
    'doc': 'fa fa-file-word-o',
    'docx': 'fa fa-file-word-o',
    'xls': 'fa fa-file-excel-o',
    'xlsx': 'fa fa-file-excel-o',
    'csv': 'fa fa-file-o',
    'ppt': 'fa fa-file-powerpoint-o',
    'pptx': 'fa fa-file-powerpoint-o',
    'jpg': 'fa fa-file-image-o',
    'jpeg': 'fa fa-file-image-o',
    'png': 'fa fa-file-image-o',
    'gif': 'fa fa-file-image-o',
    // Add more mappings as needed
  };

  transform(filename: string): string {
    const extension = filename.split('.').pop()?.toLowerCase() || 'o'; // Extract file extension and default to 'o' for unknown extensions
    return this.iconMapping[extension] || 'fa fa-file-o'; // Default icon class for unknown extensions
  }

}
