import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { CommonServiceService } from 'src/app/pages/services/common-service.service';
import { UserWelcomeComponent } from './user-welcome/user-welcome.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { Observable } from 'rxjs';
import { GDPRComponent } from 'src/app/pages/gdpr/gdpr.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {
  pageYoffset = 0;
  userEmail: any = "";
  isLoggedin:boolean = false;
  // myvalue: any;
  @Input() userData : any;
  isEmail: boolean = false;
  gdprChecked: boolean = false;
  @HostListener('window:scroll', ['$event']) onScroll(){
    this.pageYoffset = window.pageYOffset;
  }
  totalVisitor = 0;
  constructor(public dialog: MatDialog,
    private scroll: ViewportScroller,
    private commonService: CommonServiceService,
    private snackBar: MatSnackBar
  ) {
   }

   ngOnChanges(changes: SimpleChanges): void{
      console.log("theChanges",changes);
      
   }

  ngOnInit(): void {
    // this.commonService.data.subscribe(data => {
    //   console.log("allData",data);
    //   this.userData = data;
    // })
    // this.userData = JSON.parse(localStorage.getItem('userdata')!);
    // console.log("xyzzzz",this.userData)
  }

  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

  // openSnackBar(data: string[], duration:number) {
  //   this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  // }

  openSnackBar(message:any, action = '',isSuccess:boolean=false) {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: !isSuccess ? ['primary'] : ''
  });
}
  
  subscribeUser(){
    this.gdprChecked = JSON.parse(sessionStorage.getItem("gdprConfirmation")!);
    if(!this.userEmail){
      return ;
    }
    // if(!this.gdprChecked){
    //   let dialogRef = this.dialog.open(GDPRComponent, {
    //     width: '700px',
    //     panelClass: 'gdpr-portalDialog',
    //     data: {}
    //   });
    //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //     if(confirmed)
    //     this.subscribePost();
    //   })
    // }else{
      this.subscribePost();
    // }
  }

  privacyStatement(){
    if(!this.gdprChecked){
      let dialogRef = this.dialog.open(GDPRComponent, {
        width: '700px',
        panelClass: 'gdpr-portalDialog',
        data: {}
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      })
    }
  }

  subscribePost(){
    let data = {
      email: this.userEmail
    };
    if(this.userEmail.length && this.userEmail.includes('.com')){
      this.commonService.subscribeUser(data).subscribe(res => {
        if (res.success) {
          this.openSnackBar([ res?.message ? res?.message :'Thank You For Subscribing !!'],'',true)
          this.userEmail = "";
        }else{
          this.openSnackBar(res.message);
        }
      }, (error) => {
        console.log("footer",error)
        this.openSnackBar(error?.error?.message ? error?.error?.message : 'failed !')
      }
      )
    }
  }
  validateMethod(){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(emailRegex.test(this.userEmail) || !this.userEmail){
      this.isEmail = false;
    }
    else{
      this.isEmail = true;
    }
  }

}
