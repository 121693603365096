import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserManagementServiceService } from '../user-management-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-indicator-permission',
  templateUrl: './indicator-permission.component.html',
  styleUrls: ['./indicator-permission.component.scss']
})
export class IndicatorPermissionComponent implements OnInit {

  // customJson:any [] = [
  //   {
  //       "model": "dashboard",
  //       "label": "Program View (Dashboard)",
  //       "authentication": [
  //           {
  //               "key": "create",
  //               "label": "Create",
  //               "isAction": true
  //           },
  //           {
  //               "key": "update",
  //               "label": "Update/Delete",
  //               "isAction": true
  //           },
  //           {
  //               "key": "list",
  //               "label": "List",
  //               "isAction": true
  //           },
  //       ],
  //       "is_checked": true,
  //       "isAction": true
  //   },
  //   {
  //       "model": "dashboard",
  //       "label": "Country View",
  //       "authentication": [
  //         {
  //             "key": "create",
  //             "label": "Create",
  //             "isAction": true
  //         },
  //         {
  //             "key": "update",
  //             "label": "Update/Delete",
  //             "isAction": true
  //         },
  //         {
  //             "key": "list",
  //             "label": "List",
  //             "isAction": true
  //         },
  //       ],
  //       "is_checked": true,
  //       "isAction": true
  //   },
  //   {
  //       "model": "dashboard",
  //       "label": "IP View",
  //       "authentication": [
  //         {
  //             "key": "create",
  //             "label": "Create",
  //             "isAction": true
  //         },
  //         {
  //             "key": "update",
  //             "label": "Update/Delete",
  //             "isAction": true
  //         },
  //         {
  //             "key": "list",
  //             "label": "List",
  //             "isAction": true
  //         },
  //       ],
  //       "is_checked": true,
  //       "isAction": true
  //   },
  //   {
  //       "model": "dashboard",
  //       "label": "Factory View",
  //       "authentication": [
  //         {
  //             "key": "create",
  //             "label": "Create",
  //             "isAction": true
  //         },
  //         {
  //             "key": "update",
  //             "label": "Update/Delete",
  //             "isAction": true
  //         },
  //         {
  //             "key": "list",
  //             "label": "List",
  //             "isAction": true
  //         },
  //       ],
  //       "isAction": true,
  //       "is_checked": true
  //   },
  // ];
  customJson:any [] = [
    {
      "model": "dashboard",
      "label": "Program View (Impact Summary - Card)",
      "short_key": "programview/impact-summary/card",
      "authentication": [
        {
          "key": "ParticipatingCountries",
          "label": "Participating Countries",
          "isAction": true
        },
        {
          "key": "IndustrialParksinINSTEPNetwork",
          "label": "Industrial Parks in INSTEP Network",
          "isAction": true
        },
        {
          "key": "T&AFactoriesintheINSTEPNetwork",
          "label": "T&A Factories in the INSTEP Network",
          "isAction": true
        },
        {
          "key": "WorkersinINSTEPNetwork",
          "label": "Workers in INSTEP",
          "isAction": true
        },
        {
          "key": "RegisteredImplementationPartners",
          "label": "Registered Implementation Partners",
          "isAction": true
        },
        {
          "key": "MutlistakeholderAgreements",
          "label": "Mutlistakeholder Agreements",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Program View (Impact Summary - Pie Chart)",
      "short_key": "programview/impact-summary/pie-chart",
      "authentication": [
        {
          "key": "NumberofBrandsSourcingFromINSTEPIndustrialParks",
          "label": "Number of Brands Sourcing From INSTEP Industrial Parks",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Impact Summary - Group Bar Chart)",
      "short_key": "programview/impact-summary/group-bar-chart",
      "authentication": [
        {
          "key": "T&AGoodsProducedbyINSTEPFactoriesinMT",
          "label": "T&A Goods Produced by INSTEP Factories in MT",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Impact Summary - IPSA)",
      "short_key": "programview/impact-summary/ipsa",
      "authentication": [
        {
          "key": "AverageIPSAScore",
          "label": "Average IPSA Score (Card)",
          "isAction": true
        },
        {
          "key": "CountryWisePerformanceonIPSASocialIndicators",
          "label": "Country wise IPSA Score (Bar Chart)",
          "isAction": true
        },
        {
          "key": "TopPerformingFactoryasperIPSAScore",
          "label": "Top Performing Factory as per IPSA Score(IPSA)",
          "isAction": true
        },
        {
          "key": "TopPerformingIndustrialParkasperIPSAScore",
          "label": "Top Performing Industrial Park as per IPSA Score(IPSA)",
          "isAction": true
        },
        {
          "key": "CountryWisePerformanceonIPSAEnvironmentalIndicators",
          "label": "Country wise IPSA Score (Bar Chart)",
          "isAction": true
        },
        {
          "key": "CountryWisePerformanceonIPSABuildingSafetyIndicators",
          "label": "Country wise IPSA Score (Bar Chart)",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Social Impact - Card)",
      "short_key": "programview/social-impact/card",
      "authentication": [
        {
          "key": "WomeninLeadership",
          "label": "Women in Leadership",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Social Impact - Pie Chart)",
      "short_key": "programview/social-impact/pie-chart",
      "authentication": [
        {
          "key": "AttritionRate",
          "label": "Attrition Rate",
          "isAction": true
        },
        {
          "key": "NumberofEmployeesTrainedinGrievanceRedressalMechanisms",
          "label": "Number of Employees Trained in Grievance Redressal Mechanisms",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Social Impact->OHS->Group Bar Chart)",
      "short_key": "programview/social-impact/timeline",
      "authentication": [
        {
          "key": "WorkersProvidedwithOHSTrainings",
          "label": "Workers Provided with OHS Trainings(Timeline)",
          "isAction": true
        },
        {
          "key": "WorkersSufferingfromOccupationalInjuriesbyMajorEvent",
          "label": "Workers Suffering from Occupational Injuries by Major Event(Timeline)",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Social Impact->Group Bar Chart)",
      "short_key": "programview/social-impact/group-bar-chart",
      "authentication": [
        {
          "key": "MaleWorkerswithAccesstoServices",
          "label": "Male Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "FemaleWorkerswithAccesstoServices",
          "label": "Female Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "WomenProvidedwithProfessionalTrainings",
          "label": "Women Provided with Professional Trainings",
          "isAction": true
        },
        {
          "key": "WorkplaceViolenceIncidentsReported",
          "label": "Workplace Violence Incidents Reported",
          "isAction": true
        },
        {
          "key": "OHSInspectionsConducted",
          "label": "OHS Inspections Conducted",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Environmental Impact)",
      "short_key": "programview/environmental-impact/pie-chart",
      "authentication": []
    },
    {
      "model": "dashboard1",
      "label": "Program View (Environmental Impact->Donut Chart)",
      "short_key": "programview/environmental-impact/donut-chart",
      "authentication": [
        {
          "key": "NumberofFactoriesWorkingonZDHCMRSLConformance",
          "label": "Number of Factories Working on ZDHC MRSL Conformance",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Environmental Impact->Timeline)",
      "short_key": "programview/environmental-impact/timeline",
      "authentication": [
        {
          "key": "EnergyConsumption(kWH)",
          "label": "Energy Consumption (kWH)",
          "isAction": true
        },
        {
          "key": "WaterUsage(m<sup>3</sup>)",
          "label": "Water Usage (m<sup>3</sup>)",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Program View (Environmental Impact->Group Bar Chart)",
      "short_key": "programview/environmental-impact/group-bar-chart",
      "authentication": [
        {
          "key": "Scope1&Scope2GHGEmissions(kgCO<sub>2</sub>e)",
          "label": "Scope 1 & Scope 2 GHG Emissions (kgCO<sub>2</sub>e)",
          "isAction": true
        },
        {
          "key": "WastewaterTreated(m<sup>3</sup>)",
          "label": "Wastewater Treated (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "WastewaterReusedandDischarged(m<sup>3</sup>)",
          "label": "Wastewater Reused and Discharged (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "IndustrialParkCETPMonitoringofOwnWastewaterDischarge",
          "label": "Industrial Park CETP Monitoring of Own Wastewater Discharge",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard",
      "label": "Country View (Impact Summary -> Card)",
      "short_key": "countryview/performance-impact/card",
      "authentication": [
        {
          "key": "ParticipatingCountries",
          "label": "Participating Countries",
          "isAction": true
        },
        {
          "key": "IndustrialParksinINSTEPNetwork",
          "label": "Industrial Parks in INSTEP Network",
          "isAction": true
        },
        {
          "key": "T&AFactoriesintheINSTEPNetwork",
          "label": "T&A Factories in the INSTEP Network",
          "isAction": true
        },
        {
          "key": "WorkersinINSTEPNetwork",
          "label": "Workers in INSTEP",
          "isAction": true
        },
        {
          "key": "RegisteredImplementationPartners",
          "label": "Registered Implementation Partners",
          "isAction": true
        },
        {
          "key": "MutlistakeholderAgreements",
          "label": "Mutlistakeholder Agreements",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Country View (Impact Summary->Pie Chart)",
      "short_key": "countryview/performance-impact/pie-chart",
      "authentication": [
        {
          "key": "NumberofBrandsSourcingFromINSTEPIndustrialParks",
          "label": "Number of Brands Sourcing From INSTEP Industrial Parks",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Impact Summary->Group Bar)",
      "short_key": "countryview/performance-impact/group-bar-chart",
      "authentication": [
        {
          "key": "T&AGoodsProducedbyINSTEPFactoriesinMT",
          "label": "T&A Goods Produced by INSTEP Factories in MT",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Social Impact->Card)",
      "short_key": "countryview/social-impact/card",
      "authentication": [
        {
          "key": "WomeninLeadership",
          "label": "Women in Leadership",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Social Impact->Pie Chart)",
      "short_key": "countryview/social-impact/pie-chart",
      "authentication": [
        {
          "key": "AttritionRate",
          "label": "Attrition Rate",
          "isAction": true
        },
        {
          "key": "NumberofEmployeesTrainedinGrievanceRedressalMechanisms",
          "label": "Number of Employees Trained in Grievance Redressal Mechanisms",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Social Impact->OHS->Group Bar Chart)",
      "short_key": "countryview/social-impact/timeline",
      "authentication": [
        {
          "key": "WorkersProvidedwithOHSTrainings",
          "label": "Workers Provided with OHS Trainings(Timeline)",
          "isAction": true
        },
        {
          "key": "WorkersSufferingfromOccupationalInjuriesbyMajorEvent",
          "label": "Workers Suffering from Occupational Injuries by Major Event(Timeline)",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Social Impact->Group Bar Chart)",
      "short_key": "countryview/social-impact/group-bar-chart",
      "authentication": [
        {
          "key": "MaleWorkerswithAccesstoServices",
          "label": "Male Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "FemaleWorkerswithAccesstoServices",
          "label": "Female Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "WomenProvidedwithProfessionalTrainings",
          "label": "Women Provided with Professional Trainings",
          "isAction": true
        },
        {
          "key": "WorkplaceViolenceIncidentsReported",
          "label": "Workplace Violence Incidents Reported",
          "isAction": true
        },
        {
          "key": "WorkersSufferingfromOccupationalInjuries",
          "label": "Workers Suffering from Occupational Injuries",
          "isAction": true
        },
        {
          "key": "OHSInspectionsConducted",
          "label": "OHS Inspections Conducted",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Environmental Impact)",
      "short_key": "countryview/environmental-impact/pie-chart",
      "authentication": []
    },
    {
      "model": "dashboard1",
      "label": "Country View (Environmental Impact->Donut Chart)",
      "short_key": "countryview/environmental-impact/donut-chart",
      "authentication": [
        {
          "key": "NumberofFactoriesWorkingonZDHCMRSLConformance",
          "label": "Number of Factories Working on ZDHC MRSL Conformance",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Environmental Impact)",
      "short_key": "countryview/environmental-impact/timeline",
      "authentication": [
        {
          "key": "EnergyConsumption(kWH)",
          "label": "Energy Consumption (kWH)(Timeline)",
          "isAction": true
        },
        {
          "key": "WaterUsage(m<sup>3</sup>)",
          "label": "Water Usage (m<sup>3</sup>)(Timeline)",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard1",
      "label": "Country View (Environmental Impact->Group Bar Chart)",
      "short_key": "countryview/environmental-impact/group-bar-chart",
      "authentication": [
        {
          "key": "Scope1&Scope2GHGEmissions(kgCO<sub>2</sub>e)",
          "label": "Scope 1 & Scope 2 GHG Emissions (kgCO<sub>2</sub>e)",
          "isAction": true
        },
        {
          "key": "WastewaterTreated(m<sup>3</sup>)",
          "label": "Wastewater Treated (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "WastewaterReusedandDischarged(m<sup>3</sup>)",
          "label": "Wastewater Reused and Discharged (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "IndustrialParkCETPMonitoringofOwnWastewaterDischarge",
          "label": "Industrial Park CETP Monitoring of Own Wastewater Discharge",
          "isAction": true
        }
      ]
    },
    {
      "model": "dashboard",
      "label": "IP View (Impact Summary->Card)",
      "short_key": "ipview/performance-impact/card",
      "authentication": [
        {
          "key": "T&AFactoriesintheINSTEPNetwork",
          "label": "T&A Factories in the INSTEP Network",
          "isAction": true
        },
        {
          "key": "WorkersinINSTEPNetwork",
          "label": "Workers in INSTEP",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Impact Summary->Pie Chart)",
      "short_key": "ipview/performance-impact/pie-chart",
      "authentication": [
        {
          "key": "NumberofBrandsSourcingFromINSTEPIndustrialParks",
          "label": "Number of Brands Sourcing From INSTEP Industrial Parks",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Impact Summary->Group Bar Chart)",
      "short_key": "ipview/performance-impact/group-bar-chart",
      "authentication": [
        {
          "key": "T&AGoodsProducedbyINSTEPFactoriesinMT",
          "label": "T&A Goods Produced by INSTEP Factories in MT",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Social Impact->Card)",
      "short_key": "ipview/social-impact/card",
      "authentication": [
        {
          "key": "WomenintheINSTEPProgram",
          "label": "Women in the INSTEP Program",
          "isAction": true
        },
        {
          "key": "MenintheINSTEPProgram",
          "label": "Men in the INSTEP Program",
          "isAction": true
        },
        {
          "key": "WomeninLeadership",
          "label": "Women in Leadership",
          "isAction": true
        },
        {
          "key": "Female:MaleWorkers",
          "label": "Female:Male Workers",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Social Impact->Pie Chart)",
      "short_key": "ipview/social-impact/pie-chart",
      "authentication": [
        {
          "key": "AttritionRate",
          "label": "Attrition Rate",
          "isAction": true
        },
        {
          "key": "NumberofEmployeesTrainedinGrievanceRedressalMechanisms",
          "label": "Number of Employees Trained in Grievance Redressal Mechanisms",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Social Impact->Timeline)",
      "short_key": "ipview/social-impact/timeline",
      "authentication": [
        {
          "key": "WorkersProvidedwithOHSTrainings",
          "label": "Workers Provided with OHS Trainings",
          "isAction": true
        },
        {
          "key": "WorkersSufferingfromOccupationalInjuriesbyMajorEvent",
          "label": "Workers Suffering from Occupational Injuries by Major Event",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Social Impact->Group Bar Chart)",
      "short_key": "ipview/social-impact/group-bar-chart",
      "authentication": [
        {
          "key": "MaleWorkerswithAccesstoServices",
          "label": "Male Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "FemaleWorkerswithAccesstoServices",
          "label": "Female Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "WomenProvidedwithProfessionalTrainings",
          "label": "Women Provided with Professional Trainings",
          "isAction": true
        },
        {
          "key": "WorkplaceViolenceIncidentsReported",
          "label": "Workplace Violence Incidents Reported",
          "isAction": true
        },
        {
          "key": "WorkersSufferingfromOccupationalInjuries",
          "label": "Workers Suffering from Occupational Injuries",
          "isAction": true
        },
        {
          "key": "OHSInspectionsConducted",
          "label": "OHS Inspections Conducted",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Environmental Impact)",
      "short_key": "ipview/environmental-impact/pie-chart",
      "authentication": [],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Program View (Environmental Impact->Donut Chart)",
      "short_key": "ipview/environmental-impact/donut-chart",
      "authentication": [
        {
          "key": "NumberofFactoriesWorkingonZDHCMRSLConformance",
          "label": "Number of Factories Working on ZDHC MRSL Conformance",
          "isAction": true
        },
        {
          "key": "IndustrialParkCETPWastewaterMonitoringofWet-ProcessingTenantFactoriesBasedonZDHCWastewaterGuidelines/ClearStream",
          "label": "Industrial Park CETP Wastewater Monitoring of Wet-Processing Tenant Factories Based on ZDHC Wastewater Guidelines/ ClearStream",
          "isAction": true
        },
        {
          "key": "CETPWastewaterMonitoringofTenantFactoriesBasedonContractualAgreementforWastewaterVolumesandLoads",
          "label": "CETP Wastewater Monitoring of Tenant Factories Based on Contractual Agreement for Wastewater Volumes and Loadse",
          "isAction": true
        },
        {
          "key": "IndustrialParkCETPFollows-uponRoot-CausesandCorrectiveActionPlansofTenantFactories",
          "label": "Industrial Park CETP Follows-up on Root-Causes and Corrective Action Plans of Tenant Factoriese",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Environmental Impact-Timeline)",
      "short_key": "ipview/environmental-impact/timeline",
      "authentication": [
        {
          "key": "EnergyConsumption(kWH)",
          "label": "Energy Consumption (kWH)",
          "isAction": true
        },
        {
          "key": "WaterUsage(m<sup>3</sup>)",
          "label": "Water Usage (m<sup>3</sup>)",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Environmental Impact->Group Bar Chart)",
      "short_key": "ipview/environmental-impact/group-bar-chart",
      "authentication": [
        {
          "key": "Scope1&Scope2GHGEmissions(kgCO<sub>2</sub>e)",
          "label": "Scope 1 & Scope 2 GHG Emissions (kgCO<sub>2</sub>e)",
          "isAction": true
        },
        {
          "key": "WastewaterTreated(m<sup>3</sup>)",
          "label": "Wastewater Treated (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "WastewaterReusedandDischarged(m<sup>3</sup>)",
          "label": "Wastewater Reused and Discharged (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "ZDHCMRSLConformancePercentage",
          "label": "ZDHC MRSL Conformance Percentage",
          "isAction": true
        },
        {
          "key": "ZDHCMRSLParametersDetectedinUntreatedWastewater",
          "label": "ZDHC MRSL Parameters Detected in Untreated Wastewater",
          "isAction": true
        },
        {
          "key": "ZDHCMRSLParametersDetectedinEffluent",
          "label": "ZDHC MRSL Parameters Detected in Effluent",
          "isAction": true
        },
        {
          "key": "IndustrialParkCETPMonitoringofOwnWastewaterDischarge",
          "label": "Industrial Park CETP Monitoring of Own Wastewater Discharge",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "IP View (Environmental Impact->Table)",
      "short_key": "ipview/environmental-impact/table",
      "authentication": [
        {
          "key": "IndustrialParkCETPDisposalPolicies",
          "label": "Industrial Park CETP Disposal Policies",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Environmental Impact->Card)",
      "short_key": "factoryview/performance-impact/card",
      "authentication": [
        {
          "key": "WorkersinINSTEPNetwork",
          "label": "Workers in INSTEP Networke",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Impact Summary)",
      "short_key": "factoryview/performance-impact/pie-chart",
      "authentication": [],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Impact Summary->Group Bar Chart)",
      "short_key": "factoryview/performance-impact/group-bar-chart",
      "authentication": [
        {
          "key": "T&AGoodsProducedbyINSTEPFactoriesinMT",
          "label": "T&A Goods Produced by INSTEP Factories in MT",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Social Impact->Card)",
      "short_key": "factoryview/social-impact/card",
      "authentication": [
        {
          "key": "WomenintheINSTEPProgram",
          "label": "Women in the INSTEP Program",
          "isAction": true
        },
        {
          "key": "MenintheINSTEPProgram",
          "label": "Men in the INSTEP Program",
          "isAction": true
        },
        {
          "key": "WomeninLeadership",
          "label": "Women in Leadership",
          "isAction": true
        },
        {
          "key": "Female:MaleWorkers",
          "label": "Female:Male Workers",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Social Impact->Pie Chart)",
      "short_key": "factoryview/social-impact/pie-chart",
      "authentication": [
        {
          "key": "AttritionRate",
          "label": "Attrition Rate",
          "isAction": true
        },
        {
          "key": "NumberofEmployeesTrainedinGrievanceRedressalMechanisms",
          "label": "Number of Employees Trained in Grievance Redressal Mechanisms",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Social Impact->Timeline)",
      "short_key": "factoryview/social-impact/timeline",
      "authentication": [
        {
          "key": "WorkersProvidedwithOHSTrainings",
          "label": "Workers Provided with OHS Trainings",
          "isAction": true
        },
        {
          "key": "WorkersSufferingfromOccupationalInjuriesbyMajorEvent",
          "label": "Workers Suffering from Occupational Injuries by Major Event",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Social Impact->Group Bar Chart)",
      "short_key": "factoryview/social-impact/group-bar-chart",
      "authentication": [
        {
          "key": "MaleWorkerswithAccesstoServices",
          "label": "Male Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "FemaleWorkerswithAccesstoServices",
          "label": "Female Workers with Access to Services",
          "isAction": true
        },
        {
          "key": "WomenProvidedwithProfessionalTrainings",
          "label": "Women Provided with Professional Trainings",
          "isAction": true
        },
        {
          "key": "WorkplaceViolenceIncidentsReported",
          "label": "Workplace Violence Incidents Reported",
          "isAction": true
        },
        {
          "key": "WorkersSufferingfromOccupationalInjuries",
          "label": "Workers Suffering from Occupational Injuries",
          "isAction": true
        },
        {
          "key": "OHSInspectionsConducted",
          "label": "OHS Inspections Conducted",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Environmental Impact->Pie Chart)",
      "short_key": "factoryview/environmental-impact/pie-chart",
      "authentication": [
        {
          "key": "ZDHCMRSLConformanceofChemicalProducts",
          "label": "ZDHC MRSL Conformance of Chemical Productse",
          "isAction": true
        },
        {
          "key": "MaturityoftheChemicalInventoryConformanceinTermsofConformanceLevels",
          "label": "Maturity of the Chemical Inventory Conformance, in Terms of Conformance Levelse",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": true
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Environmental Impact)",
      "short_key": "factoryview/environmental-impact/donut-chart",
      "authentication": [],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Environmental Impact->Timeline)",
      "short_key": "factoryview/environmental-impact/timeline",
      "authentication": [
        {
          "key": "EnergyConsumption(kWH)",
          "label": "Energy Consumption (kWH)",
          "isAction": true
        },
        {
          "key": "WaterUsage(m<sup>3</sup>)",
          "label": "Water Usage (m<sup>3</sup>)",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    },
    {
      "model": "dashboard1",
      "label": "Factory View (Environmental Impact->Group Bar Chart)",
      "short_key": "factoryview/environmental-impact/group-bar-chart",
      "authentication": [
        {
          "key": "Scope1&Scope2GHGEmissions(kgCO<sub>2</sub>e)",
          "label": "Scope 1 & Scope 2 GHG Emissions (kgCO<sub>2</sub>e)",
          "isAction": true
        },
        {
          "key": "WastewaterTreated(m<sup>3</sup>)",
          "label": "Wastewater Treated (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "WastewaterReusedandDischarged(m<sup>3</sup>)",
          "label": "Wastewater Reused and Discharged (m<sup>3</sup>)",
          "isAction": true
        },
        {
          "key": "MeetingCETPWastewaterPermitRequirements",
          "label": "Meeting CETP Wastewater Permit Requirements",
          "isAction": true
        },
        {
          "key": "CorrectiveActionsinCaseofDetectionsinWastewater",
          "label": "Corrective Actions in Case of Detections in Wastewater",
          "isAction": true
        }
      ],
      "is_checked": false,
      "isAction": false
    }
  ]
//   customObj:any = {
//     "model": "dashboard",
//     "authentication": [
//         {
//             "key": "create",
//             "label": "Create",
//             "isAction": true
//         },
//         {
//             "key": "update",
//             "label": "Update/Delete",
//             "isAction": true
//         },
//         {
//             "key": "list",
//             "label": "List",
//             "isAction": true
//         },
//     ],
//     "is_checked": true,
//     "isAction": true
// }
//   labelArray:any = ["Program View (Impact Summary)", 
//   "Program View (Social Impact / Worker Welfare)", 
//   "Program View (Social Impact / Women Empowerment)",
//   "Program View (Social Impact / OHS)",
//   "Program View (Environmental Impact / Energy)",
//   "Program View (Environmental Impact / Water)",
//   "Program View (Environmental Impact / Chemical)",
//   "Country View (Impact Summary)",
//   "Country View (Social Impact / Worker Welfare)",
//   "Country View (Social Impact / Women Empowerment)",
//   "Country View (Social Impact / OHS)",
//   "Country View (Environmental Impact / Energy)",
//   "Country View (Environmental Impact / Water)",
//   "Country View (Environmental Impact / Chemical)",
//   "Country View (IP List)",
//   "Country View (Factory List)",
//   "IP View (Impact Summary)",
//   "IP View (Social Impact / Worker Welfare)",
//   "IP View (Social Impact / Women Empowerment)",
//   "IP View (Social Impact / OHS)",
//   "IP View (Environmental Impact / Energy)",
//   "IP View (Environmental Impact / Water)",
//   "IP View (Environmental Impact / Chemical)",
//   "IP View (Factory List)",
//   "Factory View (Impact Summary)",
//   "Factory View (Social Impact / Worker Welfare)", 
//   "Factory View (Social Impact / Women Empowerment)",
//   "Factory View (Social Impact / OHS)",
//   "Factory View (Environmental Impact / Energy)",
//   "Factory View (Environmental Impact / Water)",
//   "Factory View (Environmental Impact / Chemical)",
// ]
  roleId:string = "1";
  currentMode: any = 'edit';
  adminData: any = sessionStorage.getItem('userdata');
  permissionDetails: any =[];
  roleName: string = "";
  selectedLevel: any;
  roleLevelId: any;

  constructor(private snackBar:MatSnackBar, private userManagementServiceService: UserManagementServiceService,
    private scroll: ViewportScroller,
    private router:Router, ) {
    // this.createCustomJson();
   }

  // createCustomJson(){
  //   this.customJson = [];
  //   this.labelArray.forEach((data:any) => {
  //     this.customJson.push(Object.assign({...this.customObj}, {label : data}))
  //   })
  //   console.log("custjs",this.customJson);
  // }
   prefillJson:any = [
    {
    "authenicationJson": [
      {
        "model": "dashboard",
        "label": "Program View (Impact Summary)",
        "authentication": [
          {
            "key": "WorldMap",
            "label": "World Map",
            "isAction": false
          },
          {
            "key": "participatingCountries",
            "label": "Participating Countries (Card)",
            "isAction": false
          },
          {
            "key": "industrialParksInINSTEPnetwork",
            "label": "Industrial Parks in INSTEP Network (Card)",
            "isAction": true
          },
          {
            "key": "T&AFactoriesInTheINSTEPnetwork",
            "label": "T&A Factories in the INSTEP Network (Card)",
            "isAction": true
          },
          {
            "key": "WorkersInINSTEP",
            "label": "Workers in INSTEP (Card)",
            "isAction": true
          },
          {
            "key": "RegisteredImplementationPartners",
            "label": "Registered Implementation Partners (Card)",
            "isAction": true
          },
          {
            "key": "MutlistakeholderAgreements",
            "label": "Mutlistakeholder Agreements (Card)",
            "isAction": true
          },
          {
            "key": "NumberofBrandsSourcingFromINSTEPIndustrialParks",
            "label": "Number of Brands Sourcing From INSTEP Industrial Parks (Pie Chart)",
            "isAction": true
          },
          {
            "key": "T&AGoodsProducedbyINSTEPFactoriesinMT",
            "label": "T&A Goods Produced by INSTEP Factories in MT (Group Bar)",
            "isAction": true
          },
          {
            "key": "TopPerformingIndustrialParkasperIPSAScore",
            "label": "Top Performing Industrial Park as per IPSA Score (Table)",
            "isAction": true
          },
          {
            "key": "TopPerformingFactoryasperIPSAScore",
            "label": "Top Performing Factory as per IPSA Score (Table)",
            "isAction": true
          },
          {
            "key": "AverageIPSAScore",
            "label": "Average IPSA Score (Card)",
            "isAction": true
          },
          {
            "key": "CountryWiseIPSAScore",
            "label": "Country wise IPSA Score (Bar Chart)",
            "isAction": true
          }
        ],
        "is_checked": true,
        "isAction": true
      },
      {
        "model": "dashboard",
        "label": "Program View (Social Impact)",
        "authentication": [
          {
            "key": "WomeninLeadership",
            "label": "Women in Leadership (Card)",
            "isAction": true
          },
          {
            "key": "WorkerWelfare",
            "label": "Worker Welfare",
            "isAction": true
          },
          {
            "key": "Women Empowerment",
            "label": "Women Empowerment",
            "isAction": true
          },
          {
            "key": "OHS",
            "label": "OHS",
            "isAction": true
          },
          {
            "key": "MaleWorkerswithAccesstoServices",
            "label": "Male Workers with Access to Services (Group Bar Chart)",
            "isAction": true
          },
          {
            "key": "FemaleWorkerswithAccesstoServices",
            "label": "Female Workers with Access to Services (Group Bar Chart)",
            "isAction": true
          },
          {
            "key": "AttritionRate",
            "label": "Attrition Rate (Doughnut)",
            "isAction": true
          },
          {
            "key": "NumberofEmployeesTrainedinGrievanceRedressalMechanisms",
            "label": "Number of Employees Trained in Grievance Redressal Mechanisms (Doughnut)",
            "isAction": true
          }
        ],
        "is_checked": true,
        "isAction": true
      },
      {
        "model": "dashboard",
        "label": "Program View (Environmental Impact)",
        "authentication": [
          {
            "key": "Energy",
            "label": "Energy",
            "isAction": true
          },
          {
            "key": "Water",
            "label": "Water",
            "isAction": true
          },
          {
            "key": "Chemical",
            "label": "Chemical",
            "isAction": true
          },
          {
            "key": "NonRenewableEnergyConsumption",
            "label": "Non - Renewable Energy Consumption (Group Bar Chart)",
            "isAction": true
          },
          {
            "key": "RenewableEnergyConsumption",
            "label": "Renewable Energy Consumption (Group Bar Chart)",
            "isAction": true
          },
          {
            "key": "TotalEnergyConsumption",
            "label": "Total Energy Consumption (Group Bar Chart)",
            "isAction": true
          },
          {
            "key": "Scope1&Scope2GHGEmissions",
            "label": "Scope 1 & Scope 2 GHG Emissions (Group Bar Chart)",
            "isAction": true
          },
          {
            "key": "NumberofEmployeesTrainedinGrievanceRedressalMechanisms",
            "label": "Number of Employees Trained in Grievance Redressal Mechanisms",
            "isAction": true
          }
        ],
        "is_checked": true,
        "isAction": true
      }
    ],
    "isActive": true
  }]
  ngOnInit(): void {
    this.adminData = JSON.parse(this.adminData);
    sessionStorage.setItem('userPermissionDemo',JSON.stringify(this.prefillJson));
     this.getRoleBasedOnId();
  }



  async getRoleBasedOnId(){
    await this.userManagementServiceService.getDashboardModule().subscribe((res:any) => {
      if(res.success){
        console.log("resres",res);
        
        res = JSON.parse(JSON.stringify(res?.data[0]));
        if(res){
            // this.roleName = res['role.name'] ? res['role.name'] :"name not availabe";
            // this.selectedLevel = res['userlevel_id'];
            // this.roleLevelId = res['role_id'];
            this.customJson = res.authenicationJson;
            console.log("this.customJson",this.customJson);
        }else{
          this.openSnackBar(['Data Not Available !'] , '','info');
          return
        }
         
      }
    },(error:any)=>{
      this.openSnackBar([error?.message ? error?.message : error?.data || 'Failed !'] , '');
    })
  }

  getUserLevelPerrmissonData(){
    this.userManagementServiceService.getDashboardModule().subscribe((res:any)=>{
      console.log('rrr',res);
      
      let selfPermission:any = JSON.parse(sessionStorage.getItem('userPermissionDemo')!);
        let filteredPermission:any = selfPermission[0].authenicationJson.filter((item:any) => item.authentication.some((auth:any) => auth.isAction === true))
        filteredPermission = filteredPermission && filteredPermission.length ? filteredPermission.map((module:any) => module.model) : [];
        this.customJson = JSON.parse(JSON.stringify(res['data']));
        console.log("this.customJson",this.customJson);
        if(this.adminData?.userlevel_id == 1){
          this.customJson = [...this.customJson.filter((permission:any) => filteredPermission.includes(permission.model))];
          
          this.customJson.forEach((element:any) => {
            let selfAction:any = selfPermission[0].authenicationJson.find((self:any) => self.model == element.model);
            selfAction = selfAction.hasOwnProperty('model') ? selfAction.authentication.filter((action:any) => action.isAction) : [];
            selfAction = selfAction?.length ? selfAction.map((ele:any) => ele.key) : [];
            element.authentication =  element.authentication.filter((action:any) => selfAction.includes(action.key))

          });
        }
        console.log("ressss",res)
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    })
  }

  selectAll(event:any){
  event.checked = this.roleId ? !event.checked : event.checked;
    if(event.checked){
      this.customJson = this.customJson?.map((item: any) => {
        item.isAction = this.roleId  ? !event.checked : event.checked;
        item.is_checked = this.roleId  ? !event.checked : event.checked;
        if (item && item?.authentication && item?.authentication?.length) {
            item?.authentication?.map((innnerItem: any) => {
                innnerItem.isAction = this.roleId  ? !event.checked : event.checked;
            });
        }
        return item;
    });
    }else {
      this.customJson = this.customJson?.map((item: any) => {
          item.isAction = this.roleId ? !event.checked : event.checked;
          item.is_checked = this.roleId  ? !event.checked : event.checked;
          if (item && item?.authentication && item?.authentication?.length) {
              item?.authentication?.map((innnerItem: any) => {
                  innnerItem.isAction = this.roleId  ? !event.checked : event.checked;
              });
          }
          return item;
      });
    }
  }

  isModuleOver(item:any):boolean{
    return item?.length && item?.authenicationJson?.length &&  item?.authenicationJson.every((el:any)=>(el?.is_checked || el?.isAction))
  }

  isCheckedModule(module:any):any {
    if (module.authentication && module.authentication.length > 0 && module.authentication.every((item:any) => item.isAction)) {
        module.isAction = true;
        module.is_checked = true;
    } else {
        module.isAction = false;
        module.is_checked = false
    }
  }

  isIndeterminateModuleOver(module:any) {
    return (
        module.authentication &&
        module.authentication.length &&
        module.authentication.some((data:any) => data.isAction) &&
        !module.authentication.every((data:any) => data.isAction)
    );
  }

  onModuleChange(event:any, module:any): void {
    console.log(module);
    if (event) {
      module.isAction = event.checked;
      module.is_checked = event.checked;
        module.authentication.map((item:any) => {
            item.isAction = event.checked;
        });
    }
  }

  onActionChange(event:any, page:any, selectedData:any) {
    if (
      (page.authentication.length == 0 && page.isAction) ||
      (page.authentication.length && page.authentication.every((item:any) => item.isAction))
  ) {
      page.isAction = true;
  } else {
      page.isAction = false;
  }
    console.log("event", selectedData);
  }

  createRole():void{
    console.log("ipsaIndex", this.customJson)
    
    // let ipsaIndex = this.customJson.findIndex((permission:any) => permission.model == 'ipsa');
    // if(ipsaIndex == -1){
    //   let ipsaPermission = {
    //     "model": "ipsa",
    //     "label": "IPSA",
    //     "authentication": [
    //         {
    //             "key": "createui",
    //             "label": "Create",
    //             "isAction": false
    //         },
    //         {
    //             "key": "list",
    //             "label": "List",
    //             "isAction": false
    //         },
    //         {
    //             "key": "update",
    //             "label": "Update",
    //             "isAction": false
    //         },
    //         {
    //             "key": "report",
    //             "label": "Report",
    //             "isAction": false
    //         },
    //         {
    //             "key": "submissionlist",
    //             "label": "Report Submisson List",
    //             "isAction": false
    //         }
    //     ],
    //     "is_checked": false,
    //     "isAction": false
    // }
    
    // this.customJson.push(ipsaPermission)
    // }

    // console.log("ipsaIndex",ipsaIndex, this.customJson)
    // this.customJson.forEach((form:any) => {
    //   form.authentication.forEach((auth:any) => {
    //     if (auth?.isAction) {
    //       const index = form.authentication.findIndex((a: any) => a.key === 'list' || a.key === 'listui');

    //       if (index !== -1) {
    //         form.authentication[index].isAction = true;  // Update isAction to false
    //       }
    //     }
    //   });
    // });
    let payload:any = {
        
        authenicationJson : this.customJson,
        
        // ...!(this.roleId) && {createdBy : this.adminData ? this.adminData?.id : ''}
    }
    console.log("payyy",payload);

    this.openSnackBar(["Updated Successfully !" || 'Success !'] , '','success');
    this.userManagementServiceService.setDashboardModule(this.customJson).subscribe((res:any) => {
      if(res.success){
        this.openSnackBar([res?.message || 'Success !'] , '','success');
        // this.scroll.scrollToPosition([0,0]);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      // this.router.navigate(['/user-level'])
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    });

}

  isDisabled():boolean{
    return !(this.customJson.some((el:any)=>el?.isAction || el?.authentication?.some((e:any)=>e?.isAction))) 
  }

  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: [className]
  });
  }

}
