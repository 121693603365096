import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-ipsa-intro',
  templateUrl: './ipsa-intro.component.html',
  styleUrls: ['./ipsa-intro.component.scss']
})
export class IpsaIntroComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  backToForm(){
    this.router.navigate(['/ipsa'])
  }

}
