<div class="Footer_bottom">
    <div class="container-fluid">
        <div class="d-flex align-items-center">
            <svg class="me-2 pointer" (click)="backToPrevious()" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none"/>
                <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z" fill="black"/>
              </svg>
              
            <nav aria-label="breadcrumb" class="my-3">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToForm()">IPSA Form</li>
                <li class="breadcrumb-item fs-18 fw-400 pointer" (click)="backToPrevious()">{{showFormName}}</li>
                <li class="breadcrumb-item fs-18 fw-600 pointer active-breadcrumb active">New Form</li>
              </ol>
            </nav>
          </div>
        <div class="row mt-2">
            
            <div class="col-sm-2">
                <label for="formTitle">Title <span class="requiredClass">*</span> </label>
                <input type="text" class="form-control" placeholder="Enter the form title" required [(ngModel)]="formTitle" name="formTitle" appAlphabatesOnly>
            </div>
            <div class="col-sm-3">
                <label for="formTitle">Name <span class="requiredClass">*</span></label>
                <input type="text" class="form-control" placeholder="Enter the form machine name" required [(ngModel)]="formName" appAlphabatesOnly>
            </div>
            <div class="col-sm-3">
                <label for="formTitle">API path <span class="requiredClass">*</span></label>
                <input type="text" class="form-control" placeholder="https://xkvkigrdwkuceys.form.io/"  [(ngModel)]="formAPIpath">
            </div>
            <div class="col-sm-4 toggleButton d-none">
                <!-- <span class="toggleButton mt-3"> -->
                    <!-- <mat-slide-toggle [(ngModel)]="showSplit" (change)="screenSplit($event)" class="mt-5 mr-3" >Split screen</mat-slide-toggle> -->
                <!-- </span> -->
                <!-- <span class="toggleButton mt-3"> -->
                    <mat-slide-toggle [(ngModel)]="isToggleChecked" (change)="onToggle($event)" class="mt-5" [disabled]="showSplit">Render form</mat-slide-toggle>
                <!-- </span> -->
            </div>
        </div>
    </div>
    <div class="container-fluid mt-5">
        
        <div class="row">
            <div class="col-sm-12" [ngStyle]="{'display': isToggleChecked ? 'none' : ''}" [ngClass]="{'col-lg-6': showSplit, 'col-lg-12': !showSplit }">
                Form Builder
                <div id="builder"></div>
            <div class="row">
                <div class="d-flex justify-content-end mb-3">
                    <button type="button" class="btn create_btn text-white me-3" (click)="onCreate()">Create Form</button>
                </div>
                <div class="col-6 d-flex justify-content-center mb-3" *ngIf="showSplit">
                    <button type="button" class="btn btn-success" (click)="onRender()">Render</button>
                </div>
            </div>
            </div>
            <div class="col-sm-12" [ngStyle]="{'display': !showSplit && !isToggleChecked  ? 'none' : ''}" [ngClass]="{'col-lg-6': showSplit, 'col-lg-12': !showSplit }">
            Form Display
            <div id="formio"></div>
            </div>
        </div>
    </div>
</div>